import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ISearchBoxProps, IPeoplePickerItemSelectedProps, PeoplePickerItem, ValidationState, NormalPeoplePicker, IPersonaProps, IPickerItemProps, DialogFooter, CheckboxVisibility } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks"
import { getPage } from "../../../../app/Pages"
import { setPage } from "../../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../../common/Table/Table"
import { reset, dismissMessage, setSort, setNumberOfResults, resetSet, setSelectedItems, setSearchText, setIsFilteredSet, addItem, updateItem, getItems, close, getTreasuryTypes, resetTreasuryTypes, getCurrencies, setIsFilteredTreasuryTypesSet, setNatureFilterOption, setCodeFilterOption, updateLimit, resetCurrencies, setIsFilteredCurrenciesSet, setIsFilteredLimitationsSet, setSelectedTreasuryTypeItems, setSelectedCurrencyItems, setSelectedLimitationItems, setStatusFilterOption, updateStatus, resetLimitations, getLimitations } from "./TreasuriesPageSlice"
import { ApiMessage, getHeaders } from "../../../../app/Api"
import { useId, useBoolean } from '@fluentui/react-hooks';
import * as google_protobuf_wrappers_pb from 'google-protobuf/google/protobuf/wrappers_pb'
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { getMainTheme } from "../../../../app/Themes"
import { SearchField } from "../../../common/SearchField/SearchField"
import { currencyFormatter, formatDate, printComponent, normalizeKey } from "../../../../app/Helpers"
import { TableState } from "../../../common/Table/TableSate"
import { Message } from "../../../common/Message/Message"
import { ShareBox } from "../../../common/ShareBox/ShareBox"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { bool, string } from "yup"
import { Popup } from "../../../common/Popup/Popup"
import { FormType } from "../../../forms/FormProps"
import { TreasuryStatus, LimitationType, Operations, Natures } from "../../../../app/Enums"
import { GetCurrenciesRequest } from "../../../../repository/Accountant/currency_pb"
import { GetLimitationsRequest } from "../../../../repository/Accountant/limitation_pb"
import { CloseTreasuryRequest, GetTreasuriesRequest, UpdateTreasuryStatusRequest } from "../../../../repository/Accountant/treasury_pb"
import { GetTreasuryTypesRequest } from "../../../../repository/Accountant/treasury_type_pb"
import { TreasuriesForm } from "../../../forms/Accountant/Treasuries/TreasuriesForm"
import { useReactToPrint } from "react-to-print"
import { PageProps } from "../../PageProps"
import { List } from "../../../common/List/List"
import { TreasuryTypesPage } from "../TreasuryTypes/TreasuryTypesPage"
import { CurrenciesPage } from "../Currencies/CurrenciesPage"

const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};

let req: GetTreasuriesRequest;
let treasuryTypesReq: GetTreasuryTypesRequest;
let currenciesReq: GetCurrenciesRequest;
let limitationReq: GetLimitationsRequest;

let org: number = -1;

let getItemsPromise: any;
let getFilterPromise: any;
let actionPromise: any;

const key = "treasuriesManagement/treasuries"
export const TreasuriesPage: React.FunctionComponent<PageProps> = (props) => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const state: {
        isChangeStateLoading: boolean,
        set: TableState,
        message: ApiMessage | undefined,
        appTheme: string, isDarkModeEnabled: boolean,
        searchText: string | undefined,
        NatrueFilterOption: { key: number, text: string },
        filters: any[],
        codeFilterOption: string | undefined
        statusFilterOption: { key: number, text: string }
        treasuryTypes: TableState
        currencies: TableState
        limitations: TableState

    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.treasuriesPage.isChangeStateLoading,
            set: state.treasuriesPage.set,
            message: state.treasuriesPage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            searchText: state.treasuriesPage.searchText,
            treasuryTypes: state.treasuriesPage.treasuryTypes,
            currencies: state.treasuriesPage.currencies,
            codeFilterOption: state.treasuriesPage.codeFilterOption,
            filters: state.treasuriesPage.filters,
            NatrueFilterOption: state.treasuriesPage.natrueFilterOption,
            statusFilterOption: state.treasuriesPage.statusFilterOption,
            limitations: state.treasuriesPage.limitations

        }
    })
    const calloutTargetId = useId('callout-target');
    const labelId = useId('callout-label');
    const closeTreasuryActionConfirmationcalloutTargetId = useId('close-treasury-action-callout-target');
    const activityActionConfirmationcalloutTargetId = useId('activity-action-callout-target');
    const ActionConfirmationLabelId = useId('action-callout-label');
    const shareCalloutLabelId = useId('share-callout-label');
    const shareTargetId = useId('share-callout-target');

    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const [isActionConfirmationCalloutVisible, { toggle: toggleisActionConfirmationCalloutVisible }] = useBoolean(false);
    const [isShareCalloutVisible, { toggle: toggleisShareCalloutVisible }] = useBoolean(false);

    const [currentAction, setCurrenctAction] = useState(0)
    const ref = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => {
            return printComponent(ref.current?.cloneNode(true), true)
        }

    });


    const getActionId = (action: number): string => {
        if (action == 1) {
            return closeTreasuryActionConfirmationcalloutTargetId;
        } else if (action == 2) {
            return activityActionConfirmationcalloutTargetId;
        } else {
            return ""
        }
    }

    const _selection = useConst(() => new Selection({
        onSelectionChanged: () => {
            if (_selection.count > 0) {
                dispatch(setSelectedItems(_selection.getSelection()));
            } else {
                dispatch(setSelectedItems([]));
            }
        },

    }));

    const clearFilter = () => {
        dispatch(setSelectedTreasuryTypeItems([]))
        req.setTreasurytype(undefined)
        dispatch(setSelectedCurrencyItems([]))
        req.setCurrency(undefined)
        dispatch(setStatusFilterOption({ key: 0, text: "" }))
        req.setStatus(undefined)
        dispatch(setCodeFilterOption(undefined))
        req.setCode(undefined)
        dispatch(setNatureFilterOption({ key: 0, text: "" }))
        req.setNatrue(undefined)
    }

    const search = (searchText: string | undefined) => {
        let isFiltered: boolean = false;
        if (searchText) {
            const wrapper = new StringValue();
            wrapper.setValue(searchText.trim());
            req.setSearch(wrapper)
            isFiltered = true;
        } else {
            req.setSearch(undefined)
        }


        if (state.treasuryTypes.selected.length > 0) {
            const wrapper = new StringValue();
            wrapper.setValue((state.treasuryTypes.selected.at(0)?.id));
            req.setTreasurytype(wrapper)
            isFiltered = true;
        } else {
            req.setTreasurytype(undefined)
        }

        if (state.currencies.selected.length > 0) {
            const wrapper = new StringValue();
            wrapper.setValue((state.currencies.selected.at(0)?.id));
            req.setCurrency(wrapper)
            isFiltered = true;
        } else {
            req.setCurrency(undefined)
        }


        if (state.statusFilterOption.key != 0) {
            const wrapper = new Int32Value();
            wrapper.setValue(state.statusFilterOption.key);
            req.setStatus(wrapper)
            isFiltered = true;

        } else {
            req.setStatus(undefined)
        }

        if (state.codeFilterOption && state.codeFilterOption.trim().length > 0) {
            const wrapper = new StringValue();
            wrapper.setValue(state.codeFilterOption.trim());
            req.setCode(wrapper)
            isFiltered = true;

        } else {
            req.setCode(undefined)
        }

        if (state.NatrueFilterOption.key != 0) {
            const wrapper = new Int32Value();
            wrapper.setValue(state.NatrueFilterOption.key);
            req.setNatrue(wrapper)
            isFiltered = true;

        } else {
            req.setNatrue(undefined)
        }
        req.setNextto(undefined)
        dispatch(resetSet())
        dispatch(setIsFilteredSet(isFiltered))
        getItemsPromise?.abort()
        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))
    }


    useEffect(() => {
        req = new GetTreasuriesRequest();
        treasuryTypesReq = new GetTreasuryTypesRequest();
        currenciesReq = new GetCurrenciesRequest();
        limitationReq = new GetLimitationsRequest();

        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                req.setOrganization(wrapper)
                treasuryTypesReq.setOrganization(wrapper)
                currenciesReq.setOrganization(wrapper)
                limitationReq.setOrganization(wrapper)

            }
        }
        if (!props.isSelection) {
            const page = getPage(key)
            dispatch(setTitle(t(normalizeKey(page?.title))));
            dispatch(setPage(page));
        }

        req.setNextto(undefined)
        req.setNumofresults(state.set.numberOfResults)
        req.setOrder(state.set.isDescending)


        const boolv = new BoolValue();
        boolv.setValue(true);

        treasuryTypesReq.setNumofresults(state.treasuryTypes.numberOfResults)
        treasuryTypesReq.setOrder(state.treasuryTypes.isDescending)
        //treasuryTypesReq.setIsavailable(boolv)
        currenciesReq.setNumofresults(state.currencies.numberOfResults)
        currenciesReq.setOrder(state.currencies.isDescending)
      //  currenciesReq.setIsavailable(boolv)
        limitationReq.setNumofresults(state.limitations.numberOfResults)
        limitationReq.setOrder(state.limitations.isDescending)
        const type = new Int32Value();
        type.setValue(LimitationType.TREASURY_LIMITATION);
        limitationReq.setType(type)
       // limitationReq.setIsavailable(boolv)
        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))


        return () => { //clean up
            getItemsPromise?.abort();
            getFilterPromise?.abort();
            actionPromise?.abort();
            dispatch(reset());
        }
    }, [])
    /*
    
          public long Id { get; set; }
                public string InternalId { get; set; }
                public string Name { get; set; }
                public string Code { get; set; }
                public decimal MirrorBalance { get; set; }
                public CurrencyResponse Currency { get; set; }
                public int Status { get; set; }
                public AccountTypeResponse AccountType { get; set; }
                public OwnerResponse Owner { get; set; }
                public ProfileResponse CreatedBy { get; set; }
                public DateTime CreatedDate { get; set; }
    */

    const c: IColumn[] = [
        {


            key: 'id',
            name: t("treasuryNumber"),
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',

            isPadded: true,
            onRender: (item) => {
                if (item?.id != undefined)
                    return <Text>{item?.id}</Text>;
            },
        },
        {
            key: 'name',
            name: t("treasury"),
            minWidth: 20,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {

                return <Link onClick={(e) => {
                    if (e && !props.isSelection) {
                        e.preventDefault();
                        navigate("/treasuries/" + item?.internalId + ((org == -1) ? "" : "?org=" + org));
                    }
                }
                } href={"/treasuries/" + item?.internalId + ((org == -1) ? "" : "?org=" + org)} target={props.isSelection ? "_blank" : undefined}> {item?.name == "" ? item?.internalId : item?.name}</Link >;
                //return <Link href="/" underline >{item.name}</Link>;
            },
        },
        {
            key: 'code',
            name: t("treasuryCode"),
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.code != undefined)
                    return <Text>{item?.code}</Text>;
            },
        },

        {
            key: 'status',
            name: t("status"),
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.status != undefined) {
                    if (item?.status == TreasuryStatus.ACTIVE)
                        return <Text>{t("active")}</Text>;
                    else if (item?.status == TreasuryStatus.INACTIVE)
                        return <Text>{t("inactive")}</Text>;
                    else if (item?.status == TreasuryStatus.CLOSED)
                        return <Text>{t("closed")}</Text>;
                }

            },
        },
        {
            key: 'treasuryTypeName',
            name: t("treasuryType"),
            minWidth: 20,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <Link onClick={(e) => {
                    if (e && !props.isSelection) {
                        e.preventDefault();
                        navigate("/treasury-types/" + item?.treasuryTypeId + ((org == -1) ? "" : "?org=" + org));
                    }
                }
                } href={"/treasury-types/" + item?.treasuryTypeId + ((org == -1) ? "" : "?org=" + org)} target={props.isSelection ? "_blank" : undefined}> {item?.treasuryTypeName == "" ? item?.treasuryTypeId : item?.treasuryTypeName}</Link >;
                //return <Link href="/" underline >{item.name}</Link>;
            },
        },
        {
            key: 'nature',
            name: t("nature"),
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {

                if (item?.nature != undefined) {
                    if (item?.nature == Natures.PHYSICAL)
                        return <Text>{t("physical")}</Text>;
                    else if (item?.nature == Natures.VIRTUAL)
                        return <Text>{t("virtual")}</Text>;
                }

            },
        },
        {
            key: 'createdDate',
            name: t("createdDate"),
            minWidth: 20,
            maxWidth: 60,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {

                return <Text>{(item?.createdDate)}</Text>; //{item.createdDate}
            },
        },



    ];

    const _selectionItems: ICommandBarItemProps[] = [
        {
            key: 'edit',
            text: t("edit"),

            iconProps: { iconName: 'EditSolid12' },
            disabled: state.set.selected.at(0)?.status == TreasuryStatus.CLOSED,
            title: t("edit"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(101)
                }
            }
        },
        {
            key: 'status',
            text: (state.set.selected.length > 0 && state.set.selected.at(0)?.status == TreasuryStatus.ACTIVE) ? t("deactivate") : t("activate"),
            iconProps: { id: activityActionConfirmationcalloutTargetId, iconName: (state.set.selected.length > 0 && state.set.selected.at(0)?.status == TreasuryStatus.ACTIVE) ? "Cancel" : "Accept", styles: { root: { fontWeight: (state.set.selected.length > 0 && state.set.selected.at(0)?.status == TreasuryStatus.ACTIVE) ? undefined : "bold" } } },
            disabled: state.set.selected.at(0)?.status == TreasuryStatus.CLOSED,
            title: (state.set.selected.length > 0 && state.set.selected.at(0)?.status == TreasuryStatus.ACTIVE) ? t("deactivate") : t("activate"),
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 2 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 2 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 2 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(2)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },
        {
            key: 'close',
            text: t("closeTreasury"),
            iconProps: { id: closeTreasuryActionConfirmationcalloutTargetId, iconName: "Blocked", styles: { root: { fontWeight: "bold" } } },
            disabled: state.set.selected.at(0)?.status == TreasuryStatus.CLOSED,
            title: t("closeTreasury"),
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 1 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 1 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 1 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(1)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },

        {
            key: 'logs',
            text: t("logs"),
            iconProps: { iconName: "PageList", styles: { root: { fontWeight: "bold" } } },
            title: t("logs"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/logs?resourceId=" + state.set.selected.at(0)?.internalId + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },
        {
            key: 'share',
            text: t("share"),
            title: t("share"),
            iconProps: { id: shareTargetId, iconName: 'Share', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                toggleisShareCalloutVisible()
            },

        },

    ];

    const mainOptions: ICommandBarItemProps[] = [
        {
            key: 'newItem',
            text: t('add'),
            title: t('add'),
            iconProps: { iconName: 'Add' },
            onClick: () => {
                //  navigate("add")
                setCurrenctAction(100)
            }
        },
        {
            key: 'refresh',
            text: t('refresh'),
            title: t('refresh'),
            iconProps: { iconName: 'refresh' },
            disabled: state.set.isFetching,
            onClick: () => {
                dispatch(resetSet())
                req.setNextto(undefined)
                //   if (!state.searchText || state.searchText?.trim().length == 0) {
                //     req.setSearch(undefined)
                //}
                getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))
            }
        },
        {
            key: 'print',
            text: t("print"),
            iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
            title: t("print"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    handlePrint();
                }
            }
        },
    ];
    const _farOptions: ICommandBarItemProps[] = [
        {
            key: 'sort',
            text: t('sort'),
            title: t('sort'),
            iconProps: { iconName: 'sort' },
            disabled: state.set.isFetching,
            subMenuProps: {
                items: [{
                    key: 'ascending',
                    text: t('ascendingDate'),
                    title: t('ascendingDate'),
                    canCheck: true,
                    checked: !state.set.isDescending,
                    onClick: () => {
                        dispatch(setSort(false))
                        dispatch(resetSet())
                        req.setOrder(false)
                        req.setNextto(undefined)
                        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

                    }


                }, {
                    key: 'descending',
                    text: t('descendingDate'),
                    title: t('descendingDate'),
                    canCheck: true,
                    checked: state.set.isDescending,
                    onClick: () => {
                        dispatch(setSort(true))
                        dispatch(resetSet())
                        req.setOrder(true)
                        req.setNextto(undefined)
                        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

                    }

                }]
            }
        }


    ];
    const _farBottomOptions: ICommandBarItemProps[] = [
        {
            key: 'results',
            text: t('results') + ': ' + state.set.numberOfResults,
            title: t('results') + ': ' + state.set.numberOfResults,
            iconProps: { iconName: 'ShowResults' },
            subMenuProps: {
                items: [{
                    key: '10',
                    text: '10',
                    title: '10',
                    canCheck: true,
                    checked: (state.set.numberOfResults == 10 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(10))
                        req.setNumofresults(10)
                    }


                }, {
                    key: '50',
                    text: '50',
                    title: '50',
                    canCheck: true,
                    checked: (state.set.numberOfResults == 50 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(50))
                        req.setNumofresults(50)
                    }

                },
                {
                    key: '100',
                    text: '100',
                    title: '100',
                    canCheck: true,
                    checked: (state.set.numberOfResults == 100 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(100))
                        req.setNumofresults(100)
                    }

                }]
            }
        }
    ];



    const treasuryTypesSuggestionsProps: ISuggestionsProps<ITag> = {
        suggestionsHeaderText: t('treasuryTypes'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.treasuryTypes.hasMore && !state.treasuryTypes.isFetching,
        onRenderSuggestion: (props: ITag, suggestionItemProps: ISuggestionItemProps<ITag>) => {
            return <TagItemSuggestion>
                {props?.name ?? props?.key}
            </TagItemSuggestion>
        },
        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.treasuryTypes.items.findIndex(e => e.id == item.key)
            if (u >= 0) {
                dispatch(setSelectedTreasuryTypeItems([state.treasuryTypes.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.treasuryTypes.items.length > 0 && !state.treasuryTypes.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.treasuryTypes.items.at(state.treasuryTypes.items.length - 1).id);
                treasuryTypesReq.setNextto(wrapper)
                getFilterPromise = dispatch(getTreasuryTypes({ body: treasuryTypesReq, headers: getHeaders() }))
            }
        },
        suggestions: state.treasuryTypes.items.length > 0 ? state.treasuryTypes.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : [],
        isLoading: state.treasuryTypes.items.length == 0 ? state.treasuryTypes.isFetching : false,
        isSearching: state.treasuryTypes.items.length > 0 ? state.treasuryTypes.isFetching : false,


    };
    const onTreasuryTypesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetTreasuryTypes())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        treasuryTypesReq.setSearch(wrapper)
        treasuryTypesReq.setNextto(undefined)
        dispatch(setIsFilteredTreasuryTypesSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getTreasuryTypes({ body: treasuryTypesReq, headers: getHeaders() }))
        return [];

    }


    const currenciesSuggestionsProps: ISuggestionsProps<ITag> = {
        suggestionsHeaderText: t('currencies'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.currencies.hasMore && !state.currencies.isFetching,
        onRenderSuggestion: (props: ITag, suggestionItemProps: ISuggestionItemProps<ITag>) => {
            return <TagItemSuggestion>
                {props?.name ?? props?.key}
            </TagItemSuggestion>
        },
        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.currencies.items.findIndex(e => e.id == item.key)
            if (u >= 0) {
                dispatch(setSelectedCurrencyItems([state.currencies.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.currencies.items.length > 0 && !state.currencies.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.currencies.items.at(state.currencies.items.length - 1).id);
                currenciesReq.setNextto(wrapper)
                getFilterPromise = dispatch(getCurrencies({ body: currenciesReq, headers: getHeaders() }))
            }
        },
        suggestions: state.currencies.items.length > 0 ? state.currencies.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : [],
        isLoading: state.currencies.items.length == 0 ? state.currencies.isFetching : false,
        isSearching: state.currencies.items.length > 0 ? state.currencies.isFetching : false,


    };
    const onCurrenciesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetCurrencies())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        currenciesReq.setSearch(wrapper)
        currenciesReq.setNextto(undefined)
        dispatch(setIsFilteredCurrenciesSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getCurrencies({ body: currenciesReq, headers: getHeaders() }))
        return [];

    }

    const limitationsSuggestionsProps: ISuggestionsProps<ITag> = {
        suggestionsHeaderText: t('limitations'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.limitations.hasMore && !state.limitations.isFetching,
        onRenderSuggestion: (props: ITag, suggestionItemProps: ISuggestionItemProps<ITag>) => {
            return <TagItemSuggestion>
                {props?.name ?? props?.key}
            </TagItemSuggestion>
        },
        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.limitations.items.findIndex(e => e.id == item.key)
            if (u >= 0) {
                dispatch(setSelectedLimitationItems([state.limitations.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.limitations.items.length > 0 && !state.limitations.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.limitations.items.at(state.limitations.items.length - 1).id);
                limitationReq.setNextto(wrapper)
                getFilterPromise = dispatch(getLimitations({ body: limitationReq, headers: getHeaders() }))
            }
        },
        suggestions: state.limitations.items.length > 0 ? state.limitations.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : [],
        isLoading: state.limitations.items.length == 0 ? state.limitations.isFetching : false,
        isSearching: state.limitations.items.length > 0 ? state.limitations.isFetching : false,


    };
    const onLimitationsFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetLimitations())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        limitationReq.setSearch(wrapper)
        limitationReq.setNextto(undefined)
        dispatch(setIsFilteredLimitationsSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getLimitations({ body: limitationReq, headers: getHeaders() }))
        return [];

    }

    return (
        <Stack>
            <Popup isOpen={(currentAction == 100)} title={t("addTreasury")} isBlocking onDismiss={() => { setCurrenctAction(0) }} >
                <TreasuriesForm childrenGap={10} maxWidth={280} type={FormType.ADD} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(addItem(e))

                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Popup isOpen={(currentAction == 101)} title={t("editTreasury")} onDismiss={() => { setCurrenctAction(0) }} >
                <TreasuriesForm childrenGap={10} maxWidth={280} type={FormType.EDIT} fetchData={true} renderObject={state.set.selected.at(0)} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(updateItem(e))
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>

            <Popup isOpen={(currentAction == 1000)} title={t("treasuryTypes")} onDismiss={() => { setCurrenctAction(0) }} >

                <TreasuryTypesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedTreasuryTypeItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Popup isOpen={(currentAction == 1003)} title={t("currencies")} onDismiss={() => { setCurrenctAction(0) }} >
                <CurrenciesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedCurrencyItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>

            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }
            <CommandBar
                items={state.set.selected.length == 0 ? mainOptions : _selectionItems}
                farItems={_farOptions}

            //id={actionConfirmationcalloutTargetId}


            />



            {isShareCalloutVisible ? (
                <Callout
                    ariaLabelledBy={shareCalloutLabelId}
                    target={`#${shareTargetId}`}
                    onDismiss={toggleisShareCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <ShareBox onDismiss={toggleisShareCalloutVisible} title={t("shareTreasury")} value={`https://${window.location.host}/treasuries/${state.set.selected.at(0).internalId}` + ((org == -1) ? "" : "?org=" + org)}></ShareBox>
                </Callout>
            ) : null}


            {isActionConfirmationCalloutVisible ? (
                <Callout
                    ariaLabelledBy={ActionConfirmationLabelId}
                    target={`#${getActionId(currentAction)}`}
                    onDismiss={toggleisActionConfirmationCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <Stack tokens={{ padding: '20px 24px ' }}>
                        <Text block variant="xLarge" styles={{ root: { marginBottom: 12, fontWeight: FontWeights.semilight } }}>
                            {(currentAction == 1 ? t("closeTreasury") : currentAction == 2 ? (state.set.selected.at(0)?.status == 1) ? t("deactivate") : t("activate") : "")}
                        </Text>
                        <Text block variant="small">
                            {(currentAction == 1 ? t("closeTreasuryDesc") : currentAction == 2 ? (state.set.selected.at(0)?.status == 1) ? t("deactivateTreasuryDesc") : t("activateTreasuryDesc") : "")}
                        </Text>
                        {/* This FocusZone allows the user to go between buttons with the arrow keys.
              It's not related to or required for FocusTrapCallout's built-in focus trapping. */}
                        <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                            <Stack reversed tokens={{ childrenGap: 8, padding: "20px 0px 0px 0px" }} horizontal>
                                <DefaultButton autoFocus onClick={toggleisActionConfirmationCalloutVisible}>{t("cancel")}</DefaultButton>

                                <PrimaryButton onClick={() => {
                                    toggleisActionConfirmationCalloutVisible()
                                    if (state.set.selected.length > 0) {
                                        if (currentAction == 1) {
                                            let req = new CloseTreasuryRequest();
                                            req.setId(state.set.selected.at(0)?.internalId)
                                            actionPromise?.abort();
                                            actionPromise = dispatch(close({ body: req, headers: getHeaders() }))
                                            actionPromise.unwrap().then((e: any) => {
                                                _selection.setAllSelected(false)
                                            })
                                        } else if (currentAction == 2) {
                                            let req = new UpdateTreasuryStatusRequest();
                                            req.setId(state.set.selected.at(0)?.internalId)
                                            req.setStatus(state.set.selected.at(0)?.status == 1 ? 2 : 1)
                                            actionPromise?.abort();
                                            actionPromise = dispatch(updateStatus({ body: req, headers: getHeaders() }))
                                            actionPromise.unwrap().then((e: any) => {
                                                _selection.setAllSelected(false)
                                            })

                                        }
                                    }
                                }} text={(currentAction == 1 ? t("close") : currentAction == 2 ? (state.set.selected.at(0)?.status == 1) ? t("deactivate") : t("activate") : "")}  >

                                </PrimaryButton>
                            </Stack>
                        </FocusZone>
                    </Stack>
                </Callout>
            ) : null}

            {isCalloutVisible ? (
                <Callout
                    ariaLabelledBy={labelId}

                    target={`#${calloutTargetId}`}
                    onDismiss={toggleIsCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    isBeakVisible={true}
                >
                    <Stack tokens={{ padding: 28, childrenGap: 30 }}  >
                        <Text block variant="mediumPlus" >
                            {t("filterDesc")}
                        </Text>

                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label >{t("treasuryType")}</Label>
                            <List
                                styles={{ root: { width: 268 } }}
                                inputProps={{ placeholder: t("treasuryType"), }}
                                suggestionsHeaderText={t('treasuryTypes')}
                                isLoading={state.treasuryTypes.items.length == 0 ? state.treasuryTypes.isFetching : false}
                                isSearching={state.treasuryTypes.items.length > 0 ? state.treasuryTypes.isFetching : false}
                                moreSuggestionsAvailable={state.treasuryTypes.hasMore && !state.treasuryTypes.isFetching}
                                suggestions={state.treasuryTypes.items.length > 0 ? state.treasuryTypes.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : []}
                                onGetMoreResults={() => {
                                    if (state.treasuryTypes.items.length > 0 && !state.treasuryTypes.isFetching) {
                                        const wrapper = new StringValue();
                                        wrapper.setValue(state.treasuryTypes.items.at(state.treasuryTypes.items.length - 1).id);
                                        treasuryTypesReq.setNextto(wrapper)
                                        getFilterPromise = dispatch(getTreasuryTypes({ body: treasuryTypesReq, headers: getHeaders() }))
                                    }
                                }}
                                onSuggestionClick={(ev?, item?: any, index?: number) => {
                                    var u = state.treasuryTypes.items.findIndex(e => e.id == item.key)
                                    if (u >= 0) {
                                        dispatch(setSelectedTreasuryTypeItems([state.treasuryTypes.items.at(u)]))
                                    }

                                }}
                                isPeoplePicker={false}
                                selectedItems={state.treasuryTypes.selected.length > 0 ? state.treasuryTypes.selected.map(e => { return { name: e.name, key: e.id } as ITag }) : []}
                                onChange={(e) => {
                                    dispatch(setSelectedTreasuryTypeItems([]))

                                }}
                                onEmptyInputFocus={() => {
                                    dispatch(resetTreasuryTypes())
                                    treasuryTypesReq.setSearch(undefined)
                                    treasuryTypesReq.setNextto(undefined)
                                    dispatch(setIsFilteredTreasuryTypesSet(false))
                                    getFilterPromise?.abort()
                                    getFilterPromise = dispatch(getTreasuryTypes({ body: treasuryTypesReq, headers: getHeaders() }))
                                    return []
                                }}
                                onFilterChanged={onTreasuryTypesFilterChanged}
                                endButtons={[
                                    {
                                        title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                        onClick: () => {
                                            setCurrenctAction(1000)

                                        }
                                    }]}
                            />

                        </Stack>
                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label >{t("currency")}</Label>
                            <List
                                styles={{ root: { width: 268 } }}
                                inputProps={{ placeholder: t("currency"), }}
                                suggestionsHeaderText={t('currencies')}
                                isLoading={state.currencies.items.length == 0 ? state.currencies.isFetching : false}
                                isSearching={state.currencies.items.length > 0 ? state.currencies.isFetching : false}
                                moreSuggestionsAvailable={state.currencies.hasMore && !state.currencies.isFetching}
                                suggestions={state.currencies.items.length > 0 ? state.currencies.items.map(e => { return { item: { key: e.id, text: e.name, imageInitials: e?.symbol, secondaryText: e?.code, imageUrl: e?.icon } } as ISuggestionModel<IPersonaProps> }) : []}
                                onGetMoreResults={() => {
                                    if (state.currencies.items.length > 0 && !state.currencies.isFetching) {
                                        const wrapper = new StringValue();
                                        wrapper.setValue(state.currencies.items.at(state.currencies.items.length - 1).id);
                                        currenciesReq.setNextto(wrapper)
                                        getFilterPromise = dispatch(getCurrencies({ body: currenciesReq, headers: getHeaders() }))
                                    }
                                }}
                                onSuggestionClick={(ev?, item?: any, index?: number) => {
                                    var u = state.currencies.items.findIndex(e => e.id == item.key)
                                    if (u >= 0) {
                                        dispatch(setSelectedCurrencyItems([state.currencies.items.at(u)]))
                                    }
                                }}
                                isPeoplePicker={true}
                                selectedItems={state.currencies.selected.length > 0 ? state.currencies.selected.map(e => { return { key: e.id, text: e.name, imageInitials: e?.symbol, secondaryText: e?.code, imageUrl: e?.icon } as IPersonaProps }) : []}
                                onChange={(e) => {
                                    dispatch(setSelectedCurrencyItems([]))

                                }}
                                onEmptyInputFocus={() => {
                                    dispatch(resetCurrencies())
                                    currenciesReq.setSearch(undefined)
                                    currenciesReq.setNextto(undefined)
                                    dispatch(setIsFilteredCurrenciesSet(false))
                                    getFilterPromise?.abort()
                                    getFilterPromise = dispatch(getCurrencies({ body: currenciesReq, headers: getHeaders() }))
                                    return []
                                }}
                                onFilterChanged={onCurrenciesFilterChanged}
                                endButtons={[
                                    {
                                        title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                        onClick: () => {
                                            setCurrenctAction(1003)

                                        }
                                    }]}
                            />

                        </Stack>


                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label>{t("nature")}</Label>
                            <Dropdown
                                selectedKey={state.NatrueFilterOption.key}
                                options={[{ key: 0, text: t("all") }, { key: 1, text: t("physical") }, { key: 2, text: t("virtual") }]}
                                styles={{ root: { width: 300 } }}
                                onChange={((e: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {

                                    dispatch(setNatureFilterOption({ key: Number(item?.key), text: t("nature") + ":" + item?.text ?? "" }))

                                })}

                            />
                        </Stack>
                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label>{t("status")}</Label>
                            <Dropdown
                                selectedKey={state.statusFilterOption.key}
                                options={[{ key: 0, text: t("all") }, { key: 1, text: t("active") }, { key: 2, text: t("inactive") }, { key: 3, text: t("closed") }]}
                                styles={{ root: { width: 300 } }}
                                onChange={((e: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
                                    dispatch(setStatusFilterOption({ key: Number(item?.key), text: t("status") + ":" + item?.text ?? "" }))

                                })}

                            />
                        </Stack>
                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label>{t("treasuryCode")}</Label>
                            <TextField onChange={(e: any) => {
                                if (e)
                                    dispatch(setCodeFilterOption(e?.target?.value))

                            }} placeholder="T-01" maxLength={20} value={state.codeFilterOption} styles={{ root: { width: 300 } }} />

                        </Stack>

                        <DialogFooter>
                            <PrimaryButton text={t("search")} onClick={(e => {
                                search(state.searchText);
                                toggleIsCalloutVisible();
                            })} />
                            <DefaultButton text={t("clearFilter")} onClick={() => {
                                clearFilter();
                            }} />

                        </DialogFooter>
                    </Stack>
                </Callout>
            ) : null}

            <Stack.Item align="center"  >
                <Stack horizontal  >
                    <SearchField
                        inputProps={{
                            maxLength: 250,
                            placeholder: t("search"),
                        }}

                        onInputChange={(e) => {
                            dispatch(setSearchText(e));
                            return e;
                        }}
                        endButtons={[
                            { id: calloutTargetId, title: t("filter"), iconProps: { iconName: "filter" }, onClick: toggleIsCalloutVisible },
                            {
                                title: t("search"), checked: true, iconProps: { iconName: "search" }, onClick: () => {
                                    search(state.searchText);
                                    if (isCalloutVisible)
                                        toggleIsCalloutVisible();

                                }
                            }]}
                        onChange={(e) => {
                            const res = state.filters.filter(({ key: key1 }) => !e?.some(({ key: key2 }) => key1 === key2));
                            res?.forEach(e => {


                                if (e.key == 1) {
                                    dispatch(setSelectedTreasuryTypeItems([]))
                                    req.setTreasurytype(undefined)
                                }
                                if (e.key == 2) {
                                    dispatch(setSelectedCurrencyItems([]))
                                    req.setCurrency(undefined)
                                }

                                if (e.key == 3) {
                                    dispatch(setCodeFilterOption(undefined))
                                    req.setCode(undefined)
                                }
                                if (e.key == 4) {
                                    dispatch(setStatusFilterOption({ key: 0, text: "" }))
                                    req.setStatus(undefined)
                                }
                                if (e.key == 5) {
                                    dispatch(setNatureFilterOption({ key: 0, text: "" }))
                                    req.setNatrue(undefined)
                                }
                            });
                        }}

                        onResolveSuggestions={() => { return [] }}
                        selectedItems={state.filters.map(e => { return { name: e.name, key: e.key } as ITag }) || []}
                        onSearch={(e) => {
                            search(state.searchText);
                            if (isCalloutVisible)
                                toggleIsCalloutVisible();
                        }}
                        onClear={(e) => {
                            dispatch(setSearchText(undefined))
                            if (state.set.isFilteredSet) {
                                search(undefined);
                            }
                            /*if (req.getSearch() && !state.managers.isFilteredSet) {
                                dispatch(resetRoles())
                                req.setNextto(undefined)
     
                                req.setSearch(undefined)
     
                                getManagersPromise = dispatch(getManagerUsers({ body: req, headers: getHeaders() }))
                            }*/
                        }}
                        showIcon
                    />
                </Stack>
            </Stack.Item>



            <Table
                setKey="treasuries"
                items={state.set.items}
                columns={c}
                width={props.isSelection ? '80vw' : undefined}
                height={props.isSelection ? "60vh" : state.message == undefined ? "calc(100vh - 176px)" : "calc(100vh - 224px)"}
                selection={_selection}
                checkboxVisibility={props.isSelection ? CheckboxVisibility.always : CheckboxVisibility.onHover}
                compRef={ref}
                onScroll={(e) => {
                    const bottom = e?.currentTarget?.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight;
                    if (bottom && !state.set.isFetching && state.set.hasMore) {
                        const wrapper = new StringValue();
                        wrapper.setValue(state.set.items.at(state.set.items.length - 11)?.internalId);
                        req.setNextto(wrapper)
                        getItemsPromise?.abort();
                        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

                    }
                }}
                selectionMode={SelectionMode.single}
                disableSelectionZone={state.isChangeStateLoading}
                enableShimmer={state.set.items.length == 0 && state.set.isFetching}
                isColumnSortingEnabled={state.set.selected.length == 0}
                layoutMode={props.isSelection ? DetailsListLayoutMode.fixedColumns : DetailsListLayoutMode.justified}
                numberOfResultsOptions={[10, 50, 100]}
                selectedNumberOfResults={state.set.numberOfResults}
                onNumberOfResultsChange={(e) => {
                    dispatch(setNumberOfResults(e))
                    req.setNumofresults(e)
                }}
                onRowDidMount={(item?: any, index?: number) => {
                    //alert(index)
                    if (index == state.set.items.length - 11) { // last element renderd
                        const { current } = ref;
                        if (current) {
                            if (state.set.items.length > 0 && !state.set.isFetching && state.set.hasMore) {
                                if (current.scrollHeight == current.clientHeight) { // scroll not shown? get more data 
                                    const wrapper = new StringValue();
                                    wrapper.setValue(state.set.items.at(state.set.items.length - 11)?.internalId);
                                    req.setNextto(wrapper)
                                    getItemsPromise?.abort();
                                    getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))
                                }


                            }
                        }
                    }
                }}
            />

            {props.isSelection ? <DialogFooter>
                <PrimaryButton disabled={state.set.isFetching || state.set.selected.length == 0} text={state.set.isFetching ? undefined : t("select")} onClick={() => {
                    if (props.onItemSelected && state.set.selected.length > 0) {
                        props.onItemSelected(state.set.selected.at(0))
                    }
                }} >

                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.set.isFetching ? "block" : "none") } }} />

                </PrimaryButton>
                <DefaultButton disabled={state.set.isFetching} text={t("discard")} onClick={() => {
                    if (props.onCancel) {
                        props.onCancel()
                    }
                }} />
            </DialogFooter> : undefined}
        </Stack>



    );
}




