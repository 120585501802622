import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ProgressIndicator, Separator, DialogFooter, CheckboxVisibility, Dialog, DialogType } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks"
import { getPage } from "../../../../app/Pages"
import { setPage } from "../../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../../common/Table/Table"
import { ApiMessage, API_URL, clearSession, getHeaders, getSession, ORGANIZATION_LOGO_URL, STORAGE_URL } from "../../../../app/Api"
import { useId, useBoolean } from '@fluentui/react-hooks';

import * as google_protobuf_wrappers_pb from 'google-protobuf/google/protobuf/wrappers_pb'
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { getMainTheme } from "../../../../app/Themes"
import { SearchField } from "../../../common/SearchField/SearchField"
import { formatDate, printComponent, normalizeKey } from "../../../../app/Helpers"
import { TableState } from "../../../common/Table/TableSate"
import { Message } from "../../../common/Message/Message"
import { ShareBox } from "../../../common/ShareBox/ShareBox"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { bool } from "yup"
import { GetOrganizationRequest, GetOrganizationsRequest, UpdateOrganizationStateRequest } from "../../../../repository/UserManagement/organization_pb"
import { dismissMessage, reset, setSelectedUserType, setIsFilteredRolesSet, setSelectedAccountTypeItems, updateIsAccountLockedByDefault, updateExternalTransferEntityConfig, addExternalTransferEntityConfig, setSelectedExternalTransferEntityConfig, setIsFilteredUserTypesSet, setSelectedCurrencyItems, setSelectedLimitationItems, setSelectedRole, updateCanOpenAccount, updateCanSignUp, updateClientDefaultConfiguration, updateItem, updateURLs, getAccountTypes, getCurrencies, getLimitations, getRoles, getUserTypes, removeOpenAccountDefaultConfiguration, resetAccountTypes, resetCurrencies, resetItem, resetLimitations, resetRoles, resetUserTypes, updateOpenAccountDefaultConfiguration, getOrganizationClientPortalSetting, setIsFilteredAccountTypesSet, setIsFilteredLimitationsSet, setIsFilteredCurrenciesSet, setSelectedAccountConfig, addAccountConfig, updateAccountConfig, resetMessage, addCurrencyPairConfig, updateCurrencyPairConfig, removeCurrencyPairsConfiguration, setSelectedCurrencyPairConfig, removeExternalTransfaresEntitesConfiguration, } from "./OrganizationClientPortalSettingPageSlice"
import { Popup } from "../../../common/Popup/Popup"
import { FormType } from "../../../forms/FormProps"
import { OrganizationsForm } from "../../../forms/Organizations/OrganizationsForm"
import { TextView } from "../../../common/DataView/TextView"
import { ProfileView } from "../../../common/DataView/ProfileView"
import { useReactToPrint } from "react-to-print"
import { ClientDefaultConfigurationResponse, GetOrganizationClientPortalSettingRequest, UpdateURLsRequest, UpdateURLsResponse, UpdateIsAccountLockedByDefaultRequest, UpdateCanOpenAccountReplay, UpdateCanOpenAccountRequest, UpdateCanSignUpRequest, UpdateClientDefaultConfigurationReplay, UpdateClientDefaultConfigurationRequest, UpdateClientDefaultConfigurationResponse, RemoveOpenAccountDefaultConfigurationReplay, RemoveOpenAccountDefaultConfigurationRequest, RemoveCurrencyPairsConfigurationRequest, RemoveExternalTransfersEntitiesConfigurationRequest } from "../../../../repository/UserManagement/organizationclientportalsetting_pb"
import { List } from "../../../common/List/List"
import { GetAccountTypesRequest } from "../../../../repository/Accountant/account_type_pb"
import { GetCurrenciesRequest } from "../../../../repository/Accountant/currency_pb"
import { LimitationType } from "../../../../app/Enums"
import { GetLimitationsRequest } from "../../../../repository/Accountant/limitation_pb"
import { AuthenticateReply } from "../../../../repository/UserManagement/authentication_pb"
import { GetRolesRequest } from "../../../../repository/UserManagement/Customers/role_pb"
import { GetUserTypesRequest } from "../../../../repository/UserManagement/Customers/usertype_pb"
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { inputs } from "../../../../app/Validation"
import * as Yup from 'yup';
import { UserTypesPage } from "../../Customers/UserTypes/UserTypesPage"
import { RolesPage } from "../../Customers/Roles/RolesPage"
import { InputField } from "../../../forms/controls/Controls"
import { OrganizationClientPortalSettingForm } from "../../../forms/Organizations/OrganizationClientPortalSetting/OrganizationClientPortalSettingForm"
import { OrganizationClientPortalSettingCurrencyPairsForm } from "../../../forms/Organizations/OrganizationClientPortalSetting/OrganizationClientPortalSettingCurrencyPairsForm"
import { OrganizationClientPortalSettingExternalTransfersForm } from "../../../forms/Organizations/OrganizationClientPortalSetting/OrganizationClientPortalSettingExternalTransfersForm"

const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};


let req: GetOrganizationClientPortalSettingRequest;

let org: number = -1;

let getOrganizationsPromise: any;
let current: AuthenticateReply.AsObject;
let getFilterPromise: any;

let roleReq: GetRolesRequest;
let userTypeReq: GetUserTypesRequest;
let promise: any;


const key = "organizationClientPortalSetting"
export const OrganizationClientPortalSettingPage: React.FunctionComponent = () => {


    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    let { id } = useParams();
    const state: {
        isChangeStateLoading: boolean,
        message: ApiMessage | undefined,
        appTheme: string, isDarkModeEnabled: boolean,
        isLoading: boolean,
        item: any,
        limitations: TableState, currencies: TableState, accountTypes: TableState, roles: TableState, userTypes: TableState,
        openAccountDefaultConfiguration: TableState,
        currencyPairsConfiguration: TableState,
        externalTransfersEntitiesConfiguration: TableState,
    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.organizationClientPortalSettingPage.isChangeStateLoading,
            message: state.organizationClientPortalSettingPage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            isLoading: state.organizationClientPortalSettingPage.isLoading,
            item: state.organizationClientPortalSettingPage.item,
            accountTypes: state.organizationClientPortalSettingPage.accountTypes,
            currencies: state.organizationClientPortalSettingPage.currencies,
            limitations: state.organizationClientPortalSettingPage.limitations,
            roles: state.organizationClientPortalSettingPage.roles,
            userTypes: state.organizationClientPortalSettingPage.userTypes,
            openAccountDefaultConfiguration: state.organizationClientPortalSettingPage.openAccountDefaultConfiguration,
            currencyPairsConfiguration: state.organizationClientPortalSettingPage.currencyPairsConfiguration,
            externalTransfersEntitiesConfiguration: state.organizationClientPortalSettingPage.externalTransfersEntitiesConfiguration

        }
    })
    const modalPropsStyles = { main: { maxWidth: 450 } };

    const [hideDialogClientConfig, { toggle: toggleHideDialogClientConfig }] = useBoolean(true);
    const [hideDialogOrgURLs, { toggle: toggleHideDialogOrgURLs }] = useBoolean(true);

    const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);
    const modalProps = useMemo(
        () => ({
            isBlocking: true,
            styles: modalPropsStyles,
            dragOptions: undefined,
        }),
        [isDraggable],
    );
    const [showConfirmation, setShowConfirmation] = useState({
        type: DialogType.normal,
        title: '',
        subText: '',
    });

    const canSignupActionConfirmationcalloutTargetId = useId('canSignup-action-callout-target');
    const canOpenAccountActionConfirmationcalloutTargetId = useId('canoOpenAccount-action-callout-target');
    const isAccountLockedByDefaultActionConfirmationcalloutTargetId = useId('isAccountLockedByDefault-action-callout-target');

    const ActionConfirmationLabelId = useId('action-callout-label');
    const shareCalloutLabelId = useId('share-callout-label');
    const shareTargetId = useId('share-callout-target');

    const [isActionConfirmationCalloutVisible, { toggle: toggleisActionConfirmationCalloutVisible }] = useBoolean(false);

    const [isShareCalloutVisible, { toggle: toggleisShareCalloutVisible }] = useBoolean(false);
    const deleteActionConfirmationcalloutTargetId = useId('delete-action-callout-target');

    const deleteCurrencyPairActionConfirmationcalloutTargetId = useId('delete-currency-pair-action-callout-target');
    const deleteExternalTransferEntityActionConfirmationcalloutTargetId = useId('delete-external-entity-action-callout-target');

    const [currentAction, setCurrenctAction] = useState(0)
    const ref = useRef<HTMLDivElement>(null);
    const currencyPairRef = useRef<HTMLDivElement>(null);
    const externalEntitesRef = useRef<HTMLDivElement>(null);

    const handlePrint = useReactToPrint({
        content: () => {
            return printComponent(ref.current?.cloneNode(true))
        }

    });
    const getActionId = (action: number): string => {
        if (action == 1)
            return canSignupActionConfirmationcalloutTargetId;
        if (action == 2)
            return canOpenAccountActionConfirmationcalloutTargetId;
        if (action == 3)
            return deleteActionConfirmationcalloutTargetId;
        if (action == 4)
            return deleteCurrencyPairActionConfirmationcalloutTargetId;
        if (action == 5)
            return deleteExternalTransferEntityActionConfirmationcalloutTargetId;
        if (action == 6)
            return isAccountLockedByDefaultActionConfirmationcalloutTargetId;

        else {
            return ""
        }
    }

    useEffect(() => {
        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        } else {
            req = new GetOrganizationClientPortalSettingRequest();
            roleReq = new GetRolesRequest();
            userTypeReq = new GetUserTypesRequest();

            var l = searchParams.get('org');
            if (l) {
                org = Number(l);
                if (Number.isNaN(org)) {
                    org = -1;
                    req.setId(Number(current.organization?.id))
                } else {
                    const wrapper = new Int32Value();
                    wrapper.setValue(org);
                    req.setId(org)
                    roleReq.setOrganization(wrapper);
                    userTypeReq.setOrganization(wrapper)

                }
            } else {
                req.setId(Number(current.organization?.id))
            }
            roleReq.setNumofresults(state.roles.numberOfResults)
            roleReq.setOrder(state.roles.isDescending)
            userTypeReq.setNumofresults(state.userTypes.numberOfResults)
            userTypeReq.setOrder(state.userTypes.isDescending)

            const page = getPage(key)
            dispatch(setTitle(t(normalizeKey(page?.title))));
            dispatch(setPage(page));

            getOrganizationsPromise = dispatch(getOrganizationClientPortalSetting({ body: req, headers: getHeaders() }))

        }
        return () => { //clean up
            getOrganizationsPromise?.abort();
            getFilterPromise?.abort();
            promise?.abort();

            dispatch(reset());
        }
    }, [])

    const _selection = useConst(() => new Selection({
        onSelectionChanged: () => {
            if (_selection.count > 0) {
                dispatch(setSelectedAccountConfig(_selection.getSelection()));
            } else {
                dispatch(setSelectedAccountConfig([]));
            }
        },

    }));

    const _selectionCurrencyPair = useConst(() => new Selection({
        onSelectionChanged: () => {
            if (_selectionCurrencyPair.count > 0) {
                dispatch(setSelectedCurrencyPairConfig(_selectionCurrencyPair.getSelection()));
            } else {
                dispatch(setSelectedCurrencyPairConfig([]));
            }
        },

    }));

    const _selectionExternalEntity = useConst(() => new Selection({
        onSelectionChanged: () => {
            if (_selectionExternalEntity.count > 0) {
                dispatch(setSelectedExternalTransferEntityConfig(_selectionExternalEntity.getSelection()));
            } else {
                dispatch(setSelectedExternalTransferEntityConfig([]));
            }
        },

    }));

    const c: IColumn[] = [



        {
            key: 'currencyName',
            name: t("currency"),
            minWidth: 20,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <Link onClick={(e) => {
                    if (e) {
                        e.preventDefault();
                        navigate("/currencies/" + item?.currencyId + ((org == -1) ? "" : "?org=" + org));
                    }
                }
                } href={"/currencies/" + item?.currencyId + ((org == -1) ? "" : "?org=" + org)} > {item?.currencyName == "" ? item?.currencyId : item?.currencyName}</Link >;
                //return <Link href="/" underline >{item.name}</Link>;
            },
        },



        {
            key: 'accountTypeName',
            name: t("accountType"),
            minWidth: 20,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <Link onClick={(e) => {
                    if (e) {
                        e.preventDefault();
                        navigate("/account-types/" + item?.accountTypeId + ((org == -1) ? "" : "?org=" + org));
                    }
                }
                } href={"/account-types/" + item?.accountTypeId + ((org == -1) ? "" : "?org=" + org)} > {item?.accountTypeName == "" ? item?.accountTypeId : item?.accountTypeName}</Link >;
                //return <Link href="/" underline >{item.name}</Link>;
            },
        },


        {
            key: 'limitation',
            name: t("limitation"),
            minWidth: 20,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <Link onClick={(e) => {
                    if (e) {
                        e.preventDefault();
                        navigate("/limitations/" + item?.limitationId + ((org == -1) ? "" : "?org=" + org));
                    }
                }
                } href={"/limitations/" + item?.limitationId + ((org == -1) ? "" : "?org=" + org)} > {item?.limitationName == "" ? item?.limitationId : item?.limitationName}</Link >;
                //return <Link href="/" underline >{item.name}</Link>;
            },
        },
        {
            key: 'maxNumberOfAccounts',
            name: t("maxNumberOfAccounts"),
            minWidth: 20,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <Text>{item?.maxNumberOfAccounts + ""}</Text>
                //return <Link href="/" underline >{item.name}</Link>;
            },
        },

        {
            key: 'openByDefault',
            name: t("openAccountByDefault"),
            minWidth: 20,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <Text>{item?.openByDefault ? t("enabled") : t("disabled")}</Text>
                //return <Link href="/" underline >{item.name}</Link>;
            },
        },

        {
            key: 'canUpdateName',
            name: t("canUpdateName"),
            minWidth: 20,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <Text>{item?.canUpdateName ? t("enabled") : t("disabled")}</Text>
                //return <Link href="/" underline >{item.name}</Link>;
            },
        },
        
        {
            key: 'canUpdateCode',
            name: t("canUpdateCode"),
            minWidth: 20,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <Text>{item?.canUpdateCode ? t("enabled") : t("disabled")}</Text>
                //return <Link href="/" underline >{item.name}</Link>;
            },
        },
        {
            key: 'rank',
            name: t("rank"),
            minWidth: 20,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <Text>{item?.rank + ""}</Text>
                //return <Link href="/" underline >{item.name}</Link>;
            },
        },


    ];



    const cPair: IColumn[] = [



        {
            key: 'currencyPairId',
            name: t("pair"),
            minWidth: 50,
            maxWidth: 340,
            isRowHeader: true,
            isResizable: true,
            isMultiline: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.currencyPairId != undefined)
                    return <Link onClick={(e) => {
                        if (e) {
                            e.preventDefault();
                            navigate("/currency-pairs/" + item?.currencyPairId + ((org == -1) ? "" : "?org=" + org));
                        }
                    }
                    } href={"/currency-pairs/" + item?.currencyPairId + ((org == -1) ? "" : "?org=" + org)}  ><Persona
                            text={item?.currencyPairName}
                            size={PersonaSize.size40}

                        /></Link>;



                //return <Link href="/" underline >{item.name}</Link>;
            },
        },


        {
            key: 'rank',
            name: t("rank"),
            minWidth: 20,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <Text>{item?.rank + ""}</Text>
                //return <Link href="/" underline >{item.name}</Link>;
            },
        },





    ];

    const externalEntites: IColumn[] = [

        {
            key: 'name',
            name: t("accountName"),
            minWidth: 50,
            maxWidth: 340,
            isRowHeader: true,
            isResizable: true,
            isMultiline: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <Text>{item?.name}</Text>;
                //return <Link href="/" underline >{item.name}</Link>;
            },
        },

        {
            key: 'gwAccountId',
            name: t("accountNumber"),
            minWidth: 50,
            maxWidth: 120,
            isRowHeader: true,
            isResizable: true,
            isMultiline: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {

                return <Link onClick={(e) => {
                    if (e) {
                        e.preventDefault();
                        navigate("/accounts/" + item?.gwAccountInternalId + ((org == -1) ? "" : "?org=" + org));
                    }
                }
                } href={"/accounts/" + item?.gwAccountInternalId + ((org == -1) ? "" : "?org=" + org)} > {t("accountNum", { num: item?.gwAccountId })}</Link >;

                //return <Link href="/" underline >{item.name}</Link>;
            },
        },




    ];


    const _mainOpenAccountOptions: ICommandBarItemProps[] = [

        {
            key: 'newItem',
            text: t('add'),
            title: t("add"),
            iconProps: { iconName: 'Add' },
            onClick: () => {
                setCurrenctAction(105)

            }
        },

    ];

    const _selectionOpenAccountItems: ICommandBarItemProps[] = [
        {
            key: 'edit',
            text: t("edit"),
            title: t("edit"),
            iconProps: { iconName: 'EditSolid12' },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(106)
                }
            }

        },
        {
            key: 'remove',
            text: t("remove"),
            title: t("remove"),
            iconProps: { id: deleteActionConfirmationcalloutTargetId, iconName: "Remove", styles: { root: { fontWeight: "bold" } } },

            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 3 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 3 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 3 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(3)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },


    ];


    const _mainCurrencyPairsOptions: ICommandBarItemProps[] = [

        {
            key: 'newItem',
            text: t('add'),
            title: t("add"),
            iconProps: { iconName: 'Add' },
            onClick: () => {
                setCurrenctAction(107)

            }
        },

    ];

    const _selectionCurrencyPairItems: ICommandBarItemProps[] = [
        {
            key: 'edit',
            text: t("edit"),
            title: t("edit"),
            iconProps: { iconName: 'EditSolid12' },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(108)
                }
            }

        },
        {
            key: 'remove',
            text: t("remove"),
            title: t("remove"),
            iconProps: { id: deleteCurrencyPairActionConfirmationcalloutTargetId, iconName: "Remove", styles: { root: { fontWeight: "bold" } } },

            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 4 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 4 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 4 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(4)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },


    ];
    const _selectionItems: ICommandBarItemProps[] = [

        {
            key: 'refresh',
            text: t('refresh'),
            title: t('refresh'),
            iconProps: { iconName: 'refresh' },
            disabled: state.isLoading,
            onClick: () => {
                dispatch(resetItem())
                getOrganizationsPromise = dispatch(getOrganizationClientPortalSetting({ body: req, headers: getHeaders() }))
            }
        },


        {
            key: 'logs',
            text: t("logs"),
            iconProps: { iconName: "PageList", styles: { root: { fontWeight: "bold" } } },
            title: t("logs"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/logs?resourceId=" + state.item?.id + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },

        {
            key: 'print',
            text: t("print"),
            iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
            title: t("print"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    handlePrint();
                }
            }
        },
    ];

    const _farOptions: ICommandBarItemProps[] = [
        {
            key: 'canSignup',
            text: (state.item && state.item.canSignup) ? t("disableSignup") : t("enableSignup"),
            title: (state.item && state.item.canSignup) ? t("disableSignup") : t("enableSignup"),
            iconProps: { id: canSignupActionConfirmationcalloutTargetId, iconName: (state.item && state.item.canSignup) ? "UserRemove" : "UserFollowed", styles: { root: { fontWeight: (state.item && state.item.canSignup) ? undefined : "bold" } } },
            disabled: state.isLoading,

            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 1 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 1 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 1 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(1)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },
        {
            key: 'canOpenAccount',
            text: (state.item && state.item.canOpenAccount) ? t("disableAbililtyToOpenAccount") : t("enableAbililtyToOpenAccount"),
            title: (state.item && state.item.canOpenAccount) ? t("disableAbililtyToOpenAccount") : t("enableAbililtyToOpenAccount"),
            iconProps: { id: canOpenAccountActionConfirmationcalloutTargetId, iconName: (state.item && state.item.canOpenAccount) ? "UserRemove" : "UserFollowed", styles: { root: { fontWeight: (state.item && state.item.canOpenAccount) ? undefined : "bold" } } },
            disabled: state.isLoading,

            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 2 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 2 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 2 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(2)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },

        {
            key: 'isAccountLockedByDefault',
            text: (state.item && state.item.isAccountLockedByDefault) ? t("isAccountUnLocked") : t("isAccountLocked"),
            title: (state.item && state.item.isAccountLockedByDefault) ? t("isAccountUnLocked") : t("isAccountLocked"),
            iconProps: { id: isAccountLockedByDefaultActionConfirmationcalloutTargetId, iconName: (state.item && state.item.isAccountLockedByDefault) ? "Unlock" : "LockSolid", styles: { root: { fontWeight: (state.item && state.item.isAccountLockedByDefault) ? undefined : "bold" } } },
            disabled: state.isLoading,

            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 6 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 6 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 6 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(6)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },

    ];


    const _mainExternalEntitesOptions: ICommandBarItemProps[] = [

        {
            key: 'newItem',
            text: t('add'),
            title: t("add"),
            iconProps: { iconName: 'Add' },
            onClick: () => {
                setCurrenctAction(109)

            }
        },

    ];


    const _selectionExternalEntitesItems: ICommandBarItemProps[] = [
        {
            key: 'edit',
            text: t("edit"),
            title: t("edit"),
            iconProps: { iconName: 'EditSolid12' },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(110)
                }
            }

        },
        {
            key: 'remove',
            text: t("remove"),
            title: t("remove"),
            iconProps: { id: deleteExternalTransferEntityActionConfirmationcalloutTargetId, iconName: "Remove", styles: { root: { fontWeight: "bold" } } },

            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 5 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 5 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 5 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(5)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },


    ];
    const onRoleFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetRoles())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        roleReq.setSearch(wrapper)
        roleReq.setNextto(0)
        dispatch(setIsFilteredRolesSet(false))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getRoles({ body: roleReq, headers: getHeaders() }))

        return [];

    }
    const onUserTypeFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {
        dispatch(resetUserTypes())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        userTypeReq.setSearch(wrapper)
        userTypeReq.setNextto(0)
        dispatch(setIsFilteredUserTypesSet(false))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getUserTypes({ body: userTypeReq, headers: getHeaders() }))

        return [];
    }
    return (
        <Stack >

            <Popup isOpen={(currentAction == 105)} title={t("newAccountsSettings")} onDismiss={() => { setCurrenctAction(0) }} >

                <OrganizationClientPortalSettingForm childrenGap={10} maxWidth={320} type={FormType.ADD} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(addAccountConfig(e))

                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            <Popup isOpen={(currentAction == 106)} title={t("newAccountsSettings")} onDismiss={() => { setCurrenctAction(0) }} >

                <OrganizationClientPortalSettingForm childrenGap={10} maxWidth={320} type={FormType.EDIT} renderObject={state.openAccountDefaultConfiguration.selected.at(0)} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(updateAccountConfig(e))

                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            <Popup isOpen={(currentAction == 107)} title={t("newCurrencyPairsSettings")} onDismiss={() => { setCurrenctAction(0) }} >

                <OrganizationClientPortalSettingCurrencyPairsForm childrenGap={10} maxWidth={320} type={FormType.ADD} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(addCurrencyPairConfig(e))

                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Popup isOpen={(currentAction == 108)} title={t("newCurrencyPairsSettings")} onDismiss={() => { setCurrenctAction(0) }} >

                <OrganizationClientPortalSettingCurrencyPairsForm childrenGap={10} maxWidth={320} type={FormType.EDIT} renderObject={state.currencyPairsConfiguration.selected.at(0)} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(updateCurrencyPairConfig(e))

                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            <Popup isOpen={(currentAction == 109)} title={t("newExternalEntitySettings")} onDismiss={() => { setCurrenctAction(0) }} >

                <OrganizationClientPortalSettingExternalTransfersForm childrenGap={10} maxWidth={320} type={FormType.ADD} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(addExternalTransferEntityConfig(e))


                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Popup isOpen={(currentAction == 110)} title={t("newExternalEntitySettings")} onDismiss={() => { setCurrenctAction(0) }} >

                <OrganizationClientPortalSettingExternalTransfersForm childrenGap={10} maxWidth={320} type={FormType.EDIT} renderObject={state.externalTransfersEntitiesConfiguration.selected.at(0)} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(updateExternalTransferEntityConfig(e))

                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>



            <Popup isOpen={(currentAction == 100)} title={t("subscriptions")} onDismiss={() => { setCurrenctAction(0) }} >

                <RolesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedRole([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            <Popup isOpen={(currentAction == 101)} title={t("clientTypes")} onDismiss={() => { setCurrenctAction(0) }} >

                <UserTypesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedUserType([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }



            {isActionConfirmationCalloutVisible ? (
                <Callout
                    ariaLabelledBy={ActionConfirmationLabelId}
                    target={`#${getActionId(currentAction)}`}
                    onDismiss={toggleisActionConfirmationCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <Stack tokens={{ padding: '20px 24px ' }}>
                        <Text block variant="xLarge" styles={{ root: { marginBottom: 12, fontWeight: FontWeights.semilight } }}>
                            {(currentAction == 1 ? (state.item?.canSignup) ? t("disableSignup") : t("enableSignup") : currentAction == 2 ? (state.item?.canOpenAccount) ? t("disableAbililtyToOpenAccount") : t("enableAbililtyToOpenAccount") : currentAction == 3 ? t("remove") : currentAction == 4 ? t("remove") : currentAction == 5 ? t("remove") : currentAction == 6 ? (state.item?.isAccountLockedByDefault) ? t("isAccountUnLocked") : t("isAccountLocked") : "")}
                        </Text>
                        <Text block variant="small">
                            {(currentAction == 1 ? (state.item?.canSignup) ? t("disableSignupDesc") : t("enableSignupDesc") : currentAction == 2 ? (state.item?.canOpenAccount) ? t("disableAbililtyToOpenAccountDesc") : t("enableAbililtyToOpenAccountDesc") : currentAction == 3 ? t("removeOpenAccountConfigDesc") : currentAction == 4 ? t("removeCurrencyPairConfigDesc") : currentAction == 5 ? t("removeExternalEntityConfigDesc") : currentAction == 6 ? (state.item?.isAccountLockedByDefault) ? t("isAccountUnLockedDesc") : t("isAccountLockedDesc") : "")}

                        </Text>
                        {/* This FocusZone allows the user to go between buttons with the arrow keys.
              It's not related to or required for FocusTrapCallout's built-in focus trapping. */}
                        <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                            <Stack reversed tokens={{ childrenGap: 8, padding: "20px 0px 0px 0px" }} horizontal>
                                <DefaultButton autoFocus onClick={toggleisActionConfirmationCalloutVisible}>{t("cancel")}</DefaultButton>

                                <PrimaryButton onClick={() => {
                                    toggleisActionConfirmationCalloutVisible()
                                    if (state.item) {
                                        if (currentAction == 1) {
                                            let req = new UpdateCanSignUpRequest();
                                            req.setId(state.item?.id)
                                            req.setCansignup(!state.item?.canSignup)
                                            promise?.abort();
                                            promise = dispatch(updateCanSignUp({ body: req, headers: getHeaders() }))

                                        } else if (currentAction == 2) {
                                            let req = new UpdateCanOpenAccountRequest();
                                            req.setId(state.item?.id)
                                            req.setCanopenaccount(!state.item?.canOpenAccount)
                                            promise?.abort();
                                            promise = dispatch(updateCanOpenAccount({ body: req, headers: getHeaders() }))

                                        } else if (currentAction == 3) {
                                            let req = new RemoveOpenAccountDefaultConfigurationRequest();
                                            req.setId(state.item?.id)
                                            req.setCurrency(state.openAccountDefaultConfiguration.selected.at(0)?.currencyId)
                                            req.setAccounttype(state.openAccountDefaultConfiguration.selected.at(0)?.accountTypeId)

                                            promise?.abort();
                                            promise = dispatch(removeOpenAccountDefaultConfiguration({ body: req, headers: getHeaders() }))
                                            promise.unwrap().then((e: any) => {
                                                _selection.setAllSelected(false)
                                            })


                                        } else if (currentAction == 4) {
                                            let req = new RemoveCurrencyPairsConfigurationRequest();
                                            req.setId(state.item?.id)
                                            req.setCurrencypair(state.currencyPairsConfiguration.selected.at(0)?.currencyPairId)
                                            promise?.abort();
                                            promise = dispatch(removeCurrencyPairsConfiguration({ body: req, headers: getHeaders() }))
                                            promise.unwrap().then((e: any) => {
                                                _selectionCurrencyPair.setAllSelected(false)
                                            })


                                        } else if (currentAction == 5) {
                                            let req = new RemoveExternalTransfersEntitiesConfigurationRequest();
                                            req.setId(state.item?.id)
                                            req.setGwaccoint(state.externalTransfersEntitiesConfiguration.selected.at(0)?.gwAccountInternalId)
                                            promise?.abort();
                                            promise = dispatch(removeExternalTransfaresEntitesConfiguration({ body: req, headers: getHeaders() }))
                                            promise.unwrap().then((e: any) => {
                                                _selectionExternalEntity.setAllSelected(false)
                                            })


                                        } else if (currentAction == 6) {
                                            let req = new UpdateIsAccountLockedByDefaultRequest();
                                            req.setId(state.item?.id)
                                            req.setIsaccountlockedbydefault(!state.item?.isAccountLockedByDefault)
                                            promise?.abort();
                                            promise = dispatch(updateIsAccountLockedByDefault({ body: req, headers: getHeaders() }))

                                        }
                                    }
                                }} text={(currentAction == 1 ? (state.item?.canSignup) ? t("disableSignup") : t("enableSignup") : currentAction == 2 ? (state.item?.canOpenAccount) ? t("disableAbililtyToOpenAccount") : t("enableAbililtyToOpenAccount") : currentAction == 3 ? t("remove") : currentAction == 4 ? t("remove") : currentAction == 5 ? t("remove") : currentAction == 6 ? (state.item?.isAccountLockedByDefault) ? t("disable") : t("enable") : "")}
                                >

                                </PrimaryButton>
                            </Stack>
                        </FocusZone>
                    </Stack>

                </Callout>
            ) : null}




            {state.isLoading && state.item == undefined ? <ProgressIndicator /> :

                !state.item ? null :
                    <div ref={ref}>
                        <CommandBar
                            items={_selectionItems}
                            farItems={_farOptions}
                        />

                        <Stack tokens={{ childrenGap: 10, padding: "0px 15% 10px 3%" }}>

                            <Separator alignContent="start"><b>{t("clientsDefaultSettings")}</b></Separator>
                            <Text variant={"medium"}>{t("clientsDefaultSettingsDesc")}</Text>

                            <Formik
                                enableReinitialize={true}
                                initialValues={{

                                    role: undefined,
                                    clientType: undefined,

                                }}

                                validationSchema={Yup.object({


                                })}

                                onSubmit={(values, actions) => {
                                    let userTypes = state.userTypes.selected.at(0);
                                    let role = state.roles.selected.at(0);


                                    let r = new UpdateClientDefaultConfigurationRequest();

                                    r.setRole(role?.id);
                                    r.setUsertype(userTypes?.id);
                                    r.setId(req.getId());

                                    promise = dispatch(updateClientDefaultConfiguration({ body: r, headers: getHeaders() }))
                                    promise.unwrap()
                                        .then((res: UpdateClientDefaultConfigurationResponse.AsObject) => {
                                            if (res) {

                                            }
                                            actions.setSubmitting(false)


                                        })
                                        .catch((error: ApiMessage) => {
                                            actions.setSubmitting(false)
                                        })
                                }}

                            >
                                {formkikProps => (
                                    <Form >
                                        <Dialog
                                            hidden={hideDialogClientConfig}
                                            onDismiss={toggleHideDialogClientConfig}
                                            dialogContentProps={showConfirmation}
                                            modalProps={modalProps}
                                        >
                                            <DialogFooter>
                                                <PrimaryButton onClick={(e: any) => { formkikProps.handleSubmit(e); toggleHideDialogClientConfig() }} text={t("confirm")} type="submit" />
                                                <DefaultButton onClick={toggleHideDialogClientConfig} text={t("cancel")} />
                                            </DialogFooter>
                                        </Dialog>
                                        <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 10 }}>
                                            <Stack.Item grow>
                                                <Label disabled={state.isLoading} >{t("subscription")}</Label>
                                                <List
                                                    inputProps={{ placeholder: t("subscription"), required: true }}
                                                    disabled={state.isLoading}
                                                    suggestionsHeaderText={t('subscriptions')}
                                                    isLoading={state.roles.items.length == 0 ? state.roles.isFetching : false}
                                                    isSearching={state.roles.items.length > 0 ? state.roles.isFetching : false}
                                                    moreSuggestionsAvailable={state.roles.hasMore && !state.roles.isFetching}
                                                    suggestions={state.roles.items.length > 0 ? state.roles.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : []}
                                                    onGetMoreResults={() => {
                                                        if (state.roles.items.length > 0 && !state.roles.isFetching) {
                                                            roleReq.setNextto(state.roles.items.at(state.roles.items.length - 1).id)
                                                            getFilterPromise = dispatch(getRoles({ body: roleReq, headers: getHeaders() }))
                                                        }
                                                    }}
                                                    onSuggestionClick={(ev?, item?: any, index?: number) => {
                                                        var u = state.roles.items.findIndex(e => e.id == item.key)
                                                        if (u >= 0) {
                                                            dispatch(setSelectedRole([state.roles.items.at(u)]))
                                                        }
                                                    }}
                                                    isPeoplePicker={false}
                                                    selectedItems={state.roles.selected.length > 0 ? state.roles.selected.map(e => { return { name: e.name, key: e.id } as ITag }) : []}
                                                    onChange={(e) => {
                                                        dispatch(setSelectedRole([]))

                                                    }}
                                                    onEmptyInputFocus={() => {
                                                        dispatch(resetRoles())
                                                        roleReq.setSearch(undefined)
                                                        roleReq.setNextto(0)
                                                        dispatch(setIsFilteredRolesSet(false))
                                                        getFilterPromise?.abort()
                                                        getFilterPromise = dispatch(getRoles({ body: roleReq, headers: getHeaders() }))
                                                        return []
                                                    }}
                                                    onFilterChanged={onRoleFilterChanged}
                                                    endButtons={[
                                                        {
                                                            title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                                            onClick: () => {
                                                                setCurrenctAction(100)

                                                            }
                                                        }]}
                                                />

                                            </Stack.Item>

                                            <Stack.Item grow>
                                                <Label disabled={state.isLoading} >{t("clientType")}</Label>
                                                <List
                                                    inputProps={{ placeholder: t("clientType"), required: true }}
                                                    disabled={state.isLoading}
                                                    suggestionsHeaderText={t('clientTypes')}
                                                    isLoading={state.userTypes.items.length == 0 ? state.userTypes.isFetching : false}
                                                    isSearching={state.userTypes.items.length > 0 ? state.userTypes.isFetching : false}
                                                    moreSuggestionsAvailable={state.userTypes.hasMore && !state.userTypes.isFetching}
                                                    suggestions={state.userTypes.items.length > 0 ? state.userTypes.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : []}
                                                    onGetMoreResults={() => {

                                                        if (state.userTypes.items.length > 0 && !state.userTypes.isFetching) {
                                                            userTypeReq.setNextto(state.userTypes.items.at(state.userTypes.items.length - 1).id)
                                                            getFilterPromise = dispatch(getUserTypes({ body: userTypeReq, headers: getHeaders() }))

                                                        }
                                                    }}
                                                    onSuggestionClick={(ev?, item?: any, index?: number) => {

                                                        var u = state.userTypes.items.findIndex(e => e.id == item.key)
                                                        if (u >= 0) {
                                                            dispatch(setSelectedUserType([state.userTypes.items.at(u)]))
                                                        }
                                                    }}
                                                    isPeoplePicker={false}
                                                    selectedItems={state.userTypes.selected.length > 0 ? state.userTypes.selected.map(e => { return { name: e.name, key: e.id } as ITag }) : []}
                                                    onChange={(e) => {
                                                        dispatch(setSelectedUserType([]))

                                                    }}
                                                    onEmptyInputFocus={() => {
                                                        dispatch(resetUserTypes())
                                                        userTypeReq.setSearch(undefined)
                                                        userTypeReq.setNextto(0)
                                                        dispatch(setIsFilteredUserTypesSet(false))
                                                        getFilterPromise?.abort()
                                                        getFilterPromise = dispatch(getUserTypes({ body: userTypeReq, headers: getHeaders() }))
                                                        return []
                                                    }}
                                                    onFilterChanged={onUserTypeFilterChanged}
                                                    endButtons={[
                                                        {
                                                            title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                                            onClick: () => {
                                                                setCurrenctAction(101)

                                                            }
                                                        }]}
                                                />


                                            </Stack.Item>
                                        </Stack>

                                        {state.item?.clientDefaultRoleId != state.roles?.selected?.at(0)?.id || state.item?.clientDefaultUserTypeId != state.userTypes?.selected?.at(0)?.id ? <DialogFooter>
                                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : t("save")} onClick={() => {
                                                dispatch(resetMessage())
                                                setShowConfirmation({
                                                    type: DialogType.normal,
                                                    title: t("confirm"),
                                                    subText: t("confirmUpdate"),
                                                })
                                                toggleHideDialogClientConfig();

                                            }} >

                                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                                            </PrimaryButton>

                                        </DialogFooter> : undefined}


                                    </Form>
                                )}
                            </Formik>

                            <Separator alignContent="start"><b>{t("organizationURLs")}</b></Separator>
                            <Text variant={"medium"}>{t("organizationURLsDesc")}</Text>

                            <Formik
                                enableReinitialize={true}
                                initialValues={{

                                    privacyPolicy: state.item?.privacyPolicy,
                                    terms: state.item?.terms,
                                    support: state.item?.support,

                                }}

                                validationSchema={Yup.object({
                                    privacyPolicy: inputs.url,
                                    terms: inputs.url,
                                    support: inputs.url,

                                })}

                                onSubmit={(values, actions) => {

                                    let r = new UpdateURLsRequest();

                                    r.setPrivacypolicy(values.privacyPolicy);
                                    r.setSupport(values.support);

                                    r.setTerms(values.terms);

                                    r.setId(req.getId());

                                    promise = dispatch(updateURLs({ body: r, headers: getHeaders() }))
                                    promise.unwrap()
                                        .then((res: UpdateURLsResponse.AsObject) => {
                                            if (res) {

                                            }
                                            actions.setSubmitting(false)


                                        })
                                        .catch((error: ApiMessage) => {
                                            actions.setSubmitting(false)
                                        })
                                }}

                            >
                                {formkikProps => (
                                    <Form >
                                        <Dialog
                                            hidden={hideDialogOrgURLs}
                                            onDismiss={toggleHideDialogOrgURLs}
                                            dialogContentProps={showConfirmation}
                                            modalProps={modalProps}
                                        >
                                            <DialogFooter>
                                                <PrimaryButton onClick={(e: any) => { formkikProps.handleSubmit(e); toggleHideDialogOrgURLs() }} text={t("confirm")} type="submit" />
                                                <DefaultButton onClick={toggleHideDialogOrgURLs} text={t("cancel")} />
                                            </DialogFooter>
                                        </Dialog>
                                        <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 10 }}>
                                            <Stack grow >

                                                <Field dir="ltr" name="privacyPolicy" label={t("privacyPolicy")} placeholder={"https://sarafa.ly/privacyPolicy"} component={InputField} resizable={false} disabled={state.isLoading} />
                                            </Stack>
                                            <Stack grow>
                                                <Field dir="ltr" name="terms" label={t("terms")} placeholder={"https://sarafa.ly/terms"} component={InputField} resizable={false} disabled={state.isLoading} />
                                            </Stack>
                                            <Stack grow>
                                                <Field dir="ltr" name="support" label={t("support")} placeholder={"https://sarafa.ly/support"} component={InputField} resizable={false} disabled={state.isLoading} />
                                            </Stack>
                                        </Stack>
                                        {state.item?.privacyPolicy?.trim() != formkikProps.values?.privacyPolicy?.trim() ||
                                            state.item?.terms?.trim() != formkikProps.values?.terms?.trim() ||
                                            state.item?.support?.trim() != formkikProps.values?.support?.trim() ? <DialogFooter>
                                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : t("save")} onClick={() => {
                                                dispatch(resetMessage())
                                                setShowConfirmation({
                                                    type: DialogType.normal,
                                                    title: t("confirm"),
                                                    subText: t("confirmUpdate"),
                                                })
                                                toggleHideDialogOrgURLs();

                                            }} >

                                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                                            </PrimaryButton>

                                        </DialogFooter> : undefined}


                                    </Form>
                                )}
                            </Formik>


                            <Separator alignContent="start"><b>{t("newAccountsSettings")}</b></Separator>
                            <Text variant={"medium"}>{t("newAccountsSettingsDesc")}</Text>

                            <CommandBar
                                items={state.openAccountDefaultConfiguration.selected.length == 0 ? _mainOpenAccountOptions : _selectionOpenAccountItems}
                            />
                            <Table
                                setKey="settings"
                                items={state.openAccountDefaultConfiguration.items}
                                height={"40vh"}
                                columns={c}
                                compRef={ref}
                                checkboxVisibility={CheckboxVisibility.onHover}

                                selection={_selection}
                                selectionMode={SelectionMode.single}
                                disableSelectionZone={state.isChangeStateLoading}
                                layoutMode={DetailsListLayoutMode.justified}
                                isColumnSortingEnabled={true}

                            />

                            <Separator alignContent="start"><b>{t("newCurrencyPairsSettings")}</b></Separator>
                            <Text variant={"medium"}>{t("newCurrencyPairsSettingsDesc")}</Text>

                            <CommandBar
                                items={state.currencyPairsConfiguration.selected.length == 0 ? _mainCurrencyPairsOptions : _selectionCurrencyPairItems}
                            />
                            <Table
                                setKey="currencyPairsSettings"
                                items={state.currencyPairsConfiguration.items}
                                height={"40vh"}
                                columns={cPair}
                                compRef={currencyPairRef}
                                checkboxVisibility={CheckboxVisibility.onHover}

                                selection={_selectionCurrencyPair}
                                selectionMode={SelectionMode.single}
                                disableSelectionZone={state.isChangeStateLoading}
                                layoutMode={DetailsListLayoutMode.justified}
                                isColumnSortingEnabled={true}

                            />

                            <Separator alignContent="start"><b>{t("newExternalEntitySettings")}</b></Separator>
                            <Text variant={"medium"}>{t("newCExternalEntitySettingsDesc")}</Text>

                            <CommandBar
                                items={state.externalTransfersEntitiesConfiguration.selected.length == 0 ? _mainExternalEntitesOptions : _selectionExternalEntitesItems}
                            />
                            <Table
                                setKey="externalTransfersEntitesConfiguration"
                                items={state.externalTransfersEntitiesConfiguration.items}
                                height={"40vh"}
                                columns={externalEntites}
                                compRef={externalEntitesRef}
                                checkboxVisibility={CheckboxVisibility.onHover}

                                selection={_selectionExternalEntity}
                                selectionMode={SelectionMode.single}
                                disableSelectionZone={state.isChangeStateLoading}
                                layoutMode={DetailsListLayoutMode.justified}
                                isColumnSortingEnabled={true}

                            />

                        </Stack>
                    </div>

            }
        </Stack >
    );
}



