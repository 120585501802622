/**
 * @fileoverview gRPC-Web generated client stub for treasury_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as treasury_pb from './treasury_pb';


export class TreasuryEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAdd = new grpcWeb.MethodDescriptor(
    '/treasury_endpoint.TreasuryEndpoint/Add',
    grpcWeb.MethodType.UNARY,
    treasury_pb.AddTreasuryRequest,
    treasury_pb.AddTreasuryResponse,
    (request: treasury_pb.AddTreasuryRequest) => {
      return request.serializeBinary();
    },
    treasury_pb.AddTreasuryResponse.deserializeBinary
  );

  add(
    request: treasury_pb.AddTreasuryRequest,
    metadata: grpcWeb.Metadata | null): Promise<treasury_pb.AddTreasuryResponse>;

  add(
    request: treasury_pb.AddTreasuryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: treasury_pb.AddTreasuryResponse) => void): grpcWeb.ClientReadableStream<treasury_pb.AddTreasuryResponse>;

  add(
    request: treasury_pb.AddTreasuryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: treasury_pb.AddTreasuryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/treasury_endpoint.TreasuryEndpoint/Add',
        request,
        metadata || {},
        this.methodDescriptorAdd,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/treasury_endpoint.TreasuryEndpoint/Add',
    request,
    metadata || {},
    this.methodDescriptorAdd);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/treasury_endpoint.TreasuryEndpoint/Update',
    grpcWeb.MethodType.UNARY,
    treasury_pb.UpdateTreasuryRequest,
    treasury_pb.UpdateTreasuryResponse,
    (request: treasury_pb.UpdateTreasuryRequest) => {
      return request.serializeBinary();
    },
    treasury_pb.UpdateTreasuryResponse.deserializeBinary
  );

  update(
    request: treasury_pb.UpdateTreasuryRequest,
    metadata: grpcWeb.Metadata | null): Promise<treasury_pb.UpdateTreasuryResponse>;

  update(
    request: treasury_pb.UpdateTreasuryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: treasury_pb.UpdateTreasuryResponse) => void): grpcWeb.ClientReadableStream<treasury_pb.UpdateTreasuryResponse>;

  update(
    request: treasury_pb.UpdateTreasuryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: treasury_pb.UpdateTreasuryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/treasury_endpoint.TreasuryEndpoint/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/treasury_endpoint.TreasuryEndpoint/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorUpdateType = new grpcWeb.MethodDescriptor(
    '/treasury_endpoint.TreasuryEndpoint/UpdateType',
    grpcWeb.MethodType.UNARY,
    treasury_pb.UpdateTreasuryTypeRequest,
    treasury_pb.UpdateTreasuryTypeResponse,
    (request: treasury_pb.UpdateTreasuryTypeRequest) => {
      return request.serializeBinary();
    },
    treasury_pb.UpdateTreasuryTypeResponse.deserializeBinary
  );

  updateType(
    request: treasury_pb.UpdateTreasuryTypeRequest,
    metadata: grpcWeb.Metadata | null): Promise<treasury_pb.UpdateTreasuryTypeResponse>;

  updateType(
    request: treasury_pb.UpdateTreasuryTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: treasury_pb.UpdateTreasuryTypeResponse) => void): grpcWeb.ClientReadableStream<treasury_pb.UpdateTreasuryTypeResponse>;

  updateType(
    request: treasury_pb.UpdateTreasuryTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: treasury_pb.UpdateTreasuryTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/treasury_endpoint.TreasuryEndpoint/UpdateType',
        request,
        metadata || {},
        this.methodDescriptorUpdateType,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/treasury_endpoint.TreasuryEndpoint/UpdateType',
    request,
    metadata || {},
    this.methodDescriptorUpdateType);
  }

  methodDescriptorUpdateLimitation = new grpcWeb.MethodDescriptor(
    '/treasury_endpoint.TreasuryEndpoint/UpdateLimitation',
    grpcWeb.MethodType.UNARY,
    treasury_pb.UpdateTreasuryLimitationRequest,
    treasury_pb.UpdateTreasuryLimitationResponse,
    (request: treasury_pb.UpdateTreasuryLimitationRequest) => {
      return request.serializeBinary();
    },
    treasury_pb.UpdateTreasuryLimitationResponse.deserializeBinary
  );

  updateLimitation(
    request: treasury_pb.UpdateTreasuryLimitationRequest,
    metadata: grpcWeb.Metadata | null): Promise<treasury_pb.UpdateTreasuryLimitationResponse>;

  updateLimitation(
    request: treasury_pb.UpdateTreasuryLimitationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: treasury_pb.UpdateTreasuryLimitationResponse) => void): grpcWeb.ClientReadableStream<treasury_pb.UpdateTreasuryLimitationResponse>;

  updateLimitation(
    request: treasury_pb.UpdateTreasuryLimitationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: treasury_pb.UpdateTreasuryLimitationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/treasury_endpoint.TreasuryEndpoint/UpdateLimitation',
        request,
        metadata || {},
        this.methodDescriptorUpdateLimitation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/treasury_endpoint.TreasuryEndpoint/UpdateLimitation',
    request,
    metadata || {},
    this.methodDescriptorUpdateLimitation);
  }

  methodDescriptorUpdateStatus = new grpcWeb.MethodDescriptor(
    '/treasury_endpoint.TreasuryEndpoint/UpdateStatus',
    grpcWeb.MethodType.UNARY,
    treasury_pb.UpdateTreasuryStatusRequest,
    treasury_pb.UpdateTreasuryStatusResponse,
    (request: treasury_pb.UpdateTreasuryStatusRequest) => {
      return request.serializeBinary();
    },
    treasury_pb.UpdateTreasuryStatusResponse.deserializeBinary
  );

  updateStatus(
    request: treasury_pb.UpdateTreasuryStatusRequest,
    metadata: grpcWeb.Metadata | null): Promise<treasury_pb.UpdateTreasuryStatusResponse>;

  updateStatus(
    request: treasury_pb.UpdateTreasuryStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: treasury_pb.UpdateTreasuryStatusResponse) => void): grpcWeb.ClientReadableStream<treasury_pb.UpdateTreasuryStatusResponse>;

  updateStatus(
    request: treasury_pb.UpdateTreasuryStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: treasury_pb.UpdateTreasuryStatusResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/treasury_endpoint.TreasuryEndpoint/UpdateStatus',
        request,
        metadata || {},
        this.methodDescriptorUpdateStatus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/treasury_endpoint.TreasuryEndpoint/UpdateStatus',
    request,
    metadata || {},
    this.methodDescriptorUpdateStatus);
  }

  methodDescriptorClose = new grpcWeb.MethodDescriptor(
    '/treasury_endpoint.TreasuryEndpoint/Close',
    grpcWeb.MethodType.UNARY,
    treasury_pb.CloseTreasuryRequest,
    treasury_pb.CloseTreasuryResponse,
    (request: treasury_pb.CloseTreasuryRequest) => {
      return request.serializeBinary();
    },
    treasury_pb.CloseTreasuryResponse.deserializeBinary
  );

  close(
    request: treasury_pb.CloseTreasuryRequest,
    metadata: grpcWeb.Metadata | null): Promise<treasury_pb.CloseTreasuryResponse>;

  close(
    request: treasury_pb.CloseTreasuryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: treasury_pb.CloseTreasuryResponse) => void): grpcWeb.ClientReadableStream<treasury_pb.CloseTreasuryResponse>;

  close(
    request: treasury_pb.CloseTreasuryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: treasury_pb.CloseTreasuryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/treasury_endpoint.TreasuryEndpoint/Close',
        request,
        metadata || {},
        this.methodDescriptorClose,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/treasury_endpoint.TreasuryEndpoint/Close',
    request,
    metadata || {},
    this.methodDescriptorClose);
  }

  methodDescriptorGetTreasury = new grpcWeb.MethodDescriptor(
    '/treasury_endpoint.TreasuryEndpoint/GetTreasury',
    grpcWeb.MethodType.UNARY,
    treasury_pb.GetTreasuryRequest,
    treasury_pb.GetTreasuryResponse,
    (request: treasury_pb.GetTreasuryRequest) => {
      return request.serializeBinary();
    },
    treasury_pb.GetTreasuryResponse.deserializeBinary
  );

  getTreasury(
    request: treasury_pb.GetTreasuryRequest,
    metadata: grpcWeb.Metadata | null): Promise<treasury_pb.GetTreasuryResponse>;

  getTreasury(
    request: treasury_pb.GetTreasuryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: treasury_pb.GetTreasuryResponse) => void): grpcWeb.ClientReadableStream<treasury_pb.GetTreasuryResponse>;

  getTreasury(
    request: treasury_pb.GetTreasuryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: treasury_pb.GetTreasuryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/treasury_endpoint.TreasuryEndpoint/GetTreasury',
        request,
        metadata || {},
        this.methodDescriptorGetTreasury,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/treasury_endpoint.TreasuryEndpoint/GetTreasury',
    request,
    metadata || {},
    this.methodDescriptorGetTreasury);
  }

  methodDescriptorGetTreasuries = new grpcWeb.MethodDescriptor(
    '/treasury_endpoint.TreasuryEndpoint/GetTreasuries',
    grpcWeb.MethodType.UNARY,
    treasury_pb.GetTreasuriesRequest,
    treasury_pb.GetTreasuriesResponse,
    (request: treasury_pb.GetTreasuriesRequest) => {
      return request.serializeBinary();
    },
    treasury_pb.GetTreasuriesResponse.deserializeBinary
  );

  getTreasuries(
    request: treasury_pb.GetTreasuriesRequest,
    metadata: grpcWeb.Metadata | null): Promise<treasury_pb.GetTreasuriesResponse>;

  getTreasuries(
    request: treasury_pb.GetTreasuriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: treasury_pb.GetTreasuriesResponse) => void): grpcWeb.ClientReadableStream<treasury_pb.GetTreasuriesResponse>;

  getTreasuries(
    request: treasury_pb.GetTreasuriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: treasury_pb.GetTreasuriesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/treasury_endpoint.TreasuryEndpoint/GetTreasuries',
        request,
        metadata || {},
        this.methodDescriptorGetTreasuries,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/treasury_endpoint.TreasuryEndpoint/GetTreasuries',
    request,
    metadata || {},
    this.methodDescriptorGetTreasuries);
  }

  methodDescriptorAppendCurrency = new grpcWeb.MethodDescriptor(
    '/treasury_endpoint.TreasuryEndpoint/AppendCurrency',
    grpcWeb.MethodType.UNARY,
    treasury_pb.AppendTreasuryCurrencyRequest,
    treasury_pb.AppendTreasuryCurrencyResponse,
    (request: treasury_pb.AppendTreasuryCurrencyRequest) => {
      return request.serializeBinary();
    },
    treasury_pb.AppendTreasuryCurrencyResponse.deserializeBinary
  );

  appendCurrency(
    request: treasury_pb.AppendTreasuryCurrencyRequest,
    metadata: grpcWeb.Metadata | null): Promise<treasury_pb.AppendTreasuryCurrencyResponse>;

  appendCurrency(
    request: treasury_pb.AppendTreasuryCurrencyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: treasury_pb.AppendTreasuryCurrencyResponse) => void): grpcWeb.ClientReadableStream<treasury_pb.AppendTreasuryCurrencyResponse>;

  appendCurrency(
    request: treasury_pb.AppendTreasuryCurrencyRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: treasury_pb.AppendTreasuryCurrencyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/treasury_endpoint.TreasuryEndpoint/AppendCurrency',
        request,
        metadata || {},
        this.methodDescriptorAppendCurrency,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/treasury_endpoint.TreasuryEndpoint/AppendCurrency',
    request,
    metadata || {},
    this.methodDescriptorAppendCurrency);
  }

  methodDescriptorUpdateCurrencyActivity = new grpcWeb.MethodDescriptor(
    '/treasury_endpoint.TreasuryEndpoint/UpdateCurrencyActivity',
    grpcWeb.MethodType.UNARY,
    treasury_pb.UpdateTreasuryCurrencyActivityRequest,
    treasury_pb.UpdateTreasuryCurrencyActivityResponse,
    (request: treasury_pb.UpdateTreasuryCurrencyActivityRequest) => {
      return request.serializeBinary();
    },
    treasury_pb.UpdateTreasuryCurrencyActivityResponse.deserializeBinary
  );

  updateCurrencyActivity(
    request: treasury_pb.UpdateTreasuryCurrencyActivityRequest,
    metadata: grpcWeb.Metadata | null): Promise<treasury_pb.UpdateTreasuryCurrencyActivityResponse>;

  updateCurrencyActivity(
    request: treasury_pb.UpdateTreasuryCurrencyActivityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: treasury_pb.UpdateTreasuryCurrencyActivityResponse) => void): grpcWeb.ClientReadableStream<treasury_pb.UpdateTreasuryCurrencyActivityResponse>;

  updateCurrencyActivity(
    request: treasury_pb.UpdateTreasuryCurrencyActivityRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: treasury_pb.UpdateTreasuryCurrencyActivityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/treasury_endpoint.TreasuryEndpoint/UpdateCurrencyActivity',
        request,
        metadata || {},
        this.methodDescriptorUpdateCurrencyActivity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/treasury_endpoint.TreasuryEndpoint/UpdateCurrencyActivity',
    request,
    metadata || {},
    this.methodDescriptorUpdateCurrencyActivity);
  }

}

