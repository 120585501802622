// source: limitation.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('./common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.limitation_endpoint.AddLimitationReplay', null, global);
goog.exportSymbol('proto.limitation_endpoint.AddLimitationRequest', null, global);
goog.exportSymbol('proto.limitation_endpoint.AddLimitationResponse', null, global);
goog.exportSymbol('proto.limitation_endpoint.AddLimitationResponse.ResultCase', null, global);
goog.exportSymbol('proto.limitation_endpoint.DeleteLimitationReplay', null, global);
goog.exportSymbol('proto.limitation_endpoint.DeleteLimitationRequest', null, global);
goog.exportSymbol('proto.limitation_endpoint.DeleteLimitationResponse', null, global);
goog.exportSymbol('proto.limitation_endpoint.DeleteLimitationResponse.ResultCase', null, global);
goog.exportSymbol('proto.limitation_endpoint.GetLimitationReplay', null, global);
goog.exportSymbol('proto.limitation_endpoint.GetLimitationRequest', null, global);
goog.exportSymbol('proto.limitation_endpoint.GetLimitationResponse', null, global);
goog.exportSymbol('proto.limitation_endpoint.GetLimitationResponse.ResultCase', null, global);
goog.exportSymbol('proto.limitation_endpoint.GetLimitationsReplay', null, global);
goog.exportSymbol('proto.limitation_endpoint.GetLimitationsRequest', null, global);
goog.exportSymbol('proto.limitation_endpoint.GetLimitationsResponse', null, global);
goog.exportSymbol('proto.limitation_endpoint.GetLimitationsResponse.ResultCase', null, global);
goog.exportSymbol('proto.limitation_endpoint.Limitation', null, global);
goog.exportSymbol('proto.limitation_endpoint.TimePeriodLimitation', null, global);
goog.exportSymbol('proto.limitation_endpoint.TimePeriodLimitationReplay', null, global);
goog.exportSymbol('proto.limitation_endpoint.TransactionLimitation', null, global);
goog.exportSymbol('proto.limitation_endpoint.TransactionLimitationReplay', null, global);
goog.exportSymbol('proto.limitation_endpoint.UpdateLimitationActivityReplay', null, global);
goog.exportSymbol('proto.limitation_endpoint.UpdateLimitationActivityRequest', null, global);
goog.exportSymbol('proto.limitation_endpoint.UpdateLimitationActivityResponse', null, global);
goog.exportSymbol('proto.limitation_endpoint.UpdateLimitationActivityResponse.ResultCase', null, global);
goog.exportSymbol('proto.limitation_endpoint.UpdateLimitationReplay', null, global);
goog.exportSymbol('proto.limitation_endpoint.UpdateLimitationRequest', null, global);
goog.exportSymbol('proto.limitation_endpoint.UpdateLimitationResponse', null, global);
goog.exportSymbol('proto.limitation_endpoint.UpdateLimitationResponse.ResultCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.limitation_endpoint.TimePeriodLimitation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.limitation_endpoint.TimePeriodLimitation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.limitation_endpoint.TimePeriodLimitation.displayName = 'proto.limitation_endpoint.TimePeriodLimitation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.limitation_endpoint.TransactionLimitation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.limitation_endpoint.TransactionLimitation.repeatedFields_, null);
};
goog.inherits(proto.limitation_endpoint.TransactionLimitation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.limitation_endpoint.TransactionLimitation.displayName = 'proto.limitation_endpoint.TransactionLimitation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.limitation_endpoint.AddLimitationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.limitation_endpoint.AddLimitationRequest.repeatedFields_, null);
};
goog.inherits(proto.limitation_endpoint.AddLimitationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.limitation_endpoint.AddLimitationRequest.displayName = 'proto.limitation_endpoint.AddLimitationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.limitation_endpoint.UpdateLimitationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.limitation_endpoint.UpdateLimitationRequest.repeatedFields_, null);
};
goog.inherits(proto.limitation_endpoint.UpdateLimitationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.limitation_endpoint.UpdateLimitationRequest.displayName = 'proto.limitation_endpoint.UpdateLimitationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.limitation_endpoint.UpdateLimitationActivityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.limitation_endpoint.UpdateLimitationActivityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.limitation_endpoint.UpdateLimitationActivityRequest.displayName = 'proto.limitation_endpoint.UpdateLimitationActivityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.limitation_endpoint.DeleteLimitationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.limitation_endpoint.DeleteLimitationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.limitation_endpoint.DeleteLimitationRequest.displayName = 'proto.limitation_endpoint.DeleteLimitationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.limitation_endpoint.GetLimitationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.limitation_endpoint.GetLimitationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.limitation_endpoint.GetLimitationRequest.displayName = 'proto.limitation_endpoint.GetLimitationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.limitation_endpoint.GetLimitationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.limitation_endpoint.GetLimitationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.limitation_endpoint.GetLimitationsRequest.displayName = 'proto.limitation_endpoint.GetLimitationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.limitation_endpoint.AddLimitationReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.limitation_endpoint.AddLimitationReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.limitation_endpoint.AddLimitationReplay.displayName = 'proto.limitation_endpoint.AddLimitationReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.limitation_endpoint.UpdateLimitationReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.limitation_endpoint.UpdateLimitationReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.limitation_endpoint.UpdateLimitationReplay.displayName = 'proto.limitation_endpoint.UpdateLimitationReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.limitation_endpoint.UpdateLimitationActivityReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.limitation_endpoint.UpdateLimitationActivityReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.limitation_endpoint.UpdateLimitationActivityReplay.displayName = 'proto.limitation_endpoint.UpdateLimitationActivityReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.limitation_endpoint.DeleteLimitationReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.limitation_endpoint.DeleteLimitationReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.limitation_endpoint.DeleteLimitationReplay.displayName = 'proto.limitation_endpoint.DeleteLimitationReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.limitation_endpoint.GetLimitationReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.limitation_endpoint.GetLimitationReplay.repeatedFields_, null);
};
goog.inherits(proto.limitation_endpoint.GetLimitationReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.limitation_endpoint.GetLimitationReplay.displayName = 'proto.limitation_endpoint.GetLimitationReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.limitation_endpoint.TimePeriodLimitationReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.limitation_endpoint.TimePeriodLimitationReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.limitation_endpoint.TimePeriodLimitationReplay.displayName = 'proto.limitation_endpoint.TimePeriodLimitationReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.limitation_endpoint.TransactionLimitationReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.limitation_endpoint.TransactionLimitationReplay.repeatedFields_, null);
};
goog.inherits(proto.limitation_endpoint.TransactionLimitationReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.limitation_endpoint.TransactionLimitationReplay.displayName = 'proto.limitation_endpoint.TransactionLimitationReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.limitation_endpoint.Limitation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.limitation_endpoint.Limitation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.limitation_endpoint.Limitation.displayName = 'proto.limitation_endpoint.Limitation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.limitation_endpoint.GetLimitationsReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.limitation_endpoint.GetLimitationsReplay.repeatedFields_, null);
};
goog.inherits(proto.limitation_endpoint.GetLimitationsReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.limitation_endpoint.GetLimitationsReplay.displayName = 'proto.limitation_endpoint.GetLimitationsReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.limitation_endpoint.AddLimitationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.limitation_endpoint.AddLimitationResponse.oneofGroups_);
};
goog.inherits(proto.limitation_endpoint.AddLimitationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.limitation_endpoint.AddLimitationResponse.displayName = 'proto.limitation_endpoint.AddLimitationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.limitation_endpoint.UpdateLimitationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.limitation_endpoint.UpdateLimitationResponse.oneofGroups_);
};
goog.inherits(proto.limitation_endpoint.UpdateLimitationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.limitation_endpoint.UpdateLimitationResponse.displayName = 'proto.limitation_endpoint.UpdateLimitationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.limitation_endpoint.UpdateLimitationActivityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.limitation_endpoint.UpdateLimitationActivityResponse.oneofGroups_);
};
goog.inherits(proto.limitation_endpoint.UpdateLimitationActivityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.limitation_endpoint.UpdateLimitationActivityResponse.displayName = 'proto.limitation_endpoint.UpdateLimitationActivityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.limitation_endpoint.DeleteLimitationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.limitation_endpoint.DeleteLimitationResponse.oneofGroups_);
};
goog.inherits(proto.limitation_endpoint.DeleteLimitationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.limitation_endpoint.DeleteLimitationResponse.displayName = 'proto.limitation_endpoint.DeleteLimitationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.limitation_endpoint.GetLimitationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.limitation_endpoint.GetLimitationResponse.oneofGroups_);
};
goog.inherits(proto.limitation_endpoint.GetLimitationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.limitation_endpoint.GetLimitationResponse.displayName = 'proto.limitation_endpoint.GetLimitationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.limitation_endpoint.GetLimitationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.limitation_endpoint.GetLimitationsResponse.oneofGroups_);
};
goog.inherits(proto.limitation_endpoint.GetLimitationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.limitation_endpoint.GetLimitationsResponse.displayName = 'proto.limitation_endpoint.GetLimitationsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.limitation_endpoint.TimePeriodLimitation.prototype.toObject = function(opt_includeInstance) {
  return proto.limitation_endpoint.TimePeriodLimitation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.limitation_endpoint.TimePeriodLimitation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.TimePeriodLimitation.toObject = function(includeInstance, msg) {
  var f, obj = {
    seconds: jspb.Message.getFieldWithDefault(msg, 1, 0),
    maxnumberoftransactions: jspb.Message.getFieldWithDefault(msg, 2, 0),
    maxtransactionstotalamount: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.limitation_endpoint.TimePeriodLimitation}
 */
proto.limitation_endpoint.TimePeriodLimitation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.limitation_endpoint.TimePeriodLimitation;
  return proto.limitation_endpoint.TimePeriodLimitation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.limitation_endpoint.TimePeriodLimitation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.limitation_endpoint.TimePeriodLimitation}
 */
proto.limitation_endpoint.TimePeriodLimitation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeconds(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxnumberoftransactions(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaxtransactionstotalamount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.limitation_endpoint.TimePeriodLimitation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.limitation_endpoint.TimePeriodLimitation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.limitation_endpoint.TimePeriodLimitation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.TimePeriodLimitation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeconds();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMaxnumberoftransactions();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMaxtransactionstotalamount();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 seconds = 1;
 * @return {number}
 */
proto.limitation_endpoint.TimePeriodLimitation.prototype.getSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.limitation_endpoint.TimePeriodLimitation} returns this
 */
proto.limitation_endpoint.TimePeriodLimitation.prototype.setSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 maxNumberOfTransactions = 2;
 * @return {number}
 */
proto.limitation_endpoint.TimePeriodLimitation.prototype.getMaxnumberoftransactions = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.limitation_endpoint.TimePeriodLimitation} returns this
 */
proto.limitation_endpoint.TimePeriodLimitation.prototype.setMaxnumberoftransactions = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string maxTransactionsTotalAmount = 3;
 * @return {string}
 */
proto.limitation_endpoint.TimePeriodLimitation.prototype.getMaxtransactionstotalamount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.limitation_endpoint.TimePeriodLimitation} returns this
 */
proto.limitation_endpoint.TimePeriodLimitation.prototype.setMaxtransactionstotalamount = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.limitation_endpoint.TransactionLimitation.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.limitation_endpoint.TransactionLimitation.prototype.toObject = function(opt_includeInstance) {
  return proto.limitation_endpoint.TransactionLimitation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.limitation_endpoint.TransactionLimitation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.TransactionLimitation.toObject = function(includeInstance, msg) {
  var f, obj = {
    operation: jspb.Message.getFieldWithDefault(msg, 1, 0),
    iscreditor: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    maxamountvaluepertransaction: jspb.Message.getFieldWithDefault(msg, 3, ""),
    minamountvaluepertransaction: jspb.Message.getFieldWithDefault(msg, 4, ""),
    timeperiodlimitationsList: jspb.Message.toObjectList(msg.getTimeperiodlimitationsList(),
    proto.limitation_endpoint.TimePeriodLimitation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.limitation_endpoint.TransactionLimitation}
 */
proto.limitation_endpoint.TransactionLimitation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.limitation_endpoint.TransactionLimitation;
  return proto.limitation_endpoint.TransactionLimitation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.limitation_endpoint.TransactionLimitation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.limitation_endpoint.TransactionLimitation}
 */
proto.limitation_endpoint.TransactionLimitation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOperation(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIscreditor(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaxamountvaluepertransaction(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMinamountvaluepertransaction(value);
      break;
    case 5:
      var value = new proto.limitation_endpoint.TimePeriodLimitation;
      reader.readMessage(value,proto.limitation_endpoint.TimePeriodLimitation.deserializeBinaryFromReader);
      msg.addTimeperiodlimitations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.limitation_endpoint.TransactionLimitation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.limitation_endpoint.TransactionLimitation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.limitation_endpoint.TransactionLimitation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.TransactionLimitation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperation();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getIscreditor();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getMaxamountvaluepertransaction();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMinamountvaluepertransaction();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTimeperiodlimitationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.limitation_endpoint.TimePeriodLimitation.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 operation = 1;
 * @return {number}
 */
proto.limitation_endpoint.TransactionLimitation.prototype.getOperation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.limitation_endpoint.TransactionLimitation} returns this
 */
proto.limitation_endpoint.TransactionLimitation.prototype.setOperation = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool isCreditor = 2;
 * @return {boolean}
 */
proto.limitation_endpoint.TransactionLimitation.prototype.getIscreditor = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.limitation_endpoint.TransactionLimitation} returns this
 */
proto.limitation_endpoint.TransactionLimitation.prototype.setIscreditor = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string maxAmountValuePerTransaction = 3;
 * @return {string}
 */
proto.limitation_endpoint.TransactionLimitation.prototype.getMaxamountvaluepertransaction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.limitation_endpoint.TransactionLimitation} returns this
 */
proto.limitation_endpoint.TransactionLimitation.prototype.setMaxamountvaluepertransaction = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string minAmountValuePerTransaction = 4;
 * @return {string}
 */
proto.limitation_endpoint.TransactionLimitation.prototype.getMinamountvaluepertransaction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.limitation_endpoint.TransactionLimitation} returns this
 */
proto.limitation_endpoint.TransactionLimitation.prototype.setMinamountvaluepertransaction = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated TimePeriodLimitation timePeriodLimitations = 5;
 * @return {!Array<!proto.limitation_endpoint.TimePeriodLimitation>}
 */
proto.limitation_endpoint.TransactionLimitation.prototype.getTimeperiodlimitationsList = function() {
  return /** @type{!Array<!proto.limitation_endpoint.TimePeriodLimitation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.limitation_endpoint.TimePeriodLimitation, 5));
};


/**
 * @param {!Array<!proto.limitation_endpoint.TimePeriodLimitation>} value
 * @return {!proto.limitation_endpoint.TransactionLimitation} returns this
*/
proto.limitation_endpoint.TransactionLimitation.prototype.setTimeperiodlimitationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.limitation_endpoint.TimePeriodLimitation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.limitation_endpoint.TimePeriodLimitation}
 */
proto.limitation_endpoint.TransactionLimitation.prototype.addTimeperiodlimitations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.limitation_endpoint.TimePeriodLimitation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.limitation_endpoint.TransactionLimitation} returns this
 */
proto.limitation_endpoint.TransactionLimitation.prototype.clearTimeperiodlimitationsList = function() {
  return this.setTimeperiodlimitationsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.limitation_endpoint.AddLimitationRequest.repeatedFields_ = [7,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.limitation_endpoint.AddLimitationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.limitation_endpoint.AddLimitationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.limitation_endpoint.AddLimitationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.AddLimitationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    balancemaxlimit: jspb.Message.getFieldWithDefault(msg, 2, ""),
    balanceminlimit: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isavailable: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    isactive: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    type: jspb.Message.getFieldWithDefault(msg, 6, 0),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    transactionlimitationsList: jspb.Message.toObjectList(msg.getTransactionlimitationsList(),
    proto.limitation_endpoint.TransactionLimitation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.limitation_endpoint.AddLimitationRequest}
 */
proto.limitation_endpoint.AddLimitationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.limitation_endpoint.AddLimitationRequest;
  return proto.limitation_endpoint.AddLimitationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.limitation_endpoint.AddLimitationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.limitation_endpoint.AddLimitationRequest}
 */
proto.limitation_endpoint.AddLimitationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBalancemaxlimit(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBalanceminlimit(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsavailable(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsactive(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setType(value);
      break;
    case 7:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 9:
      var value = new proto.limitation_endpoint.TransactionLimitation;
      reader.readMessage(value,proto.limitation_endpoint.TransactionLimitation.deserializeBinaryFromReader);
      msg.addTransactionlimitations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.limitation_endpoint.AddLimitationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.limitation_endpoint.AddLimitationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.limitation_endpoint.AddLimitationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.AddLimitationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBalancemaxlimit();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBalanceminlimit();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsavailable();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIsactive();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getType();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getTransactionlimitationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.limitation_endpoint.TransactionLimitation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.limitation_endpoint.AddLimitationRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.limitation_endpoint.AddLimitationRequest} returns this
 */
proto.limitation_endpoint.AddLimitationRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string balanceMaxLimit = 2;
 * @return {string}
 */
proto.limitation_endpoint.AddLimitationRequest.prototype.getBalancemaxlimit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.limitation_endpoint.AddLimitationRequest} returns this
 */
proto.limitation_endpoint.AddLimitationRequest.prototype.setBalancemaxlimit = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string balanceMinLimit = 3;
 * @return {string}
 */
proto.limitation_endpoint.AddLimitationRequest.prototype.getBalanceminlimit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.limitation_endpoint.AddLimitationRequest} returns this
 */
proto.limitation_endpoint.AddLimitationRequest.prototype.setBalanceminlimit = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool isAvailable = 4;
 * @return {boolean}
 */
proto.limitation_endpoint.AddLimitationRequest.prototype.getIsavailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.limitation_endpoint.AddLimitationRequest} returns this
 */
proto.limitation_endpoint.AddLimitationRequest.prototype.setIsavailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool isActive = 5;
 * @return {boolean}
 */
proto.limitation_endpoint.AddLimitationRequest.prototype.getIsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.limitation_endpoint.AddLimitationRequest} returns this
 */
proto.limitation_endpoint.AddLimitationRequest.prototype.setIsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int32 type = 6;
 * @return {number}
 */
proto.limitation_endpoint.AddLimitationRequest.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.limitation_endpoint.AddLimitationRequest} returns this
 */
proto.limitation_endpoint.AddLimitationRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated common.ExtraField extraFields = 7;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.limitation_endpoint.AddLimitationRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 7));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.limitation_endpoint.AddLimitationRequest} returns this
*/
proto.limitation_endpoint.AddLimitationRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.limitation_endpoint.AddLimitationRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.limitation_endpoint.AddLimitationRequest} returns this
 */
proto.limitation_endpoint.AddLimitationRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};


/**
 * optional google.protobuf.Int32Value organization = 8;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.limitation_endpoint.AddLimitationRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.limitation_endpoint.AddLimitationRequest} returns this
*/
proto.limitation_endpoint.AddLimitationRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.AddLimitationRequest} returns this
 */
proto.limitation_endpoint.AddLimitationRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.AddLimitationRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated TransactionLimitation transactionLimitations = 9;
 * @return {!Array<!proto.limitation_endpoint.TransactionLimitation>}
 */
proto.limitation_endpoint.AddLimitationRequest.prototype.getTransactionlimitationsList = function() {
  return /** @type{!Array<!proto.limitation_endpoint.TransactionLimitation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.limitation_endpoint.TransactionLimitation, 9));
};


/**
 * @param {!Array<!proto.limitation_endpoint.TransactionLimitation>} value
 * @return {!proto.limitation_endpoint.AddLimitationRequest} returns this
*/
proto.limitation_endpoint.AddLimitationRequest.prototype.setTransactionlimitationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.limitation_endpoint.TransactionLimitation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.limitation_endpoint.TransactionLimitation}
 */
proto.limitation_endpoint.AddLimitationRequest.prototype.addTransactionlimitations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.limitation_endpoint.TransactionLimitation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.limitation_endpoint.AddLimitationRequest} returns this
 */
proto.limitation_endpoint.AddLimitationRequest.prototype.clearTransactionlimitationsList = function() {
  return this.setTransactionlimitationsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.limitation_endpoint.UpdateLimitationRequest.repeatedFields_ = [6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.limitation_endpoint.UpdateLimitationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.limitation_endpoint.UpdateLimitationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.limitation_endpoint.UpdateLimitationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.UpdateLimitationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    balancemaxlimit: jspb.Message.getFieldWithDefault(msg, 3, ""),
    balanceminlimit: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isavailable: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance),
    transactionlimitationsList: jspb.Message.toObjectList(msg.getTransactionlimitationsList(),
    proto.limitation_endpoint.TransactionLimitation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.limitation_endpoint.UpdateLimitationRequest}
 */
proto.limitation_endpoint.UpdateLimitationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.limitation_endpoint.UpdateLimitationRequest;
  return proto.limitation_endpoint.UpdateLimitationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.limitation_endpoint.UpdateLimitationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.limitation_endpoint.UpdateLimitationRequest}
 */
proto.limitation_endpoint.UpdateLimitationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBalancemaxlimit(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBalanceminlimit(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsavailable(value);
      break;
    case 6:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    case 7:
      var value = new proto.limitation_endpoint.TransactionLimitation;
      reader.readMessage(value,proto.limitation_endpoint.TransactionLimitation.deserializeBinaryFromReader);
      msg.addTransactionlimitations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.limitation_endpoint.UpdateLimitationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.limitation_endpoint.UpdateLimitationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.limitation_endpoint.UpdateLimitationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.UpdateLimitationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBalancemaxlimit();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBalanceminlimit();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsavailable();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
  f = message.getTransactionlimitationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.limitation_endpoint.TransactionLimitation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.limitation_endpoint.UpdateLimitationRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.limitation_endpoint.UpdateLimitationRequest} returns this
 */
proto.limitation_endpoint.UpdateLimitationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.limitation_endpoint.UpdateLimitationRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.limitation_endpoint.UpdateLimitationRequest} returns this
 */
proto.limitation_endpoint.UpdateLimitationRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string balanceMaxLimit = 3;
 * @return {string}
 */
proto.limitation_endpoint.UpdateLimitationRequest.prototype.getBalancemaxlimit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.limitation_endpoint.UpdateLimitationRequest} returns this
 */
proto.limitation_endpoint.UpdateLimitationRequest.prototype.setBalancemaxlimit = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string balanceMinLimit = 4;
 * @return {string}
 */
proto.limitation_endpoint.UpdateLimitationRequest.prototype.getBalanceminlimit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.limitation_endpoint.UpdateLimitationRequest} returns this
 */
proto.limitation_endpoint.UpdateLimitationRequest.prototype.setBalanceminlimit = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool isAvailable = 5;
 * @return {boolean}
 */
proto.limitation_endpoint.UpdateLimitationRequest.prototype.getIsavailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.limitation_endpoint.UpdateLimitationRequest} returns this
 */
proto.limitation_endpoint.UpdateLimitationRequest.prototype.setIsavailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated common.ExtraField extraFields = 6;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.limitation_endpoint.UpdateLimitationRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 6));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.limitation_endpoint.UpdateLimitationRequest} returns this
*/
proto.limitation_endpoint.UpdateLimitationRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.limitation_endpoint.UpdateLimitationRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.limitation_endpoint.UpdateLimitationRequest} returns this
 */
proto.limitation_endpoint.UpdateLimitationRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};


/**
 * repeated TransactionLimitation transactionLimitations = 7;
 * @return {!Array<!proto.limitation_endpoint.TransactionLimitation>}
 */
proto.limitation_endpoint.UpdateLimitationRequest.prototype.getTransactionlimitationsList = function() {
  return /** @type{!Array<!proto.limitation_endpoint.TransactionLimitation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.limitation_endpoint.TransactionLimitation, 7));
};


/**
 * @param {!Array<!proto.limitation_endpoint.TransactionLimitation>} value
 * @return {!proto.limitation_endpoint.UpdateLimitationRequest} returns this
*/
proto.limitation_endpoint.UpdateLimitationRequest.prototype.setTransactionlimitationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.limitation_endpoint.TransactionLimitation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.limitation_endpoint.TransactionLimitation}
 */
proto.limitation_endpoint.UpdateLimitationRequest.prototype.addTransactionlimitations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.limitation_endpoint.TransactionLimitation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.limitation_endpoint.UpdateLimitationRequest} returns this
 */
proto.limitation_endpoint.UpdateLimitationRequest.prototype.clearTransactionlimitationsList = function() {
  return this.setTransactionlimitationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.limitation_endpoint.UpdateLimitationActivityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.limitation_endpoint.UpdateLimitationActivityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.limitation_endpoint.UpdateLimitationActivityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.UpdateLimitationActivityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isactive: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.limitation_endpoint.UpdateLimitationActivityRequest}
 */
proto.limitation_endpoint.UpdateLimitationActivityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.limitation_endpoint.UpdateLimitationActivityRequest;
  return proto.limitation_endpoint.UpdateLimitationActivityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.limitation_endpoint.UpdateLimitationActivityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.limitation_endpoint.UpdateLimitationActivityRequest}
 */
proto.limitation_endpoint.UpdateLimitationActivityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsactive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.limitation_endpoint.UpdateLimitationActivityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.limitation_endpoint.UpdateLimitationActivityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.limitation_endpoint.UpdateLimitationActivityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.UpdateLimitationActivityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsactive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.limitation_endpoint.UpdateLimitationActivityRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.limitation_endpoint.UpdateLimitationActivityRequest} returns this
 */
proto.limitation_endpoint.UpdateLimitationActivityRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool isActive = 2;
 * @return {boolean}
 */
proto.limitation_endpoint.UpdateLimitationActivityRequest.prototype.getIsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.limitation_endpoint.UpdateLimitationActivityRequest} returns this
 */
proto.limitation_endpoint.UpdateLimitationActivityRequest.prototype.setIsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.limitation_endpoint.DeleteLimitationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.limitation_endpoint.DeleteLimitationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.limitation_endpoint.DeleteLimitationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.DeleteLimitationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.limitation_endpoint.DeleteLimitationRequest}
 */
proto.limitation_endpoint.DeleteLimitationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.limitation_endpoint.DeleteLimitationRequest;
  return proto.limitation_endpoint.DeleteLimitationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.limitation_endpoint.DeleteLimitationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.limitation_endpoint.DeleteLimitationRequest}
 */
proto.limitation_endpoint.DeleteLimitationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.limitation_endpoint.DeleteLimitationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.limitation_endpoint.DeleteLimitationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.limitation_endpoint.DeleteLimitationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.DeleteLimitationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.limitation_endpoint.DeleteLimitationRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.limitation_endpoint.DeleteLimitationRequest} returns this
 */
proto.limitation_endpoint.DeleteLimitationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.limitation_endpoint.GetLimitationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.limitation_endpoint.GetLimitationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.limitation_endpoint.GetLimitationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.GetLimitationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.limitation_endpoint.GetLimitationRequest}
 */
proto.limitation_endpoint.GetLimitationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.limitation_endpoint.GetLimitationRequest;
  return proto.limitation_endpoint.GetLimitationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.limitation_endpoint.GetLimitationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.limitation_endpoint.GetLimitationRequest}
 */
proto.limitation_endpoint.GetLimitationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.limitation_endpoint.GetLimitationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.limitation_endpoint.GetLimitationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.limitation_endpoint.GetLimitationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.GetLimitationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.limitation_endpoint.GetLimitationRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.limitation_endpoint.GetLimitationRequest} returns this
 */
proto.limitation_endpoint.GetLimitationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.limitation_endpoint.GetLimitationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.limitation_endpoint.GetLimitationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.limitation_endpoint.GetLimitationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.GetLimitationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    type: (f = msg.getType()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    isavailable: (f = msg.getIsavailable()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    isactive: (f = msg.getIsactive()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    order: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    nextto: (f = msg.getNextto()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    numofresults: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.limitation_endpoint.GetLimitationsRequest}
 */
proto.limitation_endpoint.GetLimitationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.limitation_endpoint.GetLimitationsRequest;
  return proto.limitation_endpoint.GetLimitationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.limitation_endpoint.GetLimitationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.limitation_endpoint.GetLimitationsRequest}
 */
proto.limitation_endpoint.GetLimitationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setType(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsavailable(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsactive(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrder(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNextto(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumofresults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.limitation_endpoint.GetLimitationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.limitation_endpoint.GetLimitationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.limitation_endpoint.GetLimitationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.GetLimitationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getIsavailable();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIsactive();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getNextto();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNumofresults();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional google.protobuf.Int32Value organization = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.limitation_endpoint.GetLimitationsRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.limitation_endpoint.GetLimitationsRequest} returns this
*/
proto.limitation_endpoint.GetLimitationsRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.GetLimitationsRequest} returns this
 */
proto.limitation_endpoint.GetLimitationsRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.GetLimitationsRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Int32Value type = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.limitation_endpoint.GetLimitationsRequest.prototype.getType = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.limitation_endpoint.GetLimitationsRequest} returns this
*/
proto.limitation_endpoint.GetLimitationsRequest.prototype.setType = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.GetLimitationsRequest} returns this
 */
proto.limitation_endpoint.GetLimitationsRequest.prototype.clearType = function() {
  return this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.GetLimitationsRequest.prototype.hasType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.BoolValue isAvailable = 3;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.limitation_endpoint.GetLimitationsRequest.prototype.getIsavailable = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 3));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.limitation_endpoint.GetLimitationsRequest} returns this
*/
proto.limitation_endpoint.GetLimitationsRequest.prototype.setIsavailable = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.GetLimitationsRequest} returns this
 */
proto.limitation_endpoint.GetLimitationsRequest.prototype.clearIsavailable = function() {
  return this.setIsavailable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.GetLimitationsRequest.prototype.hasIsavailable = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.BoolValue isActive = 4;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.limitation_endpoint.GetLimitationsRequest.prototype.getIsactive = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 4));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.limitation_endpoint.GetLimitationsRequest} returns this
*/
proto.limitation_endpoint.GetLimitationsRequest.prototype.setIsactive = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.GetLimitationsRequest} returns this
 */
proto.limitation_endpoint.GetLimitationsRequest.prototype.clearIsactive = function() {
  return this.setIsactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.GetLimitationsRequest.prototype.hasIsactive = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue search = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.limitation_endpoint.GetLimitationsRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.limitation_endpoint.GetLimitationsRequest} returns this
*/
proto.limitation_endpoint.GetLimitationsRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.GetLimitationsRequest} returns this
 */
proto.limitation_endpoint.GetLimitationsRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.GetLimitationsRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool order = 6;
 * @return {boolean}
 */
proto.limitation_endpoint.GetLimitationsRequest.prototype.getOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.limitation_endpoint.GetLimitationsRequest} returns this
 */
proto.limitation_endpoint.GetLimitationsRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional google.protobuf.StringValue nextTo = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.limitation_endpoint.GetLimitationsRequest.prototype.getNextto = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.limitation_endpoint.GetLimitationsRequest} returns this
*/
proto.limitation_endpoint.GetLimitationsRequest.prototype.setNextto = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.GetLimitationsRequest} returns this
 */
proto.limitation_endpoint.GetLimitationsRequest.prototype.clearNextto = function() {
  return this.setNextto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.GetLimitationsRequest.prototype.hasNextto = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int32 numOfResults = 8;
 * @return {number}
 */
proto.limitation_endpoint.GetLimitationsRequest.prototype.getNumofresults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.limitation_endpoint.GetLimitationsRequest} returns this
 */
proto.limitation_endpoint.GetLimitationsRequest.prototype.setNumofresults = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.limitation_endpoint.AddLimitationReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.limitation_endpoint.AddLimitationReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.limitation_endpoint.AddLimitationReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.AddLimitationReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.limitation_endpoint.AddLimitationReplay}
 */
proto.limitation_endpoint.AddLimitationReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.limitation_endpoint.AddLimitationReplay;
  return proto.limitation_endpoint.AddLimitationReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.limitation_endpoint.AddLimitationReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.limitation_endpoint.AddLimitationReplay}
 */
proto.limitation_endpoint.AddLimitationReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 3:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.limitation_endpoint.AddLimitationReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.limitation_endpoint.AddLimitationReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.limitation_endpoint.AddLimitationReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.AddLimitationReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.limitation_endpoint.AddLimitationReplay.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.limitation_endpoint.AddLimitationReplay} returns this
 */
proto.limitation_endpoint.AddLimitationReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.limitation_endpoint.AddLimitationReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.limitation_endpoint.AddLimitationReplay} returns this
*/
proto.limitation_endpoint.AddLimitationReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.AddLimitationReplay} returns this
 */
proto.limitation_endpoint.AddLimitationReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.AddLimitationReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.MessageResponse message = 3;
 * @return {?proto.common.MessageResponse}
 */
proto.limitation_endpoint.AddLimitationReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 3));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.limitation_endpoint.AddLimitationReplay} returns this
*/
proto.limitation_endpoint.AddLimitationReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.AddLimitationReplay} returns this
 */
proto.limitation_endpoint.AddLimitationReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.AddLimitationReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.limitation_endpoint.UpdateLimitationReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.limitation_endpoint.UpdateLimitationReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.limitation_endpoint.UpdateLimitationReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.UpdateLimitationReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.limitation_endpoint.UpdateLimitationReplay}
 */
proto.limitation_endpoint.UpdateLimitationReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.limitation_endpoint.UpdateLimitationReplay;
  return proto.limitation_endpoint.UpdateLimitationReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.limitation_endpoint.UpdateLimitationReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.limitation_endpoint.UpdateLimitationReplay}
 */
proto.limitation_endpoint.UpdateLimitationReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.limitation_endpoint.UpdateLimitationReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.limitation_endpoint.UpdateLimitationReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.limitation_endpoint.UpdateLimitationReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.UpdateLimitationReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.limitation_endpoint.UpdateLimitationReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.limitation_endpoint.UpdateLimitationReplay} returns this
*/
proto.limitation_endpoint.UpdateLimitationReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.UpdateLimitationReplay} returns this
 */
proto.limitation_endpoint.UpdateLimitationReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.UpdateLimitationReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.limitation_endpoint.UpdateLimitationReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.limitation_endpoint.UpdateLimitationReplay} returns this
*/
proto.limitation_endpoint.UpdateLimitationReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.UpdateLimitationReplay} returns this
 */
proto.limitation_endpoint.UpdateLimitationReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.UpdateLimitationReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.limitation_endpoint.UpdateLimitationActivityReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.limitation_endpoint.UpdateLimitationActivityReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.limitation_endpoint.UpdateLimitationActivityReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.UpdateLimitationActivityReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.limitation_endpoint.UpdateLimitationActivityReplay}
 */
proto.limitation_endpoint.UpdateLimitationActivityReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.limitation_endpoint.UpdateLimitationActivityReplay;
  return proto.limitation_endpoint.UpdateLimitationActivityReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.limitation_endpoint.UpdateLimitationActivityReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.limitation_endpoint.UpdateLimitationActivityReplay}
 */
proto.limitation_endpoint.UpdateLimitationActivityReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.limitation_endpoint.UpdateLimitationActivityReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.limitation_endpoint.UpdateLimitationActivityReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.limitation_endpoint.UpdateLimitationActivityReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.UpdateLimitationActivityReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.limitation_endpoint.UpdateLimitationActivityReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.limitation_endpoint.UpdateLimitationActivityReplay} returns this
*/
proto.limitation_endpoint.UpdateLimitationActivityReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.UpdateLimitationActivityReplay} returns this
 */
proto.limitation_endpoint.UpdateLimitationActivityReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.UpdateLimitationActivityReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.limitation_endpoint.UpdateLimitationActivityReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.limitation_endpoint.UpdateLimitationActivityReplay} returns this
*/
proto.limitation_endpoint.UpdateLimitationActivityReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.UpdateLimitationActivityReplay} returns this
 */
proto.limitation_endpoint.UpdateLimitationActivityReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.UpdateLimitationActivityReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.limitation_endpoint.DeleteLimitationReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.limitation_endpoint.DeleteLimitationReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.limitation_endpoint.DeleteLimitationReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.DeleteLimitationReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    deleteddate: (f = msg.getDeleteddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.limitation_endpoint.DeleteLimitationReplay}
 */
proto.limitation_endpoint.DeleteLimitationReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.limitation_endpoint.DeleteLimitationReplay;
  return proto.limitation_endpoint.DeleteLimitationReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.limitation_endpoint.DeleteLimitationReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.limitation_endpoint.DeleteLimitationReplay}
 */
proto.limitation_endpoint.DeleteLimitationReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeleteddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.limitation_endpoint.DeleteLimitationReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.limitation_endpoint.DeleteLimitationReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.limitation_endpoint.DeleteLimitationReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.DeleteLimitationReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeleteddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp deletedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.limitation_endpoint.DeleteLimitationReplay.prototype.getDeleteddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.limitation_endpoint.DeleteLimitationReplay} returns this
*/
proto.limitation_endpoint.DeleteLimitationReplay.prototype.setDeleteddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.DeleteLimitationReplay} returns this
 */
proto.limitation_endpoint.DeleteLimitationReplay.prototype.clearDeleteddate = function() {
  return this.setDeleteddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.DeleteLimitationReplay.prototype.hasDeleteddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.limitation_endpoint.DeleteLimitationReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.limitation_endpoint.DeleteLimitationReplay} returns this
*/
proto.limitation_endpoint.DeleteLimitationReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.DeleteLimitationReplay} returns this
 */
proto.limitation_endpoint.DeleteLimitationReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.DeleteLimitationReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.limitation_endpoint.GetLimitationReplay.repeatedFields_ = [10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.limitation_endpoint.GetLimitationReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.limitation_endpoint.GetLimitationReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.limitation_endpoint.GetLimitationReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.GetLimitationReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    balancemaxlimit: (f = msg.getBalancemaxlimit()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    balanceminlimit: (f = msg.getBalanceminlimit()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isavailable: (f = msg.getIsavailable()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    isactive: (f = msg.getIsactive()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    type: (f = msg.getType()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance),
    transactionlimitationsList: jspb.Message.toObjectList(msg.getTransactionlimitationsList(),
    proto.limitation_endpoint.TransactionLimitationReplay.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.limitation_endpoint.GetLimitationReplay}
 */
proto.limitation_endpoint.GetLimitationReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.limitation_endpoint.GetLimitationReplay;
  return proto.limitation_endpoint.GetLimitationReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.limitation_endpoint.GetLimitationReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.limitation_endpoint.GetLimitationReplay}
 */
proto.limitation_endpoint.GetLimitationReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBalancemaxlimit(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBalanceminlimit(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsavailable(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsactive(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setType(value);
      break;
    case 8:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 10:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    case 11:
      var value = new proto.limitation_endpoint.TransactionLimitationReplay;
      reader.readMessage(value,proto.limitation_endpoint.TransactionLimitationReplay.deserializeBinaryFromReader);
      msg.addTransactionlimitations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.limitation_endpoint.GetLimitationReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.limitation_endpoint.GetLimitationReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.limitation_endpoint.GetLimitationReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.GetLimitationReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getBalancemaxlimit();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getBalanceminlimit();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsavailable();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIsactive();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
  f = message.getTransactionlimitationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.limitation_endpoint.TransactionLimitationReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.limitation_endpoint.GetLimitationReplay.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.limitation_endpoint.GetLimitationReplay} returns this
 */
proto.limitation_endpoint.GetLimitationReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.limitation_endpoint.GetLimitationReplay.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.limitation_endpoint.GetLimitationReplay} returns this
*/
proto.limitation_endpoint.GetLimitationReplay.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.GetLimitationReplay} returns this
 */
proto.limitation_endpoint.GetLimitationReplay.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.GetLimitationReplay.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue balanceMaxLimit = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.limitation_endpoint.GetLimitationReplay.prototype.getBalancemaxlimit = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.limitation_endpoint.GetLimitationReplay} returns this
*/
proto.limitation_endpoint.GetLimitationReplay.prototype.setBalancemaxlimit = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.GetLimitationReplay} returns this
 */
proto.limitation_endpoint.GetLimitationReplay.prototype.clearBalancemaxlimit = function() {
  return this.setBalancemaxlimit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.GetLimitationReplay.prototype.hasBalancemaxlimit = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue balanceMinLimit = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.limitation_endpoint.GetLimitationReplay.prototype.getBalanceminlimit = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.limitation_endpoint.GetLimitationReplay} returns this
*/
proto.limitation_endpoint.GetLimitationReplay.prototype.setBalanceminlimit = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.GetLimitationReplay} returns this
 */
proto.limitation_endpoint.GetLimitationReplay.prototype.clearBalanceminlimit = function() {
  return this.setBalanceminlimit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.GetLimitationReplay.prototype.hasBalanceminlimit = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.BoolValue isAvailable = 5;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.limitation_endpoint.GetLimitationReplay.prototype.getIsavailable = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 5));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.limitation_endpoint.GetLimitationReplay} returns this
*/
proto.limitation_endpoint.GetLimitationReplay.prototype.setIsavailable = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.GetLimitationReplay} returns this
 */
proto.limitation_endpoint.GetLimitationReplay.prototype.clearIsavailable = function() {
  return this.setIsavailable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.GetLimitationReplay.prototype.hasIsavailable = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.BoolValue isActive = 6;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.limitation_endpoint.GetLimitationReplay.prototype.getIsactive = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 6));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.limitation_endpoint.GetLimitationReplay} returns this
*/
proto.limitation_endpoint.GetLimitationReplay.prototype.setIsactive = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.GetLimitationReplay} returns this
 */
proto.limitation_endpoint.GetLimitationReplay.prototype.clearIsactive = function() {
  return this.setIsactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.GetLimitationReplay.prototype.hasIsactive = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Int32Value type = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.limitation_endpoint.GetLimitationReplay.prototype.getType = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.limitation_endpoint.GetLimitationReplay} returns this
*/
proto.limitation_endpoint.GetLimitationReplay.prototype.setType = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.GetLimitationReplay} returns this
 */
proto.limitation_endpoint.GetLimitationReplay.prototype.clearType = function() {
  return this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.GetLimitationReplay.prototype.hasType = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional common.ProfileResponse createdBy = 8;
 * @return {?proto.common.ProfileResponse}
 */
proto.limitation_endpoint.GetLimitationReplay.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 8));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.limitation_endpoint.GetLimitationReplay} returns this
*/
proto.limitation_endpoint.GetLimitationReplay.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.GetLimitationReplay} returns this
 */
proto.limitation_endpoint.GetLimitationReplay.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.GetLimitationReplay.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.limitation_endpoint.GetLimitationReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.limitation_endpoint.GetLimitationReplay} returns this
*/
proto.limitation_endpoint.GetLimitationReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.GetLimitationReplay} returns this
 */
proto.limitation_endpoint.GetLimitationReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.GetLimitationReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated common.ExtraField extraFields = 10;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.limitation_endpoint.GetLimitationReplay.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 10));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.limitation_endpoint.GetLimitationReplay} returns this
*/
proto.limitation_endpoint.GetLimitationReplay.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.limitation_endpoint.GetLimitationReplay.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.limitation_endpoint.GetLimitationReplay} returns this
 */
proto.limitation_endpoint.GetLimitationReplay.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};


/**
 * repeated TransactionLimitationReplay transactionLimitations = 11;
 * @return {!Array<!proto.limitation_endpoint.TransactionLimitationReplay>}
 */
proto.limitation_endpoint.GetLimitationReplay.prototype.getTransactionlimitationsList = function() {
  return /** @type{!Array<!proto.limitation_endpoint.TransactionLimitationReplay>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.limitation_endpoint.TransactionLimitationReplay, 11));
};


/**
 * @param {!Array<!proto.limitation_endpoint.TransactionLimitationReplay>} value
 * @return {!proto.limitation_endpoint.GetLimitationReplay} returns this
*/
proto.limitation_endpoint.GetLimitationReplay.prototype.setTransactionlimitationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.limitation_endpoint.TransactionLimitationReplay=} opt_value
 * @param {number=} opt_index
 * @return {!proto.limitation_endpoint.TransactionLimitationReplay}
 */
proto.limitation_endpoint.GetLimitationReplay.prototype.addTransactionlimitations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.limitation_endpoint.TransactionLimitationReplay, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.limitation_endpoint.GetLimitationReplay} returns this
 */
proto.limitation_endpoint.GetLimitationReplay.prototype.clearTransactionlimitationsList = function() {
  return this.setTransactionlimitationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.limitation_endpoint.TimePeriodLimitationReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.limitation_endpoint.TimePeriodLimitationReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.limitation_endpoint.TimePeriodLimitationReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.TimePeriodLimitationReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    seconds: (f = msg.getSeconds()) && google_protobuf_wrappers_pb.Int64Value.toObject(includeInstance, f),
    maxnumberoftransactions: (f = msg.getMaxnumberoftransactions()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    maxtransactionstotalamount: (f = msg.getMaxtransactionstotalamount()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.limitation_endpoint.TimePeriodLimitationReplay}
 */
proto.limitation_endpoint.TimePeriodLimitationReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.limitation_endpoint.TimePeriodLimitationReplay;
  return proto.limitation_endpoint.TimePeriodLimitationReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.limitation_endpoint.TimePeriodLimitationReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.limitation_endpoint.TimePeriodLimitationReplay}
 */
proto.limitation_endpoint.TimePeriodLimitationReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int64Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int64Value.deserializeBinaryFromReader);
      msg.setSeconds(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMaxnumberoftransactions(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMaxtransactionstotalamount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.limitation_endpoint.TimePeriodLimitationReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.limitation_endpoint.TimePeriodLimitationReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.limitation_endpoint.TimePeriodLimitationReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.TimePeriodLimitationReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeconds();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int64Value.serializeBinaryToWriter
    );
  }
  f = message.getMaxnumberoftransactions();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getMaxtransactionstotalamount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int64Value seconds = 1;
 * @return {?proto.google.protobuf.Int64Value}
 */
proto.limitation_endpoint.TimePeriodLimitationReplay.prototype.getSeconds = function() {
  return /** @type{?proto.google.protobuf.Int64Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int64Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int64Value|undefined} value
 * @return {!proto.limitation_endpoint.TimePeriodLimitationReplay} returns this
*/
proto.limitation_endpoint.TimePeriodLimitationReplay.prototype.setSeconds = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.TimePeriodLimitationReplay} returns this
 */
proto.limitation_endpoint.TimePeriodLimitationReplay.prototype.clearSeconds = function() {
  return this.setSeconds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.TimePeriodLimitationReplay.prototype.hasSeconds = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Int32Value maxNumberOfTransactions = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.limitation_endpoint.TimePeriodLimitationReplay.prototype.getMaxnumberoftransactions = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.limitation_endpoint.TimePeriodLimitationReplay} returns this
*/
proto.limitation_endpoint.TimePeriodLimitationReplay.prototype.setMaxnumberoftransactions = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.TimePeriodLimitationReplay} returns this
 */
proto.limitation_endpoint.TimePeriodLimitationReplay.prototype.clearMaxnumberoftransactions = function() {
  return this.setMaxnumberoftransactions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.TimePeriodLimitationReplay.prototype.hasMaxnumberoftransactions = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue maxTransactionsTotalAmount = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.limitation_endpoint.TimePeriodLimitationReplay.prototype.getMaxtransactionstotalamount = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.limitation_endpoint.TimePeriodLimitationReplay} returns this
*/
proto.limitation_endpoint.TimePeriodLimitationReplay.prototype.setMaxtransactionstotalamount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.TimePeriodLimitationReplay} returns this
 */
proto.limitation_endpoint.TimePeriodLimitationReplay.prototype.clearMaxtransactionstotalamount = function() {
  return this.setMaxtransactionstotalamount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.TimePeriodLimitationReplay.prototype.hasMaxtransactionstotalamount = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.limitation_endpoint.TransactionLimitationReplay.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.limitation_endpoint.TransactionLimitationReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.limitation_endpoint.TransactionLimitationReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.limitation_endpoint.TransactionLimitationReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.TransactionLimitationReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    operation: (f = msg.getOperation()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    iscreditor: (f = msg.getIscreditor()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    maxamountvaluepertransaction: (f = msg.getMaxamountvaluepertransaction()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    minamountvaluepertransaction: (f = msg.getMinamountvaluepertransaction()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    timeperiodlimitationsList: jspb.Message.toObjectList(msg.getTimeperiodlimitationsList(),
    proto.limitation_endpoint.TimePeriodLimitationReplay.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.limitation_endpoint.TransactionLimitationReplay}
 */
proto.limitation_endpoint.TransactionLimitationReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.limitation_endpoint.TransactionLimitationReplay;
  return proto.limitation_endpoint.TransactionLimitationReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.limitation_endpoint.TransactionLimitationReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.limitation_endpoint.TransactionLimitationReplay}
 */
proto.limitation_endpoint.TransactionLimitationReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOperation(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIscreditor(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMaxamountvaluepertransaction(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMinamountvaluepertransaction(value);
      break;
    case 5:
      var value = new proto.limitation_endpoint.TimePeriodLimitationReplay;
      reader.readMessage(value,proto.limitation_endpoint.TimePeriodLimitationReplay.deserializeBinaryFromReader);
      msg.addTimeperiodlimitations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.limitation_endpoint.TransactionLimitationReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.limitation_endpoint.TransactionLimitationReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.limitation_endpoint.TransactionLimitationReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.TransactionLimitationReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getIscreditor();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getMaxamountvaluepertransaction();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMinamountvaluepertransaction();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTimeperiodlimitationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.limitation_endpoint.TimePeriodLimitationReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value operation = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.limitation_endpoint.TransactionLimitationReplay.prototype.getOperation = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.limitation_endpoint.TransactionLimitationReplay} returns this
*/
proto.limitation_endpoint.TransactionLimitationReplay.prototype.setOperation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.TransactionLimitationReplay} returns this
 */
proto.limitation_endpoint.TransactionLimitationReplay.prototype.clearOperation = function() {
  return this.setOperation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.TransactionLimitationReplay.prototype.hasOperation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.BoolValue isCreditor = 2;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.limitation_endpoint.TransactionLimitationReplay.prototype.getIscreditor = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 2));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.limitation_endpoint.TransactionLimitationReplay} returns this
*/
proto.limitation_endpoint.TransactionLimitationReplay.prototype.setIscreditor = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.TransactionLimitationReplay} returns this
 */
proto.limitation_endpoint.TransactionLimitationReplay.prototype.clearIscreditor = function() {
  return this.setIscreditor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.TransactionLimitationReplay.prototype.hasIscreditor = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue maxAmountValuePerTransaction = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.limitation_endpoint.TransactionLimitationReplay.prototype.getMaxamountvaluepertransaction = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.limitation_endpoint.TransactionLimitationReplay} returns this
*/
proto.limitation_endpoint.TransactionLimitationReplay.prototype.setMaxamountvaluepertransaction = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.TransactionLimitationReplay} returns this
 */
proto.limitation_endpoint.TransactionLimitationReplay.prototype.clearMaxamountvaluepertransaction = function() {
  return this.setMaxamountvaluepertransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.TransactionLimitationReplay.prototype.hasMaxamountvaluepertransaction = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue minAmountValuePerTransaction = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.limitation_endpoint.TransactionLimitationReplay.prototype.getMinamountvaluepertransaction = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.limitation_endpoint.TransactionLimitationReplay} returns this
*/
proto.limitation_endpoint.TransactionLimitationReplay.prototype.setMinamountvaluepertransaction = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.TransactionLimitationReplay} returns this
 */
proto.limitation_endpoint.TransactionLimitationReplay.prototype.clearMinamountvaluepertransaction = function() {
  return this.setMinamountvaluepertransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.TransactionLimitationReplay.prototype.hasMinamountvaluepertransaction = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated TimePeriodLimitationReplay timePeriodLimitations = 5;
 * @return {!Array<!proto.limitation_endpoint.TimePeriodLimitationReplay>}
 */
proto.limitation_endpoint.TransactionLimitationReplay.prototype.getTimeperiodlimitationsList = function() {
  return /** @type{!Array<!proto.limitation_endpoint.TimePeriodLimitationReplay>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.limitation_endpoint.TimePeriodLimitationReplay, 5));
};


/**
 * @param {!Array<!proto.limitation_endpoint.TimePeriodLimitationReplay>} value
 * @return {!proto.limitation_endpoint.TransactionLimitationReplay} returns this
*/
proto.limitation_endpoint.TransactionLimitationReplay.prototype.setTimeperiodlimitationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.limitation_endpoint.TimePeriodLimitationReplay=} opt_value
 * @param {number=} opt_index
 * @return {!proto.limitation_endpoint.TimePeriodLimitationReplay}
 */
proto.limitation_endpoint.TransactionLimitationReplay.prototype.addTimeperiodlimitations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.limitation_endpoint.TimePeriodLimitationReplay, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.limitation_endpoint.TransactionLimitationReplay} returns this
 */
proto.limitation_endpoint.TransactionLimitationReplay.prototype.clearTimeperiodlimitationsList = function() {
  return this.setTimeperiodlimitationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.limitation_endpoint.Limitation.prototype.toObject = function(opt_includeInstance) {
  return proto.limitation_endpoint.Limitation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.limitation_endpoint.Limitation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.Limitation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isavailable: (f = msg.getIsavailable()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    isactive: (f = msg.getIsactive()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    type: (f = msg.getType()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.limitation_endpoint.Limitation}
 */
proto.limitation_endpoint.Limitation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.limitation_endpoint.Limitation;
  return proto.limitation_endpoint.Limitation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.limitation_endpoint.Limitation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.limitation_endpoint.Limitation}
 */
proto.limitation_endpoint.Limitation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsavailable(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsactive(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setType(value);
      break;
    case 6:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.limitation_endpoint.Limitation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.limitation_endpoint.Limitation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.limitation_endpoint.Limitation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.Limitation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsavailable();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIsactive();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.limitation_endpoint.Limitation.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.limitation_endpoint.Limitation} returns this
 */
proto.limitation_endpoint.Limitation.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.limitation_endpoint.Limitation.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.limitation_endpoint.Limitation} returns this
*/
proto.limitation_endpoint.Limitation.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.Limitation} returns this
 */
proto.limitation_endpoint.Limitation.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.Limitation.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.BoolValue isAvailable = 3;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.limitation_endpoint.Limitation.prototype.getIsavailable = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 3));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.limitation_endpoint.Limitation} returns this
*/
proto.limitation_endpoint.Limitation.prototype.setIsavailable = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.Limitation} returns this
 */
proto.limitation_endpoint.Limitation.prototype.clearIsavailable = function() {
  return this.setIsavailable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.Limitation.prototype.hasIsavailable = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.BoolValue isActive = 4;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.limitation_endpoint.Limitation.prototype.getIsactive = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 4));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.limitation_endpoint.Limitation} returns this
*/
proto.limitation_endpoint.Limitation.prototype.setIsactive = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.Limitation} returns this
 */
proto.limitation_endpoint.Limitation.prototype.clearIsactive = function() {
  return this.setIsactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.Limitation.prototype.hasIsactive = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Int32Value type = 5;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.limitation_endpoint.Limitation.prototype.getType = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 5));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.limitation_endpoint.Limitation} returns this
*/
proto.limitation_endpoint.Limitation.prototype.setType = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.Limitation} returns this
 */
proto.limitation_endpoint.Limitation.prototype.clearType = function() {
  return this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.Limitation.prototype.hasType = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional common.ProfileResponse createdBy = 6;
 * @return {?proto.common.ProfileResponse}
 */
proto.limitation_endpoint.Limitation.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 6));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.limitation_endpoint.Limitation} returns this
*/
proto.limitation_endpoint.Limitation.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.Limitation} returns this
 */
proto.limitation_endpoint.Limitation.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.Limitation.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.limitation_endpoint.Limitation.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.limitation_endpoint.Limitation} returns this
*/
proto.limitation_endpoint.Limitation.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.Limitation} returns this
 */
proto.limitation_endpoint.Limitation.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.Limitation.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.limitation_endpoint.GetLimitationsReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.limitation_endpoint.GetLimitationsReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.limitation_endpoint.GetLimitationsReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.limitation_endpoint.GetLimitationsReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.GetLimitationsReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    limitationsList: jspb.Message.toObjectList(msg.getLimitationsList(),
    proto.limitation_endpoint.Limitation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.limitation_endpoint.GetLimitationsReplay}
 */
proto.limitation_endpoint.GetLimitationsReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.limitation_endpoint.GetLimitationsReplay;
  return proto.limitation_endpoint.GetLimitationsReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.limitation_endpoint.GetLimitationsReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.limitation_endpoint.GetLimitationsReplay}
 */
proto.limitation_endpoint.GetLimitationsReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.limitation_endpoint.Limitation;
      reader.readMessage(value,proto.limitation_endpoint.Limitation.deserializeBinaryFromReader);
      msg.addLimitations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.limitation_endpoint.GetLimitationsReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.limitation_endpoint.GetLimitationsReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.limitation_endpoint.GetLimitationsReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.GetLimitationsReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLimitationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.limitation_endpoint.Limitation.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Limitation Limitations = 1;
 * @return {!Array<!proto.limitation_endpoint.Limitation>}
 */
proto.limitation_endpoint.GetLimitationsReplay.prototype.getLimitationsList = function() {
  return /** @type{!Array<!proto.limitation_endpoint.Limitation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.limitation_endpoint.Limitation, 1));
};


/**
 * @param {!Array<!proto.limitation_endpoint.Limitation>} value
 * @return {!proto.limitation_endpoint.GetLimitationsReplay} returns this
*/
proto.limitation_endpoint.GetLimitationsReplay.prototype.setLimitationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.limitation_endpoint.Limitation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.limitation_endpoint.Limitation}
 */
proto.limitation_endpoint.GetLimitationsReplay.prototype.addLimitations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.limitation_endpoint.Limitation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.limitation_endpoint.GetLimitationsReplay} returns this
 */
proto.limitation_endpoint.GetLimitationsReplay.prototype.clearLimitationsList = function() {
  return this.setLimitationsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.limitation_endpoint.AddLimitationResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.limitation_endpoint.AddLimitationResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.limitation_endpoint.AddLimitationResponse.ResultCase}
 */
proto.limitation_endpoint.AddLimitationResponse.prototype.getResultCase = function() {
  return /** @type {proto.limitation_endpoint.AddLimitationResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.limitation_endpoint.AddLimitationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.limitation_endpoint.AddLimitationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.limitation_endpoint.AddLimitationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.limitation_endpoint.AddLimitationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.AddLimitationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.limitation_endpoint.AddLimitationReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.limitation_endpoint.AddLimitationResponse}
 */
proto.limitation_endpoint.AddLimitationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.limitation_endpoint.AddLimitationResponse;
  return proto.limitation_endpoint.AddLimitationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.limitation_endpoint.AddLimitationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.limitation_endpoint.AddLimitationResponse}
 */
proto.limitation_endpoint.AddLimitationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.limitation_endpoint.AddLimitationReplay;
      reader.readMessage(value,proto.limitation_endpoint.AddLimitationReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.limitation_endpoint.AddLimitationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.limitation_endpoint.AddLimitationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.limitation_endpoint.AddLimitationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.AddLimitationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.limitation_endpoint.AddLimitationReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional AddLimitationReplay success = 1;
 * @return {?proto.limitation_endpoint.AddLimitationReplay}
 */
proto.limitation_endpoint.AddLimitationResponse.prototype.getSuccess = function() {
  return /** @type{?proto.limitation_endpoint.AddLimitationReplay} */ (
    jspb.Message.getWrapperField(this, proto.limitation_endpoint.AddLimitationReplay, 1));
};


/**
 * @param {?proto.limitation_endpoint.AddLimitationReplay|undefined} value
 * @return {!proto.limitation_endpoint.AddLimitationResponse} returns this
*/
proto.limitation_endpoint.AddLimitationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.limitation_endpoint.AddLimitationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.AddLimitationResponse} returns this
 */
proto.limitation_endpoint.AddLimitationResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.AddLimitationResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.limitation_endpoint.UpdateLimitationResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.limitation_endpoint.UpdateLimitationResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.limitation_endpoint.UpdateLimitationResponse.ResultCase}
 */
proto.limitation_endpoint.UpdateLimitationResponse.prototype.getResultCase = function() {
  return /** @type {proto.limitation_endpoint.UpdateLimitationResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.limitation_endpoint.UpdateLimitationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.limitation_endpoint.UpdateLimitationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.limitation_endpoint.UpdateLimitationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.limitation_endpoint.UpdateLimitationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.UpdateLimitationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.limitation_endpoint.UpdateLimitationReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.limitation_endpoint.UpdateLimitationResponse}
 */
proto.limitation_endpoint.UpdateLimitationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.limitation_endpoint.UpdateLimitationResponse;
  return proto.limitation_endpoint.UpdateLimitationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.limitation_endpoint.UpdateLimitationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.limitation_endpoint.UpdateLimitationResponse}
 */
proto.limitation_endpoint.UpdateLimitationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.limitation_endpoint.UpdateLimitationReplay;
      reader.readMessage(value,proto.limitation_endpoint.UpdateLimitationReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.limitation_endpoint.UpdateLimitationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.limitation_endpoint.UpdateLimitationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.limitation_endpoint.UpdateLimitationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.UpdateLimitationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.limitation_endpoint.UpdateLimitationReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateLimitationReplay success = 1;
 * @return {?proto.limitation_endpoint.UpdateLimitationReplay}
 */
proto.limitation_endpoint.UpdateLimitationResponse.prototype.getSuccess = function() {
  return /** @type{?proto.limitation_endpoint.UpdateLimitationReplay} */ (
    jspb.Message.getWrapperField(this, proto.limitation_endpoint.UpdateLimitationReplay, 1));
};


/**
 * @param {?proto.limitation_endpoint.UpdateLimitationReplay|undefined} value
 * @return {!proto.limitation_endpoint.UpdateLimitationResponse} returns this
*/
proto.limitation_endpoint.UpdateLimitationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.limitation_endpoint.UpdateLimitationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.UpdateLimitationResponse} returns this
 */
proto.limitation_endpoint.UpdateLimitationResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.UpdateLimitationResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.limitation_endpoint.UpdateLimitationActivityResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.limitation_endpoint.UpdateLimitationActivityResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.limitation_endpoint.UpdateLimitationActivityResponse.ResultCase}
 */
proto.limitation_endpoint.UpdateLimitationActivityResponse.prototype.getResultCase = function() {
  return /** @type {proto.limitation_endpoint.UpdateLimitationActivityResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.limitation_endpoint.UpdateLimitationActivityResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.limitation_endpoint.UpdateLimitationActivityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.limitation_endpoint.UpdateLimitationActivityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.limitation_endpoint.UpdateLimitationActivityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.UpdateLimitationActivityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.limitation_endpoint.UpdateLimitationActivityReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.limitation_endpoint.UpdateLimitationActivityResponse}
 */
proto.limitation_endpoint.UpdateLimitationActivityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.limitation_endpoint.UpdateLimitationActivityResponse;
  return proto.limitation_endpoint.UpdateLimitationActivityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.limitation_endpoint.UpdateLimitationActivityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.limitation_endpoint.UpdateLimitationActivityResponse}
 */
proto.limitation_endpoint.UpdateLimitationActivityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.limitation_endpoint.UpdateLimitationActivityReplay;
      reader.readMessage(value,proto.limitation_endpoint.UpdateLimitationActivityReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.limitation_endpoint.UpdateLimitationActivityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.limitation_endpoint.UpdateLimitationActivityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.limitation_endpoint.UpdateLimitationActivityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.UpdateLimitationActivityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.limitation_endpoint.UpdateLimitationActivityReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateLimitationActivityReplay success = 1;
 * @return {?proto.limitation_endpoint.UpdateLimitationActivityReplay}
 */
proto.limitation_endpoint.UpdateLimitationActivityResponse.prototype.getSuccess = function() {
  return /** @type{?proto.limitation_endpoint.UpdateLimitationActivityReplay} */ (
    jspb.Message.getWrapperField(this, proto.limitation_endpoint.UpdateLimitationActivityReplay, 1));
};


/**
 * @param {?proto.limitation_endpoint.UpdateLimitationActivityReplay|undefined} value
 * @return {!proto.limitation_endpoint.UpdateLimitationActivityResponse} returns this
*/
proto.limitation_endpoint.UpdateLimitationActivityResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.limitation_endpoint.UpdateLimitationActivityResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.UpdateLimitationActivityResponse} returns this
 */
proto.limitation_endpoint.UpdateLimitationActivityResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.UpdateLimitationActivityResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.limitation_endpoint.DeleteLimitationResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.limitation_endpoint.DeleteLimitationResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.limitation_endpoint.DeleteLimitationResponse.ResultCase}
 */
proto.limitation_endpoint.DeleteLimitationResponse.prototype.getResultCase = function() {
  return /** @type {proto.limitation_endpoint.DeleteLimitationResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.limitation_endpoint.DeleteLimitationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.limitation_endpoint.DeleteLimitationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.limitation_endpoint.DeleteLimitationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.limitation_endpoint.DeleteLimitationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.DeleteLimitationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.limitation_endpoint.DeleteLimitationReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.limitation_endpoint.DeleteLimitationResponse}
 */
proto.limitation_endpoint.DeleteLimitationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.limitation_endpoint.DeleteLimitationResponse;
  return proto.limitation_endpoint.DeleteLimitationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.limitation_endpoint.DeleteLimitationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.limitation_endpoint.DeleteLimitationResponse}
 */
proto.limitation_endpoint.DeleteLimitationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.limitation_endpoint.DeleteLimitationReplay;
      reader.readMessage(value,proto.limitation_endpoint.DeleteLimitationReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.limitation_endpoint.DeleteLimitationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.limitation_endpoint.DeleteLimitationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.limitation_endpoint.DeleteLimitationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.DeleteLimitationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.limitation_endpoint.DeleteLimitationReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeleteLimitationReplay success = 1;
 * @return {?proto.limitation_endpoint.DeleteLimitationReplay}
 */
proto.limitation_endpoint.DeleteLimitationResponse.prototype.getSuccess = function() {
  return /** @type{?proto.limitation_endpoint.DeleteLimitationReplay} */ (
    jspb.Message.getWrapperField(this, proto.limitation_endpoint.DeleteLimitationReplay, 1));
};


/**
 * @param {?proto.limitation_endpoint.DeleteLimitationReplay|undefined} value
 * @return {!proto.limitation_endpoint.DeleteLimitationResponse} returns this
*/
proto.limitation_endpoint.DeleteLimitationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.limitation_endpoint.DeleteLimitationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.DeleteLimitationResponse} returns this
 */
proto.limitation_endpoint.DeleteLimitationResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.DeleteLimitationResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.limitation_endpoint.GetLimitationResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.limitation_endpoint.GetLimitationResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.limitation_endpoint.GetLimitationResponse.ResultCase}
 */
proto.limitation_endpoint.GetLimitationResponse.prototype.getResultCase = function() {
  return /** @type {proto.limitation_endpoint.GetLimitationResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.limitation_endpoint.GetLimitationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.limitation_endpoint.GetLimitationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.limitation_endpoint.GetLimitationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.limitation_endpoint.GetLimitationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.GetLimitationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.limitation_endpoint.GetLimitationReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.limitation_endpoint.GetLimitationResponse}
 */
proto.limitation_endpoint.GetLimitationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.limitation_endpoint.GetLimitationResponse;
  return proto.limitation_endpoint.GetLimitationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.limitation_endpoint.GetLimitationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.limitation_endpoint.GetLimitationResponse}
 */
proto.limitation_endpoint.GetLimitationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.limitation_endpoint.GetLimitationReplay;
      reader.readMessage(value,proto.limitation_endpoint.GetLimitationReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.limitation_endpoint.GetLimitationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.limitation_endpoint.GetLimitationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.limitation_endpoint.GetLimitationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.GetLimitationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.limitation_endpoint.GetLimitationReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetLimitationReplay success = 1;
 * @return {?proto.limitation_endpoint.GetLimitationReplay}
 */
proto.limitation_endpoint.GetLimitationResponse.prototype.getSuccess = function() {
  return /** @type{?proto.limitation_endpoint.GetLimitationReplay} */ (
    jspb.Message.getWrapperField(this, proto.limitation_endpoint.GetLimitationReplay, 1));
};


/**
 * @param {?proto.limitation_endpoint.GetLimitationReplay|undefined} value
 * @return {!proto.limitation_endpoint.GetLimitationResponse} returns this
*/
proto.limitation_endpoint.GetLimitationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.limitation_endpoint.GetLimitationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.GetLimitationResponse} returns this
 */
proto.limitation_endpoint.GetLimitationResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.GetLimitationResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.limitation_endpoint.GetLimitationsResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.limitation_endpoint.GetLimitationsResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.limitation_endpoint.GetLimitationsResponse.ResultCase}
 */
proto.limitation_endpoint.GetLimitationsResponse.prototype.getResultCase = function() {
  return /** @type {proto.limitation_endpoint.GetLimitationsResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.limitation_endpoint.GetLimitationsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.limitation_endpoint.GetLimitationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.limitation_endpoint.GetLimitationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.limitation_endpoint.GetLimitationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.GetLimitationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.limitation_endpoint.GetLimitationsReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.limitation_endpoint.GetLimitationsResponse}
 */
proto.limitation_endpoint.GetLimitationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.limitation_endpoint.GetLimitationsResponse;
  return proto.limitation_endpoint.GetLimitationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.limitation_endpoint.GetLimitationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.limitation_endpoint.GetLimitationsResponse}
 */
proto.limitation_endpoint.GetLimitationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.limitation_endpoint.GetLimitationsReplay;
      reader.readMessage(value,proto.limitation_endpoint.GetLimitationsReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.limitation_endpoint.GetLimitationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.limitation_endpoint.GetLimitationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.limitation_endpoint.GetLimitationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.limitation_endpoint.GetLimitationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.limitation_endpoint.GetLimitationsReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetLimitationsReplay success = 1;
 * @return {?proto.limitation_endpoint.GetLimitationsReplay}
 */
proto.limitation_endpoint.GetLimitationsResponse.prototype.getSuccess = function() {
  return /** @type{?proto.limitation_endpoint.GetLimitationsReplay} */ (
    jspb.Message.getWrapperField(this, proto.limitation_endpoint.GetLimitationsReplay, 1));
};


/**
 * @param {?proto.limitation_endpoint.GetLimitationsReplay|undefined} value
 * @return {!proto.limitation_endpoint.GetLimitationsResponse} returns this
*/
proto.limitation_endpoint.GetLimitationsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.limitation_endpoint.GetLimitationsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.limitation_endpoint.GetLimitationsResponse} returns this
 */
proto.limitation_endpoint.GetLimitationsResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.limitation_endpoint.GetLimitationsResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.limitation_endpoint);
