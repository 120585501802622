import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { UserEndpointClient } from '../../../../repository/UserManagement/Customers/UserServiceClientPb';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIResponse, refreshSession, getSession, ACCOUNTANT_API_URL, APIRequest } from '../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../app/Helpers';
import { TableState } from '../../../common/Table/TableSate';
import { number } from 'yup';
import { AccountEndpointClient } from '../../../../repository/Accountant/AccountServiceClientPb';
import { GetAccountsRequest, GetAccountsResponse, CloseAccountResponse, TransferAccountOwnershipRequest, UpdateAccountStatusResponse, TransferAccountOwnershipResponse, UpdateAccountLimitationRequest, UpdateAccountLimitationResponse, UpdateAccountStatusRequest, CloseAccountRequest, RevokeLinkRequest, LinkResponse } from '../../../../repository/Accountant/account_pb';
import { GetCurrenciesRequest, GetCurrenciesResponse } from '../../../../repository/Accountant/currency_pb';
import { CurrencyEndpointClient } from '../../../../repository/Accountant/CurrencyServiceClientPb';
import { AccountTypeEndpointClient } from '../../../../repository/Accountant/Account_typeServiceClientPb';
import { GetUsersRequest, GetUsersResponse } from '../../../../repository/UserManagement/Customers/user_pb';
import { GetAccountTypesRequest, GetAccountTypesResponse } from '../../../../repository/Accountant/account_type_pb';
import { GetLimitationsRequest, GetLimitationsResponse } from '../../../../repository/Accountant/limitation_pb';
import { LimitationEndpointClient } from '../../../../repository/Accountant/LimitationServiceClientPb';
import { AccountStatus } from '../../../../app/Enums';


export interface AccountsPageState {
    set: TableState
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    searchText: string | undefined,
    filters: any[],
    currencies: TableState
    accountTypes: TableState
    owners: TableState
    autorizedClient:TableState
    limitations: TableState
    codeFilterOption: { value: string, text: string },
    isDebtorFilterOption: { key: number, text: string },
    statusFilterOption: { key: number, text: string },
    authorizationFilterOption: { key: number, text: string },
    accountLinkingFilterOption: { key: number, text: string },


}

const initialState: AccountsPageState = {
    set: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    isChangeStateLoading: false,
    message: undefined,
    searchText: undefined,
    filters: [],
    currencies: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    accountTypes: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    owners: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    autorizedClient: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    limitations: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    codeFilterOption: { text: "", value: "" },
    isDebtorFilterOption: { key: 0, text: "" },
    statusFilterOption: { key: 0, text: "" },
    authorizationFilterOption: { key: 0, text: "" },
    accountLinkingFilterOption: {  key: 0, text: "" },

}

const client = new AccountEndpointClient(ACCOUNTANT_API_URL, null, null);
const clientCurrency = new CurrencyEndpointClient(ACCOUNTANT_API_URL, null, null);
const clientAccountType = new AccountTypeEndpointClient(ACCOUNTANT_API_URL, null, null);
const clienCustomers = new UserEndpointClient(API_URL, null, null);
const clienLimition = new LimitationEndpointClient(ACCOUNTANT_API_URL, null, null);

export const getItems = createAsyncThunk<GetAccountsResponse.AsObject, APIRequest<GetAccountsRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/accounts/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await client.getAccounts(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getClinetUsers = createAsyncThunk<GetUsersResponse.AsObject, APIRequest<GetUsersRequest>, {
    rejectValue: ApiMessage
}>(
    'pages/accountant/accounts/clients/fetch',
    async (req, thunkApi) => {

        let callReq = async (): Promise<any> => {
            try {
                var r = await clienCustomers.getUsers(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {
                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getAutorizedClientUsers = createAsyncThunk<GetUsersResponse.AsObject, APIRequest<GetUsersRequest>, {
    rejectValue: ApiMessage
}>(
    'pages/accountant/accounts/clients/fetchAutorizedClientUsers',
    async (req, thunkApi) => {

        let callReq = async (): Promise<any> => {
            try {
                var r = await clienCustomers.getUsers(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {
                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getCurrencies = createAsyncThunk<GetCurrenciesResponse.AsObject, APIRequest<GetCurrenciesRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/accounts/currencies/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clientCurrency.getCurrencies(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getAccountTypes = createAsyncThunk<GetAccountTypesResponse.AsObject, APIRequest<GetAccountTypesRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/accounts/accountTypes/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clientAccountType.getAccountTypes(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

export const getLimitations = createAsyncThunk<GetLimitationsResponse.AsObject, APIRequest<GetLimitationsRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/accounts/limitations/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clienLimition.getLimitations(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)



export const changeOwnership = createAsyncThunk<APIResponse<TransferAccountOwnershipResponse.AsObject>, APIRequest<TransferAccountOwnershipRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/accounts/transferAccountOwnership',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.transferOwnership(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId(), ownerId: req.body.getOwner() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)

export const updateLimit = createAsyncThunk<APIResponse<UpdateAccountLimitationResponse.AsObject>, APIRequest<UpdateAccountLimitationRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/accounts/updateAccountLimitation',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.updateLimitation(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId(), limitationId: req.body.getLimitation() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)

export const updateStatus = createAsyncThunk<APIResponse<UpdateAccountStatusResponse.AsObject>, APIRequest<UpdateAccountStatusRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/accounts/updateStatus',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.updateStatus(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId(), status: req.body.getStatus() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)


export const removeLink = createAsyncThunk<APIResponse<LinkResponse.AsObject>, APIRequest<RevokeLinkRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/accounts/removeLink',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.revokeLink(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId()}, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)

export const closeAccount = createAsyncThunk<APIResponse<CloseAccountResponse.AsObject>, APIRequest<CloseAccountRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/accounts/closeAccount',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.close(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)
const getFilters = (state: AccountsPageState): any[] => {
    const selected = [];
    if (state.accountTypes.selected.length > 0) {
        selected.push({ key: 1, name: state.accountTypes.selected.at(0).name });
    }
    if (state.currencies.selected.length > 0) {
        selected.push({ key: 2, name: state.currencies.selected.at(0).name });
    }
    if (state.owners.selected.length > 0) {
        selected.push({ key: 3, name: state.owners.selected.at(0).name });
    }
    if (state.autorizedClient.selected.length > 0) {
        selected.push({ key: 4, name: state.autorizedClient.selected.at(0).name });
    }
    if (state.codeFilterOption.value && state.codeFilterOption.value != "") {
        selected.push({ key: 5, name: state.codeFilterOption.text });
    }
    if (state.statusFilterOption.key != 0) {
        selected.push({ key: 6, name: state.statusFilterOption.text });
    }

    if (state.isDebtorFilterOption.key != 0) {
        selected.push({ key: 7, name: state.isDebtorFilterOption.text });
    }

    if (state.authorizationFilterOption.key != 0) {
        selected.push({ key: 8, name: state.authorizationFilterOption.text });
    }

    if (state.accountLinkingFilterOption.key != 0) {
        selected.push({ key: 9, name: state.accountLinkingFilterOption.text });
    }
   
    
    return selected;
}

export const accountsPageSlice = createSlice({
    name: 'pages/accountant/accounts',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.set = initialState.set;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
            state.searchText = initialState.searchText;
            state.accountTypes = initialState.accountTypes;
            state.codeFilterOption = initialState.codeFilterOption;
            state.currencies = initialState.currencies;
            state.isDebtorFilterOption = initialState.isDebtorFilterOption;
            state.owners = initialState.owners;
            state.limitations = initialState.limitations;
            state.statusFilterOption = initialState.statusFilterOption;
            state.filters = initialState.filters;
            state.authorizationFilterOption = initialState.authorizationFilterOption;
            state.accountLinkingFilterOption = initialState.accountLinkingFilterOption;
            state.autorizedClient = initialState.autorizedClient;

        },
        resetSet: (state) => {
            state.set.items = [];
            state.set.hasMore = true;
        },
        resetUsers: (state) => {
            state.owners.items = [];
            state.owners.hasMore = true;
        },
        resetAutorizedClients: (state) => {
            state.autorizedClient.items = [];
            state.autorizedClient.hasMore = true;
        },
        resetLimitations: (state) => {
            state.limitations.items = [];
            state.limitations.hasMore = true;
        },
        resetCurrencies: (state) => {
            state.currencies.items = [];
            state.currencies.hasMore = true;
        },
        resetAccountTypes: (state) => {
            state.accountTypes.items = [];
            state.accountTypes.hasMore = true;
        },

        setSort: (state, action: PayloadAction<boolean>) => {
            state.set.isDescending = action.payload;
        },
        setNumberOfResults: (state, action: PayloadAction<number>) => {
            state.set.numberOfResults = action.payload;
        },
        setSelectedItems: (state, action: PayloadAction<any[]>) => {
            state.set.selected = action.payload;
        },
        setSelectedCurrencyItems: (state, action: PayloadAction<any[]>) => {
            state.currencies.selected = action.payload;
            state.filters = getFilters(state);

        },
        setSelectedLimitationItems: (state, action: PayloadAction<any[]>) => {
            state.limitations.selected = action.payload;

        },
        setSelectedAccountTypeItems: (state, action: PayloadAction<any[]>) => {
            state.accountTypes.selected = action.payload;
            state.filters = getFilters(state);

        },
        setSelectedUserItems: (state, action: PayloadAction<any[]>) => {
            state.owners.selected = action.payload;
            state.filters = getFilters(state);

        },
        setSelectedAutorizedClientItems: (state, action: PayloadAction<any[]>) => {
            state.autorizedClient.selected = action.payload;
            state.filters = getFilters(state);

        },
        setSearchText: (state, action: PayloadAction<string | undefined>) => {
            state.searchText = action.payload;
        },
        setIsFilteredSet: (state, action: PayloadAction<boolean>) => {
            state.set.isFilteredSet = action.payload;
        },
        setIsFilteredCurrenciesSet: (state, action: PayloadAction<boolean>) => {
            state.currencies.isFilteredSet = action.payload;
        },
        setIsFilteredLimitationsSet: (state, action: PayloadAction<boolean>) => {
            state.limitations.isFilteredSet = action.payload;
        },
        setIsFilteredAccountTypesSet: (state, action: PayloadAction<boolean>) => {
            state.accountTypes.isFilteredSet = action.payload;
        },
        setIsFilteredOwnersSet: (state, action: PayloadAction<boolean>) => {
            state.owners.isFilteredSet = action.payload;
        },
        setIsFilteredAutorizedClientSet: (state, action: PayloadAction<boolean>) => {
            state.autorizedClient.isFilteredSet = action.payload;
        },
        setStatusFilterOption: (state, action: PayloadAction<{ key: number, text: string }>) => {
            state.statusFilterOption = action.payload;
            state.filters = getFilters(state);
        },
        setAuthorizationFilterOption: (state, action: PayloadAction<{ key: number, text: string }>) => {
            state.authorizationFilterOption = action.payload;
            state.filters = getFilters(state);
        },
        setAccountLinkingFilterOption: (state, action: PayloadAction<{ key: number, text: string }>) => {
            state.accountLinkingFilterOption = action.payload;
            state.filters = getFilters(state);
        },
        setCodeFilterOption: (state, action: PayloadAction<{ value: string, text: string }>) => {
            state.codeFilterOption = action.payload;
            state.filters = getFilters(state);
        },
        setIsDebtorFilterOption: (state, action: PayloadAction<{ key: number, text: string }>) => {
            state.isDebtorFilterOption = action.payload;
            state.filters = getFilters(state);
        },
        addItem: (state, action: PayloadAction<any>) => {
            if (!state.set.isFilteredSet) {
                if (!state.set.hasMore && !state.set.isDescending) {
                    state.set.items.push(action.payload);
                } else if (state.set.isDescending) {
                    state.set.items.unshift(action.payload);
                }
            }
        },
        updateItem: (state, action: PayloadAction<any>) => {
            let selected = state.set.selected;
            if (action?.payload) {
                if (selected.length > 0 && selected.at(0).id == action?.payload?.id) {
                    selected[0] = action?.payload
                }
                var u = state.set.items.findIndex(e => e.id == action?.payload?.id)
                if (u >= 0) {
                    state.set.items[u] = action?.payload
                }
            }

        }

        ,
        addAmount: (state, action: PayloadAction<any>) => {
            let selected = state.set.selected;
            if (action?.payload) {

                var u = state.set.items.findIndex(e => e.internalId == action?.payload?.id)
                if (u >= 0) {
                    if (state.set.items[u].mirrorBalance) {
                        state.set.items[u].mirrorBalance = Number(state.set.items[u].mirrorBalance) + action?.payload?.amount
                    }
                }
            }

        },

        withdrawAmount: (state, action: PayloadAction<any>) => {
            if (action?.payload) {

                var u = state.set.items.findIndex(e => e.internalId == action?.payload?.id)
                if (u >= 0) {
                    if (state.set.items[u].mirrorBalance) {
                        state.set.items[u].mirrorBalance = Number(state.set.items[u].mirrorBalance) - action?.payload?.amount
                    }
                }
            }

        }

    },
    extraReducers: (builder) => {
        builder.addCase(getAccountTypes.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.accounttypesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])

                if (r.length < state.accountTypes.numberOfResults) {
                    state.accountTypes.hasMore = false;
                }
                state.accountTypes.items = state.accountTypes.items.concat(r);

            } else {
                state.accountTypes.hasMore = false;
            }
            state.accountTypes.isFetching = false

        })
        builder.addCase(getAccountTypes.rejected, (state, action) => {
            state.accountTypes.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getAccountTypes.pending, (state, action) => {
            state.accountTypes.isFetching = true;
            // state.message = undefined;
        })

        builder.addCase(getCurrencies.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.currenciesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        code: val.code?.value ?? "",
                        icon: val.iconsource?.value ?? "",
                        form: val.form?.formid,
                        symbol: val.symbol?.value ?? "",
                        decimalPlaces: val.decimalplaces?.value,

                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.currencies.numberOfResults) {
                    state.currencies.hasMore = false;
                }
                state.currencies.items = state.currencies.items.concat(r);


                //console.log(state.items)

            } else {
                state.currencies.hasMore = false;
            }
            state.currencies.isFetching = false

        })
        builder.addCase(getCurrencies.rejected, (state, action) => {
            state.currencies.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getCurrencies.pending, (state, action) => {
            state.currencies.isFetching = true;
            //state.message = undefined;
        })


        builder.addCase(getClinetUsers.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.usersList.map(val => {
                    return {
                        internalId: val.profile?.internalid,
                        name: (val.profile?.firstname?.value ?? "") + " " + (val.profile?.lastname?.value ?? ""),
                        firstname: (val.profile?.firstname?.value ?? ""),
                        lastname: (val.profile?.lastname?.value ?? ""),
                        username: val.username?.value ?? "",
                        isActive: val.isactive,
                        isConfirmed: val.isconfirmed,
                        createdDate: formatDate(timestampToDate(val.profile?.createddate?.seconds, val.profile?.createddate?.nanos)),
                        addedBy: (val.profile?.addedby?.firstname?.value ?? "") + " " + (val.profile?.addedby?.lastname?.value ?? ""),
                        addedById: val.profile?.addedby?.id?.value,
                        role: val.profile?.role?.name?.value,
                        roleId: val.profile?.role?.id,
                        usertypeId: val.profile?.usertype?.id,
                        usertype: val.profile?.usertype?.name?.value,
                        lastlogin: formatDate(timestampToDate(val.lastlogin?.seconds, val.lastlogin?.nanos)),
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.owners.numberOfResults) {
                    state.owners.hasMore = false;
                }
                state.owners.items = state.owners.items.concat(r);
                //console.log(state.items)

            } else {
                state.owners.hasMore = false;
            }
            state.owners.isFetching = false

        })
        builder.addCase(getClinetUsers.rejected, (state, action) => {
            state.owners.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getClinetUsers.pending, (state, action) => {
            state.owners.isFetching = true;
            // state.message = undefined;
        })


        builder.addCase(getAutorizedClientUsers.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.usersList.map(val => {
                    return {
                        internalId: val.profile?.internalid,
                        name: (val.profile?.firstname?.value ?? "") + " " + (val.profile?.lastname?.value ?? ""),
                        firstname: (val.profile?.firstname?.value ?? ""),
                        lastname: (val.profile?.lastname?.value ?? ""),
                        username: val.username?.value ?? "",
                        isActive: val.isactive,
                        isConfirmed: val.isconfirmed,
                        createdDate: formatDate(timestampToDate(val.profile?.createddate?.seconds, val.profile?.createddate?.nanos)),
                        addedBy: (val.profile?.addedby?.firstname?.value ?? "") + " " + (val.profile?.addedby?.lastname?.value ?? ""),
                        addedById: val.profile?.addedby?.id?.value,
                        role: val.profile?.role?.name?.value,
                        roleId: val.profile?.role?.id,
                        usertypeId: val.profile?.usertype?.id,
                        usertype: val.profile?.usertype?.name?.value,
                        lastlogin: formatDate(timestampToDate(val.lastlogin?.seconds, val.lastlogin?.nanos)),
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.autorizedClient.numberOfResults) {
                    state.autorizedClient.hasMore = false;
                }
                state.autorizedClient.items = state.autorizedClient.items.concat(r);
                //console.log(state.items)

            } else {
                state.autorizedClient.hasMore = false;
            }
            state.autorizedClient.isFetching = false

        })
        builder.addCase(getAutorizedClientUsers.rejected, (state, action) => {
            state.autorizedClient.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getAutorizedClientUsers.pending, (state, action) => {
            state.autorizedClient.isFetching = true;
            // state.message = undefined;
        })


        builder.addCase(getLimitations.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.limitationsList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        isActive: val.isactive?.value ?? undefined,
                        type: val.type?.value ?? undefined,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.limitations.numberOfResults) {
                    state.limitations.hasMore = false;
                }
                state.limitations.items = state.limitations.items.concat(r);
            } else {
                state.limitations.hasMore = false;
            }
            state.limitations.isFetching = false

        })
        builder.addCase(getLimitations.rejected, (state, action) => {
            state.limitations.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getLimitations.pending, (state, action) => {
            state.limitations.isFetching = true;
            // state.message = undefined;
        })

        builder.addCase(getItems.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.accountsList.map(val => {

                    return {
                        id: val.id,
                        internalId: val.internalid,
                        name: val.name?.value ?? "",
                        code: val.code?.value ?? "",
                        currencyId: val.currency?.id?.value ?? "",
                        currencyName: val.currency?.name?.value ?? "",
                        currencyCode: val.currency?.code?.value ?? "",
                        currencyDecimalPlaces: val.currency?.decimalplaces?.value ?? undefined,
                        currencyForm: val.currency?.form?.value ?? undefined,
                        currencySymbol: val.currency?.symbol?.value ?? "",
                        accountTypeId: val.accounttype?.id?.value ?? "",
                        accountTypeName: val.accounttype?.name?.value ?? "",
                        mirrorBalance: val.mirrorbalance?.value ?? undefined,
                        status: val.status?.value ?? undefined,
                        ownerFirstname: val.owner?.firstname?.value ?? "",
                        ownerLastname: val.owner?.lastname?.value ?? "",
                        ownerMiddlename: val.owner?.middlename?.value ?? "",
                        ownerName: (val.owner?.firstname?.value ?? "") + " " + (val.owner?.lastname?.value ?? ""),
                        ownerId: val.owner?.id?.value ?? "",
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                        isAuthorizationRequired: val.accountsetting?.isauthorizationrequired,
                        isLinkingEnable: val.accountsetting?.islinkingenable?.value,
                        isLinkingMetadataSet: val.accountsetting?.islinkingmetadataset?.value,
                        transferControl: val?.accountsetting?.tansfercontrol?.value ,


                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                let l = [];
                if (state.set.items.length != 0) {
                    l = state.set.items.slice(0, state.set.items.length - 10)
                }
                l = l.concat(r);
                if (r.length < state.set.numberOfResults) {
                    state.set.hasMore = false;
                } else {
                    l = l.concat(...Array(10).fill(null));
                }

                state.set.items = l
                //console.log(state.items)

            } else {
                state.set.hasMore = false;
            }
            state.set.isFetching = false

        })
        builder.addCase(getItems.rejected, (state, action) => {
            state.set.isFetching = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getItems.pending, (state, action) => {
            state.set.isFetching = true;
            state.message = undefined;
        })


        builder.addCase(updateStatus.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response?.success?.message);
                let selected = state.set.selected;
                if (payload?.metadata) {
                    if (selected.length > 0 && selected.at(0).internalId == payload?.metadata.id) {
                        selected.at(0).status = payload?.metadata.status
                    }
                    var u = state.set.items.findIndex(e => e.internalId == payload?.metadata.id)
                    if (u >= 0) {
                        state.set.items.at(u).status = payload?.metadata.status
                    }

                }
            }
        })
        builder.addCase(updateStatus.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateStatus.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

        builder.addCase(removeLink.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response?.success?.message);
                let selected = state.set.selected;
                if (payload?.metadata) {
                    if (selected.length > 0 && selected.at(0).internalId == payload?.metadata.id) {
                        selected.at(0).isLinkingEnable = false
                        selected.at(0).isLinkingMetadataSet = false


                    }
                    var u = state.set.items.findIndex(e => e.internalId == payload?.metadata.id)
                    if (u >= 0) {
                        state.set.items.at(u).isLinkingEnable = false
                        state.set.items.at(u).isLinkingMetadataSet = false
                    }

                }
            }
        })
        builder.addCase(removeLink.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(removeLink.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

        builder.addCase(closeAccount.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response?.success?.message);
                let selected = state.set.selected;
                if (payload?.metadata) {
                    if (selected.length > 0 && selected.at(0).internalId == payload?.metadata.id) {
                        selected.at(0).status = AccountStatus.CLOSED
                    }
                    var u = state.set.items.findIndex(e => e.internalId == payload?.metadata.id)
                    if (u >= 0) {
                        state.set.items.at(u).status = AccountStatus.CLOSED
                    }

                }
            }
        })
        builder.addCase(closeAccount.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(closeAccount.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

        builder.addCase(changeOwnership.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response?.success?.message);
                let selected = state.set.selected;
                if (payload?.metadata) {
                    if (selected.length > 0 && selected.at(0).internalId == payload?.metadata.id) {
                        let r = selected.at(0);
                        r.ownerId = payload?.metadata.ownerId
                        //  r.ownerFirstname = val.owner?.firstname?.value ?? "";
                        // r.ownerLastname = val.owner?.lastname?.value ?? "";
                        //  r.ownerMiddlename = val.owner?.middlename?.value ?? "";
                        //  r.ownerId = val.owner?.id?.value ?? "";
                    }
                    var u = state.set.items.findIndex(e => e.internalId == payload?.metadata.id)
                    if (u >= 0) {
                        let r = selected.at(u);
                        r.ownerId = payload?.metadata.ownerId

                    }

                }
            }
        })
        builder.addCase(changeOwnership.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(changeOwnership.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

        builder.addCase(updateLimit.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response?.success?.message);
                let selected = state.set.selected;
                if (payload?.metadata) {
                    if (selected.length > 0 && selected.at(0).internalId == payload?.metadata.id) {
                        let r = selected.at(0);
                        r.limitationId = payload?.metadata.limitationId
                        //  r.ownerFirstname = val.owner?.firstname?.value ?? "";
                        // r.ownerLastname = val.owner?.lastname?.value ?? "";
                        //  r.ownerMiddlename = val.owner?.middlename?.value ?? "";
                        //  r.ownerId = val.owner?.id?.value ?? "";
                    }
                    var u = state.set.items.findIndex(e => e.internalId == payload?.metadata.id)
                    if (u >= 0) {
                        let r = selected.at(u);
                        r.limitationId = payload?.metadata.limitationId

                    }

                }
            }
        })
        builder.addCase(updateLimit.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateLimit.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })
    }
})

export const { reset, dismissMessage, setSort, setSelectedAutorizedClientItems, resetAutorizedClients, setIsFilteredAutorizedClientSet, withdrawAmount, addAmount, setNumberOfResults, setAuthorizationFilterOption, setAccountLinkingFilterOption, resetSet, setSelectedItems, setSearchText, setIsFilteredSet, addItem, updateItem, resetAccountTypes, resetCurrencies, resetLimitations, resetUsers, setCodeFilterOption, setIsDebtorFilterOption, setIsFilteredAccountTypesSet, setIsFilteredCurrenciesSet, setIsFilteredLimitationsSet, setIsFilteredOwnersSet, setSelectedAccountTypeItems, setSelectedCurrencyItems, setSelectedLimitationItems, setSelectedUserItems, setStatusFilterOption } = accountsPageSlice.actions

export default accountsPageSlice.reducer


