import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ISearchBoxProps, DialogFooter, CheckboxVisibility } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../../../app/Hooks"
import { getPage } from "../../../../../../app/Pages"
import { setPage } from "../../../../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../../../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../../../../common/Table/Table"
import { reset, dismissMessage, setSort, setNumberOfResults, resetSet, setSelectedItems, setSearchText, setActivityFilterOption, setIsFilteredSet, addItem, updateItem, resetAccountTypes, setIsFilteredAccountTypesSet, setOperationFilterOption, setSelectedAccountType, getItems, deleteItem, updateActivity, getAccountTypes, setMessage, setDirectReservationFilterOption } from "./TreasuryTypeRulesPageSlice"
import { ApiMessage, getHeaders } from "../../../../../../app/Api"
import { useId, useBoolean } from '@fluentui/react-hooks';
import * as google_protobuf_wrappers_pb from 'google-protobuf/google/protobuf/wrappers_pb'
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { getMainTheme } from "../../../../../../app/Themes"
import { SearchField } from "../../../../../common/SearchField/SearchField"
import { formatDate, getOperations, printComponent, normalizeKey } from "../../../../../../app/Helpers"
import { TableState } from "../../../../../common/Table/TableSate"
import { Message } from "../../../../../common/Message/Message"
import { ShareBox } from "../../../../../common/ShareBox/ShareBox"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { bool, string } from "yup"
import { Popup } from "../../../../../common/Popup/Popup"
import { FormType } from "../../../../../forms/FormProps"
import { GetAccountTypeRulesRequest, GetAccountTypeRulesResponse, DeleteAccountTypeRuleRequest, DeleteAccountTypeRuleResponse, UpdateAccountTypeRuleActivityRequest, UpdateAccountTypeRuleActivityResponse } from "../../../../../../repository/Accountant/account_type_rule_pb"
import { Operations } from "../../../../../../app/Enums"
import { GetAccountTypesRequest } from "../../../../../../repository/Accountant/account_type_pb"
import { AccountTypeRulesForm } from "../../../../../forms/Accountant/AccountTypes/AccounetTypeRulesForm/AccounetTypeRulesForm"
import { DeleteTreasuryTypeRuleRequest, GetTreasuryTypeRulesRequest, UpdateTreasuryTypeRuleActivityRequest } from "../../../../../../repository/Accountant/treasury_type_rule_pb"
import { TreasuryTypeRulesForm } from "../../../../../forms/Accountant/TreasuryTypes/TreasuryTypeRulesForm/TreasuryTypeRulesForm"
import { useReactToPrint } from "react-to-print"
import { PageProps } from "../../../../PageProps"
import { List } from "../../../../../common/List/List"
import { AccountTypesPage } from "../../../AccountTypes/AccountTypesPage"

const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};

let req: GetTreasuryTypeRulesRequest;
let debetTypesReq: GetAccountTypesRequest;

let org: number = -1;

let getItemsPromise: any;
let getFilterPromise: any;
let actionPromise: any;

interface TreasuryTypeRulesPageProps {
    treasuryType: any
}

export const TreasuryTypeRulesPage: React.FunctionComponent<TreasuryTypeRulesPageProps & PageProps> = (props) => {
    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const state: {
        isChangeStateLoading: boolean,
        set: TableState,
        message: ApiMessage | undefined,
        appTheme: string, isDarkModeEnabled: boolean,
        searchText: string | undefined,
        activityFilterOption: { key: number, text: string },
        directReservationFilterOption: { key: number, text: string },

        filters: any[],
        operationFilterOption: { key: number, text: string }
        accountTypes: TableState
    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.treasuryTypeRulesPage.isChangeStateLoading,
            set: state.treasuryTypeRulesPage.set,
            message: state.treasuryTypeRulesPage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            searchText: state.treasuryTypeRulesPage.searchText,
            accountTypes: state.treasuryTypeRulesPage.accountTypes,
            operationFilterOption: state.treasuryTypeRulesPage.operationFilterOption,
            filters: state.treasuryTypeRulesPage.filters,
            activityFilterOption: state.treasuryTypeRulesPage.activityFilterOption,
            directReservationFilterOption: state.treasuryTypeRulesPage.directReservationFilterOption
        }
    })
    const calloutTargetId = useId('callout-target');
    const labelId = useId('callout-label');
    const deleteActionConfirmationcalloutTargetId = useId('delete-action-callout-target');
    const activityActionConfirmationcalloutTargetId = useId('activity-action-callout-target');
    const ActionConfirmationLabelId = useId('action-callout-label');
    const shareCalloutLabelId = useId('share-callout-label');
    const shareTargetId = useId('share-callout-target');

    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const [isActionConfirmationCalloutVisible, { toggle: toggleisActionConfirmationCalloutVisible }] = useBoolean(false);
    const [isShareCalloutVisible, { toggle: toggleisShareCalloutVisible }] = useBoolean(false);

    const [currentAction, setCurrenctAction] = useState(0)
    const ref = useRef<HTMLDivElement>(null);

    const handlePrint = useReactToPrint({
        content: () => {
            return printComponent(ref.current?.cloneNode(true), true)
        }

    });

    const getActionId = (action: number): string => {
        if (action == 1) {
            return deleteActionConfirmationcalloutTargetId;
        } else if (action == 2) {
            return activityActionConfirmationcalloutTargetId;
        } else {
            return ""
        }
    }

    const _selection = useConst(() => new Selection({
        onSelectionChanged: () => {
            if (_selection.count > 0) {
                dispatch(setSelectedItems(_selection.getSelection()));
            } else {
                dispatch(setSelectedItems([]));
            }
        },

    }));

    const clearFilter = () => {
        dispatch(setSelectedAccountType([]))
        req.setAccounttype(undefined)
        dispatch(setOperationFilterOption({ key: 0, text: "" }))
        req.setOperation(undefined)
        dispatch(setActivityFilterOption({ key: 0, text: "" }))
        req.setIsactive(undefined)
        dispatch(setDirectReservationFilterOption({ key: 0, text: "" }))
        req.setDirectreservation(undefined)
        
    }

    const search = (searchText: string | undefined) => {
        let isFiltered: boolean = false;
        if (searchText) {
            const wrapper = new StringValue();
            wrapper.setValue(searchText.trim());
            req.setSearch(wrapper)
            isFiltered = true;
        } else {
            req.setSearch(undefined)
        }


        if (state.accountTypes.selected.length > 0) {
            const wrapper = new StringValue();
            wrapper.setValue((state.accountTypes.selected.at(0)?.id));
            req.setAccounttype(wrapper)
            isFiltered = true;
        } else {
            req.setAccounttype(undefined)
        }

        if (state.operationFilterOption.key != 0) {
            const wrapper = new Int32Value();
            wrapper.setValue(state.operationFilterOption.key);
            req.setOperation(wrapper)
            isFiltered = true;

        } else {
            req.setOperation(undefined)
        }

        
        if (state.directReservationFilterOption.key != 0) {
            const wrapper = new BoolValue();
            wrapper.setValue(state.directReservationFilterOption.key == 1);
            req.setDirectreservation(wrapper)
            isFiltered = true;

        } else {
            req.setDirectreservation(undefined)
        }
        if (state.activityFilterOption.key != 0) {
            const wrapper = new BoolValue();
            wrapper.setValue(state.activityFilterOption.key == 1);
            req.setIsactive(wrapper)
            isFiltered = true;

        } else {
            req.setIsactive(undefined)
        }
        req.setNextto(undefined)
        dispatch(resetSet())
        dispatch(setIsFilteredSet(isFiltered))
        getItemsPromise?.abort()
        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))
    }


    useEffect(() => {
        req = new GetTreasuryTypeRulesRequest();
        debetTypesReq = new GetAccountTypesRequest();
        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                req.setOrganization(wrapper)
                debetTypesReq.setOrganization(wrapper)
            }
        }

        req.setNextto(undefined)
        req.setNumofresults(state.set.numberOfResults)
        req.setOrder(state.set.isDescending)
        const wrapper = new StringValue();
        wrapper.setValue(props.treasuryType?.id);
        req.setTreasurytype(wrapper)


        debetTypesReq.setNumofresults(state.accountTypes.numberOfResults)
        debetTypesReq.setOrder(state.accountTypes.isDescending)
        const boolv = new BoolValue();
        boolv.setValue(true);
      //  debetTypesReq.setIsavailable(boolv)

        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

        dispatch(setMessage({ body: t("treasuryRuleDesc") + " " + props.treasuryType?.name, type: 4, title: "", data: undefined }))


        return () => { //clean up
            getItemsPromise?.abort();
            getFilterPromise?.abort();
            actionPromise?.abort();
            dispatch(reset());
        }
    }, [])


    const c: IColumn[] = [
        {
            key: 'rule',
            name: t("id"),
            minWidth: 20,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <Link onClick={(e) => {
                    if (e && !props.isSelection) {
                        e.preventDefault();
                        navigate("/treasury-type-rules/" + item?.id + ((org == -1) ? "" : "?org=" + org));
                    }
                }
                } href={"/treasury-type-rules/" + item?.id + ((org == -1) ? "" : "?org=" + org)} target={props.isSelection ? "_blank" : undefined} > {item?.id}</Link >;
                //return <Link href="/" underline >{item.name}</Link>;
            },
        },
        {
            key: 'operation',
            name: t("operation"),
            minWidth: 20,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.operation != undefined)
                    return <Text>{t(getOperations().find(x => x.key == item?.operation)?.text ?? item?.operation)}</Text>;

                //return <Link href="/" underline >{item.name}</Link>;
            },
        },

        {
            key: 'accountType',
            name: t("accountType"),
            minWidth: 20,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <Link onClick={(e) => {
                    if (e && !props.isSelection) {
                        e.preventDefault();
                        navigate("/account-types/" + item?.accountTypeId + ((org == -1) ? "" : "?org=" + org));
                    }
                }
                } href={"/account-types/" + item?.accountTypeId + ((org == -1) ? "" : "?org=" + org)} target={props.isSelection ? "_blank" : undefined}> {item?.accountTypeName == "" ? item?.accountTypeId : item?.accountTypeName}</Link >;
                //return <Link href="/" underline >{item.name}</Link>;
            },
        },

        {
            key: 'directReservation',
            name: t("directReservation"),
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.directReservation != undefined)
                    return <Text>{item?.directReservation ? t("enabled"): t("disabled")}</Text>;
                //return <Link href="/" underline >{item.name}</Link>;
            },
        },

        {
            key: 'isActive',
            name: t("status"),
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.isActive != undefined)
                    return <Text>{item?.isActive ? t("active") : t("inactive")}</Text>;
            },
        },


        {
            key: 'createdDate',
            name: t("createdDate"),
            minWidth: 20,
            maxWidth: 60,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {

                return <Text>{(item?.createdDate)}</Text>; //{item.createdDate}
            },
        },



    ];

    const _selectionItems: ICommandBarItemProps[] = [
        {
            key: 'edit',
            text: t("edit"),
            title: t('edit'),
            iconProps: { iconName: 'EditSolid12' },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(101)
                }
            }
        },
        {
            key: 'status',
            text: (state.set.selected.length > 0 && state.set.selected.at(0)?.isActive) ? t("deactivate") : t("activate"),
            title: (state.set.selected.length > 0 && state.set.selected.at(0)?.isActive) ? t("deactivate") : t("activate"),
            iconProps: { id: activityActionConfirmationcalloutTargetId, iconName: (state.set.selected.length > 0 && state.set.selected.at(0)?.isActive) ? "Cancel" : "Accept", styles: { root: { fontWeight: (state.set.selected.length > 0 && state.set.selected.at(0)?.isActive) ? undefined : "bold" } } },

            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 2 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 2 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 2 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(2)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },
        {
            key: 'delete',
            text: t("delete"),
            title: t("delete"),

            iconProps: { id: deleteActionConfirmationcalloutTargetId, iconName: "Delete", styles: { root: { fontWeight: "bold" } } },

            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 1 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 1 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 1 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(1)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },
        {
            key: 'logs',
            text: t("logs"),
            iconProps: { iconName: "PageList", styles: { root: { fontWeight: "bold" } } },
            title: t("logs"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/logs?resourceId=" + state.set.selected.at(0)?.id + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },

        {
            key: 'share',
            text: t("share"),
            title: t("share"),
            iconProps: { id: shareTargetId, iconName: 'Share', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                toggleisShareCalloutVisible()
            },

        },

    ];

    const mainOptions: ICommandBarItemProps[] = [
        {
            key: 'newItem',
            text: t('add'),
            title: t("add"),
            iconProps: { iconName: 'Add' },
            onClick: () => {
                //  navigate("add")
                setCurrenctAction(100)
            }
        },
        {
            key: 'refresh',
            text: t('refresh'),
            title: t("refresh"),
            iconProps: { iconName: 'refresh' },
            disabled: state.set.isFetching,
            onClick: () => {
                dispatch(resetSet())
                req.setNextto(undefined)
                //   if (!state.searchText || state.searchText?.trim().length == 0) {
                //     req.setSearch(undefined)
                //}
                getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))
            }
        },
        {
            key: 'print',
            text: t("print"),
            iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
            title: t("print"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    handlePrint();
                }
            }
        },
    ];
    const _farOptions: ICommandBarItemProps[] = [
        {
            key: 'sort',
            text: t('sort'),
            title: t("sort"),
            iconProps: { iconName: 'sort' },
            disabled: state.set.isFetching,
            subMenuProps: {
                items: [{
                    key: 'ascending',
                    text: t('ascendingDate'),
                    title: t("ascendingDate"),
                    canCheck: true,
                    checked: !state.set.isDescending,
                    onClick: () => {
                        dispatch(setSort(false))
                        dispatch(resetSet())
                        req.setOrder(false)
                        req.setNextto(undefined)
                        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

                    }


                }, {
                    key: 'descending',
                    text: t('descendingDate'),
                    title: t("descendingDate"),

                    canCheck: true,
                    checked: state.set.isDescending,
                    onClick: () => {
                        dispatch(setSort(true))
                        dispatch(resetSet())
                        req.setOrder(true)
                        req.setNextto(undefined)
                        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

                    }

                }]
            }
        }


    ];
    const _farBottomOptions: ICommandBarItemProps[] = [
        {
            key: 'results',
            text: t('results') + ': ' + state.set.numberOfResults,
            title: t('results') + ': ' + state.set.numberOfResults,
            iconProps: { iconName: 'ShowResults' },
            subMenuProps: {
                items: [{
                    key: '10',
                    text: '10',
                    title: '10',
                    canCheck: true,
                    checked: (state.set.numberOfResults == 10 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(10))
                        req.setNumofresults(10)
                    }


                }, {
                    key: '50',
                    text: '50',
                    title: '50',
                    canCheck: true,
                    checked: (state.set.numberOfResults == 50 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(50))
                        req.setNumofresults(50)
                    }

                },
                {
                    key: '100',
                    text: '100',
                    title: '100',
                    canCheck: true,
                    checked: (state.set.numberOfResults == 100 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(100))
                        req.setNumofresults(100)
                    }

                }]
            }
        }
    ];



    const debetorTypesSuggestionsProps: ISuggestionsProps<ITag> = {
        suggestionsHeaderText: t('accountType'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.accountTypes.hasMore && !state.accountTypes.isFetching,
        onRenderSuggestion: (props: ITag, suggestionItemProps: ISuggestionItemProps<ITag>) => {
            return <TagItemSuggestion>
                {props?.name ?? props?.key}
            </TagItemSuggestion>
        },
        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.accountTypes.items.findIndex(e => e.id == item.key)
            if (u >= 0) {
                dispatch(setSelectedAccountType([state.accountTypes.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.accountTypes.items.length > 0 && !state.accountTypes.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.accountTypes.items.at(state.accountTypes.items.length - 1).id);
                debetTypesReq.setNextto(wrapper)

                getFilterPromise = dispatch(getAccountTypes({ body: debetTypesReq, headers: getHeaders() }))
            }
        },
        suggestions: state.accountTypes.items.length > 0 ? state.accountTypes.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : [],
        isLoading: state.accountTypes.items.length == 0 ? state.accountTypes.isFetching : false,
        isSearching: state.accountTypes.items.length > 0 ? state.accountTypes.isFetching : false,


    };
    const onDebetorTypesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetAccountTypes())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        debetTypesReq.setSearch(wrapper)
        debetTypesReq.setNextto(undefined)
        dispatch(setIsFilteredAccountTypesSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getAccountTypes({ body: debetTypesReq, headers: getHeaders() }))
        return [];

    }

    return (
        <Stack >
            <Popup isOpen={(currentAction == 100)} title={t("addTreasuryTypeRule")} isBlocking onDismiss={() => { setCurrenctAction(0) }} >
                <TreasuryTypeRulesForm childrenGap={10} maxWidth={280} renderObject={{ treasuryType: props.treasuryType }} type={FormType.ADD} onSuccess={(e) => {

                    setCurrenctAction(0)
                    dispatch(addItem(e))

                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>
            <Popup isOpen={(currentAction == 101)} title={t("editTreasuryTypeRule")} onDismiss={() => { setCurrenctAction(0) }} >

                <TreasuryTypeRulesForm childrenGap={10} maxWidth={280} type={FormType.EDIT} fetchData={true} renderObject={state.set.selected.at(0)} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(updateItem(e))
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Popup isOpen={(currentAction == 1002)} title={t("accountTypes")} onDismiss={() => { setCurrenctAction(0) }} >

                <AccountTypesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedAccountType([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }
            <CommandBar
                items={state.set.selected.length == 0 ? mainOptions : _selectionItems}
                farItems={_farOptions}

            //id={actionConfirmationcalloutTargetId}


            />
            {isShareCalloutVisible ? (
                <Callout
                    ariaLabelledBy={shareCalloutLabelId}
                    target={`#${shareTargetId}`}
                    onDismiss={toggleisShareCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <ShareBox onDismiss={toggleisShareCalloutVisible} title={t("shareTreasuryTypeRule")} value={`https://${window.location.host}/treasury-type-rules/${state.set.selected.at(0).id}` + ((org == -1) ? "" : "?org=" + org)}></ShareBox>
                </Callout>
            ) : null}


            {isActionConfirmationCalloutVisible ? (
                <Callout
                    ariaLabelledBy={ActionConfirmationLabelId}
                    target={`#${getActionId(currentAction)}`}
                    onDismiss={toggleisActionConfirmationCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <Stack tokens={{ padding: '20px 24px ' }}>
                        <Text block variant="xLarge" styles={{ root: { marginBottom: 12, fontWeight: FontWeights.semilight } }}>
                            {(currentAction == 1 ? t("delete") : currentAction == 2 ? (state.set.selected.at(0)?.isActive) ? t("deactivate") : t("activate") : "")}
                        </Text>
                        <Text block variant="small">
                            {(currentAction == 1 ? t("deleteTreasuryTypeRuleDesc") : currentAction == 2 ? (state.set.selected.at(0)?.isActive) ? t("deactivateTreasuryTypeRuleDesc") : t("activateTreasuryTypeRuleDesc") : "")}
                        </Text>
                        {/* This FocusZone allows the user to go between buttons with the arrow keys.
              It's not related to or required for FocusTrapCallout's built-in focus trapping. */}
                        <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                            <Stack reversed tokens={{ childrenGap: 8, padding: "20px 0px 0px 0px" }} horizontal>
                                <DefaultButton autoFocus onClick={toggleisActionConfirmationCalloutVisible}>{t("cancel")}</DefaultButton>

                                <PrimaryButton onClick={() => {
                                    toggleisActionConfirmationCalloutVisible()
                                    if (state.set.selected.length > 0) {
                                        if (currentAction == 1) {
                                            let req = new DeleteTreasuryTypeRuleRequest();
                                            req.setId(state.set.selected.at(0)?.id)
                                            actionPromise?.abort();
                                            actionPromise = dispatch(deleteItem({ body: req, headers: getHeaders() }))
                                            actionPromise.unwrap().then((e: any) => {
                                                _selection.setAllSelected(false)
                                            })
                                        } else if (currentAction == 2) {
                                            let req = new UpdateTreasuryTypeRuleActivityRequest();
                                            req.setId(state.set.selected.at(0)?.id)
                                            req.setIsactive(!state.set.selected.at(0)?.isActive)
                                            actionPromise?.abort();
                                            actionPromise = dispatch(updateActivity({ body: req, headers: getHeaders() }))
                                            actionPromise.unwrap().then((e: any) => {
                                                _selection.setAllSelected(false)
                                            })

                                        }
                                    }
                                }} text={(currentAction == 1 ? t("delete") : currentAction == 2 ? (state.set.selected.at(0)?.isActive) ? t("deactivate") : t("activate") : "")}  >

                                </PrimaryButton>
                            </Stack>
                        </FocusZone>
                    </Stack>
                </Callout>
            ) : null}

            {isCalloutVisible ? (
                <Callout
                    ariaLabelledBy={labelId}

                    target={`#${calloutTargetId}`}
                    onDismiss={toggleIsCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    isBeakVisible={true}
                >
                    <Stack tokens={{ padding: 28, childrenGap: 30 }}  >
                        <Text block variant="mediumPlus" >
                            {t("filterDesc")}
                        </Text>

                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label >{t("accountType")}</Label>
                            <List
                                styles={{ root: { width: 268 } }}
                                inputProps={{ placeholder: t("accountType") }}
                                suggestionsHeaderText={t('accountTypes')}
                                isLoading={state.accountTypes.items.length == 0 ? state.accountTypes.isFetching : false}
                                isSearching={state.accountTypes.items.length > 0 ? state.accountTypes.isFetching : false}
                                moreSuggestionsAvailable={state.accountTypes.hasMore && !state.accountTypes.isFetching}
                                suggestions={state.accountTypes.items.length > 0 ? state.accountTypes.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : []}
                                onGetMoreResults={() => {
                                    if (state.accountTypes.items.length > 0 && !state.accountTypes.isFetching) {
                                        const wrapper = new StringValue();
                                        wrapper.setValue(state.accountTypes.items.at(state.accountTypes.items.length - 1).id);
                                        debetTypesReq.setNextto(wrapper)
                                        getFilterPromise = dispatch(getAccountTypes({ body: debetTypesReq, headers: getHeaders() }))
                                    }
                                }}
                                onSuggestionClick={(ev?, item?: any, index?: number) => {
                                    var u = state.accountTypes.items.findIndex(e => e.id == item.key)
                                    if (u >= 0) {
                                        dispatch(setSelectedAccountType([state.accountTypes.items.at(u)]))
                                    }
                                }}
                                isPeoplePicker={false}
                                selectedItems={state.accountTypes.selected.length > 0 ? state.accountTypes.selected.map(e => { return { name: e.name, key: e.id } as ITag }) : []}
                                onChange={(e) => {
                                    dispatch(setSelectedAccountType([]))

                                }}
                                onEmptyInputFocus={() => {
                                    dispatch(resetAccountTypes())
                                    debetTypesReq.setSearch(undefined)
                                    debetTypesReq.setNextto(undefined)
                                    dispatch(setIsFilteredAccountTypesSet(false))
                                    getFilterPromise?.abort()
                                    getFilterPromise = dispatch(getAccountTypes({ body: debetTypesReq, headers: getHeaders() }))
                                    return []
                                }}
                                onFilterChanged={onDebetorTypesFilterChanged}
                                endButtons={[
                                    {
                                        title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                        onClick: () => {
                                            setCurrenctAction(1002)

                                        }
                                    }]}
                            />

                        </Stack>

                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label>{t("operation")}</Label>
                            <Dropdown
                                selectedKey={state.operationFilterOption.key}
                                options={[{ key: 0, text: t("all") }].concat(getOperations(undefined, 3, false).map(o => {
                                    return { key: o.key, text: t(normalizeKey(o.text)) }
                                }))}
                                styles={{ root: { width: 300 } }}
                                onChange={((e: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {

                                    dispatch(setOperationFilterOption({ key: Number(item?.key), text: t("operation") + ":" + item?.text ?? "" }))

                                })}

                            />
                        </Stack>
                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label>{t("directReservation")}</Label>
                            <Dropdown
                                selectedKey={state.directReservationFilterOption.key}
                                options={[{ key: 0, text: t("enabledAndDisabled") }, { key: 1, text: t("enabled") }, { key: 2, text: t("disabled") }]}
                                styles={{ root: { width: 300 } }}
                                onChange={((e: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
                                    dispatch(setDirectReservationFilterOption({ key: Number(item?.key), text: t("directReservation") + ":" + item?.text ?? "" }))

                                })}

                            />
                        </Stack>
                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label>{t("status")}</Label>
                            <Dropdown
                                selectedKey={state.activityFilterOption.key}
                                options={[{ key: 0, text: t("activeAndInactive") }, { key: 1, text: t("active") }, { key: 2, text: t("inactive") }]}
                                styles={{ root: { width: 300 } }}
                                onChange={((e: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
                                    dispatch(setActivityFilterOption({ key: Number(item?.key), text: t("status") + ":" + item?.text ?? "" }))

                                })}

                            />
                        </Stack>
                   
                        <DialogFooter>
                            <PrimaryButton text={t("search")} onClick={(e => {
                                search(state.searchText);
                                toggleIsCalloutVisible();
                            })} />
                            <DefaultButton text={t("clearFilter")} onClick={() => {
                                clearFilter();
                            }} />

                        </DialogFooter>
                    </Stack>
                </Callout>
            ) : null}

            <Stack.Item align="center"  >
                <Stack horizontal  >
                    <SearchField
                        inputProps={{
                            maxLength: 250,
                            placeholder: t("search"),
                        }}

                        onInputChange={(e) => {
                            dispatch(setSearchText(e));
                            return e;
                        }}
                        endButtons={[
                            { id: calloutTargetId, title: t("filter"), iconProps: { iconName: "filter" }, onClick: toggleIsCalloutVisible },
                            {
                                title: t("search"), checked: true, iconProps: { iconName: "search" }, onClick: () => {
                                    search(state.searchText);
                                    if (isCalloutVisible)
                                        toggleIsCalloutVisible();

                                }
                            }]}
                        onChange={(e) => {
                            const res = state.filters.filter(({ key: key1 }) => !e?.some(({ key: key2 }) => key1 === key2));
                            res?.forEach(e => {
                                if (e.key == 1) {
                                    dispatch(setSelectedAccountType([]))
                                    req.setAccounttype(undefined)
                                }
                                if (e.key == 2) {
                                    dispatch(setActivityFilterOption({ key: 0, text: "" }))
                                    req.setIsactive(undefined)
                                }
                                if (e.key == 3) {
                                    dispatch(setOperationFilterOption({ key: 0, text: "" }))
                                    req.setOperation(undefined)
                                }
                                if (e.key == 4) {
                                    dispatch(setDirectReservationFilterOption({ key: 0, text: "" }))
                                    req.setDirectreservation(undefined)
                                }

                                
                            });
                        }}

                        onResolveSuggestions={() => { return [] }}
                        selectedItems={state.filters.map(e => { return { name: e.name, key: e.key } as ITag }) || []}
                        onSearch={(e) => {
                            search(state.searchText);
                            if (isCalloutVisible)
                                toggleIsCalloutVisible();
                        }}
                        onClear={(e) => {
                            dispatch(setSearchText(undefined))
                            if (state.set.isFilteredSet) {
                                search(undefined);
                            }
                            /*if (req.getSearch() && !state.managers.isFilteredSet) {
                                dispatch(resetRoles())
                                req.setNextto(undefined)
     
                                req.setSearch(undefined)
     
                                getManagersPromise = dispatch(getManagerUsers({ body: req, headers: getHeaders() }))
                            }*/
                        }}
                        showIcon
                    />
                </Stack>
            </Stack.Item>



            <Table
                setKey="treasuryTypeRules"
                items={state.set.items}
                columns={c}
                checkboxVisibility={props.isSelection ? CheckboxVisibility.always : CheckboxVisibility.onHover}
                compRef={ref}
                onScroll={(e) => {
                    const bottom = e?.currentTarget?.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight;
                    if (bottom && !state.set.isFetching && state.set.hasMore) {
                        const wrapper = new StringValue();
                        wrapper.setValue(state.set.items.at(state.set.items.length - 11)?.id);
                        req.setNextto(wrapper)
                        getItemsPromise?.abort();
                        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

                    }
                }}
                selection={_selection}
                width={props.isSelection ? '80vw' : undefined}
                height={props.isSelection ? "60vh" : state.message == undefined ? "calc(100vh - 225px)" : "calc(100vh - 273px)"}
                selectionMode={SelectionMode.single}
                disableSelectionZone={state.isChangeStateLoading}
                enableShimmer={state.set.items.length == 0 && state.set.isFetching}
                isColumnSortingEnabled={state.set.selected.length == 0}
                layoutMode={props.isSelection ? DetailsListLayoutMode.fixedColumns : DetailsListLayoutMode.justified}
                numberOfResultsOptions={[10, 50, 100]}
                selectedNumberOfResults={state.set.numberOfResults}
                onNumberOfResultsChange={(e) => {
                    dispatch(setNumberOfResults(e))
                    req.setNumofresults(e)
                }}
                onRowDidMount={(item?: any, index?: number) => {
                    //alert(index)
                    if (index == state.set.items.length - 11) { // last element renderd
                        const { current } = ref;
                        if (current) {
                            if (state.set.items.length > 0 && !state.set.isFetching && state.set.hasMore) {
                                if (current.scrollHeight == current.clientHeight) { // scroll not shown? get more data 
                                    const wrapper = new StringValue();
                                    wrapper.setValue(state.set.items.at(state.set.items.length - 11)?.id);
                                    req.setNextto(wrapper)
                                    getItemsPromise?.abort();
                                    getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))
                                }


                            }
                        }
                    }
                }}
            />

            {props.isSelection ? <DialogFooter>
                <PrimaryButton disabled={state.set.isFetching || state.set.selected.length == 0} text={state.set.isFetching ? undefined : t("select")} onClick={() => {
                    if (props.onItemSelected && state.set.selected.length > 0) {
                        props.onItemSelected(state.set.selected.at(0))
                    }
                }} >

                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.set.isFetching ? "block" : "none") } }} />

                </PrimaryButton>
                <DefaultButton disabled={state.set.isFetching} text={t("discard")} onClick={() => {
                    if (props.onCancel) {
                        props.onCancel()
                    }
                }} />
            </DialogFooter> : undefined}
        </Stack>



    );
}




