import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, refreshSession, getSession, ACCOUNTANT_API_URL, TRADING_API_URL } from '../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../app/Helpers';
import { AccountTypeEndpointClient } from '../../../../repository/Accountant/Account_typeServiceClientPb';
import { GetAccountTypesRequest, GetAccountTypesResponse } from '../../../../repository/Accountant/account_type_pb';
import { CurrencyEndpointClient } from '../../../../repository/Accountant/CurrencyServiceClientPb';
import { GetCurrenciesRequest, GetCurrenciesResponse } from '../../../../repository/Accountant/currency_pb';
import { LimitationEndpointClient } from '../../../../repository/Accountant/LimitationServiceClientPb';
import { GetLimitationsRequest, GetLimitationsResponse } from '../../../../repository/Accountant/limitation_pb';
import { RoleEndpointClient } from '../../../../repository/UserManagement/Customers/RoleServiceClientPb';
import { GetRolesRequest, GetRolesResponse } from '../../../../repository/UserManagement/Customers/role_pb';
import { UserTypeEndpointClient } from '../../../../repository/UserManagement/Customers/UsertypeServiceClientPb';
import { GetUserTypesRequest, GetUserTypesResponse } from '../../../../repository/UserManagement/Customers/usertype_pb';
import { OrganizationClientPortalSettingEndpointClient } from '../../../../repository/UserManagement/OrganizationclientportalsettingServiceClientPb';
import {
    GetOrganizationClientPortalSettingRequest, GetOrganizationClientPortalSettingResponse, RemoveOpenAccountDefaultConfigurationRequest,
    UpdateOpenAccountDefaultConfigurationRequest, UpdateOpenAccountDefaultConfigurationResponse, UpdateURLsRequest, UpdateURLsResponse
    , RemoveOpenAccountDefaultConfigurationResponse, UpdateCurrencyPairsConfigurationResponse, UpdateClientDefaultConfigurationRequest, UpdateClientDefaultConfigurationResponse, UpdateCanSignUpRequest, UpdateCanSignUpResponse, UpdateCanOpenAccountResponse, UpdateCanOpenAccountRequest, UpdateCurrencyPairsConfigurationRequest, UpdateExternalTransfersEntitiesConfigurationResponse, UpdateExternalTransfersEntitiesConfigurationRequest,
} from '../../../../repository/UserManagement/organizationclientportalsetting_pb';
import { TableState } from '../../../common/Table/TableSate';
import { CurrencyPairEndpointClient, } from '../../../../repository/Trading/Currency_pairServiceClientPb';
import { GetCurrencyPairsRequest, GetCurrencyPairsResponse } from '../../../../repository/Trading/currency_pair_pb';
import { AccountEndpointClient } from '../../../../repository/Accountant/AccountServiceClientPb';
import { GetAccountsRequest, GetAccountsResponse } from '../../../../repository/Accountant/account_pb';


export interface OrganizationClientPortalSettingFormState {
    stage1: any,
    message?: ApiMessage,
    isLoading: boolean
    currencies: TableState
    accountTypes: TableState
    limitations: TableState
    currencyPairs: TableState
    accounts: TableState


}

const initialState: OrganizationClientPortalSettingFormState = {
    stage1: undefined,
    message: undefined,
    isLoading: false,
    currencies: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    accountTypes: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    limitations: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    currencyPairs: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    accounts: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },

}

const client = new OrganizationClientPortalSettingEndpointClient(API_URL, null, null);
const clientCurrency = new CurrencyEndpointClient(ACCOUNTANT_API_URL, null, null);
const clientAccountType = new AccountTypeEndpointClient(ACCOUNTANT_API_URL, null, null);
const clienLimition = new LimitationEndpointClient(ACCOUNTANT_API_URL, null, null);
const clientCurrencyPair = new CurrencyPairEndpointClient(TRADING_API_URL, null, null);
const clientAccount = new AccountEndpointClient(ACCOUNTANT_API_URL, null, null);


export const getCurrencyPairs = createAsyncThunk<GetCurrencyPairsResponse.AsObject, APIRequest<GetCurrencyPairsRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/organizationClientPortalSetting/currencyPairs/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await clientCurrencyPair.getCurrencyPairs(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getAccounts = createAsyncThunk<GetAccountsResponse.AsObject, APIRequest<GetAccountsRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/organizationClientPortalSetting/externalTransfers/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clientAccount.getAccounts(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)



export const updateOpenAccountDefaultConfiguration = createAsyncThunk<UpdateOpenAccountDefaultConfigurationResponse.AsObject, APIRequest<UpdateOpenAccountDefaultConfigurationRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/organizationClientPortalSetting/updateOpenAccountDefaultConfiguration',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.updateOpenAccountDefaultConfiguration(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)



export const updateCurrencyPairConfiguration = createAsyncThunk<UpdateCurrencyPairsConfigurationResponse.AsObject, APIRequest<UpdateCurrencyPairsConfigurationRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/organizationClientPortalSetting/updateCurrencyPairConfiguration',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.updateCurrencyPairsConfiguration(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

export const updateExternalTransferEntityConfig = createAsyncThunk<UpdateExternalTransfersEntitiesConfigurationResponse.AsObject, APIRequest<UpdateExternalTransfersEntitiesConfigurationRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/organizationClientPortalSetting/updateExternalTransferEntityConfig',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.updateExternalTransfersEntitiesConfiguration(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)




export const getCurrencies = createAsyncThunk<GetCurrenciesResponse.AsObject, APIRequest<GetCurrenciesRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/organizationClientPortalSetting/currencies/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await clientCurrency.getCurrencies(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getAccountTypes = createAsyncThunk<GetAccountTypesResponse.AsObject, APIRequest<GetAccountTypesRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/organizationClientPortalSetting/accountTypes/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clientAccountType.getAccountTypes(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

export const getLimitations = createAsyncThunk<GetLimitationsResponse.AsObject, APIRequest<GetLimitationsRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/organizationClientPortalSetting/limitations/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clienLimition.getLimitations(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const organizationClientPortalSettingFormSlice = createSlice({
    name: 'forms/organizationClientPortalSetting',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.stage1 = initialState.stage1;
            state.accountTypes = initialState.accountTypes;
            state.currencies = initialState.currencies;
            state.limitations = initialState.limitations;
            state.currencyPairs = initialState.currencyPairs;
            state.accounts = initialState.accounts;


        },

        setStage1: (state, action: PayloadAction<any>) => {
            let stage1 = {

                currencyId: action.payload.currencyId,
                currencyName: action.payload.currencyName,
                currencyCode: action.payload.currencyCode,
                currencyDecimalPlaces: action.payload.currencyDecimalPlaces,
                currencyForm: action.payload.currencyDecimalPlaces,
                currencySymbol: action.payload.currencySymbol,
                accountTypeId: action.payload.accountTypeId,
                accountTypeName: action.payload.accountTypeName,

                limitationId: action.payload.limitationId,
                limitationName: action.payload.limitationName,

                maxNumberOfAccounts: action.payload.maxNumberOfAccounts,

            }
            state.stage1 = stage1;
        },

        resetItem: (state) => {
            state.stage1 = undefined;

        },

        resetLimitations: (state) => {
            state.limitations.items = [];
            state.limitations.hasMore = true;
        },
        resetCurrencies: (state) => {
            state.currencies.items = [];
            state.currencies.hasMore = true;
        },
        resetAccountTypes: (state) => {
            state.accountTypes.items = [];
            state.accountTypes.hasMore = true;
        },
        resetCurrencyPairsSet: (state) => {
            state.currencyPairs.items = [];
            state.currencyPairs.hasMore = true;
        },
        resetAccountsSet: (state) => {
            state.accounts.items = [];
            state.accounts.hasMore = true;
        },

        setSelectedCurrencyItems: (state, action: PayloadAction<any[]>) => {
            state.currencies.selected = action.payload;

        },
        setSelectedLimitationItems: (state, action: PayloadAction<any[]>) => {
            state.limitations.selected = action.payload;

        },
        setSelectedAccountTypeItems: (state, action: PayloadAction<any[]>) => {
            state.accountTypes.selected = action.payload;

        },


        setIsFilteredCurrenciesSet: (state, action: PayloadAction<boolean>) => {
            state.currencies.isFilteredSet = action.payload;
        },

        setIsFilteredLimitationsSet: (state, action: PayloadAction<boolean>) => {
            state.limitations.isFilteredSet = action.payload;
        },
        setIsFilteredAccountTypesSet: (state, action: PayloadAction<boolean>) => {
            state.accountTypes.isFilteredSet = action.payload;
        },


        setSelectedAccountsItems: (state, action: PayloadAction<any[]>) => {
            state.accounts.selected = action.payload;
        },

        setIsFilteredAccountsSet: (state, action: PayloadAction<boolean>) => {
            state.accounts.isFilteredSet = action.payload;
        },

        setSelectedCurrencyPairsItems: (state, action: PayloadAction<any[]>) => {
            state.currencyPairs.selected = action.payload;
        },

        setIsFilteredCurrencyPairSet: (state, action: PayloadAction<boolean>) => {
            state.currencyPairs.isFilteredSet = action.payload;
        },

    },
    extraReducers: (builder) => {



        builder.addCase(getAccountTypes.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.accounttypesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])

                if (r.length < state.accountTypes.numberOfResults) {
                    state.accountTypes.hasMore = false;
                }
                state.accountTypes.items = state.accountTypes.items.concat(r);

            } else {
                state.accountTypes.hasMore = false;
            }
            state.accountTypes.isFetching = false

        })
        builder.addCase(getAccountTypes.rejected, (state, action) => {
            state.accountTypes.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getAccountTypes.pending, (state, action) => {
            state.accountTypes.isFetching = true;
            //state.message = undefined;
        })

        builder.addCase(getCurrencies.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.currenciesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        code: val.code?.value ?? "",
                        icon: val.iconsource?.value ?? "",
                        form: val.form?.formid,
                        symbol: val.symbol?.value ?? "",
                        decimalPlaces: val.decimalplaces?.value ?? "",
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.currencies.numberOfResults) {
                    state.currencies.hasMore = false;
                }
                state.currencies.items = state.currencies.items.concat(r);


                //console.log(state.items)

            } else {
                state.currencies.hasMore = false;
            }
            state.currencies.isFetching = false

        })
        builder.addCase(getCurrencies.rejected, (state, action) => {
            state.currencies.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getCurrencies.pending, (state, action) => {
            state.currencies.isFetching = true;
            //state.message = undefined;
        })



        builder.addCase(getCurrencyPairs.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.currencypairsList.map(val => {
                    let rc = {
                        id: val.id,
                        name: val.name?.value,
                        isActive: val.isactive?.value,
                       
                        iconSource: val.iconsource?.value ?? "",
                        categoryId: val.category?.id?.value ?? "",
                        categoryNameAr: val.category?.namear?.value ?? "",
                        categoryNameEn: val.category?.nameen?.value ?? "",
                        buyPriceEquation: val.pricetrigger?.buypriceequation?.value ?? "",
                        sellPriceEquation: val.pricetrigger?.sellpriceequation?.value ?? "",
                        tradingPriceEquation: val.pricetrigger?.tradingpriceequation?.value ?? "",
                        isPriceTriggerActive: val.pricetrigger?.isactive?.value ,
                        mainCurrencyPair: val.pricetrigger?.main?.id?.value ?? "",
                        mainCurrencyPairName: val.pricetrigger?.main?.name?.value ?? "",
                        mainCurrencyPairBaseCurrencyId: val.pricetrigger?.main?.base?.id?.value ?? "",
                        mainCurrencyPairBaseCurrencyName: val.pricetrigger?.main?.base?.name?.value ?? "",
                        mainCurrencyPairBaseCurrencyCode: val.pricetrigger?.main?.base?.code?.value ?? "",
                        mainCurrencyPairBaseCurrencyDecimalPlaces: val.pricetrigger?.main?.base?.decimalplaces?.value ?? undefined,
                        mainCurrencyPairBaseCurrencyForm: val.pricetrigger?.main?.base?.form?.value ?? undefined,
                        mainCurrencyPairBaseCurrencySymbol: val.pricetrigger?.main?.base?.symbol?.value ?? "",
                        mainCurrencyPairQuoteCurrencyId: val.pricetrigger?.main?.quote?.id?.value ?? "",
                        mainCurrencyPairQuoteCurrencyName: val.pricetrigger?.main?.quote?.name?.value ?? "",
                        mainCurrencyPairQuoteCurrencyCode: val.pricetrigger?.main?.quote?.code?.value ?? "",
                        mainCurrencyPairQuoteCurrencyDecimalPlaces: val.pricetrigger?.main?.quote?.decimalplaces?.value ?? undefined,
                        mainCurrencyPairQuoteCurrencyForm: val.pricetrigger?.main?.quote?.form?.value ?? undefined,
                        mainCurrencyPairQuoteCurrencySymbol: val.pricetrigger?.main?.quote?.symbol?.value ?? "",



                        baseCurrencyId: val.base?.id?.value ?? "",
                        baseCurrencyName: val.base?.name?.value ?? "",
                        baseCurrencyCode: val.base?.code?.value ?? "",
                        baseCurrencyDecimalPlaces: val.base?.decimalplaces?.value ?? undefined,
                        baseCurrencyForm: val.base?.form?.value ?? undefined,
                        baseCurrencySymbol: val.base?.symbol?.value ?? "",
                        quoteCurrencyId: val.quote?.id?.value ?? "",
                        quoteCurrencyName: val.quote?.name?.value ?? "",
                        quoteCurrencyCode: val.quote?.code?.value ?? "",
                        quoteCurrencyDecimalPlaces: val.quote?.decimalplaces?.value ?? undefined,
                        quoteCurrencyForm: val.quote?.form?.value ?? undefined,
                        quoteCurrencySymbol: val.quote?.symbol?.value ?? "",
                        buyPrice: val.buyprice?.value,
                        sellPrice: val.sellprice?.value,
                        tradingPrice: val.tradingprice?.value,
                        priceUpdate: formatDate(timestampToDate(val.priceupdate?.seconds, val.priceupdate?.nanos)),
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                    if (rc.name == undefined) {
                        rc.name = (rc?.baseCurrencyName == "" ? rc?.baseCurrencyId : rc?.baseCurrencyName + " (" + rc?.baseCurrencyCode + ")") + " / " + (rc?.quoteCurrencyName == "" ? rc?.quoteCurrencyId : rc?.quoteCurrencyName + " (" + rc?.quoteCurrencyCode + ")")
                    }
                    return rc;
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.currencyPairs.numberOfResults) {
                    state.currencyPairs.hasMore = false;
                }
                state.currencyPairs.items = state.currencyPairs.items.concat(r);
            } else {
                state.currencyPairs.hasMore = false;

            }
            state.currencyPairs.isFetching = false

        })
        builder.addCase(getCurrencyPairs.rejected, (state, action) => {
            state.currencyPairs.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getCurrencyPairs.pending, (state, action) => {
            state.currencyPairs.isFetching = true;
            //state.message = undefined;
        })


        builder.addCase(getLimitations.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.limitationsList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        isActive: val.isactive?.value ?? undefined,
                        type: val.type?.value ?? undefined,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.limitations.numberOfResults) {
                    state.limitations.hasMore = false;
                }
                state.limitations.items = state.limitations.items.concat(r);
            } else {
                state.limitations.hasMore = false;
            }
            state.limitations.isFetching = false

        })
        builder.addCase(getLimitations.rejected, (state, action) => {
            state.limitations.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getLimitations.pending, (state, action) => {
            state.limitations.isFetching = true;
            //state.message = undefined;
        })

        builder.addCase(getAccounts.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.accountsList.map(val => {

                    return {
                        id: val.id,
                        internalId: val.internalid,
                        name: val.name?.value ?? "",
                        code: val.code?.value ?? "",
                        currencyId: val.currency?.id?.value ?? "",
                        currencyName: val.currency?.name?.value ?? "",
                        currencyCode: val.currency?.code?.value ?? "",
                        currencyDecimalPlaces: val.currency?.decimalplaces?.value ?? undefined,
                        currencyForm: val.currency?.form?.value ?? undefined,
                        currencySymbol: val.currency?.symbol?.value ?? "",
                        accountTypeId: val.accounttype?.id?.value ?? "",
                        accountTypeName: val.accounttype?.name?.value ?? "",
                        mirrorBalance: val.mirrorbalance?.value ?? undefined,
                        status: val.status?.value ?? undefined,
                        ownerFirstname: val.owner?.firstname?.value ?? "",
                        ownerLastname: val.owner?.lastname?.value ?? "",
                        ownerMiddlename: val.owner?.middlename?.value ?? "",
                        ownerName: (val.owner?.firstname?.value ?? "") + " " + (val.owner?.lastname?.value ?? ""),
                        ownerId: val.owner?.id?.value ?? "",
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                        isAuthorizationRequired: val?.accountsetting?.isauthorizationrequired,
                        isLinkingEnable: val?.accountsetting?.islinkingenable?.value,
                        isLinkingMetadataSet: val?.accountsetting?.islinkingmetadataset?.value,
                        transferControl: val?.accountsetting?.tansfercontrol?.value ,


                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.accounts.numberOfResults) {
                    state.accounts.hasMore = false;
                }
                state.accounts.items = state.accounts.items.concat(r);
            } else {
                state.accounts.hasMore = false;

            }
            state.accounts.isFetching = false

        })
        builder.addCase(getAccounts.rejected, (state, action) => {
            state.accounts.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getAccounts.pending, (state, action) => {
            state.accounts.isFetching = true;
            // state.message = undefined;
        })


        builder.addCase(updateOpenAccountDefaultConfiguration.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.success?.message);

                //state.item. = payload?.metadata.state
            }
        })
        builder.addCase(updateOpenAccountDefaultConfiguration.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateOpenAccountDefaultConfiguration.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })

        builder.addCase(updateCurrencyPairConfiguration.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.success?.message);

                //state.item. = payload?.metadata.state
            }
        })
        builder.addCase(updateCurrencyPairConfiguration.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateCurrencyPairConfiguration.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })
        builder.addCase(updateExternalTransferEntityConfig.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.success?.message);

                //state.item. = payload?.metadata.state
            }
        })
        builder.addCase(updateExternalTransferEntityConfig.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateExternalTransferEntityConfig.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })


    }
})

export const { reset, dismissMessage, resetItem, resetAccountTypes, resetAccountsSet, setIsFilteredAccountsSet, setSelectedAccountsItems, resetCurrencyPairsSet, setIsFilteredCurrencyPairSet, setSelectedCurrencyPairsItems, resetCurrencies, resetLimitations, setIsFilteredAccountTypesSet, setIsFilteredCurrenciesSet, setIsFilteredLimitationsSet, setSelectedAccountTypeItems, setSelectedCurrencyItems, setSelectedLimitationItems } = organizationClientPortalSettingFormSlice.actions

export default organizationClientPortalSettingFormSlice.reducer