import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, refreshSession, getSession, ACCOUNTANT_API_URL, TRADING_API_URL } from '../../../../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../../../../app/Helpers';
import { CurrencyPairPriceLogEndpointClient } from '../../../../../../../repository/Trading/Currency_pair_price_logServiceClientPb';
import { GetCurrencyPairPriceLogResponse, GetCurrencyPairPriceLogRequest, GetCurrencyPairPriceLogsRequest, DeleteCurrencyPairPriceLogResponse, DeleteCurrencyPairPriceLogRequest } from '../../../../../../../repository/Trading/currency_pair_price_log_pb';


export interface CurrencyPairPriceChangeLogDetailsPageState {
    item: any
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    isLoading: boolean

}

const initialState: CurrencyPairPriceChangeLogDetailsPageState = {
    item: undefined,
    isChangeStateLoading: false,
    message: undefined,
    isLoading: false
}

const client = new CurrencyPairPriceLogEndpointClient(TRADING_API_URL, null, null);

export const get = createAsyncThunk<GetCurrencyPairPriceLogResponse.AsObject, APIRequest<GetCurrencyPairPriceLogRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/trading/currencyPairDetails/currencyPairPriceLogsDetails/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getCurrencyPairPriceLog(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)





export const deleteItem = createAsyncThunk<APIResponse<DeleteCurrencyPairPriceLogResponse.AsObject>, APIRequest<DeleteCurrencyPairPriceLogRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/trading/currencyPairDetails/currencyPairPriceLogs/delete',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.delete(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)

export const currencyPairPriceChangeLogDetailsPageSlice = createSlice({
    name: 'pages/trading/currencyPairDetails/currencyPairPriceLogs',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
        },

        resetItem: (state) => {
            state.item = undefined;

        },

        updateItem: (state, action: PayloadAction<any>) => {
            state.item = action.payload;

        }
    },
    extraReducers: (builder) => {
        builder.addCase(deleteItem.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
            }
        })
        builder.addCase(deleteItem.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(deleteItem.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })


        builder.addCase(get.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {

                let rc = {
                    id: payload.success?.id,
                    buyPrice: payload.success?.buyprice?.value,
                    sellPrice: payload.success?.sellprice?.value ?? undefined,
                    tradingPrice: payload.success?.tradingprice?.value ?? undefined,
                    currencyPairId: payload.success?.currencypair?.id?.value ?? "",
                    currencyPairName: payload.success?.currencypair?.name?.value ?? "",
                    baseCurrencyId: payload.success?.currencypair?.base?.id?.value ?? "",
                    baseCurrencyName: payload.success?.currencypair?.base?.name?.value ?? "",
                    baseCurrencyCode: payload.success?.currencypair?.base?.code?.value ?? "",
                    baseCurrencyDecimalPlaces: payload.success?.currencypair?.base?.decimalplaces?.value ?? undefined,
                    baseCurrencyForm: payload.success?.currencypair?.base?.form?.value ?? undefined,
                    baseCurrencySymbol: payload.success?.currencypair?.base?.symbol?.value ?? "",
                    quoteCurrencyId: payload.success?.currencypair?.quote?.id?.value ?? "",
                    quoteCurrencyName: payload.success?.currencypair?.quote?.name?.value ?? "",
                    quoteCurrencyCode: payload.success?.currencypair?.quote?.code?.value ?? "",
                    quoteCurrencyDecimalPlaces: payload.success?.currencypair?.quote?.decimalplaces?.value ?? undefined,
                    quoteCurrencyForm: payload.success?.currencypair?.quote?.form?.value ?? undefined,
                    quoteCurrencySymbol: payload.success?.currencypair?.quote?.symbol?.value ?? "",
                    trigger: payload.success?.trigger?.value ?? undefined,
                    volumeBase: payload.success?.volumebase?.value ?? undefined,
                    volumeQuote: payload.success?.volumequote?.value ?? undefined,
                    createdDate: formatDate(timestampToDate(payload.success?.createddate?.seconds, payload.success?.createddate?.nanos)),
                    addedBy: (payload.success?.createdby?.firstname?.value ?? "") + " " + (payload.success?.createdby?.lastname?.value ?? ""),
                    addedById: payload.success?.createdby?.id?.value,
                }


                state.item = rc
            }
        })
        builder.addCase(get.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(get.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })
    }
})

export const { reset, dismissMessage, resetItem, updateItem } = currencyPairPriceChangeLogDetailsPageSlice.actions

export default currencyPairPriceChangeLogDetailsPageSlice.reducer