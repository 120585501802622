import { ComboBox, DefaultButton, DialogFooter, Icon, IconButton, IPersonaProps, IPickerItemProps, IStackTokens, ISuggestionItemProps, ISuggestionModel, ISuggestionsProps, ITag, Label, MessageBar, MessageBarType, Modal, NormalPeoplePicker, PeoplePickerItem, Persona, PersonaSize, PrimaryButton, Spinner, SpinnerSize, Stack, TagItemSuggestion, TagPicker, Text, TextField, Toggle, TooltipHost, ValidationState } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { FormEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks";
import { dismissMessage, getAccountTypes, getCurrencies, getLimitations, reset, resetCurrencies, resetLimitations, resetAccountTypes, setIsFilteredAccountTypesSet, setIsFilteredCurrenciesSet, setSelectedAccountTypeItems, setSelectedCurrencyItems, setIsFilteredLimitationsSet, setSelectedLimitationItems, resetItem, updateOpenAccountDefaultConfiguration } from "./OrganizationClientPortalSettingFormSlice";
import * as Yup from 'yup';
import { DynamicInputField, InputField, NumberInputField } from "../../controls/Controls";
import { inputs } from "../../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../../app/Api";
import { Message } from "../../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../../FormProps";
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog";
import { formatDate, timestampToDate } from "../../../../app/Helpers";
import { AuthenticateReply } from "../../../../repository/UserManagement/authentication_pb";
import { DynamicField } from "../../../common/DynamicFields/DynamicFields";
import { ExtraField } from "../../../../repository/Accountant/common_pb";
import { TableState } from "../../../common/Table/TableSate";
import { AccountStatus, LimitationType, Operations } from "../../../../app/Enums";
import { GetAccountTypesRequest } from "../../../../repository/Accountant/account_type_pb"
import { GetCurrenciesRequest } from "../../../../repository/Accountant/currency_pb"
import { GetUsersRequest } from "../../../../repository/UserManagement/Customers/user_pb"
import { GetLimitationsRequest } from "../../../../repository/Accountant/limitation_pb"
import { UpdateOpenAccountDefaultConfigurationRequest, UpdateOpenAccountDefaultConfigurationResponse } from "../../../../repository/UserManagement/organizationclientportalsetting_pb";
import { List } from "../../../common/List/List";
import { Popup } from "../../../common/Popup/Popup";
import { UsersPage } from "../../../pages/Customers/Users/UsersPage";
import { LimitationsPage } from "../../../pages/Accountant/Limitations/LimitationsPage";
import { AccountTypesPage } from "../../../pages/Accountant/AccountTypes/AccountTypesPage";
import { CurrenciesPage } from "../../../pages/Accountant/Currencies/CurrenciesPage";

let req: UpdateOpenAccountDefaultConfigurationRequest;
let limitationReq: GetLimitationsRequest;
let accountTypesReq: GetAccountTypesRequest;
let currenciesReq: GetCurrenciesRequest;
let clintsReq: GetUsersRequest;

let promise: any;
let getFilterPromise: any;
let org: number = -1;
let current: AuthenticateReply.AsObject;

export const OrganizationClientPortalSettingForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();
    const [currentAction, setCurrenctAction] = useState(0)


    const state: { isLoading: boolean, message: ApiMessage | undefined, stage1: any, limitations: TableState, currencies: TableState, accountTypes: TableState } = useAppSelector((state) => {

        return {
            isLoading: state.organizationClientPortalSettingForm.isLoading, message: state.organizationClientPortalSettingForm.message, stage1: state.organizationClientPortalSettingForm.stage1,
            accountTypes: state.organizationClientPortalSettingForm.accountTypes,
            currencies: state.organizationClientPortalSettingForm.currencies,
            limitations: state.organizationClientPortalSettingForm.limitations,

        }
    })

    useEffect(() => {
        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        }

        var l = searchParams.get('org');
        req = new UpdateOpenAccountDefaultConfigurationRequest();
        accountTypesReq = new GetAccountTypesRequest();
        currenciesReq = new GetCurrenciesRequest();
        clintsReq = new GetUsersRequest();
        limitationReq = new GetLimitationsRequest();

        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
                req.setId(Number(current.organization?.id))

            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                req.setId(org)
                accountTypesReq.setOrganization(wrapper)
                currenciesReq.setOrganization(wrapper)
                clintsReq.setOrganization(wrapper)
                limitationReq.setOrganization(wrapper)

            }
        } else {
            req.setId(Number(current.organization?.id))
        }


        const boolv = new BoolValue();
        boolv.setValue(true);
        accountTypesReq.setNumofresults(state.accountTypes.numberOfResults)
        accountTypesReq.setOrder(state.accountTypes.isDescending)
        accountTypesReq.setIsavailable(boolv)
        currenciesReq.setNumofresults(state.currencies.numberOfResults)
        currenciesReq.setOrder(state.currencies.isDescending)
        currenciesReq.setIsavailable(boolv)
        limitationReq.setNumofresults(state.limitations.numberOfResults)
        limitationReq.setOrder(state.limitations.isDescending)
        const type = new Int32Value();
        type.setValue(LimitationType.ACCOUNT_LIMITATION);
        limitationReq.setType(type)
        limitationReq.setIsavailable(boolv)

        if (props.renderObject?.currencyId)
            dispatch(setSelectedCurrencyItems(([{ id: props.renderObject.currencyId, name: props.renderObject.currencyName }])))

        if (props.renderObject?.accountTypeId)
            dispatch(setSelectedAccountTypeItems(([{ id: props.renderObject.accountTypeId, name: props.renderObject.accountTypeName }])))


        if (props.renderObject?.limitationId)
            dispatch(setSelectedLimitationItems(([{ id: props.renderObject.limitationId, name: props.renderObject.limitationName }])))


        return () => { //clean up
            promise?.abort();
            dispatch(reset());
        }
    }, [])

    const accountTypesSuggestionsProps: ISuggestionsProps<ITag> = {
        suggestionsHeaderText: t('accountTypes'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.accountTypes.hasMore && !state.accountTypes.isFetching,
        onRenderSuggestion: (props: ITag, suggestionItemProps: ISuggestionItemProps<ITag>) => {
            return <TagItemSuggestion>
                {props?.name ?? props?.key}
            </TagItemSuggestion>
        },
        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.accountTypes.items.findIndex(e => e.id == item.key)
            if (u >= 0) {
                dispatch(setSelectedAccountTypeItems([state.accountTypes.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.accountTypes.items.length > 0 && !state.accountTypes.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.accountTypes.items.at(state.accountTypes.items.length - 1).id);
                accountTypesReq.setNextto(wrapper)
                getFilterPromise = dispatch(getAccountTypes({ body: accountTypesReq, headers: getHeaders() }))
            }
        },
        suggestions: state.accountTypes.items.length > 0 ? state.accountTypes.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : [],
        isLoading: state.accountTypes.items.length == 0 ? state.accountTypes.isFetching : false,
        isSearching: state.accountTypes.items.length > 0 ? state.accountTypes.isFetching : false,


    };
    const onAccountTypesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetAccountTypes())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        accountTypesReq.setSearch(wrapper)
        accountTypesReq.setNextto(undefined)
        dispatch(setIsFilteredAccountTypesSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getAccountTypes({ body: accountTypesReq, headers: getHeaders() }))
        return [];

    }



    const onCurrenciesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetCurrencies())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        currenciesReq.setSearch(wrapper)
        currenciesReq.setNextto(undefined)
        dispatch(setIsFilteredCurrenciesSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getCurrencies({ body: currenciesReq, headers: getHeaders() }))
        return [];

    }


    const limitationsSuggestionsProps: ISuggestionsProps<ITag> = {
        suggestionsHeaderText: t('limitations'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.limitations.hasMore && !state.limitations.isFetching,
        onRenderSuggestion: (props: ITag, suggestionItemProps: ISuggestionItemProps<ITag>) => {
            return <TagItemSuggestion>
                {props?.name ?? props?.key}
            </TagItemSuggestion>
        },
        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.limitations.items.findIndex(e => e.id == item.key)
            if (u >= 0) {
                dispatch(setSelectedLimitationItems([state.limitations.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.limitations.items.length > 0 && !state.limitations.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.limitations.items.at(state.limitations.items.length - 1).id);
                limitationReq.setNextto(wrapper)
                getFilterPromise = dispatch(getLimitations({ body: limitationReq, headers: getHeaders() }))
            }
        },
        suggestions: state.limitations.items.length > 0 ? state.limitations.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : [],
        isLoading: state.limitations.items.length == 0 ? state.limitations.isFetching : false,
        isSearching: state.limitations.items.length > 0 ? state.limitations.isFetching : false,


    };
    const onLimitationsFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetLimitations())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        limitationReq.setSearch(wrapper)
        limitationReq.setNextto(undefined)
        dispatch(setIsFilteredLimitationsSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getLimitations({ body: limitationReq, headers: getHeaders() }))
        return [];

    }


    return (
        <>

            <Popup isOpen={(currentAction == 101)} title={t("limitations")} onDismiss={() => { setCurrenctAction(0) }} >

                <LimitationsPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedLimitationItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Popup isOpen={(currentAction == 102)} title={t("accountTypes")} onDismiss={() => { setCurrenctAction(0) }} >

                <AccountTypesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedAccountTypeItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Popup isOpen={(currentAction == 103)} title={t("currencies")} onDismiss={() => { setCurrenctAction(0) }} >

                <CurrenciesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedCurrencyItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    currency: undefined,
                    accountType: undefined,
                    limitation: undefined,
                    maxNumberOfAccounts: props?.renderObject?.maxNumberOfAccounts ?? 1,
                    openByDefault: props?.renderObject?.openByDefault ?? false,
                    rank: props?.renderObject?.rank ?? 0,
                    canUpdateName: props?.renderObject?.canUpdateName ?? false,
                    canUpdateCode: props?.renderObject?.canUpdateCode ?? false

                }}

                validationSchema={Yup.object({

                })}

                onSubmit={(values, actions) => {


                    let accountTypes = state.accountTypes.selected.at(0);
                    let currencies = state.currencies.selected.at(0);
                    let limitations = state.limitations.selected.at(0);

                    req.setAccounttype(accountTypes?.id);
                    req.setCurrency(currencies?.id);
                    req.setLimitation(limitations?.id);
                    req.setMaxnumberofaccountscanbeopend(Number(values.maxNumberOfAccounts));
                    req.setOpenbydefault(values.openByDefault)
                    req.setRank(values.rank)
                    req.setCanupdatename(values.canUpdateName)
                    req.setCanupdatecode(values.canUpdateCode)


                    promise = dispatch(updateOpenAccountDefaultConfiguration({ body: req, headers: getHeaders() }))
                    promise.unwrap()
                        .then((res: UpdateOpenAccountDefaultConfigurationResponse.AsObject) => {
                            if (res) {
                                if (props?.onSuccess) {
                                    props?.onSuccess({
                                        currencyId: currencies?.id,
                                        currencyName: currencies?.name,
                                        currencyCode: currencies?.code,
                                        currencyDecimalPlaces: currencies?.decimalPlaces,
                                        currencyForm: currencies?.form,
                                        currencySymbol: currencies?.symbol,
                                        accountTypeId: accountTypes.id,
                                        accountTypeName: accountTypes.name,
                                        limitationId: limitations?.id,
                                        limitationName: limitations?.name,
                                        maxNumberOfAccounts: values.maxNumberOfAccounts,
                                        openByDefault: values.openByDefault,
                                        rank: values.rank,
                                        canUpdateName: values.canUpdateName,
                                        canUpdateCode: values.canUpdateCode


                                    });
                                }
                            }
                            actions.setSubmitting(false)


                        })
                        .catch((error: ApiMessage) => {
                            actions.setSubmitting(false)
                        })

                }}

            >
                {formkikProps => (
                    <Form >
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? (state.message.data != 401) ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            /> :
                                <SessionExpiredDialog />
                                : null
                            }


                            {props.type == FormType.ADD ? <Stack>
                                <Label required
                                    disabled={state.isLoading}>{t("currency")}</Label>
                                <List
                                    inputProps={{ placeholder: t("currency"), required: true }}
                                    disabled={state.isLoading}
                                    suggestionsHeaderText={t('currencies')}
                                    isLoading={state.currencies.items.length == 0 ? state.currencies.isFetching : false}
                                    isSearching={state.currencies.items.length > 0 ? state.currencies.isFetching : false}
                                    moreSuggestionsAvailable={state.currencies.hasMore && !state.currencies.isFetching}
                                    suggestions={state.currencies.items.length > 0 ? state.currencies.items.map(e => { return { item: { key: e.id, text: e.name, imageInitials: e?.symbol, secondaryText: e?.code, imageUrl: e?.icon } } as ISuggestionModel<IPersonaProps> }) : []}                                    onGetMoreResults={() => {
                                        if (state.currencies.items.length > 0 && !state.currencies.isFetching) {
                                            const wrapper = new StringValue();
                                            wrapper.setValue(state.currencies.items.at(state.currencies.items.length - 1).id);
                                            currenciesReq.setNextto(wrapper)
                                            getFilterPromise = dispatch(getCurrencies({ body: currenciesReq, headers: getHeaders() }))
                                        }
                                    }}
                                    onSuggestionClick={(ev?, item?: any, index?: number) => {
                                        var u = state.currencies.items.findIndex(e => e.id == item.key)
                                        if (u >= 0) {
                                            dispatch(setSelectedCurrencyItems([state.currencies.items.at(u)]))
                                        }
                                    }}
                                    isPeoplePicker={true}
                                    selectedItems={state.currencies.selected.length > 0 ? state.currencies.selected.map(e => { return { key: e.id, text: e.name, imageInitials: e?.symbol, secondaryText: e?.code, imageUrl: e?.icon } as IPersonaProps }) : []}
                                    onChange={(e) => {
                                        dispatch(setSelectedCurrencyItems([]))

                                    }}
                                    onEmptyInputFocus={() => {
                                        dispatch(resetCurrencies())
                                        currenciesReq.setSearch(undefined)
                                        currenciesReq.setNextto(undefined)
                                        dispatch(setIsFilteredCurrenciesSet(false))
                                        getFilterPromise?.abort()
                                        getFilterPromise = dispatch(getCurrencies({ body: currenciesReq, headers: getHeaders() }))
                                        return []
                                    }}
                                    onFilterChanged={onCurrenciesFilterChanged}
                                    endButtons={[
                                        {
                                            title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                            onClick: () => {
                                                setCurrenctAction(103)

                                            }
                                        }]}
                                />
                            </Stack> : undefined}

                            {props.type == FormType.ADD ? <Stack>
                                <Label required
                                    disabled={state.isLoading}>{t("accountType")}</Label>
                                <List
                                    inputProps={{ placeholder: t("accountType"), required: true }}
                                    disabled={state.isLoading}
                                    suggestionsHeaderText={t('accountTypes')}
                                    isLoading={state.accountTypes.items.length == 0 ? state.accountTypes.isFetching : false}
                                    isSearching={state.accountTypes.items.length > 0 ? state.accountTypes.isFetching : false}
                                    moreSuggestionsAvailable={state.accountTypes.hasMore && !state.accountTypes.isFetching}
                                    suggestions={state.accountTypes.items.length > 0 ? state.accountTypes.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : []}
                                    onGetMoreResults={() => {
                                        if (state.accountTypes.items.length > 0 && !state.accountTypes.isFetching) {
                                            const wrapper = new StringValue();
                                            wrapper.setValue(state.accountTypes.items.at(state.accountTypes.items.length - 1).id);
                                            accountTypesReq.setNextto(wrapper)
                                            getFilterPromise = dispatch(getAccountTypes({ body: accountTypesReq, headers: getHeaders() }))
                                        }
                                    }}
                                    onSuggestionClick={(ev?, item?: any, index?: number) => {
                                        var u = state.accountTypes.items.findIndex(e => e.id == item.key)
                                        if (u >= 0) {
                                            dispatch(setSelectedAccountTypeItems([state.accountTypes.items.at(u)]))
                                        }
                                    }}
                                    isPeoplePicker={false}
                                    selectedItems={state.accountTypes.selected.length > 0 ? state.accountTypes.selected.map(e => { return { name: e.name, key: e.id } as ITag }) : []}
                                    onChange={(e) => {
                                        dispatch(setSelectedAccountTypeItems([]))

                                    }}
                                    onEmptyInputFocus={() => {
                                        dispatch(resetAccountTypes())
                                        accountTypesReq.setSearch(undefined)
                                        accountTypesReq.setNextto(undefined)
                                        dispatch(setIsFilteredAccountTypesSet(false))
                                        getFilterPromise?.abort()
                                        getFilterPromise = dispatch(getAccountTypes({ body: accountTypesReq, headers: getHeaders() }))
                                        return []
                                    }}
                                    onFilterChanged={onAccountTypesFilterChanged}
                                    endButtons={[
                                        {
                                            title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                            onClick: () => {
                                                setCurrenctAction(102)

                                            }
                                        }]}
                                />

                            </Stack> : undefined}


                            <Stack>
                                <Label required
                                    disabled={state.isLoading}>{t("limitation")}</Label>
                                <List
                                    inputProps={{ placeholder: t("limitation"), required: true }}
                                    disabled={state.isLoading}
                                    suggestionsHeaderText={t('limitations')}
                                    isLoading={state.limitations.items.length == 0 ? state.limitations.isFetching : false}
                                    isSearching={state.limitations.items.length > 0 ? state.limitations.isFetching : false}
                                    moreSuggestionsAvailable={state.limitations.hasMore && !state.limitations.isFetching}
                                    suggestions={state.limitations.items.length > 0 ? state.limitations.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : []}
                                    onGetMoreResults={() => {
                                        if (state.limitations.items.length > 0 && !state.limitations.isFetching) {
                                            const wrapper = new StringValue();
                                            wrapper.setValue(state.limitations.items.at(state.limitations.items.length - 1).id);
                                            limitationReq.setNextto(wrapper)
                                            getFilterPromise = dispatch(getLimitations({ body: limitationReq, headers: getHeaders() }))
                                        }
                                    }}
                                    onSuggestionClick={(ev?, item?: any, index?: number) => {
                                        var u = state.limitations.items.findIndex(e => e.id == item.key)
                                        if (u >= 0) {
                                            dispatch(setSelectedLimitationItems([state.limitations.items.at(u)]))
                                        }
                                    }}
                                    isPeoplePicker={false}
                                    selectedItems={state.limitations.selected.length > 0 ? state.limitations.selected.map(e => { return { name: e.name, key: e.id } as ITag }) : []}
                                    onChange={(e) => {
                                        dispatch(setSelectedLimitationItems([]))

                                    }}
                                    onEmptyInputFocus={() => {
                                        dispatch(resetLimitations())
                                        limitationReq.setSearch(undefined)
                                        limitationReq.setNextto(undefined)
                                        dispatch(setIsFilteredLimitationsSet(false))
                                        getFilterPromise?.abort()
                                        getFilterPromise = dispatch(getLimitations({ body: limitationReq, headers: getHeaders() }))
                                        return []
                                    }}
                                    onFilterChanged={onLimitationsFilterChanged}
                                    endButtons={[
                                        {
                                            title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                            onClick: () => {
                                                setCurrenctAction(101)

                                            }
                                        }]}
                                />


                            </Stack>


                            <Label disabled={state.isLoading}>{
                                <div>
                                    {t("maxNumberOfAccounts") + ' '}
                                    <TooltipHost content={t("maxNumberOfAccountsDesc")}>
                                        <Icon iconName="Info" aria-label={t("maxNumberOfAccounts")} />
                                    </TooltipHost>
                                </div>
                            }</Label>

                            <Field name={`maxNumberOfAccounts`}
                                placeholder={"1"} component={NumberInputField}
                                disabled={state.isLoading} min={0} required />


                            <Label disabled={state.isLoading}>{
                                <div>
                                    {t("rank") + ' '}
                                    <TooltipHost content={t("rankDesc")}>
                                        <Icon iconName="Info" aria-label={t("rank")} />
                                    </TooltipHost>
                                </div>
                            }</Label>

                            <Field name={`rank`}
                                placeholder={"1"} component={NumberInputField}
                                disabled={state.isLoading} max={1000} min={-1000} required />

                            <Label disabled={state.isLoading}>{
                                <div>
                                    {t("openAccountByDefault") + ' '}
                                    <TooltipHost content={t("openAccountByDefaultDesc")}>
                                        <Icon iconName="Info" aria-label={t("openAccountByDefault")} />
                                    </TooltipHost>
                                </div>
                            }</Label>
                            <Toggle

                                onText={t("enabled")} offText={t("disabled")}
                                disabled={state.isLoading}
                                checked={formkikProps.values.openByDefault}
                                onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                                    formkikProps.setFieldValue('openByDefault', checked);
                                }
                                }
                            />
                            <Label disabled={state.isLoading}>{
                                <div>
                                    {t("canUpdateName") + ' '}
                                    <TooltipHost content={t("canUpdateNameDesc")}>
                                        <Icon iconName="Info" aria-label={t("canUpdateName")} />
                                    </TooltipHost>
                                </div>
                            }</Label>
                            <Toggle

                                onText={t("enabled")} offText={t("disabled")}
                                disabled={state.isLoading}
                                checked={formkikProps.values.canUpdateName}
                                onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                                    formkikProps.setFieldValue('canUpdateName', checked);
                                }
                                }
                            />
                            <Label disabled={state.isLoading}>{
                                <div>
                                    {t("canUpdateCode") + ' '}
                                    <TooltipHost content={t("canUpdateCodeDesc")}>
                                        <Icon iconName="Info" aria-label={t("canUpdateCode")} />
                                    </TooltipHost>
                                </div>
                            }</Label>
                            <Toggle

                                onText={t("enabled")} offText={t("disabled")}
                                disabled={state.isLoading}
                                checked={formkikProps.values.canUpdateCode}
                                onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                                    formkikProps.setFieldValue('canUpdateCode', checked);
                                }
                                }
                            />
                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("add") : t("edit")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={props.type == FormType.ADD ? t("discard") : t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>



                    </Form>
                )}
            </Formik>
        </>
    );

}

