import { DefaultButton, Dialog, DialogFooter, DialogType, Icon, IconButton, IPersonaProps, IPickerItemProps, IStackTokens, ISuggestionItemProps, ISuggestionModel, ISuggestionsProps, ITag, Label, MessageBar, MessageBarType, Modal, NormalPeoplePicker, PeoplePickerItem, Persona, PersonaSize, PrimaryButton, SpinButton, Spinner, SpinnerSize, Stack, TagItemSuggestion, TagPicker, Text, TextField, Toggle, TooltipHost, ValidationState } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { FormEventHandler, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks";
import { add, dismissMessage, reset, update, getAccounts, setStage1, get, setSelectedAccountItems, resetAccountsSet, setIsFilteredAccountSet, } from "./ReservedBalancesFormSlice";
import * as Yup from 'yup';
import { DynamicInputField, DynamicNumberInputField, InputField, NumberInputField } from "../../controls/Controls";
import { inputs } from "../../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../../app/Api";
import { Message } from "../../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../../FormProps";
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog";
import { currencyFormatter, formatDate, timestampToDate } from "../../../../app/Helpers";
import { AuthenticateReply } from "../../../../repository/UserManagement/authentication_pb";
import { DynamicField } from "../../../common/DynamicFields/DynamicFields";
import { ExtraField } from "../../../../repository/Accountant/common_pb";
import { TableState } from "../../../common/Table/TableSate";
import { GetAccountsRequest } from "../../../../repository/Accountant/account_pb";
import { AccountStatus, AuthorizedOperation, ReservationStatus, TreasuryStatus } from "../../../../app/Enums";
import { GetCurrenciesRequest } from "../../../../repository/Accountant/currency_pb";
import { ReserveAmountRequest, ReserveAmountResponse, FreeAmountResponse, FreeAmountRequest, GetBalanceReservationResponse, GetBalanceReservationRequest, UpdateBalanceReservationRequest, UpdateBalanceReservationResponse } from '../../../../repository/Accountant/balance_reservation_pb';
import { List } from "../../../common/List/List";
import { AccountsPage } from "../../../pages/Accountant/Accounts/AccountsPage";
import { Popup } from "../../../common/Popup/Popup";
import { AuthorizationForm } from "../Accounts/AuthorizationForm";


let req: ReserveAmountRequest;
let freeReq: FreeAmountRequest;
let updateReq: UpdateBalanceReservationRequest;

let getAccountsReq: GetAccountsRequest;

let promise: any;
let getFilterPromise: any;

let org: number = -1;
let current: AuthenticateReply.AsObject;


export const ReservedBalancesForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();

    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();
    const [currentAction, setCurrenctAction] = useState(0)


    const state: { isLoading: boolean, message: ApiMessage | undefined, stage1: any, accounts: TableState } = useAppSelector((state) => {

        return {
            isLoading: state.reservedBalancesForm.isLoading, message: state.reservedBalancesForm.message,
            stage1: state.reservedBalancesForm.stage1,
            accounts: state.reservedBalancesForm.accounts,

        }
    })

    useEffect(() => {
        var l = searchParams.get('org');
        req = new ReserveAmountRequest();
        freeReq = new FreeAmountRequest();
        updateReq = new UpdateBalanceReservationRequest();
        getAccountsReq = new GetAccountsRequest();

        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                req.setOrganization(wrapper)
                getAccountsReq.setOrganization(wrapper)
            }
        }
        const boolv = new BoolValue();
        boolv.setValue(true);
        const stat = new Int32Value();
        stat.setValue(AccountStatus.ACTIVE);


        getAccountsReq.setNumofresults(state.accounts.numberOfResults)
        getAccountsReq.setOrder(state.accounts.isDescending)
        getAccountsReq.setStatus(stat)


        if (props.renderObject && props.renderObject?.account) {

            dispatch(setSelectedAccountItems(([props.renderObject?.account])))
        }

        if (props.type == FormType.EDIT) {
            if (props.fetchData) {
                let r = new GetBalanceReservationRequest()
                r.setId(props?.renderObject?.id);
                promise?.abort()
                promise = dispatch(get({ body: r, headers: getHeaders() }))
            } else {
                dispatch(setStage1(props?.renderObject))
            }
        }


        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        }
        return () => { //clean up
            promise?.abort();
            getFilterPromise?.abort();
            dispatch(reset());
        }
    }, [])



    const accountsSuggestionsProps: ISuggestionsProps<IPersonaProps> = {
        suggestionsHeaderText: t('accounts'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.accounts.hasMore && !state.accounts.isFetching,
        onRenderSuggestion: (props: IPersonaProps) => {


            return <Persona {...props} size={PersonaSize.size32} showSecondaryText={true} styles={{ root: { margin: 8 } }} />;
        },

        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.accounts.items.findIndex(e => e.internalId == item.key)
            if (u >= 0) {
                dispatch(setSelectedAccountItems([state.accounts.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.accounts.items.length > 0 && !state.accounts.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.accounts.items.at(state.accounts.items.length - 1).internalId);
                getAccountsReq.setNextto(wrapper)
                getFilterPromise = dispatch(getAccounts({ body: getAccountsReq, headers: getHeaders() }))
            }
        },
        suggestions: state.accounts.items.length > 0 ? state.accounts.items.map(e => { return { item: { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } } as ISuggestionModel<IPersonaProps> }) : [],
        isLoading: state.accounts.items.length == 0 ? state.accounts.isFetching : false,
        isSearching: state.accounts.items.length > 0 ? state.accounts.isFetching : false,


    };


    const onAccountsFilterChanged = (filterText: string, selectedItems?: IPersonaProps[]): IPersonaProps[] => {

        dispatch(resetAccountsSet())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        getAccountsReq.setSearch(wrapper)
        getAccountsReq.setNextto(undefined)

        dispatch(setIsFilteredAccountSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getAccounts({ body: getAccountsReq, headers: getHeaders() }))
        return [];

    }


    return (
        <>
            <Popup isOpen={(currentAction == 100)} title={t("accounts")} onDismiss={() => { setCurrenctAction(0) }} >

                <AccountsPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedAccountItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>


            <Formik
                enableReinitialize={true}
                initialValues={{

                    account: undefined,
                    amount: "",
                    note: (state.stage1?.note) ? state.stage1?.note : "",
                    extraFields: (state.stage1?.extraFields) ? state.stage1?.extraFields as DynamicField[] : [] as DynamicField[],

                }}

                validationSchema={props.type == FormType.ADD ? Yup.object({
                    amount: inputs.amount,
                    note: inputs.note,
                    extraFields: inputs.extraFields,


                }) : Yup.object({

                    note: inputs.note,
                    extraFields: inputs.extraFields,

                })}

                onSubmit={(values, actions) => {
                    if (props.type == FormType.ADD) {
                        let account = state.accounts.selected.at(0);
                        if (account.isAuthorizationRequired && req.getAuthcode() == undefined) {
                            setCurrenctAction(13);
                            return;
                        }
                        let amount = "";
                        let formattedCurrency = "";
                        if (values.amount) {
                            let r = Number(Number(values.amount?.replace(/,/g, '')).toFixed(account?.currencyDecimalPlaces ?? 9))
                            if (r <= 0) {
                                actions.setFieldValue("amount", "")
                                return;
                            }
                            formattedCurrency = currencyFormatter(r, { significantDigits: account?.currencyDecimalPlaces ?? 9, symbol: account?.currencySymbol })
                            amount = r + ""
                        }
                        req.setAccount(account.internalId);
                        req.setAmount(amount)
                        req.setNote(values.note)
                        const extraField: ExtraField[] = [];
                        const count: Number = 1;
                        values.extraFields.forEach((e: DynamicField) => {
                            const r = new ExtraField();
                            r.setKey(e.key);
                            r.setLabel(e.label?.trim() ?? "")
                            r.setType(1)
                            r.setValue(e.value.trim())
                            extraField.push(r)
                        })
                        req.setExtrafieldsList(extraField)

                        promise = dispatch(add({ body: req, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: ReserveAmountResponse.AsObject) => {
                                req.setAuthcode(undefined)
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            id: res?.success?.id,
                                            amount: amount,
                                            accountId: account.internalId,
                                            accountName: account.name,
                                            createdDate: formatDate(timestampToDate(res.success?.createddate?.seconds, res.success?.createddate?.nanos)),
                                            addedBy: (current.profile?.firstname?.value ?? "") + " " + (current.profile?.lastname?.value ?? ""),
                                            addedById: current.profile?.id?.value,
                                            note: values.note,
                                            currencyId: account.currencyId,
                                            currencyName: account.currencyName,
                                            currencyCode: account.currencyCode,
                                            currencyDecimalPlaces: account.currencyDecimalPlaces,
                                            currencyForm: account.currencyForm,
                                            currencySymbol: account.currencySymbol,
                                            status: ReservationStatus.RESERVED,

                                        });
                                    }
                                }
                                actions.setSubmitting(false)
                            })
                            .catch((error: ApiMessage) => {
                                req.setAuthcode(undefined)

                                actions.setSubmitting(false)
                            })
                    } else {
                        updateReq.setId(props.renderObject?.id)
                        updateReq.setNote(values.note)
                        const extraField: ExtraField[] = [];
                        const count: Number = 1;
                        values.extraFields.forEach((e: DynamicField) => {
                            const r = new ExtraField();
                            r.setKey(e.key);
                            r.setLabel(e.label?.trim() ?? "")
                            r.setType(1)
                            r.setValue(e.value.trim())
                            extraField.push(r)
                        })
                        updateReq.setExtrafieldsList(extraField)
                        promise = dispatch(update({ body: updateReq, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: UpdateBalanceReservationResponse.AsObject) => {
                                req.setAuthcode(undefined)
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            ...props.renderObject,
                                            note: values.note.trim(),
                                            extraFields: values.extraFields
                                        });
                                    }
                                }
                                actions.setSubmitting(false)
                            })
                            .catch((error: ApiMessage) => {
                                req.setAuthcode(undefined)
                                actions.setSubmitting(false)
                            })
                    }
                }}

            >
                {formkikProps => (

                    <Form >
                        <Popup isOpen={(currentAction == 13)} title={t("authorization")} onDismiss={() => { setCurrenctAction(0) }} >
                            <AuthorizationForm childrenGap={10} maxWidth={280} type={FormType.EDIT} renderObject={{
                                account: state.accounts.selected.at(0),
                                operation: AuthorizedOperation.RESERVE_BALANCE,
                                parameters: [{ key: "Amount", value: formkikProps.values.amount?.replace(/,/g, ''),   viewValue: state.accounts.selected.at(0)?.currencySymbol },
                                ],
                            }} onSuccess={(e) => {
                                if (req && e.authCode) {
                                    if (currentAction == 13) {
                                        var r = new StringValue();
                                        r.setValue(e.authCode)
                                        req.setAuthcode(r)
                                        setCurrenctAction(0)
                                        formkikProps.handleSubmit(e);

                                    }
                                } else {
                                    setCurrenctAction(0)
                                }
                            }} onCancel={() => { setCurrenctAction(0) }} />
                        </Popup>
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? (state.message.data != 401) ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            /> :
                                <SessionExpiredDialog />
                                : null
                            }

                            {props.type == FormType.ADD ? <Stack grow>
                                <Label required disabled={state.isLoading}>{t("account")}</Label>
                                <List
                                    isPeoplePicker={true}
                                    suggestionsHeaderText={t('accounts')}
                                    isLoading={state.accounts.items.length == 0 ? state.accounts.isFetching : false}
                                    isSearching={state.accounts.items.length > 0 ? state.accounts.isFetching : false}
                                    moreSuggestionsAvailable={state.accounts.hasMore && !state.accounts.isFetching}
                                    suggestions={state.accounts.items.length > 0 ? state.accounts.items.map(e => { return { item: { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } } as ISuggestionModel<IPersonaProps> }) : []}
                                    onGetMoreResults={() => {
                                        if (state.accounts.items.length > 0 && !state.accounts.isFetching) {
                                            const wrapper = new StringValue();
                                            wrapper.setValue(state.accounts.items.at(state.accounts.items.length - 1).internalId);
                                            getAccountsReq.setNextto(wrapper)
                                            getFilterPromise = dispatch(getAccounts({ body: getAccountsReq, headers: getHeaders() }))
                                        }
                                    }}
                                    onSuggestionClick={(ev?, item?: any, index?: number) => {
                                        var u = state.accounts.items.findIndex(e => e.internalId == item.key)
                                        if (u >= 0) {
                                            dispatch(setSelectedAccountItems([state.accounts.items.at(u)]))
                                        }

                                    }}
                                    inputProps={{ placeholder: t("account"), required: true }}

                                    disabled={state.isLoading || props.renderObject?.account ? true : false}
                                    selectedItems={state.accounts.selected.length > 0 ? state.accounts.selected.map(e => { return { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } as IPersonaProps }) : []}
                                    onChange={(e) => {
                                        dispatch(setSelectedAccountItems([]))
                                    }}
                                    onEmptyInputFocus={() => {
                                        dispatch(resetAccountsSet())
                                        getAccountsReq.setSearch(undefined)
                                        getAccountsReq.setNextto(undefined)

                                        dispatch(setIsFilteredAccountSet(false))
                                        getFilterPromise?.abort()
                                        getFilterPromise = dispatch(getAccounts({ body: getAccountsReq, headers: getHeaders() }))
                                        return []
                                    }}
                                    onFilterChanged={onAccountsFilterChanged}
                                    endButtons={[
                                        {
                                            title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                            onClick: () => {
                                                setCurrenctAction(100)

                                            }
                                        }]}
                                />

                            </Stack> : undefined}


                            {props.type == FormType.ADD ?
                                <Field name="amount" label={t("amount")} placeholder={t("amount")}
                                    component={InputField} disabled={state.isLoading} maxLength={15}
                                    onBlur={(event: any) => {
                                        let account = state.accounts.selected.at(0);
                                        if (account)
                                            formkikProps.setFieldValue("amount", Number(formkikProps.values.amount?.replace(/,/g, '')).toFixed(account?.currencyDecimalPlaces ?? 9))
                                        else
                                            formkikProps.setFieldValue("amount", Number(formkikProps.values.amount?.replace(/,/g, '')).toFixed(9))
                                        formkikProps.handleBlur(event);
                                    }}
                                    required suffix={state.accounts?.selected?.at(0)?.currencyCode} />


                                : undefined}

                            <Field name="note" label={t("note")} placeholder={t("note")} component={InputField} rows={3} multiline resizable={false} disabled={state.isLoading} maxLength={140} />

                            <Label disabled={state.isLoading}>{
                                <div>
                                    {t("extraFields") + ' '}
                                    <TooltipHost content={t("extraInformationDescription")}>
                                        <Icon iconName="Info" aria-label={t("extraFields")} />
                                    </TooltipHost>
                                </div>
                            }</Label>
                            <FieldArray
                                name="extraFields"
                                validateOnChange={false}

                            >
                                {arrayHelpers => {
                                    let r = [] as React.ReactNode[];
                                    if (formkikProps.values.extraFields && formkikProps.values.extraFields.length > 0) {
                                        r = formkikProps.values.extraFields.map((o, index) => {
                                            if (o.type == 1) {
                                                return (<Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: props.childrenGap }} key={index}>
                                                    {o.label != undefined ? <Stack.Item grow> <DynamicInputField name={`extraFields[${index}].label`} placeholder={t("extraFieldLabel")} disabled={state.isLoading}
                                                        maxLength={50} autoFocus={(o as any)?.autoFocus} /> </Stack.Item> : null}
                                                    <Stack.Item grow>  <DynamicInputField name={`extraFields[${index}].value`} placeholder={t("extraFieldValue")} disabled={state.isLoading}
                                                        maxLength={50}
                                                    /></Stack.Item>

                                                    <IconButton disabled={state.isLoading}
                                                        iconProps={{ iconName: "remove" }} onClick={() => {
                                                            arrayHelpers.remove(index)
                                                        }} />
                                                </Stack>)
                                            }
                                        }
                                        )
                                    }
                                    if (formkikProps.values.extraFields.length <= 4) {
                                        r.push(< IconButton disabled={state.isLoading}
                                            key={"addButton"} iconProps={{ iconName: "add" }} onClick={() => {
                                                arrayHelpers.push({ key: (formkikProps.values.extraFields.length + 1) + "", label: "", type: 1, value: "", autoFocus: true })
                                            }} />)
                                    }
                                    return r;
                                }
                                }

                            </FieldArray>


                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("add") : t("edit")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={props.type == FormType.ADD ? t("discard") : t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>

                    </Form>
                )}
            </Formik>
        </>
    );

}

