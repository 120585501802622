import { ComboBox, DefaultButton, DialogFooter, Icon, IconButton, IPersonaProps, IPickerItemProps, IStackTokens, ISuggestionItemProps, ISuggestionModel, ISuggestionsProps, ITag, Label, MessageBar, MessageBarType, Modal, NormalPeoplePicker, PeoplePickerItem, Persona, PersonaSize, PrimaryButton, Spinner, SpinnerSize, Stack, TagItemSuggestion, TagPicker, Text, TextField, Toggle, TooltipHost, ValidationState } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { FormEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks";
import { dismissMessage, getAccountTypes, getCurrencies, getLimitations, reset, resetCurrencies, resetLimitations, resetAccountTypes, setIsFilteredAccountTypesSet, setIsFilteredCurrenciesSet, setSelectedAccountTypeItems, setSelectedCurrencyItems, setIsFilteredLimitationsSet, setSelectedLimitationItems, resetItem, updateOpenAccountDefaultConfiguration, setSelectedCurrencyPairsItems, resetCurrencyPairsSet, setIsFilteredCurrencyPairSet, getCurrencyPairs, updateCurrencyPairConfiguration } from "./OrganizationClientPortalSettingFormSlice";
import * as Yup from 'yup';
import { DynamicInputField, InputField, NumberInputField } from "../../controls/Controls";
import { inputs } from "../../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../../app/Api";
import { Message } from "../../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../../FormProps";
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog";
import { formatDate, timestampToDate } from "../../../../app/Helpers";
import { AuthenticateReply } from "../../../../repository/UserManagement/authentication_pb";
import { DynamicField } from "../../../common/DynamicFields/DynamicFields";
import { ExtraField } from "../../../../repository/Accountant/common_pb";
import { TableState } from "../../../common/Table/TableSate";
import { AccountStatus, LimitationType, Operations } from "../../../../app/Enums";
import { GetAccountTypesRequest } from "../../../../repository/Accountant/account_type_pb"
import { GetCurrenciesRequest } from "../../../../repository/Accountant/currency_pb"
import { GetUsersRequest } from "../../../../repository/UserManagement/Customers/user_pb"
import { GetLimitationsRequest } from "../../../../repository/Accountant/limitation_pb"
import { UpdateCurrencyPairsConfigurationRequest, UpdateCurrencyPairsConfigurationResponse, UpdateOpenAccountDefaultConfigurationResponse } from "../../../../repository/UserManagement/organizationclientportalsetting_pb";
import { List } from "../../../common/List/List";
import { Popup } from "../../../common/Popup/Popup";
import { UsersPage } from "../../../pages/Customers/Users/UsersPage";
import { LimitationsPage } from "../../../pages/Accountant/Limitations/LimitationsPage";
import { AccountTypesPage } from "../../../pages/Accountant/AccountTypes/AccountTypesPage";
import { CurrenciesPage } from "../../../pages/Accountant/Currencies/CurrenciesPage";
import { GetCurrencyPairsRequest } from "../../../../repository/Trading/currency_pair_pb";
import { CurrencyPairsPage } from "../../../pages/Trading/CurrencyPairs/CurrencyPairsPage";

let req: UpdateCurrencyPairsConfigurationRequest;
let currecyPairsReq: GetCurrencyPairsRequest;

let promise: any;
let getFilterPromise: any;
let org: number = -1;
let current: AuthenticateReply.AsObject;

export const OrganizationClientPortalSettingCurrencyPairsForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();
    const [currentAction, setCurrenctAction] = useState(0)


    const state: { isLoading: boolean, message: ApiMessage | undefined, currencyPairs: TableState, } = useAppSelector((state) => {

        return {
            isLoading: state.organizationClientPortalSettingForm.isLoading,
            message: state.organizationClientPortalSettingForm.message,

            currencyPairs: state.organizationClientPortalSettingForm.currencyPairs,


        }
    })

    useEffect(() => {
        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        }

        var l = searchParams.get('org');
        req = new UpdateCurrencyPairsConfigurationRequest();
        currecyPairsReq = new GetCurrencyPairsRequest();

        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
                req.setId(Number(current.organization?.id))

            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                req.setId(org)
                currecyPairsReq.setOrganization(wrapper)

            }
        } else {
            req.setId(Number(current.organization?.id))
        }


        const boolv = new BoolValue();
        boolv.setValue(true);
        currecyPairsReq.setNumofresults(state.currencyPairs.numberOfResults)
        currecyPairsReq.setOrder(state.currencyPairs.isDescending)

        if (props.renderObject?.currencyPairId)
            dispatch(setSelectedCurrencyPairsItems(([{ id: props.renderObject.currencyPairId, name: props.renderObject.currencyPairName }])))


        return () => { //clean up
            promise?.abort();
            dispatch(reset());
        }
    }, [])

    const onPairsFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetCurrencyPairsSet())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        currecyPairsReq.setSearch(wrapper)
        currecyPairsReq.setNextto(undefined)
        dispatch(setIsFilteredCurrencyPairSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getCurrencyPairs({ body: currecyPairsReq, headers: getHeaders() }))
        return [];

    }


    return (
        <>

            <Popup isOpen={(currentAction == 1000)} title={t("pair")} onDismiss={() => { setCurrenctAction(0) }} >

                <CurrencyPairsPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedCurrencyPairsItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    currencyPair: undefined,

                    rank: props?.renderObject?.rank ?? 1,

                }}

                validationSchema={Yup.object({

                })}

                onSubmit={(values, actions) => {


                    let currencyPair = state.currencyPairs.selected.at(0);

                    req.setCurrencypair(currencyPair?.id);
                    req.setRank(Number(values.rank));


                    promise = dispatch(updateCurrencyPairConfiguration({ body: req, headers: getHeaders() }))
                    promise.unwrap()
                        .then((res: UpdateCurrencyPairsConfigurationResponse.AsObject) => {
                            if (res) {
                                if (props?.onSuccess) {
                                    props?.onSuccess({
                                        currencyPairId: currencyPair?.id,
                                        currencyPairName: currencyPair?.name,
                                        currencyPairBaseId: currencyPair?.base?.id,
                                        currencyPairBaseName: currencyPair?.base?.name,
                                        currencyPairQuoteId: currencyPair?.quote?.id,
                                        currencyPairQuoteName: currencyPair?.name,
                                        rank: values.rank,


                                    });
                                }
                            }
                            actions.setSubmitting(false)


                        })
                        .catch((error: ApiMessage) => {
                            actions.setSubmitting(false)
                        })

                }}

            >
                {formkikProps => (
                    <Form >
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? (state.message.data != 401) ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            /> :
                                <SessionExpiredDialog />
                                : null
                            }


                            {props.type == FormType.ADD ? <Stack>
                                <Label required
                                    disabled={state.isLoading}>{t("pair")}</Label>
                                <List
                                    inputProps={{ placeholder: t("pair"), required: true }}
                                    disabled={state.isLoading}
                                    suggestionsHeaderText={t('pair')}
                                    isLoading={state.currencyPairs.items.length == 0 ? state.currencyPairs.isFetching : false}
                                    isSearching={state.currencyPairs.items.length > 0 ? state.currencyPairs.isFetching : false}
                                    moreSuggestionsAvailable={state.currencyPairs.hasMore && !state.currencyPairs.isFetching}
                                    suggestions={state.currencyPairs.items.length > 0 ? state.currencyPairs.items.map(e => { return { item: { key: e.id, text: e.name, imageInitials: e?.baseCurrencySymbol + "/" + e?.quoteCurrencySymbol, secondaryText: (e?.baseCurrencyCode == "" ? e?.baseCurrencyId : e?.baseCurrencyCode) + "-" + (e?.quoteCurrencyCode == "" ? e?.quoteCurrencyId : e?.quoteCurrencyCode), imageUrl: e?.iconSource } } as ISuggestionModel<IPersonaProps> }) : []}
                                    onGetMoreResults={() => {
                                        if (state.currencyPairs.items.length > 0 && !state.currencyPairs.isFetching) {
                                            const wrapper = new StringValue();
                                            wrapper.setValue(state.currencyPairs.items.at(state.currencyPairs.items.length - 1).id);
                                            currecyPairsReq.setNextto(wrapper)
                                            getFilterPromise = dispatch(getCurrencyPairs({ body: currecyPairsReq, headers: getHeaders() }))
                                        }
                                    }}
                                    onSuggestionClick={(ev?, item?: any, index?: number) => {
                                        var u = state.currencyPairs.items.findIndex(e => e.id == item.key)
                                        if (u >= 0) {
                                            dispatch(setSelectedCurrencyPairsItems([state.currencyPairs.items.at(u)]))
                                        }


                                    }}
                                    isPeoplePicker={true}
                                    selectedItems={state.currencyPairs.selected.length > 0 ? state.currencyPairs.selected.map(e => { return { key: e.id, text: e.name, imageInitials: e?.baseCurrencySymbol + "/" + e?.quoteCurrencySymbol, secondaryText: (e?.baseCurrencyCode == "" ? e?.baseCurrencyId : e?.baseCurrencyCode) + "-" + (e?.quoteCurrencyCode == "" ? e?.quoteCurrencyId : e?.quoteCurrencyCode), imageUrl: e?.iconSource } as IPersonaProps }) : []}
                                    onChange={(e) => {
                                        dispatch(setSelectedCurrencyPairsItems([]))


                                    }}
                                    onEmptyInputFocus={() => {
                                        dispatch(resetCurrencyPairsSet())
                                        currecyPairsReq.setSearch(undefined)
                                        currecyPairsReq.setNextto(undefined)
                                        dispatch(setIsFilteredCurrencyPairSet(false))
                                        getFilterPromise?.abort()
                                        getFilterPromise = dispatch(getCurrencyPairs({ body: currecyPairsReq, headers: getHeaders() }))
                                        return []
                                    }}
                                    onFilterChanged={onPairsFilterChanged}
                                    endButtons={[
                                        {
                                            title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                            onClick: () => {
                                                setCurrenctAction(1000)

                                            }
                                        }]}
                                />
                            </Stack> : undefined}


                            <Label disabled={state.isLoading}>{
                                <div>
                                    {t("rank") + ' '}
                                    <TooltipHost content={t("rankDesc")}>
                                        <Icon iconName="Info" aria-label={t("rank")} />
                                    </TooltipHost>
                                </div>
                            }</Label>

                            <Field name={`rank`}
                                placeholder={"1"} component={NumberInputField}
                                disabled={state.isLoading} max={1000} min={-1000} required />






                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("add") : t("edit")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={props.type == FormType.ADD ? t("discard") : t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>



                    </Form>
                )}
            </Formik>
        </>
    );

}

