// source: currency_pair.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('./common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.currency_pair_endpoint.AddCurrencyPairReplay', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.AddCurrencyPairRequest', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.AddCurrencyPairResponse', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.AddCurrencyPairResponse.ResultCase', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.CurrencyPair', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.DeleteCurrencyPairReplay', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.DeleteCurrencyPairRequest', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.DeleteCurrencyPairResponse', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.DeleteCurrencyPairResponse.ResultCase', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.GetCurrencyPairReplay', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.GetCurrencyPairRequest', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.GetCurrencyPairResponse', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.GetCurrencyPairResponse.ResultCase', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.GetCurrencyPairsReplay', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.GetCurrencyPairsRequest', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.GetCurrencyPairsResponse', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.GetCurrencyPairsResponse.ResultCase', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.SetPriceTriggerReplay', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.SetPriceTriggerRequest', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.SetPriceTriggerResponse', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.SetPriceTriggerResponse.ResultCase', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.UpdateCurrencyPairActivityRequest', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse.ResultCase', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.UpdateCurrencyPairPriceRequest', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse.ResultCase', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.UpdateCurrencyPairReplay', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.UpdateCurrencyPairRequest', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.UpdateCurrencyPairResponse', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.UpdateCurrencyPairResponse.ResultCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.AddCurrencyPairRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_pair_endpoint.AddCurrencyPairRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.AddCurrencyPairRequest.displayName = 'proto.currency_pair_endpoint.AddCurrencyPairRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_pair_endpoint.UpdateCurrencyPairPriceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.UpdateCurrencyPairPriceRequest.displayName = 'proto.currency_pair_endpoint.UpdateCurrencyPairPriceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_pair_endpoint.UpdateCurrencyPairActivityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.UpdateCurrencyPairActivityRequest.displayName = 'proto.currency_pair_endpoint.UpdateCurrencyPairActivityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.UpdateCurrencyPairRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_pair_endpoint.UpdateCurrencyPairRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.UpdateCurrencyPairRequest.displayName = 'proto.currency_pair_endpoint.UpdateCurrencyPairRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.SetPriceTriggerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_pair_endpoint.SetPriceTriggerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.SetPriceTriggerRequest.displayName = 'proto.currency_pair_endpoint.SetPriceTriggerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.DeleteCurrencyPairRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_pair_endpoint.DeleteCurrencyPairRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.DeleteCurrencyPairRequest.displayName = 'proto.currency_pair_endpoint.DeleteCurrencyPairRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.GetCurrencyPairRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_pair_endpoint.GetCurrencyPairRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.GetCurrencyPairRequest.displayName = 'proto.currency_pair_endpoint.GetCurrencyPairRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_pair_endpoint.GetCurrencyPairsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.GetCurrencyPairsRequest.displayName = 'proto.currency_pair_endpoint.GetCurrencyPairsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.AddCurrencyPairReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_pair_endpoint.AddCurrencyPairReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.AddCurrencyPairReplay.displayName = 'proto.currency_pair_endpoint.AddCurrencyPairReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay.displayName = 'proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay.displayName = 'proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.DeleteCurrencyPairReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_pair_endpoint.DeleteCurrencyPairReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.DeleteCurrencyPairReplay.displayName = 'proto.currency_pair_endpoint.DeleteCurrencyPairReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.UpdateCurrencyPairReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_pair_endpoint.UpdateCurrencyPairReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.UpdateCurrencyPairReplay.displayName = 'proto.currency_pair_endpoint.UpdateCurrencyPairReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.SetPriceTriggerReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_pair_endpoint.SetPriceTriggerReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.SetPriceTriggerReplay.displayName = 'proto.currency_pair_endpoint.SetPriceTriggerReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_pair_endpoint.GetCurrencyPairReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.GetCurrencyPairReplay.displayName = 'proto.currency_pair_endpoint.GetCurrencyPairReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.CurrencyPair = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_pair_endpoint.CurrencyPair, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.CurrencyPair.displayName = 'proto.currency_pair_endpoint.CurrencyPair';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.GetCurrencyPairsReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.currency_pair_endpoint.GetCurrencyPairsReplay.repeatedFields_, null);
};
goog.inherits(proto.currency_pair_endpoint.GetCurrencyPairsReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.GetCurrencyPairsReplay.displayName = 'proto.currency_pair_endpoint.GetCurrencyPairsReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.AddCurrencyPairResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.currency_pair_endpoint.AddCurrencyPairResponse.oneofGroups_);
};
goog.inherits(proto.currency_pair_endpoint.AddCurrencyPairResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.AddCurrencyPairResponse.displayName = 'proto.currency_pair_endpoint.AddCurrencyPairResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse.oneofGroups_);
};
goog.inherits(proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse.displayName = 'proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse.oneofGroups_);
};
goog.inherits(proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse.displayName = 'proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.DeleteCurrencyPairResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.currency_pair_endpoint.DeleteCurrencyPairResponse.oneofGroups_);
};
goog.inherits(proto.currency_pair_endpoint.DeleteCurrencyPairResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.DeleteCurrencyPairResponse.displayName = 'proto.currency_pair_endpoint.DeleteCurrencyPairResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.GetCurrencyPairsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.currency_pair_endpoint.GetCurrencyPairsResponse.oneofGroups_);
};
goog.inherits(proto.currency_pair_endpoint.GetCurrencyPairsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.GetCurrencyPairsResponse.displayName = 'proto.currency_pair_endpoint.GetCurrencyPairsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.GetCurrencyPairResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.currency_pair_endpoint.GetCurrencyPairResponse.oneofGroups_);
};
goog.inherits(proto.currency_pair_endpoint.GetCurrencyPairResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.GetCurrencyPairResponse.displayName = 'proto.currency_pair_endpoint.GetCurrencyPairResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.UpdateCurrencyPairResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.currency_pair_endpoint.UpdateCurrencyPairResponse.oneofGroups_);
};
goog.inherits(proto.currency_pair_endpoint.UpdateCurrencyPairResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.UpdateCurrencyPairResponse.displayName = 'proto.currency_pair_endpoint.UpdateCurrencyPairResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.SetPriceTriggerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.currency_pair_endpoint.SetPriceTriggerResponse.oneofGroups_);
};
goog.inherits(proto.currency_pair_endpoint.SetPriceTriggerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.SetPriceTriggerResponse.displayName = 'proto.currency_pair_endpoint.SetPriceTriggerResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.AddCurrencyPairRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.AddCurrencyPairRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.AddCurrencyPairRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.AddCurrencyPairRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 7, ""),
    basecurrency: jspb.Message.getFieldWithDefault(msg, 1, ""),
    quotecurrency: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sellprice: jspb.Message.getFieldWithDefault(msg, 3, ""),
    buyprice: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isactive: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    syncprices: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    iconsource: jspb.Message.getFieldWithDefault(msg, 10, ""),
    category: jspb.Message.getFieldWithDefault(msg, 9, ""),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.AddCurrencyPairRequest}
 */
proto.currency_pair_endpoint.AddCurrencyPairRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.AddCurrencyPairRequest;
  return proto.currency_pair_endpoint.AddCurrencyPairRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.AddCurrencyPairRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.AddCurrencyPairRequest}
 */
proto.currency_pair_endpoint.AddCurrencyPairRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBasecurrency(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuotecurrency(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSellprice(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuyprice(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsactive(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSyncprices(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setIconsource(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.AddCurrencyPairRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.AddCurrencyPairRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.AddCurrencyPairRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.AddCurrencyPairRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBasecurrency();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuotecurrency();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSellprice();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBuyprice();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsactive();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getSyncprices();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIconsource();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 7;
 * @return {string}
 */
proto.currency_pair_endpoint.AddCurrencyPairRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.AddCurrencyPairRequest} returns this
 */
proto.currency_pair_endpoint.AddCurrencyPairRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string baseCurrency = 1;
 * @return {string}
 */
proto.currency_pair_endpoint.AddCurrencyPairRequest.prototype.getBasecurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.AddCurrencyPairRequest} returns this
 */
proto.currency_pair_endpoint.AddCurrencyPairRequest.prototype.setBasecurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string quoteCurrency = 2;
 * @return {string}
 */
proto.currency_pair_endpoint.AddCurrencyPairRequest.prototype.getQuotecurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.AddCurrencyPairRequest} returns this
 */
proto.currency_pair_endpoint.AddCurrencyPairRequest.prototype.setQuotecurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sellPrice = 3;
 * @return {string}
 */
proto.currency_pair_endpoint.AddCurrencyPairRequest.prototype.getSellprice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.AddCurrencyPairRequest} returns this
 */
proto.currency_pair_endpoint.AddCurrencyPairRequest.prototype.setSellprice = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string buyPrice = 4;
 * @return {string}
 */
proto.currency_pair_endpoint.AddCurrencyPairRequest.prototype.getBuyprice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.AddCurrencyPairRequest} returns this
 */
proto.currency_pair_endpoint.AddCurrencyPairRequest.prototype.setBuyprice = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool isActive = 5;
 * @return {boolean}
 */
proto.currency_pair_endpoint.AddCurrencyPairRequest.prototype.getIsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.currency_pair_endpoint.AddCurrencyPairRequest} returns this
 */
proto.currency_pair_endpoint.AddCurrencyPairRequest.prototype.setIsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool syncPrices = 6;
 * @return {boolean}
 */
proto.currency_pair_endpoint.AddCurrencyPairRequest.prototype.getSyncprices = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.currency_pair_endpoint.AddCurrencyPairRequest} returns this
 */
proto.currency_pair_endpoint.AddCurrencyPairRequest.prototype.setSyncprices = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string iconSource = 10;
 * @return {string}
 */
proto.currency_pair_endpoint.AddCurrencyPairRequest.prototype.getIconsource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.AddCurrencyPairRequest} returns this
 */
proto.currency_pair_endpoint.AddCurrencyPairRequest.prototype.setIconsource = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string category = 9;
 * @return {string}
 */
proto.currency_pair_endpoint.AddCurrencyPairRequest.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.AddCurrencyPairRequest} returns this
 */
proto.currency_pair_endpoint.AddCurrencyPairRequest.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional google.protobuf.Int32Value organization = 8;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.currency_pair_endpoint.AddCurrencyPairRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.currency_pair_endpoint.AddCurrencyPairRequest} returns this
*/
proto.currency_pair_endpoint.AddCurrencyPairRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.AddCurrencyPairRequest} returns this
 */
proto.currency_pair_endpoint.AddCurrencyPairRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.AddCurrencyPairRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.UpdateCurrencyPairPriceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sellprice: jspb.Message.getFieldWithDefault(msg, 2, ""),
    buyprice: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tradingprice: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceRequest}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.UpdateCurrencyPairPriceRequest;
  return proto.currency_pair_endpoint.UpdateCurrencyPairPriceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceRequest}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSellprice(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuyprice(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTradingprice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.UpdateCurrencyPairPriceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSellprice();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBuyprice();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTradingprice();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceRequest} returns this
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sellPrice = 2;
 * @return {string}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceRequest.prototype.getSellprice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceRequest} returns this
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceRequest.prototype.setSellprice = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string buyPrice = 3;
 * @return {string}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceRequest.prototype.getBuyprice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceRequest} returns this
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceRequest.prototype.setBuyprice = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string tradingPrice = 4;
 * @return {string}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceRequest.prototype.getTradingprice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceRequest} returns this
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceRequest.prototype.setTradingprice = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.UpdateCurrencyPairActivityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairActivityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isactive: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairActivityRequest}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.UpdateCurrencyPairActivityRequest;
  return proto.currency_pair_endpoint.UpdateCurrencyPairActivityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairActivityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairActivityRequest}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsactive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.UpdateCurrencyPairActivityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairActivityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsactive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairActivityRequest} returns this
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool isActive = 2;
 * @return {boolean}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityRequest.prototype.getIsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairActivityRequest} returns this
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityRequest.prototype.setIsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.UpdateCurrencyPairRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.UpdateCurrencyPairRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    syncprices: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    iconsource: jspb.Message.getFieldWithDefault(msg, 4, ""),
    category: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairRequest}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.UpdateCurrencyPairRequest;
  return proto.currency_pair_endpoint.UpdateCurrencyPairRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairRequest}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSyncprices(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIconsource(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.UpdateCurrencyPairRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.UpdateCurrencyPairRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSyncprices();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIconsource();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairRequest} returns this
 */
proto.currency_pair_endpoint.UpdateCurrencyPairRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairRequest} returns this
 */
proto.currency_pair_endpoint.UpdateCurrencyPairRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool syncPrices = 3;
 * @return {boolean}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairRequest.prototype.getSyncprices = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairRequest} returns this
 */
proto.currency_pair_endpoint.UpdateCurrencyPairRequest.prototype.setSyncprices = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string iconSource = 4;
 * @return {string}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairRequest.prototype.getIconsource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairRequest} returns this
 */
proto.currency_pair_endpoint.UpdateCurrencyPairRequest.prototype.setIconsource = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string category = 5;
 * @return {string}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairRequest.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairRequest} returns this
 */
proto.currency_pair_endpoint.UpdateCurrencyPairRequest.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.SetPriceTriggerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.SetPriceTriggerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.SetPriceTriggerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.SetPriceTriggerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    maincurrencypair: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isactive: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    sellpriceequation: jspb.Message.getFieldWithDefault(msg, 4, ""),
    buypriceequation: jspb.Message.getFieldWithDefault(msg, 5, ""),
    tradingpriceequation: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.SetPriceTriggerRequest}
 */
proto.currency_pair_endpoint.SetPriceTriggerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.SetPriceTriggerRequest;
  return proto.currency_pair_endpoint.SetPriceTriggerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.SetPriceTriggerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.SetPriceTriggerRequest}
 */
proto.currency_pair_endpoint.SetPriceTriggerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaincurrencypair(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsactive(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSellpriceequation(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuypriceequation(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTradingpriceequation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.SetPriceTriggerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.SetPriceTriggerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.SetPriceTriggerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.SetPriceTriggerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMaincurrencypair();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsactive();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getSellpriceequation();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBuypriceequation();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTradingpriceequation();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.currency_pair_endpoint.SetPriceTriggerRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.SetPriceTriggerRequest} returns this
 */
proto.currency_pair_endpoint.SetPriceTriggerRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string mainCurrencyPair = 2;
 * @return {string}
 */
proto.currency_pair_endpoint.SetPriceTriggerRequest.prototype.getMaincurrencypair = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.SetPriceTriggerRequest} returns this
 */
proto.currency_pair_endpoint.SetPriceTriggerRequest.prototype.setMaincurrencypair = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool isActive = 3;
 * @return {boolean}
 */
proto.currency_pair_endpoint.SetPriceTriggerRequest.prototype.getIsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.currency_pair_endpoint.SetPriceTriggerRequest} returns this
 */
proto.currency_pair_endpoint.SetPriceTriggerRequest.prototype.setIsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string sellPriceEquation = 4;
 * @return {string}
 */
proto.currency_pair_endpoint.SetPriceTriggerRequest.prototype.getSellpriceequation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.SetPriceTriggerRequest} returns this
 */
proto.currency_pair_endpoint.SetPriceTriggerRequest.prototype.setSellpriceequation = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string buyPriceEquation = 5;
 * @return {string}
 */
proto.currency_pair_endpoint.SetPriceTriggerRequest.prototype.getBuypriceequation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.SetPriceTriggerRequest} returns this
 */
proto.currency_pair_endpoint.SetPriceTriggerRequest.prototype.setBuypriceequation = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string tradingPriceEquation = 6;
 * @return {string}
 */
proto.currency_pair_endpoint.SetPriceTriggerRequest.prototype.getTradingpriceequation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.SetPriceTriggerRequest} returns this
 */
proto.currency_pair_endpoint.SetPriceTriggerRequest.prototype.setTradingpriceequation = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.DeleteCurrencyPairRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.DeleteCurrencyPairRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.DeleteCurrencyPairRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.DeleteCurrencyPairRequest}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.DeleteCurrencyPairRequest;
  return proto.currency_pair_endpoint.DeleteCurrencyPairRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.DeleteCurrencyPairRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.DeleteCurrencyPairRequest}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.DeleteCurrencyPairRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.DeleteCurrencyPairRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.DeleteCurrencyPairRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.DeleteCurrencyPairRequest} returns this
 */
proto.currency_pair_endpoint.DeleteCurrencyPairRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.GetCurrencyPairRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.GetCurrencyPairRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairRequest}
 */
proto.currency_pair_endpoint.GetCurrencyPairRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.GetCurrencyPairRequest;
  return proto.currency_pair_endpoint.GetCurrencyPairRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairRequest}
 */
proto.currency_pair_endpoint.GetCurrencyPairRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.GetCurrencyPairRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.GetCurrencyPairRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.currency_pair_endpoint.GetCurrencyPairRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairRequest} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.GetCurrencyPairsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    base: (f = msg.getBase()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    quote: (f = msg.getQuote()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isactive: (f = msg.getIsactive()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    syncprices: (f = msg.getSyncprices()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    category: (f = msg.getCategory()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    maincurrencypair: (f = msg.getMaincurrencypair()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    order: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    nextto: (f = msg.getNextto()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    numofresults: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.GetCurrencyPairsRequest;
  return proto.currency_pair_endpoint.GetCurrencyPairsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setQuote(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsactive(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setSyncprices(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCategory(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMaincurrencypair(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrder(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNextto(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumofresults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.GetCurrencyPairsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getQuote();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsactive();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getSyncprices();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getCategory();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMaincurrencypair();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getNextto();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNumofresults();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional google.protobuf.Int32Value organization = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue base = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.getBase = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.hasBase = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue quote = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.getQuote = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.setQuote = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.clearQuote = function() {
  return this.setQuote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.hasQuote = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.BoolValue isActive = 4;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.getIsactive = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 4));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.setIsactive = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.clearIsactive = function() {
  return this.setIsactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.hasIsactive = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.BoolValue syncPrices = 9;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.getSyncprices = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 9));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.setSyncprices = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.clearSyncprices = function() {
  return this.setSyncprices(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.hasSyncprices = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue category = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.getCategory = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.setCategory = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.clearCategory = function() {
  return this.setCategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.hasCategory = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.StringValue mainCurrencyPair = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.getMaincurrencypair = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.setMaincurrencypair = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.clearMaincurrencypair = function() {
  return this.setMaincurrencypair(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.hasMaincurrencypair = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.StringValue search = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool order = 6;
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.getOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional google.protobuf.StringValue nextTo = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.getNextto = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.setNextto = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.clearNextto = function() {
  return this.setNextto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.hasNextto = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int32 numOfResults = 8;
 * @return {number}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.getNumofresults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.setNumofresults = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.AddCurrencyPairReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.AddCurrencyPairReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.AddCurrencyPairReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.AddCurrencyPairReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.AddCurrencyPairReplay}
 */
proto.currency_pair_endpoint.AddCurrencyPairReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.AddCurrencyPairReplay;
  return proto.currency_pair_endpoint.AddCurrencyPairReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.AddCurrencyPairReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.AddCurrencyPairReplay}
 */
proto.currency_pair_endpoint.AddCurrencyPairReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.AddCurrencyPairReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.AddCurrencyPairReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.AddCurrencyPairReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.AddCurrencyPairReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.MessageResponse message = 1;
 * @return {?proto.common.MessageResponse}
 */
proto.currency_pair_endpoint.AddCurrencyPairReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 1));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.currency_pair_endpoint.AddCurrencyPairReplay} returns this
*/
proto.currency_pair_endpoint.AddCurrencyPairReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.AddCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.AddCurrencyPairReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.AddCurrencyPairReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.currency_pair_endpoint.AddCurrencyPairReplay.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.AddCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.AddCurrencyPairReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.currency_pair_endpoint.AddCurrencyPairReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.currency_pair_endpoint.AddCurrencyPairReplay} returns this
*/
proto.currency_pair_endpoint.AddCurrencyPairReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.AddCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.AddCurrencyPairReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.AddCurrencyPairReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f),
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay;
  return proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.MessageResponse message = 1;
 * @return {?proto.common.MessageResponse}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 1));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay} returns this
*/
proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay} returns this
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp updatedDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay} returns this
*/
proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay} returns this
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f),
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay;
  return proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.MessageResponse message = 1;
 * @return {?proto.common.MessageResponse}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 1));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay} returns this
*/
proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay} returns this
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp updatedDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay} returns this
*/
proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay} returns this
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.DeleteCurrencyPairReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.DeleteCurrencyPairReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.DeleteCurrencyPairReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f),
    deteleteddate: (f = msg.getDeteleteddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.DeleteCurrencyPairReplay}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.DeleteCurrencyPairReplay;
  return proto.currency_pair_endpoint.DeleteCurrencyPairReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.DeleteCurrencyPairReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.DeleteCurrencyPairReplay}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeteleteddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.DeleteCurrencyPairReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.DeleteCurrencyPairReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.DeleteCurrencyPairReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getDeteleteddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.MessageResponse message = 1;
 * @return {?proto.common.MessageResponse}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 1));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.currency_pair_endpoint.DeleteCurrencyPairReplay} returns this
*/
proto.currency_pair_endpoint.DeleteCurrencyPairReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.DeleteCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.DeleteCurrencyPairReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp deteletedDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairReplay.prototype.getDeteleteddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.currency_pair_endpoint.DeleteCurrencyPairReplay} returns this
*/
proto.currency_pair_endpoint.DeleteCurrencyPairReplay.prototype.setDeteleteddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.DeleteCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.DeleteCurrencyPairReplay.prototype.clearDeteleteddate = function() {
  return this.setDeteleteddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairReplay.prototype.hasDeteleteddate = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.UpdateCurrencyPairReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.UpdateCurrencyPairReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f),
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairReplay}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.UpdateCurrencyPairReplay;
  return proto.currency_pair_endpoint.UpdateCurrencyPairReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairReplay}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.UpdateCurrencyPairReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.UpdateCurrencyPairReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.MessageResponse message = 1;
 * @return {?proto.common.MessageResponse}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 1));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairReplay} returns this
*/
proto.currency_pair_endpoint.UpdateCurrencyPairReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.UpdateCurrencyPairReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp updatedDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairReplay} returns this
*/
proto.currency_pair_endpoint.UpdateCurrencyPairReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.UpdateCurrencyPairReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.SetPriceTriggerReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.SetPriceTriggerReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.SetPriceTriggerReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.SetPriceTriggerReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f),
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.SetPriceTriggerReplay}
 */
proto.currency_pair_endpoint.SetPriceTriggerReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.SetPriceTriggerReplay;
  return proto.currency_pair_endpoint.SetPriceTriggerReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.SetPriceTriggerReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.SetPriceTriggerReplay}
 */
proto.currency_pair_endpoint.SetPriceTriggerReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.SetPriceTriggerReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.SetPriceTriggerReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.SetPriceTriggerReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.SetPriceTriggerReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.MessageResponse message = 1;
 * @return {?proto.common.MessageResponse}
 */
proto.currency_pair_endpoint.SetPriceTriggerReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 1));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.currency_pair_endpoint.SetPriceTriggerReplay} returns this
*/
proto.currency_pair_endpoint.SetPriceTriggerReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.SetPriceTriggerReplay} returns this
 */
proto.currency_pair_endpoint.SetPriceTriggerReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.SetPriceTriggerReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp updatedDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.currency_pair_endpoint.SetPriceTriggerReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.currency_pair_endpoint.SetPriceTriggerReplay} returns this
*/
proto.currency_pair_endpoint.SetPriceTriggerReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.SetPriceTriggerReplay} returns this
 */
proto.currency_pair_endpoint.SetPriceTriggerReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.SetPriceTriggerReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.GetCurrencyPairReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    base: (f = msg.getBase()) && Protos_common_pb.CurrencyResponse.toObject(includeInstance, f),
    quote: (f = msg.getQuote()) && Protos_common_pb.CurrencyResponse.toObject(includeInstance, f),
    sellprice: (f = msg.getSellprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    buyprice: (f = msg.getBuyprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    tradingprice: (f = msg.getTradingprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    priceupdate: (f = msg.getPriceupdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isactive: (f = msg.getIsactive()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    syncprices: (f = msg.getSyncprices()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    category: (f = msg.getCategory()) && Protos_common_pb.CategoryResponse.toObject(includeInstance, f),
    pricetrigger: (f = msg.getPricetrigger()) && Protos_common_pb.CurrencyPairPriceTriggerResponse.toObject(includeInstance, f),
    iconsource: (f = msg.getIconsource()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.GetCurrencyPairReplay;
  return proto.currency_pair_endpoint.GetCurrencyPairReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 13:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 2:
      var value = new Protos_common_pb.CurrencyResponse;
      reader.readMessage(value,Protos_common_pb.CurrencyResponse.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    case 3:
      var value = new Protos_common_pb.CurrencyResponse;
      reader.readMessage(value,Protos_common_pb.CurrencyResponse.deserializeBinaryFromReader);
      msg.setQuote(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSellprice(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBuyprice(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTradingprice(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPriceupdate(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsactive(value);
      break;
    case 14:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setSyncprices(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 12:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 15:
      var value = new Protos_common_pb.CategoryResponse;
      reader.readMessage(value,Protos_common_pb.CategoryResponse.deserializeBinaryFromReader);
      msg.setCategory(value);
      break;
    case 16:
      var value = new Protos_common_pb.CurrencyPairPriceTriggerResponse;
      reader.readMessage(value,Protos_common_pb.CurrencyPairPriceTriggerResponse.deserializeBinaryFromReader);
      msg.setPricetrigger(value);
      break;
    case 17:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setIconsource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.GetCurrencyPairReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.CurrencyResponse.serializeBinaryToWriter
    );
  }
  f = message.getQuote();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.CurrencyResponse.serializeBinaryToWriter
    );
  }
  f = message.getSellprice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getBuyprice();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTradingprice();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPriceupdate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsactive();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getSyncprices();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCategory();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      Protos_common_pb.CategoryResponse.serializeBinaryToWriter
    );
  }
  f = message.getPricetrigger();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      Protos_common_pb.CurrencyPairPriceTriggerResponse.serializeBinaryToWriter
    );
  }
  f = message.getIconsource();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 13;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 13));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.hasName = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional common.CurrencyResponse base = 2;
 * @return {?proto.common.CurrencyResponse}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.getBase = function() {
  return /** @type{?proto.common.CurrencyResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.CurrencyResponse, 2));
};


/**
 * @param {?proto.common.CurrencyResponse|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.hasBase = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.CurrencyResponse quote = 3;
 * @return {?proto.common.CurrencyResponse}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.getQuote = function() {
  return /** @type{?proto.common.CurrencyResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.CurrencyResponse, 3));
};


/**
 * @param {?proto.common.CurrencyResponse|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.setQuote = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.clearQuote = function() {
  return this.setQuote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.hasQuote = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue sellPrice = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.getSellprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.setSellprice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.clearSellprice = function() {
  return this.setSellprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.hasSellprice = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue buyPrice = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.getBuyprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.setBuyprice = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.clearBuyprice = function() {
  return this.setBuyprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.hasBuyprice = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue tradingPrice = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.getTradingprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.setTradingprice = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.clearTradingprice = function() {
  return this.setTradingprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.hasTradingprice = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp priceUpdate = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.getPriceupdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.setPriceupdate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.clearPriceupdate = function() {
  return this.setPriceupdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.hasPriceupdate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.BoolValue isActive = 10;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.getIsactive = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 10));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.setIsactive = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.clearIsactive = function() {
  return this.setIsactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.hasIsactive = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.BoolValue syncPrices = 14;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.getSyncprices = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 14));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.setSyncprices = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.clearSyncprices = function() {
  return this.setSyncprices(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.hasSyncprices = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional common.ProfileResponse createdBy = 12;
 * @return {?proto.common.ProfileResponse}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 12));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional common.CategoryResponse category = 15;
 * @return {?proto.common.CategoryResponse}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.getCategory = function() {
  return /** @type{?proto.common.CategoryResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.CategoryResponse, 15));
};


/**
 * @param {?proto.common.CategoryResponse|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.setCategory = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.clearCategory = function() {
  return this.setCategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.hasCategory = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional common.CurrencyPairPriceTriggerResponse priceTrigger = 16;
 * @return {?proto.common.CurrencyPairPriceTriggerResponse}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.getPricetrigger = function() {
  return /** @type{?proto.common.CurrencyPairPriceTriggerResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.CurrencyPairPriceTriggerResponse, 16));
};


/**
 * @param {?proto.common.CurrencyPairPriceTriggerResponse|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.setPricetrigger = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.clearPricetrigger = function() {
  return this.setPricetrigger(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.hasPricetrigger = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.StringValue iconSource = 17;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.getIconsource = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 17));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.setIconsource = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.clearIconsource = function() {
  return this.setIconsource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.hasIconsource = function() {
  return jspb.Message.getField(this, 17) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.CurrencyPair.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.CurrencyPair} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.CurrencyPair.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    base: (f = msg.getBase()) && Protos_common_pb.CurrencyResponse.toObject(includeInstance, f),
    quote: (f = msg.getQuote()) && Protos_common_pb.CurrencyResponse.toObject(includeInstance, f),
    sellprice: (f = msg.getSellprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    buyprice: (f = msg.getBuyprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    tradingprice: (f = msg.getTradingprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    priceupdate: (f = msg.getPriceupdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isactive: (f = msg.getIsactive()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    syncprices: (f = msg.getSyncprices()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    category: (f = msg.getCategory()) && Protos_common_pb.CategoryResponse.toObject(includeInstance, f),
    pricetrigger: (f = msg.getPricetrigger()) && Protos_common_pb.CurrencyPairPriceTriggerResponse.toObject(includeInstance, f),
    iconsource: (f = msg.getIconsource()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.CurrencyPair}
 */
proto.currency_pair_endpoint.CurrencyPair.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.CurrencyPair;
  return proto.currency_pair_endpoint.CurrencyPair.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.CurrencyPair} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.CurrencyPair}
 */
proto.currency_pair_endpoint.CurrencyPair.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 13:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 2:
      var value = new Protos_common_pb.CurrencyResponse;
      reader.readMessage(value,Protos_common_pb.CurrencyResponse.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    case 3:
      var value = new Protos_common_pb.CurrencyResponse;
      reader.readMessage(value,Protos_common_pb.CurrencyResponse.deserializeBinaryFromReader);
      msg.setQuote(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSellprice(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBuyprice(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTradingprice(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPriceupdate(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsactive(value);
      break;
    case 14:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setSyncprices(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 12:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 15:
      var value = new Protos_common_pb.CategoryResponse;
      reader.readMessage(value,Protos_common_pb.CategoryResponse.deserializeBinaryFromReader);
      msg.setCategory(value);
      break;
    case 16:
      var value = new Protos_common_pb.CurrencyPairPriceTriggerResponse;
      reader.readMessage(value,Protos_common_pb.CurrencyPairPriceTriggerResponse.deserializeBinaryFromReader);
      msg.setPricetrigger(value);
      break;
    case 17:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setIconsource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.CurrencyPair.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.CurrencyPair} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.CurrencyPair.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.CurrencyResponse.serializeBinaryToWriter
    );
  }
  f = message.getQuote();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.CurrencyResponse.serializeBinaryToWriter
    );
  }
  f = message.getSellprice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getBuyprice();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTradingprice();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPriceupdate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsactive();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getSyncprices();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCategory();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      Protos_common_pb.CategoryResponse.serializeBinaryToWriter
    );
  }
  f = message.getPricetrigger();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      Protos_common_pb.CurrencyPairPriceTriggerResponse.serializeBinaryToWriter
    );
  }
  f = message.getIconsource();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 13;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 13));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
*/
proto.currency_pair_endpoint.CurrencyPair.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.hasName = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional common.CurrencyResponse base = 2;
 * @return {?proto.common.CurrencyResponse}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.getBase = function() {
  return /** @type{?proto.common.CurrencyResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.CurrencyResponse, 2));
};


/**
 * @param {?proto.common.CurrencyResponse|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
*/
proto.currency_pair_endpoint.CurrencyPair.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.hasBase = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.CurrencyResponse quote = 3;
 * @return {?proto.common.CurrencyResponse}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.getQuote = function() {
  return /** @type{?proto.common.CurrencyResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.CurrencyResponse, 3));
};


/**
 * @param {?proto.common.CurrencyResponse|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
*/
proto.currency_pair_endpoint.CurrencyPair.prototype.setQuote = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.clearQuote = function() {
  return this.setQuote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.hasQuote = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue sellPrice = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.getSellprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
*/
proto.currency_pair_endpoint.CurrencyPair.prototype.setSellprice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.clearSellprice = function() {
  return this.setSellprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.hasSellprice = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue buyPrice = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.getBuyprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
*/
proto.currency_pair_endpoint.CurrencyPair.prototype.setBuyprice = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.clearBuyprice = function() {
  return this.setBuyprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.hasBuyprice = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue tradingPrice = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.getTradingprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
*/
proto.currency_pair_endpoint.CurrencyPair.prototype.setTradingprice = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.clearTradingprice = function() {
  return this.setTradingprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.hasTradingprice = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp priceUpdate = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.getPriceupdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
*/
proto.currency_pair_endpoint.CurrencyPair.prototype.setPriceupdate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.clearPriceupdate = function() {
  return this.setPriceupdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.hasPriceupdate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.BoolValue isActive = 10;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.getIsactive = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 10));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
*/
proto.currency_pair_endpoint.CurrencyPair.prototype.setIsactive = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.clearIsactive = function() {
  return this.setIsactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.hasIsactive = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.BoolValue syncPrices = 14;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.getSyncprices = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 14));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
*/
proto.currency_pair_endpoint.CurrencyPair.prototype.setSyncprices = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.clearSyncprices = function() {
  return this.setSyncprices(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.hasSyncprices = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
*/
proto.currency_pair_endpoint.CurrencyPair.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional common.ProfileResponse createdBy = 12;
 * @return {?proto.common.ProfileResponse}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 12));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
*/
proto.currency_pair_endpoint.CurrencyPair.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional common.CategoryResponse category = 15;
 * @return {?proto.common.CategoryResponse}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.getCategory = function() {
  return /** @type{?proto.common.CategoryResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.CategoryResponse, 15));
};


/**
 * @param {?proto.common.CategoryResponse|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
*/
proto.currency_pair_endpoint.CurrencyPair.prototype.setCategory = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.clearCategory = function() {
  return this.setCategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.hasCategory = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional common.CurrencyPairPriceTriggerResponse priceTrigger = 16;
 * @return {?proto.common.CurrencyPairPriceTriggerResponse}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.getPricetrigger = function() {
  return /** @type{?proto.common.CurrencyPairPriceTriggerResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.CurrencyPairPriceTriggerResponse, 16));
};


/**
 * @param {?proto.common.CurrencyPairPriceTriggerResponse|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
*/
proto.currency_pair_endpoint.CurrencyPair.prototype.setPricetrigger = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.clearPricetrigger = function() {
  return this.setPricetrigger(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.hasPricetrigger = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.StringValue iconSource = 17;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.getIconsource = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 17));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
*/
proto.currency_pair_endpoint.CurrencyPair.prototype.setIconsource = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.clearIconsource = function() {
  return this.setIconsource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.hasIconsource = function() {
  return jspb.Message.getField(this, 17) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.currency_pair_endpoint.GetCurrencyPairsReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.GetCurrencyPairsReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.GetCurrencyPairsReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairsReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairsReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    currencypairsList: jspb.Message.toObjectList(msg.getCurrencypairsList(),
    proto.currency_pair_endpoint.CurrencyPair.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsReplay}
 */
proto.currency_pair_endpoint.GetCurrencyPairsReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.GetCurrencyPairsReplay;
  return proto.currency_pair_endpoint.GetCurrencyPairsReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairsReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsReplay}
 */
proto.currency_pair_endpoint.GetCurrencyPairsReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.currency_pair_endpoint.CurrencyPair;
      reader.readMessage(value,proto.currency_pair_endpoint.CurrencyPair.deserializeBinaryFromReader);
      msg.addCurrencypairs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.GetCurrencyPairsReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.GetCurrencyPairsReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairsReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairsReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrencypairsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.currency_pair_endpoint.CurrencyPair.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CurrencyPair currencyPairs = 1;
 * @return {!Array<!proto.currency_pair_endpoint.CurrencyPair>}
 */
proto.currency_pair_endpoint.GetCurrencyPairsReplay.prototype.getCurrencypairsList = function() {
  return /** @type{!Array<!proto.currency_pair_endpoint.CurrencyPair>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.currency_pair_endpoint.CurrencyPair, 1));
};


/**
 * @param {!Array<!proto.currency_pair_endpoint.CurrencyPair>} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairsReplay.prototype.setCurrencypairsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.currency_pair_endpoint.CurrencyPair=} opt_value
 * @param {number=} opt_index
 * @return {!proto.currency_pair_endpoint.CurrencyPair}
 */
proto.currency_pair_endpoint.GetCurrencyPairsReplay.prototype.addCurrencypairs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.currency_pair_endpoint.CurrencyPair, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairsReplay.prototype.clearCurrencypairsList = function() {
  return this.setCurrencypairsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.currency_pair_endpoint.AddCurrencyPairResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.currency_pair_endpoint.AddCurrencyPairResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.currency_pair_endpoint.AddCurrencyPairResponse.ResultCase}
 */
proto.currency_pair_endpoint.AddCurrencyPairResponse.prototype.getResultCase = function() {
  return /** @type {proto.currency_pair_endpoint.AddCurrencyPairResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.currency_pair_endpoint.AddCurrencyPairResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.AddCurrencyPairResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.AddCurrencyPairResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.AddCurrencyPairResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.AddCurrencyPairResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.currency_pair_endpoint.AddCurrencyPairReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.AddCurrencyPairResponse}
 */
proto.currency_pair_endpoint.AddCurrencyPairResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.AddCurrencyPairResponse;
  return proto.currency_pair_endpoint.AddCurrencyPairResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.AddCurrencyPairResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.AddCurrencyPairResponse}
 */
proto.currency_pair_endpoint.AddCurrencyPairResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.currency_pair_endpoint.AddCurrencyPairReplay;
      reader.readMessage(value,proto.currency_pair_endpoint.AddCurrencyPairReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.AddCurrencyPairResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.AddCurrencyPairResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.AddCurrencyPairResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.AddCurrencyPairResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.currency_pair_endpoint.AddCurrencyPairReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional AddCurrencyPairReplay success = 1;
 * @return {?proto.currency_pair_endpoint.AddCurrencyPairReplay}
 */
proto.currency_pair_endpoint.AddCurrencyPairResponse.prototype.getSuccess = function() {
  return /** @type{?proto.currency_pair_endpoint.AddCurrencyPairReplay} */ (
    jspb.Message.getWrapperField(this, proto.currency_pair_endpoint.AddCurrencyPairReplay, 1));
};


/**
 * @param {?proto.currency_pair_endpoint.AddCurrencyPairReplay|undefined} value
 * @return {!proto.currency_pair_endpoint.AddCurrencyPairResponse} returns this
*/
proto.currency_pair_endpoint.AddCurrencyPairResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.currency_pair_endpoint.AddCurrencyPairResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.AddCurrencyPairResponse} returns this
 */
proto.currency_pair_endpoint.AddCurrencyPairResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.AddCurrencyPairResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse.ResultCase}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse.prototype.getResultCase = function() {
  return /** @type {proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse;
  return proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay;
      reader.readMessage(value,proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateCurrencyPairActivityReplay success = 1;
 * @return {?proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse.prototype.getSuccess = function() {
  return /** @type{?proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay} */ (
    jspb.Message.getWrapperField(this, proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay, 1));
};


/**
 * @param {?proto.currency_pair_endpoint.UpdateCurrencyPairActivityReplay|undefined} value
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse} returns this
*/
proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse} returns this
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairActivityResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse.ResultCase}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse.prototype.getResultCase = function() {
  return /** @type {proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse;
  return proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay;
      reader.readMessage(value,proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateCurrencyPairPriceReplay success = 1;
 * @return {?proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse.prototype.getSuccess = function() {
  return /** @type{?proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay} */ (
    jspb.Message.getWrapperField(this, proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay, 1));
};


/**
 * @param {?proto.currency_pair_endpoint.UpdateCurrencyPairPriceReplay|undefined} value
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse} returns this
*/
proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse} returns this
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.currency_pair_endpoint.DeleteCurrencyPairResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.currency_pair_endpoint.DeleteCurrencyPairResponse.ResultCase}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairResponse.prototype.getResultCase = function() {
  return /** @type {proto.currency_pair_endpoint.DeleteCurrencyPairResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.currency_pair_endpoint.DeleteCurrencyPairResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.DeleteCurrencyPairResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.DeleteCurrencyPairResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.DeleteCurrencyPairResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.currency_pair_endpoint.DeleteCurrencyPairReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.DeleteCurrencyPairResponse}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.DeleteCurrencyPairResponse;
  return proto.currency_pair_endpoint.DeleteCurrencyPairResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.DeleteCurrencyPairResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.DeleteCurrencyPairResponse}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.currency_pair_endpoint.DeleteCurrencyPairReplay;
      reader.readMessage(value,proto.currency_pair_endpoint.DeleteCurrencyPairReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.DeleteCurrencyPairResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.DeleteCurrencyPairResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.DeleteCurrencyPairResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.currency_pair_endpoint.DeleteCurrencyPairReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeleteCurrencyPairReplay success = 1;
 * @return {?proto.currency_pair_endpoint.DeleteCurrencyPairReplay}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairResponse.prototype.getSuccess = function() {
  return /** @type{?proto.currency_pair_endpoint.DeleteCurrencyPairReplay} */ (
    jspb.Message.getWrapperField(this, proto.currency_pair_endpoint.DeleteCurrencyPairReplay, 1));
};


/**
 * @param {?proto.currency_pair_endpoint.DeleteCurrencyPairReplay|undefined} value
 * @return {!proto.currency_pair_endpoint.DeleteCurrencyPairResponse} returns this
*/
proto.currency_pair_endpoint.DeleteCurrencyPairResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.currency_pair_endpoint.DeleteCurrencyPairResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.DeleteCurrencyPairResponse} returns this
 */
proto.currency_pair_endpoint.DeleteCurrencyPairResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.currency_pair_endpoint.GetCurrencyPairsResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.currency_pair_endpoint.GetCurrencyPairsResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.currency_pair_endpoint.GetCurrencyPairsResponse.ResultCase}
 */
proto.currency_pair_endpoint.GetCurrencyPairsResponse.prototype.getResultCase = function() {
  return /** @type {proto.currency_pair_endpoint.GetCurrencyPairsResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.currency_pair_endpoint.GetCurrencyPairsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.GetCurrencyPairsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.GetCurrencyPairsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.currency_pair_endpoint.GetCurrencyPairsReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsResponse}
 */
proto.currency_pair_endpoint.GetCurrencyPairsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.GetCurrencyPairsResponse;
  return proto.currency_pair_endpoint.GetCurrencyPairsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsResponse}
 */
proto.currency_pair_endpoint.GetCurrencyPairsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.currency_pair_endpoint.GetCurrencyPairsReplay;
      reader.readMessage(value,proto.currency_pair_endpoint.GetCurrencyPairsReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.GetCurrencyPairsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.GetCurrencyPairsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.currency_pair_endpoint.GetCurrencyPairsReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetCurrencyPairsReplay success = 1;
 * @return {?proto.currency_pair_endpoint.GetCurrencyPairsReplay}
 */
proto.currency_pair_endpoint.GetCurrencyPairsResponse.prototype.getSuccess = function() {
  return /** @type{?proto.currency_pair_endpoint.GetCurrencyPairsReplay} */ (
    jspb.Message.getWrapperField(this, proto.currency_pair_endpoint.GetCurrencyPairsReplay, 1));
};


/**
 * @param {?proto.currency_pair_endpoint.GetCurrencyPairsReplay|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsResponse} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.currency_pair_endpoint.GetCurrencyPairsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsResponse} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairsResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairsResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.currency_pair_endpoint.GetCurrencyPairResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.currency_pair_endpoint.GetCurrencyPairResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.currency_pair_endpoint.GetCurrencyPairResponse.ResultCase}
 */
proto.currency_pair_endpoint.GetCurrencyPairResponse.prototype.getResultCase = function() {
  return /** @type {proto.currency_pair_endpoint.GetCurrencyPairResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.currency_pair_endpoint.GetCurrencyPairResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.GetCurrencyPairResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.GetCurrencyPairResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.currency_pair_endpoint.GetCurrencyPairReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairResponse}
 */
proto.currency_pair_endpoint.GetCurrencyPairResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.GetCurrencyPairResponse;
  return proto.currency_pair_endpoint.GetCurrencyPairResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairResponse}
 */
proto.currency_pair_endpoint.GetCurrencyPairResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.currency_pair_endpoint.GetCurrencyPairReplay;
      reader.readMessage(value,proto.currency_pair_endpoint.GetCurrencyPairReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.GetCurrencyPairResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.GetCurrencyPairResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.currency_pair_endpoint.GetCurrencyPairReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetCurrencyPairReplay success = 1;
 * @return {?proto.currency_pair_endpoint.GetCurrencyPairReplay}
 */
proto.currency_pair_endpoint.GetCurrencyPairResponse.prototype.getSuccess = function() {
  return /** @type{?proto.currency_pair_endpoint.GetCurrencyPairReplay} */ (
    jspb.Message.getWrapperField(this, proto.currency_pair_endpoint.GetCurrencyPairReplay, 1));
};


/**
 * @param {?proto.currency_pair_endpoint.GetCurrencyPairReplay|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairResponse} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.currency_pair_endpoint.GetCurrencyPairResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairResponse} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.currency_pair_endpoint.UpdateCurrencyPairResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.currency_pair_endpoint.UpdateCurrencyPairResponse.ResultCase}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairResponse.prototype.getResultCase = function() {
  return /** @type {proto.currency_pair_endpoint.UpdateCurrencyPairResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.currency_pair_endpoint.UpdateCurrencyPairResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.UpdateCurrencyPairResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.UpdateCurrencyPairResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.currency_pair_endpoint.UpdateCurrencyPairReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairResponse}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.UpdateCurrencyPairResponse;
  return proto.currency_pair_endpoint.UpdateCurrencyPairResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairResponse}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.currency_pair_endpoint.UpdateCurrencyPairReplay;
      reader.readMessage(value,proto.currency_pair_endpoint.UpdateCurrencyPairReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.UpdateCurrencyPairResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.UpdateCurrencyPairResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.currency_pair_endpoint.UpdateCurrencyPairReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateCurrencyPairReplay success = 1;
 * @return {?proto.currency_pair_endpoint.UpdateCurrencyPairReplay}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairResponse.prototype.getSuccess = function() {
  return /** @type{?proto.currency_pair_endpoint.UpdateCurrencyPairReplay} */ (
    jspb.Message.getWrapperField(this, proto.currency_pair_endpoint.UpdateCurrencyPairReplay, 1));
};


/**
 * @param {?proto.currency_pair_endpoint.UpdateCurrencyPairReplay|undefined} value
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairResponse} returns this
*/
proto.currency_pair_endpoint.UpdateCurrencyPairResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.currency_pair_endpoint.UpdateCurrencyPairResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairResponse} returns this
 */
proto.currency_pair_endpoint.UpdateCurrencyPairResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.currency_pair_endpoint.SetPriceTriggerResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.currency_pair_endpoint.SetPriceTriggerResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.currency_pair_endpoint.SetPriceTriggerResponse.ResultCase}
 */
proto.currency_pair_endpoint.SetPriceTriggerResponse.prototype.getResultCase = function() {
  return /** @type {proto.currency_pair_endpoint.SetPriceTriggerResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.currency_pair_endpoint.SetPriceTriggerResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.SetPriceTriggerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.SetPriceTriggerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.SetPriceTriggerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.SetPriceTriggerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.currency_pair_endpoint.SetPriceTriggerReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.SetPriceTriggerResponse}
 */
proto.currency_pair_endpoint.SetPriceTriggerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.SetPriceTriggerResponse;
  return proto.currency_pair_endpoint.SetPriceTriggerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.SetPriceTriggerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.SetPriceTriggerResponse}
 */
proto.currency_pair_endpoint.SetPriceTriggerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.currency_pair_endpoint.SetPriceTriggerReplay;
      reader.readMessage(value,proto.currency_pair_endpoint.SetPriceTriggerReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.SetPriceTriggerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.SetPriceTriggerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.SetPriceTriggerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.SetPriceTriggerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.currency_pair_endpoint.SetPriceTriggerReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional SetPriceTriggerReplay success = 1;
 * @return {?proto.currency_pair_endpoint.SetPriceTriggerReplay}
 */
proto.currency_pair_endpoint.SetPriceTriggerResponse.prototype.getSuccess = function() {
  return /** @type{?proto.currency_pair_endpoint.SetPriceTriggerReplay} */ (
    jspb.Message.getWrapperField(this, proto.currency_pair_endpoint.SetPriceTriggerReplay, 1));
};


/**
 * @param {?proto.currency_pair_endpoint.SetPriceTriggerReplay|undefined} value
 * @return {!proto.currency_pair_endpoint.SetPriceTriggerResponse} returns this
*/
proto.currency_pair_endpoint.SetPriceTriggerResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.currency_pair_endpoint.SetPriceTriggerResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.SetPriceTriggerResponse} returns this
 */
proto.currency_pair_endpoint.SetPriceTriggerResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.SetPriceTriggerResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.currency_pair_endpoint);
