// source: treasury.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('./common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.treasury_endpoint.AddTreasuryReplay', null, global);
goog.exportSymbol('proto.treasury_endpoint.AddTreasuryRequest', null, global);
goog.exportSymbol('proto.treasury_endpoint.AddTreasuryResponse', null, global);
goog.exportSymbol('proto.treasury_endpoint.AddTreasuryResponse.ResultCase', null, global);
goog.exportSymbol('proto.treasury_endpoint.AppendTreasuryCurrencyReplay', null, global);
goog.exportSymbol('proto.treasury_endpoint.AppendTreasuryCurrencyRequest', null, global);
goog.exportSymbol('proto.treasury_endpoint.AppendTreasuryCurrencyResponse', null, global);
goog.exportSymbol('proto.treasury_endpoint.AppendTreasuryCurrencyResponse.ResultCase', null, global);
goog.exportSymbol('proto.treasury_endpoint.CloseTreasuryReplay', null, global);
goog.exportSymbol('proto.treasury_endpoint.CloseTreasuryRequest', null, global);
goog.exportSymbol('proto.treasury_endpoint.CloseTreasuryResponse', null, global);
goog.exportSymbol('proto.treasury_endpoint.CloseTreasuryResponse.ResultCase', null, global);
goog.exportSymbol('proto.treasury_endpoint.Deposit', null, global);
goog.exportSymbol('proto.treasury_endpoint.DepositResponse', null, global);
goog.exportSymbol('proto.treasury_endpoint.GetTreasuriesReplay', null, global);
goog.exportSymbol('proto.treasury_endpoint.GetTreasuriesRequest', null, global);
goog.exportSymbol('proto.treasury_endpoint.GetTreasuriesResponse', null, global);
goog.exportSymbol('proto.treasury_endpoint.GetTreasuriesResponse.ResultCase', null, global);
goog.exportSymbol('proto.treasury_endpoint.GetTreasuryReplay', null, global);
goog.exportSymbol('proto.treasury_endpoint.GetTreasuryRequest', null, global);
goog.exportSymbol('proto.treasury_endpoint.GetTreasuryResponse', null, global);
goog.exportSymbol('proto.treasury_endpoint.GetTreasuryResponse.ResultCase', null, global);
goog.exportSymbol('proto.treasury_endpoint.Nature', null, global);
goog.exportSymbol('proto.treasury_endpoint.Treasury', null, global);
goog.exportSymbol('proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay', null, global);
goog.exportSymbol('proto.treasury_endpoint.UpdateTreasuryCurrencyActivityRequest', null, global);
goog.exportSymbol('proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse', null, global);
goog.exportSymbol('proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse.ResultCase', null, global);
goog.exportSymbol('proto.treasury_endpoint.UpdateTreasuryLimitationReplay', null, global);
goog.exportSymbol('proto.treasury_endpoint.UpdateTreasuryLimitationRequest', null, global);
goog.exportSymbol('proto.treasury_endpoint.UpdateTreasuryLimitationResponse', null, global);
goog.exportSymbol('proto.treasury_endpoint.UpdateTreasuryLimitationResponse.ResultCase', null, global);
goog.exportSymbol('proto.treasury_endpoint.UpdateTreasuryReplay', null, global);
goog.exportSymbol('proto.treasury_endpoint.UpdateTreasuryRequest', null, global);
goog.exportSymbol('proto.treasury_endpoint.UpdateTreasuryResponse', null, global);
goog.exportSymbol('proto.treasury_endpoint.UpdateTreasuryResponse.ResultCase', null, global);
goog.exportSymbol('proto.treasury_endpoint.UpdateTreasuryStatusReplay', null, global);
goog.exportSymbol('proto.treasury_endpoint.UpdateTreasuryStatusRequest', null, global);
goog.exportSymbol('proto.treasury_endpoint.UpdateTreasuryStatusResponse', null, global);
goog.exportSymbol('proto.treasury_endpoint.UpdateTreasuryStatusResponse.ResultCase', null, global);
goog.exportSymbol('proto.treasury_endpoint.UpdateTreasuryTypeReplay', null, global);
goog.exportSymbol('proto.treasury_endpoint.UpdateTreasuryTypeRequest', null, global);
goog.exportSymbol('proto.treasury_endpoint.UpdateTreasuryTypeResponse', null, global);
goog.exportSymbol('proto.treasury_endpoint.UpdateTreasuryTypeResponse.ResultCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_endpoint.Deposit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_endpoint.Deposit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_endpoint.Deposit.displayName = 'proto.treasury_endpoint.Deposit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_endpoint.AddTreasuryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.treasury_endpoint.AddTreasuryRequest.repeatedFields_, null);
};
goog.inherits(proto.treasury_endpoint.AddTreasuryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_endpoint.AddTreasuryRequest.displayName = 'proto.treasury_endpoint.AddTreasuryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_endpoint.UpdateTreasuryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.treasury_endpoint.UpdateTreasuryRequest.repeatedFields_, null);
};
goog.inherits(proto.treasury_endpoint.UpdateTreasuryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_endpoint.UpdateTreasuryRequest.displayName = 'proto.treasury_endpoint.UpdateTreasuryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_endpoint.UpdateTreasuryTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_endpoint.UpdateTreasuryTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_endpoint.UpdateTreasuryTypeRequest.displayName = 'proto.treasury_endpoint.UpdateTreasuryTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_endpoint.UpdateTreasuryLimitationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_endpoint.UpdateTreasuryLimitationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_endpoint.UpdateTreasuryLimitationRequest.displayName = 'proto.treasury_endpoint.UpdateTreasuryLimitationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_endpoint.UpdateTreasuryStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_endpoint.UpdateTreasuryStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_endpoint.UpdateTreasuryStatusRequest.displayName = 'proto.treasury_endpoint.UpdateTreasuryStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_endpoint.GetTreasuryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_endpoint.GetTreasuryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_endpoint.GetTreasuryRequest.displayName = 'proto.treasury_endpoint.GetTreasuryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_endpoint.CloseTreasuryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_endpoint.CloseTreasuryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_endpoint.CloseTreasuryRequest.displayName = 'proto.treasury_endpoint.CloseTreasuryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_endpoint.GetTreasuriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_endpoint.GetTreasuriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_endpoint.GetTreasuriesRequest.displayName = 'proto.treasury_endpoint.GetTreasuriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_endpoint.AppendTreasuryCurrencyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_endpoint.AppendTreasuryCurrencyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_endpoint.AppendTreasuryCurrencyRequest.displayName = 'proto.treasury_endpoint.AppendTreasuryCurrencyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_endpoint.UpdateTreasuryCurrencyActivityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_endpoint.UpdateTreasuryCurrencyActivityRequest.displayName = 'proto.treasury_endpoint.UpdateTreasuryCurrencyActivityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_endpoint.CloseTreasuryReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_endpoint.CloseTreasuryReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_endpoint.CloseTreasuryReplay.displayName = 'proto.treasury_endpoint.CloseTreasuryReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_endpoint.AddTreasuryReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_endpoint.AddTreasuryReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_endpoint.AddTreasuryReplay.displayName = 'proto.treasury_endpoint.AddTreasuryReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_endpoint.UpdateTreasuryLimitationReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_endpoint.UpdateTreasuryLimitationReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_endpoint.UpdateTreasuryLimitationReplay.displayName = 'proto.treasury_endpoint.UpdateTreasuryLimitationReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_endpoint.UpdateTreasuryReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_endpoint.UpdateTreasuryReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_endpoint.UpdateTreasuryReplay.displayName = 'proto.treasury_endpoint.UpdateTreasuryReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_endpoint.UpdateTreasuryTypeReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_endpoint.UpdateTreasuryTypeReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_endpoint.UpdateTreasuryTypeReplay.displayName = 'proto.treasury_endpoint.UpdateTreasuryTypeReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_endpoint.UpdateTreasuryStatusReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_endpoint.UpdateTreasuryStatusReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_endpoint.UpdateTreasuryStatusReplay.displayName = 'proto.treasury_endpoint.UpdateTreasuryStatusReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_endpoint.DepositResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_endpoint.DepositResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_endpoint.DepositResponse.displayName = 'proto.treasury_endpoint.DepositResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_endpoint.Nature = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_endpoint.Nature, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_endpoint.Nature.displayName = 'proto.treasury_endpoint.Nature';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_endpoint.GetTreasuryReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.treasury_endpoint.GetTreasuryReplay.repeatedFields_, null);
};
goog.inherits(proto.treasury_endpoint.GetTreasuryReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_endpoint.GetTreasuryReplay.displayName = 'proto.treasury_endpoint.GetTreasuryReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_endpoint.Treasury = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_endpoint.Treasury, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_endpoint.Treasury.displayName = 'proto.treasury_endpoint.Treasury';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_endpoint.GetTreasuriesReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.treasury_endpoint.GetTreasuriesReplay.repeatedFields_, null);
};
goog.inherits(proto.treasury_endpoint.GetTreasuriesReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_endpoint.GetTreasuriesReplay.displayName = 'proto.treasury_endpoint.GetTreasuriesReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_endpoint.AppendTreasuryCurrencyReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_endpoint.AppendTreasuryCurrencyReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_endpoint.AppendTreasuryCurrencyReplay.displayName = 'proto.treasury_endpoint.AppendTreasuryCurrencyReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay.displayName = 'proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_endpoint.AddTreasuryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.treasury_endpoint.AddTreasuryResponse.oneofGroups_);
};
goog.inherits(proto.treasury_endpoint.AddTreasuryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_endpoint.AddTreasuryResponse.displayName = 'proto.treasury_endpoint.AddTreasuryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_endpoint.UpdateTreasuryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.treasury_endpoint.UpdateTreasuryResponse.oneofGroups_);
};
goog.inherits(proto.treasury_endpoint.UpdateTreasuryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_endpoint.UpdateTreasuryResponse.displayName = 'proto.treasury_endpoint.UpdateTreasuryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_endpoint.UpdateTreasuryTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.treasury_endpoint.UpdateTreasuryTypeResponse.oneofGroups_);
};
goog.inherits(proto.treasury_endpoint.UpdateTreasuryTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_endpoint.UpdateTreasuryTypeResponse.displayName = 'proto.treasury_endpoint.UpdateTreasuryTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_endpoint.UpdateTreasuryLimitationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.treasury_endpoint.UpdateTreasuryLimitationResponse.oneofGroups_);
};
goog.inherits(proto.treasury_endpoint.UpdateTreasuryLimitationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_endpoint.UpdateTreasuryLimitationResponse.displayName = 'proto.treasury_endpoint.UpdateTreasuryLimitationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_endpoint.UpdateTreasuryStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.treasury_endpoint.UpdateTreasuryStatusResponse.oneofGroups_);
};
goog.inherits(proto.treasury_endpoint.UpdateTreasuryStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_endpoint.UpdateTreasuryStatusResponse.displayName = 'proto.treasury_endpoint.UpdateTreasuryStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_endpoint.CloseTreasuryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.treasury_endpoint.CloseTreasuryResponse.oneofGroups_);
};
goog.inherits(proto.treasury_endpoint.CloseTreasuryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_endpoint.CloseTreasuryResponse.displayName = 'proto.treasury_endpoint.CloseTreasuryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_endpoint.GetTreasuryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.treasury_endpoint.GetTreasuryResponse.oneofGroups_);
};
goog.inherits(proto.treasury_endpoint.GetTreasuryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_endpoint.GetTreasuryResponse.displayName = 'proto.treasury_endpoint.GetTreasuryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_endpoint.GetTreasuriesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.treasury_endpoint.GetTreasuriesResponse.oneofGroups_);
};
goog.inherits(proto.treasury_endpoint.GetTreasuriesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_endpoint.GetTreasuriesResponse.displayName = 'proto.treasury_endpoint.GetTreasuriesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_endpoint.AppendTreasuryCurrencyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.treasury_endpoint.AppendTreasuryCurrencyResponse.oneofGroups_);
};
goog.inherits(proto.treasury_endpoint.AppendTreasuryCurrencyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_endpoint.AppendTreasuryCurrencyResponse.displayName = 'proto.treasury_endpoint.AppendTreasuryCurrencyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse.oneofGroups_);
};
goog.inherits(proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse.displayName = 'proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_endpoint.Deposit.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_endpoint.Deposit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_endpoint.Deposit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.Deposit.toObject = function(includeInstance, msg) {
  var f, obj = {
    currency: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isactive: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_endpoint.Deposit}
 */
proto.treasury_endpoint.Deposit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_endpoint.Deposit;
  return proto.treasury_endpoint.Deposit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_endpoint.Deposit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_endpoint.Deposit}
 */
proto.treasury_endpoint.Deposit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsactive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_endpoint.Deposit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_endpoint.Deposit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_endpoint.Deposit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.Deposit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsactive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string currency = 1;
 * @return {string}
 */
proto.treasury_endpoint.Deposit.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_endpoint.Deposit} returns this
 */
proto.treasury_endpoint.Deposit.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool isActive = 2;
 * @return {boolean}
 */
proto.treasury_endpoint.Deposit.prototype.getIsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.treasury_endpoint.Deposit} returns this
 */
proto.treasury_endpoint.Deposit.prototype.setIsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.treasury_endpoint.AddTreasuryRequest.repeatedFields_ = [2,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_endpoint.AddTreasuryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_endpoint.AddTreasuryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_endpoint.AddTreasuryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.AddTreasuryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    depositsList: jspb.Message.toObjectList(msg.getDepositsList(),
    proto.treasury_endpoint.Deposit.toObject, includeInstance),
    nature: jspb.Message.getFieldWithDefault(msg, 3, 0),
    treasurytype: jspb.Message.getFieldWithDefault(msg, 4, ""),
    code: jspb.Message.getFieldWithDefault(msg, 5, ""),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    limitation: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_endpoint.AddTreasuryRequest}
 */
proto.treasury_endpoint.AddTreasuryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_endpoint.AddTreasuryRequest;
  return proto.treasury_endpoint.AddTreasuryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_endpoint.AddTreasuryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_endpoint.AddTreasuryRequest}
 */
proto.treasury_endpoint.AddTreasuryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.treasury_endpoint.Deposit;
      reader.readMessage(value,proto.treasury_endpoint.Deposit.deserializeBinaryFromReader);
      msg.addDeposits(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNature(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTreasurytype(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 6:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLimitation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_endpoint.AddTreasuryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_endpoint.AddTreasuryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_endpoint.AddTreasuryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.AddTreasuryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDepositsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.treasury_endpoint.Deposit.serializeBinaryToWriter
    );
  }
  f = message.getNature();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTreasurytype();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getLimitation();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.treasury_endpoint.AddTreasuryRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_endpoint.AddTreasuryRequest} returns this
 */
proto.treasury_endpoint.AddTreasuryRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Deposit deposits = 2;
 * @return {!Array<!proto.treasury_endpoint.Deposit>}
 */
proto.treasury_endpoint.AddTreasuryRequest.prototype.getDepositsList = function() {
  return /** @type{!Array<!proto.treasury_endpoint.Deposit>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.treasury_endpoint.Deposit, 2));
};


/**
 * @param {!Array<!proto.treasury_endpoint.Deposit>} value
 * @return {!proto.treasury_endpoint.AddTreasuryRequest} returns this
*/
proto.treasury_endpoint.AddTreasuryRequest.prototype.setDepositsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.treasury_endpoint.Deposit=} opt_value
 * @param {number=} opt_index
 * @return {!proto.treasury_endpoint.Deposit}
 */
proto.treasury_endpoint.AddTreasuryRequest.prototype.addDeposits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.treasury_endpoint.Deposit, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.treasury_endpoint.AddTreasuryRequest} returns this
 */
proto.treasury_endpoint.AddTreasuryRequest.prototype.clearDepositsList = function() {
  return this.setDepositsList([]);
};


/**
 * optional int32 nature = 3;
 * @return {number}
 */
proto.treasury_endpoint.AddTreasuryRequest.prototype.getNature = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.treasury_endpoint.AddTreasuryRequest} returns this
 */
proto.treasury_endpoint.AddTreasuryRequest.prototype.setNature = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string treasuryType = 4;
 * @return {string}
 */
proto.treasury_endpoint.AddTreasuryRequest.prototype.getTreasurytype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_endpoint.AddTreasuryRequest} returns this
 */
proto.treasury_endpoint.AddTreasuryRequest.prototype.setTreasurytype = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string code = 5;
 * @return {string}
 */
proto.treasury_endpoint.AddTreasuryRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_endpoint.AddTreasuryRequest} returns this
 */
proto.treasury_endpoint.AddTreasuryRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated common.ExtraField extraFields = 6;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.treasury_endpoint.AddTreasuryRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 6));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.treasury_endpoint.AddTreasuryRequest} returns this
*/
proto.treasury_endpoint.AddTreasuryRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.treasury_endpoint.AddTreasuryRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.treasury_endpoint.AddTreasuryRequest} returns this
 */
proto.treasury_endpoint.AddTreasuryRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};


/**
 * optional google.protobuf.Int32Value organization = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.treasury_endpoint.AddTreasuryRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.treasury_endpoint.AddTreasuryRequest} returns this
*/
proto.treasury_endpoint.AddTreasuryRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.AddTreasuryRequest} returns this
 */
proto.treasury_endpoint.AddTreasuryRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.AddTreasuryRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string limitation = 8;
 * @return {string}
 */
proto.treasury_endpoint.AddTreasuryRequest.prototype.getLimitation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_endpoint.AddTreasuryRequest} returns this
 */
proto.treasury_endpoint.AddTreasuryRequest.prototype.setLimitation = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.treasury_endpoint.UpdateTreasuryRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_endpoint.UpdateTreasuryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_endpoint.UpdateTreasuryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_endpoint.UpdateTreasuryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.UpdateTreasuryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    code: jspb.Message.getFieldWithDefault(msg, 3, ""),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_endpoint.UpdateTreasuryRequest}
 */
proto.treasury_endpoint.UpdateTreasuryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_endpoint.UpdateTreasuryRequest;
  return proto.treasury_endpoint.UpdateTreasuryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_endpoint.UpdateTreasuryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_endpoint.UpdateTreasuryRequest}
 */
proto.treasury_endpoint.UpdateTreasuryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 4:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_endpoint.UpdateTreasuryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_endpoint.UpdateTreasuryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_endpoint.UpdateTreasuryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.UpdateTreasuryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.treasury_endpoint.UpdateTreasuryRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_endpoint.UpdateTreasuryRequest} returns this
 */
proto.treasury_endpoint.UpdateTreasuryRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.treasury_endpoint.UpdateTreasuryRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_endpoint.UpdateTreasuryRequest} returns this
 */
proto.treasury_endpoint.UpdateTreasuryRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.treasury_endpoint.UpdateTreasuryRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_endpoint.UpdateTreasuryRequest} returns this
 */
proto.treasury_endpoint.UpdateTreasuryRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated common.ExtraField extraFields = 4;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.treasury_endpoint.UpdateTreasuryRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 4));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.treasury_endpoint.UpdateTreasuryRequest} returns this
*/
proto.treasury_endpoint.UpdateTreasuryRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.treasury_endpoint.UpdateTreasuryRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.treasury_endpoint.UpdateTreasuryRequest} returns this
 */
proto.treasury_endpoint.UpdateTreasuryRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_endpoint.UpdateTreasuryTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_endpoint.UpdateTreasuryTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_endpoint.UpdateTreasuryTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.UpdateTreasuryTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    treasurytype: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_endpoint.UpdateTreasuryTypeRequest}
 */
proto.treasury_endpoint.UpdateTreasuryTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_endpoint.UpdateTreasuryTypeRequest;
  return proto.treasury_endpoint.UpdateTreasuryTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_endpoint.UpdateTreasuryTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_endpoint.UpdateTreasuryTypeRequest}
 */
proto.treasury_endpoint.UpdateTreasuryTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTreasurytype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_endpoint.UpdateTreasuryTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_endpoint.UpdateTreasuryTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_endpoint.UpdateTreasuryTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.UpdateTreasuryTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTreasurytype();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.treasury_endpoint.UpdateTreasuryTypeRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_endpoint.UpdateTreasuryTypeRequest} returns this
 */
proto.treasury_endpoint.UpdateTreasuryTypeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string treasuryType = 2;
 * @return {string}
 */
proto.treasury_endpoint.UpdateTreasuryTypeRequest.prototype.getTreasurytype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_endpoint.UpdateTreasuryTypeRequest} returns this
 */
proto.treasury_endpoint.UpdateTreasuryTypeRequest.prototype.setTreasurytype = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_endpoint.UpdateTreasuryLimitationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_endpoint.UpdateTreasuryLimitationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_endpoint.UpdateTreasuryLimitationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.UpdateTreasuryLimitationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    limitation: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_endpoint.UpdateTreasuryLimitationRequest}
 */
proto.treasury_endpoint.UpdateTreasuryLimitationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_endpoint.UpdateTreasuryLimitationRequest;
  return proto.treasury_endpoint.UpdateTreasuryLimitationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_endpoint.UpdateTreasuryLimitationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_endpoint.UpdateTreasuryLimitationRequest}
 */
proto.treasury_endpoint.UpdateTreasuryLimitationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLimitation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_endpoint.UpdateTreasuryLimitationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_endpoint.UpdateTreasuryLimitationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_endpoint.UpdateTreasuryLimitationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.UpdateTreasuryLimitationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLimitation();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.treasury_endpoint.UpdateTreasuryLimitationRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_endpoint.UpdateTreasuryLimitationRequest} returns this
 */
proto.treasury_endpoint.UpdateTreasuryLimitationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string limitation = 2;
 * @return {string}
 */
proto.treasury_endpoint.UpdateTreasuryLimitationRequest.prototype.getLimitation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_endpoint.UpdateTreasuryLimitationRequest} returns this
 */
proto.treasury_endpoint.UpdateTreasuryLimitationRequest.prototype.setLimitation = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_endpoint.UpdateTreasuryStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_endpoint.UpdateTreasuryStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_endpoint.UpdateTreasuryStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.UpdateTreasuryStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_endpoint.UpdateTreasuryStatusRequest}
 */
proto.treasury_endpoint.UpdateTreasuryStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_endpoint.UpdateTreasuryStatusRequest;
  return proto.treasury_endpoint.UpdateTreasuryStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_endpoint.UpdateTreasuryStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_endpoint.UpdateTreasuryStatusRequest}
 */
proto.treasury_endpoint.UpdateTreasuryStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_endpoint.UpdateTreasuryStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_endpoint.UpdateTreasuryStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_endpoint.UpdateTreasuryStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.UpdateTreasuryStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.treasury_endpoint.UpdateTreasuryStatusRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_endpoint.UpdateTreasuryStatusRequest} returns this
 */
proto.treasury_endpoint.UpdateTreasuryStatusRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 status = 2;
 * @return {number}
 */
proto.treasury_endpoint.UpdateTreasuryStatusRequest.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.treasury_endpoint.UpdateTreasuryStatusRequest} returns this
 */
proto.treasury_endpoint.UpdateTreasuryStatusRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_endpoint.GetTreasuryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_endpoint.GetTreasuryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_endpoint.GetTreasuryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.GetTreasuryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_endpoint.GetTreasuryRequest}
 */
proto.treasury_endpoint.GetTreasuryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_endpoint.GetTreasuryRequest;
  return proto.treasury_endpoint.GetTreasuryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_endpoint.GetTreasuryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_endpoint.GetTreasuryRequest}
 */
proto.treasury_endpoint.GetTreasuryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_endpoint.GetTreasuryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_endpoint.GetTreasuryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_endpoint.GetTreasuryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.GetTreasuryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.treasury_endpoint.GetTreasuryRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_endpoint.GetTreasuryRequest} returns this
 */
proto.treasury_endpoint.GetTreasuryRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_endpoint.CloseTreasuryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_endpoint.CloseTreasuryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_endpoint.CloseTreasuryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.CloseTreasuryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_endpoint.CloseTreasuryRequest}
 */
proto.treasury_endpoint.CloseTreasuryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_endpoint.CloseTreasuryRequest;
  return proto.treasury_endpoint.CloseTreasuryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_endpoint.CloseTreasuryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_endpoint.CloseTreasuryRequest}
 */
proto.treasury_endpoint.CloseTreasuryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_endpoint.CloseTreasuryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_endpoint.CloseTreasuryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_endpoint.CloseTreasuryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.CloseTreasuryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.treasury_endpoint.CloseTreasuryRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_endpoint.CloseTreasuryRequest} returns this
 */
proto.treasury_endpoint.CloseTreasuryRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_endpoint.GetTreasuriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_endpoint.GetTreasuriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_endpoint.GetTreasuriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.GetTreasuriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    treasurytype: (f = msg.getTreasurytype()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    currency: (f = msg.getCurrency()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    natrue: (f = msg.getNatrue()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    code: (f = msg.getCode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    order: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    nextto: (f = msg.getNextto()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    numofresults: jspb.Message.getFieldWithDefault(msg, 10, 0),
    limitation: (f = msg.getLimitation()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_endpoint.GetTreasuriesRequest}
 */
proto.treasury_endpoint.GetTreasuriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_endpoint.GetTreasuriesRequest;
  return proto.treasury_endpoint.GetTreasuriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_endpoint.GetTreasuriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_endpoint.GetTreasuriesRequest}
 */
proto.treasury_endpoint.GetTreasuriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTreasurytype(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setNatrue(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCode(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrder(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNextto(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumofresults(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLimitation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_endpoint.GetTreasuriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_endpoint.GetTreasuriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_endpoint.GetTreasuriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.GetTreasuriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getTreasurytype();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNatrue();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getCode();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getNextto();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNumofresults();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getLimitation();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value organization = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.treasury_endpoint.GetTreasuriesRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.treasury_endpoint.GetTreasuriesRequest} returns this
*/
proto.treasury_endpoint.GetTreasuriesRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.GetTreasuriesRequest} returns this
 */
proto.treasury_endpoint.GetTreasuriesRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.GetTreasuriesRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue treasuryType = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_endpoint.GetTreasuriesRequest.prototype.getTreasurytype = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_endpoint.GetTreasuriesRequest} returns this
*/
proto.treasury_endpoint.GetTreasuriesRequest.prototype.setTreasurytype = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.GetTreasuriesRequest} returns this
 */
proto.treasury_endpoint.GetTreasuriesRequest.prototype.clearTreasurytype = function() {
  return this.setTreasurytype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.GetTreasuriesRequest.prototype.hasTreasurytype = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue currency = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_endpoint.GetTreasuriesRequest.prototype.getCurrency = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_endpoint.GetTreasuriesRequest} returns this
*/
proto.treasury_endpoint.GetTreasuriesRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.GetTreasuriesRequest} returns this
 */
proto.treasury_endpoint.GetTreasuriesRequest.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.GetTreasuriesRequest.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Int32Value natrue = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.treasury_endpoint.GetTreasuriesRequest.prototype.getNatrue = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.treasury_endpoint.GetTreasuriesRequest} returns this
*/
proto.treasury_endpoint.GetTreasuriesRequest.prototype.setNatrue = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.GetTreasuriesRequest} returns this
 */
proto.treasury_endpoint.GetTreasuriesRequest.prototype.clearNatrue = function() {
  return this.setNatrue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.GetTreasuriesRequest.prototype.hasNatrue = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Int32Value status = 5;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.treasury_endpoint.GetTreasuriesRequest.prototype.getStatus = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 5));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.treasury_endpoint.GetTreasuriesRequest} returns this
*/
proto.treasury_endpoint.GetTreasuriesRequest.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.GetTreasuriesRequest} returns this
 */
proto.treasury_endpoint.GetTreasuriesRequest.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.GetTreasuriesRequest.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue code = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_endpoint.GetTreasuriesRequest.prototype.getCode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_endpoint.GetTreasuriesRequest} returns this
*/
proto.treasury_endpoint.GetTreasuriesRequest.prototype.setCode = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.GetTreasuriesRequest} returns this
 */
proto.treasury_endpoint.GetTreasuriesRequest.prototype.clearCode = function() {
  return this.setCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.GetTreasuriesRequest.prototype.hasCode = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue search = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_endpoint.GetTreasuriesRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_endpoint.GetTreasuriesRequest} returns this
*/
proto.treasury_endpoint.GetTreasuriesRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.GetTreasuriesRequest} returns this
 */
proto.treasury_endpoint.GetTreasuriesRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.GetTreasuriesRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool order = 8;
 * @return {boolean}
 */
proto.treasury_endpoint.GetTreasuriesRequest.prototype.getOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.treasury_endpoint.GetTreasuriesRequest} returns this
 */
proto.treasury_endpoint.GetTreasuriesRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional google.protobuf.StringValue nextTo = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_endpoint.GetTreasuriesRequest.prototype.getNextto = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_endpoint.GetTreasuriesRequest} returns this
*/
proto.treasury_endpoint.GetTreasuriesRequest.prototype.setNextto = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.GetTreasuriesRequest} returns this
 */
proto.treasury_endpoint.GetTreasuriesRequest.prototype.clearNextto = function() {
  return this.setNextto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.GetTreasuriesRequest.prototype.hasNextto = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional int32 numOfResults = 10;
 * @return {number}
 */
proto.treasury_endpoint.GetTreasuriesRequest.prototype.getNumofresults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.treasury_endpoint.GetTreasuriesRequest} returns this
 */
proto.treasury_endpoint.GetTreasuriesRequest.prototype.setNumofresults = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional google.protobuf.StringValue limitation = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_endpoint.GetTreasuriesRequest.prototype.getLimitation = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_endpoint.GetTreasuriesRequest} returns this
*/
proto.treasury_endpoint.GetTreasuriesRequest.prototype.setLimitation = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.GetTreasuriesRequest} returns this
 */
proto.treasury_endpoint.GetTreasuriesRequest.prototype.clearLimitation = function() {
  return this.setLimitation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.GetTreasuriesRequest.prototype.hasLimitation = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_endpoint.AppendTreasuryCurrencyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_endpoint.AppendTreasuryCurrencyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_endpoint.AppendTreasuryCurrencyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.AppendTreasuryCurrencyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isactive: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_endpoint.AppendTreasuryCurrencyRequest}
 */
proto.treasury_endpoint.AppendTreasuryCurrencyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_endpoint.AppendTreasuryCurrencyRequest;
  return proto.treasury_endpoint.AppendTreasuryCurrencyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_endpoint.AppendTreasuryCurrencyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_endpoint.AppendTreasuryCurrencyRequest}
 */
proto.treasury_endpoint.AppendTreasuryCurrencyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsactive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_endpoint.AppendTreasuryCurrencyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_endpoint.AppendTreasuryCurrencyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_endpoint.AppendTreasuryCurrencyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.AppendTreasuryCurrencyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsactive();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.treasury_endpoint.AppendTreasuryCurrencyRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_endpoint.AppendTreasuryCurrencyRequest} returns this
 */
proto.treasury_endpoint.AppendTreasuryCurrencyRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string currency = 2;
 * @return {string}
 */
proto.treasury_endpoint.AppendTreasuryCurrencyRequest.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_endpoint.AppendTreasuryCurrencyRequest} returns this
 */
proto.treasury_endpoint.AppendTreasuryCurrencyRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool isActive = 3;
 * @return {boolean}
 */
proto.treasury_endpoint.AppendTreasuryCurrencyRequest.prototype.getIsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.treasury_endpoint.AppendTreasuryCurrencyRequest} returns this
 */
proto.treasury_endpoint.AppendTreasuryCurrencyRequest.prototype.setIsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_endpoint.UpdateTreasuryCurrencyActivityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_endpoint.UpdateTreasuryCurrencyActivityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isactive: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_endpoint.UpdateTreasuryCurrencyActivityRequest}
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_endpoint.UpdateTreasuryCurrencyActivityRequest;
  return proto.treasury_endpoint.UpdateTreasuryCurrencyActivityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_endpoint.UpdateTreasuryCurrencyActivityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_endpoint.UpdateTreasuryCurrencyActivityRequest}
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsactive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_endpoint.UpdateTreasuryCurrencyActivityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_endpoint.UpdateTreasuryCurrencyActivityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsactive();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_endpoint.UpdateTreasuryCurrencyActivityRequest} returns this
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string currency = 2;
 * @return {string}
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityRequest.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_endpoint.UpdateTreasuryCurrencyActivityRequest} returns this
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool isActive = 3;
 * @return {boolean}
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityRequest.prototype.getIsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.treasury_endpoint.UpdateTreasuryCurrencyActivityRequest} returns this
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityRequest.prototype.setIsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_endpoint.CloseTreasuryReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_endpoint.CloseTreasuryReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_endpoint.CloseTreasuryReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.CloseTreasuryReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_endpoint.CloseTreasuryReplay}
 */
proto.treasury_endpoint.CloseTreasuryReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_endpoint.CloseTreasuryReplay;
  return proto.treasury_endpoint.CloseTreasuryReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_endpoint.CloseTreasuryReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_endpoint.CloseTreasuryReplay}
 */
proto.treasury_endpoint.CloseTreasuryReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_endpoint.CloseTreasuryReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_endpoint.CloseTreasuryReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_endpoint.CloseTreasuryReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.CloseTreasuryReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.treasury_endpoint.CloseTreasuryReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.treasury_endpoint.CloseTreasuryReplay} returns this
*/
proto.treasury_endpoint.CloseTreasuryReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.CloseTreasuryReplay} returns this
 */
proto.treasury_endpoint.CloseTreasuryReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.CloseTreasuryReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.treasury_endpoint.CloseTreasuryReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.treasury_endpoint.CloseTreasuryReplay} returns this
*/
proto.treasury_endpoint.CloseTreasuryReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.CloseTreasuryReplay} returns this
 */
proto.treasury_endpoint.CloseTreasuryReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.CloseTreasuryReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_endpoint.AddTreasuryReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_endpoint.AddTreasuryReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_endpoint.AddTreasuryReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.AddTreasuryReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    internalid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_endpoint.AddTreasuryReplay}
 */
proto.treasury_endpoint.AddTreasuryReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_endpoint.AddTreasuryReplay;
  return proto.treasury_endpoint.AddTreasuryReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_endpoint.AddTreasuryReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_endpoint.AddTreasuryReplay}
 */
proto.treasury_endpoint.AddTreasuryReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalid(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 4:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_endpoint.AddTreasuryReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_endpoint.AddTreasuryReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_endpoint.AddTreasuryReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.AddTreasuryReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getInternalid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.treasury_endpoint.AddTreasuryReplay.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.treasury_endpoint.AddTreasuryReplay} returns this
 */
proto.treasury_endpoint.AddTreasuryReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string internalId = 2;
 * @return {string}
 */
proto.treasury_endpoint.AddTreasuryReplay.prototype.getInternalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_endpoint.AddTreasuryReplay} returns this
 */
proto.treasury_endpoint.AddTreasuryReplay.prototype.setInternalid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.treasury_endpoint.AddTreasuryReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.treasury_endpoint.AddTreasuryReplay} returns this
*/
proto.treasury_endpoint.AddTreasuryReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.AddTreasuryReplay} returns this
 */
proto.treasury_endpoint.AddTreasuryReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.AddTreasuryReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional common.MessageResponse message = 4;
 * @return {?proto.common.MessageResponse}
 */
proto.treasury_endpoint.AddTreasuryReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 4));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.treasury_endpoint.AddTreasuryReplay} returns this
*/
proto.treasury_endpoint.AddTreasuryReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.AddTreasuryReplay} returns this
 */
proto.treasury_endpoint.AddTreasuryReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.AddTreasuryReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_endpoint.UpdateTreasuryLimitationReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_endpoint.UpdateTreasuryLimitationReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_endpoint.UpdateTreasuryLimitationReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.UpdateTreasuryLimitationReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_endpoint.UpdateTreasuryLimitationReplay}
 */
proto.treasury_endpoint.UpdateTreasuryLimitationReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_endpoint.UpdateTreasuryLimitationReplay;
  return proto.treasury_endpoint.UpdateTreasuryLimitationReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_endpoint.UpdateTreasuryLimitationReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_endpoint.UpdateTreasuryLimitationReplay}
 */
proto.treasury_endpoint.UpdateTreasuryLimitationReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_endpoint.UpdateTreasuryLimitationReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_endpoint.UpdateTreasuryLimitationReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_endpoint.UpdateTreasuryLimitationReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.UpdateTreasuryLimitationReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.treasury_endpoint.UpdateTreasuryLimitationReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.treasury_endpoint.UpdateTreasuryLimitationReplay} returns this
*/
proto.treasury_endpoint.UpdateTreasuryLimitationReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.UpdateTreasuryLimitationReplay} returns this
 */
proto.treasury_endpoint.UpdateTreasuryLimitationReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.UpdateTreasuryLimitationReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.treasury_endpoint.UpdateTreasuryLimitationReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.treasury_endpoint.UpdateTreasuryLimitationReplay} returns this
*/
proto.treasury_endpoint.UpdateTreasuryLimitationReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.UpdateTreasuryLimitationReplay} returns this
 */
proto.treasury_endpoint.UpdateTreasuryLimitationReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.UpdateTreasuryLimitationReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_endpoint.UpdateTreasuryReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_endpoint.UpdateTreasuryReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_endpoint.UpdateTreasuryReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.UpdateTreasuryReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_endpoint.UpdateTreasuryReplay}
 */
proto.treasury_endpoint.UpdateTreasuryReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_endpoint.UpdateTreasuryReplay;
  return proto.treasury_endpoint.UpdateTreasuryReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_endpoint.UpdateTreasuryReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_endpoint.UpdateTreasuryReplay}
 */
proto.treasury_endpoint.UpdateTreasuryReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_endpoint.UpdateTreasuryReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_endpoint.UpdateTreasuryReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_endpoint.UpdateTreasuryReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.UpdateTreasuryReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.treasury_endpoint.UpdateTreasuryReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.treasury_endpoint.UpdateTreasuryReplay} returns this
*/
proto.treasury_endpoint.UpdateTreasuryReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.UpdateTreasuryReplay} returns this
 */
proto.treasury_endpoint.UpdateTreasuryReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.UpdateTreasuryReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.treasury_endpoint.UpdateTreasuryReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.treasury_endpoint.UpdateTreasuryReplay} returns this
*/
proto.treasury_endpoint.UpdateTreasuryReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.UpdateTreasuryReplay} returns this
 */
proto.treasury_endpoint.UpdateTreasuryReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.UpdateTreasuryReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_endpoint.UpdateTreasuryTypeReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_endpoint.UpdateTreasuryTypeReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_endpoint.UpdateTreasuryTypeReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.UpdateTreasuryTypeReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_endpoint.UpdateTreasuryTypeReplay}
 */
proto.treasury_endpoint.UpdateTreasuryTypeReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_endpoint.UpdateTreasuryTypeReplay;
  return proto.treasury_endpoint.UpdateTreasuryTypeReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_endpoint.UpdateTreasuryTypeReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_endpoint.UpdateTreasuryTypeReplay}
 */
proto.treasury_endpoint.UpdateTreasuryTypeReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_endpoint.UpdateTreasuryTypeReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_endpoint.UpdateTreasuryTypeReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_endpoint.UpdateTreasuryTypeReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.UpdateTreasuryTypeReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.treasury_endpoint.UpdateTreasuryTypeReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.treasury_endpoint.UpdateTreasuryTypeReplay} returns this
*/
proto.treasury_endpoint.UpdateTreasuryTypeReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.UpdateTreasuryTypeReplay} returns this
 */
proto.treasury_endpoint.UpdateTreasuryTypeReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.UpdateTreasuryTypeReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.treasury_endpoint.UpdateTreasuryTypeReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.treasury_endpoint.UpdateTreasuryTypeReplay} returns this
*/
proto.treasury_endpoint.UpdateTreasuryTypeReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.UpdateTreasuryTypeReplay} returns this
 */
proto.treasury_endpoint.UpdateTreasuryTypeReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.UpdateTreasuryTypeReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_endpoint.UpdateTreasuryStatusReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_endpoint.UpdateTreasuryStatusReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_endpoint.UpdateTreasuryStatusReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.UpdateTreasuryStatusReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_endpoint.UpdateTreasuryStatusReplay}
 */
proto.treasury_endpoint.UpdateTreasuryStatusReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_endpoint.UpdateTreasuryStatusReplay;
  return proto.treasury_endpoint.UpdateTreasuryStatusReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_endpoint.UpdateTreasuryStatusReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_endpoint.UpdateTreasuryStatusReplay}
 */
proto.treasury_endpoint.UpdateTreasuryStatusReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_endpoint.UpdateTreasuryStatusReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_endpoint.UpdateTreasuryStatusReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_endpoint.UpdateTreasuryStatusReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.UpdateTreasuryStatusReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.treasury_endpoint.UpdateTreasuryStatusReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.treasury_endpoint.UpdateTreasuryStatusReplay} returns this
*/
proto.treasury_endpoint.UpdateTreasuryStatusReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.UpdateTreasuryStatusReplay} returns this
 */
proto.treasury_endpoint.UpdateTreasuryStatusReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.UpdateTreasuryStatusReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.treasury_endpoint.UpdateTreasuryStatusReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.treasury_endpoint.UpdateTreasuryStatusReplay} returns this
*/
proto.treasury_endpoint.UpdateTreasuryStatusReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.UpdateTreasuryStatusReplay} returns this
 */
proto.treasury_endpoint.UpdateTreasuryStatusReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.UpdateTreasuryStatusReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_endpoint.DepositResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_endpoint.DepositResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_endpoint.DepositResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.DepositResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    currency: (f = msg.getCurrency()) && Protos_common_pb.CurrencyResponse.toObject(includeInstance, f),
    isactive: (f = msg.getIsactive()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_endpoint.DepositResponse}
 */
proto.treasury_endpoint.DepositResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_endpoint.DepositResponse;
  return proto.treasury_endpoint.DepositResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_endpoint.DepositResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_endpoint.DepositResponse}
 */
proto.treasury_endpoint.DepositResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Protos_common_pb.CurrencyResponse;
      reader.readMessage(value,Protos_common_pb.CurrencyResponse.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsactive(value);
      break;
    case 3:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_endpoint.DepositResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_endpoint.DepositResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_endpoint.DepositResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.DepositResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Protos_common_pb.CurrencyResponse.serializeBinaryToWriter
    );
  }
  f = message.getIsactive();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.CurrencyResponse currency = 1;
 * @return {?proto.common.CurrencyResponse}
 */
proto.treasury_endpoint.DepositResponse.prototype.getCurrency = function() {
  return /** @type{?proto.common.CurrencyResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.CurrencyResponse, 1));
};


/**
 * @param {?proto.common.CurrencyResponse|undefined} value
 * @return {!proto.treasury_endpoint.DepositResponse} returns this
*/
proto.treasury_endpoint.DepositResponse.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.DepositResponse} returns this
 */
proto.treasury_endpoint.DepositResponse.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.DepositResponse.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.BoolValue isActive = 2;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.treasury_endpoint.DepositResponse.prototype.getIsactive = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 2));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.treasury_endpoint.DepositResponse} returns this
*/
proto.treasury_endpoint.DepositResponse.prototype.setIsactive = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.DepositResponse} returns this
 */
proto.treasury_endpoint.DepositResponse.prototype.clearIsactive = function() {
  return this.setIsactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.DepositResponse.prototype.hasIsactive = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.ProfileResponse createdBy = 3;
 * @return {?proto.common.ProfileResponse}
 */
proto.treasury_endpoint.DepositResponse.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 3));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.treasury_endpoint.DepositResponse} returns this
*/
proto.treasury_endpoint.DepositResponse.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.DepositResponse} returns this
 */
proto.treasury_endpoint.DepositResponse.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.DepositResponse.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.treasury_endpoint.DepositResponse.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.treasury_endpoint.DepositResponse} returns this
*/
proto.treasury_endpoint.DepositResponse.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.DepositResponse} returns this
 */
proto.treasury_endpoint.DepositResponse.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.DepositResponse.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_endpoint.Nature.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_endpoint.Nature.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_endpoint.Nature} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.Nature.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_endpoint.Nature}
 */
proto.treasury_endpoint.Nature.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_endpoint.Nature;
  return proto.treasury_endpoint.Nature.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_endpoint.Nature} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_endpoint.Nature}
 */
proto.treasury_endpoint.Nature.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_endpoint.Nature.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_endpoint.Nature.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_endpoint.Nature} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.Nature.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value id = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.treasury_endpoint.Nature.prototype.getId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.treasury_endpoint.Nature} returns this
*/
proto.treasury_endpoint.Nature.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.Nature} returns this
 */
proto.treasury_endpoint.Nature.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.Nature.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.treasury_endpoint.GetTreasuryReplay.repeatedFields_ = [4,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_endpoint.GetTreasuryReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_endpoint.GetTreasuryReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.GetTreasuryReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    internalid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    depositsList: jspb.Message.toObjectList(msg.getDepositsList(),
    proto.treasury_endpoint.DepositResponse.toObject, includeInstance),
    nature: (f = msg.getNature()) && proto.treasury_endpoint.Nature.toObject(includeInstance, f),
    treasurytype: (f = msg.getTreasurytype()) && Protos_common_pb.TreasuryTypeResponse.toObject(includeInstance, f),
    code: (f = msg.getCode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance),
    limitation: (f = msg.getLimitation()) && Protos_common_pb.LimitationResponse.toObject(includeInstance, f),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_endpoint.GetTreasuryReplay}
 */
proto.treasury_endpoint.GetTreasuryReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_endpoint.GetTreasuryReplay;
  return proto.treasury_endpoint.GetTreasuryReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_endpoint.GetTreasuryReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_endpoint.GetTreasuryReplay}
 */
proto.treasury_endpoint.GetTreasuryReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalid(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 4:
      var value = new proto.treasury_endpoint.DepositResponse;
      reader.readMessage(value,proto.treasury_endpoint.DepositResponse.deserializeBinaryFromReader);
      msg.addDeposits(value);
      break;
    case 5:
      var value = new proto.treasury_endpoint.Nature;
      reader.readMessage(value,proto.treasury_endpoint.Nature.deserializeBinaryFromReader);
      msg.setNature(value);
      break;
    case 6:
      var value = new Protos_common_pb.TreasuryTypeResponse;
      reader.readMessage(value,Protos_common_pb.TreasuryTypeResponse.deserializeBinaryFromReader);
      msg.setTreasurytype(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCode(value);
      break;
    case 8:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    case 9:
      var value = new Protos_common_pb.LimitationResponse;
      reader.readMessage(value,Protos_common_pb.LimitationResponse.deserializeBinaryFromReader);
      msg.setLimitation(value);
      break;
    case 10:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_endpoint.GetTreasuryReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_endpoint.GetTreasuryReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.GetTreasuryReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getInternalid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDepositsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.treasury_endpoint.DepositResponse.serializeBinaryToWriter
    );
  }
  f = message.getNature();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.treasury_endpoint.Nature.serializeBinaryToWriter
    );
  }
  f = message.getTreasurytype();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Protos_common_pb.TreasuryTypeResponse.serializeBinaryToWriter
    );
  }
  f = message.getCode();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
  f = message.getLimitation();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      Protos_common_pb.LimitationResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.treasury_endpoint.GetTreasuryReplay} returns this
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string internalId = 2;
 * @return {string}
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.getInternalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_endpoint.GetTreasuryReplay} returns this
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.setInternalid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.StringValue name = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_endpoint.GetTreasuryReplay} returns this
*/
proto.treasury_endpoint.GetTreasuryReplay.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.GetTreasuryReplay} returns this
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated DepositResponse deposits = 4;
 * @return {!Array<!proto.treasury_endpoint.DepositResponse>}
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.getDepositsList = function() {
  return /** @type{!Array<!proto.treasury_endpoint.DepositResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.treasury_endpoint.DepositResponse, 4));
};


/**
 * @param {!Array<!proto.treasury_endpoint.DepositResponse>} value
 * @return {!proto.treasury_endpoint.GetTreasuryReplay} returns this
*/
proto.treasury_endpoint.GetTreasuryReplay.prototype.setDepositsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.treasury_endpoint.DepositResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.treasury_endpoint.DepositResponse}
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.addDeposits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.treasury_endpoint.DepositResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.treasury_endpoint.GetTreasuryReplay} returns this
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.clearDepositsList = function() {
  return this.setDepositsList([]);
};


/**
 * optional Nature nature = 5;
 * @return {?proto.treasury_endpoint.Nature}
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.getNature = function() {
  return /** @type{?proto.treasury_endpoint.Nature} */ (
    jspb.Message.getWrapperField(this, proto.treasury_endpoint.Nature, 5));
};


/**
 * @param {?proto.treasury_endpoint.Nature|undefined} value
 * @return {!proto.treasury_endpoint.GetTreasuryReplay} returns this
*/
proto.treasury_endpoint.GetTreasuryReplay.prototype.setNature = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.GetTreasuryReplay} returns this
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.clearNature = function() {
  return this.setNature(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.hasNature = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional common.TreasuryTypeResponse treasuryType = 6;
 * @return {?proto.common.TreasuryTypeResponse}
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.getTreasurytype = function() {
  return /** @type{?proto.common.TreasuryTypeResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.TreasuryTypeResponse, 6));
};


/**
 * @param {?proto.common.TreasuryTypeResponse|undefined} value
 * @return {!proto.treasury_endpoint.GetTreasuryReplay} returns this
*/
proto.treasury_endpoint.GetTreasuryReplay.prototype.setTreasurytype = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.GetTreasuryReplay} returns this
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.clearTreasurytype = function() {
  return this.setTreasurytype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.hasTreasurytype = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue code = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.getCode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_endpoint.GetTreasuryReplay} returns this
*/
proto.treasury_endpoint.GetTreasuryReplay.prototype.setCode = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.GetTreasuryReplay} returns this
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.clearCode = function() {
  return this.setCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.hasCode = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated common.ExtraField extraFields = 8;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 8));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.treasury_endpoint.GetTreasuryReplay} returns this
*/
proto.treasury_endpoint.GetTreasuryReplay.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.treasury_endpoint.GetTreasuryReplay} returns this
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};


/**
 * optional common.LimitationResponse limitation = 9;
 * @return {?proto.common.LimitationResponse}
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.getLimitation = function() {
  return /** @type{?proto.common.LimitationResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.LimitationResponse, 9));
};


/**
 * @param {?proto.common.LimitationResponse|undefined} value
 * @return {!proto.treasury_endpoint.GetTreasuryReplay} returns this
*/
proto.treasury_endpoint.GetTreasuryReplay.prototype.setLimitation = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.GetTreasuryReplay} returns this
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.clearLimitation = function() {
  return this.setLimitation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.hasLimitation = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional common.ProfileResponse createdBy = 10;
 * @return {?proto.common.ProfileResponse}
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 10));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.treasury_endpoint.GetTreasuryReplay} returns this
*/
proto.treasury_endpoint.GetTreasuryReplay.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.GetTreasuryReplay} returns this
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.treasury_endpoint.GetTreasuryReplay} returns this
*/
proto.treasury_endpoint.GetTreasuryReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.GetTreasuryReplay} returns this
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.Int32Value status = 12;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.getStatus = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 12));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.treasury_endpoint.GetTreasuryReplay} returns this
*/
proto.treasury_endpoint.GetTreasuryReplay.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.GetTreasuryReplay} returns this
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.GetTreasuryReplay.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_endpoint.Treasury.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_endpoint.Treasury.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_endpoint.Treasury} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.Treasury.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    internalid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    nature: (f = msg.getNature()) && proto.treasury_endpoint.Nature.toObject(includeInstance, f),
    treasurytype: (f = msg.getTreasurytype()) && Protos_common_pb.TreasuryTypeResponse.toObject(includeInstance, f),
    code: (f = msg.getCode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_endpoint.Treasury}
 */
proto.treasury_endpoint.Treasury.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_endpoint.Treasury;
  return proto.treasury_endpoint.Treasury.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_endpoint.Treasury} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_endpoint.Treasury}
 */
proto.treasury_endpoint.Treasury.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalid(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 4:
      var value = new proto.treasury_endpoint.Nature;
      reader.readMessage(value,proto.treasury_endpoint.Nature.deserializeBinaryFromReader);
      msg.setNature(value);
      break;
    case 5:
      var value = new Protos_common_pb.TreasuryTypeResponse;
      reader.readMessage(value,Protos_common_pb.TreasuryTypeResponse.deserializeBinaryFromReader);
      msg.setTreasurytype(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCode(value);
      break;
    case 7:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_endpoint.Treasury.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_endpoint.Treasury.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_endpoint.Treasury} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.Treasury.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getInternalid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNature();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.treasury_endpoint.Nature.serializeBinaryToWriter
    );
  }
  f = message.getTreasurytype();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Protos_common_pb.TreasuryTypeResponse.serializeBinaryToWriter
    );
  }
  f = message.getCode();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.treasury_endpoint.Treasury.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.treasury_endpoint.Treasury} returns this
 */
proto.treasury_endpoint.Treasury.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string internalId = 2;
 * @return {string}
 */
proto.treasury_endpoint.Treasury.prototype.getInternalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_endpoint.Treasury} returns this
 */
proto.treasury_endpoint.Treasury.prototype.setInternalid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.StringValue name = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_endpoint.Treasury.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_endpoint.Treasury} returns this
*/
proto.treasury_endpoint.Treasury.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.Treasury} returns this
 */
proto.treasury_endpoint.Treasury.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.Treasury.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Nature nature = 4;
 * @return {?proto.treasury_endpoint.Nature}
 */
proto.treasury_endpoint.Treasury.prototype.getNature = function() {
  return /** @type{?proto.treasury_endpoint.Nature} */ (
    jspb.Message.getWrapperField(this, proto.treasury_endpoint.Nature, 4));
};


/**
 * @param {?proto.treasury_endpoint.Nature|undefined} value
 * @return {!proto.treasury_endpoint.Treasury} returns this
*/
proto.treasury_endpoint.Treasury.prototype.setNature = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.Treasury} returns this
 */
proto.treasury_endpoint.Treasury.prototype.clearNature = function() {
  return this.setNature(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.Treasury.prototype.hasNature = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional common.TreasuryTypeResponse treasuryType = 5;
 * @return {?proto.common.TreasuryTypeResponse}
 */
proto.treasury_endpoint.Treasury.prototype.getTreasurytype = function() {
  return /** @type{?proto.common.TreasuryTypeResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.TreasuryTypeResponse, 5));
};


/**
 * @param {?proto.common.TreasuryTypeResponse|undefined} value
 * @return {!proto.treasury_endpoint.Treasury} returns this
*/
proto.treasury_endpoint.Treasury.prototype.setTreasurytype = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.Treasury} returns this
 */
proto.treasury_endpoint.Treasury.prototype.clearTreasurytype = function() {
  return this.setTreasurytype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.Treasury.prototype.hasTreasurytype = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue code = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_endpoint.Treasury.prototype.getCode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_endpoint.Treasury} returns this
*/
proto.treasury_endpoint.Treasury.prototype.setCode = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.Treasury} returns this
 */
proto.treasury_endpoint.Treasury.prototype.clearCode = function() {
  return this.setCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.Treasury.prototype.hasCode = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional common.ProfileResponse createdBy = 7;
 * @return {?proto.common.ProfileResponse}
 */
proto.treasury_endpoint.Treasury.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 7));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.treasury_endpoint.Treasury} returns this
*/
proto.treasury_endpoint.Treasury.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.Treasury} returns this
 */
proto.treasury_endpoint.Treasury.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.Treasury.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.treasury_endpoint.Treasury.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.treasury_endpoint.Treasury} returns this
*/
proto.treasury_endpoint.Treasury.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.Treasury} returns this
 */
proto.treasury_endpoint.Treasury.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.Treasury.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Int32Value status = 9;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.treasury_endpoint.Treasury.prototype.getStatus = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 9));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.treasury_endpoint.Treasury} returns this
*/
proto.treasury_endpoint.Treasury.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.Treasury} returns this
 */
proto.treasury_endpoint.Treasury.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.Treasury.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.treasury_endpoint.GetTreasuriesReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_endpoint.GetTreasuriesReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_endpoint.GetTreasuriesReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_endpoint.GetTreasuriesReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.GetTreasuriesReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    treasuriesList: jspb.Message.toObjectList(msg.getTreasuriesList(),
    proto.treasury_endpoint.Treasury.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_endpoint.GetTreasuriesReplay}
 */
proto.treasury_endpoint.GetTreasuriesReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_endpoint.GetTreasuriesReplay;
  return proto.treasury_endpoint.GetTreasuriesReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_endpoint.GetTreasuriesReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_endpoint.GetTreasuriesReplay}
 */
proto.treasury_endpoint.GetTreasuriesReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.treasury_endpoint.Treasury;
      reader.readMessage(value,proto.treasury_endpoint.Treasury.deserializeBinaryFromReader);
      msg.addTreasuries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_endpoint.GetTreasuriesReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_endpoint.GetTreasuriesReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_endpoint.GetTreasuriesReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.GetTreasuriesReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTreasuriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.treasury_endpoint.Treasury.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Treasury treasuries = 1;
 * @return {!Array<!proto.treasury_endpoint.Treasury>}
 */
proto.treasury_endpoint.GetTreasuriesReplay.prototype.getTreasuriesList = function() {
  return /** @type{!Array<!proto.treasury_endpoint.Treasury>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.treasury_endpoint.Treasury, 1));
};


/**
 * @param {!Array<!proto.treasury_endpoint.Treasury>} value
 * @return {!proto.treasury_endpoint.GetTreasuriesReplay} returns this
*/
proto.treasury_endpoint.GetTreasuriesReplay.prototype.setTreasuriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.treasury_endpoint.Treasury=} opt_value
 * @param {number=} opt_index
 * @return {!proto.treasury_endpoint.Treasury}
 */
proto.treasury_endpoint.GetTreasuriesReplay.prototype.addTreasuries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.treasury_endpoint.Treasury, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.treasury_endpoint.GetTreasuriesReplay} returns this
 */
proto.treasury_endpoint.GetTreasuriesReplay.prototype.clearTreasuriesList = function() {
  return this.setTreasuriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_endpoint.AppendTreasuryCurrencyReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_endpoint.AppendTreasuryCurrencyReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_endpoint.AppendTreasuryCurrencyReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.AppendTreasuryCurrencyReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    addeddate: (f = msg.getAddeddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_endpoint.AppendTreasuryCurrencyReplay}
 */
proto.treasury_endpoint.AppendTreasuryCurrencyReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_endpoint.AppendTreasuryCurrencyReplay;
  return proto.treasury_endpoint.AppendTreasuryCurrencyReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_endpoint.AppendTreasuryCurrencyReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_endpoint.AppendTreasuryCurrencyReplay}
 */
proto.treasury_endpoint.AppendTreasuryCurrencyReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAddeddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_endpoint.AppendTreasuryCurrencyReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_endpoint.AppendTreasuryCurrencyReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_endpoint.AppendTreasuryCurrencyReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.AppendTreasuryCurrencyReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddeddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp addedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.treasury_endpoint.AppendTreasuryCurrencyReplay.prototype.getAddeddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.treasury_endpoint.AppendTreasuryCurrencyReplay} returns this
*/
proto.treasury_endpoint.AppendTreasuryCurrencyReplay.prototype.setAddeddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.AppendTreasuryCurrencyReplay} returns this
 */
proto.treasury_endpoint.AppendTreasuryCurrencyReplay.prototype.clearAddeddate = function() {
  return this.setAddeddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.AppendTreasuryCurrencyReplay.prototype.hasAddeddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.treasury_endpoint.AppendTreasuryCurrencyReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.treasury_endpoint.AppendTreasuryCurrencyReplay} returns this
*/
proto.treasury_endpoint.AppendTreasuryCurrencyReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.AppendTreasuryCurrencyReplay} returns this
 */
proto.treasury_endpoint.AppendTreasuryCurrencyReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.AppendTreasuryCurrencyReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay}
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay;
  return proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay}
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay} returns this
*/
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay} returns this
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay} returns this
*/
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay} returns this
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.treasury_endpoint.AddTreasuryResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.treasury_endpoint.AddTreasuryResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.treasury_endpoint.AddTreasuryResponse.ResultCase}
 */
proto.treasury_endpoint.AddTreasuryResponse.prototype.getResultCase = function() {
  return /** @type {proto.treasury_endpoint.AddTreasuryResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.treasury_endpoint.AddTreasuryResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_endpoint.AddTreasuryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_endpoint.AddTreasuryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_endpoint.AddTreasuryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.AddTreasuryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.treasury_endpoint.AddTreasuryReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_endpoint.AddTreasuryResponse}
 */
proto.treasury_endpoint.AddTreasuryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_endpoint.AddTreasuryResponse;
  return proto.treasury_endpoint.AddTreasuryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_endpoint.AddTreasuryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_endpoint.AddTreasuryResponse}
 */
proto.treasury_endpoint.AddTreasuryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.treasury_endpoint.AddTreasuryReplay;
      reader.readMessage(value,proto.treasury_endpoint.AddTreasuryReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_endpoint.AddTreasuryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_endpoint.AddTreasuryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_endpoint.AddTreasuryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.AddTreasuryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.treasury_endpoint.AddTreasuryReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional AddTreasuryReplay success = 1;
 * @return {?proto.treasury_endpoint.AddTreasuryReplay}
 */
proto.treasury_endpoint.AddTreasuryResponse.prototype.getSuccess = function() {
  return /** @type{?proto.treasury_endpoint.AddTreasuryReplay} */ (
    jspb.Message.getWrapperField(this, proto.treasury_endpoint.AddTreasuryReplay, 1));
};


/**
 * @param {?proto.treasury_endpoint.AddTreasuryReplay|undefined} value
 * @return {!proto.treasury_endpoint.AddTreasuryResponse} returns this
*/
proto.treasury_endpoint.AddTreasuryResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.treasury_endpoint.AddTreasuryResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.AddTreasuryResponse} returns this
 */
proto.treasury_endpoint.AddTreasuryResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.AddTreasuryResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.treasury_endpoint.UpdateTreasuryResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.treasury_endpoint.UpdateTreasuryResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.treasury_endpoint.UpdateTreasuryResponse.ResultCase}
 */
proto.treasury_endpoint.UpdateTreasuryResponse.prototype.getResultCase = function() {
  return /** @type {proto.treasury_endpoint.UpdateTreasuryResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.treasury_endpoint.UpdateTreasuryResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_endpoint.UpdateTreasuryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_endpoint.UpdateTreasuryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_endpoint.UpdateTreasuryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.UpdateTreasuryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.treasury_endpoint.UpdateTreasuryReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_endpoint.UpdateTreasuryResponse}
 */
proto.treasury_endpoint.UpdateTreasuryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_endpoint.UpdateTreasuryResponse;
  return proto.treasury_endpoint.UpdateTreasuryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_endpoint.UpdateTreasuryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_endpoint.UpdateTreasuryResponse}
 */
proto.treasury_endpoint.UpdateTreasuryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.treasury_endpoint.UpdateTreasuryReplay;
      reader.readMessage(value,proto.treasury_endpoint.UpdateTreasuryReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_endpoint.UpdateTreasuryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_endpoint.UpdateTreasuryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_endpoint.UpdateTreasuryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.UpdateTreasuryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.treasury_endpoint.UpdateTreasuryReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateTreasuryReplay success = 1;
 * @return {?proto.treasury_endpoint.UpdateTreasuryReplay}
 */
proto.treasury_endpoint.UpdateTreasuryResponse.prototype.getSuccess = function() {
  return /** @type{?proto.treasury_endpoint.UpdateTreasuryReplay} */ (
    jspb.Message.getWrapperField(this, proto.treasury_endpoint.UpdateTreasuryReplay, 1));
};


/**
 * @param {?proto.treasury_endpoint.UpdateTreasuryReplay|undefined} value
 * @return {!proto.treasury_endpoint.UpdateTreasuryResponse} returns this
*/
proto.treasury_endpoint.UpdateTreasuryResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.treasury_endpoint.UpdateTreasuryResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.UpdateTreasuryResponse} returns this
 */
proto.treasury_endpoint.UpdateTreasuryResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.UpdateTreasuryResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.treasury_endpoint.UpdateTreasuryTypeResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.treasury_endpoint.UpdateTreasuryTypeResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.treasury_endpoint.UpdateTreasuryTypeResponse.ResultCase}
 */
proto.treasury_endpoint.UpdateTreasuryTypeResponse.prototype.getResultCase = function() {
  return /** @type {proto.treasury_endpoint.UpdateTreasuryTypeResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.treasury_endpoint.UpdateTreasuryTypeResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_endpoint.UpdateTreasuryTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_endpoint.UpdateTreasuryTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_endpoint.UpdateTreasuryTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.UpdateTreasuryTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.treasury_endpoint.UpdateTreasuryTypeReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_endpoint.UpdateTreasuryTypeResponse}
 */
proto.treasury_endpoint.UpdateTreasuryTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_endpoint.UpdateTreasuryTypeResponse;
  return proto.treasury_endpoint.UpdateTreasuryTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_endpoint.UpdateTreasuryTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_endpoint.UpdateTreasuryTypeResponse}
 */
proto.treasury_endpoint.UpdateTreasuryTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.treasury_endpoint.UpdateTreasuryTypeReplay;
      reader.readMessage(value,proto.treasury_endpoint.UpdateTreasuryTypeReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_endpoint.UpdateTreasuryTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_endpoint.UpdateTreasuryTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_endpoint.UpdateTreasuryTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.UpdateTreasuryTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.treasury_endpoint.UpdateTreasuryTypeReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateTreasuryTypeReplay success = 1;
 * @return {?proto.treasury_endpoint.UpdateTreasuryTypeReplay}
 */
proto.treasury_endpoint.UpdateTreasuryTypeResponse.prototype.getSuccess = function() {
  return /** @type{?proto.treasury_endpoint.UpdateTreasuryTypeReplay} */ (
    jspb.Message.getWrapperField(this, proto.treasury_endpoint.UpdateTreasuryTypeReplay, 1));
};


/**
 * @param {?proto.treasury_endpoint.UpdateTreasuryTypeReplay|undefined} value
 * @return {!proto.treasury_endpoint.UpdateTreasuryTypeResponse} returns this
*/
proto.treasury_endpoint.UpdateTreasuryTypeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.treasury_endpoint.UpdateTreasuryTypeResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.UpdateTreasuryTypeResponse} returns this
 */
proto.treasury_endpoint.UpdateTreasuryTypeResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.UpdateTreasuryTypeResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.treasury_endpoint.UpdateTreasuryLimitationResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.treasury_endpoint.UpdateTreasuryLimitationResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.treasury_endpoint.UpdateTreasuryLimitationResponse.ResultCase}
 */
proto.treasury_endpoint.UpdateTreasuryLimitationResponse.prototype.getResultCase = function() {
  return /** @type {proto.treasury_endpoint.UpdateTreasuryLimitationResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.treasury_endpoint.UpdateTreasuryLimitationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_endpoint.UpdateTreasuryLimitationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_endpoint.UpdateTreasuryLimitationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_endpoint.UpdateTreasuryLimitationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.UpdateTreasuryLimitationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.treasury_endpoint.UpdateTreasuryLimitationReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_endpoint.UpdateTreasuryLimitationResponse}
 */
proto.treasury_endpoint.UpdateTreasuryLimitationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_endpoint.UpdateTreasuryLimitationResponse;
  return proto.treasury_endpoint.UpdateTreasuryLimitationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_endpoint.UpdateTreasuryLimitationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_endpoint.UpdateTreasuryLimitationResponse}
 */
proto.treasury_endpoint.UpdateTreasuryLimitationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.treasury_endpoint.UpdateTreasuryLimitationReplay;
      reader.readMessage(value,proto.treasury_endpoint.UpdateTreasuryLimitationReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_endpoint.UpdateTreasuryLimitationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_endpoint.UpdateTreasuryLimitationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_endpoint.UpdateTreasuryLimitationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.UpdateTreasuryLimitationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.treasury_endpoint.UpdateTreasuryLimitationReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateTreasuryLimitationReplay success = 1;
 * @return {?proto.treasury_endpoint.UpdateTreasuryLimitationReplay}
 */
proto.treasury_endpoint.UpdateTreasuryLimitationResponse.prototype.getSuccess = function() {
  return /** @type{?proto.treasury_endpoint.UpdateTreasuryLimitationReplay} */ (
    jspb.Message.getWrapperField(this, proto.treasury_endpoint.UpdateTreasuryLimitationReplay, 1));
};


/**
 * @param {?proto.treasury_endpoint.UpdateTreasuryLimitationReplay|undefined} value
 * @return {!proto.treasury_endpoint.UpdateTreasuryLimitationResponse} returns this
*/
proto.treasury_endpoint.UpdateTreasuryLimitationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.treasury_endpoint.UpdateTreasuryLimitationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.UpdateTreasuryLimitationResponse} returns this
 */
proto.treasury_endpoint.UpdateTreasuryLimitationResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.UpdateTreasuryLimitationResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.treasury_endpoint.UpdateTreasuryStatusResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.treasury_endpoint.UpdateTreasuryStatusResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.treasury_endpoint.UpdateTreasuryStatusResponse.ResultCase}
 */
proto.treasury_endpoint.UpdateTreasuryStatusResponse.prototype.getResultCase = function() {
  return /** @type {proto.treasury_endpoint.UpdateTreasuryStatusResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.treasury_endpoint.UpdateTreasuryStatusResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_endpoint.UpdateTreasuryStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_endpoint.UpdateTreasuryStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_endpoint.UpdateTreasuryStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.UpdateTreasuryStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.treasury_endpoint.UpdateTreasuryStatusReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_endpoint.UpdateTreasuryStatusResponse}
 */
proto.treasury_endpoint.UpdateTreasuryStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_endpoint.UpdateTreasuryStatusResponse;
  return proto.treasury_endpoint.UpdateTreasuryStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_endpoint.UpdateTreasuryStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_endpoint.UpdateTreasuryStatusResponse}
 */
proto.treasury_endpoint.UpdateTreasuryStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.treasury_endpoint.UpdateTreasuryStatusReplay;
      reader.readMessage(value,proto.treasury_endpoint.UpdateTreasuryStatusReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_endpoint.UpdateTreasuryStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_endpoint.UpdateTreasuryStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_endpoint.UpdateTreasuryStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.UpdateTreasuryStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.treasury_endpoint.UpdateTreasuryStatusReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateTreasuryStatusReplay success = 1;
 * @return {?proto.treasury_endpoint.UpdateTreasuryStatusReplay}
 */
proto.treasury_endpoint.UpdateTreasuryStatusResponse.prototype.getSuccess = function() {
  return /** @type{?proto.treasury_endpoint.UpdateTreasuryStatusReplay} */ (
    jspb.Message.getWrapperField(this, proto.treasury_endpoint.UpdateTreasuryStatusReplay, 1));
};


/**
 * @param {?proto.treasury_endpoint.UpdateTreasuryStatusReplay|undefined} value
 * @return {!proto.treasury_endpoint.UpdateTreasuryStatusResponse} returns this
*/
proto.treasury_endpoint.UpdateTreasuryStatusResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.treasury_endpoint.UpdateTreasuryStatusResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.UpdateTreasuryStatusResponse} returns this
 */
proto.treasury_endpoint.UpdateTreasuryStatusResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.UpdateTreasuryStatusResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.treasury_endpoint.CloseTreasuryResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.treasury_endpoint.CloseTreasuryResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.treasury_endpoint.CloseTreasuryResponse.ResultCase}
 */
proto.treasury_endpoint.CloseTreasuryResponse.prototype.getResultCase = function() {
  return /** @type {proto.treasury_endpoint.CloseTreasuryResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.treasury_endpoint.CloseTreasuryResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_endpoint.CloseTreasuryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_endpoint.CloseTreasuryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_endpoint.CloseTreasuryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.CloseTreasuryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.treasury_endpoint.CloseTreasuryReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_endpoint.CloseTreasuryResponse}
 */
proto.treasury_endpoint.CloseTreasuryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_endpoint.CloseTreasuryResponse;
  return proto.treasury_endpoint.CloseTreasuryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_endpoint.CloseTreasuryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_endpoint.CloseTreasuryResponse}
 */
proto.treasury_endpoint.CloseTreasuryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.treasury_endpoint.CloseTreasuryReplay;
      reader.readMessage(value,proto.treasury_endpoint.CloseTreasuryReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_endpoint.CloseTreasuryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_endpoint.CloseTreasuryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_endpoint.CloseTreasuryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.CloseTreasuryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.treasury_endpoint.CloseTreasuryReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional CloseTreasuryReplay success = 1;
 * @return {?proto.treasury_endpoint.CloseTreasuryReplay}
 */
proto.treasury_endpoint.CloseTreasuryResponse.prototype.getSuccess = function() {
  return /** @type{?proto.treasury_endpoint.CloseTreasuryReplay} */ (
    jspb.Message.getWrapperField(this, proto.treasury_endpoint.CloseTreasuryReplay, 1));
};


/**
 * @param {?proto.treasury_endpoint.CloseTreasuryReplay|undefined} value
 * @return {!proto.treasury_endpoint.CloseTreasuryResponse} returns this
*/
proto.treasury_endpoint.CloseTreasuryResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.treasury_endpoint.CloseTreasuryResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.CloseTreasuryResponse} returns this
 */
proto.treasury_endpoint.CloseTreasuryResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.CloseTreasuryResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.treasury_endpoint.GetTreasuryResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.treasury_endpoint.GetTreasuryResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.treasury_endpoint.GetTreasuryResponse.ResultCase}
 */
proto.treasury_endpoint.GetTreasuryResponse.prototype.getResultCase = function() {
  return /** @type {proto.treasury_endpoint.GetTreasuryResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.treasury_endpoint.GetTreasuryResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_endpoint.GetTreasuryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_endpoint.GetTreasuryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_endpoint.GetTreasuryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.GetTreasuryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.treasury_endpoint.GetTreasuryReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_endpoint.GetTreasuryResponse}
 */
proto.treasury_endpoint.GetTreasuryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_endpoint.GetTreasuryResponse;
  return proto.treasury_endpoint.GetTreasuryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_endpoint.GetTreasuryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_endpoint.GetTreasuryResponse}
 */
proto.treasury_endpoint.GetTreasuryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.treasury_endpoint.GetTreasuryReplay;
      reader.readMessage(value,proto.treasury_endpoint.GetTreasuryReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_endpoint.GetTreasuryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_endpoint.GetTreasuryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_endpoint.GetTreasuryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.GetTreasuryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.treasury_endpoint.GetTreasuryReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetTreasuryReplay success = 1;
 * @return {?proto.treasury_endpoint.GetTreasuryReplay}
 */
proto.treasury_endpoint.GetTreasuryResponse.prototype.getSuccess = function() {
  return /** @type{?proto.treasury_endpoint.GetTreasuryReplay} */ (
    jspb.Message.getWrapperField(this, proto.treasury_endpoint.GetTreasuryReplay, 1));
};


/**
 * @param {?proto.treasury_endpoint.GetTreasuryReplay|undefined} value
 * @return {!proto.treasury_endpoint.GetTreasuryResponse} returns this
*/
proto.treasury_endpoint.GetTreasuryResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.treasury_endpoint.GetTreasuryResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.GetTreasuryResponse} returns this
 */
proto.treasury_endpoint.GetTreasuryResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.GetTreasuryResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.treasury_endpoint.GetTreasuriesResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.treasury_endpoint.GetTreasuriesResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.treasury_endpoint.GetTreasuriesResponse.ResultCase}
 */
proto.treasury_endpoint.GetTreasuriesResponse.prototype.getResultCase = function() {
  return /** @type {proto.treasury_endpoint.GetTreasuriesResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.treasury_endpoint.GetTreasuriesResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_endpoint.GetTreasuriesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_endpoint.GetTreasuriesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_endpoint.GetTreasuriesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.GetTreasuriesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.treasury_endpoint.GetTreasuriesReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_endpoint.GetTreasuriesResponse}
 */
proto.treasury_endpoint.GetTreasuriesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_endpoint.GetTreasuriesResponse;
  return proto.treasury_endpoint.GetTreasuriesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_endpoint.GetTreasuriesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_endpoint.GetTreasuriesResponse}
 */
proto.treasury_endpoint.GetTreasuriesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.treasury_endpoint.GetTreasuriesReplay;
      reader.readMessage(value,proto.treasury_endpoint.GetTreasuriesReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_endpoint.GetTreasuriesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_endpoint.GetTreasuriesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_endpoint.GetTreasuriesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.GetTreasuriesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.treasury_endpoint.GetTreasuriesReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetTreasuriesReplay success = 1;
 * @return {?proto.treasury_endpoint.GetTreasuriesReplay}
 */
proto.treasury_endpoint.GetTreasuriesResponse.prototype.getSuccess = function() {
  return /** @type{?proto.treasury_endpoint.GetTreasuriesReplay} */ (
    jspb.Message.getWrapperField(this, proto.treasury_endpoint.GetTreasuriesReplay, 1));
};


/**
 * @param {?proto.treasury_endpoint.GetTreasuriesReplay|undefined} value
 * @return {!proto.treasury_endpoint.GetTreasuriesResponse} returns this
*/
proto.treasury_endpoint.GetTreasuriesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.treasury_endpoint.GetTreasuriesResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.GetTreasuriesResponse} returns this
 */
proto.treasury_endpoint.GetTreasuriesResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.GetTreasuriesResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.treasury_endpoint.AppendTreasuryCurrencyResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.treasury_endpoint.AppendTreasuryCurrencyResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.treasury_endpoint.AppendTreasuryCurrencyResponse.ResultCase}
 */
proto.treasury_endpoint.AppendTreasuryCurrencyResponse.prototype.getResultCase = function() {
  return /** @type {proto.treasury_endpoint.AppendTreasuryCurrencyResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.treasury_endpoint.AppendTreasuryCurrencyResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_endpoint.AppendTreasuryCurrencyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_endpoint.AppendTreasuryCurrencyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_endpoint.AppendTreasuryCurrencyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.AppendTreasuryCurrencyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.treasury_endpoint.AppendTreasuryCurrencyReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_endpoint.AppendTreasuryCurrencyResponse}
 */
proto.treasury_endpoint.AppendTreasuryCurrencyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_endpoint.AppendTreasuryCurrencyResponse;
  return proto.treasury_endpoint.AppendTreasuryCurrencyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_endpoint.AppendTreasuryCurrencyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_endpoint.AppendTreasuryCurrencyResponse}
 */
proto.treasury_endpoint.AppendTreasuryCurrencyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.treasury_endpoint.AppendTreasuryCurrencyReplay;
      reader.readMessage(value,proto.treasury_endpoint.AppendTreasuryCurrencyReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_endpoint.AppendTreasuryCurrencyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_endpoint.AppendTreasuryCurrencyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_endpoint.AppendTreasuryCurrencyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.AppendTreasuryCurrencyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.treasury_endpoint.AppendTreasuryCurrencyReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional AppendTreasuryCurrencyReplay success = 1;
 * @return {?proto.treasury_endpoint.AppendTreasuryCurrencyReplay}
 */
proto.treasury_endpoint.AppendTreasuryCurrencyResponse.prototype.getSuccess = function() {
  return /** @type{?proto.treasury_endpoint.AppendTreasuryCurrencyReplay} */ (
    jspb.Message.getWrapperField(this, proto.treasury_endpoint.AppendTreasuryCurrencyReplay, 1));
};


/**
 * @param {?proto.treasury_endpoint.AppendTreasuryCurrencyReplay|undefined} value
 * @return {!proto.treasury_endpoint.AppendTreasuryCurrencyResponse} returns this
*/
proto.treasury_endpoint.AppendTreasuryCurrencyResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.treasury_endpoint.AppendTreasuryCurrencyResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.AppendTreasuryCurrencyResponse} returns this
 */
proto.treasury_endpoint.AppendTreasuryCurrencyResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.AppendTreasuryCurrencyResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse.ResultCase}
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse.prototype.getResultCase = function() {
  return /** @type {proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse}
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse;
  return proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse}
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay;
      reader.readMessage(value,proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateTreasuryCurrencyActivityReplay success = 1;
 * @return {?proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay}
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse.prototype.getSuccess = function() {
  return /** @type{?proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay} */ (
    jspb.Message.getWrapperField(this, proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay, 1));
};


/**
 * @param {?proto.treasury_endpoint.UpdateTreasuryCurrencyActivityReplay|undefined} value
 * @return {!proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse} returns this
*/
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse} returns this
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_endpoint.UpdateTreasuryCurrencyActivityResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.treasury_endpoint);
