import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError } from 'grpc-web';
import { RoleEndpointClient } from '../../../../../repository/UserManagement/Managers/RoleServiceClientPb';
import { AddRoleRequest, AddRoleResponse, UpdateRoleRequest, UpdateRoleResponse } from '../../../../../repository/UserManagement/Managers/role_pb';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, refreshSession, getSession, ACCOUNTANT_API_URL, APIResponse } from '../../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../../app/Helpers';
import { DynamicField } from '../../../../common/DynamicFields/DynamicFields';
import { FeeEndpointClient } from '../../../../../repository/Accountant/FeeServiceClientPb';
import { AddFeeCollectionAccountRequest, AddFeeCollectionAccountResponse, AddFeeRequest, AddFeeResponse, GetFeeRequest, GetFeeResponse, UpdateFeeRequest, UpdateFeeResponse } from '../../../../../repository/Accountant/fee_pb';
import { GetAccountsRequest, GetAccountsResponse } from '../../../../../repository/Accountant/account_pb';
import { AccountEndpointClient } from '../../../../../repository/Accountant/AccountServiceClientPb';
import { TableState } from '../../../../common/Table/TableSate';
import { GetCurrenciesRequest, GetCurrenciesResponse } from '../../../../../repository/Accountant/currency_pb';
import { CurrencyEndpointClient } from '../../../../../repository/Accountant/CurrencyServiceClientPb';
import { WithdrawRequest, WithdrawResponse, TransferRequest, TransferResponse, GetTransactionSummaryResponse, GetTransactionSummaryRequest } from '../../../../../repository/Accountant/account_operation_pb';
import { AccountOperationEndpointClient } from '../../../../../repository/Accountant/Account_operationServiceClientPb';
import { TreasuryEndpointClient } from '../../../../../repository/Accountant/TreasuryServiceClientPb';
import { GetTreasuriesRequest, GetTreasuriesResponse } from '../../../../../repository/Accountant/treasury_pb';
export interface WithdrawFormState {
    isLoading: boolean
    message?: ApiMessage
    stage1: any,
    treasuries: TableState,
    accounts: TableState,
    confirmation: any[]

}

const initialState: WithdrawFormState = {
    isLoading: false,
    message: undefined,
    stage1: undefined,
    treasuries: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    accounts: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    confirmation: []

}

const client = new AccountOperationEndpointClient(ACCOUNTANT_API_URL, null, null);
const accountClient = new AccountEndpointClient(ACCOUNTANT_API_URL, null, null);
const treasuryClient = new TreasuryEndpointClient(ACCOUNTANT_API_URL, null, null);


export const getAccounts = createAsyncThunk<GetAccountsResponse.AsObject, APIRequest<GetAccountsRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/operations/withdraw/accounts/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await accountClient.getAccounts(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getTreasuries = createAsyncThunk<GetTreasuriesResponse.AsObject, APIRequest<GetTreasuriesRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/operations/withdraw/treasuries/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await treasuryClient.getTreasuries(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const add = createAsyncThunk<WithdrawResponse.AsObject, APIRequest<WithdrawRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/operations/withdraw/add',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.withdraw(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)


export const getTransactionSummary = createAsyncThunk<APIResponse<GetTransactionSummaryResponse.AsObject>, APIRequest<GetTransactionSummaryRequest>, {

    rejectValue: APIResponse<ApiMessage>
}>(
    'forms/accountant/operations/withdraw/getTransactionSummary',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await client.getTransactionSummary(req.body, req.headers ?? {});
                return { metadata: { transactions: req.body.getTransactionsList()?.map(v => {
                    return {amount : v.getAmount()}
                }) }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue({ response: message, metadata: { transactions: req.body.getTransactionsList()?.map(v => {
                        return {amount : v.getAmount()}
                    }) } })
                } catch (err) {

                    return thunkApi.rejectWithValue({ response: { body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage, metadata: { transactions: { amount: req.body.getTransactionsList()?.map(v => {
                        return {amount : v.getAmount()}
                    }) } }})
                }
            }
        }
        return await callReq();
    }
)

export const withdrawFormSlice = createSlice({
    name: 'forms/accountant/operations/withdraw',
    initialState,
    reducers: {

        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.isLoading = false;
            state.message = undefined;
            state.stage1 = initialState.stage1;
            state.accounts = initialState.accounts;
            state.treasuries = initialState.treasuries;
            state.confirmation = initialState.confirmation;

        },
        resetAccountsSet: (state) => {
            state.accounts.items = [];
            state.accounts.hasMore = true;
        },

        resetTreasuriesSet: (state) => {
            state.treasuries.items = [];
            state.treasuries.hasMore = true;
        },

        setSelectedAccountItems: (state, action: PayloadAction<any[]>) => {
            state.accounts.selected = action.payload;
        },

        setIsFilteredTreasurySet: (state, action: PayloadAction<boolean>) => {
            state.treasuries.isFilteredSet = action.payload;
        },

        setSelectedTreasuryItems: (state, action: PayloadAction<any[]>) => {
            state.treasuries.selected = action.payload;
        },

        setIsFilteredAccountSet: (state, action: PayloadAction<boolean>) => {
            state.accounts.isFilteredSet = action.payload;
        },

        setStage1: (state, action: PayloadAction<any>) => {
            let stage1 = {
           
            }
            state.stage1 = stage1;
        },

        setMessage: (state, action: PayloadAction<ApiMessage>) => {
            state.message = action.payload;
        },
        resetMessage: (state) => {
            state.message = undefined;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(add.fulfilled, (state, { payload }) => {
            state.isLoading = false;
           // if (payload)
                //state.message = toApiMessage(payload.success?.);

        })
        builder.addCase(add.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(add.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })


        builder.addCase(getAccounts.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.accountsList.map(val => {

                    return {
                        id: val.id,
                        internalId: val.internalid,
                        name: val.name?.value ?? "",
                        code: val.code?.value ?? "",
                        currencyId: val.currency?.id?.value ?? "",
                        currencyName: val.currency?.name?.value ?? "",
                        currencyCode: val.currency?.code?.value ?? "",
                        currencyDecimalPlaces: val.currency?.decimalplaces?.value ?? undefined,
                        currencyForm: val.currency?.form?.value ?? undefined,
                        currencySymbol: val.currency?.symbol?.value ?? "",
                        accountTypeId: val.accounttype?.id?.value ?? "",
                        accountTypeName: val.accounttype?.name?.value ?? "",
                        mirrorBalance: val.mirrorbalance?.value ?? undefined,
                        status: val.status?.value ?? undefined,
                        ownerFirstname: val.owner?.firstname?.value ?? "",
                        ownerLastname: val.owner?.lastname?.value ?? "",
                        ownerMiddlename: val.owner?.middlename?.value ?? "",
                        ownerName: (val.owner?.firstname?.value ?? "") + " " + (val.owner?.lastname?.value ?? ""),
                        ownerId: val.owner?.id?.value ?? "",
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                                                isAuthorizationRequired: val?.accountsetting?.isauthorizationrequired,
                        isLinkingEnable: val?.accountsetting?.islinkingenable?.value,
                        isLinkingMetadataSet: val?.accountsetting?.islinkingmetadataset?.value,
                        transferControl: val?.accountsetting?.tansfercontrol?.value ,


                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.accounts.numberOfResults) {
                    state.accounts.hasMore = false;
                }
                state.accounts.items = state.accounts.items.concat(r);
            } else {
                state.accounts.hasMore = false;

            }
            state.accounts.isFetching = false

        })
        builder.addCase(getAccounts.rejected, (state, action) => {
            state.accounts.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getAccounts.pending, (state, action) => {
            state.accounts.isFetching = true;
           // state.message = undefined;
        })

         builder.addCase(getTreasuries.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.treasuriesList.map(val => {
                  
                    return {
                        id: val.id,
                        internalId: val.internalid,
                        name: val.name?.value ?? "",
                        code: val.code?.value ?? "",
                        nature: val.nature?.id?.value ?? undefined,
                        treasuryTypeId: val.treasurytype?.id?.value ?? "",
                        treasuryTypeName: val.treasurytype?.name?.value ?? "",
                        status: val.status?.value ?? undefined,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.treasuries.numberOfResults) {
                    state.treasuries.hasMore = false;
                }
                state.treasuries.items = state.treasuries.items.concat(r);
            } else {
                state.treasuries.hasMore = false;

            }
            state.treasuries.isFetching = false

        })
        builder.addCase(getTreasuries.rejected, (state, action) => {
            state.treasuries.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getTreasuries.pending, (state, action) => {
            state.treasuries.isFetching = true;
           // state.message = undefined;
        })


        builder.addCase(getTransactionSummary.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {
                var r = (payload.response?.success?.transactionsummaryList.map(val => {

                    return {
                        amount: val?.amount?.value,
                        creditorfeeValue: val?.creditorfee?.fee?.value,
                        creditorfeeId: val?.creditorfee?.id?.value,
                        creditorfeeName: val?.creditorfee?.name?.value,
                        debtorfeeValue: val?.debtorfee?.fee?.value,
                        debtorfeeId: val?.debtorfee?.id?.value,
                        debtorfeeName: val?.debtorfee?.name?.value,
                        currencyId: val?.currency?.id?.value ?? "",
                        currencyName: val?.currency?.name?.value ?? "",
                        currencyCode: val?.currency?.code?.value ?? "",
                        currencyDecimalPlaces: val?.currency?.decimalplaces?.value ?? undefined,
                        currencyForm: val?.currency?.form?.value ?? undefined,
                        currencySymbol: val?.currency?.symbol?.value ?? "",
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
         
                state.confirmation = r;
               
            }

        })
        builder.addCase(getTransactionSummary.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload?.response.data == 403) {
                var r = (action.payload?.metadata?.transactions?.map((val: any) => {
                    return {
                        amount: val?.amount,
                    }
                }
                ) as any[])
                state.confirmation = r;
                return;
            }
            if (action.payload) {
                state.message = action.payload.response;
            }
        })
        builder.addCase(getTransactionSummary.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })

    }
})

export const { reset, dismissMessage, resetMessage, setStage1, setMessage, resetAccountsSet, resetTreasuriesSet,setIsFilteredAccountSet,setIsFilteredTreasurySet,setSelectedAccountItems,setSelectedTreasuryItems } = withdrawFormSlice.actions

export default withdrawFormSlice.reducer


