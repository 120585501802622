import { ComboBox, DefaultButton, DialogFooter, Icon, IconButton, IPersonaProps, IPickerItemProps, IStackTokens, ISuggestionItemProps, ISuggestionModel, ISuggestionsProps, ITag, Label, MessageBar, MessageBarType, Modal, NormalPeoplePicker, PeoplePickerItem, Persona, PersonaSize, PrimaryButton, Spinner, SpinnerSize, Stack, TagItemSuggestion, TagPicker, Text, TextField, Toggle, TooltipHost, ValidationState } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { FormEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks";
import { add, dismissMessage, get, getBaseCurrencies, getQuoteCurrencies, reset, getCategories, getMainPairsItems, resetCategories, resetMainPair, setIsFilteredCategoriesSet, updatePrices, resetBaseCurrencies, resetQuoteCurrencies, setIsFilteredBaseCurrenciesSet, setIsFilteredMainPairsSet, setIsFilteredQuoteCurrenciesSet, setSelectedBaseCurrencyItems, setSelectedCategoryItems, setSelectedQuoteCurrencyItems, setSelecteMainPairItems, setStage1, update, setPriceTriggerSettings } from "./CurrencyPairsFormSlice";
import * as Yup from 'yup';
import { DynamicInputField, InputField } from "../../controls/Controls";
import { inputs } from "../../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../../app/Api";
import { Message } from "../../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../../FormProps";
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog";
import { formatDate, timestampToDate } from "../../../../app/Helpers";
import { AuthenticateReply } from "../../../../repository/UserManagement/authentication_pb";
import { DynamicField } from "../../../common/DynamicFields/DynamicFields";
import { ExtraField } from "../../../../repository/Accountant/common_pb";
import { TableState } from "../../../common/Table/TableSate";
import { AccountStatus, LimitationType, Operations } from "../../../../app/Enums";
import { GetCurrenciesRequest } from "../../../../repository/Accountant/currency_pb"
import { AddCurrencyPairRequest, GetCurrencyPairRequest, UpdateCurrencyPairResponse, AddCurrencyPairResponse, UpdateCurrencyPairRequest, GetCurrencyPairsRequest, SetPriceTriggerRequest, SetPriceTriggerResponse } from "../../../../repository/Trading/currency_pair_pb";
import { List } from "../../../common/List/List";
import { Popup } from "../../../common/Popup/Popup";
import { CurrenciesPage } from "../../../pages/Accountant/Currencies/CurrenciesPage";
import { GetCategoriesRequest } from "../../../../repository/Trading/category_pb";
import { CurrencyPairsPage } from "../../../pages/Trading/CurrencyPairs/CurrencyPairsPage";
import { CategoriesPage } from "../../../pages/Trading/Categories/CategoriesPage";

let req: AddCurrencyPairRequest;
let updateReq: UpdateCurrencyPairRequest;
let setPriceTriggerReq: SetPriceTriggerRequest;

let baseCurrenciesReq: GetCurrenciesRequest;
let quoteCurrenciesReq: GetCurrenciesRequest;
let categoriesReq: GetCategoriesRequest;
let mainPairsReq: GetCurrencyPairsRequest;

let promise: any;
let getFilterPromise: any;
let org: number = -1;
let current: AuthenticateReply.AsObject;

export const CurrencyPairsPriceSettingsForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();
    const [currentAction, setCurrenctAction] = useState(0)

    const state: {
        isLoading: boolean, message: ApiMessage | undefined, stage1: any, baseCurrencies: TableState
        quoteCurrencies: TableState,
        categories: TableState,
        mainPairs: TableState

    } = useAppSelector((state) => {

        return {
            isLoading: state.currencyPairsForm.isLoading, message: state.currencyPairsForm.message, stage1: state.currencyPairsForm.stage1,
            baseCurrencies: state.currencyPairsForm.baseCurrencies,
            quoteCurrencies: state.currencyPairsForm.quoteCurrencies,
            categories: state.currencyPairsForm.categories,
            mainPairs: state.currencyPairsForm.mainPairs


        }
    })

    useEffect(() => {
        var l = searchParams.get('org');
        req = new AddCurrencyPairRequest();
        updateReq = new UpdateCurrencyPairRequest()
        baseCurrenciesReq = new GetCurrenciesRequest();
        quoteCurrenciesReq = new GetCurrenciesRequest();
        categoriesReq = new GetCategoriesRequest();
        mainPairsReq = new GetCurrencyPairsRequest();
        setPriceTriggerReq = new SetPriceTriggerRequest();
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                req.setOrganization(wrapper)
                baseCurrenciesReq.setOrganization(wrapper)
                quoteCurrenciesReq.setOrganization(wrapper)
                categoriesReq.setOrganization(wrapper)
                mainPairsReq.setOrganization(wrapper)
            }
        }

        if (props.type == FormType.EDIT) {
            if (props.fetchData) {
                let r = new GetCurrencyPairRequest()
                r.setId(props?.renderObject?.id);
                promise?.abort()
                promise = dispatch(get({ body: r, headers: getHeaders() }))
            } else {
                dispatch(setStage1(props?.renderObject))
            }
        }
        const boolv = new BoolValue();
        boolv.setValue(true);
        baseCurrenciesReq.setNumofresults(state.baseCurrencies.numberOfResults)
        baseCurrenciesReq.setOrder(state.baseCurrencies.isDescending)
        baseCurrenciesReq.setIsavailable(boolv)

        quoteCurrenciesReq.setNumofresults(state.quoteCurrencies.numberOfResults)
        quoteCurrenciesReq.setOrder(state.quoteCurrencies.isDescending)
        quoteCurrenciesReq.setIsavailable(boolv)

        categoriesReq.setNumofresults(state.categories.numberOfResults)
        categoriesReq.setOrder(state.categories.isDescending)
        categoriesReq.setIsavailable(boolv)

        mainPairsReq.setNumofresults(state.mainPairs.numberOfResults)
        mainPairsReq.setOrder(state.mainPairs.isDescending)


        if (props.renderObject?.baseCurrencyId) {
            dispatch(setSelectedBaseCurrencyItems(([{ id: props.renderObject.baseCurrencyId, name: props.renderObject.baseCurrencyName, decimalPlaces: props.renderObject.baseCurrencyDecimalPlaces }])))

        }

        if (props.renderObject?.quoteCurrencyId)
            dispatch(setSelectedQuoteCurrencyItems(([{ id: props.renderObject.quoteCurrencyId, name: props.renderObject.quoteCurrencyName, decimalPlaces: props.renderObject.quoteCurrencyDecimalPlaces }])))


        if (props.renderObject?.categoryId)
            dispatch(setSelectedCategoryItems(([{ id: props.renderObject.categoryId, nameAr: props.renderObject.categoryNameAr, nameEn: props.renderObject.categoryNameEn }])))


        if (props.renderObject?.mainCurrencyPair)
            dispatch(setSelecteMainPairItems(([{
                id: props.renderObject.mainCurrencyPair,
                name: props.renderObject.mainCurrencyPairName,
                baseCurrencyId: props.renderObject?.mainCurrencyPairBaseCurrencyId,
                baseCurrencyName: props.renderObject?.mainCurrencyPairBaseCurrencyName,
                baseCurrencyCode: props.renderObject?.mainCurrencyPairBaseCurrencyCode,
                baseCurrencyDecimalPlaces: props.renderObject?.mainCurrencyPairBaseCurrencyDecimalPlaces,
                baseCurrencyForm: props.renderObject?.mainCurrencyPairBaseCurrencyForm,
                baseCurrencySymbol: props.renderObject?.mainCurrencyPairBaseCurrencySymbol,
                quoteCurrencyId: props.renderObject?.mainCurrencyPairQuoteCurrencyId,
                quoteCurrencyName: props.renderObject?.mainCurrencyPairQuoteCurrencyName,
                quoteCurrencyCode: props.renderObject?.mainCurrencyPairQuoteCurrencyCode,
                quoteCurrencyDecimalPlaces: props.renderObject?.mainCurrencyPairQuoteCurrencyDecimalPlaces,
                quoteCurrencyForm: props.renderObject?.mainCurrencyPairQuoteCurrencyForm,
                quoteCurrencySymbol: props.renderObject?.mainCurrencyPairQuoteCurrencySymbol,





            }])))

        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        }
        return () => { //clean up
            promise?.abort();
            dispatch(reset());
        }
    }, [])


    const baseCurrenciesSuggestionsProps: ISuggestionsProps<ITag> = {
        suggestionsHeaderText: t('currencies'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.baseCurrencies.hasMore && !state.baseCurrencies.isFetching,
        onRenderSuggestion: (props: ITag, suggestionItemProps: ISuggestionItemProps<ITag>) => {
            return <TagItemSuggestion>
                {props?.name ?? props?.key}
            </TagItemSuggestion>
        },
        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.baseCurrencies.items.findIndex(e => e.id == item.key)
            if (u >= 0) {
                dispatch(setSelectedBaseCurrencyItems([state.baseCurrencies.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.baseCurrencies.items.length > 0 && !state.baseCurrencies.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.baseCurrencies.items.at(state.baseCurrencies.items.length - 1).id);
                baseCurrenciesReq.setNextto(wrapper)
                getFilterPromise = dispatch(getBaseCurrencies({ body: baseCurrenciesReq, headers: getHeaders() }))
            }
        },
        suggestions: state.baseCurrencies.items.length > 0 ? state.baseCurrencies.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : [],
        isLoading: state.baseCurrencies.items.length == 0 ? state.baseCurrencies.isFetching : false,
        isSearching: state.baseCurrencies.items.length > 0 ? state.baseCurrencies.isFetching : false,


    };
    const onBaseCurrenciesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetBaseCurrencies())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        baseCurrenciesReq.setSearch(wrapper)
        baseCurrenciesReq.setNextto(undefined)
        dispatch(setIsFilteredBaseCurrenciesSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getBaseCurrencies({ body: baseCurrenciesReq, headers: getHeaders() }))
        return [];

    }

    const quoteCurrenciesSuggestionsProps: ISuggestionsProps<ITag> = {
        suggestionsHeaderText: t('currencies'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.quoteCurrencies.hasMore && !state.quoteCurrencies.isFetching,
        onRenderSuggestion: (props: ITag, suggestionItemProps: ISuggestionItemProps<ITag>) => {
            return <TagItemSuggestion>
                {props?.name ?? props?.key}
            </TagItemSuggestion>
        },
        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.quoteCurrencies.items.findIndex(e => e.id == item.key)
            if (u >= 0) {
                dispatch(setSelectedQuoteCurrencyItems([state.quoteCurrencies.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.quoteCurrencies.items.length > 0 && !state.quoteCurrencies.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.quoteCurrencies.items.at(state.quoteCurrencies.items.length - 1).id);
                quoteCurrenciesReq.setNextto(wrapper)
                getFilterPromise = dispatch(getQuoteCurrencies({ body: quoteCurrenciesReq, headers: getHeaders() }))
            }
        },
        suggestions: state.quoteCurrencies.items.length > 0 ? state.quoteCurrencies.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : [],
        isLoading: state.quoteCurrencies.items.length == 0 ? state.quoteCurrencies.isFetching : false,
        isSearching: state.quoteCurrencies.items.length > 0 ? state.quoteCurrencies.isFetching : false,


    };
    const onQuoteCurrenciesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetQuoteCurrencies())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        quoteCurrenciesReq.setSearch(wrapper)
        quoteCurrenciesReq.setNextto(undefined)
        dispatch(setIsFilteredQuoteCurrenciesSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getQuoteCurrencies({ body: quoteCurrenciesReq, headers: getHeaders() }))
        return [];

    }
    const onCategoriesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetCategories())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        categoriesReq.setSearch(wrapper)
        categoriesReq.setNextto(undefined)
        dispatch(setIsFilteredCategoriesSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getCategories({ body: categoriesReq, headers: getHeaders() }))
        return [];

    }

    const onPairsFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetMainPair())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        mainPairsReq.setSearch(wrapper)
        mainPairsReq.setNextto(undefined)
        dispatch(setIsFilteredMainPairsSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getMainPairsItems({ body: mainPairsReq, headers: getHeaders() }))
        return [];


    }

    return (
        <>
            <Popup isOpen={(currentAction == 100)} title={t("currencies")} onDismiss={() => { setCurrenctAction(0) }} >

                <CurrenciesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedBaseCurrencyItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            <Popup isOpen={(currentAction == 101)} title={t("currencies")} onDismiss={() => { setCurrenctAction(0) }} >

                <CurrenciesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedQuoteCurrencyItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            <Popup isOpen={(currentAction == 102)} title={t("categories")} onDismiss={() => { setCurrenctAction(0) }} >

                <CategoriesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedCategoryItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>


            <Popup isOpen={(currentAction == 103)} title={t("currencyPairs")} onDismiss={() => { setCurrenctAction(0) }} >

                <CurrencyPairsPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelecteMainPairItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    isActive: (props.renderObject) ? props.renderObject?.isPriceTriggerActive : false,
                    mainPairs: undefined,
                    buyPriceEquation: (props.renderObject) ? props.renderObject?.buyPriceEquation : '',
                    sellPriceEquation: (props.renderObject) ? props.renderObject?.sellPriceEquation : '',
                    tradingPriceEquation: (props.renderObject) ? props.renderObject?.tradingPriceEquation : '',

                }}

                validationSchema={Yup.object({
                    buyPriceEquation: inputs.equation,
                    sellPriceEquation: inputs.equation,
                    tradingPriceEquation: inputs.equation,

                })}

                onSubmit={(values, actions) => {
                    if (props.type == FormType.EDIT) {

                        let mainPair = state.mainPairs.selected.at(0);
                        if (mainPair.id == props.renderObject?.id) {
                            dispatch(setSelecteMainPairItems([]))
                            return;
                        }
                        setPriceTriggerReq.setId(props.renderObject?.id)

                        setPriceTriggerReq.setIsactive(values.isActive)
                        setPriceTriggerReq.setMaincurrencypair(mainPair?.id)
                        setPriceTriggerReq.setSellpriceequation(values?.sellPriceEquation ?? "")
                        setPriceTriggerReq.setBuypriceequation(values?.buyPriceEquation ?? "")
                        setPriceTriggerReq.setTradingpriceequation(values?.tradingPriceEquation ?? "")

                        promise = dispatch(setPriceTriggerSettings({ body: setPriceTriggerReq, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: SetPriceTriggerResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            ...props.renderObject,
                                            buyPriceEquation: values.buyPriceEquation,
                                            sellPriceEquation: values.sellPriceEquation,
                                            tradingPriceEquation: values.tradingPriceEquation,
                                            isPriceTriggerActive: values.isActive,
                                            mainCurrencyPair: mainPair?.id,
                                            mainCurrencyPairName: mainPair?.name,
                                            mainCurrencyPairBaseCurrencyId: mainPair?.baseCurrencyId,
                                            mainCurrencyPairBaseCurrencyName: mainPair?.baseCurrencyName,
                                            mainCurrencyPairBaseCurrencyCode: mainPair?.baseCurrencyCode,
                                            mainCurrencyPairBaseCurrencyDecimalPlaces: mainPair?.baseCurrencyDecimalPlaces,
                                            mainCurrencyPairBaseCurrencyForm: mainPair?.baseCurrencyForm,
                                            mainCurrencyPairBaseCurrencySymbol: mainPair?.baseCurrencySymbol,
                                            mainCurrencyPairQuoteCurrencyId: mainPair?.quoteCurrencyId,
                                            mainCurrencyPairQuoteCurrencyName: mainPair?.quoteCurrencyName,
                                            mainCurrencyPairQuoteCurrencyCode: mainPair?.quoteCurrencyCode,
                                            mainCurrencyPairQuoteCurrencyDecimalPlaces: mainPair?.quoteCurrencyDecimalPlaces,
                                            mainCurrencyPairQuoteCurrencyForm: mainPair?.quoteCurrencyForm,
                                            mainCurrencyPairQuoteCurrencySymbol: mainPair?.quoteCurrencySymbol,

                                        });
                                    }
                                }
                                actions.setSubmitting(false)


                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    }
                }}

            >
                {formkikProps => (
                    <Form >
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? (state.message.data != 401) ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            /> :
                                <SessionExpiredDialog />
                                : null
                            }





                            <Stack>
                                <Label
                                    disabled={state.isLoading}>{<div>
                                        {t("mainPair") + ' '}
                                        <TooltipHost content={t("mainPairDesc")}>
                                            <Icon iconName="Info" aria-label={t("mainPairDesc")} />
                                        </TooltipHost>
                                    </div>}</Label>
                                <List
                                    inputProps={{ placeholder: t("mainPair"), required: true }}
                                    disabled={state.isLoading}
                                    suggestionsHeaderText={t('pair')}
                                    isLoading={state.mainPairs.items.length == 0 ? state.mainPairs.isFetching : false}
                                    isSearching={state.mainPairs.items.length > 0 ? state.mainPairs.isFetching : false}
                                    moreSuggestionsAvailable={state.mainPairs.hasMore && !state.mainPairs.isFetching}
                                    suggestions={state.mainPairs.items.length > 0 ? state.mainPairs.items.map(e => { return { item: { key: e.id, text: e.name, imageInitials: e?.baseCurrencySymbol + "/" + e?.quoteCurrencySymbol, secondaryText: (e?.baseCurrencyCode == "" ? e?.baseCurrencyId : e?.baseCurrencyCode) + "-" + (e?.quoteCurrencyCode == "" ? e?.quoteCurrencyId : e?.quoteCurrencyCode), imageUrl: e?.iconSource } } as ISuggestionModel<IPersonaProps> }) : []}
                                    onGetMoreResults={() => {
                                        if (state.mainPairs.items.length > 0 && !state.mainPairs.isFetching) {
                                            const wrapper = new StringValue();
                                            wrapper.setValue(state.mainPairs.items.at(state.mainPairs.items.length - 1).id);
                                            mainPairsReq.setNextto(wrapper)
                                            getFilterPromise = dispatch(getMainPairsItems({ body: mainPairsReq, headers: getHeaders() }))
                                        }
                                    }}
                                    onSuggestionClick={(ev?, item?: any, index?: number) => {
                                        var u = state.mainPairs.items.findIndex(e => e.id == item.key)
                                        if (u >= 0) {
                                            dispatch(setSelecteMainPairItems([state.mainPairs.items.at(u)]))
                                        }


                                    }}
                                    isPeoplePicker={true}
                                    selectedItems={state.mainPairs.selected.length > 0 ? state.mainPairs.selected.map(e => { return { key: e.id, text: e.name, imageInitials: e?.baseCurrencySymbol + "/" + e?.quoteCurrencySymbol, secondaryText: (e?.baseCurrencyCode == "" ? e?.baseCurrencyId : e?.baseCurrencyCode) + "-" + (e?.quoteCurrencyCode == "" ? e?.quoteCurrencyId : e?.quoteCurrencyCode), imageUrl: e?.iconSource } as IPersonaProps }) : []}
                                    onChange={(e) => {
                                        dispatch(setSelecteMainPairItems([]))


                                    }}
                                    onEmptyInputFocus={() => {
                                        dispatch(resetMainPair())
                                        mainPairsReq.setSearch(undefined)
                                        mainPairsReq.setNextto(undefined)
                                        dispatch(setIsFilteredMainPairsSet(false))
                                        getFilterPromise?.abort()
                                        getFilterPromise = dispatch(getMainPairsItems({ body: mainPairsReq, headers: getHeaders() }))
                                        return []
                                    }}
                                    onFilterChanged={onPairsFilterChanged}
                                    endButtons={[
                                        {
                                            title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                            onClick: () => {
                                                setCurrenctAction(103)

                                            }
                                        }]}
                                />
                            </Stack>

                            <Field name="buyPriceEquation" label={
                                <div>
                                    {t("buyPriceEquation") + ' '}
                                    <TooltipHost content={t("equationDesc")}>
                                        <Icon iconName="Info" aria-label={t("equationDesc")} />
                                    </TooltipHost>
                                </div>
                            } placeholder={"(bp * 0.05) + 1"} dir={"ltr"} component={InputField} disabled={state.isLoading} maxLength={200} />

                            <Field name="sellPriceEquation" label={
                                <div>
                                    {t("sellPriceEquation") + ' '}
                                    <TooltipHost content={t("equationDesc")}>
                                        <Icon iconName="Info" aria-label={t("equationDesc")} />
                                    </TooltipHost>
                                </div>
                            } placeholder={"((sp + bp) / 2) + 1"} dir={"ltr"} component={InputField} disabled={state.isLoading} maxLength={200} />

                            <Field name="tradingPriceEquation" label={
                                <div>
                                    {t("tradingPriceEquation") + ' '}
                                    <TooltipHost content={t("equationDesc")}>
                                        <Icon iconName="Info" aria-label={t("equationDesc")} />
                                    </TooltipHost>
                                </div>
                            } placeholder={"tp - 1"} component={InputField} dir={"ltr"} disabled={state.isLoading} maxLength={200} />


                            {props.type == FormType.ADD ? <Toggle

                                onText={t("active")} offText={t("inactive")}
                                label={t("status")}
                                disabled={state.isLoading}

                                checked={formkikProps.values.isActive}
                                onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                                    formkikProps.setFieldValue('isActive', checked);
                                }
                                }
                            /> : undefined}
                            <Toggle

                                onText={t("active")} offText={t("inactive")}
                                label={t("status")}
                                disabled={state.isLoading}

                                checked={formkikProps.values.isActive}
                                onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                                    formkikProps.setFieldValue('isActive', checked);
                                }
                                }
                            />


                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("add") : t("edit")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={props.type == FormType.ADD ? t("discard") : t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>



                    </Form>
                )}
            </Formik>
        </>
    );

}

