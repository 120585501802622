// source: account_type_rule.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('./common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.account_type_rule_endpoint.AccountTypeRule', null, global);
goog.exportSymbol('proto.account_type_rule_endpoint.AddAccountTypeRuleReplay', null, global);
goog.exportSymbol('proto.account_type_rule_endpoint.AddAccountTypeRuleRequest', null, global);
goog.exportSymbol('proto.account_type_rule_endpoint.AddAccountTypeRuleResponse', null, global);
goog.exportSymbol('proto.account_type_rule_endpoint.AddAccountTypeRuleResponse.ResultCase', null, global);
goog.exportSymbol('proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay', null, global);
goog.exportSymbol('proto.account_type_rule_endpoint.DeleteAccountTypeRuleRequest', null, global);
goog.exportSymbol('proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse', null, global);
goog.exportSymbol('proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse.ResultCase', null, global);
goog.exportSymbol('proto.account_type_rule_endpoint.GetAccountTypeRuleReplay', null, global);
goog.exportSymbol('proto.account_type_rule_endpoint.GetAccountTypeRuleRequest', null, global);
goog.exportSymbol('proto.account_type_rule_endpoint.GetAccountTypeRuleResponse', null, global);
goog.exportSymbol('proto.account_type_rule_endpoint.GetAccountTypeRuleResponse.ResultCase', null, global);
goog.exportSymbol('proto.account_type_rule_endpoint.GetAccountTypeRulesReplay', null, global);
goog.exportSymbol('proto.account_type_rule_endpoint.GetAccountTypeRulesRequest', null, global);
goog.exportSymbol('proto.account_type_rule_endpoint.GetAccountTypeRulesResponse', null, global);
goog.exportSymbol('proto.account_type_rule_endpoint.GetAccountTypeRulesResponse.ResultCase', null, global);
goog.exportSymbol('proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay', null, global);
goog.exportSymbol('proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityRequest', null, global);
goog.exportSymbol('proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse', null, global);
goog.exportSymbol('proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse.ResultCase', null, global);
goog.exportSymbol('proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay', null, global);
goog.exportSymbol('proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest', null, global);
goog.exportSymbol('proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse', null, global);
goog.exportSymbol('proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse.ResultCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account_type_rule_endpoint.AddAccountTypeRuleRequest.repeatedFields_, null);
};
goog.inherits(proto.account_type_rule_endpoint.AddAccountTypeRuleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_type_rule_endpoint.AddAccountTypeRuleRequest.displayName = 'proto.account_type_rule_endpoint.AddAccountTypeRuleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest.repeatedFields_, null);
};
goog.inherits(proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest.displayName = 'proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityRequest.displayName = 'proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_type_rule_endpoint.DeleteAccountTypeRuleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_type_rule_endpoint.DeleteAccountTypeRuleRequest.displayName = 'proto.account_type_rule_endpoint.DeleteAccountTypeRuleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_type_rule_endpoint.GetAccountTypeRuleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_type_rule_endpoint.GetAccountTypeRuleRequest.displayName = 'proto.account_type_rule_endpoint.GetAccountTypeRuleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_type_rule_endpoint.GetAccountTypeRulesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.displayName = 'proto.account_type_rule_endpoint.GetAccountTypeRulesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_type_rule_endpoint.AddAccountTypeRuleReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_type_rule_endpoint.AddAccountTypeRuleReplay.displayName = 'proto.account_type_rule_endpoint.AddAccountTypeRuleReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay.displayName = 'proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay.displayName = 'proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay.displayName = 'proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.repeatedFields_, null);
};
goog.inherits(proto.account_type_rule_endpoint.GetAccountTypeRuleReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.displayName = 'proto.account_type_rule_endpoint.GetAccountTypeRuleReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_type_rule_endpoint.AccountTypeRule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_type_rule_endpoint.AccountTypeRule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_type_rule_endpoint.AccountTypeRule.displayName = 'proto.account_type_rule_endpoint.AccountTypeRule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account_type_rule_endpoint.GetAccountTypeRulesReplay.repeatedFields_, null);
};
goog.inherits(proto.account_type_rule_endpoint.GetAccountTypeRulesReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_type_rule_endpoint.GetAccountTypeRulesReplay.displayName = 'proto.account_type_rule_endpoint.GetAccountTypeRulesReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_type_rule_endpoint.AddAccountTypeRuleResponse.oneofGroups_);
};
goog.inherits(proto.account_type_rule_endpoint.AddAccountTypeRuleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_type_rule_endpoint.AddAccountTypeRuleResponse.displayName = 'proto.account_type_rule_endpoint.AddAccountTypeRuleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse.oneofGroups_);
};
goog.inherits(proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse.displayName = 'proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse.oneofGroups_);
};
goog.inherits(proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse.displayName = 'proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse.oneofGroups_);
};
goog.inherits(proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse.displayName = 'proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_type_rule_endpoint.GetAccountTypeRuleResponse.oneofGroups_);
};
goog.inherits(proto.account_type_rule_endpoint.GetAccountTypeRuleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_type_rule_endpoint.GetAccountTypeRuleResponse.displayName = 'proto.account_type_rule_endpoint.GetAccountTypeRuleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_type_rule_endpoint.GetAccountTypeRulesResponse.oneofGroups_);
};
goog.inherits(proto.account_type_rule_endpoint.GetAccountTypeRulesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_type_rule_endpoint.GetAccountTypeRulesResponse.displayName = 'proto.account_type_rule_endpoint.GetAccountTypeRulesResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleRequest.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_type_rule_endpoint.AddAccountTypeRuleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_type_rule_endpoint.AddAccountTypeRuleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    operation: jspb.Message.getFieldWithDefault(msg, 1, 0),
    creditoraccounttype: jspb.Message.getFieldWithDefault(msg, 2, ""),
    debtoraccounttype: jspb.Message.getFieldWithDefault(msg, 3, ""),
    creditorfee: jspb.Message.getFieldWithDefault(msg, 4, ""),
    debtorfee: jspb.Message.getFieldWithDefault(msg, 5, ""),
    isactive: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    directreservation: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_type_rule_endpoint.AddAccountTypeRuleRequest}
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_type_rule_endpoint.AddAccountTypeRuleRequest;
  return proto.account_type_rule_endpoint.AddAccountTypeRuleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_type_rule_endpoint.AddAccountTypeRuleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_type_rule_endpoint.AddAccountTypeRuleRequest}
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOperation(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreditoraccounttype(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDebtoraccounttype(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreditorfee(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDebtorfee(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsactive(value);
      break;
    case 7:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDirectreservation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_type_rule_endpoint.AddAccountTypeRuleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_type_rule_endpoint.AddAccountTypeRuleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperation();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCreditoraccounttype();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDebtoraccounttype();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreditorfee();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDebtorfee();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIsactive();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getDirectreservation();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional int32 operation = 1;
 * @return {number}
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleRequest.prototype.getOperation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.account_type_rule_endpoint.AddAccountTypeRuleRequest} returns this
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleRequest.prototype.setOperation = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string creditorAccountType = 2;
 * @return {string}
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleRequest.prototype.getCreditoraccounttype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_type_rule_endpoint.AddAccountTypeRuleRequest} returns this
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleRequest.prototype.setCreditoraccounttype = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string debtorAccountType = 3;
 * @return {string}
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleRequest.prototype.getDebtoraccounttype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_type_rule_endpoint.AddAccountTypeRuleRequest} returns this
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleRequest.prototype.setDebtoraccounttype = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string creditorFee = 4;
 * @return {string}
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleRequest.prototype.getCreditorfee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_type_rule_endpoint.AddAccountTypeRuleRequest} returns this
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleRequest.prototype.setCreditorfee = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string debtorFee = 5;
 * @return {string}
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleRequest.prototype.getDebtorfee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_type_rule_endpoint.AddAccountTypeRuleRequest} returns this
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleRequest.prototype.setDebtorfee = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool isActive = 6;
 * @return {boolean}
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleRequest.prototype.getIsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account_type_rule_endpoint.AddAccountTypeRuleRequest} returns this
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleRequest.prototype.setIsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * repeated common.ExtraField extraFields = 7;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 7));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.account_type_rule_endpoint.AddAccountTypeRuleRequest} returns this
*/
proto.account_type_rule_endpoint.AddAccountTypeRuleRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account_type_rule_endpoint.AddAccountTypeRuleRequest} returns this
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};


/**
 * optional google.protobuf.Int32Value organization = 8;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.account_type_rule_endpoint.AddAccountTypeRuleRequest} returns this
*/
proto.account_type_rule_endpoint.AddAccountTypeRuleRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.AddAccountTypeRuleRequest} returns this
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool directReservation = 9;
 * @return {boolean}
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleRequest.prototype.getDirectreservation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account_type_rule_endpoint.AddAccountTypeRuleRequest} returns this
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleRequest.prototype.setDirectreservation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    creditoraccounttype: jspb.Message.getFieldWithDefault(msg, 2, ""),
    creditorfee: jspb.Message.getFieldWithDefault(msg, 3, ""),
    debtorfee: jspb.Message.getFieldWithDefault(msg, 4, ""),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest;
  return proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreditoraccounttype(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreditorfee(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDebtorfee(value);
      break;
    case 5:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreditoraccounttype();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreditorfee();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDebtorfee();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest} returns this
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string creditorAccountType = 2;
 * @return {string}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest.prototype.getCreditoraccounttype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest} returns this
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest.prototype.setCreditoraccounttype = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string creditorFee = 3;
 * @return {string}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest.prototype.getCreditorfee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest} returns this
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest.prototype.setCreditorfee = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string debtorFee = 4;
 * @return {string}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest.prototype.getDebtorfee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest} returns this
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest.prototype.setDebtorfee = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated common.ExtraField extraFields = 5;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 5));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest} returns this
*/
proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest} returns this
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isactive: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityRequest}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityRequest;
  return proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityRequest}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsactive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsactive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityRequest} returns this
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool isActive = 2;
 * @return {boolean}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityRequest.prototype.getIsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityRequest} returns this
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityRequest.prototype.setIsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_type_rule_endpoint.DeleteAccountTypeRuleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_type_rule_endpoint.DeleteAccountTypeRuleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_type_rule_endpoint.DeleteAccountTypeRuleRequest}
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_type_rule_endpoint.DeleteAccountTypeRuleRequest;
  return proto.account_type_rule_endpoint.DeleteAccountTypeRuleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_type_rule_endpoint.DeleteAccountTypeRuleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_type_rule_endpoint.DeleteAccountTypeRuleRequest}
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_type_rule_endpoint.DeleteAccountTypeRuleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_type_rule_endpoint.DeleteAccountTypeRuleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_type_rule_endpoint.DeleteAccountTypeRuleRequest} returns this
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_type_rule_endpoint.GetAccountTypeRuleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_type_rule_endpoint.GetAccountTypeRuleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRuleRequest}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_type_rule_endpoint.GetAccountTypeRuleRequest;
  return proto.account_type_rule_endpoint.GetAccountTypeRuleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_type_rule_endpoint.GetAccountTypeRuleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRuleRequest}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_type_rule_endpoint.GetAccountTypeRuleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_type_rule_endpoint.GetAccountTypeRuleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRuleRequest} returns this
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_type_rule_endpoint.GetAccountTypeRulesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    creditoraccounttype: (f = msg.getCreditoraccounttype()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    debtoraccounttype: (f = msg.getDebtoraccounttype()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    operation: (f = msg.getOperation()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    isactive: (f = msg.getIsactive()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    directreservation: (f = msg.getDirectreservation()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    order: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    nextto: (f = msg.getNextto()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    numofresults: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRulesRequest}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_type_rule_endpoint.GetAccountTypeRulesRequest;
  return proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_type_rule_endpoint.GetAccountTypeRulesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRulesRequest}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCreditoraccounttype(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDebtoraccounttype(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOperation(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsactive(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setDirectreservation(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrder(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNextto(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumofresults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_type_rule_endpoint.GetAccountTypeRulesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getCreditoraccounttype();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDebtoraccounttype();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOperation();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getIsactive();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getDirectreservation();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getNextto();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNumofresults();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional google.protobuf.Int32Value organization = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRulesRequest} returns this
*/
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRulesRequest} returns this
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue creditorAccountType = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.getCreditoraccounttype = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRulesRequest} returns this
*/
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.setCreditoraccounttype = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRulesRequest} returns this
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.clearCreditoraccounttype = function() {
  return this.setCreditoraccounttype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.hasCreditoraccounttype = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue debtorAccountType = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.getDebtoraccounttype = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRulesRequest} returns this
*/
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.setDebtoraccounttype = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRulesRequest} returns this
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.clearDebtoraccounttype = function() {
  return this.setDebtoraccounttype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.hasDebtoraccounttype = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Int32Value operation = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.getOperation = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRulesRequest} returns this
*/
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.setOperation = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRulesRequest} returns this
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.clearOperation = function() {
  return this.setOperation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.hasOperation = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.BoolValue isActive = 5;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.getIsactive = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 5));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRulesRequest} returns this
*/
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.setIsactive = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRulesRequest} returns this
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.clearIsactive = function() {
  return this.setIsactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.hasIsactive = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.BoolValue directReservation = 10;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.getDirectreservation = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 10));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRulesRequest} returns this
*/
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.setDirectreservation = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRulesRequest} returns this
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.clearDirectreservation = function() {
  return this.setDirectreservation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.hasDirectreservation = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.StringValue search = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRulesRequest} returns this
*/
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRulesRequest} returns this
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool order = 7;
 * @return {boolean}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.getOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRulesRequest} returns this
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional google.protobuf.StringValue nextTo = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.getNextto = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRulesRequest} returns this
*/
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.setNextto = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRulesRequest} returns this
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.clearNextto = function() {
  return this.setNextto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.hasNextto = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int32 numOfResults = 9;
 * @return {number}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.getNumofresults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRulesRequest} returns this
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesRequest.prototype.setNumofresults = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_type_rule_endpoint.AddAccountTypeRuleReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_type_rule_endpoint.AddAccountTypeRuleReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_type_rule_endpoint.AddAccountTypeRuleReplay}
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_type_rule_endpoint.AddAccountTypeRuleReplay;
  return proto.account_type_rule_endpoint.AddAccountTypeRuleReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_type_rule_endpoint.AddAccountTypeRuleReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_type_rule_endpoint.AddAccountTypeRuleReplay}
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 3:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_type_rule_endpoint.AddAccountTypeRuleReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_type_rule_endpoint.AddAccountTypeRuleReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleReplay.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_type_rule_endpoint.AddAccountTypeRuleReplay} returns this
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_type_rule_endpoint.AddAccountTypeRuleReplay} returns this
*/
proto.account_type_rule_endpoint.AddAccountTypeRuleReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.AddAccountTypeRuleReplay} returns this
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.MessageResponse message = 3;
 * @return {?proto.common.MessageResponse}
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 3));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.account_type_rule_endpoint.AddAccountTypeRuleReplay} returns this
*/
proto.account_type_rule_endpoint.AddAccountTypeRuleReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.AddAccountTypeRuleReplay} returns this
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay;
  return proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay} returns this
*/
proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay} returns this
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay} returns this
*/
proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay} returns this
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay;
  return proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay} returns this
*/
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay} returns this
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay} returns this
*/
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay} returns this
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    deleteddate: (f = msg.getDeleteddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay}
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay;
  return proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay}
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeleteddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeleteddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp deletedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay.prototype.getDeleteddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay} returns this
*/
proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay.prototype.setDeleteddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay} returns this
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay.prototype.clearDeleteddate = function() {
  return this.setDeleteddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay.prototype.hasDeleteddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay} returns this
*/
proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay} returns this
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_type_rule_endpoint.GetAccountTypeRuleReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operation: (f = msg.getOperation()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    creditoraccounttype: (f = msg.getCreditoraccounttype()) && Protos_common_pb.AccountTypeResponse.toObject(includeInstance, f),
    debtoraccounttype: (f = msg.getDebtoraccounttype()) && Protos_common_pb.AccountTypeResponse.toObject(includeInstance, f),
    creditorfee: (f = msg.getCreditorfee()) && Protos_common_pb.FeeResponse.toObject(includeInstance, f),
    debtorfee: (f = msg.getDebtorfee()) && Protos_common_pb.FeeResponse.toObject(includeInstance, f),
    isactive: (f = msg.getIsactive()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    directreservation: (f = msg.getDirectreservation()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRuleReplay}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_type_rule_endpoint.GetAccountTypeRuleReplay;
  return proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_type_rule_endpoint.GetAccountTypeRuleReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRuleReplay}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOperation(value);
      break;
    case 3:
      var value = new Protos_common_pb.AccountTypeResponse;
      reader.readMessage(value,Protos_common_pb.AccountTypeResponse.deserializeBinaryFromReader);
      msg.setCreditoraccounttype(value);
      break;
    case 4:
      var value = new Protos_common_pb.AccountTypeResponse;
      reader.readMessage(value,Protos_common_pb.AccountTypeResponse.deserializeBinaryFromReader);
      msg.setDebtoraccounttype(value);
      break;
    case 5:
      var value = new Protos_common_pb.FeeResponse;
      reader.readMessage(value,Protos_common_pb.FeeResponse.deserializeBinaryFromReader);
      msg.setCreditorfee(value);
      break;
    case 6:
      var value = new Protos_common_pb.FeeResponse;
      reader.readMessage(value,Protos_common_pb.FeeResponse.deserializeBinaryFromReader);
      msg.setDebtorfee(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsactive(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setDirectreservation(value);
      break;
    case 8:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    case 9:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_type_rule_endpoint.GetAccountTypeRuleReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getCreditoraccounttype();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.AccountTypeResponse.serializeBinaryToWriter
    );
  }
  f = message.getDebtoraccounttype();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Protos_common_pb.AccountTypeResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreditorfee();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Protos_common_pb.FeeResponse.serializeBinaryToWriter
    );
  }
  f = message.getDebtorfee();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Protos_common_pb.FeeResponse.serializeBinaryToWriter
    );
  }
  f = message.getIsactive();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getDirectreservation();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRuleReplay} returns this
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Int32Value operation = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.getOperation = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRuleReplay} returns this
*/
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.setOperation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRuleReplay} returns this
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.clearOperation = function() {
  return this.setOperation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.hasOperation = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.AccountTypeResponse creditorAccountType = 3;
 * @return {?proto.common.AccountTypeResponse}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.getCreditoraccounttype = function() {
  return /** @type{?proto.common.AccountTypeResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.AccountTypeResponse, 3));
};


/**
 * @param {?proto.common.AccountTypeResponse|undefined} value
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRuleReplay} returns this
*/
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.setCreditoraccounttype = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRuleReplay} returns this
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.clearCreditoraccounttype = function() {
  return this.setCreditoraccounttype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.hasCreditoraccounttype = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional common.AccountTypeResponse debtorAccountType = 4;
 * @return {?proto.common.AccountTypeResponse}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.getDebtoraccounttype = function() {
  return /** @type{?proto.common.AccountTypeResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.AccountTypeResponse, 4));
};


/**
 * @param {?proto.common.AccountTypeResponse|undefined} value
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRuleReplay} returns this
*/
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.setDebtoraccounttype = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRuleReplay} returns this
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.clearDebtoraccounttype = function() {
  return this.setDebtoraccounttype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.hasDebtoraccounttype = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional common.FeeResponse creditorFee = 5;
 * @return {?proto.common.FeeResponse}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.getCreditorfee = function() {
  return /** @type{?proto.common.FeeResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.FeeResponse, 5));
};


/**
 * @param {?proto.common.FeeResponse|undefined} value
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRuleReplay} returns this
*/
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.setCreditorfee = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRuleReplay} returns this
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.clearCreditorfee = function() {
  return this.setCreditorfee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.hasCreditorfee = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional common.FeeResponse debtorFee = 6;
 * @return {?proto.common.FeeResponse}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.getDebtorfee = function() {
  return /** @type{?proto.common.FeeResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.FeeResponse, 6));
};


/**
 * @param {?proto.common.FeeResponse|undefined} value
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRuleReplay} returns this
*/
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.setDebtorfee = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRuleReplay} returns this
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.clearDebtorfee = function() {
  return this.setDebtorfee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.hasDebtorfee = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.BoolValue isActive = 7;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.getIsactive = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 7));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRuleReplay} returns this
*/
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.setIsactive = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRuleReplay} returns this
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.clearIsactive = function() {
  return this.setIsactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.hasIsactive = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.BoolValue directReservation = 11;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.getDirectreservation = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 11));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRuleReplay} returns this
*/
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.setDirectreservation = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRuleReplay} returns this
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.clearDirectreservation = function() {
  return this.setDirectreservation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.hasDirectreservation = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated common.ExtraField extraFields = 8;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 8));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRuleReplay} returns this
*/
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRuleReplay} returns this
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};


/**
 * optional common.ProfileResponse createdBy = 9;
 * @return {?proto.common.ProfileResponse}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 9));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRuleReplay} returns this
*/
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRuleReplay} returns this
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRuleReplay} returns this
*/
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRuleReplay} returns this
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_type_rule_endpoint.AccountTypeRule.prototype.toObject = function(opt_includeInstance) {
  return proto.account_type_rule_endpoint.AccountTypeRule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_type_rule_endpoint.AccountTypeRule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.AccountTypeRule.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operation: (f = msg.getOperation()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    creditoraccounttype: (f = msg.getCreditoraccounttype()) && Protos_common_pb.AccountTypeResponse.toObject(includeInstance, f),
    debtoraccounttype: (f = msg.getDebtoraccounttype()) && Protos_common_pb.AccountTypeResponse.toObject(includeInstance, f),
    creditorfee: (f = msg.getCreditorfee()) && Protos_common_pb.FeeResponse.toObject(includeInstance, f),
    debtorfee: (f = msg.getDebtorfee()) && Protos_common_pb.FeeResponse.toObject(includeInstance, f),
    isactive: (f = msg.getIsactive()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    directreservation: (f = msg.getDirectreservation()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_type_rule_endpoint.AccountTypeRule}
 */
proto.account_type_rule_endpoint.AccountTypeRule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_type_rule_endpoint.AccountTypeRule;
  return proto.account_type_rule_endpoint.AccountTypeRule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_type_rule_endpoint.AccountTypeRule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_type_rule_endpoint.AccountTypeRule}
 */
proto.account_type_rule_endpoint.AccountTypeRule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOperation(value);
      break;
    case 3:
      var value = new Protos_common_pb.AccountTypeResponse;
      reader.readMessage(value,Protos_common_pb.AccountTypeResponse.deserializeBinaryFromReader);
      msg.setCreditoraccounttype(value);
      break;
    case 4:
      var value = new Protos_common_pb.AccountTypeResponse;
      reader.readMessage(value,Protos_common_pb.AccountTypeResponse.deserializeBinaryFromReader);
      msg.setDebtoraccounttype(value);
      break;
    case 5:
      var value = new Protos_common_pb.FeeResponse;
      reader.readMessage(value,Protos_common_pb.FeeResponse.deserializeBinaryFromReader);
      msg.setCreditorfee(value);
      break;
    case 6:
      var value = new Protos_common_pb.FeeResponse;
      reader.readMessage(value,Protos_common_pb.FeeResponse.deserializeBinaryFromReader);
      msg.setDebtorfee(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsactive(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setDirectreservation(value);
      break;
    case 8:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_type_rule_endpoint.AccountTypeRule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_type_rule_endpoint.AccountTypeRule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_type_rule_endpoint.AccountTypeRule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.AccountTypeRule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getCreditoraccounttype();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.AccountTypeResponse.serializeBinaryToWriter
    );
  }
  f = message.getDebtoraccounttype();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Protos_common_pb.AccountTypeResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreditorfee();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Protos_common_pb.FeeResponse.serializeBinaryToWriter
    );
  }
  f = message.getDebtorfee();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Protos_common_pb.FeeResponse.serializeBinaryToWriter
    );
  }
  f = message.getIsactive();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getDirectreservation();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.account_type_rule_endpoint.AccountTypeRule.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_type_rule_endpoint.AccountTypeRule} returns this
 */
proto.account_type_rule_endpoint.AccountTypeRule.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Int32Value operation = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.account_type_rule_endpoint.AccountTypeRule.prototype.getOperation = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.account_type_rule_endpoint.AccountTypeRule} returns this
*/
proto.account_type_rule_endpoint.AccountTypeRule.prototype.setOperation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.AccountTypeRule} returns this
 */
proto.account_type_rule_endpoint.AccountTypeRule.prototype.clearOperation = function() {
  return this.setOperation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.AccountTypeRule.prototype.hasOperation = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.AccountTypeResponse creditorAccountType = 3;
 * @return {?proto.common.AccountTypeResponse}
 */
proto.account_type_rule_endpoint.AccountTypeRule.prototype.getCreditoraccounttype = function() {
  return /** @type{?proto.common.AccountTypeResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.AccountTypeResponse, 3));
};


/**
 * @param {?proto.common.AccountTypeResponse|undefined} value
 * @return {!proto.account_type_rule_endpoint.AccountTypeRule} returns this
*/
proto.account_type_rule_endpoint.AccountTypeRule.prototype.setCreditoraccounttype = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.AccountTypeRule} returns this
 */
proto.account_type_rule_endpoint.AccountTypeRule.prototype.clearCreditoraccounttype = function() {
  return this.setCreditoraccounttype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.AccountTypeRule.prototype.hasCreditoraccounttype = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional common.AccountTypeResponse debtorAccountType = 4;
 * @return {?proto.common.AccountTypeResponse}
 */
proto.account_type_rule_endpoint.AccountTypeRule.prototype.getDebtoraccounttype = function() {
  return /** @type{?proto.common.AccountTypeResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.AccountTypeResponse, 4));
};


/**
 * @param {?proto.common.AccountTypeResponse|undefined} value
 * @return {!proto.account_type_rule_endpoint.AccountTypeRule} returns this
*/
proto.account_type_rule_endpoint.AccountTypeRule.prototype.setDebtoraccounttype = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.AccountTypeRule} returns this
 */
proto.account_type_rule_endpoint.AccountTypeRule.prototype.clearDebtoraccounttype = function() {
  return this.setDebtoraccounttype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.AccountTypeRule.prototype.hasDebtoraccounttype = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional common.FeeResponse creditorFee = 5;
 * @return {?proto.common.FeeResponse}
 */
proto.account_type_rule_endpoint.AccountTypeRule.prototype.getCreditorfee = function() {
  return /** @type{?proto.common.FeeResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.FeeResponse, 5));
};


/**
 * @param {?proto.common.FeeResponse|undefined} value
 * @return {!proto.account_type_rule_endpoint.AccountTypeRule} returns this
*/
proto.account_type_rule_endpoint.AccountTypeRule.prototype.setCreditorfee = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.AccountTypeRule} returns this
 */
proto.account_type_rule_endpoint.AccountTypeRule.prototype.clearCreditorfee = function() {
  return this.setCreditorfee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.AccountTypeRule.prototype.hasCreditorfee = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional common.FeeResponse debtorFee = 6;
 * @return {?proto.common.FeeResponse}
 */
proto.account_type_rule_endpoint.AccountTypeRule.prototype.getDebtorfee = function() {
  return /** @type{?proto.common.FeeResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.FeeResponse, 6));
};


/**
 * @param {?proto.common.FeeResponse|undefined} value
 * @return {!proto.account_type_rule_endpoint.AccountTypeRule} returns this
*/
proto.account_type_rule_endpoint.AccountTypeRule.prototype.setDebtorfee = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.AccountTypeRule} returns this
 */
proto.account_type_rule_endpoint.AccountTypeRule.prototype.clearDebtorfee = function() {
  return this.setDebtorfee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.AccountTypeRule.prototype.hasDebtorfee = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.BoolValue isActive = 7;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.account_type_rule_endpoint.AccountTypeRule.prototype.getIsactive = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 7));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.account_type_rule_endpoint.AccountTypeRule} returns this
*/
proto.account_type_rule_endpoint.AccountTypeRule.prototype.setIsactive = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.AccountTypeRule} returns this
 */
proto.account_type_rule_endpoint.AccountTypeRule.prototype.clearIsactive = function() {
  return this.setIsactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.AccountTypeRule.prototype.hasIsactive = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.BoolValue directReservation = 10;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.account_type_rule_endpoint.AccountTypeRule.prototype.getDirectreservation = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 10));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.account_type_rule_endpoint.AccountTypeRule} returns this
*/
proto.account_type_rule_endpoint.AccountTypeRule.prototype.setDirectreservation = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.AccountTypeRule} returns this
 */
proto.account_type_rule_endpoint.AccountTypeRule.prototype.clearDirectreservation = function() {
  return this.setDirectreservation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.AccountTypeRule.prototype.hasDirectreservation = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional common.ProfileResponse createdBy = 8;
 * @return {?proto.common.ProfileResponse}
 */
proto.account_type_rule_endpoint.AccountTypeRule.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 8));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.account_type_rule_endpoint.AccountTypeRule} returns this
*/
proto.account_type_rule_endpoint.AccountTypeRule.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.AccountTypeRule} returns this
 */
proto.account_type_rule_endpoint.AccountTypeRule.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.AccountTypeRule.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_type_rule_endpoint.AccountTypeRule.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_type_rule_endpoint.AccountTypeRule} returns this
*/
proto.account_type_rule_endpoint.AccountTypeRule.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.AccountTypeRule} returns this
 */
proto.account_type_rule_endpoint.AccountTypeRule.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.AccountTypeRule.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_type_rule_endpoint.GetAccountTypeRulesReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_type_rule_endpoint.GetAccountTypeRulesReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    accounttyperulesList: jspb.Message.toObjectList(msg.getAccounttyperulesList(),
    proto.account_type_rule_endpoint.AccountTypeRule.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRulesReplay}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_type_rule_endpoint.GetAccountTypeRulesReplay;
  return proto.account_type_rule_endpoint.GetAccountTypeRulesReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_type_rule_endpoint.GetAccountTypeRulesReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRulesReplay}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_type_rule_endpoint.AccountTypeRule;
      reader.readMessage(value,proto.account_type_rule_endpoint.AccountTypeRule.deserializeBinaryFromReader);
      msg.addAccounttyperules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_type_rule_endpoint.GetAccountTypeRulesReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_type_rule_endpoint.GetAccountTypeRulesReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccounttyperulesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.account_type_rule_endpoint.AccountTypeRule.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AccountTypeRule accountTypeRules = 1;
 * @return {!Array<!proto.account_type_rule_endpoint.AccountTypeRule>}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesReplay.prototype.getAccounttyperulesList = function() {
  return /** @type{!Array<!proto.account_type_rule_endpoint.AccountTypeRule>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.account_type_rule_endpoint.AccountTypeRule, 1));
};


/**
 * @param {!Array<!proto.account_type_rule_endpoint.AccountTypeRule>} value
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRulesReplay} returns this
*/
proto.account_type_rule_endpoint.GetAccountTypeRulesReplay.prototype.setAccounttyperulesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.account_type_rule_endpoint.AccountTypeRule=} opt_value
 * @param {number=} opt_index
 * @return {!proto.account_type_rule_endpoint.AccountTypeRule}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesReplay.prototype.addAccounttyperules = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.account_type_rule_endpoint.AccountTypeRule, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRulesReplay} returns this
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesReplay.prototype.clearAccounttyperulesList = function() {
  return this.setAccounttyperulesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_type_rule_endpoint.AddAccountTypeRuleResponse.ResultCase}
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_type_rule_endpoint.AddAccountTypeRuleResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_type_rule_endpoint.AddAccountTypeRuleResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_type_rule_endpoint.AddAccountTypeRuleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_type_rule_endpoint.AddAccountTypeRuleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_type_rule_endpoint.AddAccountTypeRuleReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_type_rule_endpoint.AddAccountTypeRuleResponse}
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_type_rule_endpoint.AddAccountTypeRuleResponse;
  return proto.account_type_rule_endpoint.AddAccountTypeRuleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_type_rule_endpoint.AddAccountTypeRuleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_type_rule_endpoint.AddAccountTypeRuleResponse}
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_type_rule_endpoint.AddAccountTypeRuleReplay;
      reader.readMessage(value,proto.account_type_rule_endpoint.AddAccountTypeRuleReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_type_rule_endpoint.AddAccountTypeRuleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_type_rule_endpoint.AddAccountTypeRuleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_type_rule_endpoint.AddAccountTypeRuleReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional AddAccountTypeRuleReplay success = 1;
 * @return {?proto.account_type_rule_endpoint.AddAccountTypeRuleReplay}
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_type_rule_endpoint.AddAccountTypeRuleReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_type_rule_endpoint.AddAccountTypeRuleReplay, 1));
};


/**
 * @param {?proto.account_type_rule_endpoint.AddAccountTypeRuleReplay|undefined} value
 * @return {!proto.account_type_rule_endpoint.AddAccountTypeRuleResponse} returns this
*/
proto.account_type_rule_endpoint.AddAccountTypeRuleResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_type_rule_endpoint.AddAccountTypeRuleResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.AddAccountTypeRuleResponse} returns this
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.AddAccountTypeRuleResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse.ResultCase}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse;
  return proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay;
      reader.readMessage(value,proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateAccountTypeRuleReplay success = 1;
 * @return {?proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay, 1));
};


/**
 * @param {?proto.account_type_rule_endpoint.UpdateAccountTypeRuleReplay|undefined} value
 * @return {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse} returns this
*/
proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse} returns this
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse.ResultCase}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse;
  return proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay;
      reader.readMessage(value,proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateAccountTypeRuleActivityReplay success = 1;
 * @return {?proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay, 1));
};


/**
 * @param {?proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityReplay|undefined} value
 * @return {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse} returns this
*/
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse} returns this
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.UpdateAccountTypeRuleActivityResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse.ResultCase}
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse}
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse;
  return proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse}
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay;
      reader.readMessage(value,proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeleteAccountTypeRuleReplay success = 1;
 * @return {?proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay}
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay, 1));
};


/**
 * @param {?proto.account_type_rule_endpoint.DeleteAccountTypeRuleReplay|undefined} value
 * @return {!proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse} returns this
*/
proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse} returns this
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.DeleteAccountTypeRuleResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_type_rule_endpoint.GetAccountTypeRuleResponse.ResultCase}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_type_rule_endpoint.GetAccountTypeRuleResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_type_rule_endpoint.GetAccountTypeRuleResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_type_rule_endpoint.GetAccountTypeRuleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_type_rule_endpoint.GetAccountTypeRuleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRuleResponse}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_type_rule_endpoint.GetAccountTypeRuleResponse;
  return proto.account_type_rule_endpoint.GetAccountTypeRuleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_type_rule_endpoint.GetAccountTypeRuleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRuleResponse}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_type_rule_endpoint.GetAccountTypeRuleReplay;
      reader.readMessage(value,proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_type_rule_endpoint.GetAccountTypeRuleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_type_rule_endpoint.GetAccountTypeRuleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_type_rule_endpoint.GetAccountTypeRuleReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetAccountTypeRuleReplay success = 1;
 * @return {?proto.account_type_rule_endpoint.GetAccountTypeRuleReplay}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_type_rule_endpoint.GetAccountTypeRuleReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_type_rule_endpoint.GetAccountTypeRuleReplay, 1));
};


/**
 * @param {?proto.account_type_rule_endpoint.GetAccountTypeRuleReplay|undefined} value
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRuleResponse} returns this
*/
proto.account_type_rule_endpoint.GetAccountTypeRuleResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_type_rule_endpoint.GetAccountTypeRuleResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRuleResponse} returns this
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.GetAccountTypeRuleResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_type_rule_endpoint.GetAccountTypeRulesResponse.ResultCase}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_type_rule_endpoint.GetAccountTypeRulesResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_type_rule_endpoint.GetAccountTypeRulesResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_type_rule_endpoint.GetAccountTypeRulesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_type_rule_endpoint.GetAccountTypeRulesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_type_rule_endpoint.GetAccountTypeRulesReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRulesResponse}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_type_rule_endpoint.GetAccountTypeRulesResponse;
  return proto.account_type_rule_endpoint.GetAccountTypeRulesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_type_rule_endpoint.GetAccountTypeRulesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRulesResponse}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_type_rule_endpoint.GetAccountTypeRulesReplay;
      reader.readMessage(value,proto.account_type_rule_endpoint.GetAccountTypeRulesReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_type_rule_endpoint.GetAccountTypeRulesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_type_rule_endpoint.GetAccountTypeRulesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_type_rule_endpoint.GetAccountTypeRulesReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetAccountTypeRulesReplay success = 1;
 * @return {?proto.account_type_rule_endpoint.GetAccountTypeRulesReplay}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_type_rule_endpoint.GetAccountTypeRulesReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_type_rule_endpoint.GetAccountTypeRulesReplay, 1));
};


/**
 * @param {?proto.account_type_rule_endpoint.GetAccountTypeRulesReplay|undefined} value
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRulesResponse} returns this
*/
proto.account_type_rule_endpoint.GetAccountTypeRulesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_type_rule_endpoint.GetAccountTypeRulesResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_type_rule_endpoint.GetAccountTypeRulesResponse} returns this
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_type_rule_endpoint.GetAccountTypeRulesResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.account_type_rule_endpoint);
