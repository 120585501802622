
import { CommandBar, Text, Stack, PrimaryButton, DefaultButton, Link, Modal, IconButton, IIconProps, getTheme, mergeStyleSets, FontWeights, IButtonStyles, Label, TextField, Layer, mergeStyles, AnimationClassNames } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/Hooks";
import { isVisible } from "../../common/Settings/SettingsSlice";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Popup } from "../../common/Popup/Popup";
import { LoginForm } from "../../forms/Login/LoginForm";
import { useEffect } from "react";
import { getPage } from "../../../app/Pages";
import { setTitle } from "../../common/NavHeader/NavHeaderSlice";
import { getCurrentLanguage, normalizeKey } from "../../../app/Helpers";
import { CryptoCurrencyMarket, EconomicCalendar, ForexHeatMap, MarketOverview, Screener, Ticker } from "react-ts-tradingview-widgets";


export const TradingView: React.FunctionComponent = () => {
    const stateTheme: { appTheme: string, isDarkModeEnabled: boolean } = useAppSelector((state) => {

        return { appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled }
    })



    return <Stack verticalFill >
        <Ticker symbols={[
            {
                "proName": "FX_IDC:USDLYD",
                "title": "USD/LYD"
            },
            {
                "proName": "FX_IDC:EURLYD",
                "title": "EUR/LYD"
            },
            {
                "proName": "FX_IDC:GBPLYD",
                "title": "GBP/LYD"
            },
            {
                "proName": "FX_IDC:EURUSD",
                "title": "EUR/USD"
            },
            {
                "proName": "GOLD",
                "title": "OZ/USD"
            }
        ]} colorTheme={stateTheme.isDarkModeEnabled ? "dark" : "light"} locale={getCurrentLanguage() == "ar" ? "ar_AE" : "en"}></Ticker>
        <MarketOverview  colorTheme={stateTheme.isDarkModeEnabled ? "dark" : "light"} locale={getCurrentLanguage() == "ar" ? "ar_AE" : "en"} width="100%" showFloatingTooltip></MarketOverview>
        <CryptoCurrencyMarket colorTheme={stateTheme.isDarkModeEnabled ? "dark" : "light"} locale={getCurrentLanguage() == "ar" ? "ar_AE" : "en"} width="100%" height={400}></CryptoCurrencyMarket>
        <Stack horizontal horizontalAlign="space-between" >
            <Stack.Item grow>
                <ForexHeatMap width={"100%"} currencies={["EUR", "USD", "JPY", "GBP", "CHF", "TRY", "CAD", "NZD", "CNY"]} height={400} colorTheme={stateTheme.isDarkModeEnabled ? "dark" : "light"} locale={getCurrentLanguage() == "ar" ? "ar_AE" : "en"}></ForexHeatMap>
            </Stack.Item>
            <Stack.Item grow>

                <EconomicCalendar width={"100%"} colorTheme={stateTheme.isDarkModeEnabled ? "dark" : "light"} locale={getCurrentLanguage() == "ar" ? "ar_AE" : "en"} height={400} ></EconomicCalendar>
            </Stack.Item>

        </Stack>

    </Stack >
};