import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError } from 'grpc-web';
import { RoleEndpointClient } from '../../../../repository/UserManagement/Managers/RoleServiceClientPb';
import { AddRoleRequest, AddRoleResponse, UpdateRoleRequest, UpdateRoleResponse } from '../../../../repository/UserManagement/Managers/role_pb';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, refreshSession, getSession, ACCOUNTANT_API_URL } from '../../../../app/Api';
import { AccountTypeEndpointClient } from '../../../../repository/Accountant/Account_typeServiceClientPb';
import { formatDate, timestampToDate } from '../../../../app/Helpers';
import { DynamicField } from '../../../common/DynamicFields/DynamicFields';
import { FeeEndpointClient } from '../../../../repository/Accountant/FeeServiceClientPb';
import { AddFeeRequest, AddFeeResponse, GetFeeRequest, GetFeeResponse, UpdateFeeRequest, UpdateFeeResponse } from '../../../../repository/Accountant/fee_pb';
export interface FeesFormState {
    isLoading: boolean
    message?: ApiMessage
    stage1: any,
}

const initialState: FeesFormState = {
    isLoading: false,
    message: undefined,
    stage1: undefined,
}

const client = new FeeEndpointClient(ACCOUNTANT_API_URL, null, null);
export const add = createAsyncThunk<AddFeeResponse.AsObject, APIRequest<AddFeeRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/fees/add',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.add(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)


export const update = createAsyncThunk<UpdateFeeResponse.AsObject, APIRequest<UpdateFeeRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/fees/update',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.update(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)



export const get = createAsyncThunk<GetFeeResponse.AsObject, APIRequest<GetFeeRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/fees/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getFee(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)

export const feesFormSlice = createSlice({
    name: 'forms/accountant/fees',
    initialState,
    reducers: {

        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.isLoading = false;
            state.message = undefined;
            state.stage1 = initialState.stage1;
        },
        setStage1: (state, action: PayloadAction<any>) => {
            let stage1 = {
                id: action.payload?.id,
                name: action.payload?.name,
                isAvailable: action.payload?.isAvailable,
                isActive: action.payload?.isActive,
                createdDate: action.payload?.createdDate,
                addedBy: action.payload?.addedBy,
                addedById: action.payload?.addedById,
                feeEntries: action.payload?.feeEntries,
                extraFields: action.payload?.extraFields
            }
            state.stage1 = stage1;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(add.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            if (payload)
                state.message = toApiMessage(payload.success?.message);

        })
        builder.addCase(add.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(add.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })
        builder.addCase(update.fulfilled, (state, { payload }) => {
            if (payload)
                state.message = toApiMessage(payload.success?.message);
            state.isLoading = false;
        })
        builder.addCase(update.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(update.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })

        builder.addCase(get.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {

                let r = {
                    id: payload?.success?.id,
                    name: payload?.success?.name?.value ?? "",
                    isAvailable: payload?.success?.isavailable?.value ?? undefined,
                    isActive: payload?.success?.isactive?.value ?? undefined,
                    feeEntries: [] as any[],
                    collectionAccount: [] as any[],
                    createdDate: formatDate(timestampToDate(payload?.success?.createddate?.seconds, payload?.success?.createddate?.nanos)),
                    addedBy: (payload?.success?.createdby?.firstname?.value ?? "") + " " + (payload?.success?.createdby?.lastname?.value ?? ""),
                    addedById: payload?.success?.createdby?.id?.value,
                    extraFields: [] as DynamicField[]
                }
                if (payload?.success?.extrafieldsList) {
                    let count = 0
                    payload?.success?.extrafieldsList?.forEach(element => {
                        r.extraFields.push({ key: element.key, label: element.label, type: element.type, value: element.value })
                        count++
                    });
                }
                if (payload?.success?.feeentriesList) {
                    payload?.success?.feeentriesList?.forEach(element => {
                        r.feeEntries.push({ activationThreshold: element.activationthreshold, fixedValue: element.fixedvalue, percentageValue: element.percentagevalue })
                    });
                }
                /*if (payload?.success?.collectionaccountsList) {
                    let count = 0
                    payload?.success?.collectionaccountsList?.forEach(element => {
                        r.extraFields.push({ key: element.account?.id, label: element.label, type: element.type, value: element.value })
                        count++
                    });
                }*/

                state.stage1 = r
            }
        })
        builder.addCase(get.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(get.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })
    }
})

export const { reset, dismissMessage, setStage1 } = feesFormSlice.actions

export default feesFormSlice.reducer


