import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, refreshSession, getSession, ACCOUNTANT_API_URL, APIRequest, APIResponse, TRADING_API_URL } from '../../../../../app/Api';
import { AccountTypeEndpointClient } from '../../../../../repository/Accountant/Account_typeServiceClientPb';
import { GetAccountTypesResponse, GetAccountTypesRequest } from '../../../../../repository/Accountant/account_type_pb';
import { formatDate, timestampToDate } from '../../../../../app/Helpers';
import { TableState } from '../../../../common/Table/TableSate';
import { CurrencyEndpointClient } from '../../../../../repository/Accountant/CurrencyServiceClientPb';
import { GetCurrenciesRequest, GetCurrenciesResponse } from '../../../../../repository/Accountant/currency_pb';
import { AddCurrencyPairRequest, AddCurrencyPairResponse, UpdateCurrencyPairPriceRequest, UpdateCurrencyPairPriceResponse, UpdateCurrencyPairResponse, GetCurrencyPairResponse, GetCurrencyPairRequest, UpdateCurrencyPairRequest } from '../../../../../repository/Trading/currency_pair_pb';
import { CurrencyPairPriceLogEndpointClient } from '../../../../../repository/Trading/Currency_pair_price_logServiceClientPb';
import { UpdateCurrencyPairPriceLogRequest, UpdateCurrencyPairPriceLogResponse, GetCurrencyPairPriceLogRequest, GetCurrencyPairPriceLogResponse } from '../../../../../repository/Trading/currency_pair_price_log_pb';


export interface CurrencyPairPriceChangeLogFormState {
    isLoading: boolean
    message?: ApiMessage
    stage1: any,

}

const initialState: CurrencyPairPriceChangeLogFormState = {
    isLoading: false,
    message: undefined,
    stage1: undefined,

}

const client = new CurrencyPairPriceLogEndpointClient(TRADING_API_URL, null, null);



export const update = createAsyncThunk<UpdateCurrencyPairPriceLogResponse.AsObject, APIRequest<UpdateCurrencyPairPriceLogRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/trading/currencyPair/currencyPairPriceLogs/update',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.update(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)



export const get = createAsyncThunk<GetCurrencyPairPriceLogResponse.AsObject, APIRequest<GetCurrencyPairPriceLogRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/trading/currencyPair/currencyPairPriceLogs/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getCurrencyPairPriceLog(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)


export const currencyPairPriceChangeLogFormSlice = createSlice({
    name: 'forms/trading/currencyPairs/currencyPairPriceLogs',
    initialState,
    reducers: {

        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.isLoading = false;
            state.message = undefined;
            state.stage1 = initialState.stage1;
        },
        setStage1: (state, action: PayloadAction<any>) => {
            let stage1 = {
                id: action.payload.id,
                name: action.payload.name,
                buyPrice: action.payload.buyPrice,
                sellPrice: action.payload.sellPrice,
                tradingPrice: action.payload.tradingPrice,
                isActive: action.payload.isActive,
                baseCurrencyId: action.payload.baseCurrencyId,
                baseCurrencyName: action.payload.baseCurrencyName,
                baseCurrencyCode: action.payload.baseCurrencyCode,
                baseCurrencyDecimalPlaces: action.payload.baseCurrencyDecimalPlaces,
                baseCurrencyForm: action.payload.baseCurrencyForm,
                baseCurrencySymbol: action.payload.baseCurrencySymbol,
                quoteCurrencyId: action.payload.quoteCurrencyId,
                quoteCurrencyName: action.payload.quoteCurrencyName,
                quoteCurrencyCode: action.payload.quoteCurrencyCode,
                quoteCurrencyDecimalPlaces: action.payload.quoteCurrencyDecimalPlaces,
                quoteCurrencyForm: action.payload.quoteCurrencyForm,
                quoteCurrencySymbol: action.payload.quoteCurrencySymbol,
                priceUpdate: action.payload.priceUpdate,
                createdDate: action.payload.createdDate,
                addedBy: action.payload.addedBy,
                addedById: action.payload.addedById,

                currencyPairId: action.payload.currencyPairId,
                currencyPairName: action.payload.currencyPairName,
                trigger: action.payload.trigger,
                volumeBase: action.payload.volumeBase,
                volumeQuote: action.payload.volumeQuote,

            }
            state.stage1 = stage1;
        },





    },
    extraReducers: (builder) => {




        builder.addCase(update.fulfilled, (state, { payload }) => {
            if (payload)
                state.message = toApiMessage(payload.success?.message);
            state.isLoading = false;
        })
        builder.addCase(update.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(update.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })

        builder.addCase(get.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {

                let r = {
                    id: payload.success?.id,
                    buyPrice: payload.success?.buyprice?.value,
                    sellPrice: payload.success?.sellprice?.value ?? undefined,
                    tradingPrice: payload.success?.tradingprice?.value ?? undefined,
                    currencyPairId: payload.success?.currencypair?.id?.value ?? "",
                    currencyPairName: payload.success?.currencypair?.name?.value ?? "",
                    baseCurrencyId: payload.success?.currencypair?.base?.id?.value ?? "",
                    baseCurrencyName: payload.success?.currencypair?.base?.name?.value ?? "",
                    baseCurrencyCode: payload.success?.currencypair?.base?.code?.value ?? "",
                    baseCurrencyDecimalPlaces: payload.success?.currencypair?.base?.decimalplaces?.value ?? undefined,
                    baseCurrencyForm: payload.success?.currencypair?.base?.form?.value ?? undefined,
                    baseCurrencySymbol: payload.success?.currencypair?.base?.symbol?.value ?? "",
                    quoteCurrencyId: payload.success?.currencypair?.quote?.id?.value ?? "",
                    quoteCurrencyName: payload.success?.currencypair?.quote?.name?.value ?? "",
                    quoteCurrencyCode: payload.success?.currencypair?.quote?.code?.value ?? "",
                    quoteCurrencyDecimalPlaces: payload.success?.currencypair?.quote?.decimalplaces?.value ?? undefined,
                    quoteCurrencyForm: payload.success?.currencypair?.quote?.form?.value ?? undefined,
                    quoteCurrencySymbol: payload.success?.currencypair?.quote?.symbol?.value ?? "",
                    trigger: payload.success?.trigger?.value ?? undefined,
                    volumeBase: payload.success?.volumebase?.value ?? undefined,
                    volumeQuote: payload.success?.volumequote?.value ?? undefined,
                    createdDate: formatDate(timestampToDate(payload.success?.createddate?.seconds, payload.success?.createddate?.nanos)),
                    addedBy: (payload.success?.createdby?.firstname?.value ?? "") + " " + (payload.success?.createdby?.lastname?.value ?? ""),
                    addedById: payload.success?.createdby?.id?.value,
                    

                }



                state.stage1 = r
            }
        })
        builder.addCase(get.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(get.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })





    }
})

export const { reset, dismissMessage, setStage1, } = currencyPairPriceChangeLogFormSlice.actions

export default currencyPairPriceChangeLogFormSlice.reducer


