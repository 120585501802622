import { ComboBox, DefaultButton, DialogFooter, IComboBoxOption, Icon, IconButton, IStackTokens, ISuggestionItemProps, ISuggestionModel, ISuggestionsProps, ITag, Label, MessageBar, MessageBarType, Modal, Pivot, PivotItem, PrimaryButton, Separator, Spinner, SpinnerSize, Stack, StackItem, TagItemSuggestion, TagPicker, Text, TextField, Toggle, TooltipHost, values } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldArray, FieldProps, Form, Formik, getIn } from "formik";
import { FormEventHandler, InputHTMLAttributes, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks";
import { add, dismissMessage, getCustomer, getOrganization, getRoles, getUserTypes, OrganizationState, reset, resetRoles, resetUserTypes, setCurrentOrganization, setIsFilteredRolesSet, setSelectedRole, setSelectedUserType, setStage1, setStage2, setStage3, update, updateUsername } from "./UsersFormSlice";
import * as Yup from 'yup';
import { DynamicInputField, InputField } from "../../controls/Controls";
import { inputs } from "../../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../../app/Api";
import { Message } from "../../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../../FormProps";
import { Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog";
import { formatDate, timestampToDate } from "../../../../app/Helpers";
import { AuthenticateReply } from "../../../../repository/UserManagement/authentication_pb";
import { AddUserRequest, AddUserResponse, UpdateUsernameResponse, GetUserRequest, UpdateUsernameRequest, UpdateUserRequest, UpdateUserResponse } from "../../../../repository/UserManagement/Customers/user_pb";
import { Address, ExtraField } from "../../../../repository/UserManagement/common_pb";
import { TableState } from "../../../common/Table/TableSate";
import { GetUserTypesRequest } from "../../../../repository/UserManagement/Managers/usertype_pb";
import { GetRolesRequest } from "../../../../repository/UserManagement/Managers/role_pb";
import { setIsFilteredUserTypesSet } from "../../../pages/Management/Users/UsersPageSlice";
import { DynamicField } from "../../../common/DynamicFields/DynamicFields";
import { GetOrganizationRequest } from "../../../../repository/UserManagement/organization_pb";
import { countriesAR, countriesEn } from "../../../../app/Content";


let req: UpdateUsernameRequest;


let promise: any;

let org: number = -1;
let current: AuthenticateReply.AsObject;

export const UsernameUpdateForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();

    const state: { isLoading: boolean, message: ApiMessage | undefined, organization?: OrganizationState, stage1: any, stage2: any, stage3: any } = useAppSelector((state) => {

        return {
            isLoading: state.customersUsersForm.isLoading, message: state.customersUsersForm.message, organization: state.customersUsersForm.currentOrganization
            , stage1: state.customersUsersForm.stage1
            , stage2: state.customersUsersForm.stage2
            , stage3: state.customersUsersForm.stage3

        }
    })


    useEffect(() => {

        req = new UpdateUsernameRequest();

        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        } else {

            var l = searchParams.get('org');
            let getOrgReq = new GetOrganizationRequest()
            if (l) {
                let org = Number(l);
                if (!Number.isNaN(org) && org != current.organization?.id) {
                    if (props.type == FormType.EDIT) {
                        getOrgReq.setId(org)
                        promise?.abort()
                        promise = dispatch(getOrganization({ body: getOrgReq, headers: getHeaders() }))
                        /*promise.unwrap().catch((e: any) => {
                            if ((current.organization?.domain == undefined || current.organization?.domain.value.length == 0) && current.organization?.id) {
                                getOrgReq.setId(current.organization?.id)
                                promise?.abort()
                                promise = dispatch(getOrganization({ body: getOrgReq, headers: getHeaders() }))
                                promise.unwrap().catch((e: any) => {
                                    dispatch(setCurrentOrganization({ id: current.organization?.id ?? -1, domain: current.organization?.domain?.value ?? "", name: current.organization?.name?.value ?? "" }));
                                })
                            } else {
                                dispatch(setCurrentOrganization({ id: current.organization?.id ?? -1, domain: current.organization?.domain?.value ?? "", name: current.organization?.name?.value ?? "" }));
                            }
                        })*/
                    } else {
                        dispatch(setCurrentOrganization({ id: org, domain: "", name: "" }));
                    }

                }
            } else {
                if (props.type == FormType.EDIT) {
                    if ((current.organization?.domain == undefined || current.organization?.domain.value.length == 0) && current.organization?.id) {
                        getOrgReq.setId(current.organization?.id)
                        promise?.abort()
                        promise = dispatch(getOrganization({ body: getOrgReq, headers: getHeaders() }))
                        promise.unwrap().catch((e: any) => {
                            dispatch(setCurrentOrganization({ id: current.organization?.id ?? -1, domain: current.organization?.domain?.value ?? "", name: current.organization?.name?.value ?? "" }));
                        })
                    } else {
                        dispatch(setCurrentOrganization({ id: current.organization?.id ?? -1, domain: current.organization?.domain?.value ?? "", name: current.organization?.name?.value ?? "" }));
                    }
                } else {
                    dispatch(setCurrentOrganization({ id: current.organization?.id ?? -1, domain: current.organization?.domain?.value ?? "", name: current.organization?.name?.value ?? "" }));
                }
            }

            if (props.type == FormType.EDIT) {
                if (props.fetchData) {
                    let r = new GetUserRequest()
                    r.setId(props?.renderObject?.internalId);
                    promise?.abort()
                    promise = dispatch(getCustomer({ body: r, headers: getHeaders() }))

                } else {
                    dispatch(setStage1(props?.renderObject))
                    dispatch(setStage2(props?.renderObject))
                    dispatch(setStage3(props?.renderObject))
                }
            }
        }


        return () => { //clean up
            promise?.abort();
            dispatch(reset());
        }
    }, [])


    return (
        <>

            <Formik

                initialValues={{

                    username: (props.renderObject?.username) ? props.renderObject.username.split("@")[0] : '',

                }}



                onSubmit={(values, actions) => {
                    if (props.type == FormType.EDIT) {
                        req.setProfileid(props.renderObject?.internalId)
                        req.setUsername(values.username.trim().toLowerCase())
                        promise = dispatch(updateUsername({ body: req, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: UpdateUsernameResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            internalId: props.renderObject?.internalId,
                                            username: values.username.trim().toLowerCase()+ "@" + state.organization?.domain,
                                        });
                                    }
                                }
                                actions.setSubmitting(false)

                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })


                    }
                }}

            >
                {formkikProps => (
                    <Form autoComplete="off">

                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? (state.message.data != 401) ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            /> :
                                <SessionExpiredDialog />
                                : null
                            }
                            {props.type == FormType.EDIT ? <Stack.Item dir="ltr">
                                <Field name="username" label={t("username")} placeholder={t("username")} validate={async (e: string) => {
                                    try {
                                        await inputs.username.validate(e + "@" + state.organization?.domain)
                                    } catch (e: any) {
                                        return (e?.message)
                                    }


                                }} component={InputField} suffix={state.organization?.domain ? "@" + state.organization?.domain : ""} autoComplete="one-time-code" disabled={state.isLoading} maxLength={50} />
                            </Stack.Item>
                                : null}

                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("add") : t("edit")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={props.type == FormType.ADD ? t("discard") : t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>


                    </Form>
                )
                }
            </Formik >
        </>
    );
}




