import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, refreshSession, getSession, ACCOUNTANT_API_URL, TRADING_API_URL } from '../../../../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../../../../app/Helpers';
import { CurrencyPairEndpointClient } from '../../../../../../../repository/Trading/Currency_pairServiceClientPb';
import { OrderEndpointClient } from '../../../../../../../repository/Trading/OrderServiceClientPb';
import { GetOrderRequest, GetOrderResponse, GetOrderTransactionRequest, GetOrderTransactionResponse } from '../../../../../../../repository/Trading/order_pb';
import { DynamicField } from '../../../../../../common/DynamicFields/DynamicFields';


export interface OrderTransactionDetailsPageState {
    item: any
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    isLoading: boolean

}

const initialState: OrderTransactionDetailsPageState = {
    item: undefined,
    isChangeStateLoading: false,
    message: undefined,
    isLoading: false
}

const client = new OrderEndpointClient(TRADING_API_URL, null, null);

export const get = createAsyncThunk<GetOrderTransactionResponse.AsObject, APIRequest<GetOrderTransactionRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/trading/orderDetails/orderTransactionDetails/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getOrderTransaction(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)




export const orderTransactionDetailsPageSlice = createSlice({
    name: 'pages/trading/orderDetails/orderTransactionDetails',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
        },

        resetItem: (state) => {
            state.item = undefined;

        },

        updateItem: (state, action: PayloadAction<any>) => {
            state.item = action.payload;

        }
    },
    extraReducers: (builder) => {
       
        builder.addCase(get.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {
                let val = payload.success;
                let r = {
                    id: val?.id,
                    fromAccountInternalId: val?.fromaccount?.internalid?.value ?? "",
                    fromAccountName: val?.fromaccount?.name?.value ?? "",
                    fromAccountId: val?.fromaccount?.id?.value ?? "",
                    toAccountInternalId: val?.toaccount?.internalid?.value ?? "",
                    toAccountName: val?.toaccount?.name?.value ?? "",
                    toAccountId: val?.toaccount?.id?.value ?? "",
                    currencyId: val?.currency?.id?.value ?? "",
                    currencyName: val?.currency?.name?.value ?? "",
                    currencyCode: val?.currency?.code?.value ?? "",
                    currencyDecimalPlaces: val?.currency?.decimalplaces?.value ?? undefined,
                    currencyForm: val?.currency?.form?.value ?? undefined,
                    currencySymbol: val?.currency?.symbol?.value ?? "",
                    clientId: val?.client?.id?.value,
                    clientName: (val?.client?.firstname?.value ?? "") + " " + (val?.client?.lastname?.value ?? ""),
                    createdDate: formatDate(timestampToDate(val?.createddate?.seconds, val?.createddate?.nanos)),
                    addedBy: (val?.createdby?.firstname?.value ?? "") + " " + (val?.createdby?.lastname?.value ?? ""),
                    addedById: val?.createdby?.id?.value,
                    amount: val?.amount?.value,
                    referenceTransactionId: val?.referencetransaction?.id?.value,
                    referenceTransactionInternalId: val?.referencetransaction?.internalid?.value,
                    orderTradeAction: val?.order?.tradeaction?.value,
                    referenceOrderTradeAction: val?.referenceorder?.tradeaction?.value,
                    orderId: val?.order?.id?.value ?? undefined,
                    referenceOrderId: val?.referenceorder?.id?.value ?? undefined,

                }


                state.item = r
            }
        })
        builder.addCase(get.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(get.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })
    }
})

export const { reset, dismissMessage, resetItem, updateItem } = orderTransactionDetailsPageSlice.actions

export default orderTransactionDetailsPageSlice.reducer