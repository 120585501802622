import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import ar from '../locales/ar/translation.json';
import en from '../locales/en/translation.json';

export const resources = {
    ar: {
        translation: ar,
    },
    en: {
        translation: en,
    },
} as const;


export const defaultNS = "translation";
i18next
    .use(initReactI18next)
    .init({
        lng: localStorage.getItem("lang") ?? "ar",
        fallbackLng: 'ar',
        debug: true,
        defaultNS: "translation",
        ns: ["translation"],
        returnNull: false,
        resources: resources,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });




export default i18next;