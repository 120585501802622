import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ISearchBoxProps, IPeoplePickerItemSelectedProps, PeoplePickerItem, ValidationState, NormalPeoplePicker, IPersonaProps, IPickerItemProps, DialogFooter, DatePicker, DayOfWeek, defaultDatePickerStrings, CheckboxVisibility, ConstrainMode } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks"
import { getPage } from "../../../../app/Pages"
import { setPage } from "../../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../../common/Table/Table"
import { reset, dismissMessage, getItems, resetSet, setSelectedCurrencyPairsItems, resetCurrencyPairsSet, setMessage, setIsFilteredCurrencyPairSet, getCurrencyPairs, exportReport } from "./CurrencyExchangeRatesReportPageSlice"
import { ApiMessage, getHeaders } from "../../../../app/Api"
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { currencyFormatter, formatDate, printComponent, normalizeKey } from "../../../../app/Helpers"
import { TableState } from "../../../common/Table/TableSate"
import { Message } from "../../../common/Message/Message"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { bool, string } from "yup"
import { Popup } from "../../../common/Popup/Popup"
import { GetCurrenciesRequest } from "../../../../repository/Accountant/currency_pb"
import { useReactToPrint } from "react-to-print"
import { PageProps } from "../../PageProps"
import { List } from "../../../common/List/List"
import { GetAccountBalanceReportRequest, GetTreasuryBalanceReportRequest } from "../../../../repository/Accountant/report_pb"
import { GetTreasuriesRequest } from "../../../../repository/Accountant/treasury_pb"
import { TreasuriesPage } from "../../Accountant/Treasuries/TreasuriesPage"
import { CurrenciesPage } from "../../Accountant/Currencies/CurrenciesPage"
import moment from "moment"
import { Form, Formik } from "formik"
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb"
import { AccountStatus, TreasuryStatus } from "../../../../app/Enums"
import { inputs } from "../../../../app/Validation"
import * as Yup from 'yup';
import { GetAccountsRequest } from "../../../../repository/Accountant/account_pb"
import { AccountsPage } from "../../Accountant/Accounts/AccountsPage"
import { ExportCurrencyExchangeRatesReportRequest, GetCurrencyExchangeRatesReportRequest } from "../../../../repository/Trading/report_pb"
import { GetCurrencyPairsRequest } from "../../../../repository/Trading/currency_pair_pb"
import { CurrencyPairsPage } from "../../Trading/CurrencyPairs/CurrencyPairsPage"
import report from "../../../../repository/Accountant/report_pb"

let req: GetCurrencyExchangeRatesReportRequest;
let currecyPairsReq: GetCurrencyPairsRequest;
let exportReq: ExportCurrencyExchangeRatesReportRequest;


let org: number = -1;

let getItemsPromise: any;
let getFilterPromise: any;
let actionPromise: any;

const key = "reports/currencyExchangeRatesReport"
export const CurrencyExchangeRatesReportPage: React.FunctionComponent<PageProps> = (props) => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const state: {
        set: TableState,
        message: ApiMessage | undefined,
        appTheme: string, isDarkModeEnabled: boolean,
        currencyPairs: TableState
        isChangeStateLoading: boolean

    } = useAppSelector((state) => {
        return {
            set: state.currencyExchangeRatesReportPage.set,
            isChangeStateLoading: state.currencyExchangeRatesReportPage.isChangeStateLoading,
            message: state.currencyExchangeRatesReportPage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            currencyPairs: state.currencyExchangeRatesReportPage.currencyPairs,

        }
    })

    const [currentAction, setCurrenctAction] = useState(0)
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 1);
    const ref = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => {
            return printComponent(ref.current?.cloneNode(true), true)
        }

    });


    useEffect(() => {
        req = new GetCurrencyExchangeRatesReportRequest();
        currecyPairsReq = new GetCurrencyPairsRequest();

        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                currecyPairsReq.setOrganization(wrapper)

            }
        }

        if (!props.isSelection) {
            const page = getPage(key)
            dispatch(setTitle(t(normalizeKey(page?.title))));
            dispatch(setPage(page));
        }

        currecyPairsReq.setNumofresults(state.currencyPairs.numberOfResults)
        currecyPairsReq.setOrder(state.currencyPairs.isDescending)



        return () => { //clean up
            getItemsPromise?.abort();
            getFilterPromise?.abort();
            actionPromise?.abort();
            dispatch(reset());
        }
    }, [])



    const all: IColumn[] = [


        {
            key: 'date',
            name: t("date"),
            minWidth: 50,
            maxWidth: 140,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.date != undefined)
                    return <Text>{(item?.date)}</Text>; //{item.createdDate}
            },
        },
        {
            key: 'openBuyPrice',
            name: t("openBuyPrice"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.openBuyPrice != undefined)
                    return <Text>{currencyFormatter(item?.openBuyPrice, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;
            },
        },

        {
            key: 'openSellprice',
            name: t("openSellprice"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.openSellprice != undefined)
                    return <Text>{currencyFormatter(item?.openSellprice, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;
            },
        },

        {
            key: 'openAvgPrice',
            name: t("openAvgPrice"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.openAvgPrice != undefined)
                    return <Text>{currencyFormatter(item?.openAvgPrice, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;

            },
        },

        {
            key: 'openTradingPrice',
            name: t("openTradingPrice"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.openTradingPrice != undefined)
                    return <Text>{currencyFormatter(item?.openTradingPrice, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;

            },
        },
        {
            key: 'highestBuyPrice',
            name: t("highestBuyPrice"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.highestBuyPrice != undefined)
                    return <Text>{currencyFormatter(item?.highestBuyPrice, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;

            },
        },


        {
            key: 'lowsetBuyPrice',
            name: t("lowsetBuyPrice"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.lowsetBuyPrice != undefined)
                    return <Text>{currencyFormatter(item?.lowsetBuyPrice, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;
            },
        },




        {
            key: 'highestSellPrice',
            name: t("highestSellPrice"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.highestSellPrice != undefined)
                    return <Text>{currencyFormatter(item?.highestSellPrice, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;

            },
        },


        {
            key: 'lowsetSellPrice',
            name: t("lowsetSellPrice"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.lowsetSellPrice != undefined)
                    return <Text>{currencyFormatter(item?.lowsetSellPrice, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.currency?.symbol })}</Text>;
            },
        },


        {
            key: 'highestAVGPrice',
            name: t("highestAVGPrice"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.highestAVGPrice != undefined)
                    return <Text>{currencyFormatter(item?.highestAVGPrice, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;

            },
        },


        {
            key: 'lowsetAVGPrice',
            name: t("lowsetAVGPrice"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.lowsetAVGPrice != undefined)
                    return <Text>{currencyFormatter(item?.lowsetAVGPrice, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;
            },
        },



        {
            key: 'highestTradingPrice',
            name: t("highestTradingPrice"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.highestTradingPrice != undefined)
                    return <Text>{currencyFormatter(item?.highestTradingPrice, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;

            },
        },


        {
            key: 'lowsetTradingPrice',
            name: t("lowsetTradingPrice"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.lowsetTradingPrice != undefined)
                    return <Text>{currencyFormatter(item?.lowsetTradingPrice, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;
            },
        },

        {
            key: 'closeAvgPrice',
            name: t("closeAvgPrice"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.closeAvgPrice != undefined)
                    return <Text>{currencyFormatter(item?.closeAvgPrice, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;

            },
        },


        {
            key: 'closeBuyPrice',
            name: t("closeBuyPrice"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.closeBuyPrice != undefined)
                    return <Text>{currencyFormatter(item?.closeBuyPrice, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;
            },
        },

        {
            key: 'closeSellPrice',
            name: t("closeSellPrice"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.closeSellPrice != undefined)
                    return <Text>{currencyFormatter(item?.closeSellPrice, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;
            },
        },

        {
            key: 'closeTradingPrice',
            name: t("closeTradingPrice"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.closeTradingPrice != undefined)
                    return <Text>{currencyFormatter(item?.closeTradingPrice, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;
            },
        },

        {
            key: 'volumeBase',
            name: t("volumeBase"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.volumeBase != undefined)
                    return <Text>{currencyFormatter(item?.volumeBase, { significantDigits: item?.baseCurrency?.decimalPlaces ?? 9, symbol: item?.baseCurrency?.symbol })}</Text>;

            },
        },


        {
            key: 'volumeQuote',
            name: t("volumeQuote"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.volumeQuote != undefined)
                    return <Text>{currencyFormatter(item?.volumeQuote, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;
            },
        },




    ];

    const sell: IColumn[] = [


        {
            key: 'date',
            name: t("date"),
            minWidth: 50,
            maxWidth: 140,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.date != undefined)
                    return <Text>{(item?.date)}</Text>; //{item.createdDate}
            },
        },


        {
            key: 'openSellprice',
            name: t("openSellprice"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.openSellprice != undefined)
                    return <Text>{currencyFormatter(item?.openSellprice, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;
            },
        },







        {
            key: 'highestSellPrice',
            name: t("highestSellPrice"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.highestSellPrice != undefined)
                    return <Text>{currencyFormatter(item?.highestSellPrice, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;

            },
        },


        {
            key: 'lowsetSellPrice',
            name: t("lowsetSellPrice"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.lowsetSellPrice != undefined)
                    return <Text>{currencyFormatter(item?.lowsetSellPrice, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.currency?.symbol })}</Text>;
            },
        },


        {
            key: 'closeSellPrice',
            name: t("closeSellPrice"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.closeSellPrice != undefined)
                    return <Text>{currencyFormatter(item?.closeSellPrice, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;
            },
        },
        {
            key: 'volumeBase',
            name: t("volumeBase"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.volumeBase != undefined)
                    return <Text>{currencyFormatter(item?.volumeBase, { significantDigits: item?.baseCurrency?.decimalPlaces ?? 9, symbol: item?.baseCurrency?.symbol })}</Text>;

            },
        },


        {
            key: 'volumeQuote',
            name: t("volumeQuote"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.volumeQuote != undefined)
                    return <Text>{currencyFormatter(item?.volumeQuote, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;
            },
        },




    ];


    const buy: IColumn[] = [


        {
            key: 'date',
            name: t("date"),
            minWidth: 50,
            maxWidth: 140,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.date != undefined)
                    return <Text>{(item?.date)}</Text>; //{item.createdDate}
            },
        },
        {
            key: 'openBuyPrice',
            name: t("openBuyPrice"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.openBuyPrice != undefined)
                    return <Text>{currencyFormatter(item?.openBuyPrice, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;
            },
        },



        {
            key: 'highestBuyPrice',
            name: t("highestBuyPrice"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.highestBuyPrice != undefined)
                    return <Text>{currencyFormatter(item?.highestBuyPrice, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;

            },
        },


        {
            key: 'lowsetBuyPrice',
            name: t("lowsetBuyPrice"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.lowsetBuyPrice != undefined)
                    return <Text>{currencyFormatter(item?.lowsetBuyPrice, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;
            },
        },



        {
            key: 'closeBuyPrice',
            name: t("closeBuyPrice"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.closeBuyPrice != undefined)
                    return <Text>{currencyFormatter(item?.closeBuyPrice, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;
            },
        },


        {
            key: 'volumeBase',
            name: t("volumeBase"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.volumeBase != undefined)
                    return <Text>{currencyFormatter(item?.volumeBase, { significantDigits: item?.baseCurrency?.decimalPlaces ?? 9, symbol: item?.baseCurrency?.symbol })}</Text>;

            },
        },


        {
            key: 'volumeQuote',
            name: t("volumeQuote"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.volumeQuote != undefined)
                    return <Text>{currencyFormatter(item?.volumeQuote, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;
            },
        },




    ];
    const avg: IColumn[] = [


        {
            key: 'date',
            name: t("date"),
            minWidth: 50,
            maxWidth: 140,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.date != undefined)
                    return <Text>{(item?.date)}</Text>; //{item.createdDate}
            },
        },


        {
            key: 'openAvgPrice',
            name: t("openAvgPrice"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.openAvgPrice != undefined)
                    return <Text>{currencyFormatter(item?.openAvgPrice, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;

            },
        },





        {
            key: 'highestAVGPrice',
            name: t("highestAVGPrice"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.highestAVGPrice != undefined)
                    return <Text>{currencyFormatter(item?.highestAVGPrice, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;

            },
        },


        {
            key: 'lowsetAVGPrice',
            name: t("lowsetAVGPrice"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.lowsetAVGPrice != undefined)
                    return <Text>{currencyFormatter(item?.lowsetAVGPrice, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;
            },
        },

        {
            key: 'closeAvgPrice',
            name: t("closeAvgPrice"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.closeAvgPrice != undefined)
                    return <Text>{currencyFormatter(item?.closeAvgPrice, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;

            },
        },



        {
            key: 'volumeBase',
            name: t("volumeBase"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.volumeBase != undefined)
                    return <Text>{currencyFormatter(item?.volumeBase, { significantDigits: item?.baseCurrency?.decimalPlaces ?? 9, symbol: item?.baseCurrency?.symbol })}</Text>;

            },
        },


        {
            key: 'volumeQuote',
            name: t("volumeQuote"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.volumeQuote != undefined)
                    return <Text>{currencyFormatter(item?.volumeQuote, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;
            },
        },




    ];

    const trading: IColumn[] = [


        {
            key: 'date',
            name: t("date"),
            minWidth: 50,
            maxWidth: 140,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.date != undefined)
                    return <Text>{(item?.date)}</Text>; //{item.createdDate}
            },
        },


        {
            key: 'openTradingPrice',
            name: t("openTradingPrice"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.openTradingPrice != undefined)
                    return <Text>{currencyFormatter(item?.openTradingPrice, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;

            },
        },

        {
            key: 'highestTradingPrice',
            name: t("highestTradingPrice"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.highestTradingPrice != undefined)
                    return <Text>{currencyFormatter(item?.highestTradingPrice, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;

            },
        },


        {
            key: 'lowsetTradingPrice',
            name: t("lowsetTradingPrice"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.lowsetTradingPrice != undefined)
                    return <Text>{currencyFormatter(item?.lowsetTradingPrice, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;
            },
        },

        {
            key: 'closeTradingPrice',
            name: t("closeTradingPrice"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.closeTradingPrice != undefined)
                    return <Text>{currencyFormatter(item?.closeTradingPrice, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;
            },
        },
        {
            key: 'volumeBase',
            name: t("volumeBase"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.volumeBase != undefined)
                    return <Text>{currencyFormatter(item?.volumeBase, { significantDigits: item?.baseCurrency?.decimalPlaces ?? 9, symbol: item?.baseCurrency?.symbol })}</Text>;

            },
        },


        {
            key: 'volumeQuote',
            name: t("volumeQuote"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.volumeQuote != undefined)
                    return <Text>{currencyFormatter(item?.volumeQuote, { significantDigits: item?.quoteCurrency?.decimalPlaces ?? 9, symbol: item?.quoteCurrency?.symbol })}</Text>;
            },
        },




    ];

    const onPairsFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetCurrencyPairsSet())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        currecyPairsReq.setSearch(wrapper)
        currecyPairsReq.setNextto(undefined)
        dispatch(setIsFilteredCurrencyPairSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getCurrencyPairs({ body: currecyPairsReq, headers: getHeaders() }))
        return [];

    }

    return (
        <Stack >

            <Popup isOpen={(currentAction == 1000)} title={t("pair")} onDismiss={() => { setCurrenctAction(0) }} >

                <CurrencyPairsPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedCurrencyPairsItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }


            <Formik
                enableReinitialize={true}
                initialValues={{
                    from: moment(fromDate, 'YYYY-MM-DD').startOf('day').toDate(),
                    to: moment(new Date(), 'YYYY-MM-DD').endOf('day').toDate(),
                    group: { key: -1, text: t("unspecified") },
                    priceView: { key: 0, text: t("all") },
                }}


                onSubmit={(values, actions) => {

                    let currencyPair = state.currencyPairs.selected.at(0);

                    req.setCurrencypair(currencyPair.id);
                    const wrapper = new Timestamp();
                    wrapper.fromDate(moment(values.from, 'YYYY-MM-DD').startOf('day').toDate())
                    req.setFrom(wrapper)

                    const wrapperTo = new Timestamp();
                    wrapperTo.fromDate(moment(values.to, 'YYYY-MM-DD').endOf('day').toDate())
                    req.setTo(wrapperTo)

                    if (values.group.key == -1) {
                        req.setGroupby(undefined)

                    } else {
                        const wrap = new Int32Value();
                        wrap.setValue(values.group.key)
                        req.setGroupby(wrap)
                    }


                    exportReq = new ExportCurrencyExchangeRatesReportRequest();
                    exportReq.setCurrencypair(req.getCurrencypair());
                    exportReq.setFrom(req.getFrom())
                    exportReq.setTo(req.getTo())
                    exportReq.setGroupby(req.getGroupby())


                    const labels: report.Label[] = [];
                    let r = new report.Label();
                    let value = new StringValue();
                    value.setValue(formatDate(moment(values.from, 'YYYY-MM-DD').startOf('day').toDate()))
                    r.setLabelvalue(t("from"));
                    r.setValue(value)
                    r.setPropartyname("from")
                    labels.push(r)

                    r = new report.Label();
                    value = new StringValue();
                    value.setValue(formatDate(moment(values.to, 'YYYY-MM-DD').endOf('day').toDate()))
                    r.setLabelvalue(t("to"));
                    r.setValue(value)
                    r.setPropartyname("to")
                    labels.push(r)

                    r = new report.Label();
                    value = new StringValue();
                    value.setValue(values.group.text)
                    r.setLabelvalue(t("grouping"));
                    r.setValue(value)
                    r.setPropartyname("groupby")
                    labels.push(r)

                    r = new report.Label();
                    value = new StringValue();
                    value.setValue(currencyPair.name)
                    r.setLabelvalue(t("pair"));
                    r.setValue(value)
                    r.setPropartyname("pair")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("generatedDate"));
                    r.setPropartyname("generateddate")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("generatedBy"));
                    r.setPropartyname("generatedby")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("date"));
                    r.setPropartyname("date")
                    labels.push(r)



                    r = new report.Label();
                    r.setLabelvalue(t("openBuyPrice"));
                    r.setPropartyname("openBuyPrice")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("openSellprice"));
                    r.setPropartyname("openSellprice")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("openTradingPrice"));
                    r.setPropartyname("openTradingPrice")
                    labels.push(r)



                    r = new report.Label();
                    r.setLabelvalue(t("openAvgPrice"));
                    r.setPropartyname("openAvgPrice")
                    labels.push(r)


                    r = new report.Label();
                    r.setLabelvalue(t("highestBuyPrice"));
                    r.setPropartyname("highestBuyPrice")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("highestSellPrice"));
                    r.setPropartyname("highestSellPrice")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("lowsetSellPrice"));
                    r.setPropartyname("lowsetSellPrice")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("lowsetBuyPrice"));
                    r.setPropartyname("lowsetBuyPrice")
                    labels.push(r)



                    r = new report.Label();
                    r.setLabelvalue(t("highestAVGPrice"));
                    r.setPropartyname("highestAVGPrice")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("lowsetAVGPrice"));
                    r.setPropartyname("lowsetAVGPrice")
                    labels.push(r)



                    r = new report.Label();
                    r.setLabelvalue(t("highestTradingPrice"));
                    r.setPropartyname("highestTradingPrice")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("lowsetTradingPrice"));
                    r.setPropartyname("lowsetTradingPrice")
                    labels.push(r)


                    r = new report.Label();
                    r.setLabelvalue(t("closeAvgPrice"));
                    r.setPropartyname("closeAvgPrice")
                    labels.push(r)


                    r = new report.Label();
                    r.setLabelvalue(t("closeBuyPrice"));
                    r.setPropartyname("closeBuyPrice")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("closeSellPrice"));
                    r.setPropartyname("closeSellPrice")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("closeTradingPrice"));
                    r.setPropartyname("closeTradingPrice")
                    labels.push(r)


                    r = new report.Label();
                    r.setLabelvalue(t("volumeBase"));
                    r.setPropartyname("volumeBase")
                    labels.push(r)


                    r = new report.Label();
                    r.setLabelvalue(t("volumeQuote"));
                    r.setPropartyname("volumeQuote")
                    labels.push(r)



                    r = new report.Label();
                    value = new StringValue();
                    value.setValue((((new Date()).getTimezoneOffset()) * -1) + "")
                    r.setValue(value);
                    r.setPropartyname("timezone")
                    labels.push(r)


                    exportReq.setLabelsList(labels)




                    getItemsPromise?.abort()
                    getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))


                }}

            >
                {formkikProps => (
                    <Form>

                        <Stack tokens={{ childrenGap: 10, padding: "0px 33% 10px 3%" }}>
                            <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 10 }}>
                                <Stack.Item grow>
                                    <DatePicker
                                        isRequired
                                        firstDayOfWeek={DayOfWeek.Sunday}
                                        label={t("from")}
                                        placeholder={t("from")}
                                        disabled={state.set.isFetching}
                                        value={formkikProps.values.from}
                                        ariaLabel={t("from")}
                                        formatDate={(e) => {
                                            return formatDate(e, 'YYYY-MM-DD')
                                        }}
                                        parseDateFromString={(date) => {
                                            return moment(date, 'YYYY-MM-DD').startOf('day').toDate();
                                        }}
                                        // DatePicker uses English strings by default. For localized apps, you must override this prop.
                                        strings={defaultDatePickerStrings}
                                        onSelectDate={(date: Date | null | undefined) => {

                                            formkikProps.setFieldValue("from", date)
                                        }}

                                    />
                                </Stack.Item>
                                <Stack.Item grow>
                                    <DatePicker
                                        isRequired
                                        firstDayOfWeek={DayOfWeek.Sunday}
                                        label={t("to")}
                                        placeholder={t("to")}
                                        disabled={state.set.isFetching}
                                        value={formkikProps.values.to}
                                        ariaLabel={t("to")}
                                        formatDate={(e) => {
                                            return formatDate(e, 'YYYY-MM-DD')
                                        }}
                                        parseDateFromString={(date) => {
                                            return moment(date, 'YYYY-MM-DD').endOf('day').toDate();
                                        }}
                                        // DatePicker uses English strings by default. For localized apps, you must override this prop.
                                        strings={defaultDatePickerStrings}
                                        onSelectDate={(date: Date | null | undefined) => {
                                            if (date != undefined)
                                                if (moment(date).isSameOrAfter(formkikProps.values.from)) {
                                                    formkikProps.setFieldValue("to", date)
                                                } else {
                                                    formkikProps.setFieldValue("to", new Date())
                                                }
                                        }}

                                    />
                                </Stack.Item>
                                <Stack.Item grow>
                                    <ComboBox
                                        label={t("grouping")}
                                        required
                                        allowFreeform={true}
                                        autoComplete={'on'}
                                        disabled={state.set.isFetching}
                                        options={[{ key: -1, text: t("unspecified") }, { key: 4, text: t("minutely") } , { key: 15, text: t("15minute") }, { key: 30, text: t("30minute") }, { key: 3, text: t("hourly") }, { key: 240, text: t("4hours") }, { key: 0, text: t("daily") } , { key: 10080, text: t("weekly") }, { key: 1, text: t("monthly") }, { key: 2, text: t("yearly") },]}
                                        selectedKey={formkikProps.values.group.key}
                                        onChange={(e, o) => {
                                            formkikProps.setFieldValue("group", { key: o?.key, text: o?.text })
                                        }} />
                                </Stack.Item>
                            </Stack>


                            <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 10 }}>

                                <Stack.Item grow>
                                    <Label required disabled={state.set.isFetching}>{t("pair")}</Label>
                                    <List
                                        inputProps={{ placeholder: t("pair"), required: true }}
                                        disabled={state.set.isFetching}
                                        suggestionsHeaderText={t('pair')}
                                        isLoading={state.currencyPairs.items.length == 0 ? state.currencyPairs.isFetching : false}
                                        isSearching={state.currencyPairs.items.length > 0 ? state.currencyPairs.isFetching : false}
                                        moreSuggestionsAvailable={state.currencyPairs.hasMore && !state.currencyPairs.isFetching}
                                        suggestions={state.currencyPairs.items.length > 0 ? state.currencyPairs.items.map(e => { return { item: { key: e.id, text: e.name, imageInitials: e?.baseCurrencySymbol + "/" + e?.quoteCurrencySymbol, secondaryText: (e?.baseCurrencyCode == "" ? e?.baseCurrencyId : e?.baseCurrencyCode) + "-" + (e?.quoteCurrencyCode == "" ? e?.quoteCurrencyId : e?.quoteCurrencyCode), imageUrl: e?.iconSource } } as ISuggestionModel<IPersonaProps> }) : []}
                                        onGetMoreResults={() => {
                                            if (state.currencyPairs.items.length > 0 && !state.currencyPairs.isFetching) {
                                                const wrapper = new StringValue();
                                                wrapper.setValue(state.currencyPairs.items.at(state.currencyPairs.items.length - 1).id);
                                                currecyPairsReq.setNextto(wrapper)
                                                getFilterPromise = dispatch(getCurrencyPairs({ body: currecyPairsReq, headers: getHeaders() }))
                                            }
                                        }}
                                        onSuggestionClick={(ev?, item?: any, index?: number) => {
                                            var u = state.currencyPairs.items.findIndex(e => e.id == item.key)
                                            if (u >= 0) {
                                                dispatch(setSelectedCurrencyPairsItems([state.currencyPairs.items.at(u)]))
                                            }


                                        }}
                                        isPeoplePicker={true}
                                        selectedItems={state.currencyPairs.selected.length > 0 ? state.currencyPairs.selected.map(e => { return { key: e.id, text: e.name, imageInitials: e?.baseCurrencySymbol + "/" + e?.quoteCurrencySymbol, secondaryText: (e?.baseCurrencyCode == "" ? e?.baseCurrencyId : e?.baseCurrencyCode) + "-" + (e?.quoteCurrencyCode == "" ? e?.quoteCurrencyId : e?.quoteCurrencyCode), imageUrl: e?.iconSource } as IPersonaProps }) : []}
                                        onChange={(e) => {
                                            dispatch(setSelectedCurrencyPairsItems([]))


                                        }}
                                        onEmptyInputFocus={() => {
                                            dispatch(resetCurrencyPairsSet())
                                            currecyPairsReq.setSearch(undefined)
                                            currecyPairsReq.setNextto(undefined)
                                            dispatch(setIsFilteredCurrencyPairSet(false))
                                            getFilterPromise?.abort()
                                            getFilterPromise = dispatch(getCurrencyPairs({ body: currecyPairsReq, headers: getHeaders() }))
                                            return []
                                        }}
                                        onFilterChanged={onPairsFilterChanged}
                                        endButtons={[
                                            {
                                                title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                                onClick: () => {
                                                    setCurrenctAction(1000)

                                                }
                                            }]}
                                    />

                                </Stack.Item>

                                <Stack.Item grow>
                                    <ComboBox
                                        label={t("price")}
                                        required
                                        allowFreeform={true}
                                        autoComplete={'on'}
                                        disabled={state.set.isFetching}
                                        options={[{ key: 0, text: t("all") }, { key: 1, text: t("buyPrice") }, { key: 2, text: t("sellPrice") }, { key: 3, text: t("tradingPrice") }, { key: 4, text: t("avgSellAndBuyPrice") },]}
                                        selectedKey={formkikProps.values.priceView.key}
                                        onChange={(e, o) => {
                                            formkikProps.setFieldValue("priceView", { key: o?.key, text: o?.text })
                                        }} />
                                </Stack.Item>

                            </Stack>


                            <DialogFooter>
                                <PrimaryButton disabled={state.set.isFetching} text={state.set.isFetching ? undefined : t("generateReport")} type="submit" >

                                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.set.isFetching ? "block" : "none") } }} />

                                </PrimaryButton>

                                <DefaultButton disabled={state.set.isFetching} text={t("clearFilter")} onClick={() => {
                                    formkikProps.resetForm()
                                    req.setCurrencypair("")
                                    req.setGroupby(undefined)
                                    req.setFrom(undefined)
                                    req.setTo(undefined)
                                    dispatch(setSelectedCurrencyPairsItems([]))
                                    dispatch(resetSet())

                                }} />
                            </DialogFooter>
                        </Stack>
                        {state.set.items.length > 0 ? <CommandBar
                            items={[
                                {
                                    key: 'export',
                                    text: t("export"),
                                    iconProps: { iconName: "DownloadDocument", styles: { root: { fontWeight: "bold" } } },
                                    title: t("export"),
                                    disabled: state.set.isFetching,
                                    commandBarButtonAs: (e) => {
                                        return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 1 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 1 ? undefined : e.text}  >
                                            <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 1 ? "block" : "none") } }} />
                                        </CommandBarButton>;
                                    },
                                    subMenuProps: {
                                        items: [{
                                            key: 'exportToExcel',
                                            text: t('exportToExcel'),
                                            title: t("exportToExcel"),
                                            iconProps: { iconName: 'ExcelDocument' },

                                            onClick: () => {
                                                if (!state.isChangeStateLoading && exportReq) {
                                                    exportReq.setExporttype(1)
                                                    setCurrenctAction(1)
                                                    getItemsPromise = dispatch(exportReport({
                                                        body: exportReq, headers: getHeaders()
                                                    }))

                                                }

                                            }
                                        },
                                        {
                                            key: 'exportToCsv',
                                            text: t("exportToCsv"),
                                            title: t("exportToCsv"),

                                            iconProps: { iconName: 'ReportDocument' },
                                            onClick: () => {
                                                if (!state.isChangeStateLoading && exportReq) {

                                                    exportReq.setExporttype(2)

                                                    setCurrenctAction(1)
                                                    getItemsPromise = dispatch(exportReport({
                                                        body: exportReq, headers: getHeaders()
                                                    }))

                                                }

                                            }
                                        },

                                        ]
                                    },


                                },
                                {
                                    key: 'print',
                                    text: t("print"),
                                    iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
                                    title: t("print"),
                                    onClick: () => {
                                        handlePrint();
                                    }
                                },

                            ]}

                        /> : undefined}


                        {!state.set.isFetching ?
                            <Table
                                setKey="currencyExchangeRatesReport"
                                items={state.set.items}
                                checkboxVisibility={props.isSelection ? CheckboxVisibility.always : CheckboxVisibility.onHover}
                                columns={formkikProps.values.priceView?.key == 0 ? all : formkikProps.values.priceView?.key == 1 ? buy : formkikProps.values.priceView?.key == 2 ? sell : formkikProps.values.priceView?.key == 3 ? trading : formkikProps.values.priceView?.key == 4 ? avg : all}
                                selectionMode={SelectionMode.none}
                                enableShimmer={state.set.items.length == 0 && state.set.isFetching}
                                isColumnSortingEnabled={state.set.selected.length == 0}
                                constrainMode={ConstrainMode.unconstrained}
                                layoutMode={props.isSelection ? DetailsListLayoutMode.fixedColumns : DetailsListLayoutMode.fixedColumns}
                                compRef={ref}
                                height={state.message == undefined ? "calc(100vh - 341px)" : "calc(100vh - 390px)"}

                            /> : undefined
                        }
                    </Form>

                )}
            </Formik>
        </Stack >



    );
}




