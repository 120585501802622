/**
 * @fileoverview gRPC-Web generated client stub for currency_pair_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as currency_pair_pb from './currency_pair_pb';


export class CurrencyPairEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetCurrencyPairs = new grpcWeb.MethodDescriptor(
    '/currency_pair_endpoint.CurrencyPairEndpoint/GetCurrencyPairs',
    grpcWeb.MethodType.UNARY,
    currency_pair_pb.GetCurrencyPairsRequest,
    currency_pair_pb.GetCurrencyPairsResponse,
    (request: currency_pair_pb.GetCurrencyPairsRequest) => {
      return request.serializeBinary();
    },
    currency_pair_pb.GetCurrencyPairsResponse.deserializeBinary
  );

  getCurrencyPairs(
    request: currency_pair_pb.GetCurrencyPairsRequest,
    metadata: grpcWeb.Metadata | null): Promise<currency_pair_pb.GetCurrencyPairsResponse>;

  getCurrencyPairs(
    request: currency_pair_pb.GetCurrencyPairsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: currency_pair_pb.GetCurrencyPairsResponse) => void): grpcWeb.ClientReadableStream<currency_pair_pb.GetCurrencyPairsResponse>;

  getCurrencyPairs(
    request: currency_pair_pb.GetCurrencyPairsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: currency_pair_pb.GetCurrencyPairsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/currency_pair_endpoint.CurrencyPairEndpoint/GetCurrencyPairs',
        request,
        metadata || {},
        this.methodDescriptorGetCurrencyPairs,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/currency_pair_endpoint.CurrencyPairEndpoint/GetCurrencyPairs',
    request,
    metadata || {},
    this.methodDescriptorGetCurrencyPairs);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/currency_pair_endpoint.CurrencyPairEndpoint/Update',
    grpcWeb.MethodType.UNARY,
    currency_pair_pb.UpdateCurrencyPairRequest,
    currency_pair_pb.UpdateCurrencyPairResponse,
    (request: currency_pair_pb.UpdateCurrencyPairRequest) => {
      return request.serializeBinary();
    },
    currency_pair_pb.UpdateCurrencyPairResponse.deserializeBinary
  );

  update(
    request: currency_pair_pb.UpdateCurrencyPairRequest,
    metadata: grpcWeb.Metadata | null): Promise<currency_pair_pb.UpdateCurrencyPairResponse>;

  update(
    request: currency_pair_pb.UpdateCurrencyPairRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: currency_pair_pb.UpdateCurrencyPairResponse) => void): grpcWeb.ClientReadableStream<currency_pair_pb.UpdateCurrencyPairResponse>;

  update(
    request: currency_pair_pb.UpdateCurrencyPairRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: currency_pair_pb.UpdateCurrencyPairResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/currency_pair_endpoint.CurrencyPairEndpoint/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/currency_pair_endpoint.CurrencyPairEndpoint/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorUpdateActivity = new grpcWeb.MethodDescriptor(
    '/currency_pair_endpoint.CurrencyPairEndpoint/UpdateActivity',
    grpcWeb.MethodType.UNARY,
    currency_pair_pb.UpdateCurrencyPairActivityRequest,
    currency_pair_pb.UpdateCurrencyPairActivityResponse,
    (request: currency_pair_pb.UpdateCurrencyPairActivityRequest) => {
      return request.serializeBinary();
    },
    currency_pair_pb.UpdateCurrencyPairActivityResponse.deserializeBinary
  );

  updateActivity(
    request: currency_pair_pb.UpdateCurrencyPairActivityRequest,
    metadata: grpcWeb.Metadata | null): Promise<currency_pair_pb.UpdateCurrencyPairActivityResponse>;

  updateActivity(
    request: currency_pair_pb.UpdateCurrencyPairActivityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: currency_pair_pb.UpdateCurrencyPairActivityResponse) => void): grpcWeb.ClientReadableStream<currency_pair_pb.UpdateCurrencyPairActivityResponse>;

  updateActivity(
    request: currency_pair_pb.UpdateCurrencyPairActivityRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: currency_pair_pb.UpdateCurrencyPairActivityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/currency_pair_endpoint.CurrencyPairEndpoint/UpdateActivity',
        request,
        metadata || {},
        this.methodDescriptorUpdateActivity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/currency_pair_endpoint.CurrencyPairEndpoint/UpdateActivity',
    request,
    metadata || {},
    this.methodDescriptorUpdateActivity);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/currency_pair_endpoint.CurrencyPairEndpoint/Delete',
    grpcWeb.MethodType.UNARY,
    currency_pair_pb.DeleteCurrencyPairRequest,
    currency_pair_pb.DeleteCurrencyPairResponse,
    (request: currency_pair_pb.DeleteCurrencyPairRequest) => {
      return request.serializeBinary();
    },
    currency_pair_pb.DeleteCurrencyPairResponse.deserializeBinary
  );

  delete(
    request: currency_pair_pb.DeleteCurrencyPairRequest,
    metadata: grpcWeb.Metadata | null): Promise<currency_pair_pb.DeleteCurrencyPairResponse>;

  delete(
    request: currency_pair_pb.DeleteCurrencyPairRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: currency_pair_pb.DeleteCurrencyPairResponse) => void): grpcWeb.ClientReadableStream<currency_pair_pb.DeleteCurrencyPairResponse>;

  delete(
    request: currency_pair_pb.DeleteCurrencyPairRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: currency_pair_pb.DeleteCurrencyPairResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/currency_pair_endpoint.CurrencyPairEndpoint/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/currency_pair_endpoint.CurrencyPairEndpoint/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorGetCurrencyPair = new grpcWeb.MethodDescriptor(
    '/currency_pair_endpoint.CurrencyPairEndpoint/GetCurrencyPair',
    grpcWeb.MethodType.UNARY,
    currency_pair_pb.GetCurrencyPairRequest,
    currency_pair_pb.GetCurrencyPairResponse,
    (request: currency_pair_pb.GetCurrencyPairRequest) => {
      return request.serializeBinary();
    },
    currency_pair_pb.GetCurrencyPairResponse.deserializeBinary
  );

  getCurrencyPair(
    request: currency_pair_pb.GetCurrencyPairRequest,
    metadata: grpcWeb.Metadata | null): Promise<currency_pair_pb.GetCurrencyPairResponse>;

  getCurrencyPair(
    request: currency_pair_pb.GetCurrencyPairRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: currency_pair_pb.GetCurrencyPairResponse) => void): grpcWeb.ClientReadableStream<currency_pair_pb.GetCurrencyPairResponse>;

  getCurrencyPair(
    request: currency_pair_pb.GetCurrencyPairRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: currency_pair_pb.GetCurrencyPairResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/currency_pair_endpoint.CurrencyPairEndpoint/GetCurrencyPair',
        request,
        metadata || {},
        this.methodDescriptorGetCurrencyPair,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/currency_pair_endpoint.CurrencyPairEndpoint/GetCurrencyPair',
    request,
    metadata || {},
    this.methodDescriptorGetCurrencyPair);
  }

  methodDescriptorUpdatePrice = new grpcWeb.MethodDescriptor(
    '/currency_pair_endpoint.CurrencyPairEndpoint/UpdatePrice',
    grpcWeb.MethodType.UNARY,
    currency_pair_pb.UpdateCurrencyPairPriceRequest,
    currency_pair_pb.UpdateCurrencyPairPriceResponse,
    (request: currency_pair_pb.UpdateCurrencyPairPriceRequest) => {
      return request.serializeBinary();
    },
    currency_pair_pb.UpdateCurrencyPairPriceResponse.deserializeBinary
  );

  updatePrice(
    request: currency_pair_pb.UpdateCurrencyPairPriceRequest,
    metadata: grpcWeb.Metadata | null): Promise<currency_pair_pb.UpdateCurrencyPairPriceResponse>;

  updatePrice(
    request: currency_pair_pb.UpdateCurrencyPairPriceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: currency_pair_pb.UpdateCurrencyPairPriceResponse) => void): grpcWeb.ClientReadableStream<currency_pair_pb.UpdateCurrencyPairPriceResponse>;

  updatePrice(
    request: currency_pair_pb.UpdateCurrencyPairPriceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: currency_pair_pb.UpdateCurrencyPairPriceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/currency_pair_endpoint.CurrencyPairEndpoint/UpdatePrice',
        request,
        metadata || {},
        this.methodDescriptorUpdatePrice,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/currency_pair_endpoint.CurrencyPairEndpoint/UpdatePrice',
    request,
    metadata || {},
    this.methodDescriptorUpdatePrice);
  }

  methodDescriptorAdd = new grpcWeb.MethodDescriptor(
    '/currency_pair_endpoint.CurrencyPairEndpoint/Add',
    grpcWeb.MethodType.UNARY,
    currency_pair_pb.AddCurrencyPairRequest,
    currency_pair_pb.AddCurrencyPairResponse,
    (request: currency_pair_pb.AddCurrencyPairRequest) => {
      return request.serializeBinary();
    },
    currency_pair_pb.AddCurrencyPairResponse.deserializeBinary
  );

  add(
    request: currency_pair_pb.AddCurrencyPairRequest,
    metadata: grpcWeb.Metadata | null): Promise<currency_pair_pb.AddCurrencyPairResponse>;

  add(
    request: currency_pair_pb.AddCurrencyPairRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: currency_pair_pb.AddCurrencyPairResponse) => void): grpcWeb.ClientReadableStream<currency_pair_pb.AddCurrencyPairResponse>;

  add(
    request: currency_pair_pb.AddCurrencyPairRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: currency_pair_pb.AddCurrencyPairResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/currency_pair_endpoint.CurrencyPairEndpoint/Add',
        request,
        metadata || {},
        this.methodDescriptorAdd,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/currency_pair_endpoint.CurrencyPairEndpoint/Add',
    request,
    metadata || {},
    this.methodDescriptorAdd);
  }

  methodDescriptorSetPriceTrigger = new grpcWeb.MethodDescriptor(
    '/currency_pair_endpoint.CurrencyPairEndpoint/SetPriceTrigger',
    grpcWeb.MethodType.UNARY,
    currency_pair_pb.SetPriceTriggerRequest,
    currency_pair_pb.SetPriceTriggerResponse,
    (request: currency_pair_pb.SetPriceTriggerRequest) => {
      return request.serializeBinary();
    },
    currency_pair_pb.SetPriceTriggerResponse.deserializeBinary
  );

  setPriceTrigger(
    request: currency_pair_pb.SetPriceTriggerRequest,
    metadata: grpcWeb.Metadata | null): Promise<currency_pair_pb.SetPriceTriggerResponse>;

  setPriceTrigger(
    request: currency_pair_pb.SetPriceTriggerRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: currency_pair_pb.SetPriceTriggerResponse) => void): grpcWeb.ClientReadableStream<currency_pair_pb.SetPriceTriggerResponse>;

  setPriceTrigger(
    request: currency_pair_pb.SetPriceTriggerRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: currency_pair_pb.SetPriceTriggerResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/currency_pair_endpoint.CurrencyPairEndpoint/SetPriceTrigger',
        request,
        metadata || {},
        this.methodDescriptorSetPriceTrigger,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/currency_pair_endpoint.CurrencyPairEndpoint/SetPriceTrigger',
    request,
    metadata || {},
    this.methodDescriptorSetPriceTrigger);
  }

}

