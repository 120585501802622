import { ChoiceGroup, ComboBox, DefaultButton, DialogFooter, Icon, IconButton, IPersonaProps, IPickerItemProps, IStackTokens, ISuggestionItemProps, ISuggestionModel, ISuggestionsProps, ITag, Label, MessageBar, MessageBarType, Modal, NormalPeoplePicker, PeoplePickerItem, Persona, PersonaSize, PrimaryButton, Spinner, SpinnerSize, Stack, TagItemSuggestion, TagPicker, Text, TextField, Toggle, TooltipHost, ValidationState } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { FormEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks";
import { add, dismissMessage, get, getAccountTypes, getClinetUsers, getCurrencies, getLimitations, reset, resetCurrencies, resetLimitations, resetAccountTypes, setIsFilteredAccountTypesSet, resetUsers, setIsFilteredCurrenciesSet, setIsFilteredOwnersSet, setSelectedAccountTypeItems, setSelectedCurrencyItems, setIsFilteredLimitationsSet, setSelectedLimitationItems, setStage1, setSelectedUserItems, update, updateTransferControl } from "./AccountsFromSlice";
import * as Yup from 'yup';
import { DynamicInputField, InputField } from "../../controls/Controls";
import { inputs } from "../../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../../app/Api";
import { Message } from "../../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../../FormProps";
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog";
import { formatDate, timestampToDate } from "../../../../app/Helpers";
import { AuthenticateReply } from "../../../../repository/UserManagement/authentication_pb";
import { DynamicField } from "../../../common/DynamicFields/DynamicFields";
import { ExtraField } from "../../../../repository/Accountant/common_pb";
import { TableState } from "../../../common/Table/TableSate";
import { AccountStatus, LimitationType, Operations, TransferControl } from "../../../../app/Enums";
import { GetAccountTypesRequest } from "../../../../repository/Accountant/account_type_pb"
import { GetCurrenciesRequest } from "../../../../repository/Accountant/currency_pb"
import { GetUsersRequest } from "../../../../repository/UserManagement/Customers/user_pb"
import { GetLimitationsRequest } from "../../../../repository/Accountant/limitation_pb"
import { GetAccountRequest, GetAccountResponse, OpenAccountRequest, UpdateAccountResponse, OpenAccountResponse, UpdateAccountRequest, UpdateTransferControlRequest, UpdateTransferControlResponse } from "../../../../repository/Accountant/account_pb";
import { List } from "../../../common/List/List";
import { Popup } from "../../../common/Popup/Popup";
import { UsersPage } from "../../../pages/Customers/Users/UsersPage";
import { LimitationsPage } from "../../../pages/Accountant/Limitations/LimitationsPage";
import { AccountTypesPage } from "../../../pages/Accountant/AccountTypes/AccountTypesPage";
import { CurrenciesPage } from "../../../pages/Accountant/Currencies/CurrenciesPage";

let req: UpdateTransferControlRequest;


let promise: any;
let getFilterPromise: any;
let org: number = -1;
let current: AuthenticateReply.AsObject;

export const TransferControlForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();
    const [currentAction, setCurrenctAction] = useState(0)


    const state: { isLoading: boolean, message: ApiMessage | undefined, stage1: any, owners: TableState, limitations: TableState, currencies: TableState, accountTypes: TableState } = useAppSelector((state) => {

        return {
            isLoading: state.accountsForm.isLoading, message: state.accountsForm.message, stage1: state.accountsForm.stage1,
            accountTypes: state.accountsForm.accountTypes,
            currencies: state.accountsForm.currencies,
            limitations: state.accountsForm.limitations,
            owners: state.accountsForm.owners,

        }
    })

    useEffect(() => {
        var l = searchParams.get('org');
        req = new UpdateTransferControlRequest();


        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);

            }
        }

        if (props.type == FormType.EDIT) {
            if (props.fetchData) {
                let r = new GetAccountRequest()
                r.setId(props?.renderObject?.internalId);
                promise?.abort()
                promise = dispatch(get({ body: r, headers: getHeaders() }))
            } else {
                dispatch(setStage1(props?.renderObject))
            }
        }


        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        }
        return () => { //clean up
            promise?.abort();
            dispatch(reset());
        }
    }, [])



    return (
        <>

            <Formik
                enableReinitialize={true}
                initialValues={{
                    transferControl: props.renderObject?.transferControl == TransferControl.ALL ? { key: TransferControl.ALL + "", text: t("transferControlAll") } :
                        props.renderObject?.transferControl == TransferControl.NONE ? { key: TransferControl.NONE + "", text: t("transferControlNone") } :
                            props.renderObject?.transferControl == TransferControl.ONLY_OWNER_ACCOUNTS ? { key: TransferControl.ONLY_OWNER_ACCOUNTS + "", text: t("transferControlOwnerOnly") } :
                                props.renderObject?.transferControl == TransferControl.OWNER_AND_AUTHORIZED_CLIENTS_ACCOUNTS ? { key: TransferControl.OWNER_AND_AUTHORIZED_CLIENTS_ACCOUNTS + "", text: t("transferControlOwnerAndAuth") } :
                                    { key: TransferControl.ALL + "", text: t("transferControlAll") }

                }}

                validationSchema={Yup.object({


                })}

                onSubmit={(values, actions) => {
                    req.setId(props.renderObject?.internalId);
                    req.setTansfercontrol(Number(values.transferControl.key))
                    promise = dispatch(updateTransferControl({ body: req, headers: getHeaders() }))
                    promise.unwrap()
                        .then((res: UpdateTransferControlResponse.AsObject) => {
                            if (res) {
                                if (props?.onSuccess) {
                                    props?.onSuccess({
                                        ...props.renderObject,
                                        transferControl: Number(values.transferControl.key),

                                    });
                                }
                            }
                            actions.setSubmitting(false)
                        })
                        .catch((error: ApiMessage) => {
                            actions.setSubmitting(false)
                        })

                }}

            >
                {formkikProps => (
                    <Form >
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? (state.message.data != 401) ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            /> :
                                <SessionExpiredDialog />
                                : null
                            }


                            <ChoiceGroup
                                label={t("transferControlHint")}
                                disabled={state.isLoading}
                                name="transferControl"
                                //selectedKey={formkikProps.values.gender.key}
                                selectedKey={formkikProps.values.transferControl.key}
                                options={[{ key: TransferControl.ALL + "", text: t("transferControlAll") },
                                { key: TransferControl.OWNER_AND_AUTHORIZED_CLIENTS_ACCOUNTS + "", text: t("transferControlOwnerAndAuth") },
                                { key: TransferControl.ONLY_OWNER_ACCOUNTS + "", text: t("transferControlOwnerOnly") },
                                { key: TransferControl.NONE + "", text: t("transferControlNone") }

                                ]}
                                onChange={(e, o) => {

                                    formkikProps.setFieldValue("transferControl", { key: o?.key, text: o?.text })
                                }} />


                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("add") : t("edit")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={props.type == FormType.ADD ? t("discard") : t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>



                    </Form>
                )}
            </Formik >
        </>
    );

}

