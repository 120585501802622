import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ProgressIndicator, Pivot, PivotItem, Separator, IPersonaProps, NormalPeoplePicker, IPickerItemProps, ValidationState, PeoplePickerItem } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../../../../app/Hooks"
import { getPage } from "../../../../../../../app/Pages"
import { setPage } from "../../../../../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../../../../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../../../../../common/Table/Table"
import { ApiMessage, getHeaders } from "../../../../../../../app/Api"
import { useId, useBoolean } from '@fluentui/react-hooks';

import { Message } from "../../../../../../common/Message/Message"
import { ShareBox } from "../../../../../../common/ShareBox/ShareBox"
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { dismissMessage, exportItem, get, reset, resetItem, updateItem, } from "./TreasuryStatementDetailsPageSlice"
import { Popup } from "../../../../../../common/Popup/Popup"
import { FormType } from "../../../../../../forms/FormProps"
import { DynamicField } from "../../../../../../common/DynamicFields/DynamicFields"
import { TextView } from "../../../../../../common/DataView/TextView"
import { ProfileView } from "../../../../../../common/DataView/ProfileView"
import { TreasuryStatus, LimitationType, Natures, Operations } from "../../../../../../../app/Enums"
import { TreasuriesForm } from "../../../../../../forms/Accountant/Treasuries/TreasuriesForm"
import { TableState } from "../../../../../../common/Table/TableSate"
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { GetLimitationsRequest } from "../../../../../../../repository/Accountant/limitation_pb"
import { LinkView } from "../../../../../../common/DataView/LinkView"
import { currencyFormatter, getOperations, printComponent, normalizeKey, printStatement } from "../../../../../../../app/Helpers"
import {  ExportTransactionRequest, GetTransactionRequest } from "../../../../../../../repository/Accountant/treasury_operation_pb"
import { TreasuryTransactionsForm } from "../../../../../../forms/Accountant/TreasuryTransactions/TreasuryTransactionsForm"
import { useReactToPrint } from "react-to-print"
import statement from "../../../../../../../repository/Accountant/treasury_operation_pb"

const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};
let exportReq: ExportTransactionRequest;
let req: GetTransactionRequest;

let org: number = -1;

let getPromise: any;
let getBalancePromise: any;
let actionPromise: any;
let getFilterPromise: any;


const key = "treasuriesManagement/transactions/details"
export const TreasuryTransactionDetailsPage: React.FunctionComponent = () => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    let { id } = useParams();
    const { hash } = useLocation();
    const [selectedKey, setSelectedKey] = useState("0");

    const state: {
        isChangeStateLoading: boolean,
        message: ApiMessage | undefined,
        appTheme: string, isDarkModeEnabled: boolean,
        isLoading: boolean,
        item: any,
    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.treasuryTransactionDetailsPage.isChangeStateLoading,
            message: state.treasuryTransactionDetailsPage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            isLoading: state.treasuryTransactionDetailsPage.isLoading,
            item: state.treasuryTransactionDetailsPage.item,

        }
    })

    const calloutTargetId = useId('callout-target');
    const labelId = useId('callout-label');
    const closeTreasuryActionConfirmationcalloutTargetId = useId('close-treasury-action-callout-target');
    const activityActionConfirmationcalloutTargetId = useId('activity-action-callout-target');
    const updateLimitationActionConfirmationcalloutTargetId = useId('update-limitation-action-callout-target');
    const transferOwnershipActionConfirmationcalloutTargetId = useId('transfer-ownership-action-callout-target');
    const ActionConfirmationLabelId = useId('action-callout-label');
    const shareCalloutLabelId = useId('share-callout-label');
    const shareTargetId = useId('share-callout-target');

    const updateLimtCalloutLabelId = useId('limit-callout-label');
    const transfareOwnerShipCalloutLabelId = useId('transfareOwnerShip-callout-label');


    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const [isActionConfirmationCalloutVisible, { toggle: toggleisActionConfirmationCalloutVisible }] = useBoolean(false);
    const [isShareCalloutVisible, { toggle: toggleisShareCalloutVisible }] = useBoolean(false);
    const [isupdateLimitationConfirmationCalloutVisible, { toggle: toggleisUpdateLimitationConfirmationCalloutVisible }] = useBoolean(false);
    const [isTransferOwnershipConfirmationCalloutVisible, { toggle: toggleisTransferOwnershipConfirmationCalloutVisible }] = useBoolean(false);

    const ref = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => {
            return printComponent(ref.current?.cloneNode(true))
        }

    });
    

    const handlePrintCredit = useReactToPrint({
        copyStyles: false,
        content: () => {

            return printStatement(state.item, true , t)
        }
    });
    const handlePrintDebit = useReactToPrint({
        copyStyles: false,
        content: () => {

            return printStatement(state.item, false, t)
        }

    });
    const [currentAction, setCurrenctAction] = useState(0)

    const getActionId = (action: number): string => {
        if (action == 1) {
            return closeTreasuryActionConfirmationcalloutTargetId;
        } else if (action == 2) {
            return activityActionConfirmationcalloutTargetId;
        } else if (action == 4) {
            return updateLimitationActionConfirmationcalloutTargetId;
        } else if (action == 3) {
            return transferOwnershipActionConfirmationcalloutTargetId;
        } else {
            return ""
        }
    }


    useEffect(() => {
        req = new GetTransactionRequest();

        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);

            }
        }
        const page = getPage(key)
        dispatch(setTitle(t(normalizeKey(page?.title))));
        dispatch(setPage(page));

        if (id) {
            req.setId(id)
            getPromise = dispatch(get({ body: req, headers: getHeaders() }))
        } else {
            navigate(-1)
        }


        return () => { //clean up
            getPromise?.abort();
            actionPromise?.abort();
            getFilterPromise?.abort();
            getBalancePromise?.abort();
            dispatch(reset());
        }
    }, [])



    const _selectionItems: ICommandBarItemProps[] = [
        {
            key: 'back',
            text: t('back'),
            title: t("back"),
            iconProps: { iconName: 'back' },
            disabled: state.isLoading,
            onClick: () => {
                navigate(-1)
            }
        },
        {
            key: 'refresh',
            text: t('refresh'),
            title: t('refresh'),
            iconProps: { iconName: 'refresh' },
            disabled: state.isLoading,
            onClick: () => {
                dispatch(resetItem())
                getPromise = dispatch(get({ body: req, headers: getHeaders() }))

            }
        },
        {
            key: 'edit',
            text: t("edit"),

            iconProps: { iconName: 'EditSolid12' },
            disabled: state.item?.status == TreasuryStatus.CLOSED,
            title: t("edit"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(101)
                }
            }
        },

        {
            key: 'logs',
            text: t("logs"),
            iconProps: {  iconName: "PageList", styles: { root: { fontWeight: "bold" } } },
            title: t("logs"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/logs?resourceId=" + state.item?.internalId + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },
        {
            key: 'share',
            text: t("share"),
            title: t("share"),
            iconProps: { id: shareTargetId, iconName: 'Share', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                toggleisShareCalloutVisible()
            },

        },
        {
            key: 'export',
            text: t("export"),
            iconProps: { iconName: "DownloadDocument", styles: { root: { fontWeight: "bold" } } },
            title: t("export"),
            disabled: state.isChangeStateLoading,
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 1 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 1 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 1 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            subMenuProps: {
                items: [
                    {
                        key: 'exportCredit',
                        text: t('exportCredit'),
                        title: t("exportCredit"),
                        iconProps: { iconName: "DownloadDocument", styles: { root: { fontWeight: "bold" } } },

                        onClick: () => {

                            if (!state.isChangeStateLoading) {
                                exportReq = new ExportTransactionRequest();
                                exportReq.setId(req.getId())
                                const labels: statement.Label[] = [];
                                let r = new statement.Label();
                                let value = new StringValue();


                                r = new statement.Label();
                                value = new StringValue();
                                r.setLabelvalue(t("account"));
                                r.setPropartyname("account")
                                labels.push(r)

                                r = new statement.Label();
                                value = new StringValue();
                                r.setLabelvalue(t("treasury"));
                                r.setPropartyname("treasury")
                                labels.push(r)

                                r = new statement.Label();
                                r.setLabelvalue(t("openningBalance"));
                                r.setPropartyname("BalanceBefore")
                                labels.push(r)

                                r = new statement.Label();
                                r.setLabelvalue(t("closingBalance"));
                                r.setPropartyname("BalanceAfter")
                                labels.push(r)

                                r = new statement.Label();
                                r.setLabelvalue(t("amount"));
                                r.setPropartyname("amount")
                                labels.push(r)

                                r = new statement.Label();
                                r.setLabelvalue(t("transactionNumberLabel"));
                                r.setPropartyname("id")
                                labels.push(r)

                                r = new statement.Label();
                                r.setLabelvalue(t("date"));
                                r.setPropartyname("date")
                                labels.push(r)

                                r = new statement.Label();
                                r.setLabelvalue(t("description"));
                                r.setPropartyname("desc")
                                labels.push(r)

                                value = new StringValue();
                                value.setValue(t(getOperations().find(x => x.key == state.item?.operation)?.text ?? state.item?.operation))
                                r = new statement.Label();
                                r.setLabelvalue(t("operation"));
                                r.setValue(value)
                                r.setPropartyname("op")
                                labels.push(r)


                                r = new statement.Label();
                                r.setLabelvalue(t("transactionTitle"));
                                r.setPropartyname("title")
                                labels.push(r)
                                r = new statement.Label();
                                r.setLabelvalue(t("currency"));
                                r.setPropartyname("currency")
                                labels.push(r)

                                r = new statement.Label();
                                r.setLabelvalue(t("information"));
                                r.setPropartyname("basicinfo")
                                labels.push(r)
                                r = new statement.Label();
                                value = new StringValue();
                                value.setValue((((new Date()).getTimezoneOffset()) * -1)+"")
                                r.setValue(value);
                                r.setPropartyname("timezone")
                                labels.push(r)

                                exportReq.setLabelsList(labels)
                                exportReq.setIscredit(true)
                                exportReq.setId(req.getId())
                                setCurrenctAction(1)
                                actionPromise = dispatch(exportItem({
                                    body: exportReq, headers: getHeaders()
                                }))

                            }

                        }
                    },
                    {
                        key: 'exportDebit',
                        text: t("exportDebit"),
                        title: t("exportDebit"),
                        iconProps: { iconName: "DownloadDocument", styles: { root: { fontWeight: "bold" } } },
                        onClick: () => {

                            if (!state.isChangeStateLoading) {
                                exportReq = new ExportTransactionRequest();
                                exportReq.setId(req.getId())
                                const labels: statement.Label[] = [];
                                let r = new statement.Label();
                                let value = new StringValue();


                                r = new statement.Label();
                                value = new StringValue();
                                r.setLabelvalue(t("account"));
                                r.setPropartyname("account")
                                labels.push(r)

                                r = new statement.Label();
                                value = new StringValue();
                                r.setLabelvalue(t("treasury"));
                                r.setPropartyname("treasury")
                                labels.push(r)

                                r = new statement.Label();
                                r.setLabelvalue(t("openningBalance"));
                                r.setPropartyname("BalanceBefore")
                                labels.push(r)

                                r = new statement.Label();
                                r.setLabelvalue(t("closingBalance"));
                                r.setPropartyname("BalanceAfter")
                                labels.push(r)

                                r = new statement.Label();
                                r.setLabelvalue(t("amount"));
                                r.setPropartyname("amount")
                                labels.push(r)

                                r = new statement.Label();
                                r.setLabelvalue(t("transactionNumberLabel"));
                                r.setPropartyname("id")
                                labels.push(r)

                                r = new statement.Label();
                                r.setLabelvalue(t("date"));
                                r.setPropartyname("date")
                                labels.push(r)

                                r = new statement.Label();
                                r.setLabelvalue(t("description"));
                                r.setPropartyname("desc")
                                labels.push(r)

                                value = new StringValue();
                                value.setValue(t(getOperations().find(x => x.key == state.item?.operation)?.text ?? state.item?.operation))
                                r = new statement.Label();
                                r.setLabelvalue(t("operation"));
                                r.setValue(value)
                                r.setPropartyname("op")
                                labels.push(r)

                                r = new statement.Label();
                                r.setLabelvalue(t("currency"));
                                r.setPropartyname("currency")
                                labels.push(r)
                                
                                r = new statement.Label();
                                r.setLabelvalue(t("transactionTitle"));
                                r.setPropartyname("title")
                                labels.push(r)


                                r = new statement.Label();
                                r.setLabelvalue(t("information"));
                                r.setPropartyname("basicinfo")
                                labels.push(r)

                                r = new statement.Label();
                                value = new StringValue();
                                value.setValue((((new Date()).getTimezoneOffset()) * -1)+"")
                                r.setValue(value);
                                r.setPropartyname("timezone")
                                labels.push(r)
                                
                                exportReq.setLabelsList(labels)
                                exportReq.setIscredit(false)
                                exportReq.setId(req.getId())
                                setCurrenctAction(1)
                                actionPromise = dispatch(exportItem({
                                    body: exportReq, headers: getHeaders()
                                }))

                            }

                        }
                    },

                ]

            }
        },
        {
            key: 'print',
            text: t("print"),
            iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
            title: t("print"),
            subMenuProps: {
                items: [{
                    key: 'printReceiptCredit',
                    text: t('printReceiptCredit'),
                    title: t("printReceiptCredit"),
                    iconProps: { iconName: 'M365InvoicingLogo' },

                    onClick: () => {
                        if (!state.isChangeStateLoading) {
                            handlePrintCredit()
                        }

                    }
                },
                {
                    key: 'printReceiptDebit',
                    text: t('printReceiptDebit'),
                    title: t("printReceiptDebit"),
                    iconProps: { iconName: 'M365InvoicingLogo' },

                    onClick: () => {
                        if (!state.isChangeStateLoading) {
                            handlePrintDebit()
                        }

                    }
                },
                {
                    key: 'printPage',
                    text: t("printPage"),
                    title: t("printPage"),
                    iconProps: { iconName: "Page", styles: { root: { fontWeight: "bold" } } },
                    onClick: () => {
                        if (!state.isChangeStateLoading) {
                            handlePrint();
                        }
                    }
                },

                ]
            },

        },
    ];


    return (
        <Stack >
            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }

            <Popup isOpen={(currentAction == 101)} title={t("editTransaction")} onDismiss={() => { setCurrenctAction(0) }} >
                <TreasuryTransactionsForm childrenGap={10} maxWidth={280} type={FormType.EDIT} renderObject={state.item} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(updateItem(e))
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>





            {isShareCalloutVisible ? (
                <Callout
                    ariaLabelledBy={shareCalloutLabelId}
                    target={`#${shareTargetId}`}
                    onDismiss={toggleisShareCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <ShareBox onDismiss={toggleisShareCalloutVisible} title={t("shareTransaction")} value={`https://${window.location.host}/treasury-transactions/${state.item?.internalId}` + ((org == -1) ? "" : "?org=" + org)}></ShareBox>
                </Callout>
            ) : null}



            {state.isLoading ? <ProgressIndicator /> :
                !state.item ? null :
                    <div ref={ref}>
                        <CommandBar
                            items={_selectionItems}
                        />
                        <Stack tokens={{ childrenGap: 10, padding: "0px 33% 10px 3%" }}>
                            <Stack horizontal tokens={{ childrenGap: 10 }}>

                                <TextView label={t("id")} value={state.item?.internalId} />

                                <TextView label={t("createdDate")} value={state.item?.createdDate} />

                                {state.item?.addedBy ? <ProfileView label={t("addedBy")} value={state.item?.addedBy == "" ? state.item?.addedById : state.item?.addedBy}
                                    url={"/management/users/" + state.item?.addedById + ((org == -1) ? "" : "?org=" + org)}
                                    text={state.item?.addedBy == "" ? state.item?.addedById : state.item?.addedBy}
                                    size={PersonaSize.size40} />

                                    : undefined}

                            </Stack>

                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <TextView label={t("transactionNumber")} value={state.item?.id} />

                                <TextView label={t("operation")} value={t(getOperations().find(x => x.key == state.item?.operation)?.text ?? state.item?.operation)} />

                                <TextView label={t("amount")} value={currencyFormatter(state.item?.amount, { significantDigits: state.item?.currencyDecimalPlaces ?? 9, symbol: state.item?.currencySymbol })} />

                            </Stack>
                            <LinkView label={t("currency")} value={state.item?.currencyName == "" ? state.item?.currencyId : state.item?.currencyName}
                                url={"/currencies/" + state.item?.currencyId + ((org == -1) ? "" : "?org=" + org)} />

                            <LinkView label={t("referenceTransaction")} value={state.item?.referenceTransactionId}
                                url={"/account-transactions/" + state.item?.referenceTransactionInternalId + ((org == -1) ? "" : "?org=" + org)} />



                            <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 10 }}>
                              

                                <Stack>
                                    <Separator alignContent="start"><b>{t("credit")}</b></Separator>

                                    {state.item.creditorIsTreasury != undefined ? state.item.creditorIsTreasury ?
                                        <LinkView label={t("treasury")} value={state.item?.creditorName == "" ? state.item?.creditorId : state.item?.creditorName + "(#" + (state.item?.creditorId ?? "") + ")"}
                                            url={"/treasuries/" + state.item?.creditorInternalId + ((org == -1) ? "" : "?org=" + org)} />
                                        :
                                        <LinkView label={t("account")} value={state.item?.creditorName == "" ? state.item?.creditorId : state.item?.creditorName + "(#" + (state.item?.creditorId ?? "") + ")"}
                                            url={"/accounts/" + state.item?.creditorInternalId + ((org == -1) ? "" : "?org=" + org)} />

                                        : <TextView label={t("party")} value={state.item?.creditorName == "" ? state.item?.creditorId : state.item?.creditorName + "(#" + (state.item?.creditorId ?? "") + ")"} />
                                    }

                                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                                        <TextView label={t("balanceBefore")} value={currencyFormatter(state.item?.creditorBalanceBefore, { significantDigits: state.item?.currencyDecimalPlaces ?? 9, symbol: state.item?.currencySymbol })} />
                                        <TextView label={t("balanceAfter")} value={currencyFormatter(state.item?.creditorBalanceAfter, { significantDigits: state.item?.currencyDecimalPlaces ?? 9, symbol: state.item?.currencySymbol })} />

                                    </Stack>
                                    <TextView label={t("description")} value={state.item?.creditorDescription} />
                                    <LinkView label={t("feeTransaction")} value={state.item?.creditorFeeTransactionId}
                                url={"/account-transactions/" + state.item?.creditorFeeTransactionInternalId + ((org == -1) ? "" : "?org=" + org)} />

                                </Stack>
                                <Stack>
                                    <Separator alignContent="start"><b>{t("debit")}</b></Separator>
                                    {state.item.debtorIsTreasury != undefined ? state.item.debtorIsTreasury ?
                                        <LinkView label={t("treasury")} value={state.item?.debtorName == "" ? state.item?.debtorId : state.item?.debtorName + "(#" + (state.item?.debtorId ?? "") + ")"}
                                            url={"/treasuries/" + state.item?.debtorInternalId + ((org == -1) ? "" : "?org=" + org)} />
                                        :
                                        <LinkView label={t("account")} value={state.item?.debtorName == "" ? state.item?.debtorId : state.item?.debtorName + "(#" + (state.item?.debtorId ?? "") + ")"}
                                            url={"/accounts/" + state.item?.debtorInternalId + ((org == -1) ? "" : "?org=" + org)} />

                                        : <TextView label={t("party")} value={state.item?.debtorName == "" ? state.item?.debtorId : state.item?.debtorName + "(#" + (state.item?.debtorId ?? "") + ")"} />
                                    }
                                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                                        <TextView label={t("balanceBefore")} value={currencyFormatter(state.item?.debtorBalanceBefore, { significantDigits: state.item?.currencyDecimalPlaces ?? 9, symbol: state.item?.currencySymbol })} />
                                        <TextView label={t("balanceAfter")} value={currencyFormatter(state.item?.debtorBalanceAfter, { significantDigits: state.item?.currencyDecimalPlaces ?? 9, symbol: state.item?.currencySymbol })} />

                                    </Stack>
                                    <TextView label={t("description")} value={state.item?.debtorDescription} />
                                    <LinkView label={t("feeTransaction")} value={state.item?.debtorFeeTransactionId}
                                url={"/account-transactions/" + state.item?.debtorFeeTransactionInternalId + ((org == -1) ? "" : "?org=" + org)} />

                                </Stack>
                            </Stack>
                            
                            <TextView label={t("message")} value={state.item?.message} />

                            <Separator alignContent="start"><b>{t("otherInfo")}</b></Separator>

                            {state.item?.extraFields.map((e: DynamicField, index: number) => {
                                return <Stack key={e.key}>
                                    <TextView label={e.label ?? ""} value={e?.value} />

                                </Stack>
                            })}
                        </Stack>


                    </div>
            }


        </Stack>



    );
}




