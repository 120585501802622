import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, refreshSession, getSession, ACCOUNTANT_API_URL, TRADING_API_URL } from '../../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../../app/Helpers';
import { CurrencyPairEndpointClient } from '../../../../../repository/Trading/Currency_pairServiceClientPb';
import { DeleteCurrencyPairRequest, DeleteCurrencyPairResponse, GetCurrencyPairRequest, GetCurrencyPairResponse, UpdateCurrencyPairActivityRequest, UpdateCurrencyPairActivityResponse } from '../../../../../repository/Trading/currency_pair_pb';
import { DynamicField } from '../../../../common/DynamicFields/DynamicFields';


export interface CurrencyPairDetailsPageState {
    item: any
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    isLoading: boolean

}

const initialState: CurrencyPairDetailsPageState = {
    item: undefined,
    isChangeStateLoading: false,
    message: undefined,
    isLoading: false
}

const client = new CurrencyPairEndpointClient(TRADING_API_URL, null, null);

export const get = createAsyncThunk<GetCurrencyPairResponse.AsObject, APIRequest<GetCurrencyPairRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/trading/currencyPairDetails/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getCurrencyPair(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)




export const updateStatus = createAsyncThunk<APIResponse<UpdateCurrencyPairActivityResponse.AsObject>, APIRequest<UpdateCurrencyPairActivityRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/trading/currencyPairDetails/updateStatus',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.updateActivity(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId(), status: req.body.getIsactive() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)





export const deleteItem = createAsyncThunk<APIResponse<DeleteCurrencyPairResponse.AsObject>, APIRequest<DeleteCurrencyPairRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/trading/currencyPairDetails/delete',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.delete(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)

export const currencyPairDetailsPageSlice = createSlice({
    name: 'pages/trading/currencyPairDetails',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
        },

        resetItem: (state) => {
            state.item = undefined;

        },

        updateItem: (state, action: PayloadAction<any>) => {
            state.item = action.payload;

        }
    },
    extraReducers: (builder) => {
        builder.addCase(deleteItem.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
            }
        })
        builder.addCase(deleteItem.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(deleteItem.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

        builder.addCase(updateStatus.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                state.item.isActive = payload?.metadata.status
            }
        })
        builder.addCase(updateStatus.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateStatus.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

        builder.addCase(get.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {

                let rc = {
                    id: payload.success?.id,
                    name: payload.success?.name?.value,

                    iconSource: payload.success?.iconsource?.value ?? "",
                    categoryId: payload.success?.category?.id?.value ?? "",
                    categoryNameAr: payload.success?.category?.namear?.value ?? "",
                    categoryNameEn: payload.success?.category?.nameen?.value ?? "",
                    buyPriceEquation: payload.success?.pricetrigger?.buypriceequation?.value ?? "",
                    sellPriceEquation: payload.success?.pricetrigger?.sellpriceequation?.value ?? "",
                    tradingPriceEquation: payload.success?.pricetrigger?.tradingpriceequation?.value ?? "",
                    isPriceTriggerActive: payload.success?.pricetrigger?.isactive?.value,
                    mainCurrencyPair: payload.success?.pricetrigger?.main?.id?.value ?? "",
                    mainCurrencyPairName: payload.success?.pricetrigger?.main?.name?.value ?? "",
                    mainCurrencyPairBaseCurrencyId: payload.success?.pricetrigger?.main?.base?.id?.value ?? "",
                    mainCurrencyPairBaseCurrencyName: payload.success?.pricetrigger?.main?.base?.name?.value ?? "",
                    mainCurrencyPairBaseCurrencyCode: payload.success?.pricetrigger?.main?.base?.code?.value ?? "",
                    mainCurrencyPairBaseCurrencyDecimalPlaces: payload.success?.pricetrigger?.main?.base?.decimalplaces?.value ?? undefined,
                    mainCurrencyPairBaseCurrencyForm: payload.success?.pricetrigger?.main?.base?.form?.value ?? undefined,
                    mainCurrencyPairBaseCurrencySymbol: payload.success?.pricetrigger?.main?.base?.symbol?.value ?? "",
                    mainCurrencyPairQuoteCurrencyId: payload.success?.pricetrigger?.main?.quote?.id?.value ?? "",
                    mainCurrencyPairQuoteCurrencyName: payload.success?.pricetrigger?.main?.quote?.name?.value ?? "",
                    mainCurrencyPairQuoteCurrencyCode: payload.success?.pricetrigger?.main?.quote?.code?.value ?? "",
                    mainCurrencyPairQuoteCurrencyDecimalPlaces: payload.success?.pricetrigger?.main?.quote?.decimalplaces?.value ?? undefined,
                    mainCurrencyPairQuoteCurrencyForm: payload.success?.pricetrigger?.main?.quote?.form?.value ?? undefined,
                    mainCurrencyPairQuoteCurrencySymbol: payload.success?.pricetrigger?.main?.quote?.symbol?.value ?? "",



                    buyPrice: payload.success?.buyprice?.value,
                    sellPrice: payload.success?.sellprice?.value,
                    tradingPrice: payload.success?.tradingprice?.value,
                    isActive: payload.success?.isactive?.value,
                    syncPrices: payload.success?.syncprices?.value,
                    baseCurrencyId: payload.success?.base?.id?.value ?? "",
                    baseCurrencyName: payload.success?.base?.name?.value ?? "",
                    baseCurrencyCode: payload.success?.base?.code?.value ?? "",
                    baseCurrencyDecimalPlaces: payload.success?.base?.decimalplaces?.value ?? undefined,
                    baseCurrencyForm: payload.success?.base?.form?.value ?? undefined,
                    baseCurrencySymbol: payload.success?.base?.symbol?.value ?? "",
                    quoteCurrencyId: payload.success?.quote?.id?.value ?? "",
                    quoteCurrencyName: payload.success?.quote?.name?.value ?? "",
                    quoteCurrencyCode: payload.success?.quote?.code?.value ?? "",
                    quoteCurrencyDecimalPlaces: payload.success?.quote?.decimalplaces?.value ?? undefined,
                    quoteCurrencyForm: payload.success?.quote?.form?.value ?? undefined,
                    quoteCurrencySymbol: payload.success?.quote?.symbol?.value ?? "",
                    priceUpdate: formatDate(timestampToDate(payload.success?.priceupdate?.seconds, payload.success?.priceupdate?.nanos)),
                    createdDate: formatDate(timestampToDate(payload.success?.createddate?.seconds, payload.success?.createddate?.nanos)),
                    addedBy: (payload.success?.createdby?.firstname?.value ?? "") + " " + (payload.success?.createdby?.lastname?.value ?? ""),
                    addedById: payload.success?.createdby?.id?.value,
                }
                if (rc.name == undefined) {
                    rc.name = (rc?.baseCurrencyName == "" ? rc?.baseCurrencyId : rc?.baseCurrencyName + " (" + rc?.baseCurrencyCode + ")") + " / " + (rc?.quoteCurrencyName == "" ? rc?.quoteCurrencyId : rc?.quoteCurrencyName + " (" + rc?.quoteCurrencyCode + ")")
                }

                state.item = rc
            }
        })
        builder.addCase(get.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(get.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })
    }
})

export const { reset, dismissMessage, resetItem, updateItem } = currencyPairDetailsPageSlice.actions

export default currencyPairDetailsPageSlice.reducer