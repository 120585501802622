import { ComboBox, DefaultButton, DialogFooter, Icon, IconButton, IStackTokens, ISuggestionItemProps, ISuggestionModel, ISuggestionsProps, ITag, Label, MessageBar, MessageBarType, Modal, PrimaryButton, Spinner, SpinnerSize, Stack, TagItemSuggestion, TagPicker, Text, TextField, Toggle, TooltipHost } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { FormEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../../app/Hooks";
import { add, dismissMessage, get, reset, setStage1, update, getAccountTypes, getFees, resetAccountTypes, resetFees, setIsFilteredAccountTypesSet, setIsFilteredFeesSet, setSelectedAccountType, setSelectedFee } from "./TreasuryTypeRulesFormSlice";
import * as Yup from 'yup';
import { DynamicInputField, InputField } from "../../../controls/Controls";
import { inputs } from "../../../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../../../app/Api";
import { Message } from "../../../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../../../FormProps";
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../../../common/SessionExpiredDialog/SessionExpiredDialog";
import { formatDate, getOperations, normalizeKey, timestampToDate } from "../../../../../app/Helpers";
import { AuthenticateReply } from "../../../../../repository/UserManagement/authentication_pb";
import { DynamicField } from "../../../../common/DynamicFields/DynamicFields";
import { ExtraField } from "../../../../../repository/Accountant/common_pb";
import { AddAccountTypeRuleRequest, GetAccountTypeRuleRequest, UpdateAccountTypeRuleRequest, AddAccountTypeRuleResponse, UpdateAccountTypeRuleResponse } from "../../../../../repository/Accountant/account_type_rule_pb";
import { TableState } from "../../../../common/Table/TableSate";
import { GetAccountTypesRequest } from "../../../../../repository/Accountant/account_type_pb";
import { GetFeesRequest } from "../../../../../repository/Accountant/fee_pb";
import { Operations } from "../../../../../app/Enums";
import { GetTreasuryTypesRequest } from "../../../../../repository/Accountant/treasury_type_pb";
import { AddTreasuryTypeRuleRequest, AddTreasuryTypeRuleResponse, UpdateTreasuryTypeRuleRequest } from "../../../../../repository/Accountant/treasury_type_rule_pb";
import { List } from "../../../../common/List/List";
import { Popup } from "../../../../common/Popup/Popup";
import { AccountTypesPage } from "../../../../pages/Accountant/AccountTypes/AccountTypesPage";
import { FeesPage } from "../../../../pages/Accountant/Fees/FeesPage";


let req: AddTreasuryTypeRuleRequest;
let updateReq: UpdateTreasuryTypeRuleRequest;
let debetTypesReq: GetTreasuryTypesRequest;
let debtorFeesReq: GetFeesRequest;

let promise: any;
let getFilterPromise: any;
let org: number = -1;
let current: AuthenticateReply.AsObject;

export const TreasuryTypeRulesForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();

    const [currentAction, setCurrenctAction] = useState(0)
    const state: { isLoading: boolean, message: ApiMessage | undefined, stage1: any, fees: TableState, accountTypes: TableState } = useAppSelector((state) => {

        return {
            isLoading: state.treasuryTypeRulesForm.isLoading, message: state.treasuryTypeRulesForm.message, stage1: state.treasuryTypeRulesForm.stage1,
            accountTypes: state.treasuryTypeRulesForm.accountTypes,
            fees: state.treasuryTypeRulesForm.fees,

        }
    })

    useEffect(() => {
        var l = searchParams.get('org');
        req = new AddTreasuryTypeRuleRequest();
        updateReq = new UpdateTreasuryTypeRuleRequest()
        debetTypesReq = new GetAccountTypesRequest()
        debtorFeesReq = new GetFeesRequest()

        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                req.setOrganization(wrapper)
                debtorFeesReq.setOrganization(wrapper)
                debetTypesReq.setOrganization(wrapper)

            }
        }

        if (props.type == FormType.EDIT) {
            if (props.fetchData) {
                let r = new GetAccountTypeRuleRequest()
                r.setId(props?.renderObject?.id);
                promise?.abort()
                promise = dispatch(get({ body: r, headers: getHeaders() }))
            } else {
                dispatch(setStage1(props?.renderObject))
            }
        }
        debetTypesReq.setNumofresults(state.accountTypes.numberOfResults)
        debetTypesReq.setOrder(state.accountTypes.isDescending)
        debtorFeesReq.setNumofresults(state.fees.numberOfResults)
        debtorFeesReq.setOrder(state.fees.isDescending)
        const boolv = new BoolValue();
        boolv.setValue(true);
        debtorFeesReq.setIsavailable(boolv)
        debetTypesReq.setIsavailable(boolv)

        if (props.renderObject?.feeId)
            dispatch(setSelectedFee(([{ id: props.renderObject.feeId, name: props.renderObject.feeName }])))


        if (props.renderObject?.accountTypeId)
            dispatch(setSelectedAccountType(([{ id: props.renderObject.accountTypeId, name: props.renderObject.accountTypeName }])))


        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        }
        return () => { //clean up
            promise?.abort();
            dispatch(reset());
        }
    }, [])



    const onDebtorFeesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetFees())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        debtorFeesReq.setSearch(wrapper)
        debtorFeesReq.setNextto(undefined)
        dispatch(setIsFilteredFeesSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getFees({ body: debtorFeesReq, headers: getHeaders() }))
        return [];
    }

    const debetorTypesSuggestionsProps: ISuggestionsProps<ITag> = {
        suggestionsHeaderText: t('accountType'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.accountTypes.hasMore && !state.accountTypes.isFetching,
        onRenderSuggestion: (props: ITag, suggestionItemProps: ISuggestionItemProps<ITag>) => {
            return <TagItemSuggestion>
                {props?.name ?? props?.key}
            </TagItemSuggestion>
        },
        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.accountTypes.items.findIndex(e => e.id == item.key)
            if (u >= 0) {
                dispatch(setSelectedAccountType([state.accountTypes.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.accountTypes.items.length > 0 && !state.accountTypes.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.accountTypes.items.at(state.accountTypes.items.length - 1).id);
                debetTypesReq.setNextto(wrapper)
                getFilterPromise = dispatch(getAccountTypes({ body: debetTypesReq, headers: getHeaders() }))
            }
        },
        suggestions: state.accountTypes.items.length > 0 ? state.accountTypes.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : [],
        isLoading: state.accountTypes.items.length == 0 ? state.accountTypes.isFetching : false,
        isSearching: state.accountTypes.items.length > 0 ? state.accountTypes.isFetching : false,


    };
    const onDebetorTypesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetAccountTypes())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        debetTypesReq.setSearch(wrapper)
        debetTypesReq.setNextto(undefined)
        dispatch(setIsFilteredAccountTypesSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getAccountTypes({ body: debetTypesReq, headers: getHeaders() }))
        return [];

    }



    return (
        <>
            <Popup isOpen={(currentAction == 100)} title={t("accountTypes")} onDismiss={() => { setCurrenctAction(0) }} >

                <AccountTypesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedAccountType([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Popup isOpen={(currentAction == 101)} title={t("fees")} onDismiss={() => { setCurrenctAction(0) }} >

                <FeesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedFee([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    operation: (props.renderObject?.operation) ? { key: props.renderObject?.operation, text: "" } : { key: 1, text: t("queryBalance") },
                    fee: undefined,
                    accountType: undefined,
                    isActive: (props.renderObject) ? props.renderObject.isActive == undefined ? true : props.renderObject.isActive : true,
                    extraFields: (state.stage1?.extraFields) ? state.stage1?.extraFields as DynamicField[] : [] as DynamicField[],
                    directReservation: false,

                }}

                validationSchema={Yup.object({

                    extraFields: inputs.extraFields,

                })}

                onSubmit={(values, actions) => {
                    if (props.type == FormType.ADD) {

                        let accountType = state.accountTypes.selected.at(0);


                        let fee = state.fees.selected.at(0);


                        req.setTreasurytype(props.renderObject?.treasuryType?.id);
                        if (accountType) {
                            let r = new StringValue();
                            r.setValue(accountType.id)
                            req.setAccounttype(r);
                        }

                        req.setIsactive(values.isActive)
                        req.setOperation(values.operation.key)
                        req.setFee(fee?.id)
                        req.setDirectreservation(values.directReservation)

                        const extraField: ExtraField[] = [];
                        const count: Number = 1;
                        values.extraFields.forEach((e: DynamicField) => {
                            const r = new ExtraField();
                            r.setKey(e.key);
                            r.setLabel(e.label?.trim() ?? "")
                            r.setType(1)
                            r.setValue(e.value.trim())
                            extraField.push(r)
                        })
                        req.setExtrafieldsList(extraField)
                        promise = dispatch(add({ body: req, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: AddTreasuryTypeRuleResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            id: res.success?.id,
                                            treasuryTypeId: props.renderObject?.treasuryType?.id,
                                            treasuryTypeName: props.renderObject?.treasuryType?.name,
                                            accountTypeId: accountType?.id,
                                            accountTypeName: accountType?.name,
                                            operation: values.operation.key,
                                            feeId: fee?.id,
                                            feeName: fee?.name,
                                            isActive: values.isActive,
                                            createdDate: formatDate(timestampToDate(res.success?.createddate?.seconds, res.success?.createddate?.nanos)),
                                            addedBy: (current.profile?.firstname?.value ?? "") + " " + (current.profile?.lastname?.value ?? ""),
                                            addedById: current.profile?.id?.value,
                                            directReservation: values.directReservation

                                        });
                                    }
                                }
                                actions.setSubmitting(false)


                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    } else {
                        let accountType = state.accountTypes.selected.at(0);
                        let fee = state.fees?.selected?.at(0);
                        if (accountType) {
                            let r = new StringValue();
                            r.setValue(accountType.id)
                            updateReq.setAccounttype(r);
                        }
                        updateReq.setId(props.renderObject?.id)
                        updateReq.setFee(fee?.id)
                        const extraField: ExtraField[] = [];
                        const count: Number = 1;
                        values.extraFields.forEach((e: DynamicField) => {
                            const r = new ExtraField();
                            r.setKey(e.key);
                            r.setLabel(e.label?.trim() ?? "")
                            r.setType(1)
                            r.setValue(e.value.trim())
                            extraField.push(r)
                        })
                        updateReq.setExtrafieldsList(extraField)
                        updateReq.setId(props.renderObject?.id)
                        promise = dispatch(update({ body: updateReq, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: UpdateAccountTypeRuleResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            ...props.renderObject,
                                            accountTypeId: accountType?.id,
                                            accountTypeName: accountType?.name,
                                            feeId: fee?.id,
                                            feeName: fee?.name,
                                            extraFields: values.extraFields
                                        });
                                    }
                                }
                                actions.setSubmitting(false)

                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    }
                }}

            >
                {formkikProps => (
                    <Form >
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? (state.message.data != 401) ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            /> :
                                <SessionExpiredDialog />
                                : null
                            }

                            {props.type == FormType.ADD ? <ComboBox
                                label={t("operation")}
                                allowFreeform={true}
                                autoComplete={'on'}
                                disabled={state.isLoading}
                                options={getOperations(undefined, 3, false).map(o => {
                                    return { key: o.key, text: t(normalizeKey(o.text)) }
                                })}
                                selectedKey={formkikProps.values.operation.key}
                                onChange={(e, o) => {
                                    formkikProps.setFieldValue("operation", { key: o?.key, text: o?.text })
                                }}
                            // Force re-creating the component when the toggles change (for demo purposes)
                            /> : undefined}
                            {formkikProps.values.operation.key == Operations.DEPOSIT || formkikProps.values.operation.key == Operations.WITHDRAW
                                || formkikProps.values.operation.key == Operations.EXTERNAL_TRADING_ORDER_BUY_DEPOSIT || formkikProps.values.operation.key == Operations.EXTERNAL_TRADING_ORDER_BUY_WITHDRAW
                                || formkikProps.values.operation.key == Operations.EXTERNAL_TRADING_ORDER_SELL_DEPOSIT || formkikProps.values.operation.key == Operations.EXTERNAL_TRADING_ORDER_SELL_WITHDRAW

                                ?
                                <Stack>
                                    <Label disabled={state.isLoading}>{
                                        <div>
                                            {t("accountType") + ' '}
                                            <TooltipHost content={t("accountTypeTreasuryRuleDescription")}>
                                                <Icon iconName="Info" aria-label={t("accountType")} />
                                            </TooltipHost>
                                        </div>
                                    }</Label>
                                    <List
                                        inputProps={{ placeholder: t("accountType"), required: true }}
                                        disabled={state.isLoading}
                                        suggestionsHeaderText={t('accountTypes')}
                                        isLoading={state.accountTypes.items.length == 0 ? state.accountTypes.isFetching : false}
                                        isSearching={state.accountTypes.items.length > 0 ? state.accountTypes.isFetching : false}
                                        moreSuggestionsAvailable={state.accountTypes.hasMore && !state.accountTypes.isFetching}
                                        suggestions={state.accountTypes.items.length > 0 ? state.accountTypes.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : []}
                                        onGetMoreResults={() => {
                                            if (state.accountTypes.items.length > 0 && !state.accountTypes.isFetching) {
                                                const wrapper = new StringValue();
                                                wrapper.setValue(state.accountTypes.items.at(state.accountTypes.items.length - 1).id);
                                                debetTypesReq.setNextto(wrapper)
                                                getFilterPromise = dispatch(getAccountTypes({ body: debetTypesReq, headers: getHeaders() }))
                                            }
                                        }}
                                        onSuggestionClick={(ev?, item?: any, index?: number) => {
                                            var u = state.accountTypes.items.findIndex(e => e.id == item.key)
                                            if (u >= 0) {
                                                dispatch(setSelectedAccountType([state.accountTypes.items.at(u)]))
                                            }
                                        }}
                                        isPeoplePicker={false}
                                        selectedItems={state.accountTypes.selected.length > 0 ? state.accountTypes.selected.map(e => { return { name: e.name, key: e.id } as ITag }) : []}
                                        onChange={(e) => {
                                            dispatch(setSelectedAccountType([]))

                                        }}
                                        onEmptyInputFocus={() => {
                                            dispatch(resetAccountTypes())
                                            debetTypesReq.setSearch(undefined)
                                            debetTypesReq.setNextto(undefined)
                                            dispatch(setIsFilteredAccountTypesSet(false))
                                            getFilterPromise?.abort()
                                            getFilterPromise = dispatch(getAccountTypes({ body: debtorFeesReq, headers: getHeaders() }))
                                            return []
                                        }}
                                        onFilterChanged={onDebetorTypesFilterChanged}
                                        endButtons={[
                                            {
                                                title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                                onClick: () => {
                                                    setCurrenctAction(100)

                                                }
                                            }]}
                                    />
                                </Stack> : undefined}



                            {formkikProps.values.operation.key == Operations.DEPOSIT || formkikProps.values.operation.key == Operations.WITHDRAW
                                || formkikProps.values.operation.key == Operations.EXTERNAL_TRADING_ORDER_BUY_DEPOSIT || formkikProps.values.operation.key == Operations.EXTERNAL_TRADING_ORDER_BUY_WITHDRAW
                                || formkikProps.values.operation.key == Operations.EXTERNAL_TRADING_ORDER_SELL_DEPOSIT || formkikProps.values.operation.key == Operations.EXTERNAL_TRADING_ORDER_SELL_WITHDRAW ? <Stack>
                                <Label disabled={state.isLoading}>{
                                    <div>
                                        {t("fees") + ' '}
                                        <TooltipHost content={t("feesDescription")}>
                                            <Icon iconName="Info" aria-label={t("fees")} />
                                        </TooltipHost>
                                    </div>
                                }</Label>
                                <List
                                    inputProps={{ placeholder: t("fees") }}
                                    disabled={state.isLoading}
                                    suggestionsHeaderText={t('fees')}
                                    isLoading={state.fees.items.length == 0 ? state.fees.isFetching : false}
                                    isSearching={state.fees.items.length > 0 ? state.fees.isFetching : false}
                                    moreSuggestionsAvailable={state.fees.hasMore && !state.fees.isFetching}
                                    suggestions={state.fees.items.length > 0 ? state.fees.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : []}
                                    onGetMoreResults={() => {
                                        if (state.fees.items.length > 0 && !state.fees.isFetching) {
                                            const wrapper = new StringValue();
                                            wrapper.setValue(state.fees.items.at(state.fees.items.length - 1).id);
                                            debtorFeesReq.setNextto(wrapper)
                                            getFilterPromise = dispatch(getFees({ body: debtorFeesReq, headers: getHeaders() }))
                                        }
                                    }}
                                    onSuggestionClick={(ev?, item?: any, index?: number) => {
                                        var u = state.fees.items.findIndex(e => e.id == item.key)
                                        if (u >= 0) {
                                            dispatch(setSelectedFee([state.fees.items.at(u)]))
                                        }
                                    }}
                                    isPeoplePicker={false}
                                    selectedItems={state.fees.selected.length > 0 ? state.fees.selected.map(e => { return { name: e.name, key: e.id } as ITag }) : []}
                                    onChange={(e) => {
                                        dispatch(setSelectedFee([]))

                                    }}
                                    onEmptyInputFocus={() => {
                                        dispatch(resetFees())
                                        debtorFeesReq.setSearch(undefined)
                                        debtorFeesReq.setNextto(undefined)
                                        dispatch(setIsFilteredFeesSet(false))
                                        getFilterPromise?.abort()
                                        getFilterPromise = dispatch(getFees({ body: debtorFeesReq, headers: getHeaders() }))
                                        return []
                                    }}
                                    onFilterChanged={onDebtorFeesFilterChanged}
                                    endButtons={[
                                        {
                                            title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                            onClick: () => {
                                                setCurrenctAction(101)

                                            }
                                        }]}
                                />
                            </Stack> : undefined}


                            {props.type == FormType.ADD && formkikProps.values.operation.key != Operations.QUERY_BALANCE 
                                && formkikProps.values.operation.key != Operations.ISSUE_STATEMENT
                                && formkikProps.values.operation.key != Operations.WITHDRAW ? <Stack>
                                <Label disabled={state.isLoading}>{
                                    <div>
                                        {t("directReservation") + ' '}
                                        <TooltipHost content={t("directReservationDesc")}>
                                            <Icon iconName="Info" aria-label={t("openAccountByDefault")} />
                                        </TooltipHost>
                                    </div>
                                }</Label>
                                <Toggle

                                    onText={t("enabled")} offText={t("disabled")}
                                    disabled={state.isLoading}
                                    checked={formkikProps.values.directReservation}
                                    onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                                        formkikProps.setFieldValue('directReservation', checked);
                                    }
                                    }
                                /> </Stack> : undefined}

                            {props.type == FormType.ADD ? <Toggle

                                onText={t("active")} offText={t("inactive")}
                                label={t("status")}
                                disabled={state.isLoading}

                                checked={formkikProps.values.isActive}
                                onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                                    formkikProps.setFieldValue('isActive', checked);
                                }
                                }
                            /> : undefined}
                            <Label disabled={state.isLoading}>{
                                <div>
                                    {t("extraFields") + ' '}
                                    <TooltipHost content={t("extraInformationDescription")}>
                                        <Icon iconName="Info" aria-label={t("extraFields")} />
                                    </TooltipHost>
                                </div>
                            }</Label>
                            <FieldArray
                                name="extraFields"
                                validateOnChange={false}

                            >
                                {arrayHelpers => {
                                    let r = [] as React.ReactNode[];
                                    if (formkikProps.values.extraFields && formkikProps.values.extraFields.length > 0) {
                                        r = formkikProps.values.extraFields.map((o, index) => {
                                            if (o.type == 1) {
                                                return (<Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: props.childrenGap }} key={index}>
                                                    {o.label != undefined ? <Stack.Item grow> <DynamicInputField name={`extraFields[${index}].label`} placeholder={t("extraFieldLabel")} disabled={state.isLoading}
                                                        maxLength={50} autoFocus={(o as any)?.autoFocus} /> </Stack.Item> : null}
                                                    <Stack.Item grow>  <DynamicInputField name={`extraFields[${index}].value`} placeholder={t("extraFieldValue")} disabled={state.isLoading}
                                                        maxLength={50}
                                                    /></Stack.Item>

                                                    <IconButton disabled={state.isLoading}
                                                        iconProps={{ iconName: "remove" }} onClick={() => {
                                                            arrayHelpers.remove(index)
                                                        }} />
                                                </Stack>)
                                            }
                                        }
                                        )
                                    }
                                    if (formkikProps.values.extraFields.length <= 4) {
                                        r.push(< IconButton disabled={state.isLoading}
                                            key={"addButton"} iconProps={{ iconName: "add" }} onClick={() => {
                                                arrayHelpers.push({ key: (formkikProps.values.extraFields.length + 1) + "", label: "", type: 1, value: "", autoFocus: true })
                                            }} />)
                                    }
                                    return r;
                                }
                                }

                            </FieldArray>
                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("add") : t("edit")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={props.type == FormType.ADD ? t("discard") : t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>

                    </Form>
                )}
            </Formik>
        </>
    );

}

