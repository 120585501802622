import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIResponse, refreshSession, getSession, ACCOUNTANT_API_URL, APIRequest } from '../../../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../../../app/Helpers';
import { GetBalanceRequest, GetBalanceResponse } from '../../../../../../repository/Accountant/treasury_operation_pb';
import { LimitationEndpointClient } from '../../../../../../repository/Accountant/LimitationServiceClientPb';
import { GetLimitationsRequest, GetLimitationsResponse } from '../../../../../../repository/Accountant/limitation_pb';
import { TreasuryEndpointClient } from '../../../../../../repository/Accountant/TreasuryServiceClientPb';
import { GetTreasuryRequest, GetTreasuryResponse, UpdateTreasuryCurrencyActivityRequest, UpdateTreasuryCurrencyActivityResponse } from '../../../../../../repository/Accountant/treasury_pb';
import { DynamicField } from '../../../../../common/DynamicFields/DynamicFields';
import { TableState } from '../../../../../common/Table/TableSate';
import { TreasuryOperationEndpointClient } from '../../../../../../repository/Accountant/Treasury_operationServiceClientPb';


export interface TreasuryDepositsPageState {
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    isLoading: boolean,
    set: TableState
    searchText: string | undefined,

}

const initialState: TreasuryDepositsPageState = {
    isChangeStateLoading: false,
    message: undefined,
    isLoading: false,
    set: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    searchText: undefined,

}

const client = new TreasuryEndpointClient(ACCOUNTANT_API_URL, null, null);
const clientOp = new TreasuryOperationEndpointClient(ACCOUNTANT_API_URL, null, null);


export const get = createAsyncThunk<GetTreasuryResponse.AsObject, APIRequest<GetTreasuryRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/treasuryDetails/treasuryDeposits/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getTreasury(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)


export const getBalance = createAsyncThunk<APIResponse<GetBalanceResponse.AsObject>, APIRequest<GetBalanceRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/treasuryDetails/treasuryDeposits/getBalance',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await clientOp.getBalance(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getJsPbMessageId(), currency: req.body.getCurrencyid() }, response: r.toObject() }; 
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)

export const updateStatus = createAsyncThunk<APIResponse<UpdateTreasuryCurrencyActivityResponse.AsObject>, APIRequest<UpdateTreasuryCurrencyActivityRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/treasuryDetails/treasuryDeposits/updateStatus',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.updateCurrencyActivity(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId(), state: req.body.getIsactive(), currency: req.body.getCurrency() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)


export const treasuryDepositsPageSlice = createSlice({
    name: 'pages/accountant/treasuryDetails/treasuryDeposits',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
            state.set = initialState.set;
            state.searchText = initialState.searchText;
            state.isLoading = initialState.isLoading;
        },

        resetSet: (state) => {
            state.set.items = [];
            state.set.hasMore = true;
        },
        setItems: (state, action: PayloadAction<any>) => {
            state.set.items = action.payload;
        },
        setSort: (state, action: PayloadAction<boolean>) => {
            state.set.isDescending = action.payload;
        },
        setNumberOfResults: (state, action: PayloadAction<number>) => {
            state.set.numberOfResults = action.payload;
        },
        setSelectedItems: (state, action: PayloadAction<any[]>) => {
            state.set.selected = action.payload;
        },
        setSearchText: (state, action: PayloadAction<string | undefined>) => {
            state.searchText = action.payload;
        },
        setIsFilteredSet: (state, action: PayloadAction<boolean>) => {
            state.set.isFilteredSet = action.payload;
        },
        addItem: (state, action: PayloadAction<any>) => {
            if (!state.set.isFilteredSet) {
                if (!state.set.hasMore && !state.set.isDescending) {
                    state.set.items.push(action.payload);
                } else if (state.set.isDescending) {
                    state.set.items.unshift(action.payload);
                }
            }
        },
        updateItem: (state, action: PayloadAction<any>) => {
            let selected = state.set.selected;
            if (action?.payload) {
                if (selected.length > 0 && selected.at(0).id == action?.payload?.id) {
                    selected[0] = action?.payload
                }
                var u = state.set.items.findIndex(e => e.id == action?.payload?.id)
                if (u >= 0) {
                    state.set.items[u] = action?.payload
                }
            }
        },
        setMessage: (state, action: PayloadAction<ApiMessage>) => {
            state.message = action.payload;
        },

    },
    extraReducers: (builder) => {
        builder.addCase(updateStatus.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                let selected = state.set.selected;
                if (payload?.metadata) {
                    if (selected.length > 0 && selected.at(0).currencyId == payload?.metadata.currency) {
                        selected.at(0).isActive = payload?.metadata.state
                    }
                    var u = state.set.items.findIndex(e => e.currencyId == payload?.metadata.currency)
                    if (u >= 0) {
                        state.set.items.at(u).isActive = payload?.metadata.state
                    }

                }
            }
        })
        builder.addCase(updateStatus.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateStatus.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })
    
        builder.addCase(getBalance.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                let selected = state.set.selected;
                if (payload?.metadata) {
                    if (selected.length > 0 && selected.at(0).currencyId == payload?.metadata.currency) {
                        selected.at(0).balance = payload?.response?.success?.balance?.value
                        selected.at(0).isLoading = false
                    }
                    var u = state.set.items.findIndex(e => e.currencyId == payload?.metadata.currency)
                    if (u >= 0) {
                        state.set.items.at(u).balance = payload?.response?.success?.balance?.value
                        state.set.items.at(u).isLoading = false
                    }

                }
            }
        })
        builder.addCase(getBalance.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.meta) {
                if (action.meta.arg) {
                    var u = state.set.items.findIndex(e => e.currencyId == action.meta.arg.body.getCurrencyid())
                    if (u >= 0) {
                        state.set.items.at(u).isLoading = false;
                    }
                }
            }
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getBalance.pending, (state, action) => {
            state.isChangeStateLoading = true;
            if (action.meta) {
                if (action.meta.arg) {
                    var u = state.set.items.findIndex(e => e.currencyId == action.meta.arg.body.getCurrencyid())
                    if (u >= 0) {
                        state.set.items.at(u).isLoading = true;
                    }
                }
            }
            state.message = undefined;
        })
        
   
        builder.addCase(get.fulfilled, (state, { payload }) => {
            if (payload) {
                let r = {
                    id: payload.success?.id,
                    internalId: payload.success?.internalid,
                    name: payload.success?.name?.value ?? "",
                    code: payload.success?.code?.value ?? "",
                    nature: payload.success?.nature?.id?.value ?? undefined,
                    treasuryTypeId: payload.success?.treasurytype?.id?.value ?? "",
                    treasuryTypeName: payload.success?.treasurytype?.name?.value ?? "",
                    status: payload.success?.status?.value ?? undefined,
                    createdDate: formatDate(timestampToDate(payload.success?.createddate?.seconds, payload.success?.createddate?.nanos)),
                    addedBy: (payload.success?.createdby?.firstname?.value ?? "") + " " + (payload.success?.createdby?.lastname?.value ?? ""),
                    addedById: payload.success?.createdby?.id?.value,
                    limitationId: payload.success?.limitation?.id?.value ?? "",
                    limitationName: payload.success?.limitation?.name?.value ?? "",
                    extraFields: [] as DynamicField[],
                    deposits: [] as any
                }

                if (payload?.success?.extrafieldsList) {
                    let count = 0
                    payload?.success?.extrafieldsList?.forEach(element => {
                        r.extraFields.push({ key: element.key, label: element.label, type: element.type, value: element.value })
                        count++
                    });
                }

                if (payload?.success?.depositsList) {
                    let count = 0
                    payload?.success?.depositsList?.forEach(element => {
                        r.deposits.push({  currencyId: element?.currency?.id?.value ?? "",
                        currencyName: element?.currency?.name?.value ?? "",
                        currencyCode: element?.currency?.code?.value ?? "",
                        currencyDecimalPlaces: element?.currency?.decimalplaces?.value ?? undefined,
                        currencyForm: element?.currency?.form?.value ?? undefined,
                        currencySymbol: element?.currency?.symbol?.value ?? "",
                        createdDate: formatDate(timestampToDate(element?.createddate?.seconds, element?.createddate?.nanos)),
                        addedBy: (element?.createdby?.firstname?.value ?? "") + " " + (element?.createdby?.lastname?.value ?? ""),
                        addedById: element?.createdby?.id?.value,
                        isLoading: false,
                        isActive: element.isactive?.value ?? undefined})
                        count++
                    });
                }

                state.set.items = r.deposits
                state.set.hasMore = false;
                state.set.isFetching = false;
            }
            state.isLoading = false
            state.set.isFetching = false;

        })
        builder.addCase(get.rejected, (state, action) => {
            state.isLoading = false;
            state.set.isFetching = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(get.pending, (state, action) => {
            state.isLoading = true;
            state.set.isFetching = true;
            state.message = undefined;
        })
    }
})

export const { reset, dismissMessage, setMessage, updateItem, resetSet, setItems, setIsFilteredSet, setNumberOfResults, setSelectedItems, setSearchText, setSort, addItem } = treasuryDepositsPageSlice.actions

export default treasuryDepositsPageSlice.reducer