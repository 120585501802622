import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, refreshSession, getSession, ACCOUNTANT_API_URL, APIRequest, APIResponse } from '../../../../app/Api';
import { AccountTypeEndpointClient } from '../../../../repository/Accountant/Account_typeServiceClientPb';
import { GetAccountTypesResponse, GetAccountTypesRequest } from '../../../../repository/Accountant/account_type_pb';
import { formatDate, timestampToDate } from '../../../../app/Helpers';
import { AuthorizationEndpointClient } from '../../../../repository/Accountant/AuthorizationServiceClientPb';
import { AddAuthorizationRequest, AddAuthorizationResponse, GenerateTriggeredTransferTokenRequest, GenerateTriggeredTransferTokenResponse, UpdateAuthorizationResponse, GetAuthorizationRequest, GetAuthorizationResponse, UpdateAuthorizationRequest, GenerateSignaturesResponse, GenerateSignaturesRequest } from '../../../../repository/Accountant/authorization_pb';
import { DynamicField } from '../../../common/DynamicFields/DynamicFields';


export interface AuthorizationFormState {
    isLoading: boolean
    message?: ApiMessage
    stage1: any,


}

const initialState: AuthorizationFormState = {
    isLoading: false,
    message: undefined,
    stage1: undefined,

}


const client = new AuthorizationEndpointClient(ACCOUNTANT_API_URL, null, null);

export const add = createAsyncThunk<AddAuthorizationResponse.AsObject, APIRequest<AddAuthorizationRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/authorization/add',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.add(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)

export const generateTriggeredTransferToken = createAsyncThunk<GenerateTriggeredTransferTokenResponse.AsObject, APIRequest<GenerateTriggeredTransferTokenRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/authorization/generateTriggeredTransferToken',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.generateTriggeredTransferToken(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)


export const generateSignatures = createAsyncThunk<GenerateSignaturesResponse.AsObject, APIRequest<GenerateSignaturesRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/authorization/generateSignatures',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.generateSignatures(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)




export const update = createAsyncThunk<UpdateAuthorizationResponse.AsObject, APIRequest<UpdateAuthorizationRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/authorization/update',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.update(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)



export const get = createAsyncThunk<GetAuthorizationResponse.AsObject, APIRequest<GetAuthorizationRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/authorization/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getAuthorization(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)




export const authorizationFormSlice = createSlice({
    name: 'forms/accountant/authorization',
    initialState,
    reducers: {

        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.isLoading = false;
            state.message = undefined;
            state.stage1 = initialState.stage1;
        },
        setStage1: (state, action: PayloadAction<any>) => {
            let stage1 = {
                account: action.payload.account,
                authorization: action.payload.authorization,
                extraFields: action.payload.extraFields,



            }
            state.stage1 = stage1;
        },
        setMessage: (state, action: PayloadAction<ApiMessage>) => {
            state.message = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(add.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            if (payload)
                state.message = toApiMessage(payload.success?.message);

        })
        builder.addCase(add.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(add.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })


        builder.addCase(get.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {

                let r = {
                    id: payload.success?.id,
                    accountId: payload.success?.account?.id?.value,
                    name: payload.success?.account?.name?.value ?? "",
                    token: payload.success?.token?.value ?? "",
                    accountCurrencyId: payload.success?.account?.currency?.id?.value ?? "",
                    accountCurrencyName: payload.success?.account?.currency?.name?.value ?? "",
                    accountCurrencyCode: payload.success?.account?.currency?.code?.value ?? "",
                    accountCurrencyDecimalPlaces: payload.success?.account?.currency?.decimalplaces?.value ?? undefined,
                    accountCurrencyForm: payload.success?.account?.currency?.form?.value ?? undefined,
                    accountCurrencySymbol: payload.success?.account?.currency?.symbol?.value ?? "",
                    expirationDate: formatDate(timestampToDate(payload.success?.expirationdate?.seconds, payload.success?.expirationdate?.nanos)),
                    isRequest: payload.success?.isrequest?.value,
                    status: payload.success?.status?.id?.value,
                    operation: payload.success?.operation?.value,
                    extraFields: [] as DynamicField[],
                    createdDate: formatDate(timestampToDate(payload.success?.createddate?.seconds, payload.success?.createddate?.nanos)),
                    createdBy: (payload.success?.createdby?.firstname?.value ?? "") + " " + (payload.success?.createdby?.lastname?.value ?? ""),
                    createdById: payload.success?.createdby?.id?.value,


                }

                if (payload?.success?.extrafieldsList) {
                    let count = 0
                    payload?.success?.extrafieldsList?.forEach(element => {
                        r.extraFields.push({ key: element.key, label: element.label, type: element.type, value: element.value })
                        count++
                    });
                }
                state.stage1 = r
            }
        })
        builder.addCase(get.rejected, (state, action) => {
            state.isLoading = false;
         

            if (action.payload?.data != 73) {
                state.message = action.payload;
            }
        })
        builder.addCase(get.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })
        builder.addCase(update.fulfilled, (state, { payload }) => {
            if (payload)
                state.message = toApiMessage(payload.success?.message);
            state.isLoading = false;
        })
        builder.addCase(update.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(update.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })


        builder.addCase(generateTriggeredTransferToken.fulfilled, (state, { payload }) => {
            state.isLoading = false;
        })
        builder.addCase(generateTriggeredTransferToken.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(generateTriggeredTransferToken.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })

        builder.addCase(generateSignatures.fulfilled, (state, { payload }) => {
            state.isLoading = false;
        })
        builder.addCase(generateSignatures.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(generateSignatures.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })



    }
})

export const { reset, dismissMessage, setStage1, setMessage } = authorizationFormSlice.actions

export default authorizationFormSlice.reducer


