// source: fee.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('./common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.fee_endpoint.AddFeeCollectionAccountReplay', null, global);
goog.exportSymbol('proto.fee_endpoint.AddFeeCollectionAccountRequest', null, global);
goog.exportSymbol('proto.fee_endpoint.AddFeeCollectionAccountResponse', null, global);
goog.exportSymbol('proto.fee_endpoint.AddFeeCollectionAccountResponse.ResultCase', null, global);
goog.exportSymbol('proto.fee_endpoint.AddFeeReplay', null, global);
goog.exportSymbol('proto.fee_endpoint.AddFeeRequest', null, global);
goog.exportSymbol('proto.fee_endpoint.AddFeeResponse', null, global);
goog.exportSymbol('proto.fee_endpoint.AddFeeResponse.ResultCase', null, global);
goog.exportSymbol('proto.fee_endpoint.CollectionAccountResponse', null, global);
goog.exportSymbol('proto.fee_endpoint.DeleteFeeReplay', null, global);
goog.exportSymbol('proto.fee_endpoint.DeleteFeeRequest', null, global);
goog.exportSymbol('proto.fee_endpoint.DeleteFeeResponse', null, global);
goog.exportSymbol('proto.fee_endpoint.DeleteFeeResponse.ResultCase', null, global);
goog.exportSymbol('proto.fee_endpoint.Fee', null, global);
goog.exportSymbol('proto.fee_endpoint.FeeEntry', null, global);
goog.exportSymbol('proto.fee_endpoint.GetFeeReplay', null, global);
goog.exportSymbol('proto.fee_endpoint.GetFeeRequest', null, global);
goog.exportSymbol('proto.fee_endpoint.GetFeeResponse', null, global);
goog.exportSymbol('proto.fee_endpoint.GetFeeResponse.ResultCase', null, global);
goog.exportSymbol('proto.fee_endpoint.GetFeesReplay', null, global);
goog.exportSymbol('proto.fee_endpoint.GetFeesRequest', null, global);
goog.exportSymbol('proto.fee_endpoint.GetFeesResponse', null, global);
goog.exportSymbol('proto.fee_endpoint.GetFeesResponse.ResultCase', null, global);
goog.exportSymbol('proto.fee_endpoint.RemoveFeeCollectionAccountReplay', null, global);
goog.exportSymbol('proto.fee_endpoint.RemoveFeeCollectionAccountRequest', null, global);
goog.exportSymbol('proto.fee_endpoint.RemoveFeeCollectionAccountResponse', null, global);
goog.exportSymbol('proto.fee_endpoint.RemoveFeeCollectionAccountResponse.ResultCase', null, global);
goog.exportSymbol('proto.fee_endpoint.UpdateFeeActivityReplay', null, global);
goog.exportSymbol('proto.fee_endpoint.UpdateFeeActivityRequest', null, global);
goog.exportSymbol('proto.fee_endpoint.UpdateFeeActivityResponse', null, global);
goog.exportSymbol('proto.fee_endpoint.UpdateFeeActivityResponse.ResultCase', null, global);
goog.exportSymbol('proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay', null, global);
goog.exportSymbol('proto.fee_endpoint.UpdateFeeCollectionAccountActivityRequest', null, global);
goog.exportSymbol('proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse', null, global);
goog.exportSymbol('proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse.ResultCase', null, global);
goog.exportSymbol('proto.fee_endpoint.UpdateFeeReplay', null, global);
goog.exportSymbol('proto.fee_endpoint.UpdateFeeRequest', null, global);
goog.exportSymbol('proto.fee_endpoint.UpdateFeeResponse', null, global);
goog.exportSymbol('proto.fee_endpoint.UpdateFeeResponse.ResultCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fee_endpoint.FeeEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fee_endpoint.FeeEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fee_endpoint.FeeEntry.displayName = 'proto.fee_endpoint.FeeEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fee_endpoint.AddFeeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fee_endpoint.AddFeeRequest.repeatedFields_, null);
};
goog.inherits(proto.fee_endpoint.AddFeeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fee_endpoint.AddFeeRequest.displayName = 'proto.fee_endpoint.AddFeeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fee_endpoint.UpdateFeeActivityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fee_endpoint.UpdateFeeActivityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fee_endpoint.UpdateFeeActivityRequest.displayName = 'proto.fee_endpoint.UpdateFeeActivityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fee_endpoint.UpdateFeeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fee_endpoint.UpdateFeeRequest.repeatedFields_, null);
};
goog.inherits(proto.fee_endpoint.UpdateFeeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fee_endpoint.UpdateFeeRequest.displayName = 'proto.fee_endpoint.UpdateFeeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fee_endpoint.DeleteFeeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fee_endpoint.DeleteFeeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fee_endpoint.DeleteFeeRequest.displayName = 'proto.fee_endpoint.DeleteFeeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fee_endpoint.GetFeeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fee_endpoint.GetFeeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fee_endpoint.GetFeeRequest.displayName = 'proto.fee_endpoint.GetFeeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fee_endpoint.GetFeesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fee_endpoint.GetFeesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fee_endpoint.GetFeesRequest.displayName = 'proto.fee_endpoint.GetFeesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fee_endpoint.AddFeeCollectionAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fee_endpoint.AddFeeCollectionAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fee_endpoint.AddFeeCollectionAccountRequest.displayName = 'proto.fee_endpoint.AddFeeCollectionAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fee_endpoint.UpdateFeeCollectionAccountActivityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fee_endpoint.UpdateFeeCollectionAccountActivityRequest.displayName = 'proto.fee_endpoint.UpdateFeeCollectionAccountActivityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fee_endpoint.RemoveFeeCollectionAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fee_endpoint.RemoveFeeCollectionAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fee_endpoint.RemoveFeeCollectionAccountRequest.displayName = 'proto.fee_endpoint.RemoveFeeCollectionAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fee_endpoint.AddFeeReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fee_endpoint.AddFeeReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fee_endpoint.AddFeeReplay.displayName = 'proto.fee_endpoint.AddFeeReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fee_endpoint.UpdateFeeReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fee_endpoint.UpdateFeeReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fee_endpoint.UpdateFeeReplay.displayName = 'proto.fee_endpoint.UpdateFeeReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fee_endpoint.UpdateFeeActivityReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fee_endpoint.UpdateFeeActivityReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fee_endpoint.UpdateFeeActivityReplay.displayName = 'proto.fee_endpoint.UpdateFeeActivityReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fee_endpoint.DeleteFeeReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fee_endpoint.DeleteFeeReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fee_endpoint.DeleteFeeReplay.displayName = 'proto.fee_endpoint.DeleteFeeReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fee_endpoint.CollectionAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fee_endpoint.CollectionAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fee_endpoint.CollectionAccountResponse.displayName = 'proto.fee_endpoint.CollectionAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fee_endpoint.GetFeeReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fee_endpoint.GetFeeReplay.repeatedFields_, null);
};
goog.inherits(proto.fee_endpoint.GetFeeReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fee_endpoint.GetFeeReplay.displayName = 'proto.fee_endpoint.GetFeeReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fee_endpoint.Fee = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fee_endpoint.Fee, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fee_endpoint.Fee.displayName = 'proto.fee_endpoint.Fee';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fee_endpoint.GetFeesReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.fee_endpoint.GetFeesReplay.repeatedFields_, null);
};
goog.inherits(proto.fee_endpoint.GetFeesReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fee_endpoint.GetFeesReplay.displayName = 'proto.fee_endpoint.GetFeesReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fee_endpoint.AddFeeCollectionAccountReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fee_endpoint.AddFeeCollectionAccountReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fee_endpoint.AddFeeCollectionAccountReplay.displayName = 'proto.fee_endpoint.AddFeeCollectionAccountReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay.displayName = 'proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fee_endpoint.RemoveFeeCollectionAccountReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.fee_endpoint.RemoveFeeCollectionAccountReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fee_endpoint.RemoveFeeCollectionAccountReplay.displayName = 'proto.fee_endpoint.RemoveFeeCollectionAccountReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fee_endpoint.AddFeeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.fee_endpoint.AddFeeResponse.oneofGroups_);
};
goog.inherits(proto.fee_endpoint.AddFeeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fee_endpoint.AddFeeResponse.displayName = 'proto.fee_endpoint.AddFeeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fee_endpoint.UpdateFeeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.fee_endpoint.UpdateFeeResponse.oneofGroups_);
};
goog.inherits(proto.fee_endpoint.UpdateFeeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fee_endpoint.UpdateFeeResponse.displayName = 'proto.fee_endpoint.UpdateFeeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fee_endpoint.UpdateFeeActivityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.fee_endpoint.UpdateFeeActivityResponse.oneofGroups_);
};
goog.inherits(proto.fee_endpoint.UpdateFeeActivityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fee_endpoint.UpdateFeeActivityResponse.displayName = 'proto.fee_endpoint.UpdateFeeActivityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fee_endpoint.DeleteFeeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.fee_endpoint.DeleteFeeResponse.oneofGroups_);
};
goog.inherits(proto.fee_endpoint.DeleteFeeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fee_endpoint.DeleteFeeResponse.displayName = 'proto.fee_endpoint.DeleteFeeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fee_endpoint.GetFeeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.fee_endpoint.GetFeeResponse.oneofGroups_);
};
goog.inherits(proto.fee_endpoint.GetFeeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fee_endpoint.GetFeeResponse.displayName = 'proto.fee_endpoint.GetFeeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fee_endpoint.GetFeesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.fee_endpoint.GetFeesResponse.oneofGroups_);
};
goog.inherits(proto.fee_endpoint.GetFeesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fee_endpoint.GetFeesResponse.displayName = 'proto.fee_endpoint.GetFeesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fee_endpoint.AddFeeCollectionAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.fee_endpoint.AddFeeCollectionAccountResponse.oneofGroups_);
};
goog.inherits(proto.fee_endpoint.AddFeeCollectionAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fee_endpoint.AddFeeCollectionAccountResponse.displayName = 'proto.fee_endpoint.AddFeeCollectionAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse.oneofGroups_);
};
goog.inherits(proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse.displayName = 'proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.fee_endpoint.RemoveFeeCollectionAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.fee_endpoint.RemoveFeeCollectionAccountResponse.oneofGroups_);
};
goog.inherits(proto.fee_endpoint.RemoveFeeCollectionAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.fee_endpoint.RemoveFeeCollectionAccountResponse.displayName = 'proto.fee_endpoint.RemoveFeeCollectionAccountResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fee_endpoint.FeeEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.fee_endpoint.FeeEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fee_endpoint.FeeEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.FeeEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    fixedvalue: jspb.Message.getFieldWithDefault(msg, 1, ""),
    percentagevalue: jspb.Message.getFieldWithDefault(msg, 2, ""),
    activationthreshold: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fee_endpoint.FeeEntry}
 */
proto.fee_endpoint.FeeEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fee_endpoint.FeeEntry;
  return proto.fee_endpoint.FeeEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fee_endpoint.FeeEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fee_endpoint.FeeEntry}
 */
proto.fee_endpoint.FeeEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFixedvalue(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPercentagevalue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivationthreshold(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fee_endpoint.FeeEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fee_endpoint.FeeEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fee_endpoint.FeeEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.FeeEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFixedvalue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPercentagevalue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActivationthreshold();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string fixedValue = 1;
 * @return {string}
 */
proto.fee_endpoint.FeeEntry.prototype.getFixedvalue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fee_endpoint.FeeEntry} returns this
 */
proto.fee_endpoint.FeeEntry.prototype.setFixedvalue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string percentageValue = 2;
 * @return {string}
 */
proto.fee_endpoint.FeeEntry.prototype.getPercentagevalue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fee_endpoint.FeeEntry} returns this
 */
proto.fee_endpoint.FeeEntry.prototype.setPercentagevalue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string activationThreshold = 3;
 * @return {string}
 */
proto.fee_endpoint.FeeEntry.prototype.getActivationthreshold = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.fee_endpoint.FeeEntry} returns this
 */
proto.fee_endpoint.FeeEntry.prototype.setActivationthreshold = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fee_endpoint.AddFeeRequest.repeatedFields_ = [4,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fee_endpoint.AddFeeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fee_endpoint.AddFeeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fee_endpoint.AddFeeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.AddFeeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isavailable: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    isactive: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    feeentriesList: jspb.Message.toObjectList(msg.getFeeentriesList(),
    proto.fee_endpoint.FeeEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fee_endpoint.AddFeeRequest}
 */
proto.fee_endpoint.AddFeeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fee_endpoint.AddFeeRequest;
  return proto.fee_endpoint.AddFeeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fee_endpoint.AddFeeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fee_endpoint.AddFeeRequest}
 */
proto.fee_endpoint.AddFeeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsavailable(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsactive(value);
      break;
    case 4:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 6:
      var value = new proto.fee_endpoint.FeeEntry;
      reader.readMessage(value,proto.fee_endpoint.FeeEntry.deserializeBinaryFromReader);
      msg.addFeeentries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fee_endpoint.AddFeeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fee_endpoint.AddFeeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fee_endpoint.AddFeeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.AddFeeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsavailable();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIsactive();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getFeeentriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.fee_endpoint.FeeEntry.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.fee_endpoint.AddFeeRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fee_endpoint.AddFeeRequest} returns this
 */
proto.fee_endpoint.AddFeeRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool isAvailable = 2;
 * @return {boolean}
 */
proto.fee_endpoint.AddFeeRequest.prototype.getIsavailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fee_endpoint.AddFeeRequest} returns this
 */
proto.fee_endpoint.AddFeeRequest.prototype.setIsavailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool isActive = 3;
 * @return {boolean}
 */
proto.fee_endpoint.AddFeeRequest.prototype.getIsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fee_endpoint.AddFeeRequest} returns this
 */
proto.fee_endpoint.AddFeeRequest.prototype.setIsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated common.ExtraField extraFields = 4;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.fee_endpoint.AddFeeRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 4));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.fee_endpoint.AddFeeRequest} returns this
*/
proto.fee_endpoint.AddFeeRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.fee_endpoint.AddFeeRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fee_endpoint.AddFeeRequest} returns this
 */
proto.fee_endpoint.AddFeeRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};


/**
 * optional google.protobuf.Int32Value organization = 5;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.fee_endpoint.AddFeeRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 5));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.fee_endpoint.AddFeeRequest} returns this
*/
proto.fee_endpoint.AddFeeRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.AddFeeRequest} returns this
 */
proto.fee_endpoint.AddFeeRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.AddFeeRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated FeeEntry feeEntries = 6;
 * @return {!Array<!proto.fee_endpoint.FeeEntry>}
 */
proto.fee_endpoint.AddFeeRequest.prototype.getFeeentriesList = function() {
  return /** @type{!Array<!proto.fee_endpoint.FeeEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fee_endpoint.FeeEntry, 6));
};


/**
 * @param {!Array<!proto.fee_endpoint.FeeEntry>} value
 * @return {!proto.fee_endpoint.AddFeeRequest} returns this
*/
proto.fee_endpoint.AddFeeRequest.prototype.setFeeentriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.fee_endpoint.FeeEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fee_endpoint.FeeEntry}
 */
proto.fee_endpoint.AddFeeRequest.prototype.addFeeentries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.fee_endpoint.FeeEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fee_endpoint.AddFeeRequest} returns this
 */
proto.fee_endpoint.AddFeeRequest.prototype.clearFeeentriesList = function() {
  return this.setFeeentriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fee_endpoint.UpdateFeeActivityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fee_endpoint.UpdateFeeActivityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fee_endpoint.UpdateFeeActivityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.UpdateFeeActivityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isactive: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fee_endpoint.UpdateFeeActivityRequest}
 */
proto.fee_endpoint.UpdateFeeActivityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fee_endpoint.UpdateFeeActivityRequest;
  return proto.fee_endpoint.UpdateFeeActivityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fee_endpoint.UpdateFeeActivityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fee_endpoint.UpdateFeeActivityRequest}
 */
proto.fee_endpoint.UpdateFeeActivityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsactive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fee_endpoint.UpdateFeeActivityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fee_endpoint.UpdateFeeActivityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fee_endpoint.UpdateFeeActivityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.UpdateFeeActivityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsactive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.fee_endpoint.UpdateFeeActivityRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fee_endpoint.UpdateFeeActivityRequest} returns this
 */
proto.fee_endpoint.UpdateFeeActivityRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool isActive = 2;
 * @return {boolean}
 */
proto.fee_endpoint.UpdateFeeActivityRequest.prototype.getIsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fee_endpoint.UpdateFeeActivityRequest} returns this
 */
proto.fee_endpoint.UpdateFeeActivityRequest.prototype.setIsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fee_endpoint.UpdateFeeRequest.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fee_endpoint.UpdateFeeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fee_endpoint.UpdateFeeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fee_endpoint.UpdateFeeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.UpdateFeeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isavailable: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance),
    feeentriesList: jspb.Message.toObjectList(msg.getFeeentriesList(),
    proto.fee_endpoint.FeeEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fee_endpoint.UpdateFeeRequest}
 */
proto.fee_endpoint.UpdateFeeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fee_endpoint.UpdateFeeRequest;
  return proto.fee_endpoint.UpdateFeeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fee_endpoint.UpdateFeeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fee_endpoint.UpdateFeeRequest}
 */
proto.fee_endpoint.UpdateFeeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsavailable(value);
      break;
    case 4:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    case 5:
      var value = new proto.fee_endpoint.FeeEntry;
      reader.readMessage(value,proto.fee_endpoint.FeeEntry.deserializeBinaryFromReader);
      msg.addFeeentries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fee_endpoint.UpdateFeeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fee_endpoint.UpdateFeeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fee_endpoint.UpdateFeeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.UpdateFeeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsavailable();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
  f = message.getFeeentriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.fee_endpoint.FeeEntry.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.fee_endpoint.UpdateFeeRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fee_endpoint.UpdateFeeRequest} returns this
 */
proto.fee_endpoint.UpdateFeeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.fee_endpoint.UpdateFeeRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fee_endpoint.UpdateFeeRequest} returns this
 */
proto.fee_endpoint.UpdateFeeRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool isAvailable = 3;
 * @return {boolean}
 */
proto.fee_endpoint.UpdateFeeRequest.prototype.getIsavailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fee_endpoint.UpdateFeeRequest} returns this
 */
proto.fee_endpoint.UpdateFeeRequest.prototype.setIsavailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated common.ExtraField extraFields = 4;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.fee_endpoint.UpdateFeeRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 4));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.fee_endpoint.UpdateFeeRequest} returns this
*/
proto.fee_endpoint.UpdateFeeRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.fee_endpoint.UpdateFeeRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fee_endpoint.UpdateFeeRequest} returns this
 */
proto.fee_endpoint.UpdateFeeRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};


/**
 * repeated FeeEntry feeEntries = 5;
 * @return {!Array<!proto.fee_endpoint.FeeEntry>}
 */
proto.fee_endpoint.UpdateFeeRequest.prototype.getFeeentriesList = function() {
  return /** @type{!Array<!proto.fee_endpoint.FeeEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fee_endpoint.FeeEntry, 5));
};


/**
 * @param {!Array<!proto.fee_endpoint.FeeEntry>} value
 * @return {!proto.fee_endpoint.UpdateFeeRequest} returns this
*/
proto.fee_endpoint.UpdateFeeRequest.prototype.setFeeentriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.fee_endpoint.FeeEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fee_endpoint.FeeEntry}
 */
proto.fee_endpoint.UpdateFeeRequest.prototype.addFeeentries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.fee_endpoint.FeeEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fee_endpoint.UpdateFeeRequest} returns this
 */
proto.fee_endpoint.UpdateFeeRequest.prototype.clearFeeentriesList = function() {
  return this.setFeeentriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fee_endpoint.DeleteFeeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fee_endpoint.DeleteFeeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fee_endpoint.DeleteFeeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.DeleteFeeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fee_endpoint.DeleteFeeRequest}
 */
proto.fee_endpoint.DeleteFeeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fee_endpoint.DeleteFeeRequest;
  return proto.fee_endpoint.DeleteFeeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fee_endpoint.DeleteFeeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fee_endpoint.DeleteFeeRequest}
 */
proto.fee_endpoint.DeleteFeeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fee_endpoint.DeleteFeeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fee_endpoint.DeleteFeeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fee_endpoint.DeleteFeeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.DeleteFeeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.fee_endpoint.DeleteFeeRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fee_endpoint.DeleteFeeRequest} returns this
 */
proto.fee_endpoint.DeleteFeeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fee_endpoint.GetFeeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fee_endpoint.GetFeeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fee_endpoint.GetFeeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.GetFeeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fee_endpoint.GetFeeRequest}
 */
proto.fee_endpoint.GetFeeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fee_endpoint.GetFeeRequest;
  return proto.fee_endpoint.GetFeeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fee_endpoint.GetFeeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fee_endpoint.GetFeeRequest}
 */
proto.fee_endpoint.GetFeeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fee_endpoint.GetFeeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fee_endpoint.GetFeeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fee_endpoint.GetFeeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.GetFeeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.fee_endpoint.GetFeeRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fee_endpoint.GetFeeRequest} returns this
 */
proto.fee_endpoint.GetFeeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fee_endpoint.GetFeesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fee_endpoint.GetFeesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fee_endpoint.GetFeesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.GetFeesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    isavailable: (f = msg.getIsavailable()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    isactive: (f = msg.getIsactive()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    order: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    nextto: (f = msg.getNextto()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    numofresults: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fee_endpoint.GetFeesRequest}
 */
proto.fee_endpoint.GetFeesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fee_endpoint.GetFeesRequest;
  return proto.fee_endpoint.GetFeesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fee_endpoint.GetFeesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fee_endpoint.GetFeesRequest}
 */
proto.fee_endpoint.GetFeesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsavailable(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsactive(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrder(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNextto(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumofresults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fee_endpoint.GetFeesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fee_endpoint.GetFeesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fee_endpoint.GetFeesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.GetFeesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getIsavailable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIsactive();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getNextto();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNumofresults();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional google.protobuf.Int32Value organization = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.fee_endpoint.GetFeesRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.fee_endpoint.GetFeesRequest} returns this
*/
proto.fee_endpoint.GetFeesRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.GetFeesRequest} returns this
 */
proto.fee_endpoint.GetFeesRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.GetFeesRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.BoolValue isAvailable = 2;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.fee_endpoint.GetFeesRequest.prototype.getIsavailable = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 2));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.fee_endpoint.GetFeesRequest} returns this
*/
proto.fee_endpoint.GetFeesRequest.prototype.setIsavailable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.GetFeesRequest} returns this
 */
proto.fee_endpoint.GetFeesRequest.prototype.clearIsavailable = function() {
  return this.setIsavailable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.GetFeesRequest.prototype.hasIsavailable = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.BoolValue isActive = 3;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.fee_endpoint.GetFeesRequest.prototype.getIsactive = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 3));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.fee_endpoint.GetFeesRequest} returns this
*/
proto.fee_endpoint.GetFeesRequest.prototype.setIsactive = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.GetFeesRequest} returns this
 */
proto.fee_endpoint.GetFeesRequest.prototype.clearIsactive = function() {
  return this.setIsactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.GetFeesRequest.prototype.hasIsactive = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue search = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.fee_endpoint.GetFeesRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.fee_endpoint.GetFeesRequest} returns this
*/
proto.fee_endpoint.GetFeesRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.GetFeesRequest} returns this
 */
proto.fee_endpoint.GetFeesRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.GetFeesRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool order = 5;
 * @return {boolean}
 */
proto.fee_endpoint.GetFeesRequest.prototype.getOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fee_endpoint.GetFeesRequest} returns this
 */
proto.fee_endpoint.GetFeesRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional google.protobuf.StringValue nextTo = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.fee_endpoint.GetFeesRequest.prototype.getNextto = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.fee_endpoint.GetFeesRequest} returns this
*/
proto.fee_endpoint.GetFeesRequest.prototype.setNextto = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.GetFeesRequest} returns this
 */
proto.fee_endpoint.GetFeesRequest.prototype.clearNextto = function() {
  return this.setNextto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.GetFeesRequest.prototype.hasNextto = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 numOfResults = 7;
 * @return {number}
 */
proto.fee_endpoint.GetFeesRequest.prototype.getNumofresults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.fee_endpoint.GetFeesRequest} returns this
 */
proto.fee_endpoint.GetFeesRequest.prototype.setNumofresults = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fee_endpoint.AddFeeCollectionAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fee_endpoint.AddFeeCollectionAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fee_endpoint.AddFeeCollectionAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.AddFeeCollectionAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    account: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isactive: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fee_endpoint.AddFeeCollectionAccountRequest}
 */
proto.fee_endpoint.AddFeeCollectionAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fee_endpoint.AddFeeCollectionAccountRequest;
  return proto.fee_endpoint.AddFeeCollectionAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fee_endpoint.AddFeeCollectionAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fee_endpoint.AddFeeCollectionAccountRequest}
 */
proto.fee_endpoint.AddFeeCollectionAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsactive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fee_endpoint.AddFeeCollectionAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fee_endpoint.AddFeeCollectionAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fee_endpoint.AddFeeCollectionAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.AddFeeCollectionAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsactive();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.fee_endpoint.AddFeeCollectionAccountRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fee_endpoint.AddFeeCollectionAccountRequest} returns this
 */
proto.fee_endpoint.AddFeeCollectionAccountRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account = 2;
 * @return {string}
 */
proto.fee_endpoint.AddFeeCollectionAccountRequest.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fee_endpoint.AddFeeCollectionAccountRequest} returns this
 */
proto.fee_endpoint.AddFeeCollectionAccountRequest.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool isActive = 3;
 * @return {boolean}
 */
proto.fee_endpoint.AddFeeCollectionAccountRequest.prototype.getIsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fee_endpoint.AddFeeCollectionAccountRequest} returns this
 */
proto.fee_endpoint.AddFeeCollectionAccountRequest.prototype.setIsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fee_endpoint.UpdateFeeCollectionAccountActivityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fee_endpoint.UpdateFeeCollectionAccountActivityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    account: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isactive: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fee_endpoint.UpdateFeeCollectionAccountActivityRequest}
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fee_endpoint.UpdateFeeCollectionAccountActivityRequest;
  return proto.fee_endpoint.UpdateFeeCollectionAccountActivityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fee_endpoint.UpdateFeeCollectionAccountActivityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fee_endpoint.UpdateFeeCollectionAccountActivityRequest}
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsactive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fee_endpoint.UpdateFeeCollectionAccountActivityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fee_endpoint.UpdateFeeCollectionAccountActivityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsactive();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fee_endpoint.UpdateFeeCollectionAccountActivityRequest} returns this
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account = 2;
 * @return {string}
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityRequest.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fee_endpoint.UpdateFeeCollectionAccountActivityRequest} returns this
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityRequest.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool isActive = 3;
 * @return {boolean}
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityRequest.prototype.getIsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.fee_endpoint.UpdateFeeCollectionAccountActivityRequest} returns this
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityRequest.prototype.setIsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fee_endpoint.RemoveFeeCollectionAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.fee_endpoint.RemoveFeeCollectionAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fee_endpoint.RemoveFeeCollectionAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.RemoveFeeCollectionAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    account: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fee_endpoint.RemoveFeeCollectionAccountRequest}
 */
proto.fee_endpoint.RemoveFeeCollectionAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fee_endpoint.RemoveFeeCollectionAccountRequest;
  return proto.fee_endpoint.RemoveFeeCollectionAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fee_endpoint.RemoveFeeCollectionAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fee_endpoint.RemoveFeeCollectionAccountRequest}
 */
proto.fee_endpoint.RemoveFeeCollectionAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fee_endpoint.RemoveFeeCollectionAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fee_endpoint.RemoveFeeCollectionAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fee_endpoint.RemoveFeeCollectionAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.RemoveFeeCollectionAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.fee_endpoint.RemoveFeeCollectionAccountRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fee_endpoint.RemoveFeeCollectionAccountRequest} returns this
 */
proto.fee_endpoint.RemoveFeeCollectionAccountRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account = 2;
 * @return {string}
 */
proto.fee_endpoint.RemoveFeeCollectionAccountRequest.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.fee_endpoint.RemoveFeeCollectionAccountRequest} returns this
 */
proto.fee_endpoint.RemoveFeeCollectionAccountRequest.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fee_endpoint.AddFeeReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.fee_endpoint.AddFeeReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fee_endpoint.AddFeeReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.AddFeeReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fee_endpoint.AddFeeReplay}
 */
proto.fee_endpoint.AddFeeReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fee_endpoint.AddFeeReplay;
  return proto.fee_endpoint.AddFeeReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fee_endpoint.AddFeeReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fee_endpoint.AddFeeReplay}
 */
proto.fee_endpoint.AddFeeReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 3:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fee_endpoint.AddFeeReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fee_endpoint.AddFeeReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fee_endpoint.AddFeeReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.AddFeeReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.fee_endpoint.AddFeeReplay.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fee_endpoint.AddFeeReplay} returns this
 */
proto.fee_endpoint.AddFeeReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.fee_endpoint.AddFeeReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.fee_endpoint.AddFeeReplay} returns this
*/
proto.fee_endpoint.AddFeeReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.AddFeeReplay} returns this
 */
proto.fee_endpoint.AddFeeReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.AddFeeReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.MessageResponse message = 3;
 * @return {?proto.common.MessageResponse}
 */
proto.fee_endpoint.AddFeeReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 3));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.fee_endpoint.AddFeeReplay} returns this
*/
proto.fee_endpoint.AddFeeReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.AddFeeReplay} returns this
 */
proto.fee_endpoint.AddFeeReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.AddFeeReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fee_endpoint.UpdateFeeReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.fee_endpoint.UpdateFeeReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fee_endpoint.UpdateFeeReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.UpdateFeeReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fee_endpoint.UpdateFeeReplay}
 */
proto.fee_endpoint.UpdateFeeReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fee_endpoint.UpdateFeeReplay;
  return proto.fee_endpoint.UpdateFeeReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fee_endpoint.UpdateFeeReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fee_endpoint.UpdateFeeReplay}
 */
proto.fee_endpoint.UpdateFeeReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fee_endpoint.UpdateFeeReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fee_endpoint.UpdateFeeReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fee_endpoint.UpdateFeeReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.UpdateFeeReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.fee_endpoint.UpdateFeeReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.fee_endpoint.UpdateFeeReplay} returns this
*/
proto.fee_endpoint.UpdateFeeReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.UpdateFeeReplay} returns this
 */
proto.fee_endpoint.UpdateFeeReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.UpdateFeeReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.fee_endpoint.UpdateFeeReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.fee_endpoint.UpdateFeeReplay} returns this
*/
proto.fee_endpoint.UpdateFeeReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.UpdateFeeReplay} returns this
 */
proto.fee_endpoint.UpdateFeeReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.UpdateFeeReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fee_endpoint.UpdateFeeActivityReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.fee_endpoint.UpdateFeeActivityReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fee_endpoint.UpdateFeeActivityReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.UpdateFeeActivityReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fee_endpoint.UpdateFeeActivityReplay}
 */
proto.fee_endpoint.UpdateFeeActivityReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fee_endpoint.UpdateFeeActivityReplay;
  return proto.fee_endpoint.UpdateFeeActivityReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fee_endpoint.UpdateFeeActivityReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fee_endpoint.UpdateFeeActivityReplay}
 */
proto.fee_endpoint.UpdateFeeActivityReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fee_endpoint.UpdateFeeActivityReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fee_endpoint.UpdateFeeActivityReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fee_endpoint.UpdateFeeActivityReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.UpdateFeeActivityReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.fee_endpoint.UpdateFeeActivityReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.fee_endpoint.UpdateFeeActivityReplay} returns this
*/
proto.fee_endpoint.UpdateFeeActivityReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.UpdateFeeActivityReplay} returns this
 */
proto.fee_endpoint.UpdateFeeActivityReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.UpdateFeeActivityReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.fee_endpoint.UpdateFeeActivityReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.fee_endpoint.UpdateFeeActivityReplay} returns this
*/
proto.fee_endpoint.UpdateFeeActivityReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.UpdateFeeActivityReplay} returns this
 */
proto.fee_endpoint.UpdateFeeActivityReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.UpdateFeeActivityReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fee_endpoint.DeleteFeeReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.fee_endpoint.DeleteFeeReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fee_endpoint.DeleteFeeReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.DeleteFeeReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    deleteddate: (f = msg.getDeleteddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fee_endpoint.DeleteFeeReplay}
 */
proto.fee_endpoint.DeleteFeeReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fee_endpoint.DeleteFeeReplay;
  return proto.fee_endpoint.DeleteFeeReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fee_endpoint.DeleteFeeReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fee_endpoint.DeleteFeeReplay}
 */
proto.fee_endpoint.DeleteFeeReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeleteddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fee_endpoint.DeleteFeeReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fee_endpoint.DeleteFeeReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fee_endpoint.DeleteFeeReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.DeleteFeeReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeleteddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp deletedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.fee_endpoint.DeleteFeeReplay.prototype.getDeleteddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.fee_endpoint.DeleteFeeReplay} returns this
*/
proto.fee_endpoint.DeleteFeeReplay.prototype.setDeleteddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.DeleteFeeReplay} returns this
 */
proto.fee_endpoint.DeleteFeeReplay.prototype.clearDeleteddate = function() {
  return this.setDeleteddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.DeleteFeeReplay.prototype.hasDeleteddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.fee_endpoint.DeleteFeeReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.fee_endpoint.DeleteFeeReplay} returns this
*/
proto.fee_endpoint.DeleteFeeReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.DeleteFeeReplay} returns this
 */
proto.fee_endpoint.DeleteFeeReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.DeleteFeeReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fee_endpoint.CollectionAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fee_endpoint.CollectionAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fee_endpoint.CollectionAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.CollectionAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    account: (f = msg.getAccount()) && Protos_common_pb.AccountResponse.toObject(includeInstance, f),
    isactive: (f = msg.getIsactive()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    addedby: (f = msg.getAddedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    addeddate: (f = msg.getAddeddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fee_endpoint.CollectionAccountResponse}
 */
proto.fee_endpoint.CollectionAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fee_endpoint.CollectionAccountResponse;
  return proto.fee_endpoint.CollectionAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fee_endpoint.CollectionAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fee_endpoint.CollectionAccountResponse}
 */
proto.fee_endpoint.CollectionAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Protos_common_pb.AccountResponse;
      reader.readMessage(value,Protos_common_pb.AccountResponse.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsactive(value);
      break;
    case 3:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setAddedby(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAddeddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fee_endpoint.CollectionAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fee_endpoint.CollectionAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fee_endpoint.CollectionAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.CollectionAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Protos_common_pb.AccountResponse.serializeBinaryToWriter
    );
  }
  f = message.getIsactive();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getAddedby();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getAddeddate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.AccountResponse account = 1;
 * @return {?proto.common.AccountResponse}
 */
proto.fee_endpoint.CollectionAccountResponse.prototype.getAccount = function() {
  return /** @type{?proto.common.AccountResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.AccountResponse, 1));
};


/**
 * @param {?proto.common.AccountResponse|undefined} value
 * @return {!proto.fee_endpoint.CollectionAccountResponse} returns this
*/
proto.fee_endpoint.CollectionAccountResponse.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.CollectionAccountResponse} returns this
 */
proto.fee_endpoint.CollectionAccountResponse.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.CollectionAccountResponse.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.BoolValue isActive = 2;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.fee_endpoint.CollectionAccountResponse.prototype.getIsactive = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 2));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.fee_endpoint.CollectionAccountResponse} returns this
*/
proto.fee_endpoint.CollectionAccountResponse.prototype.setIsactive = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.CollectionAccountResponse} returns this
 */
proto.fee_endpoint.CollectionAccountResponse.prototype.clearIsactive = function() {
  return this.setIsactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.CollectionAccountResponse.prototype.hasIsactive = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.ProfileResponse addedBy = 3;
 * @return {?proto.common.ProfileResponse}
 */
proto.fee_endpoint.CollectionAccountResponse.prototype.getAddedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 3));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.fee_endpoint.CollectionAccountResponse} returns this
*/
proto.fee_endpoint.CollectionAccountResponse.prototype.setAddedby = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.CollectionAccountResponse} returns this
 */
proto.fee_endpoint.CollectionAccountResponse.prototype.clearAddedby = function() {
  return this.setAddedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.CollectionAccountResponse.prototype.hasAddedby = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp addedDate = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.fee_endpoint.CollectionAccountResponse.prototype.getAddeddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.fee_endpoint.CollectionAccountResponse} returns this
*/
proto.fee_endpoint.CollectionAccountResponse.prototype.setAddeddate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.CollectionAccountResponse} returns this
 */
proto.fee_endpoint.CollectionAccountResponse.prototype.clearAddeddate = function() {
  return this.setAddeddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.CollectionAccountResponse.prototype.hasAddeddate = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fee_endpoint.GetFeeReplay.repeatedFields_ = [5,6,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fee_endpoint.GetFeeReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.fee_endpoint.GetFeeReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fee_endpoint.GetFeeReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.GetFeeReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isavailable: (f = msg.getIsavailable()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    isactive: (f = msg.getIsactive()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance),
    feeentriesList: jspb.Message.toObjectList(msg.getFeeentriesList(),
    proto.fee_endpoint.FeeEntry.toObject, includeInstance),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    collectionaccountsList: jspb.Message.toObjectList(msg.getCollectionaccountsList(),
    proto.fee_endpoint.CollectionAccountResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fee_endpoint.GetFeeReplay}
 */
proto.fee_endpoint.GetFeeReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fee_endpoint.GetFeeReplay;
  return proto.fee_endpoint.GetFeeReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fee_endpoint.GetFeeReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fee_endpoint.GetFeeReplay}
 */
proto.fee_endpoint.GetFeeReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsavailable(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsactive(value);
      break;
    case 5:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    case 6:
      var value = new proto.fee_endpoint.FeeEntry;
      reader.readMessage(value,proto.fee_endpoint.FeeEntry.deserializeBinaryFromReader);
      msg.addFeeentries(value);
      break;
    case 7:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 9:
      var value = new proto.fee_endpoint.CollectionAccountResponse;
      reader.readMessage(value,proto.fee_endpoint.CollectionAccountResponse.deserializeBinaryFromReader);
      msg.addCollectionaccounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fee_endpoint.GetFeeReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fee_endpoint.GetFeeReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fee_endpoint.GetFeeReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.GetFeeReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsavailable();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIsactive();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
  f = message.getFeeentriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.fee_endpoint.FeeEntry.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCollectionaccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.fee_endpoint.CollectionAccountResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.fee_endpoint.GetFeeReplay.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fee_endpoint.GetFeeReplay} returns this
 */
proto.fee_endpoint.GetFeeReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.fee_endpoint.GetFeeReplay.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.fee_endpoint.GetFeeReplay} returns this
*/
proto.fee_endpoint.GetFeeReplay.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.GetFeeReplay} returns this
 */
proto.fee_endpoint.GetFeeReplay.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.GetFeeReplay.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.BoolValue isAvailable = 3;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.fee_endpoint.GetFeeReplay.prototype.getIsavailable = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 3));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.fee_endpoint.GetFeeReplay} returns this
*/
proto.fee_endpoint.GetFeeReplay.prototype.setIsavailable = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.GetFeeReplay} returns this
 */
proto.fee_endpoint.GetFeeReplay.prototype.clearIsavailable = function() {
  return this.setIsavailable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.GetFeeReplay.prototype.hasIsavailable = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.BoolValue isActive = 4;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.fee_endpoint.GetFeeReplay.prototype.getIsactive = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 4));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.fee_endpoint.GetFeeReplay} returns this
*/
proto.fee_endpoint.GetFeeReplay.prototype.setIsactive = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.GetFeeReplay} returns this
 */
proto.fee_endpoint.GetFeeReplay.prototype.clearIsactive = function() {
  return this.setIsactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.GetFeeReplay.prototype.hasIsactive = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated common.ExtraField extraFields = 5;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.fee_endpoint.GetFeeReplay.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 5));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.fee_endpoint.GetFeeReplay} returns this
*/
proto.fee_endpoint.GetFeeReplay.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.fee_endpoint.GetFeeReplay.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fee_endpoint.GetFeeReplay} returns this
 */
proto.fee_endpoint.GetFeeReplay.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};


/**
 * repeated FeeEntry feeEntries = 6;
 * @return {!Array<!proto.fee_endpoint.FeeEntry>}
 */
proto.fee_endpoint.GetFeeReplay.prototype.getFeeentriesList = function() {
  return /** @type{!Array<!proto.fee_endpoint.FeeEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fee_endpoint.FeeEntry, 6));
};


/**
 * @param {!Array<!proto.fee_endpoint.FeeEntry>} value
 * @return {!proto.fee_endpoint.GetFeeReplay} returns this
*/
proto.fee_endpoint.GetFeeReplay.prototype.setFeeentriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.fee_endpoint.FeeEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fee_endpoint.FeeEntry}
 */
proto.fee_endpoint.GetFeeReplay.prototype.addFeeentries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.fee_endpoint.FeeEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fee_endpoint.GetFeeReplay} returns this
 */
proto.fee_endpoint.GetFeeReplay.prototype.clearFeeentriesList = function() {
  return this.setFeeentriesList([]);
};


/**
 * optional common.ProfileResponse createdBy = 7;
 * @return {?proto.common.ProfileResponse}
 */
proto.fee_endpoint.GetFeeReplay.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 7));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.fee_endpoint.GetFeeReplay} returns this
*/
proto.fee_endpoint.GetFeeReplay.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.GetFeeReplay} returns this
 */
proto.fee_endpoint.GetFeeReplay.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.GetFeeReplay.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.fee_endpoint.GetFeeReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.fee_endpoint.GetFeeReplay} returns this
*/
proto.fee_endpoint.GetFeeReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.GetFeeReplay} returns this
 */
proto.fee_endpoint.GetFeeReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.GetFeeReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated CollectionAccountResponse collectionAccounts = 9;
 * @return {!Array<!proto.fee_endpoint.CollectionAccountResponse>}
 */
proto.fee_endpoint.GetFeeReplay.prototype.getCollectionaccountsList = function() {
  return /** @type{!Array<!proto.fee_endpoint.CollectionAccountResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fee_endpoint.CollectionAccountResponse, 9));
};


/**
 * @param {!Array<!proto.fee_endpoint.CollectionAccountResponse>} value
 * @return {!proto.fee_endpoint.GetFeeReplay} returns this
*/
proto.fee_endpoint.GetFeeReplay.prototype.setCollectionaccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.fee_endpoint.CollectionAccountResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fee_endpoint.CollectionAccountResponse}
 */
proto.fee_endpoint.GetFeeReplay.prototype.addCollectionaccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.fee_endpoint.CollectionAccountResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fee_endpoint.GetFeeReplay} returns this
 */
proto.fee_endpoint.GetFeeReplay.prototype.clearCollectionaccountsList = function() {
  return this.setCollectionaccountsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fee_endpoint.Fee.prototype.toObject = function(opt_includeInstance) {
  return proto.fee_endpoint.Fee.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fee_endpoint.Fee} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.Fee.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isavailable: (f = msg.getIsavailable()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    isactive: (f = msg.getIsactive()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fee_endpoint.Fee}
 */
proto.fee_endpoint.Fee.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fee_endpoint.Fee;
  return proto.fee_endpoint.Fee.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fee_endpoint.Fee} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fee_endpoint.Fee}
 */
proto.fee_endpoint.Fee.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsavailable(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsactive(value);
      break;
    case 5:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fee_endpoint.Fee.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fee_endpoint.Fee.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fee_endpoint.Fee} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.Fee.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsavailable();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIsactive();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.fee_endpoint.Fee.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.fee_endpoint.Fee} returns this
 */
proto.fee_endpoint.Fee.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.fee_endpoint.Fee.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.fee_endpoint.Fee} returns this
*/
proto.fee_endpoint.Fee.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.Fee} returns this
 */
proto.fee_endpoint.Fee.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.Fee.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.BoolValue isAvailable = 3;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.fee_endpoint.Fee.prototype.getIsavailable = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 3));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.fee_endpoint.Fee} returns this
*/
proto.fee_endpoint.Fee.prototype.setIsavailable = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.Fee} returns this
 */
proto.fee_endpoint.Fee.prototype.clearIsavailable = function() {
  return this.setIsavailable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.Fee.prototype.hasIsavailable = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.BoolValue isActive = 4;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.fee_endpoint.Fee.prototype.getIsactive = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 4));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.fee_endpoint.Fee} returns this
*/
proto.fee_endpoint.Fee.prototype.setIsactive = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.Fee} returns this
 */
proto.fee_endpoint.Fee.prototype.clearIsactive = function() {
  return this.setIsactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.Fee.prototype.hasIsactive = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional common.ProfileResponse createdBy = 5;
 * @return {?proto.common.ProfileResponse}
 */
proto.fee_endpoint.Fee.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 5));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.fee_endpoint.Fee} returns this
*/
proto.fee_endpoint.Fee.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.Fee} returns this
 */
proto.fee_endpoint.Fee.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.Fee.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.fee_endpoint.Fee.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.fee_endpoint.Fee} returns this
*/
proto.fee_endpoint.Fee.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.Fee} returns this
 */
proto.fee_endpoint.Fee.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.Fee.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.fee_endpoint.GetFeesReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fee_endpoint.GetFeesReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.fee_endpoint.GetFeesReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fee_endpoint.GetFeesReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.GetFeesReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    feesList: jspb.Message.toObjectList(msg.getFeesList(),
    proto.fee_endpoint.Fee.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fee_endpoint.GetFeesReplay}
 */
proto.fee_endpoint.GetFeesReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fee_endpoint.GetFeesReplay;
  return proto.fee_endpoint.GetFeesReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fee_endpoint.GetFeesReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fee_endpoint.GetFeesReplay}
 */
proto.fee_endpoint.GetFeesReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fee_endpoint.Fee;
      reader.readMessage(value,proto.fee_endpoint.Fee.deserializeBinaryFromReader);
      msg.addFees(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fee_endpoint.GetFeesReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fee_endpoint.GetFeesReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fee_endpoint.GetFeesReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.GetFeesReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.fee_endpoint.Fee.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Fee fees = 1;
 * @return {!Array<!proto.fee_endpoint.Fee>}
 */
proto.fee_endpoint.GetFeesReplay.prototype.getFeesList = function() {
  return /** @type{!Array<!proto.fee_endpoint.Fee>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.fee_endpoint.Fee, 1));
};


/**
 * @param {!Array<!proto.fee_endpoint.Fee>} value
 * @return {!proto.fee_endpoint.GetFeesReplay} returns this
*/
proto.fee_endpoint.GetFeesReplay.prototype.setFeesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.fee_endpoint.Fee=} opt_value
 * @param {number=} opt_index
 * @return {!proto.fee_endpoint.Fee}
 */
proto.fee_endpoint.GetFeesReplay.prototype.addFees = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.fee_endpoint.Fee, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.fee_endpoint.GetFeesReplay} returns this
 */
proto.fee_endpoint.GetFeesReplay.prototype.clearFeesList = function() {
  return this.setFeesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fee_endpoint.AddFeeCollectionAccountReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.fee_endpoint.AddFeeCollectionAccountReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fee_endpoint.AddFeeCollectionAccountReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.AddFeeCollectionAccountReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    addeddate: (f = msg.getAddeddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fee_endpoint.AddFeeCollectionAccountReplay}
 */
proto.fee_endpoint.AddFeeCollectionAccountReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fee_endpoint.AddFeeCollectionAccountReplay;
  return proto.fee_endpoint.AddFeeCollectionAccountReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fee_endpoint.AddFeeCollectionAccountReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fee_endpoint.AddFeeCollectionAccountReplay}
 */
proto.fee_endpoint.AddFeeCollectionAccountReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAddeddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fee_endpoint.AddFeeCollectionAccountReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fee_endpoint.AddFeeCollectionAccountReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fee_endpoint.AddFeeCollectionAccountReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.AddFeeCollectionAccountReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddeddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp addedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.fee_endpoint.AddFeeCollectionAccountReplay.prototype.getAddeddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.fee_endpoint.AddFeeCollectionAccountReplay} returns this
*/
proto.fee_endpoint.AddFeeCollectionAccountReplay.prototype.setAddeddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.AddFeeCollectionAccountReplay} returns this
 */
proto.fee_endpoint.AddFeeCollectionAccountReplay.prototype.clearAddeddate = function() {
  return this.setAddeddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.AddFeeCollectionAccountReplay.prototype.hasAddeddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.fee_endpoint.AddFeeCollectionAccountReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.fee_endpoint.AddFeeCollectionAccountReplay} returns this
*/
proto.fee_endpoint.AddFeeCollectionAccountReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.AddFeeCollectionAccountReplay} returns this
 */
proto.fee_endpoint.AddFeeCollectionAccountReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.AddFeeCollectionAccountReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay}
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay;
  return proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay}
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay} returns this
*/
proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay} returns this
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay} returns this
*/
proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay} returns this
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fee_endpoint.RemoveFeeCollectionAccountReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.fee_endpoint.RemoveFeeCollectionAccountReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fee_endpoint.RemoveFeeCollectionAccountReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.RemoveFeeCollectionAccountReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    deleteddate: (f = msg.getDeleteddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fee_endpoint.RemoveFeeCollectionAccountReplay}
 */
proto.fee_endpoint.RemoveFeeCollectionAccountReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fee_endpoint.RemoveFeeCollectionAccountReplay;
  return proto.fee_endpoint.RemoveFeeCollectionAccountReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fee_endpoint.RemoveFeeCollectionAccountReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fee_endpoint.RemoveFeeCollectionAccountReplay}
 */
proto.fee_endpoint.RemoveFeeCollectionAccountReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeleteddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fee_endpoint.RemoveFeeCollectionAccountReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fee_endpoint.RemoveFeeCollectionAccountReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fee_endpoint.RemoveFeeCollectionAccountReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.RemoveFeeCollectionAccountReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeleteddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp deletedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.fee_endpoint.RemoveFeeCollectionAccountReplay.prototype.getDeleteddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.fee_endpoint.RemoveFeeCollectionAccountReplay} returns this
*/
proto.fee_endpoint.RemoveFeeCollectionAccountReplay.prototype.setDeleteddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.RemoveFeeCollectionAccountReplay} returns this
 */
proto.fee_endpoint.RemoveFeeCollectionAccountReplay.prototype.clearDeleteddate = function() {
  return this.setDeleteddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.RemoveFeeCollectionAccountReplay.prototype.hasDeleteddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.fee_endpoint.RemoveFeeCollectionAccountReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.fee_endpoint.RemoveFeeCollectionAccountReplay} returns this
*/
proto.fee_endpoint.RemoveFeeCollectionAccountReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.RemoveFeeCollectionAccountReplay} returns this
 */
proto.fee_endpoint.RemoveFeeCollectionAccountReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.RemoveFeeCollectionAccountReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.fee_endpoint.AddFeeResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.fee_endpoint.AddFeeResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.fee_endpoint.AddFeeResponse.ResultCase}
 */
proto.fee_endpoint.AddFeeResponse.prototype.getResultCase = function() {
  return /** @type {proto.fee_endpoint.AddFeeResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.fee_endpoint.AddFeeResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fee_endpoint.AddFeeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fee_endpoint.AddFeeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fee_endpoint.AddFeeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.AddFeeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.fee_endpoint.AddFeeReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fee_endpoint.AddFeeResponse}
 */
proto.fee_endpoint.AddFeeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fee_endpoint.AddFeeResponse;
  return proto.fee_endpoint.AddFeeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fee_endpoint.AddFeeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fee_endpoint.AddFeeResponse}
 */
proto.fee_endpoint.AddFeeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fee_endpoint.AddFeeReplay;
      reader.readMessage(value,proto.fee_endpoint.AddFeeReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fee_endpoint.AddFeeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fee_endpoint.AddFeeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fee_endpoint.AddFeeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.AddFeeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fee_endpoint.AddFeeReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional AddFeeReplay success = 1;
 * @return {?proto.fee_endpoint.AddFeeReplay}
 */
proto.fee_endpoint.AddFeeResponse.prototype.getSuccess = function() {
  return /** @type{?proto.fee_endpoint.AddFeeReplay} */ (
    jspb.Message.getWrapperField(this, proto.fee_endpoint.AddFeeReplay, 1));
};


/**
 * @param {?proto.fee_endpoint.AddFeeReplay|undefined} value
 * @return {!proto.fee_endpoint.AddFeeResponse} returns this
*/
proto.fee_endpoint.AddFeeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.fee_endpoint.AddFeeResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.AddFeeResponse} returns this
 */
proto.fee_endpoint.AddFeeResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.AddFeeResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.fee_endpoint.UpdateFeeResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.fee_endpoint.UpdateFeeResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.fee_endpoint.UpdateFeeResponse.ResultCase}
 */
proto.fee_endpoint.UpdateFeeResponse.prototype.getResultCase = function() {
  return /** @type {proto.fee_endpoint.UpdateFeeResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.fee_endpoint.UpdateFeeResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fee_endpoint.UpdateFeeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fee_endpoint.UpdateFeeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fee_endpoint.UpdateFeeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.UpdateFeeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.fee_endpoint.UpdateFeeReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fee_endpoint.UpdateFeeResponse}
 */
proto.fee_endpoint.UpdateFeeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fee_endpoint.UpdateFeeResponse;
  return proto.fee_endpoint.UpdateFeeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fee_endpoint.UpdateFeeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fee_endpoint.UpdateFeeResponse}
 */
proto.fee_endpoint.UpdateFeeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fee_endpoint.UpdateFeeReplay;
      reader.readMessage(value,proto.fee_endpoint.UpdateFeeReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fee_endpoint.UpdateFeeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fee_endpoint.UpdateFeeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fee_endpoint.UpdateFeeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.UpdateFeeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fee_endpoint.UpdateFeeReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateFeeReplay success = 1;
 * @return {?proto.fee_endpoint.UpdateFeeReplay}
 */
proto.fee_endpoint.UpdateFeeResponse.prototype.getSuccess = function() {
  return /** @type{?proto.fee_endpoint.UpdateFeeReplay} */ (
    jspb.Message.getWrapperField(this, proto.fee_endpoint.UpdateFeeReplay, 1));
};


/**
 * @param {?proto.fee_endpoint.UpdateFeeReplay|undefined} value
 * @return {!proto.fee_endpoint.UpdateFeeResponse} returns this
*/
proto.fee_endpoint.UpdateFeeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.fee_endpoint.UpdateFeeResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.UpdateFeeResponse} returns this
 */
proto.fee_endpoint.UpdateFeeResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.UpdateFeeResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.fee_endpoint.UpdateFeeActivityResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.fee_endpoint.UpdateFeeActivityResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.fee_endpoint.UpdateFeeActivityResponse.ResultCase}
 */
proto.fee_endpoint.UpdateFeeActivityResponse.prototype.getResultCase = function() {
  return /** @type {proto.fee_endpoint.UpdateFeeActivityResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.fee_endpoint.UpdateFeeActivityResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fee_endpoint.UpdateFeeActivityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fee_endpoint.UpdateFeeActivityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fee_endpoint.UpdateFeeActivityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.UpdateFeeActivityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.fee_endpoint.UpdateFeeActivityReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fee_endpoint.UpdateFeeActivityResponse}
 */
proto.fee_endpoint.UpdateFeeActivityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fee_endpoint.UpdateFeeActivityResponse;
  return proto.fee_endpoint.UpdateFeeActivityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fee_endpoint.UpdateFeeActivityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fee_endpoint.UpdateFeeActivityResponse}
 */
proto.fee_endpoint.UpdateFeeActivityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fee_endpoint.UpdateFeeActivityReplay;
      reader.readMessage(value,proto.fee_endpoint.UpdateFeeActivityReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fee_endpoint.UpdateFeeActivityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fee_endpoint.UpdateFeeActivityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fee_endpoint.UpdateFeeActivityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.UpdateFeeActivityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fee_endpoint.UpdateFeeActivityReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateFeeActivityReplay success = 1;
 * @return {?proto.fee_endpoint.UpdateFeeActivityReplay}
 */
proto.fee_endpoint.UpdateFeeActivityResponse.prototype.getSuccess = function() {
  return /** @type{?proto.fee_endpoint.UpdateFeeActivityReplay} */ (
    jspb.Message.getWrapperField(this, proto.fee_endpoint.UpdateFeeActivityReplay, 1));
};


/**
 * @param {?proto.fee_endpoint.UpdateFeeActivityReplay|undefined} value
 * @return {!proto.fee_endpoint.UpdateFeeActivityResponse} returns this
*/
proto.fee_endpoint.UpdateFeeActivityResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.fee_endpoint.UpdateFeeActivityResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.UpdateFeeActivityResponse} returns this
 */
proto.fee_endpoint.UpdateFeeActivityResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.UpdateFeeActivityResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.fee_endpoint.DeleteFeeResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.fee_endpoint.DeleteFeeResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.fee_endpoint.DeleteFeeResponse.ResultCase}
 */
proto.fee_endpoint.DeleteFeeResponse.prototype.getResultCase = function() {
  return /** @type {proto.fee_endpoint.DeleteFeeResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.fee_endpoint.DeleteFeeResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fee_endpoint.DeleteFeeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fee_endpoint.DeleteFeeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fee_endpoint.DeleteFeeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.DeleteFeeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.fee_endpoint.DeleteFeeReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fee_endpoint.DeleteFeeResponse}
 */
proto.fee_endpoint.DeleteFeeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fee_endpoint.DeleteFeeResponse;
  return proto.fee_endpoint.DeleteFeeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fee_endpoint.DeleteFeeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fee_endpoint.DeleteFeeResponse}
 */
proto.fee_endpoint.DeleteFeeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fee_endpoint.DeleteFeeReplay;
      reader.readMessage(value,proto.fee_endpoint.DeleteFeeReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fee_endpoint.DeleteFeeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fee_endpoint.DeleteFeeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fee_endpoint.DeleteFeeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.DeleteFeeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fee_endpoint.DeleteFeeReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeleteFeeReplay success = 1;
 * @return {?proto.fee_endpoint.DeleteFeeReplay}
 */
proto.fee_endpoint.DeleteFeeResponse.prototype.getSuccess = function() {
  return /** @type{?proto.fee_endpoint.DeleteFeeReplay} */ (
    jspb.Message.getWrapperField(this, proto.fee_endpoint.DeleteFeeReplay, 1));
};


/**
 * @param {?proto.fee_endpoint.DeleteFeeReplay|undefined} value
 * @return {!proto.fee_endpoint.DeleteFeeResponse} returns this
*/
proto.fee_endpoint.DeleteFeeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.fee_endpoint.DeleteFeeResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.DeleteFeeResponse} returns this
 */
proto.fee_endpoint.DeleteFeeResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.DeleteFeeResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.fee_endpoint.GetFeeResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.fee_endpoint.GetFeeResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.fee_endpoint.GetFeeResponse.ResultCase}
 */
proto.fee_endpoint.GetFeeResponse.prototype.getResultCase = function() {
  return /** @type {proto.fee_endpoint.GetFeeResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.fee_endpoint.GetFeeResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fee_endpoint.GetFeeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fee_endpoint.GetFeeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fee_endpoint.GetFeeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.GetFeeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.fee_endpoint.GetFeeReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fee_endpoint.GetFeeResponse}
 */
proto.fee_endpoint.GetFeeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fee_endpoint.GetFeeResponse;
  return proto.fee_endpoint.GetFeeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fee_endpoint.GetFeeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fee_endpoint.GetFeeResponse}
 */
proto.fee_endpoint.GetFeeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fee_endpoint.GetFeeReplay;
      reader.readMessage(value,proto.fee_endpoint.GetFeeReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fee_endpoint.GetFeeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fee_endpoint.GetFeeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fee_endpoint.GetFeeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.GetFeeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fee_endpoint.GetFeeReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetFeeReplay success = 1;
 * @return {?proto.fee_endpoint.GetFeeReplay}
 */
proto.fee_endpoint.GetFeeResponse.prototype.getSuccess = function() {
  return /** @type{?proto.fee_endpoint.GetFeeReplay} */ (
    jspb.Message.getWrapperField(this, proto.fee_endpoint.GetFeeReplay, 1));
};


/**
 * @param {?proto.fee_endpoint.GetFeeReplay|undefined} value
 * @return {!proto.fee_endpoint.GetFeeResponse} returns this
*/
proto.fee_endpoint.GetFeeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.fee_endpoint.GetFeeResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.GetFeeResponse} returns this
 */
proto.fee_endpoint.GetFeeResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.GetFeeResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.fee_endpoint.GetFeesResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.fee_endpoint.GetFeesResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.fee_endpoint.GetFeesResponse.ResultCase}
 */
proto.fee_endpoint.GetFeesResponse.prototype.getResultCase = function() {
  return /** @type {proto.fee_endpoint.GetFeesResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.fee_endpoint.GetFeesResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fee_endpoint.GetFeesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fee_endpoint.GetFeesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fee_endpoint.GetFeesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.GetFeesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.fee_endpoint.GetFeesReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fee_endpoint.GetFeesResponse}
 */
proto.fee_endpoint.GetFeesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fee_endpoint.GetFeesResponse;
  return proto.fee_endpoint.GetFeesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fee_endpoint.GetFeesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fee_endpoint.GetFeesResponse}
 */
proto.fee_endpoint.GetFeesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fee_endpoint.GetFeesReplay;
      reader.readMessage(value,proto.fee_endpoint.GetFeesReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fee_endpoint.GetFeesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fee_endpoint.GetFeesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fee_endpoint.GetFeesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.GetFeesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fee_endpoint.GetFeesReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetFeesReplay success = 1;
 * @return {?proto.fee_endpoint.GetFeesReplay}
 */
proto.fee_endpoint.GetFeesResponse.prototype.getSuccess = function() {
  return /** @type{?proto.fee_endpoint.GetFeesReplay} */ (
    jspb.Message.getWrapperField(this, proto.fee_endpoint.GetFeesReplay, 1));
};


/**
 * @param {?proto.fee_endpoint.GetFeesReplay|undefined} value
 * @return {!proto.fee_endpoint.GetFeesResponse} returns this
*/
proto.fee_endpoint.GetFeesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.fee_endpoint.GetFeesResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.GetFeesResponse} returns this
 */
proto.fee_endpoint.GetFeesResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.GetFeesResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.fee_endpoint.AddFeeCollectionAccountResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.fee_endpoint.AddFeeCollectionAccountResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.fee_endpoint.AddFeeCollectionAccountResponse.ResultCase}
 */
proto.fee_endpoint.AddFeeCollectionAccountResponse.prototype.getResultCase = function() {
  return /** @type {proto.fee_endpoint.AddFeeCollectionAccountResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.fee_endpoint.AddFeeCollectionAccountResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fee_endpoint.AddFeeCollectionAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fee_endpoint.AddFeeCollectionAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fee_endpoint.AddFeeCollectionAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.AddFeeCollectionAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.fee_endpoint.AddFeeCollectionAccountReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fee_endpoint.AddFeeCollectionAccountResponse}
 */
proto.fee_endpoint.AddFeeCollectionAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fee_endpoint.AddFeeCollectionAccountResponse;
  return proto.fee_endpoint.AddFeeCollectionAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fee_endpoint.AddFeeCollectionAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fee_endpoint.AddFeeCollectionAccountResponse}
 */
proto.fee_endpoint.AddFeeCollectionAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fee_endpoint.AddFeeCollectionAccountReplay;
      reader.readMessage(value,proto.fee_endpoint.AddFeeCollectionAccountReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fee_endpoint.AddFeeCollectionAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fee_endpoint.AddFeeCollectionAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fee_endpoint.AddFeeCollectionAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.AddFeeCollectionAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fee_endpoint.AddFeeCollectionAccountReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional AddFeeCollectionAccountReplay success = 1;
 * @return {?proto.fee_endpoint.AddFeeCollectionAccountReplay}
 */
proto.fee_endpoint.AddFeeCollectionAccountResponse.prototype.getSuccess = function() {
  return /** @type{?proto.fee_endpoint.AddFeeCollectionAccountReplay} */ (
    jspb.Message.getWrapperField(this, proto.fee_endpoint.AddFeeCollectionAccountReplay, 1));
};


/**
 * @param {?proto.fee_endpoint.AddFeeCollectionAccountReplay|undefined} value
 * @return {!proto.fee_endpoint.AddFeeCollectionAccountResponse} returns this
*/
proto.fee_endpoint.AddFeeCollectionAccountResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.fee_endpoint.AddFeeCollectionAccountResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.AddFeeCollectionAccountResponse} returns this
 */
proto.fee_endpoint.AddFeeCollectionAccountResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.AddFeeCollectionAccountResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse.ResultCase}
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse.prototype.getResultCase = function() {
  return /** @type {proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse}
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse;
  return proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse}
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay;
      reader.readMessage(value,proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateFeeCollectionAccountActivityReplay success = 1;
 * @return {?proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay}
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse.prototype.getSuccess = function() {
  return /** @type{?proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay} */ (
    jspb.Message.getWrapperField(this, proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay, 1));
};


/**
 * @param {?proto.fee_endpoint.UpdateFeeCollectionAccountActivityReplay|undefined} value
 * @return {!proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse} returns this
*/
proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse} returns this
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.UpdateFeeCollectionAccountActivityResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.fee_endpoint.RemoveFeeCollectionAccountResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.fee_endpoint.RemoveFeeCollectionAccountResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.fee_endpoint.RemoveFeeCollectionAccountResponse.ResultCase}
 */
proto.fee_endpoint.RemoveFeeCollectionAccountResponse.prototype.getResultCase = function() {
  return /** @type {proto.fee_endpoint.RemoveFeeCollectionAccountResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.fee_endpoint.RemoveFeeCollectionAccountResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.fee_endpoint.RemoveFeeCollectionAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.fee_endpoint.RemoveFeeCollectionAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.fee_endpoint.RemoveFeeCollectionAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.RemoveFeeCollectionAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.fee_endpoint.RemoveFeeCollectionAccountReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.fee_endpoint.RemoveFeeCollectionAccountResponse}
 */
proto.fee_endpoint.RemoveFeeCollectionAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.fee_endpoint.RemoveFeeCollectionAccountResponse;
  return proto.fee_endpoint.RemoveFeeCollectionAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.fee_endpoint.RemoveFeeCollectionAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.fee_endpoint.RemoveFeeCollectionAccountResponse}
 */
proto.fee_endpoint.RemoveFeeCollectionAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.fee_endpoint.RemoveFeeCollectionAccountReplay;
      reader.readMessage(value,proto.fee_endpoint.RemoveFeeCollectionAccountReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.fee_endpoint.RemoveFeeCollectionAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.fee_endpoint.RemoveFeeCollectionAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.fee_endpoint.RemoveFeeCollectionAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.fee_endpoint.RemoveFeeCollectionAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.fee_endpoint.RemoveFeeCollectionAccountReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional RemoveFeeCollectionAccountReplay success = 1;
 * @return {?proto.fee_endpoint.RemoveFeeCollectionAccountReplay}
 */
proto.fee_endpoint.RemoveFeeCollectionAccountResponse.prototype.getSuccess = function() {
  return /** @type{?proto.fee_endpoint.RemoveFeeCollectionAccountReplay} */ (
    jspb.Message.getWrapperField(this, proto.fee_endpoint.RemoveFeeCollectionAccountReplay, 1));
};


/**
 * @param {?proto.fee_endpoint.RemoveFeeCollectionAccountReplay|undefined} value
 * @return {!proto.fee_endpoint.RemoveFeeCollectionAccountResponse} returns this
*/
proto.fee_endpoint.RemoveFeeCollectionAccountResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.fee_endpoint.RemoveFeeCollectionAccountResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.fee_endpoint.RemoveFeeCollectionAccountResponse} returns this
 */
proto.fee_endpoint.RemoveFeeCollectionAccountResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.fee_endpoint.RemoveFeeCollectionAccountResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.fee_endpoint);
