import { ComboBox, DatePicker, DayOfWeek, DefaultButton, DialogFooter, defaultDatePickerStrings, IconButton, IPersonaProps, IPickerItemProps, IStackTokens, ISuggestionItemProps, ISuggestionModel, ISuggestionsProps, ITag, Label, MessageBar, MessageBarType, Modal, NormalPeoplePicker, PeoplePickerItem, Persona, PersonaSize, PrimaryButton, Spinner, SpinnerSize, Stack, TagItemSuggestion, TagPicker, Text, TextField, Toggle, TooltipHost, ValidationState, TimePicker, IComboBox } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { FormEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../../app/Hooks";
import { dismissMessage, get, reset, setStage1, update } from "./CurrencyPairPriceChangeLogFormSlice";
import * as Yup from 'yup';
import { DynamicInputField, InputField } from "../../../controls/Controls";
import { inputs } from "../../../../../app/Validation";
import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../../../app/Api";
import { Message } from "../../../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../../../FormProps";
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../../../common/SessionExpiredDialog/SessionExpiredDialog";
import { formatDate, timestampToDate } from "../../../../../app/Helpers";
import { AuthenticateReply } from "../../../../../repository/UserManagement/authentication_pb";
import { GetCurrenciesRequest } from "../../../../../repository/Accountant/currency_pb"
import { AddCurrencyPairRequest, GetCurrencyPairRequest, UpdateCurrencyPairResponse, AddCurrencyPairResponse, UpdateCurrencyPairRequest } from "../../../../../repository/Trading/currency_pair_pb";
import { List } from "../../../../common/List/List";
import { Popup } from "../../../../common/Popup/Popup";
import { CurrenciesPage } from "../../../../pages/Accountant/Currencies/CurrenciesPage";
import { GetCurrencyPairPriceLogRequest, UpdateCurrencyPairPriceLogRequest, UpdateCurrencyPairPriceLogResponse } from "../../../../../repository/Trading/currency_pair_price_log_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import moment from "moment";


let req: UpdateCurrencyPairPriceLogRequest;

let promise: any;
let getFilterPromise: any;
let org: number = -1;
let current: AuthenticateReply.AsObject;

export const CurrencyPairPriceChangeLogForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();
    const [currentAction, setCurrenctAction] = useState(0)

    const state: {
        isLoading: boolean, message: ApiMessage | undefined, stage1: any,
    } = useAppSelector((state) => {

        return {
            isLoading: state.currencyPairPriceChangeLogForm.isLoading,
            message: state.currencyPairPriceChangeLogForm.message,
            stage1: state.currencyPairPriceChangeLogForm.stage1,



        }
    })

    useEffect(() => {
        var l = searchParams.get('org');
        req = new UpdateCurrencyPairPriceLogRequest();
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);

            }
        }

        if (props.type == FormType.EDIT) {
            if (props.fetchData) {
                let r = new GetCurrencyPairPriceLogRequest()
                r.setId(props?.renderObject?.id);
                promise?.abort()
                promise = dispatch(get({ body: r, headers: getHeaders() }))
            } else {
                dispatch(setStage1(props?.renderObject))
            }
        }



        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        }
        return () => { //clean up
            promise?.abort();
            dispatch(reset());
        }
    }, [])



    return (
        <>

            <Formik
                enableReinitialize={true}
                initialValues={{
                    createdDate: (props.renderObject?.createdDate) ? moment(props.renderObject.createdDate, 'YYYY-MM-DD HH:mm:ss').toDate() : moment(new Date(), 'YYYY-MM-DD HH:mm:ss').toDate(),

                }}

                validationSchema={Yup.object({
                })}

                onSubmit={(values, actions) => {
                    if (props.type == FormType.EDIT) {
                        var r = new Timestamp();
                        r.fromDate(moment(values.createdDate, 'YYYY-MM-DD HH:mm:ss').toDate())
                        req.setCreateddate(r)
                        req.setId(props?.renderObject?.id);


                        promise = dispatch(update({ body: req, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: UpdateCurrencyPairPriceLogResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            ...props.renderObject,
                                            createdDate: formatDate(values.createdDate),
                                        });
                                    }
                                }
                                actions.setSubmitting(false)


                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    } else {

                    }
                }}

            >
                {formkikProps => (
                    <Form >
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? (state.message.data != 401) ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            /> :
                                <SessionExpiredDialog />
                                : null
                            }
                            <DatePicker
                                isRequired
                                firstDayOfWeek={DayOfWeek.Sunday}
                                label={t("createdDate")}
                                placeholder={t("createdDate")}
                                disabled={state.isLoading}
                                value={formkikProps.values.createdDate}
                                ariaLabel={t("createdDate")}
                                formatDate={(e) => {
                                    return formatDate(e, 'YYYY-MM-DD hh:mm:ss')
                                }}
                                parseDateFromString={(date) => {
                                    return moment(date, 'YYYY-MM-DD hh:mm:ss').toDate();
                                }}
                                

                                // DatePicker uses English strings by default. For localized apps, you must override this prop.
                                strings={defaultDatePickerStrings}
                                onSelectDate={(date: Date | null | undefined) => {
                                    formkikProps.setFieldValue("createdDate", date)
                                }}

                            />
                            <TimePicker
                                required
                                showSeconds
                                label={t("time")}
                                placeholder={t("time")}
                                disabled={state.isLoading}
                                value={formkikProps.values.createdDate}
                                onChange={(_ev: React.FormEvent<IComboBox>, date: Date) => {
                                    formkikProps.setFieldValue("createdDate", moment(date, 'YYYY-MM-DD hh:mm:ss').toDate())

                                }}

                            />

                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("add") : t("edit")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={props.type == FormType.ADD ? t("discard") : t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>



                    </Form>
                )}
            </Formik>
        </>
    );

}

