import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ISearchBoxProps, DialogFooter, CheckboxVisibility } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../../../app/Hooks"
import { getPage } from "../../../../../../app/Pages"
import { setPage } from "../../../../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../../../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../../../../common/Table/Table"
import { dismissMessage, getUserTypes, reset, resetMembers, resetUserTypes, setNumberOfResults, setSort, setSearchText, setSelectedMembers, setSelectedUserType, getMembers, removeProfile, setIsFilteredUserTypesSet, updateUser, addUser, setIsFilteredMembersSet } from "./GroupMembersPageSlice"
import { ApiMessage, getHeaders } from "../../../../../../app/Api"
import { useId, useBoolean } from '@fluentui/react-hooks';

import * as google_protobuf_wrappers_pb from 'google-protobuf/google/protobuf/wrappers_pb'
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { getMainTheme } from "../../../../../../app/Themes"
import { SearchField } from "../../../../../common/SearchField/SearchField"
import { formatDate, printComponent, normalizeKey } from "../../../../../../app/Helpers"
import { TableState } from "../../../../../common/Table/TableSate"
import { Message } from "../../../../../common/Message/Message"
import { ShareBox } from "../../../../../common/ShareBox/ShareBox"
import { GetUserTypesRequest } from "../../../../../../repository/UserManagement/Customers/usertype_pb"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { bool, string } from "yup"
import { Popup } from "../../../../../common/Popup/Popup"
import { FormType } from "../../../../../forms/FormProps"
import { GroupMembersForm } from "../../../../../forms/Customers/Groups/GroupMembers/GroupMembersFrom"
import { DeleteGroupMembershipRequest, GetGroupMembershipsRequest } from "../../../../../../repository/UserManagement/Customers/groupmembership_pb"
import { useReactToPrint } from "react-to-print"
import { PageProps } from "../../../../PageProps"
import { List } from "../../../../../common/List/List"
import { UserTypesPage } from "../../../../Management/UserTypes/UserTypesPage"

const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};

let req: GetGroupMembershipsRequest;

let userTypeReq: GetUserTypesRequest;

let org: number = -1;

let getCustomersPromise: any;
let getUserTypesPromise: any;
let actionPromise: any;

interface GroupMembersPageProps {
    group: any
}
export const GroupMembersPage: React.FunctionComponent<GroupMembersPageProps & PageProps> = (props) => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();



    const state: {
        isChangeStateLoading: boolean,
        members: TableState, message: ApiMessage | undefined
        appTheme: string, isDarkModeEnabled: boolean, userTypes: TableState, searchText: string | undefined,
        filters: any[],
    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.customersGroupMembersPage.isChangeStateLoading,
            members: state.customersGroupMembersPage.members, message: state.customersGroupMembersPage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled, userTypes: state.customersGroupMembersPage.userTypes,
            searchText: state.customersGroupMembersPage.searchText,
            filters: state.customersGroupMembersPage.filters,
        }
    })
    const calloutTargetId = useId('callout-target');
    const labelId = useId('callout-label');
    const statusActionConfirmationcalloutTargetId = useId('status-action-callout-target');

    const ActionConfirmationLabelId = useId('action-callout-label');
    const shareCalloutLabelId = useId('share-callout-label');
    const shareTargetId = useId('share-callout-target');

    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const [isActionConfirmationCalloutVisible, { toggle: toggleisActionConfirmationCalloutVisible }] = useBoolean(false);
    const [isShareCalloutVisible, { toggle: toggleisShareCalloutVisible }] = useBoolean(false);

    const [currentAction, setCurrenctAction] = useState(0)
    const ref = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => {
            return printComponent(ref.current?.cloneNode(true), true)
        }

    });
    const getActionId = (action: number): string => {
        if (action == 1) {
            return statusActionConfirmationcalloutTargetId;
        } else {
            return ""
        }
    }

    const _selection = useConst(() => new Selection({
        onSelectionChanged: () => {
            if (_selection.count > 0) {
                dispatch(setSelectedMembers(_selection.getSelection()));
            } else {
                dispatch(setSelectedMembers([]));
            }
        },

    }));

    const clearFilter = () => {
        dispatch(setSelectedUserType([]))
        req.setUsertype(-1)

    }

    const search = (searchText: string | undefined) => {
        let isFiltered: boolean = false;
        if (searchText) {
            const wrapper = new StringValue();
            wrapper.setValue(searchText.trim());
            req.setSearch(wrapper)
            isFiltered = true;
        } else {
            req.setSearch(undefined)
        }

        if (state.userTypes.selected.length > 0) {

            req.setUsertype(Number(state.userTypes.selected.at(0)?.id))
            isFiltered = true;


        } else {
            req.setUsertype(-1)
        }


        req.setNextto(0)
        dispatch(resetMembers())
        dispatch(setIsFilteredMembersSet(isFiltered))
        getCustomersPromise?.abort()
        getCustomersPromise = dispatch(getMembers({ body: req, headers: getHeaders() }))
    }


    useEffect(() => {
        req = new GetGroupMembershipsRequest();
        userTypeReq = new GetUserTypesRequest();


        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                req.setOrganization(wrapper)
                userTypeReq.setOrganization(wrapper)
            }
        }
        req.setNextto(0)
        req.setNumofresults(state.members.numberOfResults)
        req.setOrder(state.members.isDescending)
        req.setGroupid(props.group?.id)
        req.setUsertype(-1)

        userTypeReq.setNumofresults(state.userTypes.numberOfResults)
        userTypeReq.setOrder(state.userTypes.isDescending)

        getCustomersPromise = dispatch(getMembers({ body: req, headers: getHeaders() }))
        return () => { //clean up
            getCustomersPromise?.abort();
            getUserTypesPromise?.abort();
            actionPromise?.abort();
            dispatch(reset());
        }
    }, [])


    const c: IColumn[] = [
        {
            key: 'id',
            name: t("id"),
            minWidth: 20,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {

                return <Link onClick={(e) => {
                    if (e && !props.isSelection) {
                        e.preventDefault();
                        navigate("/customers/group-memberships/" + item?.id + ((org == -1) ? "" : "?org=" + org));
                    }
                }
                } href={"/customers/group-memberships/" + item?.id + ((org == -1) ? "" : "?org=" + org)} target={props.isSelection ? "_blank" : undefined}> {item?.id}</Link >;
                //return <Link href="/" underline >{item.name}</Link>;
            },
        },
        {
            key: 'name',
            name: t("user"),
            minWidth: 20,
            maxWidth: 250,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <Link onClick={(e) => {
                    if (e && !props.isSelection) {
                        e.preventDefault();
                        navigate("/customers/users/" + item?.profileId + ((org == -1) ? "" : "?org=" + org));
                    }
                }
                } href={"/customers/users/" + item?.profileId + ((org == -1) ? "" : "?org=" + org)} target={props.isSelection ? "_blank" : undefined} ><Persona
                        text={item?.name == "" ? item?.profileId : item?.name}

                        size={PersonaSize.size40}

                    /></Link>

            },
        },

        {
            key: 'createdDate',
            name: t("createdDate"),
            minWidth: 20,
            maxWidth: 60,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {


                return <Text>{(item?.createdDate)}</Text>; //{item.createdDate}
            },
        },
    ];

    const _selectionItems: ICommandBarItemProps[] = [
        {
            key: 'remove',
            text: t("removeMember"),
            title: t('removeMember'),
            iconProps: { id: statusActionConfirmationcalloutTargetId, iconName: "Remove", styles: { root: { fontWeight: "bold" } } },
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 1 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 1 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 1 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(1)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },
        {
            key: 'logs',
            text: t("logs"),
            iconProps: { iconName: "PageList", styles: { root: { fontWeight: "bold" } } },
            title: t("logs"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/logs?resourceId=" + state.members.selected.at(0)?.id + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },
        {
            key: 'share',
            text: t("share"),
            title: t('share'),
            iconProps: { id: shareTargetId, iconName: 'Share', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                toggleisShareCalloutVisible()
            },

        },

    ];

    const mainOptions: ICommandBarItemProps[] = [
        {
            key: 'newItem',
            text: t('add'),
            title: t('add'),
            iconProps: { iconName: 'Add' },
            onClick: () => {
                //  navigate("add")
                setCurrenctAction(100)
            }
        },
        {
            key: 'refresh',
            text: t('refresh'),
            title: t('refresh'),
            iconProps: { iconName: 'refresh' },
            disabled: state.members.isFetching,
            onClick: () => {
                dispatch(resetMembers())
                req.setNextto(0)
                //   if (!state.searchText || state.searchText?.trim().length == 0) {
                //     req.setSearch(undefined)
                //}
                getCustomersPromise = dispatch(getMembers({ body: req, headers: getHeaders() }))
            }
        },
        {
            key: 'print',
            text: t("print"),
            iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
            title: t("print"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    handlePrint();
                }
            }
        },
    ];
    const _farOptions: ICommandBarItemProps[] = [
        {
            key: 'sort',
            text: t('sort'),
            title: t('sort'),
            iconProps: { iconName: 'sort' },
            disabled: state.members.isFetching,
            subMenuProps: {
                items: [{
                    key: 'ascending',
                    text: t('ascendingDate'),
                    title: t('ascendingDate'),
                    canCheck: true,
                    checked: !state.members.isDescending,
                    onClick: () => {
                        dispatch(setSort(false))
                        dispatch(resetMembers())
                        req.setOrder(false)
                        req.setNextto(0)
                        getCustomersPromise = dispatch(getMembers({ body: req, headers: getHeaders() }))

                    }


                }, {
                    key: 'descending',
                    text: t('descendingDate'),
                    title: t('descendingDate'),
                    canCheck: true,
                    checked: state.members.isDescending,
                    onClick: () => {
                        dispatch(setSort(true))
                        dispatch(resetMembers())
                        req.setOrder(true)
                        req.setNextto(0)
                        getCustomersPromise = dispatch(getMembers({ body: req, headers: getHeaders() }))

                    }

                }]
            }
        }


    ];
    const _farBottomOptions: ICommandBarItemProps[] = [
        {
            key: 'results',
            text: t('results') + ': ' + state.members.numberOfResults,
            title: t('results') + ': ' + state.members.numberOfResults,
            iconProps: { iconName: 'ShowResults' },
            subMenuProps: {
                items: [{
                    key: '10',
                    text: '10',
                    title: '10',
                    canCheck: true,
                    checked: (state.members.numberOfResults == 10 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(10))
                        req.setNumofresults(10)
                    }


                }, {
                    key: '50',
                    text: '50',
                    title: '50',
                    canCheck: true,
                    checked: (state.members.numberOfResults == 50 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(50))
                        req.setNumofresults(50)
                    }

                },
                {
                    key: '100',
                    text: '100',
                    title: '100',
                    canCheck: true,
                    checked: (state.members.numberOfResults == 100 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(100))
                        req.setNumofresults(100)
                    }

                }]
            }
        }
    ];


    const userTypeSuggestionsProps: ISuggestionsProps<ITag> = {
        suggestionsHeaderText: t('usersTypes'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.userTypes.hasMore && !state.userTypes.isFetching,
        onRenderSuggestion: (props: ITag, suggestionItemProps: ISuggestionItemProps<ITag>) => {
            return <TagItemSuggestion>
                {props?.name ?? props?.key}
            </TagItemSuggestion>
        },
        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.userTypes.items.findIndex(e => e.id == item.key)
            if (u >= 0) {
                dispatch(setSelectedUserType([state.userTypes.items.at(u)]))
            }
        },
        onGetMoreResults: () => {
            if (state.userTypes.items.length > 0 && !state.userTypes.isFetching) {
                userTypeReq.setNextto(state.userTypes.items.at(state.userTypes.items.length - 1).id)
                getUserTypesPromise = dispatch(getUserTypes({ body: userTypeReq, headers: getHeaders() }))

            }
        },
        suggestions: state.userTypes.items.length > 0 ? state.userTypes.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : [],
        isLoading: state.userTypes.items.length == 0 ? state.userTypes.isFetching : false,
        isSearching: state.userTypes.items.length > 0 ? state.userTypes.isFetching : false,

    };
    const onUserTypeFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {
        dispatch(resetUserTypes())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        userTypeReq.setSearch(wrapper)
        userTypeReq.setNextto(0)
        dispatch(setIsFilteredUserTypesSet(true))
        getUserTypesPromise?.abort();
        getUserTypesPromise = dispatch(getUserTypes({ body: userTypeReq, headers: getHeaders() }))

        return [];
    }


    return (
        <Stack >
            <Popup isOpen={(currentAction == 100)} title={t("addMembers")} onDismiss={() => { setCurrenctAction(0) }} isBlocking={true} >
                <GroupMembersForm type={FormType.ADD} renderObject={{ group: props.group }} childrenGap={10} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(addUser(e))

                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Popup isOpen={(currentAction == 1001)} title={t("usersTypes")} onDismiss={() => { setCurrenctAction(0) }} >

                <UserTypesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedUserType([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }
            <CommandBar
                items={state.members.selected.length == 0 ? mainOptions : _selectionItems}
                farItems={_farOptions}

            //id={actionConfirmationcalloutTargetId}


            />
            {isShareCalloutVisible ? (
                <Callout
                    ariaLabelledBy={shareCalloutLabelId}
                    target={`#${shareTargetId}`}
                    onDismiss={toggleisShareCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <ShareBox onDismiss={toggleisShareCalloutVisible} title={t("shareMembership")} value={`https://${window.location.host}/customers/group-memberships/${state.members.selected.at(0).id}` + ((org == -1) ? "" : "?org=" + org)}></ShareBox>
                </Callout>
            ) : null}


            {isActionConfirmationCalloutVisible ? (
                <Callout
                    ariaLabelledBy={ActionConfirmationLabelId}
                    target={`#${getActionId(currentAction)}`}
                    onDismiss={toggleisActionConfirmationCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <Stack tokens={{ padding: '20px 24px ' }}>
                        <Text block variant="xLarge" styles={{ root: { marginBottom: 12, fontWeight: FontWeights.semilight } }}>
                            {(currentAction == 1 ? t("removeMember") : "")}
                        </Text>
                        <Text block variant="small">
                            {(currentAction == 1 ? t("removeMemberDesc") : "")}
                        </Text>
                        {/* This FocusZone allows the user to go between buttons with the arrow keys.
              It's not related to or required for FocusTrapCallout's built-in focus trapping. */}
                        <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                            <Stack reversed tokens={{ childrenGap: 8, padding: "20px 0px 0px 0px" }} horizontal>
                                <DefaultButton autoFocus onClick={toggleisActionConfirmationCalloutVisible}>{t("cancel")}</DefaultButton>

                                <PrimaryButton onClick={() => {
                                    toggleisActionConfirmationCalloutVisible()
                                    if (state.members.selected.length > 0) {
                                        if (currentAction == 1) {
                                            let req = new DeleteGroupMembershipRequest();
                                            req.setId(state.members.selected.at(0)?.id)
                                            if (org != -1) {
                                                const wrapper = new Int32Value();
                                                wrapper.setValue(org);
                                                req.setOrganization(wrapper)

                                            }
                                            actionPromise = dispatch(removeProfile({ body: req, headers: getHeaders() }))
                                            actionPromise.unwrap().then((e: any) => {
                                                _selection.setAllSelected(false)
                                            })
                                        }
                                    }
                                }} text={(currentAction == 1 ? t("remove") : "")}   >

                                </PrimaryButton>
                            </Stack>
                        </FocusZone>
                    </Stack>
                </Callout>
            ) : null}

            {isCalloutVisible ? (
                <Callout
                    ariaLabelledBy={labelId}

                    target={`#${calloutTargetId}`}
                    onDismiss={toggleIsCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    isBeakVisible={true}
                >
                    <Stack tokens={{ padding: 28, childrenGap: 30 }}  >
                        <Text block variant="mediumPlus" >
                            {t("filterDesc")}
                        </Text>

                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label >{t("usertype")}</Label>
                            <List
                                styles={{ root: { width: 268 } }}
                                inputProps={{ placeholder: t("usertype"), }}
                                suggestionsHeaderText={t('usersTypes')}
                                isLoading={state.userTypes.items.length == 0 ? state.userTypes.isFetching : false}
                                isSearching={state.userTypes.items.length > 0 ? state.userTypes.isFetching : false}
                                moreSuggestionsAvailable={state.userTypes.hasMore && !state.userTypes.isFetching}
                                suggestions={state.userTypes.items.length > 0 ? state.userTypes.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : []}
                                onGetMoreResults={() => {

                                    if (state.userTypes.items.length > 0 && !state.userTypes.isFetching) {
                                        userTypeReq.setNextto(state.userTypes.items.at(state.userTypes.items.length - 1).id)
                                        getUserTypesPromise = dispatch(getUserTypes({ body: userTypeReq, headers: getHeaders() }))

                                    }
                                }}
                                onSuggestionClick={(ev?, item?: any, index?: number) => {

                                    var u = state.userTypes.items.findIndex(e => e.id == item.key)
                                    if (u >= 0) {
                                        dispatch(setSelectedUserType([state.userTypes.items.at(u)]))
                                    }
                                }}
                                isPeoplePicker={false}
                                selectedItems={state.userTypes.selected.length > 0 ? state.userTypes.selected.map(e => { return { name: e.name, key: e.id } as ITag }) : []}
                                onChange={(e) => {
                                    dispatch(setSelectedUserType([]))

                                }}
                                onEmptyInputFocus={() => {
                                    dispatch(resetUserTypes())
                                    userTypeReq.setSearch(undefined)
                                    userTypeReq.setNextto(0)
                                    dispatch(setIsFilteredUserTypesSet(false))
                                    getUserTypesPromise?.abort()
                                    getUserTypesPromise = dispatch(getUserTypes({ body: userTypeReq, headers: getHeaders() }))
                                    return []
                                }}
                                onFilterChanged={onUserTypeFilterChanged}
                                endButtons={[
                                    {
                                        title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                        onClick: () => {
                                            setCurrenctAction(1001)

                                        }
                                    }]}
                            />

                        </Stack>


                        <DialogFooter>
                            <PrimaryButton text={t("search")} onClick={(e => {
                                search(state.searchText);
                                toggleIsCalloutVisible();
                            })} />
                            <DefaultButton text={t("clearFilter")} onClick={() => {
                                clearFilter();
                            }} />

                        </DialogFooter>
                    </Stack>
                </Callout>
            ) : null}

            <Stack.Item align="center"  >
                <Stack horizontal  >
                    <SearchField
                        inputProps={{
                            maxLength: 250,
                            placeholder: t("search"),
                        }}

                        onInputChange={(e) => {
                            dispatch(setSearchText(e));
                            return e;
                        }}
                        endButtons={[
                            { id: calloutTargetId, title: t("filter"), iconProps: { iconName: "filter" }, onClick: toggleIsCalloutVisible },
                            {
                                title: t("search"), checked: true, iconProps: { iconName: "search" }, onClick: () => {
                                    search(state.searchText);
                                    if (isCalloutVisible)
                                        toggleIsCalloutVisible();

                                }
                            }]}
                        onChange={(e) => {
                            const res = state.filters.filter(({ key: key1 }) => !e?.some(({ key: key2 }) => key1 === key2));
                            res?.forEach(e => {
                                if (e.key == 2) {
                                    dispatch(setSelectedUserType([]))
                                    req.setUsertype(0)
                                }
                            });

                        }}

                        onResolveSuggestions={() => { return [] }}
                        selectedItems={state.filters.map(e => { return { name: e.name, key: e.key } as ITag }) || []}
                        onSearch={(e) => {
                            search(state.searchText);
                            if (isCalloutVisible)
                                toggleIsCalloutVisible();
                        }}
                        onClear={(e) => {
                            dispatch(setSearchText(undefined))
                            if (state.members.isFilteredSet) {
                                search(undefined);
                            }
                            /*if (req.getSearch() && !state.managers.isFilteredSet) {
                                dispatch(resetRoles())
                                req.setNextto(undefined)
     
                                req.setSearch(undefined)
     
                                getManagersPromise = dispatch(getManagerUsers({ body: req, headers: getHeaders() }))
                            }*/
                        }}
                        showIcon
                    />
                </Stack>
            </Stack.Item>



            <Table
                setKey="members"
                items={state.members.items}
              width={props.isSelection ? '80vw' : undefined}
                height={props.isSelection ? "60vh" : state.message == undefined ? "calc(100vh - 225px)" : "calc(100vh - 273px)"}
                columns={c}
                checkboxVisibility={props.isSelection ? CheckboxVisibility.always : CheckboxVisibility.onHover}
                compRef={ref}
                onScroll={(e) => {
                    const bottom = e?.currentTarget?.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight;
                    if (bottom && !state.members.isFetching && state.members.hasMore) {
                        const wrapper = new StringValue();
                        req.setNextto(state.members.items.at(state.members.items.length - 11)?.id)
                        getCustomersPromise?.abort();
                        getCustomersPromise = dispatch(getMembers({ body: req, headers: getHeaders() }))

                    }
                }}
                selection={_selection}
                numberOfResultsOptions={[10, 50, 100]}
                selectedNumberOfResults={state.members.numberOfResults}
                onNumberOfResultsChange={(e) => {
                    dispatch(setNumberOfResults(e))
                    req.setNumofresults(e)
                }}
                selectionMode={SelectionMode.single}
                disableSelectionZone={state.isChangeStateLoading}
                enableShimmer={state.members.items.length == 0 && state.members.isFetching}
                isColumnSortingEnabled={state.members.selected.length == 0}
                layoutMode={props.isSelection ? DetailsListLayoutMode.fixedColumns : DetailsListLayoutMode.justified}

                onRowDidMount={(item?: any, index?: number) => {
                    //alert(index)
                    if (index == state.members.items.length - 11) { // last element renderd
                        const { current } = ref;
                        if (current) {
                            if (state.members.items.length > 0 && !state.members.isFetching && state.members.hasMore) {
                                if (current.scrollHeight == current.clientHeight) { // scroll not shown? get more data 
                                    req.setNextto(state.members.items.at(state.members.items.length - 11)?.id)
                                    getCustomersPromise?.abort();
                                    getCustomersPromise = dispatch(getMembers({ body: req, headers: getHeaders() }))
                                }


                            }
                        }
                    }
                }}
            />


            {props.isSelection ? <DialogFooter>
                <PrimaryButton disabled={state.members.isFetching || state.members.selected.length == 0} text={state.members.isFetching ? undefined : t("select")} onClick={() => {
                    if (props.onItemSelected && state.members.selected.length > 0) {
                        props.onItemSelected(state.members.selected.at(0))
                    }
                }} >

                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.members.isFetching ? "block" : "none") } }} />

                </PrimaryButton>
                <DefaultButton disabled={state.members.isFetching} text={t("discard")} onClick={() => {
                    if (props.onCancel) {
                        props.onCancel()
                    }
                }} />
            </DialogFooter> : undefined}
        </Stack>



    );
}
