import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, refreshSession, getSession, ACCOUNTANT_API_URL, APIRequest, APIResponse } from '../../../../app/Api';
import { AccountTypeEndpointClient } from '../../../../repository/Accountant/Account_typeServiceClientPb';
import { GetAccountTypesResponse, GetAccountTypesRequest } from '../../../../repository/Accountant/account_type_pb';
import { formatDate, timestampToDate } from '../../../../app/Helpers';
import { DynamicField } from '../../../common/DynamicFields/DynamicFields';
import { AccountTypeRuleEndpointClient } from '../../../../repository/Accountant/Account_type_ruleServiceClientPb';
import { FeeEndpointClient } from '../../../../repository/Accountant/FeeServiceClientPb';
import { GetFeesRequest, GetFeesResponse } from '../../../../repository/Accountant/fee_pb';
import { GetAccountRequest, OpenAccountRequest, OpenAccountResponse, UpdateAccountRequest, UpdateAccountResponse, GetAccountResponse, TransferAccountOwnershipResponse, TransferAccountOwnershipRequest, LinkResponse, LinkRequest } from '../../../../repository/Accountant/account_pb';
import { TableState } from '../../../common/Table/TableSate';
import { AccountEndpointClient } from '../../../../repository/Accountant/AccountServiceClientPb';
import { CurrencyEndpointClient } from '../../../../repository/Accountant/CurrencyServiceClientPb';
import { UserEndpointClient } from '../../../../repository/UserManagement/Customers/UserServiceClientPb';
import { LimitationEndpointClient } from '../../../../repository/Accountant/LimitationServiceClientPb';
import { GetLimitationsRequest, GetLimitationsResponse } from '../../../../repository/Accountant/limitation_pb';
import { GetCurrenciesRequest, GetCurrenciesResponse } from '../../../../repository/Accountant/currency_pb';
import { GetUsersRequest, GetUsersResponse } from '../../../../repository/UserManagement/Customers/user_pb';
import { AccountAuthorizationEndpointClient } from '../../../../repository/Accountant/Account_autorizationServiceClientPb';
import { AddAccountAuthorizationRequest, AddAccountAuthorizationResponse, GetAccountAuthorizationResponse, GetAccountAuthorizationRequest, UpdateAccountAuthorizationResponse, UpdateAccountAuthorizationRequest } from '../../../../repository/Accountant/account_autorization_pb';


export interface AccountAuthorizationFormState {
    isLoading: boolean
    message?: ApiMessage
    stage1: any,
    clients: TableState
}

const initialState: AccountAuthorizationFormState = {
    isLoading: false,
    message: undefined,
    stage1: undefined,
    clients: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },

}


const client = new AccountAuthorizationEndpointClient(ACCOUNTANT_API_URL, null, null);
const clienCustomers = new UserEndpointClient(API_URL, null, null);

export const add = createAsyncThunk<AddAccountAuthorizationResponse.AsObject, APIRequest<AddAccountAuthorizationRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/accounts/accountAuthorization/add',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.add(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)

 

export const getClinetUsers = createAsyncThunk<GetUsersResponse.AsObject, APIRequest<GetUsersRequest>, {
    rejectValue: ApiMessage
}>(
    'forms/accountant/accounts/accountAuthorization/clients/fetch',
    async (req, thunkApi) => {

        let callReq = async (): Promise<any> => {
            try {
                var r = await clienCustomers.getUsers(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {
                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const get = createAsyncThunk<GetAccountAuthorizationResponse.AsObject, APIRequest<GetAccountAuthorizationRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/accounts/accountAuthorization/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getAccountAuthorization(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)


export const update = createAsyncThunk<UpdateAccountAuthorizationResponse.AsObject, APIRequest<UpdateAccountAuthorizationRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/accounts/accountAuthorization/update',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.update(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)

 


export const accountAuthorizationFormSlice = createSlice({
    name: 'forms/accountant/accounts/accountAuthorization',
    initialState,
    reducers: {

        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.isLoading = false;
            state.message = undefined;
            state.stage1 = initialState.stage1;
            state.clients = initialState.clients;
        },
        resetMessage: (state) => {
            state.message = undefined;
        },
        setStage1: (state, action: PayloadAction<any>) => {
        
            state.stage1 = action.payload;
        },
        resetUsers: (state) => {
            state.clients.items = [];
            state.clients.hasMore = true;
        },
      

        setSelectedUserItems: (state, action: PayloadAction<any[]>) => {
            state.clients.selected = action.payload;

        },

     
        setIsFilteredUsersSet: (state, action: PayloadAction<boolean>) => {
            state.clients.isFilteredSet = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(add.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            if (payload)
                state.message = toApiMessage(payload.success?.message);

        })
        builder.addCase(add.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(add.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })
       

      

      

        builder.addCase(getClinetUsers.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.usersList.map(val => {
                    return {
                        internalId: val.profile?.internalid,
                        name: (val.profile?.firstname?.value ?? "") + " " + (val.profile?.lastname?.value ?? ""),
                        firstname: (val.profile?.firstname?.value ?? ""),
                        lastname: (val.profile?.lastname?.value ?? ""),
                        username: val.username?.value ?? "",
                        isActive: val.isactive,
                        isConfirmed: val.isconfirmed,
                        createdDate: formatDate(timestampToDate(val.profile?.createddate?.seconds, val.profile?.createddate?.nanos)),
                        addedBy: (val.profile?.addedby?.firstname?.value ?? "") + " " + (val.profile?.addedby?.lastname?.value ?? ""),
                        addedById: val.profile?.addedby?.id?.value,
                        role: val.profile?.role?.name?.value,
                        roleId: val.profile?.role?.id,
                        usertypeId: val.profile?.usertype?.id,
                        usertype: val.profile?.usertype?.name?.value,
                        lastlogin: formatDate(timestampToDate(val.lastlogin?.seconds, val.lastlogin?.nanos)),
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.clients.numberOfResults) {
                    state.clients.hasMore = false;
                }
                state.clients.items = state.clients.items.concat(r);
                //console.log(state.items)

            } else {
                state.clients.hasMore = false;
            }
            state.clients.isFetching = false

        })
        builder.addCase(getClinetUsers.rejected, (state, action) => {
            state.clients.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }

        })
        builder.addCase(getClinetUsers.pending, (state, action) => {
            state.clients.isFetching = true;
            // state.message = undefined;
        })

        builder.addCase(get.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {

                let r = {
                    id: payload.success?.id,
                    accountId: payload.success?.account?.id?.value,
                    accountName: payload.success?.account?.name?.value,
                    accountCurrencyId: payload.success?.account?.currency?.id?.value ?? "",
                    accountCurrencyName: payload.success?.account?.currency?.name?.value ?? "",
                    accountCurrencyCode: payload.success?.account?.currency?.code?.value ?? "",
                    accountCurrencyDecimalPlaces: payload.success?.account?.currency?.decimalplaces?.value ?? undefined,
                    accountCurrencyForm: payload.success?.account?.currency?.form?.value ?? undefined,
                    accountCurrencySymbol: payload.success?.account?.currency?.symbol?.value ?? "",
                    authorizedClientId: payload.success?.authorizedclient?.id?.value ,
                    authorizedClientFirstName: payload.success?.authorizedclient?.firstname?.value ,
                    authorizedClientMiddleName: payload.success?.authorizedclient?.middlename?.value ,
                    authorizedClientLastName: payload.success?.authorizedclient?.lastname?.value ,
                    authorizedClientName: (payload.success?.authorizedclient?.firstname?.value ?? "") + " " + (payload.success?.createdby?.lastname?.value ?? "") ,
                    permissions: payload.success?.permissionsList,
                    createdDate: formatDate(timestampToDate(payload.success?.createddate?.seconds, payload.success?.createddate?.nanos)),
                    addedBy: (payload.success?.createdby?.firstname?.value ?? "") + " " + (payload.success?.createdby?.lastname?.value ?? ""),
                    addedById: payload.success?.createdby?.id?.value,
                    extraFields: [] as DynamicField[],

                }

                if (payload?.success?.extrafieldsList) {
                    let count = 0
                    payload?.success?.extrafieldsList?.forEach(element => {
                        r.extraFields.push({ key: element.key, label: element.label, type: element.type, value: element.value })
                        count++
                    });
                }

                state.stage1 = r
            }
        })
        builder.addCase(get.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(get.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })
        builder.addCase(update.fulfilled, (state, { payload }) => {
            if (payload)
                state.message = toApiMessage(payload.success?.message);
            state.isLoading = false;
        })
        builder.addCase(update.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(update.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })
     }
})

export const { reset, dismissMessage, setStage1, resetMessage,   resetUsers, setIsFilteredUsersSet ,setSelectedUserItems } = accountAuthorizationFormSlice.actions

export default accountAuthorizationFormSlice.reducer


