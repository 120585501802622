import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, refreshSession, getSession, ACCOUNTANT_API_URL, APIRequest, APIResponse } from '../../../../app/Api';
import { AccountTypeEndpointClient } from '../../../../repository/Accountant/Account_typeServiceClientPb';
import { GetAccountTypesResponse, GetAccountTypesRequest } from '../../../../repository/Accountant/account_type_pb';
import { formatDate, timestampToDate } from '../../../../app/Helpers';
import { DynamicField } from '../../../common/DynamicFields/DynamicFields';
import { AccountTypeRuleEndpointClient } from '../../../../repository/Accountant/Account_type_ruleServiceClientPb';
import { FeeEndpointClient } from '../../../../repository/Accountant/FeeServiceClientPb';
import { GetFeesRequest, GetFeesResponse } from '../../../../repository/Accountant/fee_pb';
import { GetAccountRequest, OpenAccountRequest, OpenAccountResponse, UpdateAccountRequest, UpdateAccountResponse, GetAccountResponse, TransferAccountOwnershipResponse, TransferAccountOwnershipRequest, LinkResponse, LinkRequest, UpdateTransferControlResponse, UpdateTransferControlRequest } from '../../../../repository/Accountant/account_pb';
import { TableState } from '../../../common/Table/TableSate';
import { AccountEndpointClient } from '../../../../repository/Accountant/AccountServiceClientPb';
import { CurrencyEndpointClient } from '../../../../repository/Accountant/CurrencyServiceClientPb';
import { UserEndpointClient } from '../../../../repository/UserManagement/Customers/UserServiceClientPb';
import { LimitationEndpointClient } from '../../../../repository/Accountant/LimitationServiceClientPb';
import { GetLimitationsRequest, GetLimitationsResponse } from '../../../../repository/Accountant/limitation_pb';
import { GetCurrenciesRequest, GetCurrenciesResponse } from '../../../../repository/Accountant/currency_pb';
import { GetUsersRequest, GetUsersResponse } from '../../../../repository/UserManagement/Customers/user_pb';


export interface AccountsFormState {
    isLoading: boolean
    message?: ApiMessage
    stage1: any,
    currencies: TableState
    accountTypes: TableState
    owners: TableState
    limitations: TableState
}

const initialState: AccountsFormState = {
    isLoading: false,
    message: undefined,
    stage1: undefined,
    currencies: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    accountTypes: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    limitations: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    owners: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },

}


const client = new AccountEndpointClient(ACCOUNTANT_API_URL, null, null);
const clientCurrency = new CurrencyEndpointClient(ACCOUNTANT_API_URL, null, null);
const clientAccountType = new AccountTypeEndpointClient(ACCOUNTANT_API_URL, null, null);
const clienCustomers = new UserEndpointClient(API_URL, null, null);
const clienLimition = new LimitationEndpointClient(ACCOUNTANT_API_URL, null, null);

export const add = createAsyncThunk<OpenAccountResponse.AsObject, APIRequest<OpenAccountRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/accounts/add',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.open(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)


export const update = createAsyncThunk<UpdateAccountResponse.AsObject, APIRequest<UpdateAccountRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/accounts/update',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.update(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)


export const updateTransferControl = createAsyncThunk<UpdateTransferControlResponse.AsObject, APIRequest<UpdateTransferControlRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/accounts/updateTransferControl',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.updateTransferControl(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)



export const linkAccount = createAsyncThunk<LinkResponse.AsObject, APIRequest<LinkRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/accounts/link',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.link(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)




export const get = createAsyncThunk<GetAccountResponse.AsObject, APIRequest<GetAccountRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/accounts/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getAccount(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)




export const getClinetUsers = createAsyncThunk<GetUsersResponse.AsObject, APIRequest<GetUsersRequest>, {
    rejectValue: ApiMessage
}>(
    'forms/accountant/accounts/clients/fetch',
    async (req, thunkApi) => {

        let callReq = async (): Promise<any> => {
            try {
                var r = await clienCustomers.getUsers(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {
                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

export const getCurrencies = createAsyncThunk<GetCurrenciesResponse.AsObject, APIRequest<GetCurrenciesRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/accounts/currencies/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await clientCurrency.getCurrencies(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getAccountTypes = createAsyncThunk<GetAccountTypesResponse.AsObject, APIRequest<GetAccountTypesRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/accounts/accountTypes/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clientAccountType.getAccountTypes(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

export const getLimitations = createAsyncThunk<GetLimitationsResponse.AsObject, APIRequest<GetLimitationsRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/accounts/limitations/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clienLimition.getLimitations(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)



export const accountsFormSlice = createSlice({
    name: 'forms/accountant/accounts',
    initialState,
    reducers: {

        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.isLoading = false;
            state.message = undefined;
            state.stage1 = initialState.stage1;
            state.accountTypes = initialState.accountTypes;
            state.currencies = initialState.currencies;
            state.limitations = initialState.limitations;

            state.owners = initialState.owners;
        },
        setStage1: (state, action: PayloadAction<any>) => {
            let stage1 = {
                id: action.payload.id,
                internalId: action.payload.internalId,
                name: action.payload.name,
                code: action.payload.code,
                currencyId: action.payload.currencyId,
                currencyName: action.payload.currencyName,
                currencyCode: action.payload.currencyCode,
                currencyDecimalPlaces: action.payload.currencyDecimalPlaces,
                currencyForm: action.payload.currencyDecimalPlaces,
                currencySymbol: action.payload.currencySymbol,
                accountTypeId: action.payload.accountTypeId,
                accountTypeName: action.payload.accountTypeName,
                status: action.payload.status,
                ownerFirstname: action.payload.ownerFirstname,
                ownerLastname: action.payload.ownerLastname,
                ownerMiddlename: action.payload.ownerMiddlename,
                ownerName: action.payload.ownerName,
                ownerId: action.payload.ownerId,
                createdDate: action.payload.createdDate,
                addedBy: action.payload.addedBy,
                addedById: action.payload.addedById,
                limitationId: action.payload.limitationId,
                limitationName: action.payload.limitationName,
                mirrorBalance: action.payload.mirrorBalance,
                extraFields: action.payload.extraFields


            }
            state.stage1 = stage1;
        },
        resetUsers: (state) => {
            state.owners.items = [];
            state.owners.hasMore = true;
        },
        resetLimitations: (state) => {
            state.limitations.items = [];
            state.limitations.hasMore = true;
        },
        resetCurrencies: (state) => {
            state.currencies.items = [];
            state.currencies.hasMore = true;
        },
        resetAccountTypes: (state) => {
            state.accountTypes.items = [];
            state.accountTypes.hasMore = true;
        },


        setSelectedCurrencyItems: (state, action: PayloadAction<any[]>) => {
            state.currencies.selected = action.payload;

        },
        setSelectedLimitationItems: (state, action: PayloadAction<any[]>) => {
            state.limitations.selected = action.payload;

        },
        setSelectedAccountTypeItems: (state, action: PayloadAction<any[]>) => {
            state.accountTypes.selected = action.payload;

        },
        setSelectedUserItems: (state, action: PayloadAction<any[]>) => {
            state.owners.selected = action.payload;

        },

        setIsFilteredCurrenciesSet: (state, action: PayloadAction<boolean>) => {
            state.currencies.isFilteredSet = action.payload;
        },
        setIsFilteredLimitationsSet: (state, action: PayloadAction<boolean>) => {
            state.limitations.isFilteredSet = action.payload;
        },
        setIsFilteredAccountTypesSet: (state, action: PayloadAction<boolean>) => {
            state.accountTypes.isFilteredSet = action.payload;
        },
        setIsFilteredOwnersSet: (state, action: PayloadAction<boolean>) => {
            state.owners.isFilteredSet = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(add.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            if (payload)
                state.message = toApiMessage(payload.success?.message);

        })
        builder.addCase(add.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(add.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })
        builder.addCase(update.fulfilled, (state, { payload }) => {
            if (payload)
                state.message = toApiMessage(payload.success?.message);
            state.isLoading = false;
        })
        builder.addCase(update.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(update.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })
        builder.addCase(updateTransferControl.fulfilled, (state, { payload }) => {
            if (payload)
                state.message = toApiMessage(payload.success?.message);
            state.isLoading = false;
        })
        builder.addCase(updateTransferControl.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateTransferControl.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })




        builder.addCase(linkAccount.fulfilled, (state, { payload }) => {
            if (payload)
                state.message = toApiMessage(payload.success?.message);
            state.isLoading = false;
        })
        builder.addCase(linkAccount.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(linkAccount.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })

        builder.addCase(get.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {

                let r = {
                    id: payload.success?.id,
                    internalId: payload.success?.internalid,
                    name: payload.success?.name?.value ?? "",
                    code: payload.success?.code?.value ?? "",
                    currencyId: payload.success?.currency?.id?.value ?? "",
                    currencyName: payload.success?.currency?.name?.value ?? "",
                    currencyCode: payload.success?.currency?.code?.value ?? "",
                    currencyDecimalPlaces: payload.success?.currency?.decimalplaces?.value ?? undefined,
                    currencyForm: payload.success?.currency?.form?.value ?? undefined,
                    currencySymbol: payload.success?.currency?.symbol?.value ?? "",
                    accountTypeId: payload.success?.accounttype?.id?.value ?? "",
                    accountTypeName: payload.success?.accounttype?.name?.value ?? "",
                    status: payload.success?.status?.value ?? undefined,
                    ownerFirstname: payload.success?.owner?.firstname?.value ?? "",
                    ownerLastname: payload.success?.owner?.lastname?.value ?? "",
                    ownerMiddlename: payload.success?.owner?.middlename?.value ?? "",
                    ownerName: (payload.success?.owner?.firstname?.value ?? "") + " " + (payload.success?.owner?.lastname?.value ?? ""),
                    ownerId: payload.success?.owner?.id?.value ?? "",
                    createdDate: formatDate(timestampToDate(payload.success?.createddate?.seconds, payload.success?.createddate?.nanos)),
                    addedBy: (payload.success?.createdby?.firstname?.value ?? "") + " " + (payload.success?.createdby?.lastname?.value ?? ""),
                    addedById: payload.success?.createdby?.id?.value,
                    limitationId: payload.success?.limitation?.id?.value ?? "",
                    limitationName: payload.success?.limitation?.name?.value ?? "",
                    extraFields: [] as DynamicField[],
                    isAuthorizationRequired: payload.success?.accountsetting?.isauthorizationrequired,
                    isLinkingEnable: payload.success?.accountsetting?.islinkingenable?.value,
                    isLinkingMetadataSet: payload.success?.accountsetting?.islinkingmetadataset?.value,
                    transferControl: payload.success?.accountsetting?.tansfercontrol?.value,



                }

                if (payload?.success?.extrafieldsList) {
                    let count = 0
                    payload?.success?.extrafieldsList?.forEach(element => {
                        r.extraFields.push({ key: element.key, label: element.label, type: element.type, value: element.value })
                        count++
                    });
                }

                state.stage1 = r
            }
        })
        builder.addCase(get.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(get.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })

        builder.addCase(getAccountTypes.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.accounttypesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])

                if (r.length < state.accountTypes.numberOfResults) {
                    state.accountTypes.hasMore = false;
                }
                state.accountTypes.items = state.accountTypes.items.concat(r);

            } else {
                state.accountTypes.hasMore = false;
            }
            state.accountTypes.isFetching = false

        })
        builder.addCase(getAccountTypes.rejected, (state, action) => {
            state.accountTypes.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getAccountTypes.pending, (state, action) => {
            state.accountTypes.isFetching = true;
            //state.message = undefined;
        })

        builder.addCase(getCurrencies.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.currenciesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        code: val.code?.value ?? "",
                        icon: val.iconsource?.value ?? "",
                        form: val.form?.formid,
                        symbol: val.symbol?.value ?? "",
                        decimalPlaces: val.decimalplaces?.value ?? "",
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.currencies.numberOfResults) {
                    state.currencies.hasMore = false;
                }
                state.currencies.items = state.currencies.items.concat(r);


                //console.log(state.items)

            } else {
                state.currencies.hasMore = false;
            }
            state.currencies.isFetching = false

        })
        builder.addCase(getCurrencies.rejected, (state, action) => {
            state.currencies.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getCurrencies.pending, (state, action) => {
            state.currencies.isFetching = true;
            //state.message = undefined;
        })


        builder.addCase(getClinetUsers.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.usersList.map(val => {
                    return {
                        internalId: val.profile?.internalid,
                        name: (val.profile?.firstname?.value ?? "") + " " + (val.profile?.lastname?.value ?? ""),
                        firstname: (val.profile?.firstname?.value ?? ""),
                        lastname: (val.profile?.lastname?.value ?? ""),
                        username: val.username?.value ?? "",
                        isActive: val.isactive,
                        isConfirmed: val.isconfirmed,
                        createdDate: formatDate(timestampToDate(val.profile?.createddate?.seconds, val.profile?.createddate?.nanos)),
                        addedBy: (val.profile?.addedby?.firstname?.value ?? "") + " " + (val.profile?.addedby?.lastname?.value ?? ""),
                        addedById: val.profile?.addedby?.id?.value,
                        role: val.profile?.role?.name?.value,
                        roleId: val.profile?.role?.id,
                        usertypeId: val.profile?.usertype?.id,
                        usertype: val.profile?.usertype?.name?.value,
                        lastlogin: formatDate(timestampToDate(val.lastlogin?.seconds, val.lastlogin?.nanos)),
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.owners.numberOfResults) {
                    state.owners.hasMore = false;
                }
                state.owners.items = state.owners.items.concat(r);
                //console.log(state.items)

            } else {
                state.owners.hasMore = false;
            }
            state.owners.isFetching = false

        })
        builder.addCase(getClinetUsers.rejected, (state, action) => {
            state.owners.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }

        })
        builder.addCase(getClinetUsers.pending, (state, action) => {
            state.owners.isFetching = true;
            // state.message = undefined;
        })



        builder.addCase(getLimitations.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.limitationsList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        isActive: val.isactive?.value ?? undefined,
                        type: val.type?.value ?? undefined,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.limitations.numberOfResults) {
                    state.limitations.hasMore = false;
                }
                state.limitations.items = state.limitations.items.concat(r);
            } else {
                state.limitations.hasMore = false;
            }
            state.limitations.isFetching = false

        })
        builder.addCase(getLimitations.rejected, (state, action) => {
            state.limitations.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getLimitations.pending, (state, action) => {
            state.limitations.isFetching = true;
            //state.message = undefined;
        })
    }
})

export const { reset, dismissMessage, setStage1, resetAccountTypes, resetCurrencies, resetLimitations, resetUsers, setIsFilteredAccountTypesSet, setIsFilteredCurrenciesSet, setIsFilteredLimitationsSet, setIsFilteredOwnersSet, setSelectedAccountTypeItems, setSelectedCurrencyItems, setSelectedLimitationItems, setSelectedUserItems } = accountsFormSlice.actions

export default accountsFormSlice.reducer


