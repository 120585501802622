import { ComboBox, DefaultButton, DialogFooter, IComboBoxOption, Icon, IconButton, IStackTokens, ISuggestionItemProps, ISuggestionModel, ISuggestionsProps, ITag, Label, MessageBar, MessageBarType, Modal, Pivot, PivotItem, PrimaryButton, Separator, Spinner, SpinnerSize, Stack, StackItem, TagItemSuggestion, TagPicker, Text, TextField, Toggle, TooltipHost, values } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldArray, FieldProps, Form, Formik, getIn } from "formik";
import { FormEventHandler, InputHTMLAttributes, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks";
import * as Yup from 'yup';
import { DynamicInputField, InputField } from "../../controls/Controls";
import { inputs } from "../../../../app/Validation";
import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../../app/Api";
import { Message } from "../../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../../FormProps";
import { Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog";
import { formatDate, timestampToDate } from "../../../../app/Helpers";
import { AuthenticateReply } from "../../../../repository/UserManagement/authentication_pb";
import { AddUserRequest, AddUserResponse, UpdateUsernameResponse, GetUserRequest, UpdateUsernameRequest, UpdateUserRequest, UpdateUserResponse } from "../../../../repository/UserManagement/Managers/user_pb";
import { Address, ExtraField } from "../../../../repository/UserManagement/common_pb";
import { TableState } from "../../../common/Table/TableSate";
import { GetUserTypesRequest } from "../../../../repository/UserManagement/Managers/usertype_pb";
import { GetRolesRequest } from "../../../../repository/UserManagement/Managers/role_pb";
import { setIsFilteredUserTypesSet } from "../../../pages/Management/Users/UsersPageSlice";
import { DynamicField } from "../../../common/DynamicFields/DynamicFields";
import { GetOrganizationRequest } from "../../../../repository/UserManagement/organization_pb";
import { countriesAR, countriesEn } from "../../../../app/Content";
import { AddAuthorizationRequest, KeyValuePair, AddAuthorizationResponse, GetAuthorizationRequest, GetAuthorizationResponse } from "../../../../repository/Accountant/authorization_pb";
import { add, dismissMessage, get, reset, setStage1 } from "./AuthorizationFormSlice";

let req: AddAuthorizationRequest;
let getReq: GetAuthorizationRequest;

let promise: any;
let getPromise: any;
let org: number = -1;
let current: AuthenticateReply.AsObject;


export const AuthorizationForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();
    const [currentAction, setCurrenctAction] = useState(0)
    const [counter, setCounter] = useState(-1)
    const [sendRequest, setSendRequest] = useState(true)
    const timerRef = useRef<any>(null);

    const state: { isLoading: boolean, message: ApiMessage | undefined, stage1: any } = useAppSelector((state) => {

        return {
            isLoading: state.authorizationForm.isLoading, message: state.authorizationForm.message, stage1: state.authorizationForm.stage1,

        }
    })

    useEffect(() => {
        var l = searchParams.get('org');
        req = new AddAuthorizationRequest();
        getReq = new GetAuthorizationRequest();
        if (props?.renderObject && props?.renderObject?.account) {
            req.setAccount(props?.renderObject?.account?.internalId)
            dispatch(setStage1(props?.renderObject))
        } else {
            props.onCancel()
        }
        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        }
        return () => { //clean up
            promise?.abort();
            getPromise?.abort();
            dispatch(reset());
            if (timerRef.current != undefined) {
                clearTimeout(timerRef.current)
            }
        }
    }, [])
    useEffect(() => {
        if (counter == -1) {
            if (timerRef.current != undefined) {
                clearTimeout(timerRef.current)
            }
            return;
        }

        if (counter > 0) {
            timerRef.current = setTimeout(() => setCounter(counter - 1), 1000)
        }

        if (currentAction == 3 && counter > 0 && sendRequest) {
            setSendRequest(false)
            getPromise = dispatch(get({ body: getReq, headers: getHeaders() }))
            getPromise.unwrap()
                .then((res: GetAuthorizationResponse.AsObject) => {
                    if (res) {
                        setSendRequest(false)
                        if (props.onSuccess) {
                            props?.onSuccess({
                                authCode: res?.success?.token?.value,
                            });
                        }
                        //setCounter(60)
                        //setCurrenctAction(0)

                    }
                })
                .catch((error: ApiMessage) => {
                    if (error.data == 73)
                        setSendRequest(true)
                    else
                        setSendRequest(false)

                })

        }
    }, [counter]);

    useEffect(() => {
        dispatch(dismissMessage())
        if (currentAction != 3) {
            setCounter(-1)
            getPromise?.abort();
        }else{
            setSendRequest(true)
            setCounter(60)
        }
    }, [currentAction]);

    return (
        <>

            <Formik

                initialValues={{

                    authCode: '',

                }}

                validationSchema={currentAction == 1 ? Yup.object({
                    authCode: inputs.authCode,


                }) : undefined}
                onSubmit={(values, actions) => {
                    if (currentAction == 1) {
                        if (props?.onSuccess) {
                            props?.onSuccess({
                                authCode: values?.authCode,
                            });
                        }
                    } else {
                        req.setOperation(Number(props?.renderObject?.operation))

                        const set: KeyValuePair[] = [];
                        props?.renderObject?.parameters?.forEach((e: any) => {
                            const trans = new KeyValuePair();
                            trans.setKey(e.key)
                            trans.setValue(e.value)
                            trans.setViewvalue(e.viewValue)
                            set.push(trans)
                        });
                        req.setParametersList(set);
                        promise = dispatch(add({ body: req, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: AddAuthorizationResponse.AsObject) => {
                                if (res) {
                                    if (res.success?.id) {
                                        getReq.setId(res.success?.id)
                                        setCurrenctAction(3)
                                    }


                                }
                                actions.setSubmitting(false)
                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    }

                }}

            >
                {formkikProps => (
                    <Form autoComplete="off">

                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? (state.message.data != 401) ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            /> :
                                <SessionExpiredDialog />
                                : null
                            }
                            {currentAction == 0 && state.stage1?.account? <Text>{state.stage1?.account?.name ? t("authorizationCodeDesc", { account: state.stage1?.account?.name + "(#" + state.stage1?.account?.id + ")" }) : t("authorizationCodeDesc", { account: state.stage1?.account?.id ?? t("account") })}</Text> : undefined}

                            {currentAction == 0 && state.stage1?.account? <Stack tokens={{ childrenGap: 5 }} >
                                <DefaultButton iconProps={{ iconName: "NumberField" }}
                                    text={t("authCodeMethod")} onClick={() => {
                                        setCurrenctAction(1)
                                    }} />

                                <DefaultButton iconProps={{ iconName: "Send" }} text={t("authRequestMethod")} onClick={() => {
                                    setCurrenctAction(2)

                                }} />
                            </Stack> : undefined}

                            {currentAction == 1 && state.stage1?.account ? <Stack>
                                <Text><b>{state.stage1?.account?.name ?
                                    state.stage1?.account?.name + "(#" + state.stage1?.account?.id + ")" : state.stage1?.account?.id + ""}</b></Text>
                                <Text>{t("authorizationCodeMethodDesc")}</Text>
                                <Stack.Item dir="ltr">
                                    <Field name="authCode" label={t("authorizationCode")} placeholder={55199544} component={InputField} autoComplete="one-time-code" maxLength={8} autoFocus />
                                </Stack.Item>
                            </Stack> : undefined}


                            {currentAction == 2 && state.stage1?.account ? <Stack>
                                <Text><b>{state.stage1?.account?.name ?
                                    state.stage1?.account?.name + "(#" + state.stage1?.account?.id + ")" : state.stage1?.account?.id + ""}</b></Text>

                                <Text>{t("authorizationRequestMethodConfirmation")}</Text>
                            </Stack> : undefined}

                            {currentAction == 3 && state.stage1?.account ? <Stack>
                                <Text><b>{state.stage1?.account?.name ?
                                    state.stage1?.account?.name + "(#" + state.stage1?.account?.id + ")" : state.stage1?.account?.id + ""}</b></Text>
                                {counter > 0 ? <Text>{t("authorizationRequestMethodDesc")}</Text> : undefined}
                                {counter > 0 ? <Spinner size={SpinnerSize.large} /> : undefined}
                                {counter > 0 ? <Text>{t("authorizationRequestHint", { seconds: counter })}</Text> : undefined}
                                {counter == 0 ? <Text>{t("authorizationRequestDecliend")}</Text> : undefined}


                            </Stack> : undefined}

                        </Stack>
                        {currentAction == 1 && state.stage1?.account ? <DialogFooter>
                            <PrimaryButton text={t("authorize")} type="submit" >
                            </PrimaryButton>
                            <DefaultButton text={t("cancel")} onClick={() => {
                                formkikProps.resetForm()
                                setCurrenctAction(0)
                            }} />
                        </DialogFooter> : undefined}

                        {currentAction == 2  && state.stage1?.account? <DialogFooter>

                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("request") : t("request")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>

                            <DefaultButton disabled={state.isLoading} text={t("cancel")} onClick={() => {
                                setCurrenctAction(0)
                            }} />
                        </DialogFooter> : undefined}

                        {currentAction == 3 && state.stage1?.account ? <DialogFooter>

                            <DefaultButton text={counter == 0 ? t("back") : t("cancel")} onClick={() => {
                                setCurrenctAction(0)
                            }} />
                        </DialogFooter> : undefined}

                    </Form>
                )
                }
            </Formik >
        </>
    );
}




