import { ComboBox, DefaultButton, DialogFooter, Dropdown, Icon, IconButton, IDropdownOption, IPersonaProps, IPickerItemProps, IStackTokens, ISuggestionItemProps, ISuggestionModel, ISuggestionsProps, ITag, Label, MessageBar, MessageBarType, Modal, NormalPeoplePicker, PeoplePickerItem, Persona, PersonaSize, PrimaryButton, Spinner, SpinnerSize, Stack, TagItemSuggestion, TagPicker, Text, TextField, Toggle, TooltipHost, ValidationState } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { FormEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks";
import { dismissMessage, reset, get, setMessage, setStage1, add, update } from "./AuthorizationFormSlice";
import * as Yup from 'yup';
import { DynamicInputField, InputField } from "../../controls/Controls";
import { inputs } from "../../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../../app/Api";
import { Message } from "../../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../../FormProps";
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog";
import { formatDate, getAccountAuthorizedPermissions, normalizeKey, timestampToDate } from "../../../../app/Helpers";
import { AuthenticateReply } from "../../../../repository/UserManagement/authentication_pb";
import { DynamicField } from "../../../common/DynamicFields/DynamicFields";
import { ExtraField } from "../../../../repository/Accountant/common_pb";
import { TableState } from "../../../common/Table/TableSate";
import { AccountStatus, AuthorizationStatus, AuthorizedOperation, LimitationType, Operations } from "../../../../app/Enums";
import { GetAccountTypesRequest } from "../../../../repository/Accountant/account_type_pb"
import { GetCurrenciesRequest } from "../../../../repository/Accountant/currency_pb"
import { GetUsersRequest } from "../../../../repository/UserManagement/Customers/user_pb"
import { GetLimitationsRequest } from "../../../../repository/Accountant/limitation_pb"
import { List } from "../../../common/List/List";
import { Popup } from "../../../common/Popup/Popup";
import { UsersPage } from "../../../pages/Customers/Users/UsersPage";
import { LimitationsPage } from "../../../pages/Accountant/Limitations/LimitationsPage";
import { AccountTypesPage } from "../../../pages/Accountant/AccountTypes/AccountTypesPage";
import { CurrenciesPage } from "../../../pages/Accountant/Currencies/CurrenciesPage";
import { AuthorizationForm } from "./AuthorizationForm";
import { GenerateTriggeredTransferTokenRequest, UpdateAuthorizationRequest, GetAuthorizationRequest, KeyValuePair, UpdateAuthorizationResponse, GenerateTriggeredTransferTokenResponse } from "../../../../repository/Accountant/authorization_pb";
import { generateTriggeredTransferToken } from "./AuthorizationFormSlice";

let req: GenerateTriggeredTransferTokenRequest;
let updateReq: UpdateAuthorizationRequest;



let promise: any;
let getFilterPromise: any;
let org: number = -1;
let current: AuthenticateReply.AsObject;

export const GenerateLinkTokenForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();
    const [result, setResult] = useState<any>(undefined)


    const state: {
        isLoading: boolean, message: ApiMessage | undefined, stage1: any,
    } = useAppSelector((state) => {

        return {
            isLoading: state.authorizationForm.isLoading, message: state.authorizationForm.message, stage1: state.authorizationForm.stage1,

        }
    })

    useEffect(() => {
        var l = searchParams.get('org');
        req = new GenerateTriggeredTransferTokenRequest();
        updateReq = new UpdateAuthorizationRequest();
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);

            }
        }

        if (props.type == FormType.EDIT) {
            if (props.fetchData) {
                let r = new GetAuthorizationRequest()
                r.setId(props?.renderObject?.id);
                r.setIsview(true)
                promise?.abort()
                promise = dispatch(get({ body: r, headers: getHeaders() }))
            } else {
                dispatch(setStage1(props?.renderObject))
            }
        } else {
            req.setAccount(props.renderObject?.internalId)
        }
        const boolv = new BoolValue();
        boolv.setValue(true);
        const type = new Int32Value();
        type.setValue(LimitationType.ACCOUNT_LIMITATION);




        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        }
        return () => { //clean up
            promise?.abort();
            dispatch(reset());
        }
    }, [])




    return (
        <>


            <Formik
                enableReinitialize={true}
                initialValues={{
                    linkedToAccount: undefined,
                    intermediateAccount: undefined,
                    extraFields: (state.stage1?.extraFields) ? state.stage1?.extraFields as DynamicField[] : [] as DynamicField[],

                }}

                validationSchema={props.type == FormType.ADD ? Yup.object({
                    linkedToAccount: inputs.accountNumber,
                    intermediateAccount: inputs.notRequiredAccountNumber,

                    extraFields: inputs.extraFields,

                }) : Yup.object({

                    extraFields: inputs.extraFields,

                })}

                onSubmit={(values, actions) => {
                    if (props.type == FormType.ADD) {

                        let params = [] as KeyValuePair[]
                        let kv = new KeyValuePair()
                        kv.setKey("TriggerAccount")
                        kv.setValue(values.linkedToAccount + "")
                        kv.setViewvalue(t("accountNum", { num: values.linkedToAccount }))
                        params.push(kv)

                        if (values.intermediateAccount && !isNaN(values.intermediateAccount)) {
                            let kv2 = new KeyValuePair()
                            kv2.setKey("Intermediate")
                            kv2.setValue(values.intermediateAccount + "")
                            kv2.setViewvalue(t("accountNum", { num: values.intermediateAccount }))
                            params.push(kv2)
                        }
                        req.setParametersList(params)


                        const extraField: ExtraField[] = [];
                        const count: Number = 1;
                        values.extraFields.forEach((e: DynamicField) => {
                            const r = new ExtraField();
                            r.setKey(e.key);
                            r.setLabel(e.label?.trim() ?? "")
                            r.setType(1)
                            r.setValue(e.value.trim())
                            extraField.push(r)
                        })
                        req.setExtrafieldsList(extraField)
                        promise = dispatch(generateTriggeredTransferToken({ body: req, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: GenerateTriggeredTransferTokenResponse.AsObject) => {
                                if (res) {
                                    dispatch(setMessage({ body: t("linkCodeHint"), type: 1, title: res.success?.message?.title?.value ?? "", data: undefined }))
                                    setResult(res.success?.code)
                                    if (props?.onSuccess) {

                                        props?.onSuccess({
                                            id: res.success?.id,
                                            status: AuthorizationStatus.NEW,
                                            createdDate: formatDate(timestampToDate(res.success?.createddate?.seconds, res.success?.createddate?.nanos)),
                                            addedBy: (current.profile?.firstname?.value ?? "") + " " + (current.profile?.lastname?.value ?? ""),
                                            addedById: current.profile?.id?.value,

                                        });
                                    }
                                }
                                actions.setSubmitting(false)


                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    } else {
                        updateReq.setId(props.renderObject?.id);
                        const extraField: ExtraField[] = [];
                        const count: Number = 1;
                        values.extraFields.forEach((e: DynamicField) => {
                            const r = new ExtraField();
                            r.setKey(e.key);
                            r.setLabel(e.label?.trim() ?? "")
                            r.setType(1)
                            r.setValue(e.value.trim())
                            extraField.push(r)
                        })
                        updateReq.setExtrafieldsList(extraField)
                        promise = dispatch(update({ body: updateReq, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: UpdateAuthorizationResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            ...props.renderObject,
                                            extraFields: values.extraFields
                                        });
                                    }
                                }
                                actions.setSubmitting(false)
                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    }
                }}

            >
                {formkikProps => (
                    <Form onSubmit={(e) => {
                        e.preventDefault()


                        formkikProps.handleSubmit();




                    }} >

                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? (state.message.data != 401) ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            /> :
                                <SessionExpiredDialog />
                                : null
                            }

                            {result != undefined ?
                                <Stack >
                                    <Label >{t("linkKey")}</Label>
                                    <Stack horizontal>
                                        <Stack.Item grow>
                                            <TextField autoFocus onFocus={(e) => { (e.target as HTMLInputElement).select() }}  readOnly defaultValue={result} />
                                        </Stack.Item>
                                        <IconButton onClick={() => { navigator.clipboard.writeText(result) }} label={t("linkKey")}
                                            iconProps={{ iconName: "Copy" }}  ></IconButton>
                                    </Stack>
                                </Stack>
                                : undefined}
                            {props.type == FormType.ADD && result == undefined ?

                                <Field name="linkedToAccount" label={
                                    <div>
                                        {t("linkedToAccount") + ' '}
                                        <TooltipHost content={t("linkedToAccountHint")}>
                                            <Icon iconName="Info" aria-label={t("linkedToAccount")} />
                                        </TooltipHost>
                                    </div>
                                } placeholder={"1122121"} component={InputField} disabled={state.isLoading} maxLength={20} />


                                : undefined}
                            {props.type == FormType.ADD && result == undefined ?
                                <Field name="intermediateAccount" label={
                                    <div>
                                        {t("intermediateAccount") + ' '}
                                        <TooltipHost content={t("intermediateAccountHint")}>
                                            <Icon iconName="Info" aria-label={t("intermediateAccount")} />
                                        </TooltipHost>
                                    </div>
                                } placeholder={"323232"} component={InputField} disabled={state.isLoading} maxLength={20} />

                                : undefined}


                            {result == undefined ?
                                <Label disabled={state.isLoading}>{
                                    <div>
                                        {t("extraFields") + ' '}
                                        <TooltipHost content={t("extraInformationDescription")}>
                                            <Icon iconName="Info" aria-label={t("extraFields")} />
                                        </TooltipHost>
                                    </div>
                                }</Label> : undefined}
                            {result == undefined ? <FieldArray
                                name="extraFields"
                                validateOnChange={false}

                            >
                                {arrayHelpers => {
                                    let r = [] as React.ReactNode[];
                                    if (formkikProps.values.extraFields && formkikProps.values.extraFields.length > 0) {
                                        r = formkikProps.values.extraFields.map((o, index) => {
                                            if (o.type == 1) {
                                                return (<Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: props.childrenGap }} key={index}>
                                                    {o.label != undefined ? <Stack.Item grow> <DynamicInputField name={`extraFields[${index}].label`} placeholder={t("extraFieldLabel")} disabled={state.isLoading}
                                                        maxLength={50} autoFocus={(o as any)?.autoFocus} /> </Stack.Item> : null}
                                                    <Stack.Item grow>  <DynamicInputField name={`extraFields[${index}].value`} placeholder={t("extraFieldValue")} disabled={state.isLoading}
                                                        maxLength={50}
                                                    /></Stack.Item>

                                                    <IconButton disabled={state.isLoading}
                                                        iconProps={{ iconName: "remove" }} onClick={() => {
                                                            arrayHelpers.remove(index)
                                                        }} />
                                                </Stack>)
                                            }
                                        }
                                        )
                                    }
                                    if (formkikProps.values.extraFields.length <= 4) {
                                        r.push(< IconButton disabled={state.isLoading}
                                            key={"addButton"} iconProps={{ iconName: "add" }} onClick={() => {
                                                arrayHelpers.push({ key: (formkikProps.values.extraFields.length + 1) + "", label: "", type: 1, value: "", autoFocus: true })
                                            }} />)
                                    }
                                    return r;
                                }
                                }

                            </FieldArray> : undefined}


                        </Stack>
                        {result == undefined ? <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("generate") : t("edit")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={props.type == FormType.ADD ? t("discard") : t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter> : undefined}



                    </Form>
                )}
            </Formik >
        </>
    );

}

