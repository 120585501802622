/**
 * @fileoverview gRPC-Web generated client stub for account_type_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as account_type_pb from './account_type_pb';


export class AccountTypeEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAdd = new grpcWeb.MethodDescriptor(
    '/account_type_endpoint.AccountTypeEndpoint/Add',
    grpcWeb.MethodType.UNARY,
    account_type_pb.AddAccountTypeRequest,
    account_type_pb.AddAccountTypeResponse,
    (request: account_type_pb.AddAccountTypeRequest) => {
      return request.serializeBinary();
    },
    account_type_pb.AddAccountTypeResponse.deserializeBinary
  );

  add(
    request: account_type_pb.AddAccountTypeRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_type_pb.AddAccountTypeResponse>;

  add(
    request: account_type_pb.AddAccountTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_type_pb.AddAccountTypeResponse) => void): grpcWeb.ClientReadableStream<account_type_pb.AddAccountTypeResponse>;

  add(
    request: account_type_pb.AddAccountTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_type_pb.AddAccountTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_type_endpoint.AccountTypeEndpoint/Add',
        request,
        metadata || {},
        this.methodDescriptorAdd,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_type_endpoint.AccountTypeEndpoint/Add',
    request,
    metadata || {},
    this.methodDescriptorAdd);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/account_type_endpoint.AccountTypeEndpoint/Update',
    grpcWeb.MethodType.UNARY,
    account_type_pb.UpdateAccountTypeRequest,
    account_type_pb.UpdateAccountTypeResponse,
    (request: account_type_pb.UpdateAccountTypeRequest) => {
      return request.serializeBinary();
    },
    account_type_pb.UpdateAccountTypeResponse.deserializeBinary
  );

  update(
    request: account_type_pb.UpdateAccountTypeRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_type_pb.UpdateAccountTypeResponse>;

  update(
    request: account_type_pb.UpdateAccountTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_type_pb.UpdateAccountTypeResponse) => void): grpcWeb.ClientReadableStream<account_type_pb.UpdateAccountTypeResponse>;

  update(
    request: account_type_pb.UpdateAccountTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_type_pb.UpdateAccountTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_type_endpoint.AccountTypeEndpoint/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_type_endpoint.AccountTypeEndpoint/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/account_type_endpoint.AccountTypeEndpoint/Delete',
    grpcWeb.MethodType.UNARY,
    account_type_pb.DeleteAccountTypeRequest,
    account_type_pb.DeleteAccountTypeResponse,
    (request: account_type_pb.DeleteAccountTypeRequest) => {
      return request.serializeBinary();
    },
    account_type_pb.DeleteAccountTypeResponse.deserializeBinary
  );

  delete(
    request: account_type_pb.DeleteAccountTypeRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_type_pb.DeleteAccountTypeResponse>;

  delete(
    request: account_type_pb.DeleteAccountTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_type_pb.DeleteAccountTypeResponse) => void): grpcWeb.ClientReadableStream<account_type_pb.DeleteAccountTypeResponse>;

  delete(
    request: account_type_pb.DeleteAccountTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_type_pb.DeleteAccountTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_type_endpoint.AccountTypeEndpoint/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_type_endpoint.AccountTypeEndpoint/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorGetAccountType = new grpcWeb.MethodDescriptor(
    '/account_type_endpoint.AccountTypeEndpoint/GetAccountType',
    grpcWeb.MethodType.UNARY,
    account_type_pb.GetAccountTypeRequest,
    account_type_pb.GetAccountTypeResponse,
    (request: account_type_pb.GetAccountTypeRequest) => {
      return request.serializeBinary();
    },
    account_type_pb.GetAccountTypeResponse.deserializeBinary
  );

  getAccountType(
    request: account_type_pb.GetAccountTypeRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_type_pb.GetAccountTypeResponse>;

  getAccountType(
    request: account_type_pb.GetAccountTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_type_pb.GetAccountTypeResponse) => void): grpcWeb.ClientReadableStream<account_type_pb.GetAccountTypeResponse>;

  getAccountType(
    request: account_type_pb.GetAccountTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_type_pb.GetAccountTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_type_endpoint.AccountTypeEndpoint/GetAccountType',
        request,
        metadata || {},
        this.methodDescriptorGetAccountType,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_type_endpoint.AccountTypeEndpoint/GetAccountType',
    request,
    metadata || {},
    this.methodDescriptorGetAccountType);
  }

  methodDescriptorGetAccountTypes = new grpcWeb.MethodDescriptor(
    '/account_type_endpoint.AccountTypeEndpoint/GetAccountTypes',
    grpcWeb.MethodType.UNARY,
    account_type_pb.GetAccountTypesRequest,
    account_type_pb.GetAccountTypesResponse,
    (request: account_type_pb.GetAccountTypesRequest) => {
      return request.serializeBinary();
    },
    account_type_pb.GetAccountTypesResponse.deserializeBinary
  );

  getAccountTypes(
    request: account_type_pb.GetAccountTypesRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_type_pb.GetAccountTypesResponse>;

  getAccountTypes(
    request: account_type_pb.GetAccountTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_type_pb.GetAccountTypesResponse) => void): grpcWeb.ClientReadableStream<account_type_pb.GetAccountTypesResponse>;

  getAccountTypes(
    request: account_type_pb.GetAccountTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_type_pb.GetAccountTypesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_type_endpoint.AccountTypeEndpoint/GetAccountTypes',
        request,
        metadata || {},
        this.methodDescriptorGetAccountTypes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_type_endpoint.AccountTypeEndpoint/GetAccountTypes',
    request,
    metadata || {},
    this.methodDescriptorGetAccountTypes);
  }

}

