/**
 * @fileoverview gRPC-Web generated client stub for order_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as order_pb from './order_pb';


export class OrderEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorDirectExchange = new grpcWeb.MethodDescriptor(
    '/order_endpoint.OrderEndpoint/DirectExchange',
    grpcWeb.MethodType.UNARY,
    order_pb.DirectExchangeRequest,
    order_pb.DirectExchangeResponse,
    (request: order_pb.DirectExchangeRequest) => {
      return request.serializeBinary();
    },
    order_pb.DirectExchangeResponse.deserializeBinary
  );

  directExchange(
    request: order_pb.DirectExchangeRequest,
    metadata: grpcWeb.Metadata | null): Promise<order_pb.DirectExchangeResponse>;

  directExchange(
    request: order_pb.DirectExchangeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: order_pb.DirectExchangeResponse) => void): grpcWeb.ClientReadableStream<order_pb.DirectExchangeResponse>;

  directExchange(
    request: order_pb.DirectExchangeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: order_pb.DirectExchangeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_endpoint.OrderEndpoint/DirectExchange',
        request,
        metadata || {},
        this.methodDescriptorDirectExchange,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_endpoint.OrderEndpoint/DirectExchange',
    request,
    metadata || {},
    this.methodDescriptorDirectExchange);
  }

  methodDescriptorExternalExchange = new grpcWeb.MethodDescriptor(
    '/order_endpoint.OrderEndpoint/ExternalExchange',
    grpcWeb.MethodType.UNARY,
    order_pb.ExternalExchangeRequest,
    order_pb.ExternalExchangeResponse,
    (request: order_pb.ExternalExchangeRequest) => {
      return request.serializeBinary();
    },
    order_pb.ExternalExchangeResponse.deserializeBinary
  );

  externalExchange(
    request: order_pb.ExternalExchangeRequest,
    metadata: grpcWeb.Metadata | null): Promise<order_pb.ExternalExchangeResponse>;

  externalExchange(
    request: order_pb.ExternalExchangeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: order_pb.ExternalExchangeResponse) => void): grpcWeb.ClientReadableStream<order_pb.ExternalExchangeResponse>;

  externalExchange(
    request: order_pb.ExternalExchangeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: order_pb.ExternalExchangeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_endpoint.OrderEndpoint/ExternalExchange',
        request,
        metadata || {},
        this.methodDescriptorExternalExchange,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_endpoint.OrderEndpoint/ExternalExchange',
    request,
    metadata || {},
    this.methodDescriptorExternalExchange);
  }

  methodDescriptorGetOrders = new grpcWeb.MethodDescriptor(
    '/order_endpoint.OrderEndpoint/GetOrders',
    grpcWeb.MethodType.UNARY,
    order_pb.GetOrdersRequest,
    order_pb.GetOrdersResponse,
    (request: order_pb.GetOrdersRequest) => {
      return request.serializeBinary();
    },
    order_pb.GetOrdersResponse.deserializeBinary
  );

  getOrders(
    request: order_pb.GetOrdersRequest,
    metadata: grpcWeb.Metadata | null): Promise<order_pb.GetOrdersResponse>;

  getOrders(
    request: order_pb.GetOrdersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: order_pb.GetOrdersResponse) => void): grpcWeb.ClientReadableStream<order_pb.GetOrdersResponse>;

  getOrders(
    request: order_pb.GetOrdersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: order_pb.GetOrdersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_endpoint.OrderEndpoint/GetOrders',
        request,
        metadata || {},
        this.methodDescriptorGetOrders,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_endpoint.OrderEndpoint/GetOrders',
    request,
    metadata || {},
    this.methodDescriptorGetOrders);
  }

  methodDescriptorCancel = new grpcWeb.MethodDescriptor(
    '/order_endpoint.OrderEndpoint/Cancel',
    grpcWeb.MethodType.UNARY,
    order_pb.CancelRequest,
    order_pb.CancelResponse,
    (request: order_pb.CancelRequest) => {
      return request.serializeBinary();
    },
    order_pb.CancelResponse.deserializeBinary
  );

  cancel(
    request: order_pb.CancelRequest,
    metadata: grpcWeb.Metadata | null): Promise<order_pb.CancelResponse>;

  cancel(
    request: order_pb.CancelRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: order_pb.CancelResponse) => void): grpcWeb.ClientReadableStream<order_pb.CancelResponse>;

  cancel(
    request: order_pb.CancelRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: order_pb.CancelResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_endpoint.OrderEndpoint/Cancel',
        request,
        metadata || {},
        this.methodDescriptorCancel,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_endpoint.OrderEndpoint/Cancel',
    request,
    metadata || {},
    this.methodDescriptorCancel);
  }

  methodDescriptorGetOrder = new grpcWeb.MethodDescriptor(
    '/order_endpoint.OrderEndpoint/GetOrder',
    grpcWeb.MethodType.UNARY,
    order_pb.GetOrderRequest,
    order_pb.GetOrderResponse,
    (request: order_pb.GetOrderRequest) => {
      return request.serializeBinary();
    },
    order_pb.GetOrderResponse.deserializeBinary
  );

  getOrder(
    request: order_pb.GetOrderRequest,
    metadata: grpcWeb.Metadata | null): Promise<order_pb.GetOrderResponse>;

  getOrder(
    request: order_pb.GetOrderRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: order_pb.GetOrderResponse) => void): grpcWeb.ClientReadableStream<order_pb.GetOrderResponse>;

  getOrder(
    request: order_pb.GetOrderRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: order_pb.GetOrderResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_endpoint.OrderEndpoint/GetOrder',
        request,
        metadata || {},
        this.methodDescriptorGetOrder,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_endpoint.OrderEndpoint/GetOrder',
    request,
    metadata || {},
    this.methodDescriptorGetOrder);
  }

  methodDescriptorGetOrderTransactions = new grpcWeb.MethodDescriptor(
    '/order_endpoint.OrderEndpoint/GetOrderTransactions',
    grpcWeb.MethodType.UNARY,
    order_pb.GetOrderTransactionsRequest,
    order_pb.GetOrderTransactionsResponse,
    (request: order_pb.GetOrderTransactionsRequest) => {
      return request.serializeBinary();
    },
    order_pb.GetOrderTransactionsResponse.deserializeBinary
  );

  getOrderTransactions(
    request: order_pb.GetOrderTransactionsRequest,
    metadata: grpcWeb.Metadata | null): Promise<order_pb.GetOrderTransactionsResponse>;

  getOrderTransactions(
    request: order_pb.GetOrderTransactionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: order_pb.GetOrderTransactionsResponse) => void): grpcWeb.ClientReadableStream<order_pb.GetOrderTransactionsResponse>;

  getOrderTransactions(
    request: order_pb.GetOrderTransactionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: order_pb.GetOrderTransactionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_endpoint.OrderEndpoint/GetOrderTransactions',
        request,
        metadata || {},
        this.methodDescriptorGetOrderTransactions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_endpoint.OrderEndpoint/GetOrderTransactions',
    request,
    metadata || {},
    this.methodDescriptorGetOrderTransactions);
  }

  methodDescriptorGetOrderTransaction = new grpcWeb.MethodDescriptor(
    '/order_endpoint.OrderEndpoint/GetOrderTransaction',
    grpcWeb.MethodType.UNARY,
    order_pb.GetOrderTransactionRequest,
    order_pb.GetOrderTransactionResponse,
    (request: order_pb.GetOrderTransactionRequest) => {
      return request.serializeBinary();
    },
    order_pb.GetOrderTransactionResponse.deserializeBinary
  );

  getOrderTransaction(
    request: order_pb.GetOrderTransactionRequest,
    metadata: grpcWeb.Metadata | null): Promise<order_pb.GetOrderTransactionResponse>;

  getOrderTransaction(
    request: order_pb.GetOrderTransactionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: order_pb.GetOrderTransactionResponse) => void): grpcWeb.ClientReadableStream<order_pb.GetOrderTransactionResponse>;

  getOrderTransaction(
    request: order_pb.GetOrderTransactionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: order_pb.GetOrderTransactionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_endpoint.OrderEndpoint/GetOrderTransaction',
        request,
        metadata || {},
        this.methodDescriptorGetOrderTransaction,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_endpoint.OrderEndpoint/GetOrderTransaction',
    request,
    metadata || {},
    this.methodDescriptorGetOrderTransaction);
  }

  methodDescriptorExportOrder = new grpcWeb.MethodDescriptor(
    '/order_endpoint.OrderEndpoint/ExportOrder',
    grpcWeb.MethodType.UNARY,
    order_pb.ExportOrderRequest,
    order_pb.ExportOrderResponse,
    (request: order_pb.ExportOrderRequest) => {
      return request.serializeBinary();
    },
    order_pb.ExportOrderResponse.deserializeBinary
  );

  exportOrder(
    request: order_pb.ExportOrderRequest,
    metadata: grpcWeb.Metadata | null): Promise<order_pb.ExportOrderResponse>;

  exportOrder(
    request: order_pb.ExportOrderRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: order_pb.ExportOrderResponse) => void): grpcWeb.ClientReadableStream<order_pb.ExportOrderResponse>;

  exportOrder(
    request: order_pb.ExportOrderRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: order_pb.ExportOrderResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_endpoint.OrderEndpoint/ExportOrder',
        request,
        metadata || {},
        this.methodDescriptorExportOrder,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_endpoint.OrderEndpoint/ExportOrder',
    request,
    metadata || {},
    this.methodDescriptorExportOrder);
  }

}

