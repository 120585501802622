import { ComboBox, DatePicker, DayOfWeek, DefaultButton, defaultDatePickerStrings, DialogFooter, Icon, IconButton, IStackTokens, Label, MessageBar, MessageBarType, Modal, PrimaryButton, Spinner, SpinnerSize, Stack, StackItem, Text, TextField, Toggle, TooltipHost } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { FormEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks";
import { add, dismissMessage, generateSignatures, get, reset, setStage1, update } from "./AuthorizationFormSlice";
import * as Yup from 'yup';
import { DynamicInputField, InputField, NumberInputField } from "../../controls/Controls";
import { inputs } from "../../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../../app/Api";
import { Message } from "../../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../../FormProps";
import { Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog";
import { formatDate, timestampToDate } from "../../../../app/Helpers";
import { AuthenticateReply } from "../../../../repository/UserManagement/authentication_pb";
import { DynamicField } from "../../../common/DynamicFields/DynamicFields";
import { ExtraField } from "../../../../repository/Accountant/common_pb";
import { GenerateSignaturesRequest, GenerateSignaturesResponse, GetAuthorizationRequest, KeyValuePair, UpdateAuthorizationRequest, UpdateAuthorizationResponse } from "../../../../repository/Accountant/authorization_pb";
import moment from "moment";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";


let req: GenerateSignaturesRequest;
let updateReq: UpdateAuthorizationRequest;
let promise: any;
let getFilterPromise: any;
let org: number = -1;
let current: AuthenticateReply.AsObject;

export const GenerateSignaturesForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();


    const state: { isLoading: boolean, message: ApiMessage | undefined, stage1: any } = useAppSelector((state) => {

        return { isLoading: state.authorizationForm.isLoading, message: state.authorizationForm.message, stage1: state.authorizationForm.stage1 }
    })

    useEffect(() => {
        var l = searchParams.get('org');
        req = new GenerateSignaturesRequest();
        updateReq = new UpdateAuthorizationRequest()
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
            }
        }

        if (props.type == FormType.EDIT) {
            if (props.fetchData) {
                let r = new GetAuthorizationRequest()
                r.setId(props?.renderObject?.id);
                r.setIsview(true)
                promise?.abort()
                promise = dispatch(get({ body: r, headers: getHeaders() }))
            } else {
                dispatch(setStage1(props?.renderObject))
            }
        } else {
            req.setAccount(props.renderObject?.internalId)
        }

        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        }
        return () => { //clean up
            promise?.abort();
            dispatch(reset());
        }
    }, [])



    const [date, setDate] = useState(moment(new Date(), 'YYYY-MM-DD').toDate())

    return (
        <>

            <Formik
                enableReinitialize={true}
                initialValues={{

                    count: '1',
                    expiration: date,
                    parms: [] as DynamicField[],
                    extraFields: (state.stage1?.extraFields) ? state.stage1?.extraFields as DynamicField[] : [] as DynamicField[],

                }}

                validationSchema={props.type == FormType.ADD ? Yup.object({
                    extraFields: inputs.extraFields,
                    parms: inputs.extraFields,

                }) : Yup.object({
                    extraFields: inputs.extraFields,

                })}


                onSubmit={(values, actions) => {
                    if (props.type == FormType.ADD) {
                        let params = [] as KeyValuePair[]

                        values.parms.forEach((e: DynamicField) => {
                            if (params.filter(x => x.getKey() == e.label?.trim()).length == 0) {
                                const r = new KeyValuePair();
                                r.setKey(e.label?.trim() ?? "");
                                r.setValue(e.value.trim())
                                r.setViewvalue(e.value.trim())
                                params.push(r)
                            }
                        })
                        req.setParametersList(params)

                        var r = new Timestamp();
                        r.fromDate(moment(values.expiration, 'YYYY-MM-DD').toDate())
                        req.setExpirationdate(r)

                        req.setCount(Number(values.count))


                        const extraField: ExtraField[] = [];
                        const count: Number = 1;
                        values.extraFields.forEach((e: DynamicField) => {
                            const r = new ExtraField();
                            r.setKey(e.key);
                            r.setLabel(e.label?.trim() ?? "")
                            r.setType(1)
                            r.setValue(e.value.trim())
                            extraField.push(r)
                        })
                        req.setExtrafieldsList(extraField)
                        promise = dispatch(generateSignatures({ body: req, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: GenerateSignaturesResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {

                                        props?.onSuccess({

                                        });
                                    }
                                }
                                actions.setSubmitting(false)


                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })

                    } else {

                        const extraField: ExtraField[] = [];
                        const count: Number = 1;
                        values.extraFields.forEach((e: DynamicField) => {
                            const r = new ExtraField();
                            r.setKey(e.key);
                            r.setLabel(e.label?.trim() ?? "")
                            r.setType(1)
                            r.setValue(e.value.trim())
                            extraField.push(r)
                        })
                        updateReq.setExtrafieldsList(extraField)
                        updateReq.setId(props.renderObject?.id)
                        promise = dispatch(update({ body: updateReq, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: UpdateAuthorizationResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            ...props.renderObject,
                                            extraFields: values.extraFields,
                                        });
                                    }
                                }
                                actions.setSubmitting(false)

                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    }
                }}

            >
                {formkikProps => (
                    <Form onSubmit={(e) => {
                        e.preventDefault()
                        formkikProps.handleSubmit();
                    }} >
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? (state.message.data != 401) ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            /> :
                                <SessionExpiredDialog />
                                : null
                            }
                            {props.type == FormType.ADD ? <Stack>
                                <Label required disabled={state.isLoading}>{t("count")} </Label>
                                <Field name="count" placeholder={100}
                                    component={NumberInputField} disabled={state.isLoading} max={1000} min={1} step={1} required />
                            </Stack> : undefined}

                            {props.type == FormType.ADD ?
                                <DatePicker
                                    isRequired
                                    firstDayOfWeek={DayOfWeek.Sunday}
                                    label={t("expiration")}
                                    placeholder={t("expiration")}
                                    disabled={state.isLoading}
                                    value={formkikProps.values.expiration}
                                    ariaLabel={t("expiration")}
                                    formatDate={(e) => {
                                        return formatDate(e, 'YYYY-MM-DD')
                                    }}
                                    parseDateFromString={(date) => {
                                        return moment(date, 'YYYY-MM-DD').toDate();
                                    }}


                                    // DatePicker uses English strings by default. For localized apps, you must override this prop.
                                    strings={defaultDatePickerStrings}
                                    onSelectDate={(date: Date | null | undefined) => {
                                        formkikProps.setFieldValue("expiration", moment(date, 'YYYY-MM-DD').toDate())
                                    }}

                                />
                                : undefined}


                            {props.type == FormType.ADD ? <Label disabled={state.isLoading}>{
                                <div>
                                    {t("metadata") + ' '}
                                    <TooltipHost content={t("sigMetadataDesc")}>
                                        <Icon iconName="Info" aria-label={t("metadata")} />
                                    </TooltipHost>
                                </div>
                            }</Label> : undefined}
                            {props.type == FormType.ADD ? <FieldArray
                                name="parms"
                                validateOnChange={false}

                            >
                                {arrayHelpers => {
                                    let r = [] as React.ReactNode[];
                                    if (formkikProps.values.parms && formkikProps.values.parms.length > 0) {
                                        r = formkikProps.values.parms.map((o, index) => {
                                            if (o.type == 1) {
                                                return (<Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: props.childrenGap }} key={index}>
                                                    {o.label != undefined ? <Stack.Item grow> <DynamicInputField name={`parms[${index}].label`} placeholder={t("extraFieldLabel")} disabled={state.isLoading}
                                                        maxLength={50} autoFocus={(o as any)?.autoFocus} /> </Stack.Item> : null}
                                                    <Stack.Item grow>  <DynamicInputField name={`parms[${index}].value`} placeholder={t("extraFieldValue")} disabled={state.isLoading}
                                                        maxLength={50}
                                                    /></Stack.Item>

                                                    <IconButton disabled={state.isLoading}
                                                        iconProps={{ iconName: "remove" }} onClick={() => {
                                                            arrayHelpers.remove(index)
                                                        }} />
                                                </Stack>)
                                            }
                                        }
                                        )
                                    }
                                    if (formkikProps.values.parms.length <= 10) {
                                        r.push(< IconButton disabled={state.isLoading}
                                            key={"addButton"} iconProps={{ iconName: "add" }} onClick={() => {
                                                arrayHelpers.push({ key: (formkikProps.values.parms.length + 1) + "", label: "", type: 1, value: "", autoFocus: true })
                                            }} />)
                                    }
                                    return r;
                                }
                                }

                            </FieldArray> : undefined}



                            <Label disabled={state.isLoading}>{
                                <div>
                                    {t("extraFields") + ' '}
                                    <TooltipHost content={t("extraInformationDescription")}>
                                        <Icon iconName="Info" aria-label={t("extraFields")} />
                                    </TooltipHost>
                                </div>
                            }</Label>
                            <FieldArray
                                name="extraFields"
                                validateOnChange={false}

                            >
                                {arrayHelpers => {
                                    let r = [] as React.ReactNode[];
                                    if (formkikProps.values.extraFields && formkikProps.values.extraFields.length > 0) {
                                        r = formkikProps.values.extraFields.map((o, index) => {
                                            if (o.type == 1) {
                                                return (<Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: props.childrenGap }} key={index}>
                                                    {o.label != undefined ? <Stack.Item grow> <DynamicInputField name={`extraFields[${index}].label`} placeholder={t("extraFieldLabel")} disabled={state.isLoading}
                                                        maxLength={50} autoFocus={(o as any)?.autoFocus} /> </Stack.Item> : null}
                                                    <Stack.Item grow>  <DynamicInputField name={`extraFields[${index}].value`} placeholder={t("extraFieldValue")} disabled={state.isLoading}
                                                        maxLength={50}
                                                    /></Stack.Item>

                                                    <IconButton disabled={state.isLoading}
                                                        iconProps={{ iconName: "remove" }} onClick={() => {
                                                            arrayHelpers.remove(index)
                                                        }} />
                                                </Stack>)
                                            }
                                        }
                                        )
                                    }
                                    if (formkikProps.values.extraFields.length <= 4) {
                                        r.push(< IconButton disabled={state.isLoading}
                                            key={"addButton"} iconProps={{ iconName: "add" }} onClick={() => {
                                                arrayHelpers.push({ key: (formkikProps.values.extraFields.length + 1) + "", label: "", type: 1, value: "", autoFocus: true })
                                            }} />)
                                    }
                                    return r;
                                }
                                }

                            </FieldArray>


                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("generate") : t("edit")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={props.type == FormType.ADD ? t("discard") : t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>

                    </Form>
                )}
            </Formik>
        </>
    );

}

