/**
 * @fileoverview gRPC-Web generated client stub for account_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as account_pb from './account_pb';


export class AccountEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorOpen = new grpcWeb.MethodDescriptor(
    '/account_endpoint.AccountEndpoint/Open',
    grpcWeb.MethodType.UNARY,
    account_pb.OpenAccountRequest,
    account_pb.OpenAccountResponse,
    (request: account_pb.OpenAccountRequest) => {
      return request.serializeBinary();
    },
    account_pb.OpenAccountResponse.deserializeBinary
  );

  open(
    request: account_pb.OpenAccountRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_pb.OpenAccountResponse>;

  open(
    request: account_pb.OpenAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_pb.OpenAccountResponse) => void): grpcWeb.ClientReadableStream<account_pb.OpenAccountResponse>;

  open(
    request: account_pb.OpenAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_pb.OpenAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_endpoint.AccountEndpoint/Open',
        request,
        metadata || {},
        this.methodDescriptorOpen,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_endpoint.AccountEndpoint/Open',
    request,
    metadata || {},
    this.methodDescriptorOpen);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/account_endpoint.AccountEndpoint/Update',
    grpcWeb.MethodType.UNARY,
    account_pb.UpdateAccountRequest,
    account_pb.UpdateAccountResponse,
    (request: account_pb.UpdateAccountRequest) => {
      return request.serializeBinary();
    },
    account_pb.UpdateAccountResponse.deserializeBinary
  );

  update(
    request: account_pb.UpdateAccountRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_pb.UpdateAccountResponse>;

  update(
    request: account_pb.UpdateAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_pb.UpdateAccountResponse) => void): grpcWeb.ClientReadableStream<account_pb.UpdateAccountResponse>;

  update(
    request: account_pb.UpdateAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_pb.UpdateAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_endpoint.AccountEndpoint/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_endpoint.AccountEndpoint/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorUpdateStatus = new grpcWeb.MethodDescriptor(
    '/account_endpoint.AccountEndpoint/UpdateStatus',
    grpcWeb.MethodType.UNARY,
    account_pb.UpdateAccountStatusRequest,
    account_pb.UpdateAccountStatusResponse,
    (request: account_pb.UpdateAccountStatusRequest) => {
      return request.serializeBinary();
    },
    account_pb.UpdateAccountStatusResponse.deserializeBinary
  );

  updateStatus(
    request: account_pb.UpdateAccountStatusRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_pb.UpdateAccountStatusResponse>;

  updateStatus(
    request: account_pb.UpdateAccountStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_pb.UpdateAccountStatusResponse) => void): grpcWeb.ClientReadableStream<account_pb.UpdateAccountStatusResponse>;

  updateStatus(
    request: account_pb.UpdateAccountStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_pb.UpdateAccountStatusResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_endpoint.AccountEndpoint/UpdateStatus',
        request,
        metadata || {},
        this.methodDescriptorUpdateStatus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_endpoint.AccountEndpoint/UpdateStatus',
    request,
    metadata || {},
    this.methodDescriptorUpdateStatus);
  }

  methodDescriptorClose = new grpcWeb.MethodDescriptor(
    '/account_endpoint.AccountEndpoint/Close',
    grpcWeb.MethodType.UNARY,
    account_pb.CloseAccountRequest,
    account_pb.CloseAccountResponse,
    (request: account_pb.CloseAccountRequest) => {
      return request.serializeBinary();
    },
    account_pb.CloseAccountResponse.deserializeBinary
  );

  close(
    request: account_pb.CloseAccountRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_pb.CloseAccountResponse>;

  close(
    request: account_pb.CloseAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_pb.CloseAccountResponse) => void): grpcWeb.ClientReadableStream<account_pb.CloseAccountResponse>;

  close(
    request: account_pb.CloseAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_pb.CloseAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_endpoint.AccountEndpoint/Close',
        request,
        metadata || {},
        this.methodDescriptorClose,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_endpoint.AccountEndpoint/Close',
    request,
    metadata || {},
    this.methodDescriptorClose);
  }

  methodDescriptorUpdateType = new grpcWeb.MethodDescriptor(
    '/account_endpoint.AccountEndpoint/UpdateType',
    grpcWeb.MethodType.UNARY,
    account_pb.UpdateAccountTypeRequest,
    account_pb.UpdateAccountTypeResponse,
    (request: account_pb.UpdateAccountTypeRequest) => {
      return request.serializeBinary();
    },
    account_pb.UpdateAccountTypeResponse.deserializeBinary
  );

  updateType(
    request: account_pb.UpdateAccountTypeRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_pb.UpdateAccountTypeResponse>;

  updateType(
    request: account_pb.UpdateAccountTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_pb.UpdateAccountTypeResponse) => void): grpcWeb.ClientReadableStream<account_pb.UpdateAccountTypeResponse>;

  updateType(
    request: account_pb.UpdateAccountTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_pb.UpdateAccountTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_endpoint.AccountEndpoint/UpdateType',
        request,
        metadata || {},
        this.methodDescriptorUpdateType,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_endpoint.AccountEndpoint/UpdateType',
    request,
    metadata || {},
    this.methodDescriptorUpdateType);
  }

  methodDescriptorUpdateLimitation = new grpcWeb.MethodDescriptor(
    '/account_endpoint.AccountEndpoint/UpdateLimitation',
    grpcWeb.MethodType.UNARY,
    account_pb.UpdateAccountLimitationRequest,
    account_pb.UpdateAccountLimitationResponse,
    (request: account_pb.UpdateAccountLimitationRequest) => {
      return request.serializeBinary();
    },
    account_pb.UpdateAccountLimitationResponse.deserializeBinary
  );

  updateLimitation(
    request: account_pb.UpdateAccountLimitationRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_pb.UpdateAccountLimitationResponse>;

  updateLimitation(
    request: account_pb.UpdateAccountLimitationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_pb.UpdateAccountLimitationResponse) => void): grpcWeb.ClientReadableStream<account_pb.UpdateAccountLimitationResponse>;

  updateLimitation(
    request: account_pb.UpdateAccountLimitationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_pb.UpdateAccountLimitationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_endpoint.AccountEndpoint/UpdateLimitation',
        request,
        metadata || {},
        this.methodDescriptorUpdateLimitation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_endpoint.AccountEndpoint/UpdateLimitation',
    request,
    metadata || {},
    this.methodDescriptorUpdateLimitation);
  }

  methodDescriptorGetAccount = new grpcWeb.MethodDescriptor(
    '/account_endpoint.AccountEndpoint/GetAccount',
    grpcWeb.MethodType.UNARY,
    account_pb.GetAccountRequest,
    account_pb.GetAccountResponse,
    (request: account_pb.GetAccountRequest) => {
      return request.serializeBinary();
    },
    account_pb.GetAccountResponse.deserializeBinary
  );

  getAccount(
    request: account_pb.GetAccountRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_pb.GetAccountResponse>;

  getAccount(
    request: account_pb.GetAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_pb.GetAccountResponse) => void): grpcWeb.ClientReadableStream<account_pb.GetAccountResponse>;

  getAccount(
    request: account_pb.GetAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_pb.GetAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_endpoint.AccountEndpoint/GetAccount',
        request,
        metadata || {},
        this.methodDescriptorGetAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_endpoint.AccountEndpoint/GetAccount',
    request,
    metadata || {},
    this.methodDescriptorGetAccount);
  }

  methodDescriptorGetAccounts = new grpcWeb.MethodDescriptor(
    '/account_endpoint.AccountEndpoint/GetAccounts',
    grpcWeb.MethodType.UNARY,
    account_pb.GetAccountsRequest,
    account_pb.GetAccountsResponse,
    (request: account_pb.GetAccountsRequest) => {
      return request.serializeBinary();
    },
    account_pb.GetAccountsResponse.deserializeBinary
  );

  getAccounts(
    request: account_pb.GetAccountsRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_pb.GetAccountsResponse>;

  getAccounts(
    request: account_pb.GetAccountsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_pb.GetAccountsResponse) => void): grpcWeb.ClientReadableStream<account_pb.GetAccountsResponse>;

  getAccounts(
    request: account_pb.GetAccountsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_pb.GetAccountsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_endpoint.AccountEndpoint/GetAccounts',
        request,
        metadata || {},
        this.methodDescriptorGetAccounts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_endpoint.AccountEndpoint/GetAccounts',
    request,
    metadata || {},
    this.methodDescriptorGetAccounts);
  }

  methodDescriptorTransferOwnership = new grpcWeb.MethodDescriptor(
    '/account_endpoint.AccountEndpoint/TransferOwnership',
    grpcWeb.MethodType.UNARY,
    account_pb.TransferAccountOwnershipRequest,
    account_pb.TransferAccountOwnershipResponse,
    (request: account_pb.TransferAccountOwnershipRequest) => {
      return request.serializeBinary();
    },
    account_pb.TransferAccountOwnershipResponse.deserializeBinary
  );

  transferOwnership(
    request: account_pb.TransferAccountOwnershipRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_pb.TransferAccountOwnershipResponse>;

  transferOwnership(
    request: account_pb.TransferAccountOwnershipRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_pb.TransferAccountOwnershipResponse) => void): grpcWeb.ClientReadableStream<account_pb.TransferAccountOwnershipResponse>;

  transferOwnership(
    request: account_pb.TransferAccountOwnershipRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_pb.TransferAccountOwnershipResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_endpoint.AccountEndpoint/TransferOwnership',
        request,
        metadata || {},
        this.methodDescriptorTransferOwnership,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_endpoint.AccountEndpoint/TransferOwnership',
    request,
    metadata || {},
    this.methodDescriptorTransferOwnership);
  }

  methodDescriptorUpdateAuthorizationRequirement = new grpcWeb.MethodDescriptor(
    '/account_endpoint.AccountEndpoint/UpdateAuthorizationRequirement',
    grpcWeb.MethodType.UNARY,
    account_pb.UpdateAuthorizationRequirementRequest,
    account_pb.UpdateAuthorizationRequirementResponse,
    (request: account_pb.UpdateAuthorizationRequirementRequest) => {
      return request.serializeBinary();
    },
    account_pb.UpdateAuthorizationRequirementResponse.deserializeBinary
  );

  updateAuthorizationRequirement(
    request: account_pb.UpdateAuthorizationRequirementRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_pb.UpdateAuthorizationRequirementResponse>;

  updateAuthorizationRequirement(
    request: account_pb.UpdateAuthorizationRequirementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_pb.UpdateAuthorizationRequirementResponse) => void): grpcWeb.ClientReadableStream<account_pb.UpdateAuthorizationRequirementResponse>;

  updateAuthorizationRequirement(
    request: account_pb.UpdateAuthorizationRequirementRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_pb.UpdateAuthorizationRequirementResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_endpoint.AccountEndpoint/UpdateAuthorizationRequirement',
        request,
        metadata || {},
        this.methodDescriptorUpdateAuthorizationRequirement,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_endpoint.AccountEndpoint/UpdateAuthorizationRequirement',
    request,
    metadata || {},
    this.methodDescriptorUpdateAuthorizationRequirement);
  }

  methodDescriptorUpdateTransferControl = new grpcWeb.MethodDescriptor(
    '/account_endpoint.AccountEndpoint/UpdateTransferControl',
    grpcWeb.MethodType.UNARY,
    account_pb.UpdateTransferControlRequest,
    account_pb.UpdateTransferControlResponse,
    (request: account_pb.UpdateTransferControlRequest) => {
      return request.serializeBinary();
    },
    account_pb.UpdateTransferControlResponse.deserializeBinary
  );

  updateTransferControl(
    request: account_pb.UpdateTransferControlRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_pb.UpdateTransferControlResponse>;

  updateTransferControl(
    request: account_pb.UpdateTransferControlRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_pb.UpdateTransferControlResponse) => void): grpcWeb.ClientReadableStream<account_pb.UpdateTransferControlResponse>;

  updateTransferControl(
    request: account_pb.UpdateTransferControlRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_pb.UpdateTransferControlResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_endpoint.AccountEndpoint/UpdateTransferControl',
        request,
        metadata || {},
        this.methodDescriptorUpdateTransferControl,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_endpoint.AccountEndpoint/UpdateTransferControl',
    request,
    metadata || {},
    this.methodDescriptorUpdateTransferControl);
  }

  methodDescriptorLink = new grpcWeb.MethodDescriptor(
    '/account_endpoint.AccountEndpoint/Link',
    grpcWeb.MethodType.UNARY,
    account_pb.LinkRequest,
    account_pb.LinkResponse,
    (request: account_pb.LinkRequest) => {
      return request.serializeBinary();
    },
    account_pb.LinkResponse.deserializeBinary
  );

  link(
    request: account_pb.LinkRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_pb.LinkResponse>;

  link(
    request: account_pb.LinkRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_pb.LinkResponse) => void): grpcWeb.ClientReadableStream<account_pb.LinkResponse>;

  link(
    request: account_pb.LinkRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_pb.LinkResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_endpoint.AccountEndpoint/Link',
        request,
        metadata || {},
        this.methodDescriptorLink,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_endpoint.AccountEndpoint/Link',
    request,
    metadata || {},
    this.methodDescriptorLink);
  }

  methodDescriptorRevokeLink = new grpcWeb.MethodDescriptor(
    '/account_endpoint.AccountEndpoint/RevokeLink',
    grpcWeb.MethodType.UNARY,
    account_pb.RevokeLinkRequest,
    account_pb.LinkResponse,
    (request: account_pb.RevokeLinkRequest) => {
      return request.serializeBinary();
    },
    account_pb.LinkResponse.deserializeBinary
  );

  revokeLink(
    request: account_pb.RevokeLinkRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_pb.LinkResponse>;

  revokeLink(
    request: account_pb.RevokeLinkRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_pb.LinkResponse) => void): grpcWeb.ClientReadableStream<account_pb.LinkResponse>;

  revokeLink(
    request: account_pb.RevokeLinkRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_pb.LinkResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_endpoint.AccountEndpoint/RevokeLink',
        request,
        metadata || {},
        this.methodDescriptorRevokeLink,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_endpoint.AccountEndpoint/RevokeLink',
    request,
    metadata || {},
    this.methodDescriptorRevokeLink);
  }

}

