import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, refreshSession, getSession, ACCOUNTANT_API_URL, APIRequest } from '../../../../../app/Api';
import { AccountTypeEndpointClient } from '../../../../../repository/Accountant/Account_typeServiceClientPb';
import { GetAccountTypesResponse, GetAccountTypesRequest } from '../../../../../repository/Accountant/account_type_pb';
import { formatDate, timestampToDate } from '../../../../../app/Helpers';
import { DynamicField } from '../../../../common/DynamicFields/DynamicFields';
import { AccountTypeRuleEndpointClient } from '../../../../../repository/Accountant/Account_type_ruleServiceClientPb';
import { AddAccountTypeRuleRequest, AddAccountTypeRuleResponse, GetAccountTypeRuleRequest, UpdateAccountTypeRuleRequest, UpdateAccountTypeRuleResponse, GetAccountTypeRuleResponse } from '../../../../../repository/Accountant/account_type_rule_pb';
import { FeeEndpointClient } from '../../../../../repository/Accountant/FeeServiceClientPb';
import { GetFeesRequest, GetFeesResponse } from '../../../../../repository/Accountant/fee_pb';
import { TableState } from '../../../../common/Table/TableSate';
import { TreasuryTypeRuleEndpointClient } from '../../../../../repository/Accountant/Treasury_type_ruleServiceClientPb';
import { AddTreasuryTypeRuleResponse, GetTreasuryTypeRuleRequest, UpdateTreasuryTypeRuleRequest, UpdateTreasuryTypeRuleResponse, GetTreasuryTypeRuleResponse, AddTreasuryTypeRuleRequest } from '../../../../../repository/Accountant/treasury_type_rule_pb';


export interface TreasuryTypeRulesFormState {
    isLoading: boolean
    message?: ApiMessage
    stage1: any,
    accountTypes: TableState,
    fees: TableState,


}

const initialState: TreasuryTypeRulesFormState = {
    isLoading: false,
    message: undefined,
    stage1: undefined,
    accountTypes: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    fees: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },

}

const client = new TreasuryTypeRuleEndpointClient(ACCOUNTANT_API_URL, null, null);
const accountTypesClient = new AccountTypeEndpointClient(ACCOUNTANT_API_URL, null, null);
const feesClient = new FeeEndpointClient(ACCOUNTANT_API_URL, null, null);


export const add = createAsyncThunk<AddTreasuryTypeRuleResponse.AsObject, APIRequest<AddTreasuryTypeRuleRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/treasuryTypeRules/add',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.add(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)


export const update = createAsyncThunk<UpdateTreasuryTypeRuleResponse.AsObject, APIRequest<UpdateTreasuryTypeRuleRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/treasuryTypeRules/update',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.update(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)



export const get = createAsyncThunk<GetTreasuryTypeRuleResponse.AsObject, APIRequest<GetTreasuryTypeRuleRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/treasuryTypeRules/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getTreasuryTypeRule(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)


export const getAccountTypes = createAsyncThunk<GetAccountTypesResponse.AsObject, APIRequest<GetAccountTypesRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/treasuryTypeRules/accountTypes/getAccountTypes',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await accountTypesClient.getAccountTypes(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getFees = createAsyncThunk<GetFeesResponse.AsObject, APIRequest<GetFeesRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/treasuryTypeRules/fees/getFees',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await feesClient.getFees(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)



export const treasuryTypeRulesFormSlice = createSlice({
    name: 'forms/accountant/treasuryTypeRules',
    initialState,
    reducers: {

        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.isLoading = false;
            state.message = undefined;
            state.stage1 = initialState.stage1;
            state.accountTypes = initialState.accountTypes;
            state.fees = initialState.fees;
        },
        setStage1: (state, action: PayloadAction<any>) => {
            let stage1 = {
                id: action.payload?.id,
                name: action.payload?.name,
                isActive: action.payload?.isActive,
                createdDate: action.payload?.createdDate,
                addedBy: action.payload?.addedBy,
                addedById: action.payload?.addedById,
                extraFields: action.payload?.extraFields,
                accountTypeId: action.payload?.accountTypeId,
                accountTypeName: action.payload?.accountTypeName,
                operation: action.payload?.operation,
                feeId: action.payload?.feeId,
                feeName: action.payload?.feeName,

            }
            state.stage1 = stage1;
        },
        resetAccountTypes: (state) => {
            state.accountTypes.items = [];
            state.accountTypes.hasMore = true;
        },

        resetFees: (state) => {
            state.fees = initialState.fees
        },

        setSelectedFee: (state, action: PayloadAction<any[]>) => {
            state.fees.selected = action.payload;
        },

        setSelectedAccountType: (state, action: PayloadAction<any[]>) => {
            state.accountTypes.selected = action.payload;
        },
        setIsFilteredAccountTypesSet: (state, action: PayloadAction<boolean>) => {
            state.accountTypes.isFilteredSet = action.payload;
        },
        setIsFilteredFeesSet: (state, action: PayloadAction<boolean>) => {
            state.fees.isFilteredSet = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(add.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            if (payload)
                state.message = toApiMessage(payload.success?.message);

        })
        builder.addCase(add.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(add.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })
        builder.addCase(update.fulfilled, (state, { payload }) => {
            if (payload)
                state.message = toApiMessage(payload.success?.message);
            state.isLoading = false;
        })
        builder.addCase(update.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(update.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })

        builder.addCase(get.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {

                let r = {
                    id: payload?.success?.id,
                    accountTypeId: payload?.success?.accounttype?.id?.value ?? "",
                    accountTypeName: payload?.success?.accounttype?.name?.value ?? "",
                    treasuryTypeId: payload?.success?.treasurytype?.id?.value ?? "",
                    treasuryTypeName: payload?.success?.treasurytype?.name?.value ?? "",
                    operation: payload?.success?.operation?.value ?? undefined,
                    feeId: payload?.success?.fee?.id?.value ?? "",
                    feeName: payload?.success?.fee?.name?.value ?? "",
                    isActive: payload?.success?.isactive?.value ?? undefined,
                    createdDate: formatDate(timestampToDate(payload?.success?.createddate?.seconds, payload?.success?.createddate?.nanos)),
                    addedBy: (payload?.success?.createdby?.firstname?.value ?? "") + " " + (payload?.success?.createdby?.lastname?.value ?? ""),
                    addedById: payload?.success?.createdby?.id?.value,
                    extraFields: [] as DynamicField[]
                }

                if (payload?.success?.extrafieldsList) {
                    let count = 0
                    payload?.success?.extrafieldsList?.forEach(element => {
                        r.extraFields.push({ key: element.key, label: element.label, type: element.type, value: element.value })
                        count++
                    });
                }

                state.stage1 = r
            }
        })
        builder.addCase(get.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(get.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })

        builder.addCase(getFees.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.feesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        isActive: val.isactive?.value ?? undefined,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])
                if (r.length < state.fees.numberOfResults) {
                    state.fees.hasMore = false;
                }
                state.fees.items = state.fees.items.concat(r);
            } else {
                state.fees.hasMore = false;
            }
            state.fees.isFetching = false

        })
        builder.addCase(getFees.rejected, (state, action) => {
            state.fees.isFetching = false;

            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getFees.pending, (state, action) => {
            state.fees.isFetching = true;
            //state.message = undefined;
        })


        builder.addCase(getAccountTypes.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.accounttypesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.accountTypes.numberOfResults) {
                    state.accountTypes.hasMore = false;
                }
                state.accountTypes.items = state.accountTypes.items.concat(r);

            } else {
                state.accountTypes.hasMore = false;
            }
            state.accountTypes.isFetching = false

        })
        builder.addCase(getAccountTypes.rejected, (state, action) => {
            state.accountTypes.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getAccountTypes.pending, (state, action) => {
            state.accountTypes.isFetching = true;
            //state.message = undefined;
        })
    }
})

export const { reset, dismissMessage, setStage1, resetAccountTypes, resetFees, setIsFilteredAccountTypesSet, setIsFilteredFeesSet, setSelectedAccountType, setSelectedFee } = treasuryTypeRulesFormSlice.actions

export default treasuryTypeRulesFormSlice.reducer


