import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, refreshSession, getSession, ACCOUNTANT_API_URL } from '../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../app/Helpers';
import { AccountTypeEndpointClient } from '../../../../repository/Accountant/Account_typeServiceClientPb';
import { GetAccountTypesRequest, GetAccountTypesResponse } from '../../../../repository/Accountant/account_type_pb';
import { CurrencyEndpointClient } from '../../../../repository/Accountant/CurrencyServiceClientPb';
import { GetCurrenciesRequest, GetCurrenciesResponse } from '../../../../repository/Accountant/currency_pb';
import { LimitationEndpointClient } from '../../../../repository/Accountant/LimitationServiceClientPb';
import { GetLimitationsRequest, GetLimitationsResponse } from '../../../../repository/Accountant/limitation_pb';
import { RoleEndpointClient } from '../../../../repository/UserManagement/Customers/RoleServiceClientPb';
import { GetRolesRequest, GetRolesResponse } from '../../../../repository/UserManagement/Customers/role_pb';
import { UserTypeEndpointClient } from '../../../../repository/UserManagement/Customers/UsertypeServiceClientPb';
import { GetUserTypesRequest, GetUserTypesResponse } from '../../../../repository/UserManagement/Customers/usertype_pb';
import { OrganizationClientPortalSettingEndpointClient } from '../../../../repository/UserManagement/OrganizationclientportalsettingServiceClientPb';
import {
    GetOrganizationClientPortalSettingRequest, GetOrganizationClientPortalSettingResponse, RemoveOpenAccountDefaultConfigurationRequest,
    UpdateOpenAccountDefaultConfigurationRequest, UpdateOpenAccountDefaultConfigurationResponse, UpdateURLsRequest, UpdateURLsResponse, UpdateCurrencyPairsConfigurationRequest
    , RemoveOpenAccountDefaultConfigurationResponse, UpdateClientDefaultConfigurationRequest, UpdateClientDefaultConfigurationResponse, UpdateCanSignUpRequest, UpdateCanSignUpResponse, UpdateCanOpenAccountResponse, UpdateCanOpenAccountRequest, RemoveCurrencyPairsConfigurationResponse, RemoveCurrencyPairsConfigurationRequest, UpdateCurrencyPairsConfigurationResponse, RemoveExternalTransfersEntitiesConfigurationResponse, RemoveExternalTransfersEntitiesConfigurationRequest, UpdateIsAccountLockedByDefaultResponse, UpdateIsAccountLockedByDefaultRequest,
} from '../../../../repository/UserManagement/organizationclientportalsetting_pb';
import { TableState } from '../../../common/Table/TableSate';


export interface OrganizationClientPortalSettingPageState {
    item: any
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    isLoading: boolean
    roles: TableState
    userTypes: TableState
    currencies: TableState
    accountTypes: TableState
    limitations: TableState
    openAccountDefaultConfiguration: TableState,
    currencyPairsConfiguration: TableState,
    externalTransfersEntitiesConfiguration: TableState



}

const initialState: OrganizationClientPortalSettingPageState = {
    item: undefined,
    isChangeStateLoading: false,
    message: undefined,
    isLoading: false,
    roles: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    userTypes: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    currencies: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    accountTypes: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    limitations: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    openAccountDefaultConfiguration: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    currencyPairsConfiguration: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    externalTransfersEntitiesConfiguration: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },

}

const client = new OrganizationClientPortalSettingEndpointClient(API_URL, null, null);
const clientRole = new RoleEndpointClient(API_URL, null, null);
const clientuserType = new UserTypeEndpointClient(API_URL, null, null);
const clientCurrency = new CurrencyEndpointClient(ACCOUNTANT_API_URL, null, null);
const clientAccountType = new AccountTypeEndpointClient(ACCOUNTANT_API_URL, null, null);
const clienLimition = new LimitationEndpointClient(ACCOUNTANT_API_URL, null, null);


export const getOrganizationClientPortalSetting = createAsyncThunk<GetOrganizationClientPortalSettingResponse.AsObject, APIRequest<GetOrganizationClientPortalSettingRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/organizationClientPortalSetting/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getOrganizationClientPortalSetting(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

export const updateOpenAccountDefaultConfiguration = createAsyncThunk<UpdateOpenAccountDefaultConfigurationResponse.AsObject, APIRequest<UpdateOpenAccountDefaultConfigurationRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/organizationClientPortalSetting/updateOpenAccountDefaultConfiguration',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.updateOpenAccountDefaultConfiguration(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const updateCurrencyPairsConfiguration = createAsyncThunk<UpdateCurrencyPairsConfigurationResponse.AsObject, APIRequest<UpdateCurrencyPairsConfigurationRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/organizationClientPortalSetting/updateCurrencyPairsConfiguration',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.updateCurrencyPairsConfiguration(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

export const updateURLs = createAsyncThunk<APIResponse<UpdateURLsResponse.AsObject>, APIRequest<UpdateURLsRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/organizationClientPortalSetting/updateURLs',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.updateURLs(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId(), terms: req.body.getTerms(), privacypolicy: req.body.getPrivacypolicy(), support: req.body.getSupport(), }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)




export const removeOpenAccountDefaultConfiguration = createAsyncThunk<APIResponse<RemoveOpenAccountDefaultConfigurationResponse.AsObject>, APIRequest<RemoveOpenAccountDefaultConfigurationRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/organizationClientPortalSetting/removeOpenAccountDefaultConfiguration',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.removeOpenAccountDefaultConfiguration(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getCurrency(), accountType: req.body.getAccounttype() }, response: r.toObject() };

            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const removeCurrencyPairsConfiguration = createAsyncThunk<APIResponse<RemoveCurrencyPairsConfigurationResponse.AsObject>, APIRequest<RemoveCurrencyPairsConfigurationRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/organizationClientPortalSetting/removeCurrencyPairsConfiguration',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.removeCurrencyPairsConfiguration(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getCurrencypair() }, response: r.toObject() };

            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const removeExternalTransfaresEntitesConfiguration = createAsyncThunk<APIResponse<RemoveExternalTransfersEntitiesConfigurationResponse.AsObject>, APIRequest<RemoveExternalTransfersEntitiesConfigurationRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/organizationClientPortalSetting/removeExternalTransfaresEntitesConfiguration',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.removeExternalTransfersEntitiesConfiguration(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getGwaccoint() }, response: r.toObject() };

            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const updateClientDefaultConfiguration = createAsyncThunk<APIResponse<UpdateClientDefaultConfigurationResponse.AsObject>, APIRequest<UpdateClientDefaultConfigurationRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/organizationClientPortalSetting/updateClientDefaultConfiguration',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.updateClientDefaultConfiguration(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId(), role: req.body.getRole(), userType: req.body.getUsertype() }, response: r.toObject() };

            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const updateCanSignUp = createAsyncThunk<APIResponse<UpdateCanSignUpResponse.AsObject>, APIRequest<UpdateCanSignUpRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/organizationClientPortalSetting/updateCanSignUp',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.updateCanSignUp(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId(), state: req.body.getCansignup() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

export const updateCanOpenAccount = createAsyncThunk<APIResponse<UpdateCanOpenAccountResponse.AsObject>, APIRequest<UpdateCanOpenAccountRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/organizationClientPortalSetting/updateCanOpenAccount',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.updateCanOpenAccount(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId(), state: req.body.getCanopenaccount() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)



export const updateIsAccountLockedByDefault = createAsyncThunk<APIResponse<UpdateIsAccountLockedByDefaultResponse.AsObject>, APIRequest<UpdateIsAccountLockedByDefaultRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/organizationClientPortalSetting/updateIsAccountLockedByDefault',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.updateIsAccountLockedByDefault(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId(), state: req.body.getIsaccountlockedbydefault() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)



export const getRoles = createAsyncThunk<GetRolesResponse.AsObject, APIRequest<GetRolesRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/organizationClientPortalSetting/getRoles',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clientRole.getRoles(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }


)

export const getUserTypes = createAsyncThunk<GetUserTypesResponse.AsObject, APIRequest<GetUserTypesRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/organizationClientPortalSetting/getUserTypes',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await clientuserType.getUserTypes(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getCurrencies = createAsyncThunk<GetCurrenciesResponse.AsObject, APIRequest<GetCurrenciesRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/organizationClientPortalSetting/currencies/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await clientCurrency.getCurrencies(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getAccountTypes = createAsyncThunk<GetAccountTypesResponse.AsObject, APIRequest<GetAccountTypesRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/organizationClientPortalSetting/accountTypes/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clientAccountType.getAccountTypes(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

export const getLimitations = createAsyncThunk<GetLimitationsResponse.AsObject, APIRequest<GetLimitationsRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/organizationClientPortalSetting/limitations/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clienLimition.getLimitations(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const organizationClientPortalSettingPageSlice = createSlice({
    name: 'pages/organizationClientPortalSetting',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
            state.item = initialState.item;
            state.roles = initialState.roles;
            state.userTypes = initialState.userTypes;
            state.accountTypes = initialState.accountTypes;
            state.currencies = initialState.currencies;
            state.limitations = initialState.limitations;
            state.openAccountDefaultConfiguration = initialState.openAccountDefaultConfiguration;
            state.currencyPairsConfiguration = initialState.currencyPairsConfiguration;
            state.externalTransfersEntitiesConfiguration = initialState.externalTransfersEntitiesConfiguration;

        },
        setSelectedAccountConfig: (state, action: PayloadAction<any[]>) => {
            state.openAccountDefaultConfiguration.selected = action.payload;
        },

        addAccountConfig: (state, action: PayloadAction<any>) => {
            state.openAccountDefaultConfiguration.items.push(action.payload);
            state.item.openAccountDefaultConfiguration.push(action.payload);

        },
        updateAccountConfig: (state, action: PayloadAction<any>) => {
            let selected = state.openAccountDefaultConfiguration.selected;
            if (action?.payload) {
                if (selected.length > 0 && selected.at(0).currencyId == action?.payload?.currencyId && selected.at(0).accountTypeId == action?.payload?.accountTypeId) {
                    selected[0] = action?.payload
                }
                var u = state.openAccountDefaultConfiguration.items.findIndex(e => e.currencyId == action?.payload?.currencyId && e.accountTypeId == action?.payload?.accountTypeId)
                if (u >= 0) {
                    state.openAccountDefaultConfiguration.items[u] = action?.payload
                }
            }

        },

        setSelectedCurrencyPairConfig: (state, action: PayloadAction<any[]>) => {
            state.currencyPairsConfiguration.selected = action.payload;
        },

        setSelectedExternalTransferEntityConfig: (state, action: PayloadAction<any[]>) => {
            state.externalTransfersEntitiesConfiguration.selected = action.payload;
        },
        addExternalTransferEntityConfig: (state, action: PayloadAction<any>) => {
            state.externalTransfersEntitiesConfiguration.items.push(action.payload);
            state.item.externalTransfersEntitiesConfiguration.push(action.payload);
        },
        updateExternalTransferEntityConfig: (state, action: PayloadAction<any>) => {
            let selected = state.externalTransfersEntitiesConfiguration.selected;
            if (action?.payload) {
                if (selected.length > 0 && selected.at(0).gwAccountInternalId == action?.payload?.gwAccountInternalId) {
                    selected[0] = action?.payload
                }
                var u = state.externalTransfersEntitiesConfiguration.items.findIndex(e => e.gwAccountInternalId == action?.payload?.gwAccountInternalId)
                if (u >= 0) {
                    state.externalTransfersEntitiesConfiguration.items[u] = action?.payload
                }
            }

        },
        addCurrencyPairConfig: (state, action: PayloadAction<any>) => {
            state.currencyPairsConfiguration.items.push(action.payload);
            state.item.currencyPairsConfiguration.push(action.payload);

        },
        updateCurrencyPairConfig: (state, action: PayloadAction<any>) => {
            let selected = state.currencyPairsConfiguration.selected;
            if (action?.payload) {
                if (selected.length > 0 && selected.at(0).currencyPairId == action?.payload?.currencyPairId) {
                    selected[0] = action?.payload
                }
                var u = state.currencyPairsConfiguration.items.findIndex(e => e.currencyPairId == action?.payload?.currencyPairId)
                if (u >= 0) {
                    state.currencyPairsConfiguration.items[u] = action?.payload
                }
            }

        },

        resetItem: (state) => {
            state.item = undefined;

        },

        resetLimitations: (state) => {
            state.limitations.items = [];
            state.limitations.hasMore = true;
        },
        resetCurrencies: (state) => {
            state.currencies.items = [];
            state.currencies.hasMore = true;
        },
        resetAccountTypes: (state) => {
            state.accountTypes.items = [];
            state.accountTypes.hasMore = true;
        },

        resetRoles: (state) => {
            state.roles.items = [];
            state.roles.hasMore = true;
        },
        resetUserTypes: (state) => {
            state.userTypes.items = [];
            state.userTypes.hasMore = true;
        },
        setSelectedRole: (state, action: PayloadAction<any[]>) => {
            state.roles.selected = action.payload;

        },
        setSelectedUserType: (state, action: PayloadAction<any[]>) => {
            state.userTypes.selected = action.payload;

        },

        setSelectedCurrencyItems: (state, action: PayloadAction<any[]>) => {
            state.currencies.selected = action.payload;

        },
        setSelectedLimitationItems: (state, action: PayloadAction<any[]>) => {
            state.limitations.selected = action.payload;

        },
        setSelectedAccountTypeItems: (state, action: PayloadAction<any[]>) => {
            state.accountTypes.selected = action.payload;

        },

        setIsFilteredRolesSet: (state, action: PayloadAction<boolean>) => {
            state.roles.isFilteredSet = action.payload;
        },
        setIsFilteredUserTypesSet: (state, action: PayloadAction<boolean>) => {
            state.userTypes.isFilteredSet = action.payload;
        },

        setIsFilteredCurrenciesSet: (state, action: PayloadAction<boolean>) => {
            state.currencies.isFilteredSet = action.payload;
        },
        setIsFilteredLimitationsSet: (state, action: PayloadAction<boolean>) => {
            state.limitations.isFilteredSet = action.payload;
        },
        setIsFilteredAccountTypesSet: (state, action: PayloadAction<boolean>) => {
            state.accountTypes.isFilteredSet = action.payload;
        },
        updateItem: (state, action: PayloadAction<any>) => {
            state.item = action.payload;
        },
        resetMessage: (state) => {
            state.message = undefined;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getOrganizationClientPortalSetting.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {

                var r = {
                    id: payload.success?.id,
                    canSignup: (payload.success?.cansignup?.value),
                    canOpenAccount: payload.success?.canopenaccount?.value,
                    isActive: payload.success?.isactive?.value,
                    isAccountLockedByDefault: payload.success?.isaccountlockedbydefault?.value,

                    privacyPolicy: payload.success?.privacypolicy?.value ?? "",
                    terms: payload.success?.terms?.value ?? "",
                    support: payload.success?.support?.value ?? "",
                    clientDefaultRoleId: payload.success?.clientdefaultconfiguration?.role?.id,
                    clientDefaultRoleName: payload.success?.clientdefaultconfiguration?.role?.name?.value,
                    clientDefaultUserTypeId: payload.success?.clientdefaultconfiguration?.usertype?.id,
                    clientDefaultUserTypeName: payload.success?.clientdefaultconfiguration?.usertype?.name?.value,
                    openAccountDefaultConfiguration: [] as any[],
                    currencyPairsConfiguration: [] as any[],
                    externalTransfersEntitiesConfiguration: [] as any[],
                    createdDate: formatDate(timestampToDate(payload.success?.createddate?.seconds, payload.success?.createddate?.nanos)),
                    addedBy: (payload.success?.createdby?.firstname?.value ?? "") + " " + (payload.success?.createdby?.lastname?.value ?? ""),
                    addedById: payload.success?.createdby?.id?.value,

                }
                if (r.clientDefaultRoleId) {
                    state.roles.selected = [{ id: r.clientDefaultRoleId, name: r.clientDefaultRoleName }]
                } else {
                    state.roles.selected = []
                }


                if (r.clientDefaultUserTypeId) {
                    state.userTypes.selected = [{ id: r.clientDefaultUserTypeId, name: r.clientDefaultUserTypeName }]
                } else {
                    state.userTypes.selected = []
                }

                if (payload?.success?.openaccountdefaultconfigurationsList) {
                    payload?.success?.openaccountdefaultconfigurationsList?.forEach(element => {

                        let item = {
                            accountTypeId: element.accounttype?.id,
                            accountTypeName: element.accounttype?.name?.value ?? "",
                            currencyId: element.currency?.id,
                            currencyName: element.currency?.name?.value,
                            limitationId: element.limitation?.id,
                            limitationName: element.limitation?.name?.value,
                            maxNumberOfAccounts: element.maxnumberofaccountscanbeopend?.value ?? 0,
                            openByDefault: element.openbydefault?.value,
                            rank: element.rank?.value,
                            canUpdateName: element.canupdatename?.value,
                            canUpdateCode: element.canupdatecode?.value

                        }


                        r.openAccountDefaultConfiguration.push(item);
                    });
                }

                state.openAccountDefaultConfiguration.items = r.openAccountDefaultConfiguration;
                state.openAccountDefaultConfiguration.hasMore = false;



                if (payload?.success?.currencypairsconfigurationsList) {
                    payload?.success?.currencypairsconfigurationsList?.forEach(element => {

                        let item = {
                            currencyPairId: element.currencypair?.id,
                            currencyPairName: element.currencypair?.name?.value ?? "",
                            currencyPairBaseId: element.currencypair?.base?.id,
                            currencyPairBaseName: element.currencypair?.base?.name?.value ?? "",
                            currencyPairQuoteId: element.currencypair?.quote?.id,
                            currencyPairQuoteName: element.currencypair?.quote?.name?.value ?? "",
                            rank: element.rank?.value,
                        }


                        r.currencyPairsConfiguration.push(item);
                    });
                }

                state.currencyPairsConfiguration.items = r.currencyPairsConfiguration;
                state.currencyPairsConfiguration.hasMore = false;

                if (payload?.success?.externaltransfersentitiesconfigurationsList) {
                    payload?.success?.externaltransfersentitiesconfigurationsList?.forEach(element => {

                        let item = {
                            name: element.name?.value ?? "",
                            gwAccountInternalId: element.gwaccount?.internalid,
                            gwAccountId: element.gwaccount?.id,
                            gwAccountCurrencyId: element.gwaccount?.currency?.id,
                            gwAccountCurrencyName: element.gwaccount?.currency?.name?.value ?? "",
                        }


                        r.externalTransfersEntitiesConfiguration.push(item);
                    });
                }

                state.externalTransfersEntitiesConfiguration.items = r.externalTransfersEntitiesConfiguration;
                state.externalTransfersEntitiesConfiguration.hasMore = false;

                state.item = r;

            }
        })
        builder.addCase(getOrganizationClientPortalSetting.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getOrganizationClientPortalSetting.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })

        builder.addCase(getRoles.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = payload?.success?.rolesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isdefault: val.isdefault?.value,
                        rolecategory: val.rolecategory?.value,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.addedby?.firstname?.value ?? "") + " " + (val.addedby?.lastname?.value ?? ""),
                        addedById: val.addedby?.id?.value,
                    }
                }
                ) as any[]

                if (r.length < state.roles.numberOfResults) {
                    state.roles.hasMore = false;
                }
                state.roles.items = state.roles.items.concat(r);

            }
            state.roles.isFetching = false;

        })
        builder.addCase(getRoles.rejected, (state, action) => {
            state.roles.isFetching = false;

            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getRoles.pending, (state, action) => {
            state.roles.isFetching = true;
            //state.message = undefined;
        })

        builder.addCase(getUserTypes.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = payload?.success?.usertypesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        userTypeCategory: val.usertypecategory?.value,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.addedby?.firstname?.value ?? "") + " " + (val.addedby?.lastname?.value ?? ""),
                        addedById: val.addedby?.id?.value,
                    }
                }
                ) as any[]

                if (r.length < state.userTypes.numberOfResults) {
                    state.userTypes.hasMore = false;
                }
                state.userTypes.items = state.userTypes.items.concat(r);

            }
            state.userTypes.isFetching = false;

        })
        builder.addCase(getUserTypes.rejected, (state, action) => {
            state.userTypes.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getUserTypes.pending, (state, action) => {
            state.userTypes.isFetching = true;
            //state.message = undefined;
        })





        builder.addCase(getAccountTypes.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.accounttypesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])

                if (r.length < state.accountTypes.numberOfResults) {
                    state.accountTypes.hasMore = false;
                }
                state.accountTypes.items = state.accountTypes.items.concat(r);

            } else {
                state.accountTypes.hasMore = false;
            }
            state.accountTypes.isFetching = false

        })
        builder.addCase(getAccountTypes.rejected, (state, action) => {
            state.accountTypes.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getAccountTypes.pending, (state, action) => {
            state.accountTypes.isFetching = true;
            //state.message = undefined;
        })

        builder.addCase(getCurrencies.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.currenciesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        code: val.code?.value ?? "",
                        icon: val.iconsource?.value ?? "",
                        form: val.form?.formid,
                        symbol: val.symbol?.value ?? "",
                        decimalPlaces: val.decimalplaces?.value ?? "",
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.currencies.numberOfResults) {
                    state.currencies.hasMore = false;
                }
                state.currencies.items = state.currencies.items.concat(r);


                //console.log(state.items)

            } else {
                state.currencies.hasMore = false;
            }
            state.currencies.isFetching = false

        })
        builder.addCase(getCurrencies.rejected, (state, action) => {
            state.currencies.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getCurrencies.pending, (state, action) => {
            state.currencies.isFetching = true;
            //state.message = undefined;
        })





        builder.addCase(getLimitations.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.limitationsList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        isActive: val.isactive?.value ?? undefined,
                        type: val.type?.value ?? undefined,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.limitations.numberOfResults) {
                    state.limitations.hasMore = false;
                }
                state.limitations.items = state.limitations.items.concat(r);
            } else {
                state.limitations.hasMore = false;
            }
            state.limitations.isFetching = false

        })
        builder.addCase(getLimitations.rejected, (state, action) => {
            state.limitations.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getLimitations.pending, (state, action) => {
            state.limitations.isFetching = true;
            //state.message = undefined;
        })


        builder.addCase(updateIsAccountLockedByDefault.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                state.item.isAccountLockedByDefault = payload?.metadata.state

            }

        })
        builder.addCase(updateIsAccountLockedByDefault.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateIsAccountLockedByDefault.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

        builder.addCase(updateCanSignUp.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                state.item.canSignup = payload?.metadata.state
            }

        })
        builder.addCase(updateCanSignUp.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateCanSignUp.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

        builder.addCase(updateCanOpenAccount.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                state.item.canOpenAccount = payload?.metadata.state
            }

        })
        builder.addCase(updateCanOpenAccount.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateCanOpenAccount.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

        builder.addCase(updateOpenAccountDefaultConfiguration.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.success?.message);

                //state.item. = payload?.metadata.state
            }
        })
        builder.addCase(updateOpenAccountDefaultConfiguration.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateOpenAccountDefaultConfiguration.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })

        builder.addCase(updateClientDefaultConfiguration.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                state.item.clientDefaultRoleId = payload?.metadata?.role;
                state.item.clientDefaultUserTypeId = payload?.metadata?.userType;
                //state.item. = payload?.metadata.state
            }
        })
        builder.addCase(updateClientDefaultConfiguration.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateClientDefaultConfiguration.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })



        builder.addCase(updateURLs.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response?.success?.message);
                state.item.terms = payload?.metadata?.terms
                state.item.privacyPolicy = payload?.metadata?.privacyPolicy
                state.item.support = payload?.metadata?.support

            }
        })
        builder.addCase(updateURLs.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateURLs.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })

        builder.addCase(removeOpenAccountDefaultConfiguration.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isChangeStateLoading = false;

            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                if (payload?.metadata) {
                    var u = state.item?.openAccountDefaultConfiguration?.findIndex((e: any) => e.currencyId == payload?.metadata.id && e.accountTypeId == payload?.metadata.accountType);
                    var u2 = state.openAccountDefaultConfiguration?.items.findIndex((e: any) => e.currencyId == payload?.metadata.id && e.accountTypeId == payload?.metadata.accountType);
                    if (u >= 0) {
                        state.item?.openAccountDefaultConfiguration.splice(u, 1);
                    }
                    if (u2 >= 0) {
                        state.openAccountDefaultConfiguration?.items?.splice(u, 1);
                    }

                }
            }
        })
        builder.addCase(removeOpenAccountDefaultConfiguration.rejected, (state, action) => {
            state.isLoading = false;
            state.isChangeStateLoading = false;

            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(removeOpenAccountDefaultConfiguration.pending, (state, action) => {
            state.isLoading = true;
            state.isChangeStateLoading = true;

            state.message = undefined;
        })


        builder.addCase(removeCurrencyPairsConfiguration.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isChangeStateLoading = false;

            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                if (payload?.metadata) {
                    var u = state.item?.currencyPairsConfiguration?.findIndex((e: any) => e.currencyPairId == payload?.metadata.id);
                    var u2 = state.currencyPairsConfiguration?.items.findIndex((e: any) => e.currencyPairId == payload?.metadata.id);
                    if (u >= 0) {
                        state.item?.currencyPairsConfiguration.splice(u, 1);
                    }
                    if (u2 >= 0) {
                        state.currencyPairsConfiguration?.items?.splice(u, 1);
                    }

                }
            }
        })
        builder.addCase(removeCurrencyPairsConfiguration.rejected, (state, action) => {
            state.isLoading = false;
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(removeCurrencyPairsConfiguration.pending, (state, action) => {
            state.isLoading = true;
            state.isChangeStateLoading = true;

            state.message = undefined;
        })
        builder.addCase(removeExternalTransfaresEntitesConfiguration.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isChangeStateLoading = false;

            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                if (payload?.metadata) {
                    var u = state.item?.externalTransfersEntitesConfiguration?.findIndex((e: any) => e.gwAccountInternalId == payload?.metadata.id);
                    var u2 = state.externalTransfersEntitiesConfiguration?.items.findIndex((e: any) => e.gwAccountInternalId == payload?.metadata.id);
                    if (u >= 0) {
                        state.item?.externalTransfersEntitesConfiguration.splice(u, 1);
                    }
                    if (u2 >= 0) {
                        state.externalTransfersEntitiesConfiguration?.items?.splice(u, 1);
                    }

                }
            }
        })
        builder.addCase(removeExternalTransfaresEntitesConfiguration.rejected, (state, action) => {
            state.isLoading = false;
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(removeExternalTransfaresEntitesConfiguration.pending, (state, action) => {
            state.isLoading = true;
            state.isChangeStateLoading = true;

            state.message = undefined;
        })

    }
})

export const { reset, dismissMessage, addAccountConfig, resetMessage, updateExternalTransferEntityConfig, addExternalTransferEntityConfig, addCurrencyPairConfig, setSelectedExternalTransferEntityConfig, setSelectedCurrencyPairConfig, updateCurrencyPairConfig, updateAccountConfig, setSelectedAccountConfig, resetItem, updateItem, resetAccountTypes, resetCurrencies, resetLimitations, setIsFilteredAccountTypesSet, setIsFilteredCurrenciesSet, setIsFilteredLimitationsSet, setSelectedAccountTypeItems, setSelectedCurrencyItems, setSelectedLimitationItems, resetRoles, resetUserTypes, setIsFilteredRolesSet, setIsFilteredUserTypesSet, setSelectedRole, setSelectedUserType } = organizationClientPortalSettingPageSlice.actions

export default organizationClientPortalSettingPageSlice.reducer