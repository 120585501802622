// source: treasury_type_rule.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('./common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay', null, global);
goog.exportSymbol('proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest', null, global);
goog.exportSymbol('proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse', null, global);
goog.exportSymbol('proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse.ResultCase', null, global);
goog.exportSymbol('proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay', null, global);
goog.exportSymbol('proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleRequest', null, global);
goog.exportSymbol('proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse', null, global);
goog.exportSymbol('proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse.ResultCase', null, global);
goog.exportSymbol('proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay', null, global);
goog.exportSymbol('proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleRequest', null, global);
goog.exportSymbol('proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse', null, global);
goog.exportSymbol('proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse.ResultCase', null, global);
goog.exportSymbol('proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay', null, global);
goog.exportSymbol('proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest', null, global);
goog.exportSymbol('proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse', null, global);
goog.exportSymbol('proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse.ResultCase', null, global);
goog.exportSymbol('proto.treasury_type_rule_endpoint.TreasuryTypeRule', null, global);
goog.exportSymbol('proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay', null, global);
goog.exportSymbol('proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityRequest', null, global);
goog.exportSymbol('proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse', null, global);
goog.exportSymbol('proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse.ResultCase', null, global);
goog.exportSymbol('proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay', null, global);
goog.exportSymbol('proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest', null, global);
goog.exportSymbol('proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse', null, global);
goog.exportSymbol('proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse.ResultCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest.repeatedFields_, null);
};
goog.inherits(proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest.displayName = 'proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest.repeatedFields_, null);
};
goog.inherits(proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest.displayName = 'proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityRequest.displayName = 'proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleRequest.displayName = 'proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleRequest.displayName = 'proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.displayName = 'proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay.displayName = 'proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay.displayName = 'proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay.displayName = 'proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay.displayName = 'proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.repeatedFields_, null);
};
goog.inherits(proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.displayName = 'proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_rule_endpoint.TreasuryTypeRule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.treasury_type_rule_endpoint.TreasuryTypeRule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_rule_endpoint.TreasuryTypeRule.displayName = 'proto.treasury_type_rule_endpoint.TreasuryTypeRule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay.repeatedFields_, null);
};
goog.inherits(proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay.displayName = 'proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse.oneofGroups_);
};
goog.inherits(proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse.displayName = 'proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse.oneofGroups_);
};
goog.inherits(proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse.displayName = 'proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse.oneofGroups_);
};
goog.inherits(proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse.displayName = 'proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse.oneofGroups_);
};
goog.inherits(proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse.displayName = 'proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse.oneofGroups_);
};
goog.inherits(proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse.displayName = 'proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse.oneofGroups_);
};
goog.inherits(proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse.displayName = 'proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    operation: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accounttype: (f = msg.getAccounttype()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    treasurytype: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fee: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isactive: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    directreservation: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest}
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest;
  return proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest}
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOperation(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccounttype(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTreasurytype(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFee(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsactive(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDirectreservation(value);
      break;
    case 6:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperation();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAccounttype();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTreasurytype();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFee();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsactive();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getDirectreservation();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 operation = 1;
 * @return {number}
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest.prototype.getOperation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest} returns this
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest.prototype.setOperation = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue accountType = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest.prototype.getAccounttype = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest} returns this
*/
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest.prototype.setAccounttype = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest} returns this
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest.prototype.clearAccounttype = function() {
  return this.setAccounttype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest.prototype.hasAccounttype = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string treasuryType = 3;
 * @return {string}
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest.prototype.getTreasurytype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest} returns this
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest.prototype.setTreasurytype = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string fee = 4;
 * @return {string}
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest.prototype.getFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest} returns this
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest.prototype.setFee = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool isActive = 5;
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest.prototype.getIsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest} returns this
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest.prototype.setIsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool directReservation = 10;
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest.prototype.getDirectreservation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest} returns this
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest.prototype.setDirectreservation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * repeated common.ExtraField extraFields = 6;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 6));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest} returns this
*/
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest} returns this
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};


/**
 * optional google.protobuf.Int32Value organization = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest} returns this
*/
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest} returns this
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accounttype: (f = msg.getAccounttype()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    fee: jspb.Message.getFieldWithDefault(msg, 3, ""),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest;
  return proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccounttype(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFee(value);
      break;
    case 4:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccounttype();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFee();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest} returns this
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue accountType = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest.prototype.getAccounttype = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest} returns this
*/
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest.prototype.setAccounttype = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest} returns this
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest.prototype.clearAccounttype = function() {
  return this.setAccounttype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest.prototype.hasAccounttype = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string fee = 3;
 * @return {string}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest.prototype.getFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest} returns this
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest.prototype.setFee = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated common.ExtraField extraFields = 4;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 4));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest} returns this
*/
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest} returns this
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isactive: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityRequest}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityRequest;
  return proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityRequest}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsactive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsactive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityRequest} returns this
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool isActive = 2;
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityRequest.prototype.getIsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityRequest} returns this
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityRequest.prototype.setIsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleRequest}
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleRequest;
  return proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleRequest}
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleRequest} returns this
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleRequest}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleRequest;
  return proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleRequest}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleRequest} returns this
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    accounttype: (f = msg.getAccounttype()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    treasurytype: (f = msg.getTreasurytype()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    operation: (f = msg.getOperation()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    isactive: (f = msg.getIsactive()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    directreservation: (f = msg.getDirectreservation()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    order: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    nextto: (f = msg.getNextto()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    numofresults: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest;
  return proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccounttype(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTreasurytype(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOperation(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsactive(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setDirectreservation(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrder(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNextto(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumofresults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getAccounttype();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTreasurytype();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOperation();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getIsactive();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getDirectreservation();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getNextto();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNumofresults();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional google.protobuf.Int32Value organization = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest} returns this
*/
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest} returns this
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue accountType = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.getAccounttype = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest} returns this
*/
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.setAccounttype = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest} returns this
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.clearAccounttype = function() {
  return this.setAccounttype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.hasAccounttype = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue treasuryType = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.getTreasurytype = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest} returns this
*/
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.setTreasurytype = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest} returns this
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.clearTreasurytype = function() {
  return this.setTreasurytype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.hasTreasurytype = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Int32Value operation = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.getOperation = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest} returns this
*/
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.setOperation = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest} returns this
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.clearOperation = function() {
  return this.setOperation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.hasOperation = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.BoolValue isActive = 5;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.getIsactive = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 5));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest} returns this
*/
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.setIsactive = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest} returns this
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.clearIsactive = function() {
  return this.setIsactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.hasIsactive = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.BoolValue directReservation = 10;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.getDirectreservation = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 10));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest} returns this
*/
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.setDirectreservation = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest} returns this
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.clearDirectreservation = function() {
  return this.setDirectreservation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.hasDirectreservation = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.StringValue search = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest} returns this
*/
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest} returns this
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool order = 7;
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.getOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest} returns this
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional google.protobuf.StringValue nextTo = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.getNextto = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest} returns this
*/
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.setNextto = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest} returns this
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.clearNextto = function() {
  return this.setNextto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.hasNextto = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int32 numOfResults = 9;
 * @return {number}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.getNumofresults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest} returns this
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesRequest.prototype.setNumofresults = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay}
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay;
  return proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay}
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 3:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay} returns this
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay} returns this
*/
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay} returns this
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.MessageResponse message = 3;
 * @return {?proto.common.MessageResponse}
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 3));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay} returns this
*/
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay} returns this
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay;
  return proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay} returns this
*/
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay} returns this
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay} returns this
*/
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay} returns this
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay;
  return proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay} returns this
*/
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay} returns this
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay} returns this
*/
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay} returns this
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    deleteddate: (f = msg.getDeleteddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay}
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay;
  return proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay}
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeleteddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeleteddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp deletedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay.prototype.getDeleteddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay} returns this
*/
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay.prototype.setDeleteddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay} returns this
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay.prototype.clearDeleteddate = function() {
  return this.setDeleteddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay.prototype.hasDeleteddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay} returns this
*/
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay} returns this
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operation: (f = msg.getOperation()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    accounttype: (f = msg.getAccounttype()) && Protos_common_pb.AccountTypeResponse.toObject(includeInstance, f),
    treasurytype: (f = msg.getTreasurytype()) && Protos_common_pb.TreasuryTypeResponse.toObject(includeInstance, f),
    fee: (f = msg.getFee()) && Protos_common_pb.FeeResponse.toObject(includeInstance, f),
    isactive: (f = msg.getIsactive()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    directreservation: (f = msg.getDirectreservation()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay;
  return proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOperation(value);
      break;
    case 3:
      var value = new Protos_common_pb.AccountTypeResponse;
      reader.readMessage(value,Protos_common_pb.AccountTypeResponse.deserializeBinaryFromReader);
      msg.setAccounttype(value);
      break;
    case 4:
      var value = new Protos_common_pb.TreasuryTypeResponse;
      reader.readMessage(value,Protos_common_pb.TreasuryTypeResponse.deserializeBinaryFromReader);
      msg.setTreasurytype(value);
      break;
    case 5:
      var value = new Protos_common_pb.FeeResponse;
      reader.readMessage(value,Protos_common_pb.FeeResponse.deserializeBinaryFromReader);
      msg.setFee(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsactive(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setDirectreservation(value);
      break;
    case 7:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    case 8:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getAccounttype();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.AccountTypeResponse.serializeBinaryToWriter
    );
  }
  f = message.getTreasurytype();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Protos_common_pb.TreasuryTypeResponse.serializeBinaryToWriter
    );
  }
  f = message.getFee();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Protos_common_pb.FeeResponse.serializeBinaryToWriter
    );
  }
  f = message.getIsactive();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getDirectreservation();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay} returns this
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Int32Value operation = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.getOperation = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay} returns this
*/
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.setOperation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay} returns this
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.clearOperation = function() {
  return this.setOperation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.hasOperation = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.AccountTypeResponse accountType = 3;
 * @return {?proto.common.AccountTypeResponse}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.getAccounttype = function() {
  return /** @type{?proto.common.AccountTypeResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.AccountTypeResponse, 3));
};


/**
 * @param {?proto.common.AccountTypeResponse|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay} returns this
*/
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.setAccounttype = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay} returns this
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.clearAccounttype = function() {
  return this.setAccounttype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.hasAccounttype = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional common.TreasuryTypeResponse treasuryType = 4;
 * @return {?proto.common.TreasuryTypeResponse}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.getTreasurytype = function() {
  return /** @type{?proto.common.TreasuryTypeResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.TreasuryTypeResponse, 4));
};


/**
 * @param {?proto.common.TreasuryTypeResponse|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay} returns this
*/
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.setTreasurytype = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay} returns this
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.clearTreasurytype = function() {
  return this.setTreasurytype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.hasTreasurytype = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional common.FeeResponse fee = 5;
 * @return {?proto.common.FeeResponse}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.getFee = function() {
  return /** @type{?proto.common.FeeResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.FeeResponse, 5));
};


/**
 * @param {?proto.common.FeeResponse|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay} returns this
*/
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.setFee = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay} returns this
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.clearFee = function() {
  return this.setFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.hasFee = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.BoolValue isActive = 6;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.getIsactive = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 6));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay} returns this
*/
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.setIsactive = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay} returns this
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.clearIsactive = function() {
  return this.setIsactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.hasIsactive = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.BoolValue directReservation = 10;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.getDirectreservation = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 10));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay} returns this
*/
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.setDirectreservation = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay} returns this
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.clearDirectreservation = function() {
  return this.setDirectreservation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.hasDirectreservation = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated common.ExtraField extraFields = 7;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 7));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay} returns this
*/
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay} returns this
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};


/**
 * optional common.ProfileResponse createdBy = 8;
 * @return {?proto.common.ProfileResponse}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 8));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay} returns this
*/
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay} returns this
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay} returns this
*/
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay} returns this
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_rule_endpoint.TreasuryTypeRule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_rule_endpoint.TreasuryTypeRule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.TreasuryTypeRule.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operation: (f = msg.getOperation()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    accounttype: (f = msg.getAccounttype()) && Protos_common_pb.AccountTypeResponse.toObject(includeInstance, f),
    treasurytype: (f = msg.getTreasurytype()) && Protos_common_pb.TreasuryTypeResponse.toObject(includeInstance, f),
    fee: (f = msg.getFee()) && Protos_common_pb.FeeResponse.toObject(includeInstance, f),
    isactive: (f = msg.getIsactive()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    directreservation: (f = msg.getDirectreservation()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_rule_endpoint.TreasuryTypeRule}
 */
proto.treasury_type_rule_endpoint.TreasuryTypeRule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_rule_endpoint.TreasuryTypeRule;
  return proto.treasury_type_rule_endpoint.TreasuryTypeRule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_rule_endpoint.TreasuryTypeRule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_rule_endpoint.TreasuryTypeRule}
 */
proto.treasury_type_rule_endpoint.TreasuryTypeRule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOperation(value);
      break;
    case 3:
      var value = new Protos_common_pb.AccountTypeResponse;
      reader.readMessage(value,Protos_common_pb.AccountTypeResponse.deserializeBinaryFromReader);
      msg.setAccounttype(value);
      break;
    case 4:
      var value = new Protos_common_pb.TreasuryTypeResponse;
      reader.readMessage(value,Protos_common_pb.TreasuryTypeResponse.deserializeBinaryFromReader);
      msg.setTreasurytype(value);
      break;
    case 5:
      var value = new Protos_common_pb.FeeResponse;
      reader.readMessage(value,Protos_common_pb.FeeResponse.deserializeBinaryFromReader);
      msg.setFee(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsactive(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setDirectreservation(value);
      break;
    case 7:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_rule_endpoint.TreasuryTypeRule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_rule_endpoint.TreasuryTypeRule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.TreasuryTypeRule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getAccounttype();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.AccountTypeResponse.serializeBinaryToWriter
    );
  }
  f = message.getTreasurytype();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Protos_common_pb.TreasuryTypeResponse.serializeBinaryToWriter
    );
  }
  f = message.getFee();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Protos_common_pb.FeeResponse.serializeBinaryToWriter
    );
  }
  f = message.getIsactive();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getDirectreservation();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.treasury_type_rule_endpoint.TreasuryTypeRule} returns this
 */
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Int32Value operation = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.getOperation = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.TreasuryTypeRule} returns this
*/
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.setOperation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.TreasuryTypeRule} returns this
 */
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.clearOperation = function() {
  return this.setOperation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.hasOperation = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.AccountTypeResponse accountType = 3;
 * @return {?proto.common.AccountTypeResponse}
 */
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.getAccounttype = function() {
  return /** @type{?proto.common.AccountTypeResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.AccountTypeResponse, 3));
};


/**
 * @param {?proto.common.AccountTypeResponse|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.TreasuryTypeRule} returns this
*/
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.setAccounttype = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.TreasuryTypeRule} returns this
 */
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.clearAccounttype = function() {
  return this.setAccounttype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.hasAccounttype = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional common.TreasuryTypeResponse treasuryType = 4;
 * @return {?proto.common.TreasuryTypeResponse}
 */
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.getTreasurytype = function() {
  return /** @type{?proto.common.TreasuryTypeResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.TreasuryTypeResponse, 4));
};


/**
 * @param {?proto.common.TreasuryTypeResponse|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.TreasuryTypeRule} returns this
*/
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.setTreasurytype = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.TreasuryTypeRule} returns this
 */
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.clearTreasurytype = function() {
  return this.setTreasurytype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.hasTreasurytype = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional common.FeeResponse fee = 5;
 * @return {?proto.common.FeeResponse}
 */
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.getFee = function() {
  return /** @type{?proto.common.FeeResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.FeeResponse, 5));
};


/**
 * @param {?proto.common.FeeResponse|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.TreasuryTypeRule} returns this
*/
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.setFee = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.TreasuryTypeRule} returns this
 */
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.clearFee = function() {
  return this.setFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.hasFee = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.BoolValue isActive = 6;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.getIsactive = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 6));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.TreasuryTypeRule} returns this
*/
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.setIsactive = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.TreasuryTypeRule} returns this
 */
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.clearIsactive = function() {
  return this.setIsactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.hasIsactive = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.BoolValue directReservation = 9;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.getDirectreservation = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 9));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.TreasuryTypeRule} returns this
*/
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.setDirectreservation = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.TreasuryTypeRule} returns this
 */
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.clearDirectreservation = function() {
  return this.setDirectreservation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.hasDirectreservation = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional common.ProfileResponse createdBy = 7;
 * @return {?proto.common.ProfileResponse}
 */
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 7));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.TreasuryTypeRule} returns this
*/
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.TreasuryTypeRule} returns this
 */
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.TreasuryTypeRule} returns this
*/
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.TreasuryTypeRule} returns this
 */
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.TreasuryTypeRule.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    treasurytyperulesList: jspb.Message.toObjectList(msg.getTreasurytyperulesList(),
    proto.treasury_type_rule_endpoint.TreasuryTypeRule.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay;
  return proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.treasury_type_rule_endpoint.TreasuryTypeRule;
      reader.readMessage(value,proto.treasury_type_rule_endpoint.TreasuryTypeRule.deserializeBinaryFromReader);
      msg.addTreasurytyperules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTreasurytyperulesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.treasury_type_rule_endpoint.TreasuryTypeRule.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TreasuryTypeRule treasuryTypeRules = 1;
 * @return {!Array<!proto.treasury_type_rule_endpoint.TreasuryTypeRule>}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay.prototype.getTreasurytyperulesList = function() {
  return /** @type{!Array<!proto.treasury_type_rule_endpoint.TreasuryTypeRule>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.treasury_type_rule_endpoint.TreasuryTypeRule, 1));
};


/**
 * @param {!Array<!proto.treasury_type_rule_endpoint.TreasuryTypeRule>} value
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay} returns this
*/
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay.prototype.setTreasurytyperulesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.treasury_type_rule_endpoint.TreasuryTypeRule=} opt_value
 * @param {number=} opt_index
 * @return {!proto.treasury_type_rule_endpoint.TreasuryTypeRule}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay.prototype.addTreasurytyperules = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.treasury_type_rule_endpoint.TreasuryTypeRule, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay} returns this
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay.prototype.clearTreasurytyperulesList = function() {
  return this.setTreasurytyperulesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse.ResultCase}
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse.prototype.getResultCase = function() {
  return /** @type {proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse}
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse;
  return proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse}
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay;
      reader.readMessage(value,proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional AddTreasuryTypeRuleReplay success = 1;
 * @return {?proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay}
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse.prototype.getSuccess = function() {
  return /** @type{?proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay} */ (
    jspb.Message.getWrapperField(this, proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay, 1));
};


/**
 * @param {?proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleReplay|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse} returns this
*/
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse} returns this
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.AddTreasuryTypeRuleResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse.ResultCase}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse.prototype.getResultCase = function() {
  return /** @type {proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse;
  return proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay;
      reader.readMessage(value,proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateTreasuryTypeRuleReplay success = 1;
 * @return {?proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse.prototype.getSuccess = function() {
  return /** @type{?proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay} */ (
    jspb.Message.getWrapperField(this, proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay, 1));
};


/**
 * @param {?proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleReplay|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse} returns this
*/
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse} returns this
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse.ResultCase}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse.prototype.getResultCase = function() {
  return /** @type {proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse;
  return proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay;
      reader.readMessage(value,proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateTreasuryTypeRuleActivityReplay success = 1;
 * @return {?proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse.prototype.getSuccess = function() {
  return /** @type{?proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay} */ (
    jspb.Message.getWrapperField(this, proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay, 1));
};


/**
 * @param {?proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityReplay|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse} returns this
*/
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse} returns this
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.UpdateTreasuryTypeRuleActivityResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse.ResultCase}
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse.prototype.getResultCase = function() {
  return /** @type {proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse}
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse;
  return proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse}
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay;
      reader.readMessage(value,proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeleteTreasuryTypeRuleReplay success = 1;
 * @return {?proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay}
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse.prototype.getSuccess = function() {
  return /** @type{?proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay} */ (
    jspb.Message.getWrapperField(this, proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay, 1));
};


/**
 * @param {?proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleReplay|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse} returns this
*/
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse} returns this
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.DeleteTreasuryTypeRuleResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse.ResultCase}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse.prototype.getResultCase = function() {
  return /** @type {proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse;
  return proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay;
      reader.readMessage(value,proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetTreasuryTypeRuleReplay success = 1;
 * @return {?proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse.prototype.getSuccess = function() {
  return /** @type{?proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay} */ (
    jspb.Message.getWrapperField(this, proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay, 1));
};


/**
 * @param {?proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleReplay|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse} returns this
*/
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse} returns this
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRuleResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse.ResultCase}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse.prototype.getResultCase = function() {
  return /** @type {proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse;
  return proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay;
      reader.readMessage(value,proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetTreasuryTypeRulesReplay success = 1;
 * @return {?proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse.prototype.getSuccess = function() {
  return /** @type{?proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay} */ (
    jspb.Message.getWrapperField(this, proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay, 1));
};


/**
 * @param {?proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesReplay|undefined} value
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse} returns this
*/
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse} returns this
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.treasury_type_rule_endpoint.GetTreasuryTypeRulesResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.treasury_type_rule_endpoint);
