/**
 * @fileoverview gRPC-Web generated client stub for currency_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as currency_pb from './currency_pb';


export class CurrencyEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAdd = new grpcWeb.MethodDescriptor(
    '/currency_endpoint.CurrencyEndpoint/Add',
    grpcWeb.MethodType.UNARY,
    currency_pb.AddCurrencyRequest,
    currency_pb.AddCurrencyResponse,
    (request: currency_pb.AddCurrencyRequest) => {
      return request.serializeBinary();
    },
    currency_pb.AddCurrencyResponse.deserializeBinary
  );

  add(
    request: currency_pb.AddCurrencyRequest,
    metadata: grpcWeb.Metadata | null): Promise<currency_pb.AddCurrencyResponse>;

  add(
    request: currency_pb.AddCurrencyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: currency_pb.AddCurrencyResponse) => void): grpcWeb.ClientReadableStream<currency_pb.AddCurrencyResponse>;

  add(
    request: currency_pb.AddCurrencyRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: currency_pb.AddCurrencyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/currency_endpoint.CurrencyEndpoint/Add',
        request,
        metadata || {},
        this.methodDescriptorAdd,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/currency_endpoint.CurrencyEndpoint/Add',
    request,
    metadata || {},
    this.methodDescriptorAdd);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/currency_endpoint.CurrencyEndpoint/Update',
    grpcWeb.MethodType.UNARY,
    currency_pb.UpdateCurrencyRequest,
    currency_pb.UpdateCurrencyResponse,
    (request: currency_pb.UpdateCurrencyRequest) => {
      return request.serializeBinary();
    },
    currency_pb.UpdateCurrencyResponse.deserializeBinary
  );

  update(
    request: currency_pb.UpdateCurrencyRequest,
    metadata: grpcWeb.Metadata | null): Promise<currency_pb.UpdateCurrencyResponse>;

  update(
    request: currency_pb.UpdateCurrencyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: currency_pb.UpdateCurrencyResponse) => void): grpcWeb.ClientReadableStream<currency_pb.UpdateCurrencyResponse>;

  update(
    request: currency_pb.UpdateCurrencyRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: currency_pb.UpdateCurrencyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/currency_endpoint.CurrencyEndpoint/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/currency_endpoint.CurrencyEndpoint/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/currency_endpoint.CurrencyEndpoint/Delete',
    grpcWeb.MethodType.UNARY,
    currency_pb.DeleteCurrencyRequest,
    currency_pb.DeleteCurrencyResponse,
    (request: currency_pb.DeleteCurrencyRequest) => {
      return request.serializeBinary();
    },
    currency_pb.DeleteCurrencyResponse.deserializeBinary
  );

  delete(
    request: currency_pb.DeleteCurrencyRequest,
    metadata: grpcWeb.Metadata | null): Promise<currency_pb.DeleteCurrencyResponse>;

  delete(
    request: currency_pb.DeleteCurrencyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: currency_pb.DeleteCurrencyResponse) => void): grpcWeb.ClientReadableStream<currency_pb.DeleteCurrencyResponse>;

  delete(
    request: currency_pb.DeleteCurrencyRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: currency_pb.DeleteCurrencyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/currency_endpoint.CurrencyEndpoint/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/currency_endpoint.CurrencyEndpoint/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorGetCurrency = new grpcWeb.MethodDescriptor(
    '/currency_endpoint.CurrencyEndpoint/GetCurrency',
    grpcWeb.MethodType.UNARY,
    currency_pb.GetCurrencyRequest,
    currency_pb.GetCurrencyResponse,
    (request: currency_pb.GetCurrencyRequest) => {
      return request.serializeBinary();
    },
    currency_pb.GetCurrencyResponse.deserializeBinary
  );

  getCurrency(
    request: currency_pb.GetCurrencyRequest,
    metadata: grpcWeb.Metadata | null): Promise<currency_pb.GetCurrencyResponse>;

  getCurrency(
    request: currency_pb.GetCurrencyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: currency_pb.GetCurrencyResponse) => void): grpcWeb.ClientReadableStream<currency_pb.GetCurrencyResponse>;

  getCurrency(
    request: currency_pb.GetCurrencyRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: currency_pb.GetCurrencyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/currency_endpoint.CurrencyEndpoint/GetCurrency',
        request,
        metadata || {},
        this.methodDescriptorGetCurrency,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/currency_endpoint.CurrencyEndpoint/GetCurrency',
    request,
    metadata || {},
    this.methodDescriptorGetCurrency);
  }

  methodDescriptorGetCurrencies = new grpcWeb.MethodDescriptor(
    '/currency_endpoint.CurrencyEndpoint/GetCurrencies',
    grpcWeb.MethodType.UNARY,
    currency_pb.GetCurrenciesRequest,
    currency_pb.GetCurrenciesResponse,
    (request: currency_pb.GetCurrenciesRequest) => {
      return request.serializeBinary();
    },
    currency_pb.GetCurrenciesResponse.deserializeBinary
  );

  getCurrencies(
    request: currency_pb.GetCurrenciesRequest,
    metadata: grpcWeb.Metadata | null): Promise<currency_pb.GetCurrenciesResponse>;

  getCurrencies(
    request: currency_pb.GetCurrenciesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: currency_pb.GetCurrenciesResponse) => void): grpcWeb.ClientReadableStream<currency_pb.GetCurrenciesResponse>;

  getCurrencies(
    request: currency_pb.GetCurrenciesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: currency_pb.GetCurrenciesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/currency_endpoint.CurrencyEndpoint/GetCurrencies',
        request,
        metadata || {},
        this.methodDescriptorGetCurrencies,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/currency_endpoint.CurrencyEndpoint/GetCurrencies',
    request,
    metadata || {},
    this.methodDescriptorGetCurrencies);
  }

}

