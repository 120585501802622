import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, refreshSession, getSession, ACCOUNTANT_API_URL } from '../../../../app/Api';
import { ReservationStatus } from '../../../../app/Enums';
import { formatDate, timestampToDate } from '../../../../app/Helpers';
import { AccountEndpointClient } from '../../../../repository/Accountant/AccountServiceClientPb';
import { GetAccountRequest, GetAccountsRequest, GetAccountsResponse , GetAccountResponse} from '../../../../repository/Accountant/account_pb';
import { BalanceReservationEndpointClient } from '../../../../repository/Accountant/Balance_reservationServiceClientPb';
import {  FreeAmountRequest, UpdateBalanceReservationResponse, FreeAmountResponse, GetBalanceReservationsResponse, GetBalanceReservationsRequest, DeleteReservationRequest, DeleteReservationResponse, UpdateBalanceReservationReplay, UpdateBalanceReservationRequest } from '../../../../repository/Accountant/balance_reservation_pb';
import { DynamicField } from '../../../common/DynamicFields/DynamicFields';
import { TableState } from '../../../common/Table/TableSate';


export interface ReservedBalancesPageState {

    set: TableState
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    filters: any[],
    accounts: TableState
    searchText: string | undefined,
    reasonFilterOption: { key: number, text: string },
    statusFilterOption: { key: number, text: string },
    account: any,
    triggeredbyTransactionFilterOption: { value: string, text: string },
    referenceTransactionFilterOption: { value: string, text: string },


}

const initialState: ReservedBalancesPageState = {
    set: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    isChangeStateLoading: false,
    message: undefined,
    searchText: undefined,
    filters: [],
    accounts: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    reasonFilterOption: { key: 0, text: "" },
    statusFilterOption: { key: 0, text: "" },
    account: undefined,
    triggeredbyTransactionFilterOption: { text: "", value: "" },
    referenceTransactionFilterOption: { text: "", value: "" },

}

const client = new BalanceReservationEndpointClient(ACCOUNTANT_API_URL, null, null);
const accountClient = new AccountEndpointClient(ACCOUNTANT_API_URL, null, null);

export const getItems = createAsyncThunk<GetBalanceReservationsResponse.AsObject, APIRequest<GetBalanceReservationsRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/reservedBalances/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await client.getBalanceReservations(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

export const getAccount = createAsyncThunk<GetAccountResponse.AsObject, APIRequest<GetAccountRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/reservedBalances/account/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await accountClient.getAccount(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)


export const getAccounts = createAsyncThunk<GetAccountsResponse.AsObject, APIRequest<GetAccountsRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/reservedBalances/accounts/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await accountClient.getAccounts(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

export const freeBalance = createAsyncThunk<APIResponse<FreeAmountResponse.AsObject>, APIRequest<FreeAmountRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/reservedBalances/freeBalance',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.freeAmount(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)





export const deleteItem = createAsyncThunk<APIResponse<DeleteReservationResponse.AsObject>, APIRequest<DeleteReservationRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/reservedBalances/delete',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.delete(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)


const getFilters = (state: ReservedBalancesPageState): any[] => {
    const selected = [];
    
    if (state.accounts.selected.length > 0) {
        selected.push({ key: 1, name: state.accounts.selected.at(0).name });
    }

    if (state.statusFilterOption.key != 0) {
        selected.push({ key: 2, name: state.statusFilterOption.text });
    }

    if (state.reasonFilterOption.key != 0) {
        selected.push({ key: 3, name: state.reasonFilterOption.text });
    }


    if (state.triggeredbyTransactionFilterOption.value && state.triggeredbyTransactionFilterOption.value != "") {
        selected.push({ key: 4, name: state.triggeredbyTransactionFilterOption.text });
    }
    if (state.referenceTransactionFilterOption.value && state.triggeredbyTransactionFilterOption.value != "") {
        selected.push({ key: 5, name: state.referenceTransactionFilterOption.text });
    }

   
    return selected;
}

export const reservedBalancesPageSlice = createSlice({
    name: 'pages/accountant/reservedBalances',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        
        setMessage: (state, action: PayloadAction<any>) => {
            state.message = action.payload;;
        },

        reset: (state) => {
            state.message = initialState.message;
            state.set = initialState.set;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
            state.searchText = initialState.searchText;
            state.accounts = initialState.accounts;
            state.statusFilterOption = initialState.statusFilterOption;
            state.reasonFilterOption = initialState.reasonFilterOption;
            state.filters = initialState.filters;
            state.account = initialState.account;
            state.triggeredbyTransactionFilterOption = initialState.triggeredbyTransactionFilterOption;
            state.referenceTransactionFilterOption = initialState.referenceTransactionFilterOption;


        },
        resetSet: (state) => {
            state.set.items = [];
            state.set.hasMore = true;
        },

        setSort: (state, action: PayloadAction<boolean>) => {
            state.set.isDescending = action.payload;
        },
        setNumberOfResults: (state, action: PayloadAction<number>) => {
            state.set.numberOfResults = action.payload;
        },
        setSelectedItems: (state, action: PayloadAction<any[]>) => {
            state.set.selected = action.payload;
        },
        setSearchText: (state, action: PayloadAction<string | undefined>) => {
            state.searchText = action.payload;
        },
        setIsFilteredSet: (state, action: PayloadAction<boolean>) => {
            state.set.isFilteredSet = action.payload;
        },
        setStatusFilterOption: (state, action: PayloadAction<{ key: number, text: string }>) => {
            state.statusFilterOption = action.payload;
            state.filters = getFilters(state);
        },
        setReasonFilterOption: (state, action: PayloadAction<{ key: number, text: string }>) => {
            state.reasonFilterOption = action.payload;
            state.filters = getFilters(state);
        },
        resetAccountsSet: (state) => {
            state.accounts.items = [];
            state.accounts.hasMore = true;
        },

        setSelectedAccountItems: (state, action: PayloadAction<any[]>) => {
            state.accounts.selected = action.payload;
            state.filters = getFilters(state);

        },

        setIsFilteredAccountSet: (state, action: PayloadAction<boolean>) => {
            state.accounts.isFilteredSet = action.payload;
        },

        setTriggeredbyTransactionFilterOption: (state, action: PayloadAction<{ value: string, text: string }>) => {
            state.triggeredbyTransactionFilterOption = action.payload;
            state.filters = getFilters(state);
        },
        setReferenceTransactionFilterOption: (state, action: PayloadAction<{ value: string, text: string }>) => {
            state.referenceTransactionFilterOption = action.payload;
            state.filters = getFilters(state);
        },
        addItem: (state, action: PayloadAction<any>) => {
            if (!state.set.isFilteredSet) {
                if (!state.set.hasMore && !state.set.isDescending) {
                    state.set.items.push(action.payload);
                } else if (state.set.isDescending) {
                    state.set.items.unshift(action.payload);
                }
            }
        },
        updateItem: (state, action: PayloadAction<any>) => {
            let selected = state.set.selected;
            if (action?.payload) {
                if (selected.length > 0 && selected.at(0).id == action?.payload?.id) {
                    selected[0] = action?.payload
                }
                var u = state.set.items.findIndex(e => e.id == action?.payload?.id)
                if (u >= 0) {
                    state.set.items[u] = action?.payload
                }
            }

        }
    },

    extraReducers: (builder) => {
        builder.addCase(getItems.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.balancereservationsList.map(val => {
                 
                    return {
                        id: val?.id,
                        accountId: val?.account?.id?.value??  "",
                        accountName: val?.account?.name?.value ?? "",
                        currencyId: val?.account?.currency?.id?.value ?? "",
                        currencyName: val?.account?.currency?.name?.value ?? "",
                        currencyCode: val?.account?.currency?.code?.value ?? "",
                        currencyDecimalPlaces: val?.account?.currency?.decimalplaces?.value ?? undefined,
                        currencyForm: val?.account?.currency?.form?.value ?? undefined,
                        currencySymbol: val?.account?.currency?.symbol?.value ?? "",
                        amount: val.amount?.value ?? undefined,
                        reason: val.reason?.value ?? undefined,
                        note: val.note?.value ?? undefined,
                        statusCreatedBy: (val.status?.createdby?.firstname?.value ?? "") + " " + (val.status?.createdby?.lastname?.value ?? ""),
                        statusCreatedDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        status: val.status?.status?.value ?? undefined,
                        statusTransactionId: val.status?.createdtransaction?.id ?? undefined,
                        statusTransactionInternalId: val.status?.createdtransaction?.internalid ?? "",
                        referenceTransactionId: val.referencetransaction?.id?.value ?? undefined,
                        referenceTransactionInternalId: val.referencetransaction?.internalid?.value ?? "",
                        triggeredbyTransactionId: val.triggeredby?.id?.value ?? undefined,
                        triggeredbyTransactionInternalId: val.triggeredby?.internalid?.value ?? "",

                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                let l = [];
                if (state.set.items.length != 0) {
                    l = state.set.items.slice(0, state.set.items.length - 10)
                }
                l = l.concat(r);
                if (r.length < state.set.numberOfResults) {
                    state.set.hasMore = false;
                } else {
                    l = l.concat(...Array(10).fill(null));
                }
                state.set.items = l
            } else {
                state.set.hasMore = false;
            }
            state.set.isFetching = false

        })
        builder.addCase(getItems.rejected, (state, action) => {
            state.set.isFetching = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getItems.pending, (state, action) => {
            state.set.isFetching = true;
            state.message = undefined;
        })

        builder.addCase(getAccounts.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.accountsList.map(val => {

                    return {
                        id: val.id,
                        internalId: val.internalid,
                        name: val.name?.value ?? "",
                        code: val.code?.value ?? "",
                        currencyId: val.currency?.id?.value ?? "",
                        currencyName: val.currency?.name?.value ?? "",
                        currencyCode: val.currency?.code?.value ?? "",
                        currencyDecimalPlaces: val.currency?.decimalplaces?.value ?? undefined,
                        currencyForm: val.currency?.form?.value ?? undefined,
                        currencySymbol: val.currency?.symbol?.value ?? "",
                        accountTypeId: val.accounttype?.id?.value ?? "",
                        accountTypeName: val.accounttype?.name?.value ?? "",
                        mirrorBalance: val.mirrorbalance?.value ?? undefined,
                        status: val.status?.value ?? undefined,
                        ownerFirstname: val.owner?.firstname?.value ?? "",
                        ownerLastname: val.owner?.lastname?.value ?? "",
                        ownerMiddlename: val.owner?.middlename?.value ?? "",
                        ownerName: (val.owner?.firstname?.value ?? "") + " " + (val.owner?.lastname?.value ?? ""),
                        ownerId: val.owner?.id?.value ?? "",
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                                                isAuthorizationRequired: val?.accountsetting?.isauthorizationrequired,
                        isLinkingEnable: val?.accountsetting?.islinkingenable?.value,
                        isLinkingMetadataSet: val?.accountsetting?.islinkingmetadataset?.value,
                        transferControl: val?.accountsetting?.tansfercontrol?.value ,


                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.accounts.numberOfResults) {
                    state.accounts.hasMore = false;
                }
                state.accounts.items = state.accounts.items.concat(r);
            } else {
                state.accounts.hasMore = false;

            }
            state.accounts.isFetching = false

        })
        builder.addCase(getAccounts.rejected, (state, action) => {
            state.accounts.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getAccounts.pending, (state, action) => {
            state.accounts.isFetching = true;
            //state.message = undefined;
        })

        builder.addCase(getAccount.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false
            if (payload) {
                let r = {
                    id: payload.success?.id,
                    internalId: payload.success?.internalid,
                    name: payload.success?.name?.value ?? "",
                    code: payload.success?.code?.value ?? "",
                    currencyId: payload.success?.currency?.id?.value ?? "",
                    currencyName: payload.success?.currency?.name?.value ?? "",
                    currencyCode: payload.success?.currency?.code?.value ?? "",
                    currencyDecimalPlaces: payload.success?.currency?.decimalplaces?.value ?? undefined,
                    currencyForm: payload.success?.currency?.form?.value ?? undefined,
                    currencySymbol: payload.success?.currency?.symbol?.value ?? "",
                    accountTypeId: payload.success?.accounttype?.id?.value ?? "",
                    accountTypeName: payload.success?.accounttype?.name?.value ?? "",
                    status: payload.success?.status?.value ?? undefined,
                    ownerFirstname: payload.success?.owner?.firstname?.value ?? "",
                    ownerLastname: payload.success?.owner?.lastname?.value ?? "",
                    ownerMiddlename: payload.success?.owner?.middlename?.value ?? "",
                    ownerName: (payload.success?.owner?.firstname?.value ?? "") + " " + (payload.success?.owner?.lastname?.value ?? ""),
                    ownerId: payload.success?.owner?.id?.value ?? "",
                    createdDate: formatDate(timestampToDate(payload.success?.createddate?.seconds, payload.success?.createddate?.nanos)),
                    addedBy: (payload.success?.createdby?.firstname?.value ?? "") + " " + (payload.success?.createdby?.lastname?.value ?? ""),
                    addedById: payload.success?.createdby?.id?.value,
                    limitationId: payload.success?.limitation?.id?.value ?? "",
                    limitationName: payload.success?.limitation?.name?.value ?? "",
                    freeBalance: undefined,
                    reservedBalance: undefined,
                    totalBalance: undefined,
                    extraFields: [] as DynamicField[],
                    isAuthorizationRequired: payload.success?.accountsetting?.isauthorizationrequired,
                    isLinkingEnable: payload.success?.accountsetting?.islinkingenable?.value,
                    isLinkingMetadataSet: payload.success?.accountsetting?.islinkingmetadataset?.value,
                    transferControl: payload.success?.accountsetting?.tansfercontrol?.value ,

                }

                if (payload?.success?.extrafieldsList) {
                    let count = 0
                    payload?.success?.extrafieldsList?.forEach(element => {
                        r.extraFields.push({ key: element.key, label: element.label, type: element.type, value: element.value })
                        count++
                    });
                }


                state.account = r
            }
        })
        builder.addCase(getAccount.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getAccount.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })


        builder.addCase(deleteItem.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                let selected = state.set.selected;
                if (payload?.metadata) {
                    if (selected.length > 0 && selected.at(0).id == payload?.metadata.id) {
                        state.set.selected = []
                    }
                    var u = state.set.items.findIndex(e => e.id == payload?.metadata.id)
                    if (u >= 0) {
                        state.set.items.splice(u, 1);
                    }
                }
            }
        })
        builder.addCase(deleteItem.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(deleteItem.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

        builder.addCase(freeBalance.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                let selected = state.set.selected;
                if (payload?.metadata) {
                    if (selected.length > 0 && selected.at(0).id == payload?.metadata.id) {
                        selected.at(0).status = ReservationStatus.FREED
                    }
                    var u = state.set.items.findIndex(e => e.id == payload?.metadata.id)
                    if (u >= 0) {
                        state.set.items.at(u).status = ReservationStatus.FREED
                    }

                }
            }
        })
        builder.addCase(freeBalance.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(freeBalance.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })



    }
})

export const { reset, setMessage, dismissMessage, setSort, setNumberOfResults, resetSet, setReferenceTransactionFilterOption, setTriggeredbyTransactionFilterOption, setSelectedItems, setSearchText, setIsFilteredSet, addItem, updateItem, resetAccountsSet, setIsFilteredAccountSet,setReasonFilterOption,setSelectedAccountItems,setStatusFilterOption } = reservedBalancesPageSlice.actions

export default reservedBalancesPageSlice.reducer


