/**
 * @fileoverview gRPC-Web generated client stub for report_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as report_pb from './report_pb';


export class ReportEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetCurrencyExchangeRatesReport = new grpcWeb.MethodDescriptor(
    '/report_endpoint.ReportEndpoint/GetCurrencyExchangeRatesReport',
    grpcWeb.MethodType.UNARY,
    report_pb.GetCurrencyExchangeRatesReportRequest,
    report_pb.GetCurrencyExchangeRatesReportResponse,
    (request: report_pb.GetCurrencyExchangeRatesReportRequest) => {
      return request.serializeBinary();
    },
    report_pb.GetCurrencyExchangeRatesReportResponse.deserializeBinary
  );

  getCurrencyExchangeRatesReport(
    request: report_pb.GetCurrencyExchangeRatesReportRequest,
    metadata: grpcWeb.Metadata | null): Promise<report_pb.GetCurrencyExchangeRatesReportResponse>;

  getCurrencyExchangeRatesReport(
    request: report_pb.GetCurrencyExchangeRatesReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: report_pb.GetCurrencyExchangeRatesReportResponse) => void): grpcWeb.ClientReadableStream<report_pb.GetCurrencyExchangeRatesReportResponse>;

  getCurrencyExchangeRatesReport(
    request: report_pb.GetCurrencyExchangeRatesReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: report_pb.GetCurrencyExchangeRatesReportResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/report_endpoint.ReportEndpoint/GetCurrencyExchangeRatesReport',
        request,
        metadata || {},
        this.methodDescriptorGetCurrencyExchangeRatesReport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/report_endpoint.ReportEndpoint/GetCurrencyExchangeRatesReport',
    request,
    metadata || {},
    this.methodDescriptorGetCurrencyExchangeRatesReport);
  }

  methodDescriptorGetTradingReport = new grpcWeb.MethodDescriptor(
    '/report_endpoint.ReportEndpoint/GetTradingReport',
    grpcWeb.MethodType.UNARY,
    report_pb.GetTradingReportRequest,
    report_pb.GetTradingReportResponse,
    (request: report_pb.GetTradingReportRequest) => {
      return request.serializeBinary();
    },
    report_pb.GetTradingReportResponse.deserializeBinary
  );

  getTradingReport(
    request: report_pb.GetTradingReportRequest,
    metadata: grpcWeb.Metadata | null): Promise<report_pb.GetTradingReportResponse>;

  getTradingReport(
    request: report_pb.GetTradingReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: report_pb.GetTradingReportResponse) => void): grpcWeb.ClientReadableStream<report_pb.GetTradingReportResponse>;

  getTradingReport(
    request: report_pb.GetTradingReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: report_pb.GetTradingReportResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/report_endpoint.ReportEndpoint/GetTradingReport',
        request,
        metadata || {},
        this.methodDescriptorGetTradingReport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/report_endpoint.ReportEndpoint/GetTradingReport',
    request,
    metadata || {},
    this.methodDescriptorGetTradingReport);
  }

  methodDescriptorExportCurrencyExchangeRatesReport = new grpcWeb.MethodDescriptor(
    '/report_endpoint.ReportEndpoint/ExportCurrencyExchangeRatesReport',
    grpcWeb.MethodType.UNARY,
    report_pb.ExportCurrencyExchangeRatesReportRequest,
    report_pb.ExportCurrencyExchangeRatesReportResponse,
    (request: report_pb.ExportCurrencyExchangeRatesReportRequest) => {
      return request.serializeBinary();
    },
    report_pb.ExportCurrencyExchangeRatesReportResponse.deserializeBinary
  );

  exportCurrencyExchangeRatesReport(
    request: report_pb.ExportCurrencyExchangeRatesReportRequest,
    metadata: grpcWeb.Metadata | null): Promise<report_pb.ExportCurrencyExchangeRatesReportResponse>;

  exportCurrencyExchangeRatesReport(
    request: report_pb.ExportCurrencyExchangeRatesReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: report_pb.ExportCurrencyExchangeRatesReportResponse) => void): grpcWeb.ClientReadableStream<report_pb.ExportCurrencyExchangeRatesReportResponse>;

  exportCurrencyExchangeRatesReport(
    request: report_pb.ExportCurrencyExchangeRatesReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: report_pb.ExportCurrencyExchangeRatesReportResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/report_endpoint.ReportEndpoint/ExportCurrencyExchangeRatesReport',
        request,
        metadata || {},
        this.methodDescriptorExportCurrencyExchangeRatesReport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/report_endpoint.ReportEndpoint/ExportCurrencyExchangeRatesReport',
    request,
    metadata || {},
    this.methodDescriptorExportCurrencyExchangeRatesReport);
  }

  methodDescriptorExportTradingReport = new grpcWeb.MethodDescriptor(
    '/report_endpoint.ReportEndpoint/ExportTradingReport',
    grpcWeb.MethodType.UNARY,
    report_pb.ExportTradingReportRequest,
    report_pb.ExportTradingReportResponse,
    (request: report_pb.ExportTradingReportRequest) => {
      return request.serializeBinary();
    },
    report_pb.ExportTradingReportResponse.deserializeBinary
  );

  exportTradingReport(
    request: report_pb.ExportTradingReportRequest,
    metadata: grpcWeb.Metadata | null): Promise<report_pb.ExportTradingReportResponse>;

  exportTradingReport(
    request: report_pb.ExportTradingReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: report_pb.ExportTradingReportResponse) => void): grpcWeb.ClientReadableStream<report_pb.ExportTradingReportResponse>;

  exportTradingReport(
    request: report_pb.ExportTradingReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: report_pb.ExportTradingReportResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/report_endpoint.ReportEndpoint/ExportTradingReport',
        request,
        metadata || {},
        this.methodDescriptorExportTradingReport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/report_endpoint.ReportEndpoint/ExportTradingReport',
    request,
    metadata || {},
    this.methodDescriptorExportTradingReport);
  }

}

