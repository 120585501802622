import { CommandBar, ConstrainMode, DetailsRow, IColumn, IColumnDragDropDetails, IColumnReorderOptions, ICommandBarItemProps, IDetailsColumnRenderTooltipProps, IDetailsFooterProps, IDetailsHeaderProps, IDetailsListStyles, IRenderFunction, IScrollablePaneStyles, IShimmeredDetailsListProps, mergeStyleSets, ScrollablePane, SelectionMode, ShimmeredDetailsList, Stack, Sticky, StickyPositionType, TooltipHost } from "@fluentui/react"
import { ReactElement, RefObject, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

interface ITableProps {
    isColumnSortingEnabled: boolean
    height?: string,
    width?: string,
    numberOfResultsOptions?: number[],
    onNumberOfResultsChange?: (e: number) => void,
    selectedNumberOfResults?: number
    onScroll?: (e: any) => void,
    compRef?: RefObject<HTMLDivElement>

}

export const Table: React.FunctionComponent<IShimmeredDetailsListProps & ITableProps> = (props) => {
    const [columns, setColumns] = useState(props.columns ?? []);
    const [items, setItems] = useState(props.items);
    const { t, i18n } = useTranslation();

    function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => {
            if (a == null || b == null) { return -1; }

            let aN: any = Number(a[key]);
            let bN: any = Number(b[key]);



            if (isNaN(aN)) {
                aN = a[key];
            }
            if (isNaN(bN)) {
                bN = b[key];
            }
            return ((isSortedDescending ? aN < bN : aN > bN) ? 1 : -1)


        });
    }

    const _onColumnClick = (ev?: React.MouseEvent<HTMLElement>, column?: IColumn): void => {
        //const { columns, items } = this.state;
        if (columns) {


            let isSortedDescending = column?.isSortedDescending;

            // If we've sorted this column, flip it.
            if (column?.isSorted) {
                isSortedDescending = !isSortedDescending;

            }

            // Sort the items.
            var i = _copyAndSort(items, column?.key!, isSortedDescending);
            // Reset the items and columns to match the state.
            setItems(i)
            setColumns(columns.map(col => {
                col.isSorted = col.key === column?.key;

                if (col.isSorted) {
                    col.isSortedDescending = isSortedDescending;
                }

                return col;
            }))

        }
    };

    const _handleColumnReorder = (dragDropDetails: IColumnDragDropDetails) => {
        if (columns) {
            const draggedItems = columns[dragDropDetails.draggedIndex];
            const newColumns: IColumn[] = [...columns];

            // insert before the dropped item
            newColumns.splice(dragDropDetails.draggedIndex, 1);
            newColumns.splice(dragDropDetails.targetIndex, 0, draggedItems);
            setColumns(newColumns);
        }
    };

    const _getColumnReorderOptions = (): IColumnReorderOptions => {
        return {
            frozenColumnCountFromStart: 0,
            frozenColumnCountFromEnd: 0,
            onColumnDrop: _handleColumnReorder,
        };
    }
    useEffect(() => {
        setItems(props.items)

    }, [props.items])


    const getNumberOfResults = (): any[] => {
        let set: any = [];
        props.numberOfResultsOptions?.forEach(function (value) {
            set.push({
                key: value + "",
                text: value + "",
                title: value + "",
                canCheck: true,
                checked: (props.selectedNumberOfResults == value ? true : false),
                onClick: () => {
                    if (props.onNumberOfResultsChange) {
                        props.onNumberOfResultsChange(value)
                    }
                }

            })
        });

        return set;
    }
    const _farBottomOptions: ICommandBarItemProps[] = [
        {
            key: 'results',
            text: t('results') + ': ' + props.selectedNumberOfResults,
            title: t('results') + ': ' + props.selectedNumberOfResults,
            iconProps: { iconName: 'ShowResults' },
            subMenuProps: {
                items: getNumberOfResults()
            }
        }
    ];


    const onRenderDetailsFooter: IRenderFunction<IDetailsFooterProps> = (props, defaultRender) => {
        if (!props) {
            return null;
        }
        return (<Sticky  stickyPosition={StickyPositionType.Footer}>
                
                <CommandBar
                    items={[]}
                    farItems={_farBottomOptions}

                />
            </Sticky>
               
            

        );
    };

    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
        if (!props) {
            return null;
        }
        return (
            <Sticky stickyPosition={StickyPositionType.Header}    >
                {defaultRender!({
                    ...props,

                })}
            </Sticky>

        );
    };

    const classNames = mergeStyleSets({
        wrapper: {
            height: props?.height,
            width: props?.width,
            position: 'relative',
            maxHeight: 'inherit',
        },

    });


    return (
        <div className={classNames.wrapper} ref={props?.compRef} >
            <ScrollablePane
                scrollContainerFocus={true}
                onScroll={(e) => {
                    if (props?.onScroll)
                        props?.onScroll(e);

                }}

            >
                <ShimmeredDetailsList
                    {...props}
                    columns={columns}
                    items={items}
                    onColumnHeaderClick={(props.isColumnSortingEnabled) ? _onColumnClick : undefined}
                    columnReorderOptions={_getColumnReorderOptions()}
                    onRenderDetailsFooter={props.numberOfResultsOptions  && props?.items.length >= 10?  onRenderDetailsFooter : undefined}
                    onRenderDetailsHeader={onRenderDetailsHeader}
                />
            </ScrollablePane>
        </div>
        /*<div style={{
            height: "calc(100vh - 200px)", overflowY: "scroll"
        }} onScroll={() => console.log(1)}></div>
        
         
        */
    );
}

