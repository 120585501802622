import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ISearchBoxProps, CheckboxVisibility } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../../../app/Hooks"
import { getPage } from "../../../../../../app/Pages"
import { setPage } from "../../../../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../../../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../../../../common/Table/Table"
import { ApiMessage, getHeaders } from "../../../../../../app/Api"
import { useId, useBoolean } from '@fluentui/react-hooks';
import * as google_protobuf_wrappers_pb from 'google-protobuf/google/protobuf/wrappers_pb'
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { getMainTheme } from "../../../../../../app/Themes"
import { SearchField } from "../../../../../common/SearchField/SearchField"
import { formatDate, printComponent, normalizeKey } from "../../../../../../app/Helpers"
import { TableState } from "../../../../../common/Table/TableSate"
import { Message } from "../../../../../common/Message/Message"
import { ShareBox } from "../../../../../common/ShareBox/ShareBox"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { bool, string } from "yup"
import { Popup } from "../../../../../common/Popup/Popup"
import { FormType } from "../../../../../forms/FormProps"
import { DeleteFeeRequest, GetFeeRequest, GetFeesRequest, RemoveFeeCollectionAccountReplay, RemoveFeeCollectionAccountRequest, UpdateFeeActivityRequest, UpdateFeeCollectionAccountActivityRequest, UpdateFeeCollectionAccountActivityResponse, UpdateFeeRequest } from "../../../../../../repository/Accountant/fee_pb"
import { FeesForm } from "../../../../../forms/Accountant/Fees/FeesForm"
import { CollectionAccountsForm } from "../../../../../forms/Accountant/Fees/FeeCollectionAccountsForm/FeeCollectionAccountsForm"
import { get, reset, setCollectionAccounts, setSelectedItems, addItem, dismissMessage, setIsFilteredSet, removeCollectionAccount, resetSet, setSearchText, setNumberOfResults, setSort, updateCollectionAccountActivity, updateItem } from "./FeeCollectionAccountsPageSlice"
import { useReactToPrint } from "react-to-print"
import { PageProps } from "../../../../PageProps"

const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};


let req: GetFeeRequest;
let org: number = -1;

let getPromise: any;
let actionPromise: any;

interface FeeCollectionAccountsPageProps {
    fee: any
}
export const FeeCollectionAccountsPage: React.FunctionComponent<FeeCollectionAccountsPageProps & PageProps> = (props) => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    let { id } = useParams();

    const state: {
        isChangeStateLoading: boolean,
        message: ApiMessage | undefined,
        appTheme: string, isDarkModeEnabled: boolean,
        isLoading: boolean,
        set: TableState,

    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.feeCollectionAccountsPage.isChangeStateLoading,
            message: state.feeCollectionAccountsPage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            isLoading: state.feeCollectionAccountsPage.isLoading,
            set: state.feeCollectionAccountsPage.collectionAccounts,

        }
    })
    const calloutTargetId = useId('callout-target');
    const labelId = useId('callout-label');
    const deleteActionConfirmationcalloutTargetId = useId('delete-action-callout-target');
    const activityActionConfirmationcalloutTargetId = useId('activity-action-callout-target');
    const ActionConfirmationLabelId = useId('action-callout-label');
    const shareCalloutLabelId = useId('share-callout-label');
    const shareTargetId = useId('share-callout-target');

    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const [isActionConfirmationCalloutVisible, { toggle: toggleisActionConfirmationCalloutVisible }] = useBoolean(false);
    const [isShareCalloutVisible, { toggle: toggleisShareCalloutVisible }] = useBoolean(false);

    const [currentAction, setCurrenctAction] = useState(0)
    const ref = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => {
            return printComponent(ref.current?.cloneNode(true), true)
        }

    });

    const getActionId = (action: number): string => {
        if (action == 1) {
            return deleteActionConfirmationcalloutTargetId;
        } else if (action == 2) {
            return activityActionConfirmationcalloutTargetId;
        } else {
            return ""
        }
    }

    const _selection = useConst(() => new Selection({
        onSelectionChanged: () => {
            if (_selection.count > 0) {
                dispatch(setSelectedItems(_selection.getSelection()));
            } else {
                dispatch(setSelectedItems([]));
            }
        },

    }));

    const clearFilter = () => {

    }

    const search = (searchText: string | undefined) => {


    }

    useEffect(() => {
        req = new GetFeeRequest();
        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                //req.setOrganization(wrapper)
            }
        }
        req.setId(props.fee?.id)
        getPromise = dispatch(get({ body: req, headers: getHeaders() }))

        return () => { //clean up
            getPromise?.abort();
            actionPromise?.abort();
            dispatch(reset());
        }
    }, [])


    const c: IColumn[] = [

        {
            key: 'name',
            name: t("collectionAccount"),
            minWidth: 20,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {

                return <Link onClick={(e) => {
                    if (e && !props.isSelection) {
                        e.preventDefault();
                        navigate("/accounts/" + item?.id + ((org == -1) ? "" : "?org=" + org));
                    }
                }
                } href={"/accounts/" + item?.id + ((org == -1) ? "" : "?org=" + org)} target={props.isSelection ? "_blank" : undefined}> {item?.name == "" ? item?.id : item?.name}</Link >;
                //return <Link href="/" underline >{item.name}</Link>;
            },
        },


        {
            key: 'currency',
            name: t("currency"),
            minWidth: 20,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <Link onClick={(e) => {
                    if (e && !props.isSelection) {
                        e.preventDefault();
                        navigate("/currencies/" + item?.currencyId + ((org == -1) ? "" : "?org=" + org));
                    }
                }
                } href={"/currencies/" + item?.currencyId + ((org == -1) ? "" : "?org=" + org)} target={props.isSelection ? "_blank" : undefined}> {item?.currencyName == "" ? item?.currencyId : item?.currencyName + " (" + item?.currencyCode + ")"}</Link >;
                //return <Link href="/" underline >{item.name}</Link>;
            },
        },


        {
            key: 'isActive',
            name: t("status"),
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.isActive != undefined)
                    return <Text>{item?.isActive ? t("active") : t("inactive")}</Text>;
            },
        },




        {
            key: 'createdDate',
            name: t("createdDate"),
            minWidth: 20,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {


                return <Text>{(item?.createdDate)}</Text>; //{item.createdDate}
            },
        },

        {
            key: 'addedBy',
            name: t("addedByColumn"),
            minWidth: 20,
            maxWidth: 250,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <Link onClick={(e) => {
                    if (e && !props.isSelection) {
                        e.preventDefault();
                        navigate("/management/users/" + item?.addedById + ((org == -1) ? "" : "?org=" + org));
                    }
                }
                } href={"/management/users/" + item?.addedById + ((org == -1) ? "" : "?org=" + org)} target={props.isSelection ? "_blank" : undefined}><Persona
                        text={item?.addedBy == "" ? item?.addedById : item?.addedBy}
                        size={PersonaSize.size40}
                    /></Link>

            },
        }


    ];

    const _selectionItems: ICommandBarItemProps[] = [

        {
            key: 'status',
            text: (state.set.selected?.at(0)?.isActive) ? t("deactivate") : t("activate"),
            title: (state.set.selected?.at(0)?.isActive) ? t("deactivate") : t("activate"),
            iconProps: { id: activityActionConfirmationcalloutTargetId, iconName: (state.set.selected?.at(0)?.isActive) ? "Cancel" : "Accept", styles: { root: { fontWeight: (state.set.selected?.at(0)?.isActive) ? undefined : "bold" } } },

            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 2 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 2 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 2 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(2)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },
        {
            key: 'remove',
            text: t("removeCollectionAccount"),
            title: t("removeCollectionAccount"),
            iconProps: { id: deleteActionConfirmationcalloutTargetId, iconName: "Remove", styles: { root: { fontWeight: "bold" } } },

            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 1 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 1 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 1 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(1)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },


    ];

    const mainOptions: ICommandBarItemProps[] = [
        {
            key: 'newItem',
            text: t('add'),
            title: t("add"),
            iconProps: { iconName: 'Add' },
            onClick: () => {
                //  navigate("add")
                setCurrenctAction(100)
            }
        },
        {
            key: 'refresh',
            text: t('refresh'),
            title: t("refresh"),
            iconProps: { iconName: 'refresh' },
            disabled: state.set.isFetching,
            onClick: () => {
                dispatch(resetSet())
                getPromise = dispatch(get({ body: req, headers: getHeaders() }))

            }
        },
        {
            key: 'print',
            text: t("print"),
            iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
            title: t("print"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    handlePrint();
                }
            }
        },
    ];

    return (
        <Stack >
            <Popup isOpen={(currentAction == 100)} title={t("addCollectionAccount")} onDismiss={() => { setCurrenctAction(0) }} >
                <CollectionAccountsForm childrenGap={10} maxWidth={320} type={FormType.ADD} renderObject={props.fee} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(addItem(e))

                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>



            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }
            <CommandBar
                items={state.set.selected.length == 0 ? mainOptions : _selectionItems}

            //id={actionConfirmationcalloutTargetId}


            />


            {isActionConfirmationCalloutVisible ? (
                <Callout
                    ariaLabelledBy={ActionConfirmationLabelId}
                    target={`#${getActionId(currentAction)}`}
                    onDismiss={toggleisActionConfirmationCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <Stack tokens={{ padding: '20px 24px ' }}>
                        <Text block variant="xLarge" styles={{ root: { marginBottom: 12, fontWeight: FontWeights.semilight } }}>
                            {(currentAction == 1 ? t("removeCollectionAccount") : currentAction == 2 ? (state.set.selected.at(0)?.isActive) ? t("deactivate") : t("activate") : "")}
                        </Text>
                        <Text block variant="small">
                            {(currentAction == 1 ? t("removeCollectionAccountDesc", { currency: state.set.selected.at(0)?.currencyName }) : currentAction == 2 ? (state.set.selected.at(0)?.isActive) ? t("deactivateCollectionAccountDesc", { currency: state.set.selected.at(0)?.currencyName }) : t("activateCollectionAccountDesc", { currency: state.set.selected.at(0)?.currencyName }) : "")}
                        </Text>
                        {/* This FocusZone allows the user to go between buttons with the arrow keys.
              It's not related to or required for FocusTrapCallout's built-in focus trapping. */}
                        <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                            <Stack reversed tokens={{ childrenGap: 8, padding: "20px 0px 0px 0px" }} horizontal>
                                <DefaultButton autoFocus onClick={toggleisActionConfirmationCalloutVisible}>{t("cancel")}</DefaultButton>

                                <PrimaryButton onClick={() => {
                                    toggleisActionConfirmationCalloutVisible()
                                    if (state.set.selected.length > 0) {
                                        if (currentAction == 1) {
                                            let req = new RemoveFeeCollectionAccountRequest();
                                            req.setId(props.fee.id)
                                            req.setAccount(state.set.selected.at(0)?.id)
                                            actionPromise?.abort();
                                            actionPromise = dispatch(removeCollectionAccount({ body: req, headers: getHeaders() }))
                                            actionPromise.unwrap().then((e: any) => {
                                                _selection.setAllSelected(false)
                                            })
                                        } else if (currentAction == 2) {
                                            let req = new UpdateFeeCollectionAccountActivityRequest();
                                            req.setId(props.fee.id)
                                            req.setAccount(state.set.selected.at(0)?.id)
                                            req.setIsactive(!state.set.selected.at(0)?.isActive)
                                            actionPromise?.abort();
                                            actionPromise = dispatch(updateCollectionAccountActivity({ body: req, headers: getHeaders() }))
                                            actionPromise.unwrap().then((e: any) => {
                                                _selection.setAllSelected(false)
                                            })
                                        }
                                    }
                                }} text={(currentAction == 1 ? t("remove") : currentAction == 2 ? (state.set.selected.at(0)?.isActive) ? t("deactivate") : t("activate") : "")}  >

                                </PrimaryButton>
                            </Stack>
                        </FocusZone>
                    </Stack>
                </Callout>
            ) : null}



            <Table
                setKey="collectionAccounts"
                items={state.set.items}
                columns={c}
                checkboxVisibility={props.isSelection ? CheckboxVisibility.always : CheckboxVisibility.onHover}
                width={props.isSelection ? '80vw' : undefined}
                height={props.isSelection ? "60vh" : state.message == undefined ? "calc(100vh - 225px)" : "calc(100vh - 273px)"} selection={_selection}
                selectionMode={SelectionMode.single}
                disableSelectionZone={state.isChangeStateLoading}
                enableShimmer={state.set.items.length == 0 && state.set.isFetching}
                isColumnSortingEnabled={state.set.selected.length == 0}
                layoutMode={DetailsListLayoutMode.justified}
                compRef={ref}


            />



        </Stack>



    );
}




