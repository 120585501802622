/**
 * @fileoverview gRPC-Web generated client stub for authorization_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as authorization_pb from './authorization_pb';


export class AuthorizationEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAdd = new grpcWeb.MethodDescriptor(
    '/authorization_endpoint.AuthorizationEndpoint/Add',
    grpcWeb.MethodType.UNARY,
    authorization_pb.AddAuthorizationRequest,
    authorization_pb.AddAuthorizationResponse,
    (request: authorization_pb.AddAuthorizationRequest) => {
      return request.serializeBinary();
    },
    authorization_pb.AddAuthorizationResponse.deserializeBinary
  );

  add(
    request: authorization_pb.AddAuthorizationRequest,
    metadata: grpcWeb.Metadata | null): Promise<authorization_pb.AddAuthorizationResponse>;

  add(
    request: authorization_pb.AddAuthorizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: authorization_pb.AddAuthorizationResponse) => void): grpcWeb.ClientReadableStream<authorization_pb.AddAuthorizationResponse>;

  add(
    request: authorization_pb.AddAuthorizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: authorization_pb.AddAuthorizationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/authorization_endpoint.AuthorizationEndpoint/Add',
        request,
        metadata || {},
        this.methodDescriptorAdd,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/authorization_endpoint.AuthorizationEndpoint/Add',
    request,
    metadata || {},
    this.methodDescriptorAdd);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/authorization_endpoint.AuthorizationEndpoint/Update',
    grpcWeb.MethodType.UNARY,
    authorization_pb.UpdateAuthorizationRequest,
    authorization_pb.UpdateAuthorizationResponse,
    (request: authorization_pb.UpdateAuthorizationRequest) => {
      return request.serializeBinary();
    },
    authorization_pb.UpdateAuthorizationResponse.deserializeBinary
  );

  update(
    request: authorization_pb.UpdateAuthorizationRequest,
    metadata: grpcWeb.Metadata | null): Promise<authorization_pb.UpdateAuthorizationResponse>;

  update(
    request: authorization_pb.UpdateAuthorizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: authorization_pb.UpdateAuthorizationResponse) => void): grpcWeb.ClientReadableStream<authorization_pb.UpdateAuthorizationResponse>;

  update(
    request: authorization_pb.UpdateAuthorizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: authorization_pb.UpdateAuthorizationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/authorization_endpoint.AuthorizationEndpoint/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/authorization_endpoint.AuthorizationEndpoint/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorGenerateTriggeredTransferToken = new grpcWeb.MethodDescriptor(
    '/authorization_endpoint.AuthorizationEndpoint/GenerateTriggeredTransferToken',
    grpcWeb.MethodType.UNARY,
    authorization_pb.GenerateTriggeredTransferTokenRequest,
    authorization_pb.GenerateTriggeredTransferTokenResponse,
    (request: authorization_pb.GenerateTriggeredTransferTokenRequest) => {
      return request.serializeBinary();
    },
    authorization_pb.GenerateTriggeredTransferTokenResponse.deserializeBinary
  );

  generateTriggeredTransferToken(
    request: authorization_pb.GenerateTriggeredTransferTokenRequest,
    metadata: grpcWeb.Metadata | null): Promise<authorization_pb.GenerateTriggeredTransferTokenResponse>;

  generateTriggeredTransferToken(
    request: authorization_pb.GenerateTriggeredTransferTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: authorization_pb.GenerateTriggeredTransferTokenResponse) => void): grpcWeb.ClientReadableStream<authorization_pb.GenerateTriggeredTransferTokenResponse>;

  generateTriggeredTransferToken(
    request: authorization_pb.GenerateTriggeredTransferTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: authorization_pb.GenerateTriggeredTransferTokenResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/authorization_endpoint.AuthorizationEndpoint/GenerateTriggeredTransferToken',
        request,
        metadata || {},
        this.methodDescriptorGenerateTriggeredTransferToken,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/authorization_endpoint.AuthorizationEndpoint/GenerateTriggeredTransferToken',
    request,
    metadata || {},
    this.methodDescriptorGenerateTriggeredTransferToken);
  }

  methodDescriptorGenerateSignatures = new grpcWeb.MethodDescriptor(
    '/authorization_endpoint.AuthorizationEndpoint/GenerateSignatures',
    grpcWeb.MethodType.UNARY,
    authorization_pb.GenerateSignaturesRequest,
    authorization_pb.GenerateSignaturesResponse,
    (request: authorization_pb.GenerateSignaturesRequest) => {
      return request.serializeBinary();
    },
    authorization_pb.GenerateSignaturesResponse.deserializeBinary
  );

  generateSignatures(
    request: authorization_pb.GenerateSignaturesRequest,
    metadata: grpcWeb.Metadata | null): Promise<authorization_pb.GenerateSignaturesResponse>;

  generateSignatures(
    request: authorization_pb.GenerateSignaturesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: authorization_pb.GenerateSignaturesResponse) => void): grpcWeb.ClientReadableStream<authorization_pb.GenerateSignaturesResponse>;

  generateSignatures(
    request: authorization_pb.GenerateSignaturesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: authorization_pb.GenerateSignaturesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/authorization_endpoint.AuthorizationEndpoint/GenerateSignatures',
        request,
        metadata || {},
        this.methodDescriptorGenerateSignatures,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/authorization_endpoint.AuthorizationEndpoint/GenerateSignatures',
    request,
    metadata || {},
    this.methodDescriptorGenerateSignatures);
  }

  methodDescriptorRevoke = new grpcWeb.MethodDescriptor(
    '/authorization_endpoint.AuthorizationEndpoint/Revoke',
    grpcWeb.MethodType.UNARY,
    authorization_pb.RevokeAuthorizationRequest,
    authorization_pb.RevokeAuthorizationResponse,
    (request: authorization_pb.RevokeAuthorizationRequest) => {
      return request.serializeBinary();
    },
    authorization_pb.RevokeAuthorizationResponse.deserializeBinary
  );

  revoke(
    request: authorization_pb.RevokeAuthorizationRequest,
    metadata: grpcWeb.Metadata | null): Promise<authorization_pb.RevokeAuthorizationResponse>;

  revoke(
    request: authorization_pb.RevokeAuthorizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: authorization_pb.RevokeAuthorizationResponse) => void): grpcWeb.ClientReadableStream<authorization_pb.RevokeAuthorizationResponse>;

  revoke(
    request: authorization_pb.RevokeAuthorizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: authorization_pb.RevokeAuthorizationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/authorization_endpoint.AuthorizationEndpoint/Revoke',
        request,
        metadata || {},
        this.methodDescriptorRevoke,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/authorization_endpoint.AuthorizationEndpoint/Revoke',
    request,
    metadata || {},
    this.methodDescriptorRevoke);
  }

  methodDescriptorGetAuthorizations = new grpcWeb.MethodDescriptor(
    '/authorization_endpoint.AuthorizationEndpoint/GetAuthorizations',
    grpcWeb.MethodType.UNARY,
    authorization_pb.GetAuthorizationsRequest,
    authorization_pb.GetAuthorizationsResponse,
    (request: authorization_pb.GetAuthorizationsRequest) => {
      return request.serializeBinary();
    },
    authorization_pb.GetAuthorizationsResponse.deserializeBinary
  );

  getAuthorizations(
    request: authorization_pb.GetAuthorizationsRequest,
    metadata: grpcWeb.Metadata | null): Promise<authorization_pb.GetAuthorizationsResponse>;

  getAuthorizations(
    request: authorization_pb.GetAuthorizationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: authorization_pb.GetAuthorizationsResponse) => void): grpcWeb.ClientReadableStream<authorization_pb.GetAuthorizationsResponse>;

  getAuthorizations(
    request: authorization_pb.GetAuthorizationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: authorization_pb.GetAuthorizationsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/authorization_endpoint.AuthorizationEndpoint/GetAuthorizations',
        request,
        metadata || {},
        this.methodDescriptorGetAuthorizations,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/authorization_endpoint.AuthorizationEndpoint/GetAuthorizations',
    request,
    metadata || {},
    this.methodDescriptorGetAuthorizations);
  }

  methodDescriptorGetAuthorization = new grpcWeb.MethodDescriptor(
    '/authorization_endpoint.AuthorizationEndpoint/GetAuthorization',
    grpcWeb.MethodType.UNARY,
    authorization_pb.GetAuthorizationRequest,
    authorization_pb.GetAuthorizationResponse,
    (request: authorization_pb.GetAuthorizationRequest) => {
      return request.serializeBinary();
    },
    authorization_pb.GetAuthorizationResponse.deserializeBinary
  );

  getAuthorization(
    request: authorization_pb.GetAuthorizationRequest,
    metadata: grpcWeb.Metadata | null): Promise<authorization_pb.GetAuthorizationResponse>;

  getAuthorization(
    request: authorization_pb.GetAuthorizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: authorization_pb.GetAuthorizationResponse) => void): grpcWeb.ClientReadableStream<authorization_pb.GetAuthorizationResponse>;

  getAuthorization(
    request: authorization_pb.GetAuthorizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: authorization_pb.GetAuthorizationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/authorization_endpoint.AuthorizationEndpoint/GetAuthorization',
        request,
        metadata || {},
        this.methodDescriptorGetAuthorization,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/authorization_endpoint.AuthorizationEndpoint/GetAuthorization',
    request,
    metadata || {},
    this.methodDescriptorGetAuthorization);
  }

}

