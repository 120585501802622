/**
 * @fileoverview gRPC-Web generated client stub for account_authorization_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as account_autorization_pb from './account_autorization_pb';


export class AccountAuthorizationEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAdd = new grpcWeb.MethodDescriptor(
    '/account_authorization_endpoint.AccountAuthorizationEndpoint/Add',
    grpcWeb.MethodType.UNARY,
    account_autorization_pb.AddAccountAuthorizationRequest,
    account_autorization_pb.AddAccountAuthorizationResponse,
    (request: account_autorization_pb.AddAccountAuthorizationRequest) => {
      return request.serializeBinary();
    },
    account_autorization_pb.AddAccountAuthorizationResponse.deserializeBinary
  );

  add(
    request: account_autorization_pb.AddAccountAuthorizationRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_autorization_pb.AddAccountAuthorizationResponse>;

  add(
    request: account_autorization_pb.AddAccountAuthorizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_autorization_pb.AddAccountAuthorizationResponse) => void): grpcWeb.ClientReadableStream<account_autorization_pb.AddAccountAuthorizationResponse>;

  add(
    request: account_autorization_pb.AddAccountAuthorizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_autorization_pb.AddAccountAuthorizationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_authorization_endpoint.AccountAuthorizationEndpoint/Add',
        request,
        metadata || {},
        this.methodDescriptorAdd,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_authorization_endpoint.AccountAuthorizationEndpoint/Add',
    request,
    metadata || {},
    this.methodDescriptorAdd);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/account_authorization_endpoint.AccountAuthorizationEndpoint/Update',
    grpcWeb.MethodType.UNARY,
    account_autorization_pb.UpdateAccountAuthorizationRequest,
    account_autorization_pb.UpdateAccountAuthorizationResponse,
    (request: account_autorization_pb.UpdateAccountAuthorizationRequest) => {
      return request.serializeBinary();
    },
    account_autorization_pb.UpdateAccountAuthorizationResponse.deserializeBinary
  );

  update(
    request: account_autorization_pb.UpdateAccountAuthorizationRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_autorization_pb.UpdateAccountAuthorizationResponse>;

  update(
    request: account_autorization_pb.UpdateAccountAuthorizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_autorization_pb.UpdateAccountAuthorizationResponse) => void): grpcWeb.ClientReadableStream<account_autorization_pb.UpdateAccountAuthorizationResponse>;

  update(
    request: account_autorization_pb.UpdateAccountAuthorizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_autorization_pb.UpdateAccountAuthorizationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_authorization_endpoint.AccountAuthorizationEndpoint/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_authorization_endpoint.AccountAuthorizationEndpoint/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/account_authorization_endpoint.AccountAuthorizationEndpoint/Delete',
    grpcWeb.MethodType.UNARY,
    account_autorization_pb.DeleteAccountAuthorizationRequest,
    account_autorization_pb.DeleteAccountAuthorizationResponse,
    (request: account_autorization_pb.DeleteAccountAuthorizationRequest) => {
      return request.serializeBinary();
    },
    account_autorization_pb.DeleteAccountAuthorizationResponse.deserializeBinary
  );

  delete(
    request: account_autorization_pb.DeleteAccountAuthorizationRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_autorization_pb.DeleteAccountAuthorizationResponse>;

  delete(
    request: account_autorization_pb.DeleteAccountAuthorizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_autorization_pb.DeleteAccountAuthorizationResponse) => void): grpcWeb.ClientReadableStream<account_autorization_pb.DeleteAccountAuthorizationResponse>;

  delete(
    request: account_autorization_pb.DeleteAccountAuthorizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_autorization_pb.DeleteAccountAuthorizationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_authorization_endpoint.AccountAuthorizationEndpoint/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_authorization_endpoint.AccountAuthorizationEndpoint/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorGetAccountAuthorization = new grpcWeb.MethodDescriptor(
    '/account_authorization_endpoint.AccountAuthorizationEndpoint/GetAccountAuthorization',
    grpcWeb.MethodType.UNARY,
    account_autorization_pb.GetAccountAuthorizationRequest,
    account_autorization_pb.GetAccountAuthorizationResponse,
    (request: account_autorization_pb.GetAccountAuthorizationRequest) => {
      return request.serializeBinary();
    },
    account_autorization_pb.GetAccountAuthorizationResponse.deserializeBinary
  );

  getAccountAuthorization(
    request: account_autorization_pb.GetAccountAuthorizationRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_autorization_pb.GetAccountAuthorizationResponse>;

  getAccountAuthorization(
    request: account_autorization_pb.GetAccountAuthorizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_autorization_pb.GetAccountAuthorizationResponse) => void): grpcWeb.ClientReadableStream<account_autorization_pb.GetAccountAuthorizationResponse>;

  getAccountAuthorization(
    request: account_autorization_pb.GetAccountAuthorizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_autorization_pb.GetAccountAuthorizationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_authorization_endpoint.AccountAuthorizationEndpoint/GetAccountAuthorization',
        request,
        metadata || {},
        this.methodDescriptorGetAccountAuthorization,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_authorization_endpoint.AccountAuthorizationEndpoint/GetAccountAuthorization',
    request,
    metadata || {},
    this.methodDescriptorGetAccountAuthorization);
  }

  methodDescriptorGetAccountAuthorizations = new grpcWeb.MethodDescriptor(
    '/account_authorization_endpoint.AccountAuthorizationEndpoint/GetAccountAuthorizations',
    grpcWeb.MethodType.UNARY,
    account_autorization_pb.GetAccountAuthorizationsRequest,
    account_autorization_pb.GetAccountAuthorizationsResponse,
    (request: account_autorization_pb.GetAccountAuthorizationsRequest) => {
      return request.serializeBinary();
    },
    account_autorization_pb.GetAccountAuthorizationsResponse.deserializeBinary
  );

  getAccountAuthorizations(
    request: account_autorization_pb.GetAccountAuthorizationsRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_autorization_pb.GetAccountAuthorizationsResponse>;

  getAccountAuthorizations(
    request: account_autorization_pb.GetAccountAuthorizationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_autorization_pb.GetAccountAuthorizationsResponse) => void): grpcWeb.ClientReadableStream<account_autorization_pb.GetAccountAuthorizationsResponse>;

  getAccountAuthorizations(
    request: account_autorization_pb.GetAccountAuthorizationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_autorization_pb.GetAccountAuthorizationsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_authorization_endpoint.AccountAuthorizationEndpoint/GetAccountAuthorizations',
        request,
        metadata || {},
        this.methodDescriptorGetAccountAuthorizations,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_authorization_endpoint.AccountAuthorizationEndpoint/GetAccountAuthorizations',
    request,
    metadata || {},
    this.methodDescriptorGetAccountAuthorizations);
  }

}

