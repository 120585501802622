import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, refreshSession, getSession } from '../../../../../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../../../../../app/Helpers';

import { TableState } from '../../../../../../../common/Table/TableSate';
import { number } from 'yup';
import { DataRestrictionEndpointClient } from '../../../../../../../../repository/UserManagement/Managers/DatarestrictionServiceClientPb';
import { GetDataRestrictionsResponse, DeleteDataRestrictionResponse } from '../../../../../../../../repository/UserManagement/Managers/datarestriction_pb';
import { GetDataRestrictionsRequest, DeleteDataRestrictionRequest } from '../../../../../../../../repository/UserManagement/Managers/datarestriction_pb';
import { DynamicField } from '../../../../../../../common/DynamicFields/DynamicFields';


export interface DataRestrictionsPageState {
    dataRestrictions: TableState
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    searchText: string | undefined,
    filters: any[],
}

const initialState: DataRestrictionsPageState = {
    dataRestrictions: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    isChangeStateLoading: false,
    message: undefined,
    searchText: undefined,
    filters: []

}

const client = new DataRestrictionEndpointClient(API_URL, null, null);
export const getDataRestrictions = createAsyncThunk<GetDataRestrictionsResponse.AsObject, APIRequest<GetDataRestrictionsRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/management/dataRestrictions/getDataRestriction',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {

                var r = await client.getDataRestrictions(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }


        }
        return await callReq();
    }

)

export const deleteDataRestriction = createAsyncThunk<APIResponse<DeleteDataRestrictionResponse.AsObject>, APIRequest<DeleteDataRestrictionRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/management/dataRestrictions/delete',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.delete(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)

const getFilters = (state: DataRestrictionsPageState): any[] => {

    return [];

}
export const managersDataRestrictionsPageSlice = createSlice({
    name: 'pages/management/dataRestrictions',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.dataRestrictions = initialState.dataRestrictions;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
            state.searchText = initialState.searchText;
        },
        resetDataRestriction: (state) => {
            state.dataRestrictions.items = [];
            state.dataRestrictions.hasMore = true;
        },

        setSort: (state, action: PayloadAction<boolean>) => {
            state.dataRestrictions.isDescending = action.payload;
        },
        setNumberOfResults: (state, action: PayloadAction<number>) => {
            state.dataRestrictions.numberOfResults = action.payload;
        },
        setSelectedDataRestriction: (state, action: PayloadAction<any[]>) => {
            state.dataRestrictions.selected = action.payload;
        },
        setSearchText: (state, action: PayloadAction<string | undefined>) => {
            state.searchText = action.payload;
        },
        setIsFilteredSet: (state, action: PayloadAction<boolean>) => {
            state.dataRestrictions.isFilteredSet = action.payload;
        },

        addDataRestriction: (state, action: PayloadAction<any>) => {
            if (!state.dataRestrictions.isFilteredSet) {
                if (!state.dataRestrictions.hasMore && !state.dataRestrictions.isDescending) {
                    state.dataRestrictions.items.push(action.payload);
                } else if (state.dataRestrictions.isDescending) {
                    state.dataRestrictions.items.unshift(action.payload);
                }
            }
        },
        updateDataRestriction: (state, action: PayloadAction<any>) => {
            let selected = state.dataRestrictions.selected;
            if (action?.payload) {
                if (selected.length > 0 && selected.at(0).id == action?.payload?.id) {
                    selected[0] = action?.payload
                }
                var u = state.dataRestrictions.items.findIndex(e => e.id == action?.payload?.id)
                if (u >= 0) {
                    state.dataRestrictions.items[u] = action?.payload
                }
            }

        },

        setMessage: (state, action: PayloadAction<ApiMessage>) => {
            state.message = action.payload;
        },

    },
    extraReducers: (builder) => {
        builder.addCase(getDataRestrictions.fulfilled, (state, { payload }) => {
            if (payload) {

                var r = (payload?.success?.datarestrictionsList.map(val => {
                    let obj;
                    try {
                        obj = JSON.parse(val?.alloweddata?.value ?? "");
                    } catch {
                        obj = undefined
                    }
                    let r = {
                        id: val?.id,
                        groupId: val.group?.id,
                        groupName: val.group?.name?.value ?? "",
                        allowedData: obj ?? "",
                        enum: [] as DynamicField[],
                        blocked: [] as DynamicField[],
                        range: [] as DynamicField[],
                        regex: obj?.regex ?? undefined,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.addedby?.firstname?.value ?? "") + " " + (val.addedby?.lastname?.value ?? ""),
                        addedById: val.addedby?.id?.value,
                    }

                    if (obj) {
                        let count = 0
                        obj?.enum?.forEach((element: string) => {
                            r.enum.push({ key: count + "", label: undefined, type: 1, value: element })
                            count++
                        });
                        obj?.blocked?.forEach((element: string) => {
                            r.blocked.push({ key: count + "", label: undefined, type: 1, value: element })
                            count++
                        });
                        count = 0;
                        obj?.range?.forEach((element: {from: string, to: string}) => {
                            r.range.push({ key: count + "", label: element.from, type: 1, value: element.to })
                            count++
                        });
                    }

                    return r;
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                let l = [];
                if (state.dataRestrictions.items.length != 0) {
                    l = state.dataRestrictions.items.slice(0, state.dataRestrictions.items.length - 10)
                }
                l = l.concat(r);
                if (r.length < state.dataRestrictions.numberOfResults) {
                    state.dataRestrictions.hasMore = false;
                } else {
                    l = l.concat(...Array(10).fill(null));
                }

                state.dataRestrictions.items = l
                //console.log(state.items)

            } else {
                state.dataRestrictions.hasMore = false;
            }
            state.dataRestrictions.isFetching = false

        })
        builder.addCase(getDataRestrictions.rejected, (state, action) => {
            state.dataRestrictions.isFetching = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getDataRestrictions.pending, (state, action) => {
            state.dataRestrictions.isFetching = true;
            state.message = undefined;
        })

        builder.addCase(deleteDataRestriction.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                let selected = state.dataRestrictions.selected;
                if (payload?.metadata) {
                    if (selected.length > 0 && selected.at(0).id == payload?.metadata.id) {
                        state.dataRestrictions.selected = []
                    }
                    var u = state.dataRestrictions.items.findIndex(e => e.id == payload?.metadata.id)
                    if (u >= 0) {
                        state.dataRestrictions.items.splice(u, 1);
                    }
                }
            }
        })
        builder.addCase(deleteDataRestriction.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(deleteDataRestriction.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

    }
})

export const { reset, dismissMessage, setSort, setNumberOfResults, setMessage, setSelectedDataRestriction, addDataRestriction, updateDataRestriction, resetDataRestriction, setSearchText, setIsFilteredSet } = managersDataRestrictionsPageSlice.actions

export default managersDataRestrictionsPageSlice.reducer


