import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { UserEndpointClient } from '../../../../repository/UserManagement/Customers/UserServiceClientPb';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIResponse, refreshSession, getSession, ACCOUNTANT_API_URL, APIRequest, TRADING_API_URL } from '../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../app/Helpers';

import { TableState } from '../../../common/Table/TableSate';
import { number } from 'yup';
import { GetCurrenciesRequest, GetCurrenciesResponse } from '../../../../repository/Accountant/currency_pb';
import { CurrencyEndpointClient } from '../../../../repository/Accountant/CurrencyServiceClientPb';
import { AccountStatus } from '../../../../app/Enums';
import { CurrencyPairEndpointClient } from '../../../../repository/Trading/Currency_pairServiceClientPb';
import { GetCurrencyPairsResponse, GetCurrencyPairsRequest, UpdateCurrencyPairActivityRequest, UpdateCurrencyPairActivityResponse, DeleteCurrencyPairRequest, DeleteCurrencyPairResponse } from '../../../../repository/Trading/currency_pair_pb';
import { GetCategoriesRequest, GetCategoriesResponse } from '../../../../repository/Trading/category_pb';
import { CategoryEndpointClient } from '../../../../repository/Trading/CategoryServiceClientPb';


export interface CurrencyPairsPageState {
    set: TableState
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    searchText: string | undefined,
    filters: any[],
    baseCurrencies: TableState
    quoteCurrencies: TableState
    statusFilterOption: { key: number, text: string },
    syncPricesFilterOption: { key: number, text: string },
    categories: TableState,
    mainPairs: TableState,
}

const initialState: CurrencyPairsPageState = {
    set: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    isChangeStateLoading: false,
    message: undefined,
    searchText: undefined,
    filters: [],
    baseCurrencies: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    quoteCurrencies: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    categories: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    mainPairs: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    statusFilterOption: { key: 0, text: "" },
    syncPricesFilterOption: { key: 0, text: "" },

}

const client = new CurrencyPairEndpointClient(TRADING_API_URL, null, null);
const clientCurrency = new CurrencyEndpointClient(ACCOUNTANT_API_URL, null, null);
const clientCategory = new CategoryEndpointClient(TRADING_API_URL, null, null);

export const getItems = createAsyncThunk<GetCurrencyPairsResponse.AsObject, APIRequest<GetCurrencyPairsRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/trading/currencyPairs/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await client.getCurrencyPairs(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getMainPairsItems = createAsyncThunk<GetCurrencyPairsResponse.AsObject, APIRequest<GetCurrencyPairsRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/trading/currencyPairs/fetchMainPairs',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await client.getCurrencyPairs(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getCategories = createAsyncThunk<GetCategoriesResponse.AsObject, APIRequest<GetCategoriesRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/trading/currencyPairs/getCategories',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clientCategory.getCategories(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getBaseCurrencies = createAsyncThunk<GetCurrenciesResponse.AsObject, APIRequest<GetCurrenciesRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/trading/currencyPairs/baseCurrencies/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clientCurrency.getCurrencies(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getQuoteCurrencies = createAsyncThunk<GetCurrenciesResponse.AsObject, APIRequest<GetCurrenciesRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/trading/currencyPairs/quoteCurrencies/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clientCurrency.getCurrencies(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const updateStatus = createAsyncThunk<APIResponse<UpdateCurrencyPairActivityResponse.AsObject>, APIRequest<UpdateCurrencyPairActivityRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/trading/currencyPairs/updateStatus',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.updateActivity(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId(), status: req.body.getIsactive() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)





export const deleteItem = createAsyncThunk<APIResponse<DeleteCurrencyPairResponse.AsObject>, APIRequest<DeleteCurrencyPairRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/trading/currencyPairs/delete',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.delete(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)
const getFilters = (state: CurrencyPairsPageState): any[] => {
    const selected = [];

    if (state.baseCurrencies.selected.length > 0) {
        selected.push({ key: 1, name: state.baseCurrencies.selected.at(0).name });
    }
    if (state.quoteCurrencies.selected.length > 0) {
        selected.push({ key: 2, name: state.quoteCurrencies.selected.at(0).name });
    }

    if (state.categories.selected.length > 0) {
        selected.push({ key: 3, name: state.categories.selected.at(0).nameAr + " | " + state.categories.selected.at(0).nameEn });
    }
    if (state.mainPairs.selected.length > 0) {
        selected.push({ key: 4, name: state.mainPairs.selected.at(0).name });
    }

    if (state.statusFilterOption.key != 0) {
        selected.push({ key: 5, name: state.statusFilterOption.text });
    }
    if (state.syncPricesFilterOption.key != 0) {
        selected.push({ key: 6, name: state.syncPricesFilterOption.text });
    }

    return selected;
}

export const currencyPairsPageSlice = createSlice({
    name: 'pages/trading/currencyPairs',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.set = initialState.set;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
            state.searchText = initialState.searchText;
            state.baseCurrencies = initialState.baseCurrencies;
            state.quoteCurrencies = initialState.quoteCurrencies;
            state.categories = initialState.categories;
            state.mainPairs = initialState.mainPairs;
            state.statusFilterOption = initialState.statusFilterOption;
            state.filters = initialState.filters;
            state.syncPricesFilterOption = initialState.syncPricesFilterOption;

        },
        resetSet: (state) => {
            state.set.items = [];
            state.set.hasMore = true;
        },

        resetBaseCurrencies: (state) => {
            state.baseCurrencies.items = [];
            state.baseCurrencies.hasMore = true;
        },
        resetQuoteCurrencies: (state) => {
            state.quoteCurrencies.items = [];
            state.quoteCurrencies.hasMore = true;
        },
        resetMainPair: (state) => {
            state.mainPairs.items = [];
            state.mainPairs.hasMore = true;
        },

        resetCategories: (state) => {
            state.categories.items = [];
            state.categories.hasMore = true;
        },

        setSort: (state, action: PayloadAction<boolean>) => {
            state.set.isDescending = action.payload;
        },

        setNumberOfResults: (state, action: PayloadAction<number>) => {
            state.set.numberOfResults = action.payload;
        },

        setSelectedItems: (state, action: PayloadAction<any[]>) => {
            state.set.selected = action.payload;
        },

        setSelectedBaseCurrencyItems: (state, action: PayloadAction<any[]>) => {
            state.baseCurrencies.selected = action.payload;
            state.filters = getFilters(state);
        },
        setSelectedQuoteCurrencyItems: (state, action: PayloadAction<any[]>) => {
            state.quoteCurrencies.selected = action.payload;
            state.filters = getFilters(state);
        },
        setSelectedCategoryItems: (state, action: PayloadAction<any[]>) => {
            state.categories.selected = action.payload;
            state.filters = getFilters(state);
        },

        setSelecteMainPairItems: (state, action: PayloadAction<any[]>) => {
            state.mainPairs.selected = action.payload;
            state.filters = getFilters(state);
        },

        setSearchText: (state, action: PayloadAction<string | undefined>) => {
            state.searchText = action.payload;
        },

        setIsFilteredSet: (state, action: PayloadAction<boolean>) => {
            state.set.isFilteredSet = action.payload;
        },

        setIsFilteredBaseCurrenciesSet: (state, action: PayloadAction<boolean>) => {
            state.baseCurrencies.isFilteredSet = action.payload;
        },

        setIsFilteredQuoteCurrenciesSet: (state, action: PayloadAction<boolean>) => {
            state.quoteCurrencies.isFilteredSet = action.payload;
        },
        setIsFilteredCategoriesSet: (state, action: PayloadAction<boolean>) => {
            state.categories.isFilteredSet = action.payload;
        },

        setIsFilteredMainPairsSet: (state, action: PayloadAction<boolean>) => {
            state.mainPairs.isFilteredSet = action.payload;
        },


        setStatusFilterOption: (state, action: PayloadAction<{ key: number, text: string }>) => {
            state.statusFilterOption = action.payload;
            state.filters = getFilters(state);
        },
        setSyncPricesFilterOption: (state, action: PayloadAction<{ key: number, text: string }>) => {
            state.syncPricesFilterOption = action.payload;
            state.filters = getFilters(state);
        },
        addItem: (state, action: PayloadAction<any>) => {
            if (!state.set.isFilteredSet) {
                if (!state.set.hasMore && !state.set.isDescending) {
                    state.set.items.push(action.payload);
                } else if (state.set.isDescending) {
                    state.set.items.unshift(action.payload);
                }
            }
        },
        updateItem: (state, action: PayloadAction<any>) => {
            let selected = state.set.selected;
            if (action?.payload) {
                if (selected.length > 0 && selected.at(0).id == action?.payload?.id) {
                    selected[0] = action?.payload
                }
                var u = state.set.items.findIndex(e => e.id == action?.payload?.id)
                if (u >= 0) {
                    state.set.items[u] = action?.payload
                }
            }

        }

    },
    extraReducers: (builder) => {


        builder.addCase(getBaseCurrencies.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.currenciesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        code: val.code?.value ?? "",
                        icon: val.iconsource?.value ?? "",
                        form: val.form?.formid,
                        symbol: val.symbol?.value ?? "",
                        decimalPlaces: val.decimalplaces?.value,

                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.baseCurrencies.numberOfResults) {
                    state.baseCurrencies.hasMore = false;
                }
                state.baseCurrencies.items = state.baseCurrencies.items.concat(r);
                //console.log(state.items)

            } else {
                state.baseCurrencies.hasMore = false;
            }
            state.baseCurrencies.isFetching = false

        })
        builder.addCase(getBaseCurrencies.rejected, (state, action) => {
            state.baseCurrencies.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getBaseCurrencies.pending, (state, action) => {
            state.baseCurrencies.isFetching = true;
            //state.message = undefined;
        })

        builder.addCase(getQuoteCurrencies.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.currenciesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        code: val.code?.value ?? "",
                        icon: val.iconsource?.value ?? "",
                        form: val.form?.formid,
                        symbol: val.symbol?.value ?? "",
                        decimalPlaces: val.decimalplaces?.value,

                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.quoteCurrencies.numberOfResults) {
                    state.quoteCurrencies.hasMore = false;
                }
                state.quoteCurrencies.items = state.quoteCurrencies.items.concat(r);
                //console.log(state.items)

            } else {
                state.quoteCurrencies.hasMore = false;
            }
            state.quoteCurrencies.isFetching = false

        })
        builder.addCase(getQuoteCurrencies.rejected, (state, action) => {
            state.quoteCurrencies.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getQuoteCurrencies.pending, (state, action) => {
            state.quoteCurrencies.isFetching = true;
            //state.message = undefined;
        })


        builder.addCase(getCategories.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.categoriesList.map(val => {
                    return {
                        id: val.id,
                        nameAr: val.namear?.value ?? "",
                        nameEn: val.nameen?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        rank: val.rank?.value,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                        parentCategoryId: val?.parent?.id?.value,
                        parentCategoryNameAr: val?.parent?.namear?.value ?? "",
                        parentCategoryNameEn: val?.parent?.nameen?.value ?? "",
                        iconSource: val?.iconsource?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.categories.numberOfResults) {
                    state.categories.hasMore = false;
                }
                state.categories.items = state.categories.items.concat(r);
                //console.log(state.items)

            } else {
                state.categories.hasMore = false;
            }
            state.categories.isFetching = false

        })
        builder.addCase(getCategories.rejected, (state, action) => {
            state.categories.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getCategories.pending, (state, action) => {
            state.categories.isFetching = true;
            //state.message = undefined;
        })


        builder.addCase(getMainPairsItems.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.currencypairsList.map(val => {
                    let rc = {
                        id: val.id,
                        name: val.name?.value,
                        buyPrice: val.buyprice?.value,
                        sellPrice: val.sellprice?.value,
                        tradingPrice: val.tradingprice?.value,

                        isActive: val.isactive?.value,
                        syncPrices: val.syncprices?.value,
                        iconSource: val.iconsource?.value ?? "",
                        categoryId: val.category?.id?.value ?? "",
                        categoryNameAr: val.category?.namear?.value ?? "",
                        categoryNameEn: val.category?.nameen?.value ?? "",
                        buyPriceEquation: val.pricetrigger?.buypriceequation?.value ?? "",
                        sellPriceEquation: val.pricetrigger?.sellpriceequation?.value ?? "",
                        tradingPriceEquation: val.pricetrigger?.tradingpriceequation?.value ?? "",
                        isPriceTriggerActive: val.pricetrigger?.isactive?.value,
                        mainCurrencyPair: val.pricetrigger?.main?.id?.value ?? "",
                        mainCurrencyPairName: val.pricetrigger?.main?.name?.value ?? "",
                        mainCurrencyPairBaseCurrencyId: val.pricetrigger?.main?.base?.id?.value ?? "",
                        mainCurrencyPairBaseCurrencyName: val.pricetrigger?.main?.base?.name?.value ?? "",
                        mainCurrencyPairBaseCurrencyCode: val.pricetrigger?.main?.base?.code?.value ?? "",
                        mainCurrencyPairBaseCurrencyDecimalPlaces: val.pricetrigger?.main?.base?.decimalplaces?.value ?? undefined,
                        mainCurrencyPairBaseCurrencyForm: val.pricetrigger?.main?.base?.form?.value ?? undefined,
                        mainCurrencyPairBaseCurrencySymbol: val.pricetrigger?.main?.base?.symbol?.value ?? "",
                        mainCurrencyPairQuoteCurrencyId: val.pricetrigger?.main?.quote?.id?.value ?? "",
                        mainCurrencyPairQuoteCurrencyName: val.pricetrigger?.main?.quote?.name?.value ?? "",
                        mainCurrencyPairQuoteCurrencyCode: val.pricetrigger?.main?.quote?.code?.value ?? "",
                        mainCurrencyPairQuoteCurrencyDecimalPlaces: val.pricetrigger?.main?.quote?.decimalplaces?.value ?? undefined,
                        mainCurrencyPairQuoteCurrencyForm: val.pricetrigger?.main?.quote?.form?.value ?? undefined,
                        mainCurrencyPairQuoteCurrencySymbol: val.pricetrigger?.main?.quote?.symbol?.value ?? "",

                        baseCurrencyId: val.base?.id?.value ?? "",
                        baseCurrencyName: val.base?.name?.value ?? "",
                        baseCurrencyCode: val.base?.code?.value ?? "",
                        baseCurrencyDecimalPlaces: val.base?.decimalplaces?.value ?? undefined,
                        baseCurrencyForm: val.base?.form?.value ?? undefined,
                        baseCurrencySymbol: val.base?.symbol?.value ?? "",
                        quoteCurrencyId: val.quote?.id?.value ?? "",
                        quoteCurrencyName: val.quote?.name?.value ?? "",
                        quoteCurrencyCode: val.quote?.code?.value ?? "",
                        quoteCurrencyDecimalPlaces: val.quote?.decimalplaces?.value ?? undefined,
                        quoteCurrencyForm: val.quote?.form?.value ?? undefined,
                        quoteCurrencySymbol: val.quote?.symbol?.value ?? "",
                        priceUpdate: formatDate(timestampToDate(val.priceupdate?.seconds, val.priceupdate?.nanos)),
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                    if (rc.name == undefined) {
                        rc.name = (rc?.baseCurrencyName == "" ? rc?.baseCurrencyId : rc?.baseCurrencyName + " (" + rc?.baseCurrencyCode + ")") + " / " + (rc?.quoteCurrencyName == "" ? rc?.quoteCurrencyId : rc?.quoteCurrencyName + " (" + rc?.quoteCurrencyCode + ")")
                    }
                    //         rc.name = (rc?.baseCurrencyCode == "" ? rc?.baseCurrencyId : rc?.baseCurrencyCode) + "/" + (rc?.quoteCurrencyCode == "" ? rc?.quoteCurrencyId : rc?.quoteCurrencyCode);

                    return rc;
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.mainPairs.numberOfResults) {
                    state.mainPairs.hasMore = false;
                }
                state.mainPairs.items = state.mainPairs.items.concat(r);
                //console.log(state.items)

            } else {
                state.mainPairs.hasMore = false;
            }
            state.mainPairs.isFetching = false

        })
        builder.addCase(getMainPairsItems.rejected, (state, action) => {
            state.mainPairs.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getMainPairsItems.pending, (state, action) => {
            state.mainPairs.isFetching = true;
            //state.message = undefined;
        })


        builder.addCase(getItems.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.currencypairsList.map(val => {
                    let rc = {
                        id: val.id,
                        name: val.name?.value,
                        buyPrice: val.buyprice?.value,
                        sellPrice: val.sellprice?.value,
                        tradingPrice: val.tradingprice?.value,

                        isActive: val.isactive?.value,
                        syncPrices: val.syncprices?.value,
                        iconSource: val.iconsource?.value ?? "",
                        categoryId: val.category?.id?.value ?? "",
                        categoryNameAr: val.category?.namear?.value ?? "",
                        categoryNameEn: val.category?.nameen?.value ?? "",
                        buyPriceEquation: val.pricetrigger?.buypriceequation?.value ?? "",
                        sellPriceEquation: val.pricetrigger?.sellpriceequation?.value ?? "",
                        tradingPriceEquation: val.pricetrigger?.tradingpriceequation?.value ?? "",
                        isPriceTriggerActive: val.pricetrigger?.isactive?.value,
                        mainCurrencyPair: val.pricetrigger?.main?.id?.value ?? "",
                        mainCurrencyPairName: val.pricetrigger?.main?.name?.value ?? "",
                        mainCurrencyPairBaseCurrencyId: val.pricetrigger?.main?.base?.id?.value ?? "",
                        mainCurrencyPairBaseCurrencyName: val.pricetrigger?.main?.base?.name?.value ?? "",
                        mainCurrencyPairBaseCurrencyCode: val.pricetrigger?.main?.base?.code?.value ?? "",
                        mainCurrencyPairBaseCurrencyDecimalPlaces: val.pricetrigger?.main?.base?.decimalplaces?.value ?? undefined,
                        mainCurrencyPairBaseCurrencyForm: val.pricetrigger?.main?.base?.form?.value ?? undefined,
                        mainCurrencyPairBaseCurrencySymbol: val.pricetrigger?.main?.base?.symbol?.value ?? "",
                        mainCurrencyPairQuoteCurrencyId: val.pricetrigger?.main?.quote?.id?.value ?? "",
                        mainCurrencyPairQuoteCurrencyName: val.pricetrigger?.main?.quote?.name?.value ?? "",
                        mainCurrencyPairQuoteCurrencyCode: val.pricetrigger?.main?.quote?.code?.value ?? "",
                        mainCurrencyPairQuoteCurrencyDecimalPlaces: val.pricetrigger?.main?.quote?.decimalplaces?.value ?? undefined,
                        mainCurrencyPairQuoteCurrencyForm: val.pricetrigger?.main?.quote?.form?.value ?? undefined,
                        mainCurrencyPairQuoteCurrencySymbol: val.pricetrigger?.main?.quote?.symbol?.value ?? "",

                        baseCurrencyId: val.base?.id?.value ?? "",
                        baseCurrencyName: val.base?.name?.value ?? "",
                        baseCurrencyCode: val.base?.code?.value ?? "",
                        baseCurrencyDecimalPlaces: val.base?.decimalplaces?.value ?? undefined,
                        baseCurrencyForm: val.base?.form?.value ?? undefined,
                        baseCurrencySymbol: val.base?.symbol?.value ?? "",
                        quoteCurrencyId: val.quote?.id?.value ?? "",
                        quoteCurrencyName: val.quote?.name?.value ?? "",
                        quoteCurrencyCode: val.quote?.code?.value ?? "",
                        quoteCurrencyDecimalPlaces: val.quote?.decimalplaces?.value ?? undefined,
                        quoteCurrencyForm: val.quote?.form?.value ?? undefined,
                        quoteCurrencySymbol: val.quote?.symbol?.value ?? "",
                        priceUpdate: formatDate(timestampToDate(val.priceupdate?.seconds, val.priceupdate?.nanos)),
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                    if (rc.name == undefined) {
                        rc.name = (rc?.baseCurrencyName == "" ? rc?.baseCurrencyId : rc?.baseCurrencyName + " (" + rc?.baseCurrencyCode + ")") + " / " + (rc?.quoteCurrencyName == "" ? rc?.quoteCurrencyId : rc?.quoteCurrencyName + " (" + rc?.quoteCurrencyCode + ")")
                    }
                    //         rc.name = (rc?.baseCurrencyCode == "" ? rc?.baseCurrencyId : rc?.baseCurrencyCode) + "/" + (rc?.quoteCurrencyCode == "" ? rc?.quoteCurrencyId : rc?.quoteCurrencyCode);

                    return rc;
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                let l = [];
                if (state.set.items.length != 0) {
                    l = state.set.items.slice(0, state.set.items.length - 10)
                }
                l = l.concat(r);
                if (r.length < state.set.numberOfResults) {
                    state.set.hasMore = false;
                } else {
                    l = l.concat(...Array(10).fill(null));
                }

                state.set.items = l
                //console.log(state.items)

            } else {
                state.set.hasMore = false;
            }
            state.set.isFetching = false

        })
        builder.addCase(getItems.rejected, (state, action) => {
            state.set.isFetching = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getItems.pending, (state, action) => {
            state.set.isFetching = true;
            state.message = undefined;
        })


        builder.addCase(updateStatus.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response?.success?.message);
                let selected = state.set.selected;
                if (payload?.metadata) {
                    if (selected.length > 0 && selected.at(0).id == payload?.metadata.id) {
                        selected.at(0).isActive = payload?.metadata.status
                    }
                    var u = state.set.items.findIndex(e => e.id == payload?.metadata.id)
                    if (u >= 0) {
                        state.set.items.at(u).isActive = payload?.metadata.status
                    }

                }
            }
        })
        builder.addCase(updateStatus.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateStatus.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })


        builder.addCase(deleteItem.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                let selected = state.set.selected;
                if (payload?.metadata) {
                    if (selected.length > 0 && selected.at(0).id == payload?.metadata.id) {
                        state.set.selected = []
                    }
                    var u = state.set.items.findIndex(e => e.id == payload?.metadata.id)
                    if (u >= 0) {
                        state.set.items.splice(u, 1);
                    }
                }
            }
        })
        builder.addCase(deleteItem.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(deleteItem.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })
    }
})

export const { reset, dismissMessage, setSort, resetCategories, resetMainPair, setIsFilteredCategoriesSet, setIsFilteredMainPairsSet, setSelecteMainPairItems, setSelectedCategoryItems, setSyncPricesFilterOption, setNumberOfResults, resetSet, setSelectedItems, setSearchText, setIsFilteredSet, addItem, updateItem, resetBaseCurrencies, resetQuoteCurrencies, setIsFilteredBaseCurrenciesSet, setIsFilteredQuoteCurrenciesSet, setSelectedBaseCurrencyItems, setSelectedQuoteCurrencyItems, setStatusFilterOption } = currencyPairsPageSlice.actions

export default currencyPairsPageSlice.reducer


