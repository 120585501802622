import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { UserEndpointClient } from '../../../../repository/UserManagement/Customers/UserServiceClientPb';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIResponse, refreshSession, getSession, ACCOUNTANT_API_URL, APIRequest, TRADING_API_URL } from '../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../app/Helpers';
import { TableState } from '../../../common/Table/TableSate';
import { number } from 'yup';
import { OrderEndpointClient } from '../../../../repository/Trading/OrderServiceClientPb';
import { GetOrdersRequest, GetOrdersResponse, CancelResponse, CancelRequest } from '../../../../repository/Trading/order_pb';
import { GetUsersRequest, GetUsersResponse } from '../../../../repository/UserManagement/Customers/user_pb';
import { CurrencyPairEndpointClient } from '../../../../repository/Trading/Currency_pairServiceClientPb';
import { GetCurrencyPairsRequest, GetCurrencyPairsResponse } from '../../../../repository/Trading/currency_pair_pb';
import { OrderStatus } from '../../../../app/Enums';


export interface OrdersPageState {
    set: TableState
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    searchText: string | undefined,
    filters: any[],
    clients: TableState
    currencyPairs: TableState
    tradeActionFilterOption: { key: number, text: string },
    statusFilterOption: { key: number, text: string },
    orderTypeFilterOption: { key: number, text: string },
    fromFilterOption: { value: string, text: string },
    toFilterOption: { value: string, text: string },

}

const initialState: OrdersPageState = {
    set: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    isChangeStateLoading: false,
    message: undefined,
    searchText: undefined,
    filters: [],
    clients: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    currencyPairs: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    statusFilterOption: { key: -1, text: "" },
    tradeActionFilterOption: { key: 0, text: "" },
    orderTypeFilterOption: { key: 0, text: "" },
    fromFilterOption: { value: "", text: "" },
    toFilterOption: { value: "", text: "" },

}

const client = new OrderEndpointClient(TRADING_API_URL, null, null);
const clienCustomers = new UserEndpointClient(API_URL, null, null);
const clientCurrencyPairs = new CurrencyPairEndpointClient(TRADING_API_URL, null, null);


export const getItems = createAsyncThunk<GetOrdersResponse.AsObject, APIRequest<GetOrdersRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/trading/orders/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await client.getOrders(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

export const getCurrencyPairs = createAsyncThunk<GetCurrencyPairsResponse.AsObject, APIRequest<GetCurrencyPairsRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/trading/orders/currencyPairs/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clientCurrencyPairs.getCurrencyPairs(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)




export const getClinetUsers = createAsyncThunk<GetUsersResponse.AsObject, APIRequest<GetUsersRequest>, {
    rejectValue: ApiMessage
}>(
    'pages/trading/orders/clients/fetch',
    async (req, thunkApi) => {

        let callReq = async (): Promise<any> => {
            try {
                var r = await clienCustomers.getUsers(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {
                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const cancelOrder = createAsyncThunk<APIResponse<CancelResponse.AsObject>, APIRequest<CancelRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/trading/orders/cancel',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.cancel(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)
const getFilters = (state: OrdersPageState): any[] => {
    const selected = [];

    if (state.currencyPairs.selected.length > 0) {
        selected.push({ key: 1, name: state.currencyPairs.selected.at(0).name });
    }

    if (state.clients.selected.length > 0) {
        selected.push({ key: 2, name: state.clients.selected.at(0).name });
    }

    if (state.orderTypeFilterOption.key != 0) {
        selected.push({ key: 3, name: state.orderTypeFilterOption.text });
    }

    if (state.tradeActionFilterOption.key != 0) {
        selected.push({ key: 4, name: state.tradeActionFilterOption.text });
    }

    if (state.statusFilterOption.key != -1) {
        selected.push({ key: 5, name: state.statusFilterOption.text });
    }

    if (state.fromFilterOption.value && state.fromFilterOption.value != "") {
        selected.push({ key: 6, name: state.fromFilterOption.text });
    }
    if (state.toFilterOption.value && state.toFilterOption.value != "") {
        selected.push({ key: 7, name: state.toFilterOption.text });
    }


    return selected;
}

export const ordersPageSlice = createSlice({
    name: 'pages/trading/orders',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.set = initialState.set;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
            state.searchText = initialState.searchText;
            state.fromFilterOption = initialState.fromFilterOption;
            state.clients = initialState.clients;
            state.orderTypeFilterOption = initialState.orderTypeFilterOption;
            state.statusFilterOption = initialState.statusFilterOption;
            state.toFilterOption = initialState.toFilterOption;
            state.tradeActionFilterOption = initialState.tradeActionFilterOption;
            state.filters = initialState.filters;
            state.currencyPairs = initialState.currencyPairs;

        },
        resetSet: (state) => {
            state.set.items = [];
            state.set.hasMore = true;
        },
        resetUsers: (state) => {
            state.clients.items = [];
            state.clients.hasMore = true;
        },
        resetCurrencyPairs: (state) => {
            state.currencyPairs.items = [];
            state.currencyPairs.hasMore = true;
        },
        setSort: (state, action: PayloadAction<boolean>) => {
            state.set.isDescending = action.payload;
        },
        setNumberOfResults: (state, action: PayloadAction<number>) => {
            state.set.numberOfResults = action.payload;
        },
        setSelectedItems: (state, action: PayloadAction<any[]>) => {
            state.set.selected = action.payload;
        },

        setSelectedUserItems: (state, action: PayloadAction<any[]>) => {
            state.clients.selected = action.payload;
            state.filters = getFilters(state);

        },

        setSelectedCurrencyPairItems: (state, action: PayloadAction<any[]>) => {
            state.currencyPairs.selected = action.payload;
            state.filters = getFilters(state);

        },
        setSearchText: (state, action: PayloadAction<string | undefined>) => {
            state.searchText = action.payload;
        },
        setIsFilteredSet: (state, action: PayloadAction<boolean>) => {
            state.set.isFilteredSet = action.payload;
        },

        setIsFilteredOwnersSet: (state, action: PayloadAction<boolean>) => {
            state.clients.isFilteredSet = action.payload;
        },

        setIsFilteredCurrencyPairsSet: (state, action: PayloadAction<boolean>) => {
            state.currencyPairs.isFilteredSet = action.payload;
        },


        setStatusFilterOption: (state, action: PayloadAction<{ key: number, text: string }>) => {
            state.statusFilterOption = action.payload;
            state.filters = getFilters(state);
        },
        setTradeActionFilterOption: (state, action: PayloadAction<{ key: number, text: string }>) => {
            state.tradeActionFilterOption = action.payload;
            state.filters = getFilters(state);
        },
        setOrderTypeFilterOption: (state, action: PayloadAction<{ key: number, text: string }>) => {
            state.orderTypeFilterOption = action.payload;
            state.filters = getFilters(state);
        },

        setFromFilterOption: (state, action: PayloadAction<{ value: string, text: string }>) => {
            state.fromFilterOption = action.payload;
            state.filters = getFilters(state);
        },
        setToFilterOption: (state, action: PayloadAction<{ value: string, text: string }>) => {
            state.toFilterOption = action.payload;
            state.filters = getFilters(state);
        },
        addItem: (state, action: PayloadAction<any>) => {
            if (!state.set.isFilteredSet) {
                if (!state.set.hasMore && !state.set.isDescending) {
                    state.set.items.push(action.payload);
                } else if (state.set.isDescending) {
                    state.set.items.unshift(action.payload);
                }
            }
        },
        updateItem: (state, action: PayloadAction<any>) => {
            let selected = state.set.selected;
            if (action?.payload) {
                if (selected.length > 0 && selected.at(0).id == action?.payload?.id) {
                    selected[0] = action?.payload
                }
                var u = state.set.items.findIndex(e => e.id == action?.payload?.id)
                if (u >= 0) {
                    state.set.items[u] = action?.payload
                }
            }

        }

    },
    extraReducers: (builder) => {

        builder.addCase(getClinetUsers.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.usersList.map(val => {
                    return {
                        internalId: val.profile?.internalid,
                        name: (val.profile?.firstname?.value ?? "") + " " + (val.profile?.lastname?.value ?? ""),
                        firstname: (val.profile?.firstname?.value ?? ""),
                        lastname: (val.profile?.lastname?.value ?? ""),
                        username: val.username?.value ?? "",
                        isActive: val.isactive,
                        isConfirmed: val.isconfirmed,
                        createdDate: formatDate(timestampToDate(val.profile?.createddate?.seconds, val.profile?.createddate?.nanos)),
                        addedBy: (val.profile?.addedby?.firstname?.value ?? "") + " " + (val.profile?.addedby?.lastname?.value ?? ""),
                        addedById: val.profile?.addedby?.id?.value,
                        role: val.profile?.role?.name?.value,
                        roleId: val.profile?.role?.id,
                        usertypeId: val.profile?.usertype?.id,
                        usertype: val.profile?.usertype?.name?.value,
                        lastlogin: formatDate(timestampToDate(val.lastlogin?.seconds, val.lastlogin?.nanos)),
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.clients.numberOfResults) {
                    state.clients.hasMore = false;
                }
                state.clients.items = state.clients.items.concat(r);
                //console.log(state.items)

            } else {
                state.clients.hasMore = false;
            }
            state.clients.isFetching = false

        })
        builder.addCase(getClinetUsers.rejected, (state, action) => {
            state.clients.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getClinetUsers.pending, (state, action) => {
            state.clients.isFetching = true;
            // state.message = undefined;
        })


        builder.addCase(getCurrencyPairs.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.currencypairsList.map(val => {
                    let rc = {
                        id: val.id,
                        name: val.name?.value,
                        buyPrice: val.buyprice?.value,
                        sellPrice: val.sellprice?.value,
                        tradingPrice: val.tradingprice?.value,
                        isActive: val.isactive?.value,
                        iconSource: val.iconsource?.value ?? "",
                        categoryId: val.category?.id?.value ?? "",
                        categoryNameAr: val.category?.namear?.value ?? "",
                        categoryNameEn: val.category?.nameen?.value ?? "",
                        buyPriceEquation: val.pricetrigger?.buypriceequation?.value ?? "",
                        sellPriceEquation: val.pricetrigger?.sellpriceequation?.value ?? "",
                        tradingPriceEquation: val.pricetrigger?.tradingpriceequation?.value ?? "",
                        isPriceTriggerActive: val.pricetrigger?.isactive?.value ,
                        mainCurrencyPair: val.pricetrigger?.main?.id?.value ?? "",
                        mainCurrencyPairName: val.pricetrigger?.main?.name?.value ?? "",
                        mainCurrencyPairBaseCurrencyId: val.pricetrigger?.main?.base?.id?.value ?? "",
                        mainCurrencyPairBaseCurrencyName: val.pricetrigger?.main?.base?.name?.value ?? "",
                        mainCurrencyPairBaseCurrencyCode: val.pricetrigger?.main?.base?.code?.value ?? "",
                        mainCurrencyPairBaseCurrencyDecimalPlaces: val.pricetrigger?.main?.base?.decimalplaces?.value ?? undefined,
                        mainCurrencyPairBaseCurrencyForm: val.pricetrigger?.main?.base?.form?.value ?? undefined,
                        mainCurrencyPairBaseCurrencySymbol: val.pricetrigger?.main?.base?.symbol?.value ?? "",
                        mainCurrencyPairQuoteCurrencyId: val.pricetrigger?.main?.quote?.id?.value ?? "",
                        mainCurrencyPairQuoteCurrencyName: val.pricetrigger?.main?.quote?.name?.value ?? "",
                        mainCurrencyPairQuoteCurrencyCode: val.pricetrigger?.main?.quote?.code?.value ?? "",
                        mainCurrencyPairQuoteCurrencyDecimalPlaces: val.pricetrigger?.main?.quote?.decimalplaces?.value ?? undefined,
                        mainCurrencyPairQuoteCurrencyForm: val.pricetrigger?.main?.quote?.form?.value ?? undefined,
                        mainCurrencyPairQuoteCurrencySymbol: val.pricetrigger?.main?.quote?.symbol?.value ?? "",

                        baseCurrencyId: val.base?.id?.value ?? "",
                        baseCurrencyName: val.base?.name?.value ?? "",
                        baseCurrencyCode: val.base?.code?.value ?? "",
                        baseCurrencyDecimalPlaces: val.base?.decimalplaces?.value ?? undefined,
                        baseCurrencyForm: val.base?.form?.value ?? undefined,
                        baseCurrencySymbol: val.base?.symbol?.value ?? "",
                        quoteCurrencyId: val.quote?.id?.value ?? "",
                        quoteCurrencyName: val.quote?.name?.value ?? "",
                        quoteCurrencyCode: val.quote?.code?.value ?? "",
                        quoteCurrencyDecimalPlaces: val.quote?.decimalplaces?.value ?? undefined,
                        quoteCurrencyForm: val.quote?.form?.value ?? undefined,
                        quoteCurrencySymbol: val.quote?.symbol?.value ?? "",
                        priceUpdate: formatDate(timestampToDate(val.priceupdate?.seconds, val.priceupdate?.nanos)),
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }

                    if (rc.name == undefined) {
                        rc.name = (rc?.baseCurrencyName == "" ? rc?.baseCurrencyId : rc?.baseCurrencyName + " (" + rc?.baseCurrencyCode + ")") + " / " + (rc?.quoteCurrencyName == "" ? rc?.quoteCurrencyId : rc?.quoteCurrencyName + " (" + rc?.quoteCurrencyCode + ")")
                    }
                    return rc;
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.currencyPairs.numberOfResults) {
                    state.currencyPairs.hasMore = false;
                }
                state.currencyPairs.items = state.currencyPairs.items.concat(r);
                //console.log(state.items)

            } else {
                state.currencyPairs.hasMore = false;
            }
            state.currencyPairs.isFetching = false

        })
        builder.addCase(getCurrencyPairs.rejected, (state, action) => {
            state.currencyPairs.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getCurrencyPairs.pending, (state, action) => {
            state.currencyPairs.isFetching = true;
            // state.message = undefined;
        })


        builder.addCase(getItems.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.ordersList.map(val => {
                    let rc = {
                        id: val.id,
                        currencyId: val.currency?.id?.value ?? "",
                        currencyName: val.currency?.name?.value ?? "",
                        currencyCode: val.currency?.code?.value ?? "",
                        currencyDecimalPlaces: val.currency?.decimalplaces?.value ?? undefined,
                        currencyForm: val.currency?.form?.value ?? undefined,
                        currencySymbol: val.currency?.symbol?.value ?? "",
                        orderType: val.ordertype?.value,
                        filled: val.filled?.value ?? undefined,
                        itemId: val.orderitem?.id?.value ?? undefined,
                        currencyPairId: val.orderitem?.item?.id?.value,
                        currencyPairName: val.orderitem?.item?.name?.value,
                        currencyPairBaseCurrencyId: val.orderitem?.item?.base?.id?.value ?? "",
                        currencyPairBaseCurrencyName: val.orderitem?.item?.base?.name?.value ?? "",
                        currencyPairBaseCurrencyCode: val.orderitem?.item?.base?.code?.value ?? "",
                        currencyPairBaseCurrencyDecimalPlaces: val.orderitem?.item?.base?.decimalplaces?.value ?? undefined,
                        currencyPairBaseCurrencyForm: val.orderitem?.item?.base?.form?.value ?? undefined,
                        currencyPairBaseCurrencySymbol: val.orderitem?.item?.base?.symbol?.value ?? "",
                        currencyPairQuoteCurrencyId: val.orderitem?.item?.quote?.id?.value ?? "",
                        currencyPairQuoteCurrencyName: val.orderitem?.item?.quote?.name?.value ?? "",
                        currencyPairQuoteCurrencyCode: val.orderitem?.item?.quote?.code?.value ?? "",
                        currencyPairQuoteCurrencyDecimalPlaces: val.orderitem?.item?.quote?.decimalplaces?.value ?? undefined,
                        currencyPairQuoteCurrencyForm: val.orderitem?.item?.quote?.form?.value ?? undefined,
                        currencyPairQuoteCurrencySymbol: val.orderitem?.item?.quote?.symbol?.value ?? "",
                        itemPrice: val.orderitem?.price?.value,
                        itemQuantity: val.orderitem?.quantity?.value,
                        itemSubTotal: val.orderitem?.subtotal?.value,
                        clientId: val.client?.id?.value,
                        clientName: (val.client?.firstname?.value ?? "") + " " + (val.client?.lastname?.value ?? ""),
                        status: val.status?.value,
                        total: val.total?.value,
                        tradeAction: val.tradeaction?.value,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                    if (rc.currencyPairName == undefined) {
                        rc.currencyPairName = (rc?.currencyPairBaseCurrencyName == "" ? rc?.currencyPairBaseCurrencyId : rc?.currencyPairBaseCurrencyName + " (" + rc?.currencyPairBaseCurrencyCode + ")") + " / " + (rc?.currencyPairQuoteCurrencyName == "" ? rc?.currencyPairQuoteCurrencyId : rc?.currencyPairQuoteCurrencyName + " (" + rc?.currencyPairQuoteCurrencyCode + ")")
                    }
                    return rc;

                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                let l = [];
                if (state.set.items.length != 0) {
                    l = state.set.items.slice(0, state.set.items.length - 10)
                }
                l = l.concat(r);
                if (r.length < state.set.numberOfResults) {
                    state.set.hasMore = false;
                } else {
                    l = l.concat(...Array(10).fill(null));
                }

                state.set.items = l
                //console.log(state.items)

                /*{
                        id: val.id,
                        fee: val.fee?.value,
                        fromAccountId: val.fromaccount?.id?.value ?? "",
                        fromAccountName: val.fromaccount?.name?.value ?? "",
                        currencyId: val.currency?.id?.value ?? "",
                        currencyName: val.currency?.name?.value ?? "",
                        currencyCode: val.currency?.code?.value ?? "",
                        currencyDecimalPlaces: val.currency?.decimalplaces?.value ?? undefined,
                        currencyForm: val.currency?.form?.value ?? undefined,
                        currencySymbol: val.currency?.symbol?.value ?? "",
                        netTotal: val.nettotal?.value,
                        orderType: val.ordertype?.value,
                        paid: val.paid?.value ?? undefined,
                        itemId: val.item?.id?.value ?? undefined,
                        currencyPairId: val.item?.item?.id?.value,
                        currencyPairBaseCurrencyId: val.item?.item?.base?.id?.value ?? "",
                        currencyPairBaseCurrencyName: val.item?.item?.base?.name?.value ?? "",
                        currencyPairBaseCurrencyCode: val.item?.item?.base?.code?.value ?? "",
                        currencyPairBaseCurrencyDecimalPlaces: val.item?.item?.base?.decimalplaces?.value ?? undefined,
                        currencyPairBaseCurrencyForm: val.item?.item?.base?.form?.value ?? undefined,
                        currencyPairBaseCurrencySymbol: val.item?.item?.base?.symbol?.value ?? "",
                        currencyPairQuoteCurrencyId: val.item?.item?.quote?.id?.value ?? "",
                        currencyPairQuoteCurrencyName: val.item?.item?.quote?.name?.value ?? "",
                        currencyPairQuoteCurrencyCode: val.item?.item?.quote?.code?.value ?? "",
                        currencyPairQuoteCurrencyDecimalPlaces: val.item?.item?.quote?.decimalplaces?.value ?? undefined,
                        currencyPairQuoteCurrencyForm: val.item?.item?.quote?.form?.value ?? undefined,
                        currencyPairQuoteCurrencySymbol: val.item?.item?.quote?.symbol?.value ?? "",
                        itemPrice: val.item?.price?.value,
                        itemQuantity: val.item?.quantity?.value,
                        itemSubTotal: val.item?.subtotal?.value,
                        placedforId: val.placedfor?.id?.value,
                        placedforName: (val.placedfor?.firstname?.value ?? "") + " " + (val.placedfor?.lastname?.value ?? ""),
                        reference: val.reference?.value,
                        status: val.status?.value,
                        toAccountId: val.toaccount?.id?.value ?? "",
                        toAccountName: val.toaccount?.name?.value ?? "",
                        total: val.total?.value,
                        tradeAction: val.tradeaction?.value,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }*/

            } else {
                state.set.hasMore = false;
            }
            state.set.isFetching = false

        })
        builder.addCase(getItems.rejected, (state, action) => {
            state.set.isFetching = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getItems.pending, (state, action) => {
            state.set.isFetching = true;
            state.message = undefined;
        })


        builder.addCase(cancelOrder.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response?.success?.message);
                let selected = state.set.selected;
                if (payload?.metadata) {
                    if (selected.length > 0 && selected.at(0).id == payload?.metadata.id) {
                        selected.at(0).status = OrderStatus.CANCELED
                    }
                    var u = state.set.items.findIndex(e => e.id == payload?.metadata.id)
                    if (u >= 0) {
                        state.set.items.at(u).status = OrderStatus.CANCELED
                    }

                }
            }
        })
        builder.addCase(cancelOrder.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(cancelOrder.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

    }
})

export const { reset, dismissMessage, setSort, resetCurrencyPairs, setNumberOfResults, setSelectedCurrencyPairItems, setIsFilteredCurrencyPairsSet, resetSet, setSelectedItems, setSearchText, setIsFilteredSet, addItem, updateItem, resetUsers, setOrderTypeFilterOption, setFromFilterOption, setToFilterOption, setTradeActionFilterOption, setIsFilteredOwnersSet, setSelectedUserItems, setStatusFilterOption } = ordersPageSlice.actions

export default ordersPageSlice.reducer


