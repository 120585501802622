import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, refreshSession, getSession, ACCOUNTANT_API_URL, APIRequest, APIResponse } from '../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../app/Helpers';
import { DynamicField } from '../../../common/DynamicFields/DynamicFields';
import { AccountTypeRuleEndpointClient } from '../../../../repository/Accountant/Account_type_ruleServiceClientPb';
import { FeeEndpointClient } from '../../../../repository/Accountant/FeeServiceClientPb';
import { GetFeesRequest, GetFeesResponse } from '../../../../repository/Accountant/fee_pb';
import { TableState } from '../../../common/Table/TableSate';
import { CurrencyEndpointClient } from '../../../../repository/Accountant/CurrencyServiceClientPb';
import { LimitationEndpointClient } from '../../../../repository/Accountant/LimitationServiceClientPb';
import { GetLimitationsRequest, GetLimitationsResponse } from '../../../../repository/Accountant/limitation_pb';
import { GetCurrenciesRequest, GetCurrenciesResponse } from '../../../../repository/Accountant/currency_pb';
import { TreasuryEndpointClient } from '../../../../repository/Accountant/TreasuryServiceClientPb';
import { TreasuryTypeEndpointClient } from '../../../../repository/Accountant/Treasury_typeServiceClientPb';
import { AddTreasuryRequest, AddTreasuryResponse, GetTreasuryRequest, UpdateTreasuryRequest, UpdateTreasuryResponse, GetTreasuryResponse } from '../../../../repository/Accountant/treasury_pb';
import { GetTreasuryTypesRequest, GetTreasuryTypesResponse } from '../../../../repository/Accountant/treasury_type_pb';


export interface AccountTypesFormState {
    isLoading: boolean
    message?: ApiMessage
    stage1: any,
    currencies: TableState
    treasuryTypes: TableState
    limitations: TableState,
}

const initialState: AccountTypesFormState = {
    isLoading: false,
    message: undefined,
    stage1: undefined,
    currencies: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    treasuryTypes: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    limitations: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },

}

const client = new TreasuryEndpointClient(ACCOUNTANT_API_URL, null, null);
const clientCurrency = new CurrencyEndpointClient(ACCOUNTANT_API_URL, null, null);
const clientTreasuryType = new TreasuryTypeEndpointClient(ACCOUNTANT_API_URL, null, null);
const clienLimition = new LimitationEndpointClient(ACCOUNTANT_API_URL, null, null);

export const add = createAsyncThunk<AddTreasuryResponse.AsObject, APIRequest<AddTreasuryRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/treasuries/add',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.add(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)


export const update = createAsyncThunk<UpdateTreasuryResponse.AsObject, APIRequest<UpdateTreasuryRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/treasuries/update',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.update(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)



export const get = createAsyncThunk<GetTreasuryResponse.AsObject, APIRequest<GetTreasuryRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/treasuries/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getTreasury(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)



 
export const getCurrencies = createAsyncThunk<GetCurrenciesResponse.AsObject, APIRequest<GetCurrenciesRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/treasuries/currencies/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await clientCurrency.getCurrencies(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getTreasuryTypes = createAsyncThunk<GetTreasuryTypesResponse.AsObject, APIRequest<GetTreasuryTypesRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/treasuries/treasuryTypes/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clientTreasuryType.getTreasuryTypes(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getLimitations = createAsyncThunk<GetLimitationsResponse.AsObject, APIRequest<GetLimitationsRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/treasuries/limitations/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clienLimition.getLimitations(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)



export const treasuriesFormSlice = createSlice({
    name: 'forms/accountant/treasuries',
    initialState,
    reducers: {

        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.isLoading = false;
            state.message = undefined;
            state.stage1 = initialState.stage1;
            state.treasuryTypes = initialState.treasuryTypes;
            state.currencies = initialState.currencies;
             state.limitations = initialState.limitations;
        },
        setStage1: (state, action: PayloadAction<any>) => {
            let stage1 = {
             
                id: action.payload.id,
                internalId: action.payload.internalId,
                name: action.payload.name,
                code: action.payload.code,
                nature: action.payload.nature,
                treasuryTypeId: action.payload.treasuryTypeId,
                treasuryTypeName: action.payload.treasuryTypeName,
                status: action.payload.status,
                ownerFirstname: action.payload.ownerFirstname,
                ownerLastname: action.payload.ownerLastname,
                ownerMiddlename: action.payload.ownerMiddlename,
                ownerName: action.payload.ownerName,
                ownerId: action.payload.ownerId,
                createdDate: action.payload.createdDate,
                addedBy: action.payload.addedBy,
                addedById: action.payload.addedById,
                limitationId: action.payload.limitationId,
                limitationName: action.payload.limitationName,
                extraFields: action.payload.extraFields,
                deposits: action.payload.deposits,
                isAuthorizationRequired: action.payload.isAuthorizationRequired


            }
            state.stage1 = stage1;
        },
       
        resetLimitations: (state) => {
            state.limitations.items = [];
            state.limitations.hasMore = true;
        },
        resetCurrencies: (state) => {
            state.currencies.items = [];
            state.currencies.hasMore = true;
        },
        resetTreasuryTypes: (state) => {
            state.treasuryTypes.items = [];
            state.treasuryTypes.hasMore = true;
        },


        setSelectedCurrencyItems: (state, action: PayloadAction<any[]>) => {
            state.currencies.selected = action.payload;

        },
        setSelectedLimitationItems: (state, action: PayloadAction<any[]>) => {
            state.limitations.selected = action.payload;

        },
        setSelectedTreasuryTypeItems: (state, action: PayloadAction<any[]>) => {
            state.treasuryTypes.selected = action.payload;

        },
      

        setIsFilteredCurrenciesSet: (state, action: PayloadAction<boolean>) => {
            state.currencies.isFilteredSet = action.payload;
        },
        setIsFilteredLimitationsSet: (state, action: PayloadAction<boolean>) => {
            state.limitations.isFilteredSet = action.payload;
        },
        setIsFilteredTreasuryTypesSet: (state, action: PayloadAction<boolean>) => {
            state.treasuryTypes.isFilteredSet = action.payload;
        },
    
    },
    extraReducers: (builder) => {
        builder.addCase(add.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            if (payload)
                state.message = toApiMessage(payload.success?.message);

        })
        builder.addCase(add.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(add.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })
        builder.addCase(update.fulfilled, (state, { payload }) => {
            if (payload)
                state.message = toApiMessage(payload.success?.message);
            state.isLoading = false;
        })
        builder.addCase(update.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(update.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })

        builder.addCase(get.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {

                let r = {
                    id: payload.success?.id,
                    internalId: payload.success?.internalid,
                    name: payload.success?.name?.value ?? "",
                    code: payload.success?.code?.value ?? "",
                    nature: payload.success?.nature?.id?.value ?? undefined,
                    treasuryTypeId: payload.success?.treasurytype?.id?.value ?? "",
                    treasuryTypeName: payload.success?.treasurytype?.name?.value ?? "",
                    status: payload.success?.status?.value ?? undefined,
                    createdDate: formatDate(timestampToDate(payload.success?.createddate?.seconds, payload.success?.createddate?.nanos)),
                    addedBy: (payload.success?.createdby?.firstname?.value ?? "") + " " + (payload.success?.createdby?.lastname?.value ?? ""),
                    addedById: payload.success?.createdby?.id?.value,
                    limitationId: payload.success?.limitation?.id?.value ?? "",
                    limitationName: payload.success?.limitation?.name?.value ?? "",
                    extraFields: [] as DynamicField[],
                    deposits: [] as any
                }

                if (payload?.success?.extrafieldsList) {
                    let count = 0
                    payload?.success?.extrafieldsList?.forEach(element => {
                        r.extraFields.push({ key: element.key, label: element.label, type: element.type, value: element.value })
                        count++
                    });
                }

                if (payload?.success?.depositsList) {
                    let count = 0
                    payload?.success?.depositsList?.forEach(element => {
                        r.deposits.push({  currencyId: element?.currency?.id?.value ?? "",
                        currencyName: element?.currency?.name?.value ?? "",
                        currencyCode: element?.currency?.code?.value ?? "",
                        currencyDecimalPlaces: element?.currency?.decimalplaces?.value ?? undefined,
                        currencyForm: element?.currency?.form?.value ?? undefined,
                        currencySymbol: element?.currency?.symbol?.value ?? "",
                        isActive: element.isactive?.value ?? undefined})
                        count++
                    });
                }

                state.stage1 = r
            }
        })
        builder.addCase(get.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(get.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })

        builder.addCase(getTreasuryTypes.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.treasurytypesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])

                if (r.length < state.treasuryTypes.numberOfResults) {
                    state.treasuryTypes.hasMore = false;
                }
                state.treasuryTypes.items = state.treasuryTypes.items.concat(r);

            } else {
                state.treasuryTypes.hasMore = false;
            }
            state.treasuryTypes.isFetching = false

        })
        builder.addCase(getTreasuryTypes.rejected, (state, action) => {
            state.treasuryTypes.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getTreasuryTypes.pending, (state, action) => {
            state.treasuryTypes.isFetching = true;
            //state.message = undefined;
        })

        builder.addCase(getCurrencies.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.currenciesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        code: val.code?.value ?? "",
                        icon: val.iconsource?.value ?? "",
                        form: val.form?.formid,
                        symbol: val.symbol?.value ?? "",
                        decimalPlaces: val.decimalplaces?.value ?? "",
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.currencies.numberOfResults) {
                    state.currencies.hasMore = false;
                }
                state.currencies.items = state.currencies.items.concat(r);


                //console.log(state.items)

            } else {
                state.currencies.hasMore = false;
            }
            state.currencies.isFetching = false

        })
        builder.addCase(getCurrencies.rejected, (state, action) => {
            state.currencies.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getCurrencies.pending, (state, action) => {
            state.currencies.isFetching = true;
            //state.message = undefined;
        })


        



        builder.addCase(getLimitations.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.limitationsList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        isActive: val.isactive?.value ?? undefined,
                        type: val.type?.value ?? undefined,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.limitations.numberOfResults) {
                    state.limitations.hasMore = false;
                }
                state.limitations.items = state.limitations.items.concat(r);
            } else {
                state.limitations.hasMore = false;
            }
            state.limitations.isFetching = false

        })
        builder.addCase(getLimitations.rejected, (state, action) => {
            state.limitations.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getLimitations.pending, (state, action) => {
            state.limitations.isFetching = true;
            //state.message = undefined;
        })
    }
})

export const { reset, dismissMessage, setStage1, resetTreasuryTypes, resetCurrencies, resetLimitations, setIsFilteredTreasuryTypesSet, setSelectedTreasuryTypeItems, setIsFilteredCurrenciesSet, setIsFilteredLimitationsSet, setSelectedCurrencyItems, setSelectedLimitationItems } = treasuriesFormSlice.actions

export default treasuriesFormSlice.reducer


