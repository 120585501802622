import { ComboBox, DefaultButton, DialogFooter, Icon, IconButton, IPersonaProps, IPickerItemProps, IStackTokens, ISuggestionItemProps, ISuggestionModel, ISuggestionsProps, ITag, Label, MessageBar, MessageBarType, Modal, NormalPeoplePicker, PeoplePickerItem, Persona, PersonaSize, PrimaryButton, Spinner, SpinnerSize, Stack, TagItemSuggestion, TagPicker, Text, TextField, Toggle, TooltipHost, ValidationState } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { FormEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks";
import { add, dismissMessage, get, getBaseCurrencies, getQuoteCurrencies, reset, resetBaseCurrencies, resetQuoteCurrencies, setIsFilteredBaseCurrenciesSet, setIsFilteredQuoteCurrenciesSet, setSelectedBaseCurrencyItems, setSelectedQuoteCurrencyItems, setSelecteMainPairItems, setStage1, update, updatePrices } from "./CurrencyPairsFormSlice";
import * as Yup from 'yup';
import { DynamicInputField, InputField } from "../../controls/Controls";
import { inputs } from "../../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../../app/Api";
import { Message } from "../../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../../FormProps";
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog";
import { formatDate, timestampToDate } from "../../../../app/Helpers";
import { AuthenticateReply } from "../../../../repository/UserManagement/authentication_pb";
import { DynamicField } from "../../../common/DynamicFields/DynamicFields";
import { ExtraField } from "../../../../repository/Accountant/common_pb";
import { TableState } from "../../../common/Table/TableSate";
import { AccountStatus, LimitationType, Operations } from "../../../../app/Enums";
import { GetCurrenciesRequest } from "../../../../repository/Accountant/currency_pb"
import { AddCurrencyPairRequest, GetCurrencyPairRequest, UpdateCurrencyPairPriceRequest, UpdateCurrencyPairResponse, AddCurrencyPairResponse, UpdateCurrencyPairPriceResponse, UpdateCurrencyPairRequest, GetCurrencyPairsRequest } from "../../../../repository/Trading/currency_pair_pb";
import { List } from "../../../common/List/List";
import { Popup } from "../../../common/Popup/Popup";
import { CurrenciesPage } from "../../../pages/Accountant/Currencies/CurrenciesPage";
import { setSelectedCategoryItems } from "../../../pages/Trading/CurrencyPairs/CurrencyPairsPageSlice";

let req: AddCurrencyPairRequest;
let updateReq: UpdateCurrencyPairPriceRequest;
let baseCurrenciesReq: GetCurrenciesRequest;
let quoteCurrenciesReq: GetCurrenciesRequest;


let promise: any;
let getFilterPromise: any;
let org: number = -1;
let current: AuthenticateReply.AsObject;

export const CurrencyPairsPriceForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();
    const [currentAction, setCurrenctAction] = useState(0)

    const state: {
        isLoading: boolean, message: ApiMessage | undefined, stage1: any, baseCurrencies: TableState
        quoteCurrencies: TableState,

    } = useAppSelector((state) => {

        return {
            isLoading: state.currencyPairsForm.isLoading, message: state.currencyPairsForm.message, stage1: state.currencyPairsForm.stage1,
            baseCurrencies: state.currencyPairsForm.baseCurrencies,
            quoteCurrencies: state.currencyPairsForm.quoteCurrencies,



        }
    })

    useEffect(() => {
        var l = searchParams.get('org');
        req = new AddCurrencyPairRequest();
        updateReq = new UpdateCurrencyPairPriceRequest()
        baseCurrenciesReq = new GetCurrenciesRequest();
        quoteCurrenciesReq = new GetCurrenciesRequest();

        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                req.setOrganization(wrapper)
                baseCurrenciesReq.setOrganization(wrapper)
                quoteCurrenciesReq.setOrganization(wrapper)
            }
        }

        if (props.type == FormType.EDIT) {
            if (props.fetchData) {
                let r = new GetCurrencyPairRequest()
                r.setId(props?.renderObject?.id);
                promise?.abort()
                promise = dispatch(get({ body: r, headers: getHeaders() }))
            } else {
                dispatch(setStage1(props?.renderObject))
            }
        }
        const boolv = new BoolValue();
        boolv.setValue(true);
        baseCurrenciesReq.setNumofresults(state.baseCurrencies.numberOfResults)
        baseCurrenciesReq.setOrder(state.baseCurrencies.isDescending)
        baseCurrenciesReq.setIsavailable(boolv)

        quoteCurrenciesReq.setNumofresults(state.quoteCurrencies.numberOfResults)
        quoteCurrenciesReq.setOrder(state.quoteCurrencies.isDescending)
        quoteCurrenciesReq.setIsavailable(boolv)



        if (props.renderObject?.baseCurrencyId) {
            dispatch(setSelectedBaseCurrencyItems(([{ id: props.renderObject.baseCurrencyId, name: props.renderObject.baseCurrencyName, decimalPlaces: props.renderObject.baseCurrencyDecimalPlaces }])))

        }

        if (props.renderObject?.quoteCurrencyId)
            dispatch(setSelectedQuoteCurrencyItems(([{ id: props.renderObject.quoteCurrencyId, name: props.renderObject.quoteCurrencyName, decimalPlaces: props.renderObject.quoteCurrencyDecimalPlaces }])))





        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        }
        return () => { //clean up
            promise?.abort();
            dispatch(reset());
        }
    }, [])






    return (
        <>

            <Formik
                enableReinitialize={true}
                initialValues={{
                    sellPrice: (props.renderObject?.sellPrice && props.renderObject?.sellPrice) ? Number(props.renderObject?.sellPrice).toFixed(state.quoteCurrencies.selected.at(0)?.decimalPlaces ?? 9) : "",
                    buyPrice: (props.renderObject?.buyPrice) ? Number(props.renderObject?.buyPrice).toFixed(state.quoteCurrencies.selected.at(0)?.decimalPlaces ?? 9) : "",
                    tradingPrice: (props.renderObject?.tradingPrice) ? Number(props.renderObject?.tradingPrice).toFixed(state.quoteCurrencies.selected.at(0)?.decimalPlaces ?? 9) : "",

                }}

                validationSchema={Yup.object({
                    buyPrice: inputs.buyPrice,
                    sellPrice: inputs.sellPrice,
                    tradingPrice: inputs.tradingPrice,

                })}

                onSubmit={(values, actions) => {
                    if (props.type == FormType.ADD) {


                    } else {
                        updateReq.setId(props.renderObject?.id);

                        updateReq.setBuyprice(values.buyPrice?.replace(/,/g, '') + "")
                        updateReq.setSellprice(values.sellPrice?.replace(/,/g, '') + "")
                        updateReq.setTradingprice(values.tradingPrice?.replace(/,/g, '') + "")

                        promise = dispatch(updatePrices({ body: updateReq, headers: getHeaders() }))

                        promise.unwrap()
                            .then((res: UpdateCurrencyPairPriceResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            ...props.renderObject,
                                            buyPrice: values.buyPrice?.replace(/,/g, ''),
                                            sellPrice: values.sellPrice?.replace(/,/g, ''),
                                            tradingPrice: values.tradingPrice?.replace(/,/g, ''),
                                            priceUpdate: formatDate(timestampToDate(res.success?.updateddate?.seconds, res.success?.updateddate?.nanos)),

                                        });
                                    }
                                }
                                actions.setSubmitting(false)
                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })



                    }
                }}

            >
                {formkikProps => (
                    <Form >
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? (state.message.data != 401) ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            /> :
                                <SessionExpiredDialog />
                                : null
                            }




                            <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: props.childrenGap }}>
                                <Stack.Item grow>
                                    <Field name="buyPrice" label={t("buyPrice")} placeholder={t("buyPrice")}
                                        component={InputField} disabled={state.isLoading} maxLength={15}
                                        onBlur={(event: any) => {
                                            if (isNaN(Number(formkikProps.values.buyPrice?.replace(/,/g, '')))) {
                                                formkikProps.handleBlur(event);
                                                return;
                                            }
                                            let quote = state.quoteCurrencies.selected.at(0);
                                            if (quote)
                                                formkikProps.setFieldValue("buyPrice", Number(formkikProps.values.buyPrice?.replace(/,/g, '')).toFixed(quote?.decimalPlaces ?? 9))
                                            else
                                                formkikProps.setFieldValue("buyPrice", Number(formkikProps.values.buyPrice?.replace(/,/g, '')).toFixed(9))
                                            formkikProps.handleBlur(event);
                                        }}
                                        required />
                                </Stack.Item>
                                <Stack.Item grow>
                                    <Field name="sellPrice" label={t("sellPrice")} placeholder={t("sellPrice")}
                                        component={InputField} disabled={state.isLoading} maxLength={15}
                                        onBlur={(event: any) => {
                                            if (isNaN(Number(formkikProps.values.sellPrice?.replace(/,/g, '')))) {
                                                formkikProps.handleBlur(event);
                                                return;
                                            }
                                            let quote = state.quoteCurrencies.selected.at(0);
                                            if (quote)
                                                formkikProps.setFieldValue("sellPrice", Number(formkikProps.values.sellPrice?.replace(/,/g, '')).toFixed(quote?.decimalPlaces ?? 9))
                                            else
                                                formkikProps.setFieldValue("sellPrice", Number(formkikProps.values.sellPrice?.replace(/,/g, '')).toFixed(9))
                                            formkikProps.handleBlur(event);
                                        }}
                                        required />
                                </Stack.Item>
                                <Stack.Item grow>
                                    <Field name="tradingPrice" label={t("tradingPrice")} placeholder={t("tradingPrice")}
                                        component={InputField} disabled={state.isLoading} maxLength={15}
                                        onBlur={(event: any) => {
                                            if (isNaN(Number(formkikProps.values.tradingPrice?.replace(/,/g, '')))) {
                                                formkikProps.handleBlur(event);
                                                return;
                                            }
                                            let quote = state.quoteCurrencies.selected.at(0);
                                            if (quote)
                                                formkikProps.setFieldValue("tradingPrice", Number(formkikProps.values.tradingPrice?.replace(/,/g, '')).toFixed(quote?.decimalPlaces ?? 9))
                                            else
                                                formkikProps.setFieldValue("tradingPrice", Number(formkikProps.values.tradingPrice?.replace(/,/g, '')).toFixed(9))
                                            formkikProps.handleBlur(event);
                                        }}
                                        required />
                                </Stack.Item>

                            </Stack>

                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("add") : t("edit")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={props.type == FormType.ADD ? t("discard") : t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>



                    </Form>
                )}
            </Formik>
        </>
    );

}

