import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ProgressIndicator, Pivot, PivotItem, Separator, TooltipHost } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../../../../app/Hooks"
import { getPage } from "../../../../../../../app/Pages"
import { setPage } from "../../../../../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../../../../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../../../../../common/Table/Table"
import { ApiMessage, getHeaders } from "../../../../../../../app/Api"
import { useId, useBoolean } from '@fluentui/react-hooks';

import { Message } from "../../../../../../common/Message/Message"
import { ShareBox } from "../../../../../../common/ShareBox/ShareBox"
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { dismissMessage, get, reset, resetItem, updateItem } from "./OrderTransactionDetailsPageSlice"
import { Popup } from "../../../../../../common/Popup/Popup"
import { FormType } from "../../../../../../forms/FormProps"
import { DynamicField } from "../../../../../../common/DynamicFields/DynamicFields"
import { DeleteCurrencyRequest, GetCurrencyRequest } from "../../../../../../../repository/Accountant/currency_pb"
import { CurrenciesForm } from "../../../../../../forms/Accountant/Currencies/CurrenciesForm"
import { CurrencyForm, OrderAction, OrderStatus, OrderType } from "../../../../../../../app/Enums"
import { TextView } from "../../../../../../common/DataView/TextView"
import { ProfileView } from "../../../../../../common/DataView/ProfileView"
import { DeleteCurrencyPairRequest, GetCurrencyPairRequest, UpdateCurrencyPairActivityRequest } from "../../../../../../../repository/Trading/currency_pair_pb"
import { LinkView } from "../../../../../../common/DataView/LinkView"
import { currencyFormatter, printComponent, normalizeKey } from "../../../../../../../app/Helpers"
import { CurrencyPairsForm } from "../../../../../../forms/Trading/CurrencyPairs/CurrencyPairsForm"
import { GetOrderRequest, GetOrderTransactionRequest } from "../../../../../../../repository/Trading/order_pb"
import { useReactToPrint } from "react-to-print"


const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};


let req: GetOrderTransactionRequest;

let org: number = -1;

let getPromise: any;
let actionPromise: any;


const key = "trading/orderTransactions/details"
export const OrderTransactionDetailsPage: React.FunctionComponent = () => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    let { id } = useParams();
    const { hash } = useLocation();
    const [selectedKey, setSelectedKey] = useState("0");

    const state: {
        isChangeStateLoading: boolean,
        message: ApiMessage | undefined,
        appTheme: string, isDarkModeEnabled: boolean,
        isLoading: boolean,
        item: any
    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.orderTransactionDetailsPage.isChangeStateLoading,
            message: state.orderTransactionDetailsPage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            isLoading: state.orderTransactionDetailsPage.isLoading,
            item: state.orderTransactionDetailsPage.item,

        }
    })
    const calloutTargetId = useId('callout-target');
    const labelId = useId('callout-label');
    const deleteActionConfirmationcalloutTargetId = useId('delete-action-callout-target');
    const activityActionConfirmationcalloutTargetId = useId('activity-action-callout-target');
    const ActionConfirmationLabelId = useId('action-callout-label');
    const shareCalloutLabelId = useId('share-callout-label');
    const shareTargetId = useId('share-callout-target');

    const [isActionConfirmationCalloutVisible, { toggle: toggleisActionConfirmationCalloutVisible }] = useBoolean(false);
    const [isShareCalloutVisible, { toggle: toggleisShareCalloutVisible }] = useBoolean(false);

    const [currentAction, setCurrenctAction] = useState(0)
    const ref = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => {
            return printComponent(ref.current?.cloneNode(true), true)
        }

    });

    const getActionId = (action: number): string => {
        if (action == 1) {
            return deleteActionConfirmationcalloutTargetId;
        } else if (action == 2) {
            return activityActionConfirmationcalloutTargetId;
        } else {
            return ""
        }
    }


    useEffect(() => {
        req = new GetOrderTransactionRequest();
        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {

            }
        }
        const page = getPage(key)
        dispatch(setTitle(t(normalizeKey(page?.title))));
        dispatch(setPage(page));

        if (id) {
            req.setId(id)
            getPromise = dispatch(get({ body: req, headers: getHeaders() }))

        } else {
            navigate(-1)
        }


        return () => { //clean up
            getPromise?.abort();
            actionPromise?.abort();
            dispatch(reset());
        }
    }, [])



    const _selectionItems: ICommandBarItemProps[] = [
        {
            key: 'back',
            text: t('back'),
            title: t('back'),
            iconProps: { iconName: 'back' },
            disabled: state.isLoading,
            onClick: () => {
                navigate(-1)
            }
        },
        {
            key: 'refresh',
            text: t('refresh'),
            title: t('refresh'),
            iconProps: { iconName: 'refresh' },
            disabled: state.isLoading,
            onClick: () => {
                dispatch(resetItem())
                getPromise = dispatch(get({ body: req, headers: getHeaders() }))
            }
        },


   {
            key: 'logs',
            text: t("logs"),
            iconProps: { iconName: "PageList", styles: { root: { fontWeight: "bold" } } },
            title: t("logs"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/logs?resourceId=" + state.item?.id + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },
        {
            key: 'share',
            text: t("share"),
            title: t("share"),
            iconProps: { id: shareTargetId, iconName: 'Share', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                toggleisShareCalloutVisible()
            },

        },
        {
            key: 'print',
            text: t("print"),
            iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
            title: t("print"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    handlePrint();
                }
            }
        },
    ];



    return (
        <Stack >
            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }



            {isShareCalloutVisible ? (
                <Callout
                    ariaLabelledBy={shareCalloutLabelId}
                    target={`#${shareTargetId}`}
                    onDismiss={toggleisShareCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <ShareBox onDismiss={toggleisShareCalloutVisible} title={t("shareOrderTransaction")} value={`https://${window.location.host}/orderTransactions/${state.item?.id}` + ((org == -1) ? "" : "?org=" + org)}></ShareBox>
                </Callout>
            ) : null}





            {state.isLoading ? <ProgressIndicator /> :

                !state.item ? null :
                    <div ref={ref}>
                        <CommandBar
                            items={_selectionItems}
                        />
                        <Stack tokens={{ childrenGap: 10, padding: "0px 33% 10px 3%" }}>
                            <Stack horizontal tokens={{ childrenGap: 10 }}>

                                <TextView label={t("id")} value={state.item?.id} />


                                <TextView label={t("createdDate")} value={state.item?.createdDate} />

                                {state.item?.addedBy ? <ProfileView label={t("addedBy")} value={state.item?.addedBy == "" ? state.item?.addedById : state.item?.addedBy}
                                    url={"/management/users/" + state.item?.addedById + ((org == -1) ? "" : "?org=" + org)}
                                    text={state.item?.addedBy == "" ? state.item?.addedById : state.item?.addedBy}
                                    size={PersonaSize.size40} />

                                    : undefined}

                            </Stack>

                            <Stack horizontal tokens={{ childrenGap: 10 }}>

                            <LinkView label={t("transactionNumber")} value={state.item?.referenceTransactionId}
                                    url={"/account-transactions/" + state.item?.referenceTransactionInternalId + ((org == -1) ? "" : "?org=" + org)} />


                                <LinkView label={state.item?.orderTradeAction == OrderAction.SELL?  t( "sellOrder") : t("buyOrder")} value={state.item?.orderId}
                                    url={"/orders/" + state.item?.orderId + ((org == -1) ? "" : "?org=" + org)} />

                                <LinkView label={state.item?.referenceOrderTradeAction == OrderAction.SELL?  t( "sellOrder") : t("buyOrder")} value={state.item?.referenceOrderId}
                                    url={"/orders/" + state.item?.referenceOrderId + ((org == -1) ? "" : "?org=" + org)} />

                            </Stack>

                            <Stack horizontal tokens={{ childrenGap: 10 }}>

                                <LinkView label={t("from")} value={state.item?.fromAccountName == "" ? state.item?.fromAccountId : state.item?.fromAccountName}
                                    url={"/accounts/" + state.item?.fromAccountInternalId + ((org == -1) ? "" : "?org=" + org)} />

                                <LinkView label={t("to")} value={state.item?.toAccountName == "" ? state.item?.toAccountId : state.item?.toAccountName}
                                    url={"/accounts/" + state.item?.toAccountInternalId + ((org == -1) ? "" : "?org=" + org)} />

                                <TextView label={t("amount")} value={currencyFormatter(state.item?.amount, { significantDigits: state.item?.currencyDecimalPlaces ?? 9, symbol: state.item?.currencySymbol })} />


                            </Stack>


                            <Stack horizontal tokens={{ childrenGap: 10 }}>


                                {state.item?.clientId ? <ProfileView label={t("client")} value={state.item?.clientName == "" ? state.item?.clientId : state.item?.clientName}
                                    url={"/customers/users/" + state.item?.clientId + ((org == -1) ? "" : "?org=" + org)}
                                    text={state.item?.clientName == "" ? state.item?.clientId : state.item?.clientName}
                                    size={PersonaSize.size40} /> : undefined}





                            </Stack>

                        </Stack>
                    </div>

            }


        </Stack>



    );
}




