import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RpcError } from "grpc-web";
import { ApiMessage, APIRequest, APIResponse, API_URL, getSession, refreshSession, toApiMessage } from "../../../app/Api";
import { formatDate, timestampToDate } from "../../../app/Helpers";
import { AuthenticationEndpointClient } from "../../../repository/UserManagement/AuthenticationServiceClientPb";
import { LogoutRequest, LogoutResponse } from "../../../repository/UserManagement/authentication_pb";



export interface NavHeaderState {
  title: string
}

const initialState: NavHeaderState = {
  title: "",
}


const client = new AuthenticationEndpointClient(API_URL, null, null);



export const logout = createAsyncThunk<APIResponse<LogoutResponse.AsObject>, APIRequest<LogoutRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/settings/logout',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.logout(req.body, req.headers ?? {});
                return { response: r.toObject(), metadata: { id: req.body.getSessionid() } } as APIResponse<LogoutResponse.AsObject>;
            } catch (err) {

                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {
                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }


)


export const navHeaderSlice = createSlice({
  name: 'navHeader',
  initialState,
  reducers: {
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
      document.title = state.title;
    },
    
  },
  extraReducers: (builder) => {


    builder.addCase(logout.fulfilled, (state, { payload }) => {
        //  state.message = toApiMessage(payload?.response?.success?.message);


    })
    builder.addCase(logout.rejected, (state, action) => {
       // if (action.payload) {
         //   state.message = action.payload;
      //  }
    })
    builder.addCase(logout.pending, (state, action) => {
    })

}
})

export const { setTitle } = navHeaderSlice.actions

export default navHeaderSlice.reducer


