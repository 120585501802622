import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ISearchBoxProps, IPeoplePickerItemSelectedProps, PeoplePickerItem, ValidationState, NormalPeoplePicker, IPersonaProps, IPickerItemProps, DialogFooter, DatePicker, DayOfWeek, defaultDatePickerStrings, DocumentCard, DocumentCardPreview, DocumentCardTitle, DocumentCardActivity, IDocumentCardPreviewProps, ImageFit, DocumentCardLogo, IDocumentCardLogoProps, IDocumentCardStyles, mergeStyles, PivotItem, Pivot } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../app/Hooks"
import { getPage } from "../../..//app/Pages"
import { setPage } from "../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../common/Table/Table"
import { ApiMessage, clearSession, getHeaders, getSession } from "../../../app/Api"
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { currencyFormatter, formatDate, printComponent, normalizeKey, getCurrentLanguage } from "../../../app/Helpers"
import { TableState } from "../../common/Table/TableSate"
import { Message } from "../../common/Message/Message"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { bool, string } from "yup"
import { Popup } from "../../common/Popup/Popup"
import { useReactToPrint } from "react-to-print"
import { PageProps } from "../PageProps"
import { List } from "../../common/List/List"
import moment from "moment"
import { Form, Formik } from "formik"
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb"
import { AccountStatus, TreasuryStatus } from "../../../app/Enums"
import * as Yup from 'yup';
import { TradingView } from "../../common/TradingView/TradingView"



let org: number = -1;

const key = "dashboard"
export const DashboardPage: React.FunctionComponent = () => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [name, setName] = useState("");

    const state: {
        appTheme: string, isDarkModeEnabled: boolean,

    } = useAppSelector((state) => {
        return {
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,

        }
    })



    useEffect(() => {
        let current = getSession();

        if (!current) {
            clearSession();
            navigate("/login");
        }
        setName((current.profile?.firstname?.value ?? " ") + " " + (current.profile?.lastname?.value ?? " "))


        var l = searchParams.get('org');

        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
            }
        }

        const page = getPage(key)
        dispatch(setTitle(t(normalizeKey(page?.title))));
        dispatch(setPage(page));

        return () => { //clean up
        }
    }, [])



    const logoProps: IDocumentCardLogoProps = {
        logoIcon: 'OutlookLogo',
    };

    const cardStyles: IDocumentCardStyles = {
        root: { display: 'inline-block', marginRight: 20, width: 320, textAlign: "justify" },
    };
    const conversationTileClass = mergeStyles({ height: 210 });
    const handleNavClick = (event?: React.SyntheticEvent<HTMLElement>, url?: string) => {
        if (event) {
            event.preventDefault();
            navigate(url + ((org == -1) ? "" : "?org=" + org));
        }
    }
    return (

        <Pivot>
            <PivotItem
                headerText={t("navigation")}>
                <Stack tokens={{ padding: "20px", childrenGap: 20 }}>
                    <Stack.Item tokens={{ margin: "0px 0px 0px 20px" }}>
                        <Text variant={"large"} block><b>{t("welcome", { name: name })}</b></Text>
                    </Stack.Item>

                    <Stack horizontal horizontalAlign="center">


                        <DocumentCard
                            aria-label={
                                t("treasuriesDesc")
                            }
                            styles={cardStyles}

                        >
                            <DocumentCardLogo logoIcon="Settings" />
                            <div className={conversationTileClass}>
                                <DocumentCardTitle title={t("finManagment")} />
                                <DocumentCardTitle
                                    title={t("finManagmentDesc")}
                                    styles={{ root: { wordWrap: "break-word" } }}
                                    showAsSecondaryTitle
                                />
                                <ul>
                                    <li>  <Link href={"/currencies" + ((org == -1) ? "" : "?org=" + org)} onClick={(e) => {
                                        handleNavClick(e, "/currencies")
                                    }} underline>
                                        {t("currencies")}
                                    </Link></li>
                                    <li><Link href={"/fees" + ((org == -1) ? "" : "?org=" + org)} onClick={(e) => {
                                        handleNavClick(e, "/fees")
                                    }} underline>
                                        {t("fees")}
                                    </Link></li>
                                    <li><Link href={"/limitations" + ((org == -1) ? "" : "?org=" + org)} onClick={(e) => {
                                        handleNavClick(e, "/limitations")
                                    }} underline>
                                        {t("limitations")}
                                    </Link></li>
                                    <li><Link href={"/client-portal-settings" + ((org == -1) ? "" : "?org=" + org)} onClick={(e) => {
                                        handleNavClick(e, "/client-portal-settings")
                                    }} underline>
                                        {t("organizationClientPortalSetting")}
                                    </Link></li>

                                </ul>
                            </div>
                        </DocumentCard>

                        <DocumentCard
                            aria-label={
                                t("treasuriesDesc")
                            }
                            styles={cardStyles}


                        >
                            <DocumentCardLogo logoIcon="Bank" />
                            <div className={conversationTileClass}>
                                <DocumentCardTitle title={t("treasuries")} shouldTruncate />
                                <DocumentCardTitle
                                    title={t("treasuriesDesc")}

                                    showAsSecondaryTitle
                                />
                                <ul>
                                    <li>  <Link href={"/treasuries" + ((org == -1) ? "" : "?org=" + org)} onClick={(e) => {
                                        handleNavClick(e, "/treasuries")
                                    }} underline>
                                        {t("treasuries")}
                                    </Link></li>
                                    <li><Link href={"/treasury-types" + ((org == -1) ? "" : "?org=" + org)} onClick={(e) => {
                                        handleNavClick(e, "/treasury-types")
                                    }} underline>
                                        {t("treasuryTypes")}
                                    </Link></li>

                                </ul>
                            </div>
                        </DocumentCard>

                        <DocumentCard
                            aria-label={
                                t("customersDesc")
                            }
                            styles={cardStyles}
                            onClick={(e) => {
                                handleNavClick(e, "/customers/users")
                            }}

                        >
                            <DocumentCardLogo logoIcon="People" />
                            <div className={conversationTileClass}>
                                <DocumentCardTitle title={t("customers")} shouldTruncate />
                                <DocumentCardTitle
                                    title={t("customersDesc")}

                                    showAsSecondaryTitle
                                />
                            </div>
                        </DocumentCard>

                    </Stack>

                    <Stack horizontal horizontalAlign="center">



                        <DocumentCard
                            aria-label={
                                t("accountsDesc")
                            }
                            styles={cardStyles}


                        >
                            <DocumentCardLogo logoIcon="PaymentCard" />
                            <div className={conversationTileClass}>
                                <DocumentCardTitle title={t("accounts")} shouldTruncate />
                                <DocumentCardTitle
                                    title={t("accountsDesc")}

                                    showAsSecondaryTitle
                                />
                                <ul>
                                    <li>  <Link href={"/accounts" + ((org == -1) ? "" : "?org=" + org)} onClick={(e) => {
                                        handleNavClick(e, "/accounts")
                                    }} underline>
                                        {t("accounts")}
                                    </Link></li>


                                    <li>  <Link href={"/transactions" + ((org == -1) ? "" : "?org=" + org)} onClick={(e) => {
                                        handleNavClick(e, "/transactions")
                                    }} underline>
                                        {t("transactionsStatement")}
                                    </Link></li>

                                    <li><Link href={"/account-types" + ((org == -1) ? "" : "?org=" + org)} onClick={(e) => {
                                        handleNavClick(e, "/account-types")
                                    }} underline>
                                        {t("accountTypes")}
                                    </Link></li>
                                    <li><Link href={"/reserved-balances" + ((org == -1) ? "" : "?org=" + org)} onClick={(e) => {
                                        handleNavClick(e, "/reserved-balances")
                                    }} underline>
                                        {t("reservedBalances")}
                                    </Link></li>
                                </ul>
                            </div>
                        </DocumentCard>

                        <DocumentCard
                            aria-label={
                                t("tradingDesc")
                            }
                            styles={cardStyles}

                        >
                            <DocumentCardLogo logoIcon="Financial" />
                            <div className={conversationTileClass}>
                                <DocumentCardTitle title={t("trading")} shouldTruncate />
                                <DocumentCardTitle
                                    title={t("tradingDesc")}

                                    showAsSecondaryTitle
                                />
                                <ul>
                                    <li>  <Link href={"/categories" + ((org == -1) ? "" : "?org=" + org)} onClick={(e) => {
                                        handleNavClick(e, "/categories")
                                    }} underline>
                                        {t("categories")}
                                    </Link></li>
                                    <li>  <Link href={"/currency-pairs" + ((org == -1) ? "" : "?org=" + org)} onClick={(e) => {
                                        handleNavClick(e, "/currency-pairs")
                                    }} underline>
                                        {t("currencyPairs")}
                                    </Link></li>
                                    <li><Link href={"/orders" + ((org == -1) ? "" : "?org=" + org)} onClick={(e) => {
                                        handleNavClick(e, "/orders")
                                    }} underline>
                                        {t("orders")}
                                    </Link></li>

                                    <li><Link href={"/chart" + ((org == -1) ? "" : "?org=" + org)} onClick={(e) => {
                                        handleNavClick(e, "/chart")
                                    }} underline>
                                        {t("tradingScreen")}
                                    </Link></li>

                                </ul>
                            </div>
                        </DocumentCard>
                        <DocumentCard
                            aria-label={
                                t("reportsDesc")
                            }
                            styles={cardStyles}

                        >
                            <DocumentCardLogo logoIcon="ReportDocument" />
                            <div className={conversationTileClass}>
                                <DocumentCardTitle title={t("reports")} shouldTruncate />
                                <DocumentCardTitle
                                    title={t("reportsDesc")}

                                    showAsSecondaryTitle
                                />
                                <ul>
                                    <li>  <Link href={"/reports/treasuryBalanceReport" + ((org == -1) ? "" : "?org=" + org)} onClick={(e) => {
                                        handleNavClick(e, "/reports/treasuryBalanceReport")
                                    }} underline>
                                        {t("treasuryBalanceReport")}
                                    </Link></li>
                                    <li><Link href={"/reports/accountBalanceReport" + ((org == -1) ? "" : "?org=" + org)} onClick={(e) => {
                                        handleNavClick(e, "/reports/accountBalanceReport")
                                    }} underline>
                                        {t("accountBalanceReport")}
                                    </Link></li>
                                    <li><Link href={"/reports/transactionTotalsReport" + ((org == -1) ? "" : "?org=" + org)} onClick={(e) => {
                                        handleNavClick(e, "/reports/transactionTotalsReport")
                                    }} underline>
                                        {t("transactionTotalsReport")}
                                    </Link></li>
                                    <li><Link href={"/reports/currencyExchangeRatesReport" + ((org == -1) ? "" : "?org=" + org)} onClick={(e) => {
                                        handleNavClick(e, "/reports/currencyExchangeRatesReport")
                                    }} underline>
                                        {t("currencyExchangeRatesReport")}
                                    </Link></li>
                                    <li><Link href={"/reports/tradingReport" + ((org == -1) ? "" : "?org=" + org)} onClick={(e) => {
                                        handleNavClick(e, "/reports/tradingReport")
                                    }} underline>
                                        {t("tradingReport")}
                                    </Link></li>
                                </ul>
                            </div>
                        </DocumentCard>
                    </Stack>

                    <Stack horizontal horizontalAlign="center">


                        <DocumentCard
                            aria-label={
                                t("managementDesc")
                            }
                            styles={cardStyles}
                            onClick={(e) => {
                                handleNavClick(e, "/management/users")
                            }}

                        >
                            <DocumentCardLogo logoIcon="Admin" />
                            <div className={conversationTileClass}>
                                <DocumentCardTitle title={t("management")} shouldTruncate />
                                <DocumentCardTitle
                                    title={t("managementDesc")}

                                    showAsSecondaryTitle
                                />
                            </div>
                        </DocumentCard>

                        <DocumentCard
                            aria-label={
                                t("logsDesc")
                            }
                            styles={cardStyles}
                            onClick={(e) => {
                                handleNavClick(e, "/logs")
                            }}

                        >
                            <DocumentCardLogo logoIcon="History" />
                            <div className={conversationTileClass}>
                                <DocumentCardTitle title={t("logs")} shouldTruncate />
                                <DocumentCardTitle
                                    title={t("logsDesc")}

                                    showAsSecondaryTitle
                                />
                            </div>
                        </DocumentCard>
                        <DocumentCard
                            aria-label={
                                t("organizationsDesc")
                            }
                            styles={cardStyles}
                            onClick={(e) => {
                                handleNavClick(e, "/organizations")
                            }}
                        >
                            <DocumentCardLogo logoIcon="Org" />
                            <div className={conversationTileClass}>
                                <DocumentCardTitle title={t("organizations")} shouldTruncate />
                                <DocumentCardTitle
                                    title={t("organizationsDesc")}
                                    showAsSecondaryTitle
                                />
                            </div>
                        </DocumentCard>

                    </Stack>

                </Stack >
            </PivotItem>
            <PivotItem headerText={t("globalMarket")}>
                <Stack verticalFill tokens={{ padding: "20px", childrenGap: 20 }} >
                    <Stack.Item tokens={{ margin: "0px 0px 0px 20px" }}>
                        <Text variant={"large"} block><b>{t("welcome", { name: name })}</b></Text>
                    </Stack.Item>
                    <TradingView></TradingView>
                </Stack>
            </PivotItem>
            <PivotItem headerText={t("localMarket")}>
                <Stack verticalFill tokens={{ padding: "20px", childrenGap: 20 }} >
                    <Stack.Item tokens={{ margin: "0px 0px 0px 20px" }}>
                        <Text variant={"large"} block><b>{t("welcome", { name: name })}</b></Text>
                    </Stack.Item>
                    <Stack.Item grow>
                        <iframe style={{ height: "calc(100vh - 235px)", border: 0, width: "100%" }}
                            src={"https://markets.sanadapp.ly/?isIframe=1&isDark=" + (state.isDarkModeEnabled ? "1" : "0") + "&lang=" + getCurrentLanguage()}></iframe>
                    </Stack.Item>
                </Stack>
            </PivotItem>
        </Pivot>




    );
}




