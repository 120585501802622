import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIResponse, refreshSession, getSession, ACCOUNTANT_API_URL, APIRequest } from '../../../../../app/Api';
import { TreasuryStatus } from '../../../../../app/Enums';
import { formatDate, timestampToDate } from '../../../../../app/Helpers';
import { AccountEndpointClient } from '../../../../../repository/Accountant/AccountServiceClientPb';
import { AccountOperationEndpointClient } from '../../../../../repository/Accountant/Account_operationServiceClientPb';
import { GetBalanceRequest, GetBalanceResponse } from '../../../../../repository/Accountant/account_operation_pb';
import { LimitationEndpointClient } from '../../../../../repository/Accountant/LimitationServiceClientPb';
import { GetLimitationsRequest, GetLimitationsResponse } from '../../../../../repository/Accountant/limitation_pb';
import { TreasuryEndpointClient } from '../../../../../repository/Accountant/TreasuryServiceClientPb';
import { CloseTreasuryRequest, GetTreasuryRequest, GetTreasuryResponse, UpdateTreasuryTypeResponse, UpdateTreasuryStatusRequest, UpdateTreasuryStatusResponse, CloseTreasuryResponse, UpdateTreasuryLimitationResponse, UpdateTreasuryLimitationRequest, UpdateTreasuryTypeRequest } from '../../../../../repository/Accountant/treasury_pb';
import { TreasuryTypeEndpointClient } from '../../../../../repository/Accountant/Treasury_typeServiceClientPb';
import { GetTreasuryTypesRequest, GetTreasuryTypesResponse } from '../../../../../repository/Accountant/treasury_type_pb';
import { DynamicField } from '../../../../common/DynamicFields/DynamicFields';
import { TableState } from '../../../../common/Table/TableSate';


export interface TreasuryDetailsPageState {
    item: any
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    isLoading: boolean,
    limitations: TableState,
    treasuryTypes: TableState


}

const initialState: TreasuryDetailsPageState = {
    item: undefined,
    isChangeStateLoading: false,
    message: undefined,
    isLoading: false,
    limitations: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    treasuryTypes: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },


}

const client = new TreasuryEndpointClient(ACCOUNTANT_API_URL, null, null);
 const clienLimition = new LimitationEndpointClient(ACCOUNTANT_API_URL, null, null);
 const clientTreasuryType = new TreasuryTypeEndpointClient(ACCOUNTANT_API_URL, null, null);


export const get = createAsyncThunk<GetTreasuryResponse.AsObject, APIRequest<GetTreasuryRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/treasuryDetails/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getTreasury(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)



export const updateStatus = createAsyncThunk<APIResponse<UpdateTreasuryStatusResponse.AsObject>, APIRequest<UpdateTreasuryStatusRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/treasuryDetails/updateStatus',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.updateStatus(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId(), status: req.body.getStatus() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)



export const getTreasuryTypes = createAsyncThunk<GetTreasuryTypesResponse.AsObject, APIRequest<GetTreasuryTypesRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/treasuryDetails/treasuryTypes/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clientTreasuryType.getTreasuryTypes(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)



export const close = createAsyncThunk<APIResponse<CloseTreasuryResponse.AsObject>, APIRequest<CloseTreasuryRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/treasuryDetails/close',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.close(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)


export const updateLimit = createAsyncThunk<APIResponse<UpdateTreasuryLimitationResponse.AsObject>, APIRequest<UpdateTreasuryLimitationRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/treasuryDetails/updateLimitation',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.updateLimitation(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId(), limitationId: req.body.getLimitation() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)


export const updateType = createAsyncThunk<APIResponse<UpdateTreasuryTypeResponse.AsObject>, APIRequest<UpdateTreasuryTypeRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/treasuryDetails/updateType',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.updateType(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId(), type: req.body.getTreasurytype() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)


export const getLimitations = createAsyncThunk<GetLimitationsResponse.AsObject, APIRequest<GetLimitationsRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/treasuryDetails/limitations/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clienLimition.getLimitations(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)




export const treasuryDetailsPageSlice = createSlice({
    name: 'pages/accountant/treasuryDetails',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;

        },
        reset: (state) => {
            state.message = initialState.message;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
            state.limitations = initialState.limitations;
            state.treasuryTypes = initialState.treasuryTypes;


        },

        resetItem: (state) => {
            state.item = undefined;

        },

        updateItem: (state, action: PayloadAction<any>) => {
            state.item = action.payload;

        },

        resetTreasuryTypes: (state) => {
            state.treasuryTypes.items = [];
            state.treasuryTypes.hasMore = true;
        },


        resetLimitations: (state) => {
            state.limitations.items = [];
            state.limitations.hasMore = true;
        },


        setSelectedLimitationItems: (state, action: PayloadAction<any[]>) => {
            state.limitations.selected = action.payload;

        },

   
        setIsFilteredLimitationsSet: (state, action: PayloadAction<boolean>) => {
            state.limitations.isFilteredSet = action.payload;
        },

         setSelectedTreasuryTypeItems: (state, action: PayloadAction<any[]>) => {
            state.treasuryTypes.selected = action.payload;

        },
        setIsFilteredTreasuryTypesSet: (state, action: PayloadAction<boolean>) => {
            state.treasuryTypes.isFilteredSet = action.payload;
        },


    },
    extraReducers: (builder) => {
       
        builder.addCase(getTreasuryTypes.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.treasurytypesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])

                if (r.length < state.treasuryTypes.numberOfResults) {
                    state.treasuryTypes.hasMore = false;
                }
                state.treasuryTypes.items = state.treasuryTypes.items.concat(r);
          
            } else {
                state.treasuryTypes.hasMore = false;
            }
            state.treasuryTypes.isFetching = false

        })
        builder.addCase(getTreasuryTypes.rejected, (state, action) => {
            state.treasuryTypes.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getTreasuryTypes.pending, (state, action) => {
            state.treasuryTypes.isFetching = true;
            //state.message = undefined;
        })


        builder.addCase(getLimitations.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.limitationsList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        isActive: val.isactive?.value ?? undefined,
                        type: val.type?.value ?? undefined,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.limitations.numberOfResults) {
                    state.limitations.hasMore = false;
                }
                state.limitations.items = state.limitations.items.concat(r);
            } else {
                state.limitations.hasMore = false;
            }
            state.limitations.isFetching = false

        })
        builder.addCase(getLimitations.rejected, (state, action) => {
            state.limitations.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getLimitations.pending, (state, action) => {
            state.limitations.isFetching = true;
            //state.message = undefined;
        })
        builder.addCase(updateStatus.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                state.item.status = payload?.metadata.status
            }
        })
        builder.addCase(updateStatus.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateStatus.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })
        builder.addCase(close.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                state.item.status = TreasuryStatus.CLOSED

            }

        })
        builder.addCase(close.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(close.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

      

        builder.addCase(updateLimit.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                state.item.limitationId = payload?.metadata.limitationId
                state.item.limitationName = state.limitations.selected.at(0).name;
                state.limitations.selected = [];

            }
        })
        builder.addCase(updateLimit.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateLimit.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })


        builder.addCase(updateType.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                state.item.treasuryTypeId = payload?.metadata.type
                state.item.treasuryTypeName = state.treasuryTypes.selected.at(0).name;
                state.treasuryTypes.selected = [];

            }
        })
        builder.addCase(updateType.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateType.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

        builder.addCase(get.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {

                let r = {
                    id: payload.success?.id,
                    internalId: payload.success?.internalid,
                    name: payload.success?.name?.value ?? "",
                    code: payload.success?.code?.value ?? "",
                    nature: payload.success?.nature?.id?.value ?? undefined,
                    treasuryTypeId: payload.success?.treasurytype?.id?.value ?? "",
                    treasuryTypeName: payload.success?.treasurytype?.name?.value ?? "",
                    status: payload.success?.status?.value ?? undefined,
                    createdDate: formatDate(timestampToDate(payload.success?.createddate?.seconds, payload.success?.createddate?.nanos)),
                    addedBy: (payload.success?.createdby?.firstname?.value ?? "") + " " + (payload.success?.createdby?.lastname?.value ?? ""),
                    addedById: payload.success?.createdby?.id?.value,
                    limitationId: payload.success?.limitation?.id?.value ?? "",
                    limitationName: payload.success?.limitation?.name?.value ?? "",
                    extraFields: [] as DynamicField[],
                    deposits: [] as any
                }

                if (payload?.success?.extrafieldsList) {
                    let count = 0
                    payload?.success?.extrafieldsList?.forEach(element => {
                        r.extraFields.push({ key: element.key, label: element.label, type: element.type, value: element.value })
                        count++
                    });
                }

                if (payload?.success?.depositsList) {
                    let count = 0
                    payload?.success?.depositsList?.forEach(element => {
                        r.deposits.push({  currencyId: element?.currency?.id?.value ?? "",
                        currencyName: element?.currency?.name?.value ?? "",
                        currencyCode: element?.currency?.code?.value ?? "",
                        currencyDecimalPlaces: element?.currency?.decimalplaces?.value ?? undefined,
                        currencyForm: element?.currency?.form?.value ?? undefined,
                        currencySymbol: element?.currency?.symbol?.value ?? "",
                        isActive: element.isactive?.value ?? undefined})
                        count++
                    });
                }

                state.item = r
            }
        })
        builder.addCase(get.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(get.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })
    }
})

export const { reset, dismissMessage, resetItem, resetTreasuryTypes, setIsFilteredTreasuryTypesSet, setSelectedTreasuryTypeItems, updateItem, resetLimitations, setIsFilteredLimitationsSet,setSelectedLimitationItems } = treasuryDetailsPageSlice.actions

export default treasuryDetailsPageSlice.reducer