import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, refreshSession, getSession, ACCOUNTANT_API_URL, TRADING_API_URL } from '../../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../../app/Helpers';
import { CurrencyEndpointClient } from '../../../../../repository/Accountant/CurrencyServiceClientPb';
import { DeleteCategoryReplay, DeleteCategoryRequest, DeleteCategoryResponse, GetCategoryRequest, GetCategoryResponse } from '../../../../../repository/Trading/category_pb';
import { DynamicField } from '../../../../common/DynamicFields/DynamicFields';
import { CategoryEndpointClient } from '../../../../../repository/Trading/CategoryServiceClientPb';


export interface CategoryDetailsPageState {
    item: any
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    isLoading: boolean

}

const initialState: CategoryDetailsPageState = {
    item: undefined,
    isChangeStateLoading: false,
    message: undefined,
    isLoading: false
}

const client = new CategoryEndpointClient(TRADING_API_URL, null, null);

export const get = createAsyncThunk<GetCategoryResponse.AsObject, APIRequest<GetCategoryRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/trading/categoryDetails/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getCategory(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)



export const deleteItem = createAsyncThunk<APIResponse<DeleteCategoryResponse.AsObject>, APIRequest<DeleteCategoryRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/trading/categoryDetails/delete',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.delete(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)

export const categoryDetailsPageSlice = createSlice({
    name: 'pages/trading/categoryDetails',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
        },

        resetItem: (state) => {
            state.item = undefined;

        },

        updateItem: (state, action: PayloadAction<any>) => {
            state.item = action.payload;

        }
    },
    extraReducers: (builder) => {
        builder.addCase(deleteItem.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
            }
        })
        builder.addCase(deleteItem.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(deleteItem.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

        builder.addCase(get.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {

                let r = {
                    id: payload?.success?.id,
                    nameAr: payload?.success?.namear?.value ?? "",
                    nameEn: payload?.success?.nameen?.value ?? "",
                    isAvailable: payload?.success?.isavailable?.value ?? undefined,
                    rank: payload?.success?.rank?.value ?? "",
                    createdDate: formatDate(timestampToDate(payload.success?.createddate?.seconds, payload.success?.createddate?.nanos)),
                    addedBy: (payload.success?.createdby?.firstname?.value ?? "") + " " + (payload.success?.createdby?.lastname?.value ?? ""),
                    addedById: payload.success?.createdby?.id?.value,
                    parentCategoryId: payload.success?.parent?.id?.value,
                    parentCategoryNameAr: payload.success?.parent?.namear?.value ?? "",
                    parentCategoryNameEn: payload.success?.parent?.nameen?.value ?? "",
                    iconSource: payload.success?.iconsource?.value,
                }



                state.item = r
            }
        })
        builder.addCase(get.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(get.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })
    }
})

export const { reset, dismissMessage, resetItem, updateItem } = categoryDetailsPageSlice.actions

export default categoryDetailsPageSlice.reducer