import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, refreshSession, getSession, ACCOUNTANT_API_URL } from '../../../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../../../app/Helpers';
import { FeeEndpointClient } from '../../../../../../repository/Accountant/FeeServiceClientPb';
import { DeleteFeeRequest, DeleteFeeResponse, GetFeeRequest, GetFeeResponse, RemoveFeeCollectionAccountRequest, RemoveFeeCollectionAccountResponse, UpdateFeeActivityRequest, UpdateFeeActivityResponse, UpdateFeeCollectionAccountActivityRequest, UpdateFeeCollectionAccountActivityResponse } from '../../../../../../repository/Accountant/fee_pb';
import { DynamicField } from '../../../../../common/DynamicFields/DynamicFields';
import { TableState } from '../../../../../common/Table/TableSate';


export interface FeeCollectionAccountsPageState {

    isChangeStateLoading: boolean,
    message?: ApiMessage,
    isLoading: boolean,
    collectionAccounts: TableState
    searchText: string | undefined,

}

const initialState: FeeCollectionAccountsPageState = {

    isChangeStateLoading: false,
    message: undefined,
    isLoading: false,
    collectionAccounts: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    searchText: undefined,

}

const client = new FeeEndpointClient(ACCOUNTANT_API_URL, null, null);

export const get = createAsyncThunk<GetFeeResponse.AsObject, APIRequest<GetFeeRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/feeDetails/collectionAccounts/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getFee(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)




export const removeCollectionAccount = createAsyncThunk<APIResponse<RemoveFeeCollectionAccountResponse.AsObject>, APIRequest<RemoveFeeCollectionAccountRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/feeDetails/collectionAccounts/remove',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.removeCollectionAccount(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId(), account: req.body.getAccount() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)



export const updateCollectionAccountActivity = createAsyncThunk<APIResponse<UpdateFeeCollectionAccountActivityResponse.AsObject>, APIRequest<UpdateFeeCollectionAccountActivityRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/feeDetails/collectionAccounts/updateActivity',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.updateCollectionAccountActivity(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId(), account: req.body.getAccount(), state: req.body.getIsactive() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)

export const feeCollectAccountsPageSlice = createSlice({
    name: 'pages/accountant/feeDetails/feeCollectionAccounts',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
            state.collectionAccounts = initialState.collectionAccounts;
            state.searchText = initialState.searchText;
            state.isLoading = initialState.isLoading;
        },

        resetSet: (state) => {
            state.collectionAccounts.items = [];
            state.collectionAccounts.hasMore = true;
        },
        setCollectionAccounts: (state, action: PayloadAction<any>) => {
            state.collectionAccounts.items = action.payload;
        },
        setSort: (state, action: PayloadAction<boolean>) => {
            state.collectionAccounts.isDescending = action.payload;
        },
        setNumberOfResults: (state, action: PayloadAction<number>) => {
            state.collectionAccounts.numberOfResults = action.payload;
        },
        setSelectedItems: (state, action: PayloadAction<any[]>) => {
            state.collectionAccounts.selected = action.payload;
        },
        setSearchText: (state, action: PayloadAction<string | undefined>) => {
            state.searchText = action.payload;
        },
        setIsFilteredSet: (state, action: PayloadAction<boolean>) => {
            state.collectionAccounts.isFilteredSet = action.payload;
        },
        addItem: (state, action: PayloadAction<any>) => {
            if (!state.collectionAccounts.isFilteredSet) {
                if (!state.collectionAccounts.hasMore && !state.collectionAccounts.isDescending) {
                    state.collectionAccounts.items.push(action.payload);
                } else if (state.collectionAccounts.isDescending) {
                    state.collectionAccounts.items.unshift(action.payload);
                }
            }
        },
        updateItem: (state, action: PayloadAction<any>) => {
            let selected = state.collectionAccounts.selected;
            if (action?.payload) {
                if (selected.length > 0 && selected.at(0).id == action?.payload?.id) {
                    selected[0] = action?.payload
                }
                var u = state.collectionAccounts.items.findIndex(e => e.id == action?.payload?.id)
                if (u >= 0) {
                    state.collectionAccounts.items[u] = action?.payload
                }
            }
        }


    },
    extraReducers: (builder) => {


        builder.addCase(removeCollectionAccount.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                let selected = state.collectionAccounts.selected;
                if (payload?.metadata) {
                    if (selected.length > 0 && selected.at(0).id == payload?.metadata.account) {
                        state.collectionAccounts.selected = []
                    }
                    var u = state.collectionAccounts.items.findIndex(e => e.id == payload?.metadata.account)
                    if (u >= 0) {
                        state.collectionAccounts.items.splice(u, 1);
                    }
                }
            }
        })
        builder.addCase(removeCollectionAccount.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(removeCollectionAccount.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

        builder.addCase(get.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {
                let r = {
                    id: payload?.success?.id,
                    name: payload?.success?.name?.value ?? "",
                    isAvailable: payload?.success?.isavailable?.value ?? undefined,
                    isActive: payload?.success?.isactive?.value ?? undefined,
                    feeEntries: [] as any[],
                    collectionAccounts: [] as any[],
                    createdDate: formatDate(timestampToDate(payload?.success?.createddate?.seconds, payload?.success?.createddate?.nanos)),
                    addedBy: (payload?.success?.createdby?.firstname?.value ?? "") + " " + (payload?.success?.createdby?.lastname?.value ?? ""),
                    addedById: payload?.success?.createdby?.id?.value,
                    extraFields: [] as DynamicField[],

                }
                if (payload?.success?.extrafieldsList) {
                    let count = 0
                    payload?.success?.extrafieldsList?.forEach(element => {
                        r.extraFields.push({ key: element.key, label: element.label, type: element.type, value: element.value })
                        count++
                    });
                }
                if (payload?.success?.feeentriesList) {
                    payload?.success?.feeentriesList?.forEach(element => {
                        r.feeEntries.push({ activationThreshold: element.activationthreshold, fixedValue: element.fixedvalue, percentageValue: element.percentagevalue })
                    });
                }
                if (payload?.success?.collectionaccountsList) {
                    let count = 0
                    payload?.success?.collectionaccountsList?.forEach(element => {
                        r.collectionAccounts.push({
                            id: element.account?.id?.value ?? "",
                            name: element.account?.name?.value,
                            createdDate: formatDate(timestampToDate(element?.addeddate?.seconds, element?.addeddate?.nanos)),
                            addedBy: (element?.addedby?.firstname?.value ?? "") + " " + (element?.addedby?.lastname?.value ?? ""),
                            addedById: element?.addedby?.id?.value,
                            isActive: element?.isactive?.value ?? undefined,
                            currencyId: element.account?.currency?.id?.value ?? "",
                            currencyName: element.account?.currency?.name?.value ?? "",
                            currencyCode: element.account?.currency?.code?.value ?? "",
                            currencyDecimalPlaces: element.account?.currency?.decimalplaces?.value ?? undefined,
                            currencyForm: element.account?.currency?.form?.value ?? undefined,
                            currencySymbol: element.account?.currency?.symbol?.value ?? "",
                        })
                        count++
                    });
                }
                state.collectionAccounts.items = r.collectionAccounts
                state.collectionAccounts.hasMore = false;
                state.collectionAccounts.isFetching = false;

            }
        })
        builder.addCase(get.rejected, (state, action) => {
            state.isLoading = false;
            state.collectionAccounts.isFetching = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(get.pending, (state, action) => {
            state.isLoading = true;
            state.collectionAccounts.isFetching = true;
            state.message = undefined;
        })

        builder.addCase(updateCollectionAccountActivity.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                let selected = state.collectionAccounts.selected;
                if (payload?.metadata) {
                    if (selected.length > 0 && selected.at(0).id == payload?.metadata.account) {
                        selected.at(0).isActive = payload?.metadata.state
                    }
                    var u = state.collectionAccounts.items.findIndex(e => e.id == payload?.metadata.account)
                    if (u >= 0) {
                        state.collectionAccounts.items.at(u).isActive = payload?.metadata.state
                    }

                }
            }
        })
        builder.addCase(updateCollectionAccountActivity.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateCollectionAccountActivity.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

    }
})

export const { reset, dismissMessage, updateItem, resetSet, setCollectionAccounts, setIsFilteredSet, setNumberOfResults, setSelectedItems, setSearchText, setSort, addItem } = feeCollectAccountsPageSlice.actions

export default feeCollectAccountsPageSlice.reducer