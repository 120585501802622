import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIResponse, refreshSession, getSession, ACCOUNTANT_API_URL, APIRequest } from '../../../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../../../app/Helpers';
import { TableState } from '../../../../../common/Table/TableSate';
import { GetUsersRequest, GetUsersResponse } from '../../../../../../repository/UserManagement/Customers/user_pb';
import { AuthorizationEndpointClient } from '../../../../../../repository/Accountant/AuthorizationServiceClientPb';
import { GetAuthorizationsRequest, GetAuthorizationsResponse, RevokeAuthorizationRequest, RevokeAuthorizationResponse } from '../../../../../../repository/Accountant/authorization_pb';
import { AuthorizationStatus } from '../../../../../../app/Enums';


export interface SignaturesPageState {
    set: TableState
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    searchText: string | undefined,
    filters: any[],
    statusFilterOption: { key: number, text: string },
    codeFilterOption: { value: string, text: string },
    tokenFilterOption: { value: string, text: string },

}

const initialState: SignaturesPageState = {

    set: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    isChangeStateLoading: false,
    message: undefined,
    searchText: undefined,
    filters: [],
    statusFilterOption: { key: 0, text: "" },
    codeFilterOption: { text: "", value: "" },
    tokenFilterOption: { text: "", value: "" },

}

const client = new AuthorizationEndpointClient(ACCOUNTANT_API_URL, null, null);

export const getItems = createAsyncThunk<GetAuthorizationsResponse.AsObject, APIRequest<GetAuthorizationsRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/accountDetails/signatures/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await client.getAuthorizations(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

export const revokeAutorization = createAsyncThunk<APIResponse<RevokeAuthorizationResponse.AsObject>, APIRequest<RevokeAuthorizationRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/accountDetails/signatures/revoke',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.revoke(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)


const getFilters = (state: SignaturesPageState): any[] => {
    const selected = [];

    if (state.statusFilterOption.key != 0) {
        selected.push({ key: 1, name: state.statusFilterOption.text });
    }
    if (state.codeFilterOption.value && state.codeFilterOption.value != "") {
        selected.push({ key: 2, name: state.codeFilterOption.text });
    }

    if (state.tokenFilterOption.value && state.tokenFilterOption.value != "") {
        selected.push({ key: 3, name: state.tokenFilterOption.text });
    }


    return selected;
}

export const signaturesPageSlice = createSlice({
    name: 'pages/accountant/accountDetails/signatures',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.set = initialState.set;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
            state.searchText = initialState.searchText;
            state.statusFilterOption = initialState.statusFilterOption;
            state.codeFilterOption = initialState.codeFilterOption;
            state.tokenFilterOption = initialState.tokenFilterOption;
            state.filters = initialState.filters;

        },
        resetSet: (state) => {
            state.set.items = [];
            state.set.hasMore = true;
        },


        setSort: (state, action: PayloadAction<boolean>) => {
            state.set.isDescending = action.payload;
        },
        setNumberOfResults: (state, action: PayloadAction<number>) => {
            state.set.numberOfResults = action.payload;
        },
        setSelectedItems: (state, action: PayloadAction<any[]>) => {
            state.set.selected = action.payload;
        },


        setSearchText: (state, action: PayloadAction<string | undefined>) => {
            state.searchText = action.payload;
        },
        setIsFilteredSet: (state, action: PayloadAction<boolean>) => {
            state.set.isFilteredSet = action.payload;
        },





        addItem: (state, action: PayloadAction<any>) => {
            if (!state.set.isFilteredSet) {
                if (!state.set.hasMore && !state.set.isDescending) {
                    state.set.items.push(action.payload);
                } else if (state.set.isDescending) {
                    state.set.items.unshift(action.payload);
                }
            }
        },
        updateItem: (state, action: PayloadAction<any>) => {
            let selected = state.set.selected;
            if (action?.payload) {
                if (selected.length > 0 && selected.at(0).id == action?.payload?.id) {
                    selected[0] = action?.payload
                }
                var u = state.set.items.findIndex(e => e.id == action?.payload?.id)
                if (u >= 0) {
                    state.set.items[u] = action?.payload
                }
            }

        },

        setIsTokenShown: (state, action: PayloadAction<any>) => {
            if (action?.payload) {
                var u = state.set.items.findIndex(e => e.id == action?.payload?.id)
                if (u >= 0) {
                    state.set.items[u].isTokenShown = action?.payload?.isTokenShown
                }
            }

        },
        setMessage: (state, action: PayloadAction<ApiMessage>) => {
            state.message = action.payload;
        },
        setStatusFilterOption: (state, action: PayloadAction<{ key: number, text: string }>) => {
            state.statusFilterOption = action.payload;
            state.filters = getFilters(state);
        },
        setCodeFilterOption: (state, action: PayloadAction<{ value: string, text: string }>) => {
            state.codeFilterOption = action.payload;
            state.filters = getFilters(state);
        },
        setTokenFilterOption: (state, action: PayloadAction<{ value: string, text: string }>) => {
            state.tokenFilterOption = action.payload;
            state.filters = getFilters(state);
        },
    },
    extraReducers: (builder) => {


        builder.addCase(getItems.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.authorizationsList.map(val => {
                    let r = {
                        id: val.id,
                        isRequest: val.isrequest?.value,
                        status: val.status?.id?.value,
                        token: val.token?.value ?? "",
                        code: val.code?.value ?? "",

                        isTokenShown: false,
                        params: [] as { key: string | undefined, value: string | undefined }[],

                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                    if (val?.parametersList) {
                        val?.parametersList?.forEach(e => {
                            r.params.push({ key: e.key, value: e.value })
                        })
                    }
                    return r;
                }
                ) as any[])

                //r = (r.concat(...Array(9).fill(r)));
                let l = [];
                if (state.set.items.length != 0) {
                    l = state.set.items.slice(0, state.set.items.length - 10)
                }
                l = l.concat(r);
                if (r.length < state.set.numberOfResults) {
                    state.set.hasMore = false;
                } else {
                    l = l.concat(...Array(10).fill(null));
                }

                state.set.items = l

                //console.log(state.items)

            } else {
                state.set.hasMore = false;
            }
            state.set.isFetching = false

        })
        builder.addCase(getItems.rejected, (state, action) => {
            state.set.isFetching = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getItems.pending, (state, action) => {
            state.set.isFetching = true;
            state.message = undefined;
        })

        builder.addCase(revokeAutorization.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response?.success?.message);
                let selected = state.set.selected;
                if (payload?.metadata) {
                    if (selected.length > 0 && selected.at(0).id == payload?.metadata.id) {
                        selected.at(0).status = AuthorizationStatus.REVOKED
                    }
                    var u = state.set.items.findIndex(e => e.id == payload?.metadata.id)
                    if (u >= 0) {
                        state.set.items.at(u).status = AuthorizationStatus.REVOKED
                    }

                }
            }
        })
        builder.addCase(revokeAutorization.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(revokeAutorization.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })


    }
})

export const { reset, dismissMessage, setMessage, setTokenFilterOption, setCodeFilterOption, addItem, setIsTokenShown, resetSet, setStatusFilterOption, setIsFilteredSet, setNumberOfResults, setSearchText, setSelectedItems, setSort, updateItem } = signaturesPageSlice.actions

export default signaturesPageSlice.reducer


