/**
 * @fileoverview gRPC-Web generated client stub for organization_client_portal_setting_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as organizationclientportalsetting_pb from './organizationclientportalsetting_pb';


export class OrganizationClientPortalSettingEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorUpdateOpenAccountDefaultConfiguration = new grpcWeb.MethodDescriptor(
    '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/UpdateOpenAccountDefaultConfiguration',
    grpcWeb.MethodType.UNARY,
    organizationclientportalsetting_pb.UpdateOpenAccountDefaultConfigurationRequest,
    organizationclientportalsetting_pb.UpdateOpenAccountDefaultConfigurationResponse,
    (request: organizationclientportalsetting_pb.UpdateOpenAccountDefaultConfigurationRequest) => {
      return request.serializeBinary();
    },
    organizationclientportalsetting_pb.UpdateOpenAccountDefaultConfigurationResponse.deserializeBinary
  );

  updateOpenAccountDefaultConfiguration(
    request: organizationclientportalsetting_pb.UpdateOpenAccountDefaultConfigurationRequest,
    metadata: grpcWeb.Metadata | null): Promise<organizationclientportalsetting_pb.UpdateOpenAccountDefaultConfigurationResponse>;

  updateOpenAccountDefaultConfiguration(
    request: organizationclientportalsetting_pb.UpdateOpenAccountDefaultConfigurationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organizationclientportalsetting_pb.UpdateOpenAccountDefaultConfigurationResponse) => void): grpcWeb.ClientReadableStream<organizationclientportalsetting_pb.UpdateOpenAccountDefaultConfigurationResponse>;

  updateOpenAccountDefaultConfiguration(
    request: organizationclientportalsetting_pb.UpdateOpenAccountDefaultConfigurationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organizationclientportalsetting_pb.UpdateOpenAccountDefaultConfigurationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/UpdateOpenAccountDefaultConfiguration',
        request,
        metadata || {},
        this.methodDescriptorUpdateOpenAccountDefaultConfiguration,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/UpdateOpenAccountDefaultConfiguration',
    request,
    metadata || {},
    this.methodDescriptorUpdateOpenAccountDefaultConfiguration);
  }

  methodDescriptorRemoveOpenAccountDefaultConfiguration = new grpcWeb.MethodDescriptor(
    '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/RemoveOpenAccountDefaultConfiguration',
    grpcWeb.MethodType.UNARY,
    organizationclientportalsetting_pb.RemoveOpenAccountDefaultConfigurationRequest,
    organizationclientportalsetting_pb.RemoveOpenAccountDefaultConfigurationResponse,
    (request: organizationclientportalsetting_pb.RemoveOpenAccountDefaultConfigurationRequest) => {
      return request.serializeBinary();
    },
    organizationclientportalsetting_pb.RemoveOpenAccountDefaultConfigurationResponse.deserializeBinary
  );

  removeOpenAccountDefaultConfiguration(
    request: organizationclientportalsetting_pb.RemoveOpenAccountDefaultConfigurationRequest,
    metadata: grpcWeb.Metadata | null): Promise<organizationclientportalsetting_pb.RemoveOpenAccountDefaultConfigurationResponse>;

  removeOpenAccountDefaultConfiguration(
    request: organizationclientportalsetting_pb.RemoveOpenAccountDefaultConfigurationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organizationclientportalsetting_pb.RemoveOpenAccountDefaultConfigurationResponse) => void): grpcWeb.ClientReadableStream<organizationclientportalsetting_pb.RemoveOpenAccountDefaultConfigurationResponse>;

  removeOpenAccountDefaultConfiguration(
    request: organizationclientportalsetting_pb.RemoveOpenAccountDefaultConfigurationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organizationclientportalsetting_pb.RemoveOpenAccountDefaultConfigurationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/RemoveOpenAccountDefaultConfiguration',
        request,
        metadata || {},
        this.methodDescriptorRemoveOpenAccountDefaultConfiguration,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/RemoveOpenAccountDefaultConfiguration',
    request,
    metadata || {},
    this.methodDescriptorRemoveOpenAccountDefaultConfiguration);
  }

  methodDescriptorUpdateCurrencyPairsConfiguration = new grpcWeb.MethodDescriptor(
    '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/UpdateCurrencyPairsConfiguration',
    grpcWeb.MethodType.UNARY,
    organizationclientportalsetting_pb.UpdateCurrencyPairsConfigurationRequest,
    organizationclientportalsetting_pb.UpdateCurrencyPairsConfigurationResponse,
    (request: organizationclientportalsetting_pb.UpdateCurrencyPairsConfigurationRequest) => {
      return request.serializeBinary();
    },
    organizationclientportalsetting_pb.UpdateCurrencyPairsConfigurationResponse.deserializeBinary
  );

  updateCurrencyPairsConfiguration(
    request: organizationclientportalsetting_pb.UpdateCurrencyPairsConfigurationRequest,
    metadata: grpcWeb.Metadata | null): Promise<organizationclientportalsetting_pb.UpdateCurrencyPairsConfigurationResponse>;

  updateCurrencyPairsConfiguration(
    request: organizationclientportalsetting_pb.UpdateCurrencyPairsConfigurationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organizationclientportalsetting_pb.UpdateCurrencyPairsConfigurationResponse) => void): grpcWeb.ClientReadableStream<organizationclientportalsetting_pb.UpdateCurrencyPairsConfigurationResponse>;

  updateCurrencyPairsConfiguration(
    request: organizationclientportalsetting_pb.UpdateCurrencyPairsConfigurationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organizationclientportalsetting_pb.UpdateCurrencyPairsConfigurationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/UpdateCurrencyPairsConfiguration',
        request,
        metadata || {},
        this.methodDescriptorUpdateCurrencyPairsConfiguration,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/UpdateCurrencyPairsConfiguration',
    request,
    metadata || {},
    this.methodDescriptorUpdateCurrencyPairsConfiguration);
  }

  methodDescriptorRemoveCurrencyPairsConfiguration = new grpcWeb.MethodDescriptor(
    '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/RemoveCurrencyPairsConfiguration',
    grpcWeb.MethodType.UNARY,
    organizationclientportalsetting_pb.RemoveCurrencyPairsConfigurationRequest,
    organizationclientportalsetting_pb.RemoveCurrencyPairsConfigurationResponse,
    (request: organizationclientportalsetting_pb.RemoveCurrencyPairsConfigurationRequest) => {
      return request.serializeBinary();
    },
    organizationclientportalsetting_pb.RemoveCurrencyPairsConfigurationResponse.deserializeBinary
  );

  removeCurrencyPairsConfiguration(
    request: organizationclientportalsetting_pb.RemoveCurrencyPairsConfigurationRequest,
    metadata: grpcWeb.Metadata | null): Promise<organizationclientportalsetting_pb.RemoveCurrencyPairsConfigurationResponse>;

  removeCurrencyPairsConfiguration(
    request: organizationclientportalsetting_pb.RemoveCurrencyPairsConfigurationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organizationclientportalsetting_pb.RemoveCurrencyPairsConfigurationResponse) => void): grpcWeb.ClientReadableStream<organizationclientportalsetting_pb.RemoveCurrencyPairsConfigurationResponse>;

  removeCurrencyPairsConfiguration(
    request: organizationclientportalsetting_pb.RemoveCurrencyPairsConfigurationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organizationclientportalsetting_pb.RemoveCurrencyPairsConfigurationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/RemoveCurrencyPairsConfiguration',
        request,
        metadata || {},
        this.methodDescriptorRemoveCurrencyPairsConfiguration,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/RemoveCurrencyPairsConfiguration',
    request,
    metadata || {},
    this.methodDescriptorRemoveCurrencyPairsConfiguration);
  }

  methodDescriptorUpdateExternalTransfersEntitiesConfiguration = new grpcWeb.MethodDescriptor(
    '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/UpdateExternalTransfersEntitiesConfiguration',
    grpcWeb.MethodType.UNARY,
    organizationclientportalsetting_pb.UpdateExternalTransfersEntitiesConfigurationRequest,
    organizationclientportalsetting_pb.UpdateExternalTransfersEntitiesConfigurationResponse,
    (request: organizationclientportalsetting_pb.UpdateExternalTransfersEntitiesConfigurationRequest) => {
      return request.serializeBinary();
    },
    organizationclientportalsetting_pb.UpdateExternalTransfersEntitiesConfigurationResponse.deserializeBinary
  );

  updateExternalTransfersEntitiesConfiguration(
    request: organizationclientportalsetting_pb.UpdateExternalTransfersEntitiesConfigurationRequest,
    metadata: grpcWeb.Metadata | null): Promise<organizationclientportalsetting_pb.UpdateExternalTransfersEntitiesConfigurationResponse>;

  updateExternalTransfersEntitiesConfiguration(
    request: organizationclientportalsetting_pb.UpdateExternalTransfersEntitiesConfigurationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organizationclientportalsetting_pb.UpdateExternalTransfersEntitiesConfigurationResponse) => void): grpcWeb.ClientReadableStream<organizationclientportalsetting_pb.UpdateExternalTransfersEntitiesConfigurationResponse>;

  updateExternalTransfersEntitiesConfiguration(
    request: organizationclientportalsetting_pb.UpdateExternalTransfersEntitiesConfigurationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organizationclientportalsetting_pb.UpdateExternalTransfersEntitiesConfigurationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/UpdateExternalTransfersEntitiesConfiguration',
        request,
        metadata || {},
        this.methodDescriptorUpdateExternalTransfersEntitiesConfiguration,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/UpdateExternalTransfersEntitiesConfiguration',
    request,
    metadata || {},
    this.methodDescriptorUpdateExternalTransfersEntitiesConfiguration);
  }

  methodDescriptorRemoveExternalTransfersEntitiesConfiguration = new grpcWeb.MethodDescriptor(
    '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/RemoveExternalTransfersEntitiesConfiguration',
    grpcWeb.MethodType.UNARY,
    organizationclientportalsetting_pb.RemoveExternalTransfersEntitiesConfigurationRequest,
    organizationclientportalsetting_pb.RemoveExternalTransfersEntitiesConfigurationResponse,
    (request: organizationclientportalsetting_pb.RemoveExternalTransfersEntitiesConfigurationRequest) => {
      return request.serializeBinary();
    },
    organizationclientportalsetting_pb.RemoveExternalTransfersEntitiesConfigurationResponse.deserializeBinary
  );

  removeExternalTransfersEntitiesConfiguration(
    request: organizationclientportalsetting_pb.RemoveExternalTransfersEntitiesConfigurationRequest,
    metadata: grpcWeb.Metadata | null): Promise<organizationclientportalsetting_pb.RemoveExternalTransfersEntitiesConfigurationResponse>;

  removeExternalTransfersEntitiesConfiguration(
    request: organizationclientportalsetting_pb.RemoveExternalTransfersEntitiesConfigurationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organizationclientportalsetting_pb.RemoveExternalTransfersEntitiesConfigurationResponse) => void): grpcWeb.ClientReadableStream<organizationclientportalsetting_pb.RemoveExternalTransfersEntitiesConfigurationResponse>;

  removeExternalTransfersEntitiesConfiguration(
    request: organizationclientportalsetting_pb.RemoveExternalTransfersEntitiesConfigurationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organizationclientportalsetting_pb.RemoveExternalTransfersEntitiesConfigurationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/RemoveExternalTransfersEntitiesConfiguration',
        request,
        metadata || {},
        this.methodDescriptorRemoveExternalTransfersEntitiesConfiguration,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/RemoveExternalTransfersEntitiesConfiguration',
    request,
    metadata || {},
    this.methodDescriptorRemoveExternalTransfersEntitiesConfiguration);
  }

  methodDescriptorUpdateClientDefaultConfiguration = new grpcWeb.MethodDescriptor(
    '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/UpdateClientDefaultConfiguration',
    grpcWeb.MethodType.UNARY,
    organizationclientportalsetting_pb.UpdateClientDefaultConfigurationRequest,
    organizationclientportalsetting_pb.UpdateClientDefaultConfigurationResponse,
    (request: organizationclientportalsetting_pb.UpdateClientDefaultConfigurationRequest) => {
      return request.serializeBinary();
    },
    organizationclientportalsetting_pb.UpdateClientDefaultConfigurationResponse.deserializeBinary
  );

  updateClientDefaultConfiguration(
    request: organizationclientportalsetting_pb.UpdateClientDefaultConfigurationRequest,
    metadata: grpcWeb.Metadata | null): Promise<organizationclientportalsetting_pb.UpdateClientDefaultConfigurationResponse>;

  updateClientDefaultConfiguration(
    request: organizationclientportalsetting_pb.UpdateClientDefaultConfigurationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organizationclientportalsetting_pb.UpdateClientDefaultConfigurationResponse) => void): grpcWeb.ClientReadableStream<organizationclientportalsetting_pb.UpdateClientDefaultConfigurationResponse>;

  updateClientDefaultConfiguration(
    request: organizationclientportalsetting_pb.UpdateClientDefaultConfigurationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organizationclientportalsetting_pb.UpdateClientDefaultConfigurationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/UpdateClientDefaultConfiguration',
        request,
        metadata || {},
        this.methodDescriptorUpdateClientDefaultConfiguration,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/UpdateClientDefaultConfiguration',
    request,
    metadata || {},
    this.methodDescriptorUpdateClientDefaultConfiguration);
  }

  methodDescriptorUpdateCanSignUp = new grpcWeb.MethodDescriptor(
    '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/UpdateCanSignUp',
    grpcWeb.MethodType.UNARY,
    organizationclientportalsetting_pb.UpdateCanSignUpRequest,
    organizationclientportalsetting_pb.UpdateCanSignUpResponse,
    (request: organizationclientportalsetting_pb.UpdateCanSignUpRequest) => {
      return request.serializeBinary();
    },
    organizationclientportalsetting_pb.UpdateCanSignUpResponse.deserializeBinary
  );

  updateCanSignUp(
    request: organizationclientportalsetting_pb.UpdateCanSignUpRequest,
    metadata: grpcWeb.Metadata | null): Promise<organizationclientportalsetting_pb.UpdateCanSignUpResponse>;

  updateCanSignUp(
    request: organizationclientportalsetting_pb.UpdateCanSignUpRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organizationclientportalsetting_pb.UpdateCanSignUpResponse) => void): grpcWeb.ClientReadableStream<organizationclientportalsetting_pb.UpdateCanSignUpResponse>;

  updateCanSignUp(
    request: organizationclientportalsetting_pb.UpdateCanSignUpRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organizationclientportalsetting_pb.UpdateCanSignUpResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/UpdateCanSignUp',
        request,
        metadata || {},
        this.methodDescriptorUpdateCanSignUp,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/UpdateCanSignUp',
    request,
    metadata || {},
    this.methodDescriptorUpdateCanSignUp);
  }

  methodDescriptorUpdateIsAccountLockedByDefault = new grpcWeb.MethodDescriptor(
    '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/UpdateIsAccountLockedByDefault',
    grpcWeb.MethodType.UNARY,
    organizationclientportalsetting_pb.UpdateIsAccountLockedByDefaultRequest,
    organizationclientportalsetting_pb.UpdateIsAccountLockedByDefaultResponse,
    (request: organizationclientportalsetting_pb.UpdateIsAccountLockedByDefaultRequest) => {
      return request.serializeBinary();
    },
    organizationclientportalsetting_pb.UpdateIsAccountLockedByDefaultResponse.deserializeBinary
  );

  updateIsAccountLockedByDefault(
    request: organizationclientportalsetting_pb.UpdateIsAccountLockedByDefaultRequest,
    metadata: grpcWeb.Metadata | null): Promise<organizationclientportalsetting_pb.UpdateIsAccountLockedByDefaultResponse>;

  updateIsAccountLockedByDefault(
    request: organizationclientportalsetting_pb.UpdateIsAccountLockedByDefaultRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organizationclientportalsetting_pb.UpdateIsAccountLockedByDefaultResponse) => void): grpcWeb.ClientReadableStream<organizationclientportalsetting_pb.UpdateIsAccountLockedByDefaultResponse>;

  updateIsAccountLockedByDefault(
    request: organizationclientportalsetting_pb.UpdateIsAccountLockedByDefaultRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organizationclientportalsetting_pb.UpdateIsAccountLockedByDefaultResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/UpdateIsAccountLockedByDefault',
        request,
        metadata || {},
        this.methodDescriptorUpdateIsAccountLockedByDefault,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/UpdateIsAccountLockedByDefault',
    request,
    metadata || {},
    this.methodDescriptorUpdateIsAccountLockedByDefault);
  }

  methodDescriptorUpdateCanOpenAccount = new grpcWeb.MethodDescriptor(
    '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/UpdateCanOpenAccount',
    grpcWeb.MethodType.UNARY,
    organizationclientportalsetting_pb.UpdateCanOpenAccountRequest,
    organizationclientportalsetting_pb.UpdateCanOpenAccountResponse,
    (request: organizationclientportalsetting_pb.UpdateCanOpenAccountRequest) => {
      return request.serializeBinary();
    },
    organizationclientportalsetting_pb.UpdateCanOpenAccountResponse.deserializeBinary
  );

  updateCanOpenAccount(
    request: organizationclientportalsetting_pb.UpdateCanOpenAccountRequest,
    metadata: grpcWeb.Metadata | null): Promise<organizationclientportalsetting_pb.UpdateCanOpenAccountResponse>;

  updateCanOpenAccount(
    request: organizationclientportalsetting_pb.UpdateCanOpenAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organizationclientportalsetting_pb.UpdateCanOpenAccountResponse) => void): grpcWeb.ClientReadableStream<organizationclientportalsetting_pb.UpdateCanOpenAccountResponse>;

  updateCanOpenAccount(
    request: organizationclientportalsetting_pb.UpdateCanOpenAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organizationclientportalsetting_pb.UpdateCanOpenAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/UpdateCanOpenAccount',
        request,
        metadata || {},
        this.methodDescriptorUpdateCanOpenAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/UpdateCanOpenAccount',
    request,
    metadata || {},
    this.methodDescriptorUpdateCanOpenAccount);
  }

  methodDescriptorUpdateURLs = new grpcWeb.MethodDescriptor(
    '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/UpdateURLs',
    grpcWeb.MethodType.UNARY,
    organizationclientportalsetting_pb.UpdateURLsRequest,
    organizationclientportalsetting_pb.UpdateURLsResponse,
    (request: organizationclientportalsetting_pb.UpdateURLsRequest) => {
      return request.serializeBinary();
    },
    organizationclientportalsetting_pb.UpdateURLsResponse.deserializeBinary
  );

  updateURLs(
    request: organizationclientportalsetting_pb.UpdateURLsRequest,
    metadata: grpcWeb.Metadata | null): Promise<organizationclientportalsetting_pb.UpdateURLsResponse>;

  updateURLs(
    request: organizationclientportalsetting_pb.UpdateURLsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organizationclientportalsetting_pb.UpdateURLsResponse) => void): grpcWeb.ClientReadableStream<organizationclientportalsetting_pb.UpdateURLsResponse>;

  updateURLs(
    request: organizationclientportalsetting_pb.UpdateURLsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organizationclientportalsetting_pb.UpdateURLsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/UpdateURLs',
        request,
        metadata || {},
        this.methodDescriptorUpdateURLs,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/UpdateURLs',
    request,
    metadata || {},
    this.methodDescriptorUpdateURLs);
  }

  methodDescriptorGetOrganizationClientPortalSetting = new grpcWeb.MethodDescriptor(
    '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/GetOrganizationClientPortalSetting',
    grpcWeb.MethodType.UNARY,
    organizationclientportalsetting_pb.GetOrganizationClientPortalSettingRequest,
    organizationclientportalsetting_pb.GetOrganizationClientPortalSettingResponse,
    (request: organizationclientportalsetting_pb.GetOrganizationClientPortalSettingRequest) => {
      return request.serializeBinary();
    },
    organizationclientportalsetting_pb.GetOrganizationClientPortalSettingResponse.deserializeBinary
  );

  getOrganizationClientPortalSetting(
    request: organizationclientportalsetting_pb.GetOrganizationClientPortalSettingRequest,
    metadata: grpcWeb.Metadata | null): Promise<organizationclientportalsetting_pb.GetOrganizationClientPortalSettingResponse>;

  getOrganizationClientPortalSetting(
    request: organizationclientportalsetting_pb.GetOrganizationClientPortalSettingRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: organizationclientportalsetting_pb.GetOrganizationClientPortalSettingResponse) => void): grpcWeb.ClientReadableStream<organizationclientportalsetting_pb.GetOrganizationClientPortalSettingResponse>;

  getOrganizationClientPortalSetting(
    request: organizationclientportalsetting_pb.GetOrganizationClientPortalSettingRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: organizationclientportalsetting_pb.GetOrganizationClientPortalSettingResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/GetOrganizationClientPortalSetting',
        request,
        metadata || {},
        this.methodDescriptorGetOrganizationClientPortalSetting,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/organization_client_portal_setting_endpoint.OrganizationClientPortalSettingEndpoint/GetOrganizationClientPortalSetting',
    request,
    metadata || {},
    this.methodDescriptorGetOrganizationClientPortalSetting);
  }

}

