import { ComboBox, DatePicker, DayOfWeek, DefaultButton, defaultDatePickerStrings, Dialog, DialogFooter, DialogType, Icon, IconButton, IPersonaProps, IPickerItemProps, IStackTokens, ISuggestionItemProps, ISuggestionModel, ISuggestionsProps, ITag, Label, MessageBar, MessageBarType, Modal, NormalPeoplePicker, PeoplePickerItem, Persona, PersonaSize, PrimaryButton, SpinButton, Spinner, SpinnerSize, Stack, TagItemSuggestion, TagPicker, Text, TextField, Toggle, TooltipHost, ValidationState } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { FormEventHandler, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../../../app/Hooks";
import { add, dismissMessage, resetTreasuriesSet, setIsFilteredCurrenciesSet, setSelectedCurrencyItems, setIsFilteredTreasurySet, setSelectedTreasuryItems, resetCurrencies, reset, getTreasuries, setMessage, getCurrencies, setStage1, resetMessage, } from "./TreasuryStatementFormSlice";
import * as Yup from 'yup';
import { DynamicInputField, DynamicNumberInputField, InputField, NumberInputField } from "../../../../controls/Controls";
import { inputs } from "../../../../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../../../../app/Api";
import { Message } from "../../../../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../../../../FormProps";
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../../../../common/SessionExpiredDialog/SessionExpiredDialog";
import { currencyFormatter, formatDate, timestampToDate } from "../../../../../../app/Helpers";
import { AuthenticateReply } from "../../../../../../repository/UserManagement/authentication_pb";
import { DynamicField } from "../../../../../common/DynamicFields/DynamicFields";
import { ExtraField } from "../../../../../../repository/Accountant/common_pb";
import { TableState } from "../../../../../common/Table/TableSate";
import { GetAccountsRequest } from "../../../../../../repository/Accountant/account_pb";
import { AccountStatus, TreasuryStatus } from "../../../../../../app/Enums";
import { GetCurrenciesRequest } from "../../../../../../repository/Accountant/currency_pb";
import { ExportStatementRequest, ExportStatementResponse } from "../../../../../../repository/Accountant/treasury_operation_pb";
import { GetTreasuriesRequest, GetTreasuriesResponse } from "../../../../../../repository/Accountant/treasury_pb";
import { List } from "../../../../../common/List/List";
import { Popup } from "../../../../../common/Popup/Popup";
import { AccountsPage } from "../../../../../pages/Accountant/Accounts/AccountsPage";
import { TreasuriesPage } from "../../../../../pages/Accountant/Treasuries/TreasuriesPage";
import moment from "moment";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import statement from "../../../../../../repository/Accountant/treasury_operation_pb";
import { CurrenciesPage } from "../../../../../pages/Accountant/Currencies/CurrenciesPage";


let req: ExportStatementRequest;
let getrTreasuriesReq: GetTreasuriesRequest;
let currenciesReq: GetCurrenciesRequest;

let promise: any;
let getFilterPromise: any;

let org: number = -1;
let current: AuthenticateReply.AsObject;


export const TreasuryStatementForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showConfirmation, setShowConfirmation] = useState({
        type: DialogType.normal,
        title: '',
        subText: '',
    });
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();

    const modalPropsStyles = { main: { maxWidth: 450 } };
    const [currentAction, setCurrenctAction] = useState(0)
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 1);

    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);
    const modalProps = useMemo(
        () => ({
            isBlocking: true,
            styles: modalPropsStyles,
            dragOptions: undefined,
        }),
        [isDraggable],
    );

    const state: { isLoading: boolean, message: ApiMessage | undefined, stage1: any, treasuries: TableState, currencies: TableState } = useAppSelector((state) => {

        return {
            isLoading: state.trasuryStatementForm.isLoading, message: state.trasuryStatementForm.message,
            stage1: state.treasuriesForm.stage1,
            treasuries: state.trasuryStatementForm.treasuries,
            currencies: state.trasuryStatementForm.currencies,

        }
    })

    useEffect(() => {
        var l = searchParams.get('org');
        req = new ExportStatementRequest();
        currenciesReq = new GetCurrenciesRequest();
        getrTreasuriesReq = new GetTreasuriesRequest();
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                getrTreasuriesReq.setOrganization(wrapper)
                currenciesReq.setOrganization(wrapper)

            }
        }
        const boolv = new BoolValue();
        boolv.setValue(true);
        const stat = new Int32Value();
        stat.setValue(AccountStatus.ACTIVE);

        const statt = new Int32Value();
        statt.setValue(TreasuryStatus.ACTIVE);

        getrTreasuriesReq.setNumofresults(state.treasuries.numberOfResults)
        getrTreasuriesReq.setOrder(state.treasuries.isDescending)
        getrTreasuriesReq.setStatus(statt)



        if (props.renderObject && props.renderObject?.treasury) {

            dispatch(setSelectedTreasuryItems(([props.renderObject?.treasury])))
        }

        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        }
        return () => { //clean up
            promise?.abort();
            getFilterPromise?.abort();
            dispatch(reset());
        }
    }, [])




    const onTreasuriesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetTreasuriesSet())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        getrTreasuriesReq.setSearch(wrapper)
        getrTreasuriesReq.setNextto(undefined)

        dispatch(setIsFilteredTreasurySet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getTreasuries({ body: getrTreasuriesReq, headers: getHeaders() }))
        return [];

    }



    const onCurrenciesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetCurrencies())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        currenciesReq.setSearch(wrapper)
        currenciesReq.setNextto(undefined)
        dispatch(setIsFilteredCurrenciesSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getCurrencies({ body: currenciesReq, headers: getHeaders() }))
        return [];

    }

    return (
        <>
            <Popup isOpen={(currentAction == 101)} title={t("treasuries")} onDismiss={() => { setCurrenctAction(0) }} >

                <TreasuriesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedTreasuryItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            <Popup isOpen={(currentAction == 103)} title={t("currencies")} onDismiss={() => { setCurrenctAction(0) }} >

                <CurrenciesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedCurrencyItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            <Formik
                enableReinitialize={true}
                initialValues={{

                    treasury: undefined,
                    currency: undefined,
                    numberOfResults: 100,
                    from: moment(fromDate, 'YYYY-MM-DD').startOf('day').toDate(),
                    to: moment(new Date(), 'YYYY-MM-DD').endOf('day').toDate(),
                    exportType: { key: 3, text: t("exportToPDF") },


                }}

                validationSchema={Yup.object({

                })}

                onSubmit={(values, actions) => {
                    if (props.type == FormType.ADD) {

                        let treasury = state.treasuries.selected.at(0);
                        let currency = state.currencies.selected.at(0);

                        req.setTreasury(treasury.internalId);
                        req.setCurrency(currency.id);

                        const wrapper = new Timestamp();
                        wrapper.fromDate(moment(values.from, 'YYYY-MM-DD').startOf('day').toDate())
                        req.setFrom(wrapper)

                        const wrapperTo = new Timestamp();
                        wrapperTo.fromDate(moment(values.to, 'YYYY-MM-DD').endOf('day').toDate())
                        req.setTo(wrapperTo)

                        req.setExporttype(values.exportType.key)
                        req.setNumofresults(values.numberOfResults)

                        const labels: statement.Label[] = [];
                        let r = new statement.Label();
                        let value = new StringValue();
                        value.setValue(formatDate(moment(values.from, 'YYYY-MM-DD').startOf('day').toDate()))
                        r.setLabelvalue(t("from"));
                        r.setValue(value)
                        r.setPropartyname("from")
                        labels.push(r)

                        r = new statement.Label();
                        value = new StringValue();
                        value.setValue(formatDate(moment(values.to, 'YYYY-MM-DD').endOf('day').toDate()))
                        r.setLabelvalue(t("to"));
                        r.setValue(value)
                        r.setPropartyname("to")
                        labels.push(r)

                        r = new statement.Label();
                        value = new StringValue();
                        value.setValue(values.numberOfResults + "")
                        r.setLabelvalue(t("numberOfResults"));
                        r.setValue(value)
                        r.setPropartyname("numberOfResults")
                        labels.push(r)


                        r = new statement.Label();
                        value = new StringValue();
                        value.setValue((treasury?.name ?? "") + " - " + (treasury?.id ?? ""))
                        r.setLabelvalue(t("treasury"));
                        r.setValue(value)
                        r.setPropartyname("treasury")
                        labels.push(r)

                        
                        r = new statement.Label();
                        value = new StringValue();
                        value.setValue((currency?.name))
                        r.setLabelvalue(t("currency"));
                        r.setValue(value)
                        r.setPropartyname("currency")
                        labels.push(r)

                        r = new statement.Label();
                        r.setLabelvalue(t("openningBalance"));
                        r.setPropartyname("openningBalance")
                        labels.push(r)

                        r = new statement.Label();
                        r.setLabelvalue(t("closingBalance"));
                        r.setPropartyname("closingBalance")
                        labels.push(r)

                        r = new statement.Label();
                        r.setLabelvalue(t("amount"));
                        r.setPropartyname("amount")
                        labels.push(r)

                        r = new statement.Label();
                        r.setLabelvalue(t("balance"));
                        r.setPropartyname("balance")
                        labels.push(r)
                        
                        r = new statement.Label();
                        r.setLabelvalue(t("trasuryStatementTitle"));
                        r.setPropartyname("trasuryStatementTitle")
                        labels.push(r)

                        r = new statement.Label();
                        r.setLabelvalue(t("summary"));
                        r.setPropartyname("summary")
                        labels.push(r)

                        r = new statement.Label();
                        r.setLabelvalue(t("generatedDate"));
                        r.setPropartyname("generateddate")
                        labels.push(r)

                        r = new statement.Label();
                        r.setLabelvalue(t("generatedBy"));
                        r.setPropartyname("generatedby")
                        labels.push(r)

                        r = new statement.Label();
                        r.setLabelvalue(t("transactionNumberLabel"));
                        r.setPropartyname("id")
                        labels.push(r)

                        r = new statement.Label();
                        r.setLabelvalue(t("date"));
                        r.setPropartyname("date")
                        labels.push(r)

                        r = new statement.Label();
                        r.setLabelvalue(t("description"));
                        r.setPropartyname("description")
                        labels.push(r)

                        r = new statement.Label();
                        r.setLabelvalue(t("credit"));
                        r.setPropartyname("credit")
                        labels.push(r)
                        r = new statement.Label();
                        r.setLabelvalue(t("debit"));
                        r.setPropartyname("debit")
                        labels.push(r)



                        r = new statement.Label();
                        r.setLabelvalue(t("balanceBefore"));
                        r.setPropartyname("balanceBefore")
                        labels.push(r)

                        r = new statement.Label();
                        r.setLabelvalue(t("balanceAfter"));
                        r.setPropartyname("balanceAfter")
                        labels.push(r)

                        r = new statement.Label();
                        value = new StringValue();
                        value.setValue((((new Date()).getTimezoneOffset()) * -1)+"")
                        r.setValue(value);
                        r.setPropartyname("timezone")
                        labels.push(r)

                        req.setLabelsList(labels)


                        promise = dispatch(add({ body: req, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: ExportStatementResponse.AsObject) => {
                                actions.resetForm();
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({


                                        });
                                    }
                                }
                                actions.setSubmitting(false)
                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    } else {

                    }
                }}

            >
                {formkikProps => (
                    <Form >

                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? (state.message.data != 401) ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            /> :
                                <SessionExpiredDialog />
                                : null
                            }

                            {props.type == FormType.ADD ? <Stack grow>
                                <Label required disabled={state.isLoading}>{t("treasury")}</Label>
                                <List
                                    inputProps={{ placeholder: t("treasury"), required: true }}
                                    disabled={state.isLoading || props.renderObject?.treasury ? true : false}
                                    suggestionsHeaderText={t('treasuries')}
                                    isLoading={state.treasuries.items.length == 0 ? state.treasuries.isFetching : false}
                                    isSearching={state.treasuries.items.length > 0 ? state.treasuries.isFetching : false}
                                    moreSuggestionsAvailable={state.treasuries.hasMore && !state.treasuries.isFetching}
                                    suggestions={state.treasuries.items.length > 0 ? state.treasuries.items.map(e => { return { item: { name: e.name, key: e.internalId } } as ISuggestionModel<ITag> }) : []}
                                    onGetMoreResults={() => {
                                        if (state.treasuries.items.length > 0 && !state.treasuries.isFetching) {
                                            const wrapper = new StringValue();
                                            wrapper.setValue(state.treasuries.items.at(state.treasuries.items.length - 1).internalId);
                                            getrTreasuriesReq.setNextto(wrapper)
                                            getFilterPromise = dispatch(getTreasuries({ body: getrTreasuriesReq, headers: getHeaders() }))
                                        }
                                    }}
                                    onSuggestionClick={(ev?, item?: any, index?: number) => {
                                        var u = state.treasuries.items.findIndex(e => e.internalId == item.key)
                                        if (u >= 0) {
                                            dispatch(setSelectedTreasuryItems([state.treasuries.items.at(u)]))
                                        }
                                    }}
                                    isPeoplePicker={false}
                                    selectedItems={state.treasuries.selected.length > 0 ? state.treasuries.selected.map(e => { return { name: e.name, key: e.internalId } as ITag }) : []}
                                    onChange={(e) => {
                                        dispatch(setSelectedTreasuryItems([]))

                                    }}
                                    onEmptyInputFocus={() => {
                                        dispatch(resetTreasuriesSet())
                                        getrTreasuriesReq.setSearch(undefined)
                                        getrTreasuriesReq.setNextto(undefined)

                                        dispatch(setIsFilteredTreasurySet(false))
                                        getFilterPromise?.abort()
                                        getFilterPromise = dispatch(getTreasuries({ body: getrTreasuriesReq, headers: getHeaders() }))
                                        return []
                                    }}
                                    onFilterChanged={onTreasuriesFilterChanged}
                                    endButtons={[
                                        {
                                            title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                            onClick: () => {
                                                setCurrenctAction(101)

                                            }
                                        }]}
                                />
                            </Stack> : undefined}

                            {props.type == FormType.ADD ? <Stack>
                                <Label required
                                    disabled={state.isLoading}>{t("currency")}</Label>
                                <List
                                    inputProps={{ placeholder: t("currency"), required: true }}
                                    disabled={state.isLoading}
                                    suggestionsHeaderText={t('currencies')}
                                    isLoading={state.currencies.items.length == 0 ? state.currencies.isFetching : false}
                                    isSearching={state.currencies.items.length > 0 ? state.currencies.isFetching : false}
                                    moreSuggestionsAvailable={state.currencies.hasMore && !state.currencies.isFetching}
                                    suggestions={state.currencies.items.length > 0 ? state.currencies.items.map(e => { return { item: { key: e.id, text: e.name, imageInitials: e?.symbol, secondaryText: e?.code, imageUrl: e?.icon } } as ISuggestionModel<IPersonaProps> }) : []}                                    onGetMoreResults={() => {
                                        if (state.currencies.items.length > 0 && !state.currencies.isFetching) {
                                            const wrapper = new StringValue();
                                            wrapper.setValue(state.currencies.items.at(state.currencies.items.length - 1).id);
                                            currenciesReq.setNextto(wrapper)
                                            getFilterPromise = dispatch(getCurrencies({ body: currenciesReq, headers: getHeaders() }))
                                        }
                                    }}
                                    onSuggestionClick={(ev?, item?: any, index?: number) => {
                                        var u = state.currencies.items.findIndex(e => e.id == item.key)
                                        if (u >= 0) {
                                            dispatch(setSelectedCurrencyItems([state.currencies.items.at(u)]))
                                        }
                                    }}
                                    isPeoplePicker={true}
                                    selectedItems={state.currencies.selected.length > 0 ? state.currencies.selected.map(e => { return { key: e.id, text: e.name, imageInitials: e?.symbol, secondaryText: e?.code, imageUrl: e?.icon } as IPersonaProps }) : []}
                                    onChange={(e) => {
                                        dispatch(setSelectedCurrencyItems([]))

                                    }}
                                    onEmptyInputFocus={() => {
                                        dispatch(resetCurrencies())
                                        currenciesReq.setSearch(undefined)
                                        currenciesReq.setNextto(undefined)
                                        dispatch(setIsFilteredCurrenciesSet(false))
                                        getFilterPromise?.abort()
                                        getFilterPromise = dispatch(getCurrencies({ body: currenciesReq, headers: getHeaders() }))
                                        return []
                                    }}
                                    onFilterChanged={onCurrenciesFilterChanged}
                                    endButtons={[
                                        {
                                            title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                            onClick: () => {
                                                setCurrenctAction(103)

                                            }
                                        }]}
                                />
                            </Stack> : undefined}

                            <DatePicker
                                isRequired
                                firstDayOfWeek={DayOfWeek.Sunday}
                                label={t("from")}
                                placeholder={t("from")}
                                disabled={state.isLoading}
                                value={formkikProps.values.from}
                                ariaLabel={t("from")}
                                formatDate={(e) => {
                                    return formatDate(e, 'YYYY-MM-DD')
                                }}
                                parseDateFromString={(date) => {
                                    return moment(date, 'YYYY-MM-DD').startOf('day').toDate();
                                }}
                                // DatePicker uses English strings by default. For localized apps, you must override this prop.
                                strings={defaultDatePickerStrings}
                                onSelectDate={(date: Date | null | undefined) => {

                                    formkikProps.setFieldValue("from", date)
                                }}

                            />

                            <DatePicker
                                isRequired
                                firstDayOfWeek={DayOfWeek.Sunday}
                                label={t("to")}
                                placeholder={t("to")}
                                disabled={state.isLoading}
                                value={formkikProps.values.to}
                                ariaLabel={t("to")}
                                formatDate={(e) => {
                                    return formatDate(e, 'YYYY-MM-DD')
                                }}
                                parseDateFromString={(date) => {
                                    return moment(date, 'YYYY-MM-DD').endOf('day').toDate();
                                }}
                                // DatePicker uses English strings by default. For localized apps, you must override this prop.
                                strings={defaultDatePickerStrings}
                                onSelectDate={(date: Date | null | undefined) => {
                                    if (date != undefined)
                                        if (moment(date).isSameOrAfter(formkikProps.values.from)) {
                                            formkikProps.setFieldValue("to", date)
                                        } else {
                                            formkikProps.setFieldValue("to", new Date())
                                        }
                                }}

                            />

                            {props.type == FormType.ADD ? <Stack grow>
                                <Label required disabled={state.isLoading}>{
                                    t("numberOfResults")
                                }</Label>
                                <Field name={`numberOfResults`}
                                    placeholder={"100"} component={NumberInputField}
                                    disabled={state.isLoading} max={500} min={1} required />
                            </Stack> : undefined}

                            <ComboBox
                                label={t("export")}
                                required
                                allowFreeform={true}
                                autoComplete={'on'}
                                disabled={state.isLoading}
                                options={[{ key: 1, text: t("exportToExcel") }, { key: 2, text: t("exportToCsv") }, { key: 3, text: t("exportToPDF") }]}
                                selectedKey={formkikProps.values.exportType.key}
                                onChange={(e, o) => {
                                    formkikProps.setFieldValue("exportType", { key: o?.key, text: o?.text })
                                }} />


                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("download") : t("edit")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={props.type == FormType.ADD ? t("discard") : t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>

                    </Form>
                )}
            </Formik>
        </>
    );

}

