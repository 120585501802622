import { ComboBox, DefaultButton, DialogFooter, Icon, IconButton, IPersonaProps, IStackTokens, ISuggestionModel, ITag, Label, MessageBar, MessageBarType, Modal, PrimaryButton, Spinner, SpinnerSize, Stack, StackItem, Text, TextField, Toggle, TooltipHost } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { FormEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks";
import { add, dismissMessage, get, getCategories, reset, resetCategories, setIsFilteredCategoriesSet, setSelectedCategoryItems, setStage1, update } from "./CategoriesFormSlice";
import * as Yup from 'yup';
import { DynamicInputField, InputField, NumberInputField } from "../../controls/Controls";
import { inputs } from "../../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../../app/Api";
import { Message } from "../../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../../FormProps";
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog";
import { formatDate, timestampToDate } from "../../../../app/Helpers";
import { AuthenticateReply } from "../../../../repository/UserManagement/authentication_pb";
import { DynamicField } from "../../../common/DynamicFields/DynamicFields";
import { ExtraField } from "../../../../repository/Accountant/common_pb";
import { UpdateCategoryRequest, AddCategoryRequest, GetCategoryRequest, AddCategoryResponse, UpdateCategoryResponse, GetCategoriesRequest } from "../../../../repository/Trading/category_pb";
import { List } from "../../../common/List/List";
import { TableState } from "../../../common/Table/TableSate";
import { Popup } from "../../../common/Popup/Popup";
import { CategoriesPage } from "../../../pages/Trading/Categories/CategoriesPage";


let req: AddCategoryRequest;
let updateReq: UpdateCategoryRequest;
let categoriesReq: GetCategoriesRequest;
let promise: any;
let getFilterPromise: any;
let org: number = -1;
let current: AuthenticateReply.AsObject;

export const CategoriesForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();
    const [currentAction, setCurrenctAction] = useState(0)

    const state: {
        isLoading: boolean, message: ApiMessage | undefined, stage1: any, categories: TableState,
    } = useAppSelector((state) => {

        return {
            isLoading: state.categoriesForm.isLoading, message: state.categoriesForm.message, stage1: state.categoriesForm.stage1,
            categories: state.categoriesForm.categories,

        }
    })

    useEffect(() => {
        var l = searchParams.get('org');
        req = new AddCategoryRequest();
        updateReq = new UpdateCategoryRequest()
        categoriesReq = new GetCategoriesRequest();

        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                req.setOrganization(wrapper)
                categoriesReq.setOrganization(wrapper)

            }
        }

        if (props.type == FormType.EDIT) {
            if (props.fetchData) {
                let r = new GetCategoryRequest()
                r.setId(props?.renderObject?.id);
                promise?.abort()
                promise = dispatch(get({ body: r, headers: getHeaders() }))
            } else {
                dispatch(setStage1(props?.renderObject))
            }
        }
        if (props.renderObject?.parentCategoryId) {
            dispatch(setSelectedCategoryItems(([{ id: props.renderObject.parentCategoryId, nameAr: props.renderObject.parentCategoryNameAr, nameEn: props.renderObject.parentCategoryNameEn }])))

        }

        let b = new BoolValue();
        b.setValue(false)
        categoriesReq.setOnlysub(b)
        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        }
        return () => { //clean up
            promise?.abort();
            dispatch(reset());
        }
    }, [])

    const onCategoriesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetCategories())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        categoriesReq.setSearch(wrapper)
        categoriesReq.setNextto(undefined)
        dispatch(setIsFilteredCategoriesSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getCategories({ body: categoriesReq, headers: getHeaders() }))
        return [];

    }

    return (
        <>
            <Popup isOpen={(currentAction == 102)} title={t("categories")} onDismiss={() => { setCurrenctAction(0) }} >

                <CategoriesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedCategoryItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Formik
                enableReinitialize={true}
                initialValues={{

                    nameAr: (props.renderObject) ? props.renderObject?.nameAr : '',
                    nameEn: (props.renderObject) ? props.renderObject?.nameEn : '',
                    rank: (props.renderObject) ? props.renderObject?.rank : '1',
                    isAvailable: (props.renderObject) ? props.renderObject.isAvailable : true,
                    parentCategory: undefined,
                    icon: (props.renderObject) ? props.renderObject?.iconSource : '',

                }}

                validationSchema={props.type == FormType.ADD ? Yup.object({

                    nameAr: inputs.categoryName,
                    nameEn: inputs.categoryName,
                    icon: inputs.currencyIcon,


                }) : Yup.object({

                    nameAr: inputs.categoryName,
                    nameEn: inputs.categoryName,
                    icon: inputs.currencyIcon,


                })}

                onSubmit={(values, actions) => {
                    if (props.type == FormType.ADD) {
                        let category = state.categories.selected?.at(0);
                        if (category) {
                            let pId = new StringValue();
                            pId.setValue(category.id)
                            req.setParent(pId)
                        }
                        let icon = new StringValue();
                        icon.setValue(values.icon)

                        req.setIconsource(icon)

                        req.setNamear(values.nameAr.trim());
                        req.setIsavailable(values.isAvailable);
                        req.setNameen(values.nameEn.trim())
                        req.setRank(Number(values.rank ?? 0))
                        promise = dispatch(add({ body: req, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: AddCategoryResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            id: res.success?.id,
                                            nameAr: values.nameAr.trim(),
                                            nameEn: values.nameEn.trim(),
                                            rank: values.rank,
                                            isAvailable: values.isAvailable,
                                            createdDate: formatDate(timestampToDate(res.success?.createddate?.seconds, res.success?.createddate?.nanos)),
                                            addedBy: (current.profile?.firstname?.value ?? "") + " " + (current.profile?.lastname?.value ?? ""),
                                            addedById: current.profile?.id?.value,
                                            iconSource: values.icon,
                                            parentCategoryId: category?.id,
                                            parentCategoryNameAr: category?.nameAr ?? "",
                                            parentCategoryNameEn: category?.nameEn ?? "",
                    

                                        });
                                    }
                                }
                                actions.setSubmitting(false)

                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    } else {
                        let category = state.categories.selected.at(0);
                        if (category && category.id != props.renderObject?.id) {
                            let pId = new StringValue();
                            pId.setValue(category.id)
                            updateReq.setParent(pId)
                        }
                        let icon = new StringValue();
                        icon.setValue(values.icon)
                        updateReq.setIconsource(icon)
                        updateReq.setNamear(values.nameAr.trim());
                        updateReq.setIsavailable(values.isAvailable);
                        updateReq.setNameen(values.nameEn.trim())
                        updateReq.setRank(Number(values.rank ?? 0))

                        updateReq.setId(props.renderObject?.id)
                        promise = dispatch(update({ body: updateReq, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: UpdateCategoryResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            ...props.renderObject,
                                            nameAr: values.nameAr.trim(),
                                            nameEn: values.nameEn.trim(),
                                            rank: values.rank,
                                            isAvailable: values.isAvailable,
                                            iconSource: values.icon,
                                            parentCategoryId: category?.id,
                                            parentCategoryNameAr: category?.nameAr ?? "",
                                            parentCategoryNameEn: category?.nameEn ?? "",
                                        });
                                    }
                                }
                                actions.setSubmitting(false)

                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    }
                }}

            >
                {formkikProps => (
                    <Form >
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? (state.message.data != 401) ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            /> :
                                <SessionExpiredDialog />
                                : null
                            }
                            <Field name="nameAr" label={t("categoryNameAr")} placeholder={t("categoryNameHintAr")} component={InputField} disabled={state.isLoading} autoFocus required maxLength={20} />

                            <Field name="nameEn" label={t("categoryNameEn")} placeholder={t("categoryNameHintEn")} component={InputField} disabled={state.isLoading} required maxLength={20} />

                            <Stack>
                                <Label disabled={state.isLoading}>{
                                    <div>
                                        {t("rank") + ' '}
                                        <TooltipHost content={t("rankDesc")}>
                                            <Icon iconName="Info" aria-label={t("rank")} />
                                        </TooltipHost>
                                    </div>
                                }</Label>
                                <Field name="rank" placeholder={2}
                                    component={NumberInputField} disabled={state.isLoading} max={1000} min={-1000} step={1} required />
                            </Stack>


                            <Stack>
                                <Label
                                    disabled={state.isLoading}>{t("parentCategory")}</Label>
                                <List
                                    inputProps={{ placeholder: t("parentCategory"), }}
                                    disabled={state.isLoading}
                                    suggestionsHeaderText={t('categories')}
                                    isLoading={state.categories.items.length == 0 ? state.categories.isFetching : false}
                                    isSearching={state.categories.items.length > 0 ? state.categories.isFetching : false}
                                    moreSuggestionsAvailable={state.categories.hasMore && !state.categories.isFetching}
                                    suggestions={state.categories.items.length > 0 ? state.categories.items.map(e => { return { item: { key: e.id, text: e.nameAr + " | " + e.nameEn, imageInitials: (e.parentCategoryNameAr == "" && e.parentCategoryNameEn == "" ? undefined : e.parentCategoryNameAr + " | " + e.parentCategoryNameEn), secondaryText: e.parentCategoryId == undefined ? undefined : e.parentCategoryNameAr == "" && e.parentCategoryNameEn == "" ? undefined : e.parentCategoryNameAr + " | " + e.parentCategoryNameEn, imageUrl: e?.iconSource } } as ISuggestionModel<IPersonaProps> }) : []}
                                    onGetMoreResults={() => {
                                        if (state.categories.items.length > 0 && !state.categories.isFetching) {
                                            const wrapper = new StringValue();
                                            wrapper.setValue(state.categories.items.at(state.categories.items.length - 1).id);
                                            categoriesReq.setNextto(wrapper)
                                            getFilterPromise = dispatch(getCategories({ body: categoriesReq, headers: getHeaders() }))
                                        }
                                    }}
                                    onSuggestionClick={(ev?, item?: any, index?: number) => {

                                        var u = state.categories.items.findIndex(e => e.id == item.key)
                                        if (u >= 0) {
                                            dispatch(setSelectedCategoryItems([state.categories.items.at(u)]))

                                        }

                                    }}
                                    isPeoplePicker={true}
                                    selectedItems={state.categories.selected.length > 0 ? state.categories.selected.map(e => { return { key: e.id, text: e.nameAr + " | " + e.nameEn, imageInitials: (e.parentCategoryNameAr == "" && e.parentCategoryNameEn == "" ? undefined : e.parentCategoryNameAr + " | " + e.parentCategoryNameEn), secondaryText: e.parentCategoryId == undefined ? undefined : e.parentCategoryNameAr == "" && e.parentCategoryNameEn == "" ? undefined : e.parentCategoryNameAr + " | " + e.parentCategoryNameEn, imageUrl: e?.iconSource } as IPersonaProps }) : []}
                                    onChange={(e) => {
                                        dispatch(setSelectedCategoryItems([]))

                                    }}
                                    onEmptyInputFocus={() => {
                                        dispatch(resetCategories())
                                        categoriesReq.setSearch(undefined)
                                        categoriesReq.setNextto(undefined)
                                        dispatch(setIsFilteredCategoriesSet(false))
                                        getFilterPromise?.abort()
                                        getFilterPromise = dispatch(getCategories({ body: categoriesReq, headers: getHeaders() }))
                                        return []

                                    }}
                                    onFilterChanged={onCategoriesFilterChanged}
                                    endButtons={[
                                        {
                                            title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                            onClick: () => {
                                                setCurrenctAction(102)

                                            }
                                        }]}
                                />
                            </Stack>
                            <Field name="icon" label={t("currencyIcon")} placeholder={"https://icons.com/usd.png"} component={InputField} disabled={state.isLoading} maxLength={2048} />

                            <Toggle

                                onText={t("available")} offText={t("unavailable")}
                                label={t("availability")}
                                disabled={state.isLoading}

                                checked={formkikProps.values.isAvailable}
                                onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                                    formkikProps.setFieldValue('isAvailable', checked);
                                }
                                }
                            />

                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("add") : t("edit")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={props.type == FormType.ADD ? t("discard") : t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>

                    </Form>
                )}
            </Formik>
        </>
    );

}

