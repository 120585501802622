/**
 * @fileoverview gRPC-Web generated client stub for account_operation_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as account_operation_pb from './account_operation_pb';


export class AccountOperationEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetBalance = new grpcWeb.MethodDescriptor(
    '/account_operation_endpoint.AccountOperationEndpoint/GetBalance',
    grpcWeb.MethodType.UNARY,
    account_operation_pb.GetBalanceRequest,
    account_operation_pb.GetBalanceResponse,
    (request: account_operation_pb.GetBalanceRequest) => {
      return request.serializeBinary();
    },
    account_operation_pb.GetBalanceResponse.deserializeBinary
  );

  getBalance(
    request: account_operation_pb.GetBalanceRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_operation_pb.GetBalanceResponse>;

  getBalance(
    request: account_operation_pb.GetBalanceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_operation_pb.GetBalanceResponse) => void): grpcWeb.ClientReadableStream<account_operation_pb.GetBalanceResponse>;

  getBalance(
    request: account_operation_pb.GetBalanceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_operation_pb.GetBalanceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_operation_endpoint.AccountOperationEndpoint/GetBalance',
        request,
        metadata || {},
        this.methodDescriptorGetBalance,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_operation_endpoint.AccountOperationEndpoint/GetBalance',
    request,
    metadata || {},
    this.methodDescriptorGetBalance);
  }

  methodDescriptorDeposit = new grpcWeb.MethodDescriptor(
    '/account_operation_endpoint.AccountOperationEndpoint/Deposit',
    grpcWeb.MethodType.UNARY,
    account_operation_pb.DepositRequest,
    account_operation_pb.DepositResponse,
    (request: account_operation_pb.DepositRequest) => {
      return request.serializeBinary();
    },
    account_operation_pb.DepositResponse.deserializeBinary
  );

  deposit(
    request: account_operation_pb.DepositRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_operation_pb.DepositResponse>;

  deposit(
    request: account_operation_pb.DepositRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_operation_pb.DepositResponse) => void): grpcWeb.ClientReadableStream<account_operation_pb.DepositResponse>;

  deposit(
    request: account_operation_pb.DepositRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_operation_pb.DepositResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_operation_endpoint.AccountOperationEndpoint/Deposit',
        request,
        metadata || {},
        this.methodDescriptorDeposit,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_operation_endpoint.AccountOperationEndpoint/Deposit',
    request,
    metadata || {},
    this.methodDescriptorDeposit);
  }

  methodDescriptorGetStatement = new grpcWeb.MethodDescriptor(
    '/account_operation_endpoint.AccountOperationEndpoint/GetStatement',
    grpcWeb.MethodType.UNARY,
    account_operation_pb.GetStatementRequest,
    account_operation_pb.GetStatementResponse,
    (request: account_operation_pb.GetStatementRequest) => {
      return request.serializeBinary();
    },
    account_operation_pb.GetStatementResponse.deserializeBinary
  );

  getStatement(
    request: account_operation_pb.GetStatementRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_operation_pb.GetStatementResponse>;

  getStatement(
    request: account_operation_pb.GetStatementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_operation_pb.GetStatementResponse) => void): grpcWeb.ClientReadableStream<account_operation_pb.GetStatementResponse>;

  getStatement(
    request: account_operation_pb.GetStatementRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_operation_pb.GetStatementResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_operation_endpoint.AccountOperationEndpoint/GetStatement',
        request,
        metadata || {},
        this.methodDescriptorGetStatement,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_operation_endpoint.AccountOperationEndpoint/GetStatement',
    request,
    metadata || {},
    this.methodDescriptorGetStatement);
  }

  methodDescriptorExportStatement = new grpcWeb.MethodDescriptor(
    '/account_operation_endpoint.AccountOperationEndpoint/ExportStatement',
    grpcWeb.MethodType.UNARY,
    account_operation_pb.ExportStatementRequest,
    account_operation_pb.ExportStatementResponse,
    (request: account_operation_pb.ExportStatementRequest) => {
      return request.serializeBinary();
    },
    account_operation_pb.ExportStatementResponse.deserializeBinary
  );

  exportStatement(
    request: account_operation_pb.ExportStatementRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_operation_pb.ExportStatementResponse>;

  exportStatement(
    request: account_operation_pb.ExportStatementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_operation_pb.ExportStatementResponse) => void): grpcWeb.ClientReadableStream<account_operation_pb.ExportStatementResponse>;

  exportStatement(
    request: account_operation_pb.ExportStatementRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_operation_pb.ExportStatementResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_operation_endpoint.AccountOperationEndpoint/ExportStatement',
        request,
        metadata || {},
        this.methodDescriptorExportStatement,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_operation_endpoint.AccountOperationEndpoint/ExportStatement',
    request,
    metadata || {},
    this.methodDescriptorExportStatement);
  }

  methodDescriptorGetTransaction = new grpcWeb.MethodDescriptor(
    '/account_operation_endpoint.AccountOperationEndpoint/GetTransaction',
    grpcWeb.MethodType.UNARY,
    account_operation_pb.GetTransactionRequest,
    account_operation_pb.GetTransactionResponse,
    (request: account_operation_pb.GetTransactionRequest) => {
      return request.serializeBinary();
    },
    account_operation_pb.GetTransactionResponse.deserializeBinary
  );

  getTransaction(
    request: account_operation_pb.GetTransactionRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_operation_pb.GetTransactionResponse>;

  getTransaction(
    request: account_operation_pb.GetTransactionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_operation_pb.GetTransactionResponse) => void): grpcWeb.ClientReadableStream<account_operation_pb.GetTransactionResponse>;

  getTransaction(
    request: account_operation_pb.GetTransactionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_operation_pb.GetTransactionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_operation_endpoint.AccountOperationEndpoint/GetTransaction',
        request,
        metadata || {},
        this.methodDescriptorGetTransaction,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_operation_endpoint.AccountOperationEndpoint/GetTransaction',
    request,
    metadata || {},
    this.methodDescriptorGetTransaction);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/account_operation_endpoint.AccountOperationEndpoint/Update',
    grpcWeb.MethodType.UNARY,
    account_operation_pb.UpdateTransactionRequest,
    account_operation_pb.UpdateTransactionResponse,
    (request: account_operation_pb.UpdateTransactionRequest) => {
      return request.serializeBinary();
    },
    account_operation_pb.UpdateTransactionResponse.deserializeBinary
  );

  update(
    request: account_operation_pb.UpdateTransactionRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_operation_pb.UpdateTransactionResponse>;

  update(
    request: account_operation_pb.UpdateTransactionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_operation_pb.UpdateTransactionResponse) => void): grpcWeb.ClientReadableStream<account_operation_pb.UpdateTransactionResponse>;

  update(
    request: account_operation_pb.UpdateTransactionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_operation_pb.UpdateTransactionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_operation_endpoint.AccountOperationEndpoint/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_operation_endpoint.AccountOperationEndpoint/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorTransfer = new grpcWeb.MethodDescriptor(
    '/account_operation_endpoint.AccountOperationEndpoint/Transfer',
    grpcWeb.MethodType.UNARY,
    account_operation_pb.TransferRequest,
    account_operation_pb.TransferResponse,
    (request: account_operation_pb.TransferRequest) => {
      return request.serializeBinary();
    },
    account_operation_pb.TransferResponse.deserializeBinary
  );

  transfer(
    request: account_operation_pb.TransferRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_operation_pb.TransferResponse>;

  transfer(
    request: account_operation_pb.TransferRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_operation_pb.TransferResponse) => void): grpcWeb.ClientReadableStream<account_operation_pb.TransferResponse>;

  transfer(
    request: account_operation_pb.TransferRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_operation_pb.TransferResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_operation_endpoint.AccountOperationEndpoint/Transfer',
        request,
        metadata || {},
        this.methodDescriptorTransfer,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_operation_endpoint.AccountOperationEndpoint/Transfer',
    request,
    metadata || {},
    this.methodDescriptorTransfer);
  }

  methodDescriptorWithdraw = new grpcWeb.MethodDescriptor(
    '/account_operation_endpoint.AccountOperationEndpoint/Withdraw',
    grpcWeb.MethodType.UNARY,
    account_operation_pb.WithdrawRequest,
    account_operation_pb.WithdrawResponse,
    (request: account_operation_pb.WithdrawRequest) => {
      return request.serializeBinary();
    },
    account_operation_pb.WithdrawResponse.deserializeBinary
  );

  withdraw(
    request: account_operation_pb.WithdrawRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_operation_pb.WithdrawResponse>;

  withdraw(
    request: account_operation_pb.WithdrawRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_operation_pb.WithdrawResponse) => void): grpcWeb.ClientReadableStream<account_operation_pb.WithdrawResponse>;

  withdraw(
    request: account_operation_pb.WithdrawRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_operation_pb.WithdrawResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_operation_endpoint.AccountOperationEndpoint/Withdraw',
        request,
        metadata || {},
        this.methodDescriptorWithdraw,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_operation_endpoint.AccountOperationEndpoint/Withdraw',
    request,
    metadata || {},
    this.methodDescriptorWithdraw);
  }

  methodDescriptorExportTransaction = new grpcWeb.MethodDescriptor(
    '/account_operation_endpoint.AccountOperationEndpoint/ExportTransaction',
    grpcWeb.MethodType.UNARY,
    account_operation_pb.ExportTransactionRequest,
    account_operation_pb.ExportTransactionResponse,
    (request: account_operation_pb.ExportTransactionRequest) => {
      return request.serializeBinary();
    },
    account_operation_pb.ExportTransactionResponse.deserializeBinary
  );

  exportTransaction(
    request: account_operation_pb.ExportTransactionRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_operation_pb.ExportTransactionResponse>;

  exportTransaction(
    request: account_operation_pb.ExportTransactionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_operation_pb.ExportTransactionResponse) => void): grpcWeb.ClientReadableStream<account_operation_pb.ExportTransactionResponse>;

  exportTransaction(
    request: account_operation_pb.ExportTransactionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_operation_pb.ExportTransactionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_operation_endpoint.AccountOperationEndpoint/ExportTransaction',
        request,
        metadata || {},
        this.methodDescriptorExportTransaction,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_operation_endpoint.AccountOperationEndpoint/ExportTransaction',
    request,
    metadata || {},
    this.methodDescriptorExportTransaction);
  }

  methodDescriptorGetTransactionSummary = new grpcWeb.MethodDescriptor(
    '/account_operation_endpoint.AccountOperationEndpoint/GetTransactionSummary',
    grpcWeb.MethodType.UNARY,
    account_operation_pb.GetTransactionSummaryRequest,
    account_operation_pb.GetTransactionSummaryResponse,
    (request: account_operation_pb.GetTransactionSummaryRequest) => {
      return request.serializeBinary();
    },
    account_operation_pb.GetTransactionSummaryResponse.deserializeBinary
  );

  getTransactionSummary(
    request: account_operation_pb.GetTransactionSummaryRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_operation_pb.GetTransactionSummaryResponse>;

  getTransactionSummary(
    request: account_operation_pb.GetTransactionSummaryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_operation_pb.GetTransactionSummaryResponse) => void): grpcWeb.ClientReadableStream<account_operation_pb.GetTransactionSummaryResponse>;

  getTransactionSummary(
    request: account_operation_pb.GetTransactionSummaryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_operation_pb.GetTransactionSummaryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_operation_endpoint.AccountOperationEndpoint/GetTransactionSummary',
        request,
        metadata || {},
        this.methodDescriptorGetTransactionSummary,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_operation_endpoint.AccountOperationEndpoint/GetTransactionSummary',
    request,
    metadata || {},
    this.methodDescriptorGetTransactionSummary);
  }

  methodDescriptorGetTransactions = new grpcWeb.MethodDescriptor(
    '/account_operation_endpoint.AccountOperationEndpoint/GetTransactions',
    grpcWeb.MethodType.UNARY,
    account_operation_pb.GetTransactionsRequest,
    account_operation_pb.GetTransactionsResponse,
    (request: account_operation_pb.GetTransactionsRequest) => {
      return request.serializeBinary();
    },
    account_operation_pb.GetTransactionsResponse.deserializeBinary
  );

  getTransactions(
    request: account_operation_pb.GetTransactionsRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_operation_pb.GetTransactionsResponse>;

  getTransactions(
    request: account_operation_pb.GetTransactionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_operation_pb.GetTransactionsResponse) => void): grpcWeb.ClientReadableStream<account_operation_pb.GetTransactionsResponse>;

  getTransactions(
    request: account_operation_pb.GetTransactionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_operation_pb.GetTransactionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_operation_endpoint.AccountOperationEndpoint/GetTransactions',
        request,
        metadata || {},
        this.methodDescriptorGetTransactions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_operation_endpoint.AccountOperationEndpoint/GetTransactions',
    request,
    metadata || {},
    this.methodDescriptorGetTransactions);
  }

  methodDescriptorExportTransactions = new grpcWeb.MethodDescriptor(
    '/account_operation_endpoint.AccountOperationEndpoint/ExportTransactions',
    grpcWeb.MethodType.UNARY,
    account_operation_pb.ExportTransactionsRequest,
    account_operation_pb.ExportTransactionsResponse,
    (request: account_operation_pb.ExportTransactionsRequest) => {
      return request.serializeBinary();
    },
    account_operation_pb.ExportTransactionsResponse.deserializeBinary
  );

  exportTransactions(
    request: account_operation_pb.ExportTransactionsRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_operation_pb.ExportTransactionsResponse>;

  exportTransactions(
    request: account_operation_pb.ExportTransactionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_operation_pb.ExportTransactionsResponse) => void): grpcWeb.ClientReadableStream<account_operation_pb.ExportTransactionsResponse>;

  exportTransactions(
    request: account_operation_pb.ExportTransactionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_operation_pb.ExportTransactionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_operation_endpoint.AccountOperationEndpoint/ExportTransactions',
        request,
        metadata || {},
        this.methodDescriptorExportTransactions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_operation_endpoint.AccountOperationEndpoint/ExportTransactions',
    request,
    metadata || {},
    this.methodDescriptorExportTransactions);
  }

  methodDescriptorExternalTransfer = new grpcWeb.MethodDescriptor(
    '/account_operation_endpoint.AccountOperationEndpoint/ExternalTransfer',
    grpcWeb.MethodType.UNARY,
    account_operation_pb.ExternalTransferRequest,
    account_operation_pb.ExternalTransferResponse,
    (request: account_operation_pb.ExternalTransferRequest) => {
      return request.serializeBinary();
    },
    account_operation_pb.ExternalTransferResponse.deserializeBinary
  );

  externalTransfer(
    request: account_operation_pb.ExternalTransferRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_operation_pb.ExternalTransferResponse>;

  externalTransfer(
    request: account_operation_pb.ExternalTransferRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_operation_pb.ExternalTransferResponse) => void): grpcWeb.ClientReadableStream<account_operation_pb.ExternalTransferResponse>;

  externalTransfer(
    request: account_operation_pb.ExternalTransferRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_operation_pb.ExternalTransferResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_operation_endpoint.AccountOperationEndpoint/ExternalTransfer',
        request,
        metadata || {},
        this.methodDescriptorExternalTransfer,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_operation_endpoint.AccountOperationEndpoint/ExternalTransfer',
    request,
    metadata || {},
    this.methodDescriptorExternalTransfer);
  }

}

