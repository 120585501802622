import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, refreshSession, getSession, ACCOUNTANT_API_URL } from '../../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../../app/Helpers';
import { LimitationEndpointClient } from '../../../../../repository/Accountant/LimitationServiceClientPb';
import { DeleteLimitationRequest, DeleteLimitationResponse, GetLimitationRequest, GetLimitationResponse, UpdateLimitationActivityRequest, UpdateLimitationActivityResponse } from '../../../../../repository/Accountant/limitation_pb';
import { DynamicField } from '../../../../common/DynamicFields/DynamicFields';


export interface LimitationDetailsPageState {
    item: any
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    isLoading: boolean

}

const initialState: LimitationDetailsPageState = {
    item: undefined,
    isChangeStateLoading: false,
    message: undefined,
    isLoading: false
}

const client = new LimitationEndpointClient(ACCOUNTANT_API_URL, null, null);

export const get = createAsyncThunk<GetLimitationResponse.AsObject, APIRequest<GetLimitationRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/limitationDetails/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getLimitation(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)


export const updateActivity = createAsyncThunk<APIResponse<UpdateLimitationActivityResponse.AsObject>, APIRequest<UpdateLimitationActivityRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/limitationDetails/updateActivity',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.updateActivity(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId(), state: req.body.getIsactive() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)


export const deleteItem = createAsyncThunk<APIResponse<DeleteLimitationResponse.AsObject>, APIRequest<DeleteLimitationRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/limitationDetails/delete',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.delete(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)




export const limitationDetailsPageSlice = createSlice({
    name: 'pages/accountant/limitationDetails',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
        },

        resetItem: (state) => {
            state.item = undefined;

        },

        updateItem: (state, action: PayloadAction<any>) => {
            state.item = action.payload;

        }
    },
    extraReducers: (builder) => {
        builder.addCase(deleteItem.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
            }
        })
        builder.addCase(deleteItem.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(deleteItem.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

        builder.addCase(updateActivity.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                state.item.isActive = payload?.metadata.state
            }
        })
        builder.addCase(updateActivity.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateActivity.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })


        builder.addCase(get.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {
                let r = {
                    id: payload?.success?.id,
                    name: payload?.success?.name?.value ?? "",
                    isAvailable: payload?.success?.isavailable?.value ?? undefined,
                    isActive: payload?.success?.isactive?.value ?? undefined,
                    type: payload?.success?.type?.value ?? undefined,
                    balanceMaxLimit: payload?.success?.balancemaxlimit?.value ?? undefined,
                    balanceMinLimit: payload?.success?.balanceminlimit?.value ?? undefined,
                    transactionLimitations: [] as any[],
                    createdDate: formatDate(timestampToDate(payload?.success?.createddate?.seconds, payload?.success?.createddate?.nanos)),
                    addedBy: (payload?.success?.createdby?.firstname?.value ?? "") + " " + (payload?.success?.createdby?.lastname?.value ?? ""),
                    addedById: payload?.success?.createdby?.id?.value,
                    extraFields: [] as DynamicField[]
                }
                if (payload?.success?.extrafieldsList) {
                    let count = 0
                    payload?.success?.extrafieldsList?.forEach(element => {
                        r.extraFields.push({ key: element.key, label: element.label, type: element.type, value: element.value })
                        count++
                    });
                }

                if (payload?.success?.transactionlimitationsList) {
                    payload?.success?.transactionlimitationsList?.forEach(element => {
                        //let d: number = element.seconds?.value ?? 0;

                        /*let year = Math.floor(d / 31536000);
                        let month = Math.floor((d % 31536000) / 2628000);
                        let day = Math.floor(((d % 31536000) % 2628000) / 86400);
                        let hour = Math.floor((d % (3600 * 24)) / 3600);
                        let minute = Math.floor((d % 3600) / 60);*/
                        let item = {
                            operation: { key: element.operation?.value ?? 0, text: "" },
                            minAmountValuePerTransaction: element.minamountvaluepertransaction?.value,
                            maxAmountValuePerTransaction: element.maxamountvaluepertransaction?.value,
                            isCreditor: { key: element.iscreditor?.value ? 1 : 2, text: "" },
                            timePeriodLimitations: [] as any[],
                        }
                        element.timeperiodlimitationsList?.forEach(element => {

                            item.timePeriodLimitations.push(
                                {
                                    period: { key: element.seconds?.value, text: "" },
                                    seconds: element.seconds?.value,
                                    maxNumberOfTransactions: element.maxnumberoftransactions?.value,
                                    maxTransactionsTotalAmount: element.maxtransactionstotalamount?.value
                                })
                        });
        
                        r.transactionLimitations.push(item);
                    });
                }
                /*if (payload?.success?.collectionaccountsList) {
                    let count = 0
                    payload?.success?.collectionaccountsList?.forEach(element => {
                        r.extraFields.push({ key: element.account?.id, label: element.label, type: element.type, value: element.value })
                        count++
                    });
                }*/

                state.item = r
            }

        })
        builder.addCase(get.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(get.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })
    }
})

export const { reset, dismissMessage, resetItem, updateItem } = limitationDetailsPageSlice.actions

export default limitationDetailsPageSlice.reducer