import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ISearchBoxProps, IPeoplePickerItemSelectedProps, PeoplePickerItem, ValidationState, NormalPeoplePicker, IPersonaProps, IPickerItemProps, DialogFooter, DatePicker, DayOfWeek, defaultDatePickerStrings, CheckboxVisibility, Separator, ConstrainMode } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks"
import { getPage } from "../../../../app/Pages"
import { setPage } from "../../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../../common/Table/Table"
import { reset, dismissMessage, setIsFilteredCurrencySet, getCurrencies, resetCurrencies, getItems, resetSet, resetTreasuriesSet, setMessage, setSelectedCurrencyItems, setSelectedTreasuryItems, setIsFilteredTreasurySet, getTreasuries, exportReport } from "./TreasuryBalanceReportPageSlice"
import { ApiMessage, getHeaders } from "../../../../app/Api"
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { currencyFormatter, formatDate, printComponent, normalizeKey } from "../../../../app/Helpers"
import { TableState } from "../../../common/Table/TableSate"
import { Message } from "../../../common/Message/Message"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { bool, string } from "yup"
import { Popup } from "../../../common/Popup/Popup"
import { GetCurrenciesRequest } from "../../../../repository/Accountant/currency_pb"
import { useReactToPrint } from "react-to-print"
import { PageProps } from "../../PageProps"
import { List } from "../../../common/List/List"
import { ExportTreasuryBalanceReportRequest, GetTreasuryBalanceReportRequest } from "../../../../repository/Accountant/report_pb"
import { GetTreasuriesRequest } from "../../../../repository/Accountant/treasury_pb"
import { TreasuriesPage } from "../../Accountant/Treasuries/TreasuriesPage"
import { CurrenciesPage } from "../../Accountant/Currencies/CurrenciesPage"
import moment from "moment"
import { Form, Formik } from "formik"
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb"
import { TreasuryStatus } from "../../../../app/Enums"
import { inputs } from "../../../../app/Validation"
import * as Yup from 'yup';
import report from "../../../../repository/Accountant/report_pb"
import { TextView } from "../../../common/DataView/TextView"

let req: GetTreasuryBalanceReportRequest;
let currenciesReq: GetCurrenciesRequest;
let getTreasuriesReq: GetTreasuriesRequest;
let exportReq: ExportTreasuryBalanceReportRequest;

let org: number = -1;

let getItemsPromise: any;
let getFilterPromise: any;
let actionPromise: any;

const key = "reports/treasuryBalanceReport"
export const TreasuryBalanceReportPage: React.FunctionComponent<PageProps> = (props) => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const state: {
        set: TableState,
        message: ApiMessage | undefined,
        appTheme: string, isDarkModeEnabled: boolean,
        currencies: TableState
        treasuries: TableState
        isChangeStateLoading: boolean
        totals: any


    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.treasuryBalanceReportPage.isChangeStateLoading,
            set: state.treasuryBalanceReportPage.set,
            message: state.treasuryBalanceReportPage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            currencies: state.treasuryBalanceReportPage.currencies,
            treasuries: state.treasuryBalanceReportPage.treasuries,
            totals: state.treasuryBalanceReportPage.totals

        }
    })

    const [currentAction, setCurrenctAction] = useState(0)
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 1);
    const ref = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => {
            return printComponent(ref.current?.cloneNode(true), true)
        }

    });


    useEffect(() => {
        req = new GetTreasuryBalanceReportRequest();
        currenciesReq = new GetCurrenciesRequest();
        getTreasuriesReq = new GetTreasuriesRequest();

        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                getTreasuriesReq.setOrganization(wrapper)
                currenciesReq.setOrganization(wrapper)

            }
        }

        if (!props.isSelection) {
            const page = getPage(key)
            dispatch(setTitle(t(normalizeKey(page?.title))));
            dispatch(setPage(page));
        }

        const boolv = new BoolValue();
        boolv.setValue(true);
        const statt = new Int32Value();
        statt.setValue(TreasuryStatus.ACTIVE);

        currenciesReq.setNumofresults(state.currencies.numberOfResults)
        currenciesReq.setOrder(state.currencies.isDescending)
        // currenciesReq.setIsavailable(boolv)

        getTreasuriesReq.setNumofresults(state.treasuries.numberOfResults)
        getTreasuriesReq.setOrder(state.treasuries.isDescending)
        //  getTreasuriesReq.setStatus(statt)



        return () => { //clean up
            getItemsPromise?.abort();
            getFilterPromise?.abort();
            actionPromise?.abort();
            dispatch(reset());
        }
    }, [])


    const c: IColumn[] = [


        {
            key: 'date',
            name: t("date"),
            minWidth: 50,
            maxWidth: 140,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.date != undefined)
                    return <Text>{(item?.date)}</Text>; //{item.createdDate}
            },
        },

        {
            key: 'openningBalance',
            name: t("openningBalance"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.openningBalance != undefined)
                    return <Text>{currencyFormatter(item?.openningBalance, { significantDigits: item?.currency?.decimalPlaces ?? 9, symbol: item?.currency?.symbol })}</Text>;
            },
        },
        {
            key: 'totalCredit',
            name: t("totalCredit"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.totalCredit != undefined)
                    return <Text>{currencyFormatter(item?.totalCredit, { significantDigits: item?.currency?.decimalPlaces ?? 9, symbol: item?.currency?.symbol })}</Text>;
            },
        },

        {
            key: 'totalDebit',
            name: t("totalDebit"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.totalDebit != undefined)
                    return <Text>{currencyFormatter(item?.totalDebit, { significantDigits: item?.currency?.decimalPlaces ?? 9, symbol: item?.currency?.symbol })}</Text>;

            },
        },
        {
            key: 'closingBalance',
            name: t("closingBalance"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.closingBalance != undefined)
                    return <Text>{currencyFormatter(item?.closingBalance, { significantDigits: item?.currency?.decimalPlaces ?? 9, symbol: item?.currency?.symbol })}</Text>;
            },
        },

        {
            key: 'totalNumberOfCreditOperations',
            name: t("totalNumberOfCreditOperations"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.totalNumberOfCreditOperations != undefined)
                    return <Text>{item?.totalNumberOfCreditOperations}</Text>;
            },
        },

        {
            key: 'totalNumberOfDebitOperations',
            name: t("totalNumberOfDebitOperations"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.totalNumberOfDebitOperations != undefined)
                    return <Text>{item?.totalNumberOfDebitOperations}</Text>;
            },
        },


    ];




    const onCurrenciesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetCurrencies())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        currenciesReq.setSearch(wrapper)
        currenciesReq.setNextto(undefined)
        dispatch(setIsFilteredCurrencySet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getCurrencies({ body: currenciesReq, headers: getHeaders() }))
        return [];

    }

    const onTreasuriesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetTreasuriesSet())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        getTreasuriesReq.setSearch(wrapper)
        getTreasuriesReq.setNextto(undefined)
        if (state.currencies.selected.length > 0) {
            let r = new StringValue();
            r.setValue(state.currencies.selected.at(0).id);
            getTreasuriesReq.setCurrency(r);
        }
        dispatch(setIsFilteredTreasurySet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getTreasuries({ body: getTreasuriesReq, headers: getHeaders() }))
        return [];

    }

    return (
        <Stack >

            <Popup isOpen={(currentAction == 1001)} title={t("treasuries")} onDismiss={() => { setCurrenctAction(0) }} >

                <TreasuriesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedTreasuryItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Popup isOpen={(currentAction == 1003)} title={t("currencies")} onDismiss={() => { setCurrenctAction(0) }} >

                <CurrenciesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedCurrencyItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }

            <Formik
                enableReinitialize={true}
                initialValues={{
                    from: moment(fromDate, 'YYYY-MM-DD').startOf('day').toDate(),
                    to: moment(new Date(), 'YYYY-MM-DD').endOf('day').toDate(),
                    group: { key: 0, text: t("daily") },


                }}


                onSubmit={(values, actions) => {

                    let currency = state.currencies.selected.at(0);
                    let treasury = state.treasuries.selected.at(0);

                    req.setCurrency(currency.id);
                    req.setTreasury(treasury.internalId)
                    const wrapper = new Timestamp();
                    wrapper.fromDate(moment(values.from, 'YYYY-MM-DD').startOf('day').toDate())
                    req.setFrom(wrapper)

                    const wrapperTo = new Timestamp();
                    wrapperTo.fromDate(moment(values.to, 'YYYY-MM-DD').endOf('day').toDate())
                    req.setTo(wrapperTo)
                    req.setGroupby(Number(values.group.key))

                    exportReq = new ExportTreasuryBalanceReportRequest();
                    exportReq.setTreasury(req.getTreasury());
                    exportReq.setCurrency(req.getCurrency());
                    exportReq.setFrom(req.getFrom())
                    exportReq.setTo(req.getTo())
                    exportReq.setGroupby(req.getGroupby())
                    exportReq.setExporttype(1)

                    const labels: report.Label[] = [];
                    let r = new report.Label();
                    let value = new StringValue();
                    value.setValue(formatDate(moment(values.from, 'YYYY-MM-DD').startOf('day').toDate()))
                    r.setLabelvalue(t("from"));
                    r.setValue(value)
                    r.setPropartyname("from")
                    labels.push(r)

                    r = new report.Label();
                    value = new StringValue();
                    value.setValue(formatDate(moment(values.to, 'YYYY-MM-DD').endOf('day').toDate()))
                    r.setLabelvalue(t("to"));
                    r.setValue(value)
                    r.setPropartyname("to")
                    labels.push(r)

                    r = new report.Label();
                    value = new StringValue();
                    value.setValue(values.group.text)
                    r.setLabelvalue(t("grouping"));
                    r.setValue(value)
                    r.setPropartyname("groupby")
                    labels.push(r)

                    r = new report.Label();
                    value = new StringValue();
                    value.setValue(currency.name)
                    r.setLabelvalue(t("currency"));
                    r.setValue(value)
                    r.setPropartyname("currency")
                    labels.push(r)

                    r = new report.Label();
                    value = new StringValue();
                    value.setValue(treasury.name + " - " + treasury.id)
                    r.setLabelvalue(t("treasury"));
                    r.setValue(value)
                    r.setPropartyname("treasury")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("generatedDate"));
                    r.setPropartyname("generateddate")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("generatedBy"));
                    r.setPropartyname("generatedby")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("date"));
                    r.setPropartyname("date")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("openningBalance"));
                    r.setPropartyname("openningBalance")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("totalCredit"));
                    r.setPropartyname("TotalCredit")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("totalDebit"));
                    r.setPropartyname("TotalDebit")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("closingBalance"));
                    r.setPropartyname("ClosingBalance")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("totalNumberOfCreditOperations"));
                    r.setPropartyname("TotalNumberOfCreditOperations")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("totalNumberOfDebitOperations"));
                    r.setPropartyname("TotalNumberOfDebitOperations")
                    labels.push(r)

                    r = new report.Label();
                    value = new StringValue();
                    value.setValue((((new Date()).getTimezoneOffset()) * -1) + "")
                    r.setValue(value);
                    r.setPropartyname("timezone")
                    labels.push(r)


                    exportReq.setLabelsList(labels)
                    getItemsPromise?.abort();
                    getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))


                }}

            >
                {formkikProps => (
                    <Form >

                        <Stack tokens={{ childrenGap: 10, padding: "0px 33% 10px 3%" }}>
                            <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 10 }}>
                                <Stack.Item grow>
                                    <DatePicker
                                        isRequired
                                        firstDayOfWeek={DayOfWeek.Sunday}
                                        label={t("from")}
                                        placeholder={t("from")}
                                        disabled={state.set.isFetching}
                                        value={formkikProps.values.from}
                                        ariaLabel={t("from")}
                                        formatDate={(e) => {
                                            return formatDate(e, 'YYYY-MM-DD')
                                        }}
                                        parseDateFromString={(date) => {
                                            return moment(date, 'YYYY-MM-DD').startOf('day').toDate();
                                        }}
                                        // DatePicker uses English strings by default. For localized apps, you must override this prop.
                                        strings={defaultDatePickerStrings}
                                        onSelectDate={(date: Date | null | undefined) => {

                                            formkikProps.setFieldValue("from", date)
                                        }}

                                    />
                                </Stack.Item>
                                <Stack.Item grow>
                                    <DatePicker
                                        isRequired
                                        firstDayOfWeek={DayOfWeek.Sunday}
                                        label={t("to")}
                                        placeholder={t("to")}
                                        disabled={state.set.isFetching}
                                        value={formkikProps.values.to}
                                        ariaLabel={t("to")}
                                        formatDate={(e) => {
                                            return formatDate(e, 'YYYY-MM-DD')
                                        }}
                                        parseDateFromString={(date) => {
                                            return moment(date, 'YYYY-MM-DD').endOf('day').toDate();
                                        }}
                                        // DatePicker uses English strings by default. For localized apps, you must override this prop.
                                        strings={defaultDatePickerStrings}
                                        onSelectDate={(date: Date | null | undefined) => {
                                            if (date != undefined)
                                                if (moment(date).isSameOrAfter(formkikProps.values.from)) {
                                                    formkikProps.setFieldValue("to", date)
                                                } else {
                                                    formkikProps.setFieldValue("to", new Date())
                                                }
                                        }}

                                    />
                                </Stack.Item>
                                <Stack.Item grow>
                                    <ComboBox
                                        label={t("grouping")}
                                        required
                                        allowFreeform={true}
                                        autoComplete={'on'}
                                        disabled={state.set.isFetching}
                                        options={[{ key: 3, text: t("hourly") }, { key: 0, text: t("daily") }, { key: 1, text: t("monthly") }, { key: 2, text: t("yearly") },]}
                                        selectedKey={formkikProps.values.group.key}
                                        onChange={(e, o) => {
                                            formkikProps.setFieldValue("group", { key: o?.key, text: o?.text })
                                        }} />
                                </Stack.Item>
                            </Stack>


                            <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 10 }}>

                                <Stack.Item grow>
                                    <Label required disabled={state.set.isFetching}>{t("currency")}</Label>
                                    <List
                                        inputProps={{ placeholder: t("currency"), required: true }}
                                        suggestionsHeaderText={t('currencies')}
                                        disabled={state.set.isFetching}
                                        isLoading={state.currencies.items.length == 0 ? state.currencies.isFetching : false}
                                        isSearching={state.currencies.items.length > 0 ? state.currencies.isFetching : false}
                                        moreSuggestionsAvailable={state.currencies.hasMore && !state.currencies.isFetching}
                                        suggestions={state.currencies.items.length > 0 ? state.currencies.items.map(e => { return { item: { key: e.id, text: e.name, imageInitials: e?.symbol, secondaryText: e?.code, imageUrl: e?.icon } } as ISuggestionModel<IPersonaProps> }) : []}
                                        onGetMoreResults={() => {
                                            if (state.currencies.items.length > 0 && !state.currencies.isFetching) {
                                                const wrapper = new StringValue();
                                                wrapper.setValue(state.currencies.items.at(state.currencies.items.length - 1).id);
                                                currenciesReq.setNextto(wrapper)
                                                getFilterPromise = dispatch(getCurrencies({ body: currenciesReq, headers: getHeaders() }))
                                            }
                                        }}
                                        onSuggestionClick={(ev?, item?: any, index?: number) => {
                                            var u = state.currencies.items.findIndex(e => e.id == item.key)
                                            if (u >= 0) {
                                                dispatch(setSelectedCurrencyItems([state.currencies.items.at(u)]))
                                            }
                                        }}
                                        isPeoplePicker={true}
                                        selectedItems={state.currencies.selected.length > 0 ? state.currencies.selected.map(e => { return { key: e.id, text: e.name, imageInitials: e?.symbol, secondaryText: e?.code, imageUrl: e?.icon } as IPersonaProps }) : []}
                                        onChange={(e) => {
                                            dispatch(setSelectedCurrencyItems([]))

                                        }}
                                        onEmptyInputFocus={() => {
                                            dispatch(resetCurrencies())
                                            currenciesReq.setSearch(undefined)
                                            currenciesReq.setNextto(undefined)
                                            dispatch(setIsFilteredCurrencySet(false))
                                            getFilterPromise?.abort()
                                            getFilterPromise = dispatch(getCurrencies({ body: currenciesReq, headers: getHeaders() }))
                                            return []
                                        }}
                                        onFilterChanged={onCurrenciesFilterChanged}
                                        endButtons={[
                                            {
                                                title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                                onClick: () => {
                                                    setCurrenctAction(1003)

                                                }
                                            }]}
                                    />

                                </Stack.Item>


                                <Stack.Item grow>
                                    <Label required disabled={state.set.isFetching}>{t("treasury")}</Label>
                                    <List
                                        inputProps={{ placeholder: t("treasury"), required: true }}
                                        disabled={state.set.isFetching}
                                        suggestionsHeaderText={t('treasuries')}
                                        isLoading={state.treasuries.items.length == 0 ? state.treasuries.isFetching : false}
                                        isSearching={state.treasuries.items.length > 0 ? state.treasuries.isFetching : false}
                                        moreSuggestionsAvailable={state.treasuries.hasMore && !state.treasuries.isFetching}
                                        suggestions={state.treasuries.items.length > 0 ? state.treasuries.items.map(e => { return { item: { name: e.name, key: e.internalId } } as ISuggestionModel<ITag> }) : []}
                                        onGetMoreResults={() => {
                                            if (state.treasuries.items.length > 0 && !state.treasuries.isFetching) {
                                                const wrapper = new StringValue();
                                                wrapper.setValue(state.treasuries.items.at(state.treasuries.items.length - 1).internalId);
                                                getTreasuriesReq.setNextto(wrapper)
                                                getFilterPromise = dispatch(getTreasuries({ body: getTreasuriesReq, headers: getHeaders() }))
                                            }
                                        }}
                                        onSuggestionClick={(ev?, item?: any, index?: number) => {
                                            var u = state.treasuries.items.findIndex(e => e.internalId == item.key)
                                            if (u >= 0) {
                                                dispatch(setSelectedTreasuryItems([state.treasuries.items.at(u)]))
                                            }
                                        }}
                                        isPeoplePicker={false}
                                        selectedItems={state.treasuries.selected.length > 0 ? state.treasuries.selected.map(e => { return { name: e.name, key: e.internalId } as ITag }) : []}
                                        onChange={(e) => {
                                            dispatch(setSelectedTreasuryItems([]))

                                        }}
                                        onEmptyInputFocus={() => {
                                            dispatch(resetTreasuriesSet())
                                            getTreasuriesReq.setSearch(undefined)
                                            getTreasuriesReq.setNextto(undefined)
                                            if (state.currencies.selected.length > 0) {
                                                let r = new StringValue();
                                                r.setValue(state.currencies.selected.at(0).id);
                                                getTreasuriesReq.setCurrency(r);
                                            }
                                            dispatch(setIsFilteredTreasurySet(false))
                                            getFilterPromise?.abort()
                                            getFilterPromise = dispatch(getTreasuries({ body: getTreasuriesReq, headers: getHeaders() }))
                                            return []
                                        }}
                                        onFilterChanged={onTreasuriesFilterChanged}
                                        endButtons={[
                                            {
                                                title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                                onClick: () => {
                                                    setCurrenctAction(1001)

                                                }
                                            }]}
                                    />
                                </Stack.Item>
                            </Stack>


                            <DialogFooter>
                                <PrimaryButton disabled={state.set.isFetching} text={state.set.isFetching ? undefined : t("generateReport")} type="submit" >

                                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.set.isFetching ? "block" : "none") } }} />

                                </PrimaryButton>

                                <DefaultButton disabled={state.set.isFetching} text={t("clearFilter")} onClick={() => {
                                    formkikProps.resetForm()
                                    req.setCurrency("")
                                    req.setTreasury("")
                                    req.setFrom(undefined)
                                    req.setTo(undefined)
                                    req.setGroupby(0)
                                    dispatch(setSelectedCurrencyItems([]))
                                    dispatch(setSelectedTreasuryItems([]))
                                    dispatch(resetSet())


                                }} />
                            </DialogFooter>
                        </Stack>
                        {state.set.items.length > 0 ? <CommandBar
                            items={[
                                {
                                    key: 'export',
                                    text: t("export"),
                                    iconProps: { iconName: "DownloadDocument", styles: { root: { fontWeight: "bold" } } },
                                    title: t("export"),
                                    disabled: state.set.isFetching,
                                    commandBarButtonAs: (e) => {
                                        return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 1 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 1 ? undefined : e.text}  >
                                            <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 1 ? "block" : "none") } }} />
                                        </CommandBarButton>;
                                    },
                                    subMenuProps: {
                                        items: [{
                                            key: 'exportToExcel',
                                            text: t('exportToExcel'),
                                            title: t("exportToExcel"),
                                            iconProps: { iconName: 'ExcelDocument' },

                                            onClick: () => {
                                                if (!state.isChangeStateLoading && exportReq) {
                                                    exportReq.setExporttype(1)
                                                    setCurrenctAction(1)

                                                    getItemsPromise = dispatch(exportReport({
                                                        body: exportReq, headers: getHeaders()
                                                    }))

                                                }

                                            }
                                        },
                                        {
                                            key: 'exportToCsv',
                                            text: t("exportToCsv"),
                                            title: t("exportToCsv"),

                                            iconProps: { iconName: 'ReportDocument' },
                                            onClick: () => {
                                                if (!state.isChangeStateLoading && exportReq) {
                                                    exportReq.setExporttype(2)
                                                    setCurrenctAction(1)
                                                    getItemsPromise = dispatch(exportReport({
                                                        body: exportReq, headers: getHeaders()
                                                    }))
                                                }

                                            }
                                        },

                                        ]
                                    },


                                },
                                {
                                    key: 'print',
                                    text: t("print"),
                                    iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
                                    title: t("print"),
                                    onClick: () => {
                                        handlePrint();
                                    }
                                },

                            ]}

                        /> : undefined}

                    </Form>
                )}
            </Formik>


            <Table
                setKey="treasuryBalanceReport"
                items={state.set.items}
                columns={c}
                checkboxVisibility={props.isSelection ? CheckboxVisibility.always : CheckboxVisibility.onHover}
                selectionMode={SelectionMode.none}
                enableShimmer={state.set.items.length == 0 && state.set.isFetching}
                isColumnSortingEnabled={state.set.selected.length == 0}
                constrainMode={ConstrainMode.unconstrained}
                layoutMode={props.isSelection ? DetailsListLayoutMode.fixedColumns : DetailsListLayoutMode.fixedColumns}
                compRef={ref}
                height={state.message == undefined ? "calc(100vh - 435px)" : "calc(100vh - 484px)"}

            />
            {state.totals ? <>
                <Separator alignContent="start"><b>{t("totalPeriod")}</b></Separator>

                <Stack tokens={{ childrenGap: 10 }} horizontal>

                    <TextView label={t("totalCredit")} value={state.totals?.totalCredit != undefined ? currencyFormatter(state.totals?.totalCredit, { significantDigits: state.totals?.currency?.decimalPlaces ?? 9, symbol: state.totals?.currency?.symbol }) : ""} />

                    <TextView label={t("totalDebit")} value={state.totals?.totalDebit != undefined ? currencyFormatter(state.totals?.totalDebit, { significantDigits: state.totals?.currency?.decimalPlaces ?? 9, symbol: state.totals?.currency?.symbol }) : ""} />

                    <TextView label={t("totalNumberOfCreditOperations")} value={state.totals?.totalNumberOfCreditOperations != undefined ? state.totals?.totalNumberOfCreditOperations + "" : ""} />
                    <TextView label={t("totalNumberOfDebitOperations")} value={state.totals?.totalNumberOfDebitOperations != undefined ? state.totals?.totalNumberOfDebitOperations + "" : ""} />


                </Stack>
            </> : undefined
            }

        </Stack >



    );
}




