/**
 * @fileoverview gRPC-Web generated client stub for report_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as report_pb from './report_pb';


export class ReportEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetTreasuryBalanceReport = new grpcWeb.MethodDescriptor(
    '/report_endpoint.ReportEndpoint/GetTreasuryBalanceReport',
    grpcWeb.MethodType.UNARY,
    report_pb.GetTreasuryBalanceReportRequest,
    report_pb.GetTreasuryBalanceReportResponse,
    (request: report_pb.GetTreasuryBalanceReportRequest) => {
      return request.serializeBinary();
    },
    report_pb.GetTreasuryBalanceReportResponse.deserializeBinary
  );

  getTreasuryBalanceReport(
    request: report_pb.GetTreasuryBalanceReportRequest,
    metadata: grpcWeb.Metadata | null): Promise<report_pb.GetTreasuryBalanceReportResponse>;

  getTreasuryBalanceReport(
    request: report_pb.GetTreasuryBalanceReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: report_pb.GetTreasuryBalanceReportResponse) => void): grpcWeb.ClientReadableStream<report_pb.GetTreasuryBalanceReportResponse>;

  getTreasuryBalanceReport(
    request: report_pb.GetTreasuryBalanceReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: report_pb.GetTreasuryBalanceReportResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/report_endpoint.ReportEndpoint/GetTreasuryBalanceReport',
        request,
        metadata || {},
        this.methodDescriptorGetTreasuryBalanceReport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/report_endpoint.ReportEndpoint/GetTreasuryBalanceReport',
    request,
    metadata || {},
    this.methodDescriptorGetTreasuryBalanceReport);
  }

  methodDescriptorGetAccountBalanceReport = new grpcWeb.MethodDescriptor(
    '/report_endpoint.ReportEndpoint/GetAccountBalanceReport',
    grpcWeb.MethodType.UNARY,
    report_pb.GetAccountBalanceReportRequest,
    report_pb.GetAccountBalanceReportResponse,
    (request: report_pb.GetAccountBalanceReportRequest) => {
      return request.serializeBinary();
    },
    report_pb.GetAccountBalanceReportResponse.deserializeBinary
  );

  getAccountBalanceReport(
    request: report_pb.GetAccountBalanceReportRequest,
    metadata: grpcWeb.Metadata | null): Promise<report_pb.GetAccountBalanceReportResponse>;

  getAccountBalanceReport(
    request: report_pb.GetAccountBalanceReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: report_pb.GetAccountBalanceReportResponse) => void): grpcWeb.ClientReadableStream<report_pb.GetAccountBalanceReportResponse>;

  getAccountBalanceReport(
    request: report_pb.GetAccountBalanceReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: report_pb.GetAccountBalanceReportResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/report_endpoint.ReportEndpoint/GetAccountBalanceReport',
        request,
        metadata || {},
        this.methodDescriptorGetAccountBalanceReport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/report_endpoint.ReportEndpoint/GetAccountBalanceReport',
    request,
    metadata || {},
    this.methodDescriptorGetAccountBalanceReport);
  }

  methodDescriptorGetTransactionTotalsReport = new grpcWeb.MethodDescriptor(
    '/report_endpoint.ReportEndpoint/GetTransactionTotalsReport',
    grpcWeb.MethodType.UNARY,
    report_pb.GetTransactionTotalsReportRequest,
    report_pb.GetTransactionTotalsReportResponse,
    (request: report_pb.GetTransactionTotalsReportRequest) => {
      return request.serializeBinary();
    },
    report_pb.GetTransactionTotalsReportResponse.deserializeBinary
  );

  getTransactionTotalsReport(
    request: report_pb.GetTransactionTotalsReportRequest,
    metadata: grpcWeb.Metadata | null): Promise<report_pb.GetTransactionTotalsReportResponse>;

  getTransactionTotalsReport(
    request: report_pb.GetTransactionTotalsReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: report_pb.GetTransactionTotalsReportResponse) => void): grpcWeb.ClientReadableStream<report_pb.GetTransactionTotalsReportResponse>;

  getTransactionTotalsReport(
    request: report_pb.GetTransactionTotalsReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: report_pb.GetTransactionTotalsReportResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/report_endpoint.ReportEndpoint/GetTransactionTotalsReport',
        request,
        metadata || {},
        this.methodDescriptorGetTransactionTotalsReport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/report_endpoint.ReportEndpoint/GetTransactionTotalsReport',
    request,
    metadata || {},
    this.methodDescriptorGetTransactionTotalsReport);
  }

  methodDescriptorExportTransactionTotalsReport = new grpcWeb.MethodDescriptor(
    '/report_endpoint.ReportEndpoint/ExportTransactionTotalsReport',
    grpcWeb.MethodType.UNARY,
    report_pb.ExportTransactionTotalsReportRequest,
    report_pb.ExportTransactionTotalsReportResponse,
    (request: report_pb.ExportTransactionTotalsReportRequest) => {
      return request.serializeBinary();
    },
    report_pb.ExportTransactionTotalsReportResponse.deserializeBinary
  );

  exportTransactionTotalsReport(
    request: report_pb.ExportTransactionTotalsReportRequest,
    metadata: grpcWeb.Metadata | null): Promise<report_pb.ExportTransactionTotalsReportResponse>;

  exportTransactionTotalsReport(
    request: report_pb.ExportTransactionTotalsReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: report_pb.ExportTransactionTotalsReportResponse) => void): grpcWeb.ClientReadableStream<report_pb.ExportTransactionTotalsReportResponse>;

  exportTransactionTotalsReport(
    request: report_pb.ExportTransactionTotalsReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: report_pb.ExportTransactionTotalsReportResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/report_endpoint.ReportEndpoint/ExportTransactionTotalsReport',
        request,
        metadata || {},
        this.methodDescriptorExportTransactionTotalsReport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/report_endpoint.ReportEndpoint/ExportTransactionTotalsReport',
    request,
    metadata || {},
    this.methodDescriptorExportTransactionTotalsReport);
  }

  methodDescriptorExportAccountBalanceReport = new grpcWeb.MethodDescriptor(
    '/report_endpoint.ReportEndpoint/ExportAccountBalanceReport',
    grpcWeb.MethodType.UNARY,
    report_pb.ExportAccountBalanceReportRequest,
    report_pb.ExportAccountBalanceReportResponse,
    (request: report_pb.ExportAccountBalanceReportRequest) => {
      return request.serializeBinary();
    },
    report_pb.ExportAccountBalanceReportResponse.deserializeBinary
  );

  exportAccountBalanceReport(
    request: report_pb.ExportAccountBalanceReportRequest,
    metadata: grpcWeb.Metadata | null): Promise<report_pb.ExportAccountBalanceReportResponse>;

  exportAccountBalanceReport(
    request: report_pb.ExportAccountBalanceReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: report_pb.ExportAccountBalanceReportResponse) => void): grpcWeb.ClientReadableStream<report_pb.ExportAccountBalanceReportResponse>;

  exportAccountBalanceReport(
    request: report_pb.ExportAccountBalanceReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: report_pb.ExportAccountBalanceReportResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/report_endpoint.ReportEndpoint/ExportAccountBalanceReport',
        request,
        metadata || {},
        this.methodDescriptorExportAccountBalanceReport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/report_endpoint.ReportEndpoint/ExportAccountBalanceReport',
    request,
    metadata || {},
    this.methodDescriptorExportAccountBalanceReport);
  }

  methodDescriptorExportTreasuryBalanceReport = new grpcWeb.MethodDescriptor(
    '/report_endpoint.ReportEndpoint/ExportTreasuryBalanceReport',
    grpcWeb.MethodType.UNARY,
    report_pb.ExportTreasuryBalanceReportRequest,
    report_pb.ExportTreasuryBalanceReportResponse,
    (request: report_pb.ExportTreasuryBalanceReportRequest) => {
      return request.serializeBinary();
    },
    report_pb.ExportTreasuryBalanceReportResponse.deserializeBinary
  );

  exportTreasuryBalanceReport(
    request: report_pb.ExportTreasuryBalanceReportRequest,
    metadata: grpcWeb.Metadata | null): Promise<report_pb.ExportTreasuryBalanceReportResponse>;

  exportTreasuryBalanceReport(
    request: report_pb.ExportTreasuryBalanceReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: report_pb.ExportTreasuryBalanceReportResponse) => void): grpcWeb.ClientReadableStream<report_pb.ExportTreasuryBalanceReportResponse>;

  exportTreasuryBalanceReport(
    request: report_pb.ExportTreasuryBalanceReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: report_pb.ExportTreasuryBalanceReportResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/report_endpoint.ReportEndpoint/ExportTreasuryBalanceReport',
        request,
        metadata || {},
        this.methodDescriptorExportTreasuryBalanceReport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/report_endpoint.ReportEndpoint/ExportTreasuryBalanceReport',
    request,
    metadata || {},
    this.methodDescriptorExportTreasuryBalanceReport);
  }

}

