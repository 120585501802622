// source: account_autorization.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('./common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.account_authorization_endpoint.AccountAuthorization', null, global);
goog.exportSymbol('proto.account_authorization_endpoint.AddAccountAuthorizationReplay', null, global);
goog.exportSymbol('proto.account_authorization_endpoint.AddAccountAuthorizationRequest', null, global);
goog.exportSymbol('proto.account_authorization_endpoint.AddAccountAuthorizationResponse', null, global);
goog.exportSymbol('proto.account_authorization_endpoint.AddAccountAuthorizationResponse.ResultCase', null, global);
goog.exportSymbol('proto.account_authorization_endpoint.ClientResponse', null, global);
goog.exportSymbol('proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay', null, global);
goog.exportSymbol('proto.account_authorization_endpoint.DeleteAccountAuthorizationRequest', null, global);
goog.exportSymbol('proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse', null, global);
goog.exportSymbol('proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse.ResultCase', null, global);
goog.exportSymbol('proto.account_authorization_endpoint.GetAccountAuthorizationReplay', null, global);
goog.exportSymbol('proto.account_authorization_endpoint.GetAccountAuthorizationRequest', null, global);
goog.exportSymbol('proto.account_authorization_endpoint.GetAccountAuthorizationResponse', null, global);
goog.exportSymbol('proto.account_authorization_endpoint.GetAccountAuthorizationResponse.ResultCase', null, global);
goog.exportSymbol('proto.account_authorization_endpoint.GetAccountAuthorizationsReplay', null, global);
goog.exportSymbol('proto.account_authorization_endpoint.GetAccountAuthorizationsRequest', null, global);
goog.exportSymbol('proto.account_authorization_endpoint.GetAccountAuthorizationsResponse', null, global);
goog.exportSymbol('proto.account_authorization_endpoint.GetAccountAuthorizationsResponse.ResultCase', null, global);
goog.exportSymbol('proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay', null, global);
goog.exportSymbol('proto.account_authorization_endpoint.UpdateAccountAuthorizationRequest', null, global);
goog.exportSymbol('proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse', null, global);
goog.exportSymbol('proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse.ResultCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_authorization_endpoint.AddAccountAuthorizationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account_authorization_endpoint.AddAccountAuthorizationRequest.repeatedFields_, null);
};
goog.inherits(proto.account_authorization_endpoint.AddAccountAuthorizationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_authorization_endpoint.AddAccountAuthorizationRequest.displayName = 'proto.account_authorization_endpoint.AddAccountAuthorizationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account_authorization_endpoint.UpdateAccountAuthorizationRequest.repeatedFields_, null);
};
goog.inherits(proto.account_authorization_endpoint.UpdateAccountAuthorizationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_authorization_endpoint.UpdateAccountAuthorizationRequest.displayName = 'proto.account_authorization_endpoint.UpdateAccountAuthorizationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_authorization_endpoint.DeleteAccountAuthorizationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_authorization_endpoint.DeleteAccountAuthorizationRequest.displayName = 'proto.account_authorization_endpoint.DeleteAccountAuthorizationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_authorization_endpoint.GetAccountAuthorizationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_authorization_endpoint.GetAccountAuthorizationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_authorization_endpoint.GetAccountAuthorizationRequest.displayName = 'proto.account_authorization_endpoint.GetAccountAuthorizationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_authorization_endpoint.GetAccountAuthorizationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_authorization_endpoint.GetAccountAuthorizationsRequest.displayName = 'proto.account_authorization_endpoint.GetAccountAuthorizationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_authorization_endpoint.AddAccountAuthorizationReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_authorization_endpoint.AddAccountAuthorizationReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_authorization_endpoint.AddAccountAuthorizationReplay.displayName = 'proto.account_authorization_endpoint.AddAccountAuthorizationReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay.displayName = 'proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay.displayName = 'proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_authorization_endpoint.ClientResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_authorization_endpoint.ClientResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_authorization_endpoint.ClientResponse.displayName = 'proto.account_authorization_endpoint.ClientResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_authorization_endpoint.GetAccountAuthorizationReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account_authorization_endpoint.GetAccountAuthorizationReplay.repeatedFields_, null);
};
goog.inherits(proto.account_authorization_endpoint.GetAccountAuthorizationReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_authorization_endpoint.GetAccountAuthorizationReplay.displayName = 'proto.account_authorization_endpoint.GetAccountAuthorizationReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_authorization_endpoint.AccountAuthorization = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account_authorization_endpoint.AccountAuthorization.repeatedFields_, null);
};
goog.inherits(proto.account_authorization_endpoint.AccountAuthorization, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_authorization_endpoint.AccountAuthorization.displayName = 'proto.account_authorization_endpoint.AccountAuthorization';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account_authorization_endpoint.GetAccountAuthorizationsReplay.repeatedFields_, null);
};
goog.inherits(proto.account_authorization_endpoint.GetAccountAuthorizationsReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_authorization_endpoint.GetAccountAuthorizationsReplay.displayName = 'proto.account_authorization_endpoint.GetAccountAuthorizationsReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_authorization_endpoint.AddAccountAuthorizationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_authorization_endpoint.AddAccountAuthorizationResponse.oneofGroups_);
};
goog.inherits(proto.account_authorization_endpoint.AddAccountAuthorizationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_authorization_endpoint.AddAccountAuthorizationResponse.displayName = 'proto.account_authorization_endpoint.AddAccountAuthorizationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse.oneofGroups_);
};
goog.inherits(proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse.displayName = 'proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse.oneofGroups_);
};
goog.inherits(proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse.displayName = 'proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_authorization_endpoint.GetAccountAuthorizationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_authorization_endpoint.GetAccountAuthorizationResponse.oneofGroups_);
};
goog.inherits(proto.account_authorization_endpoint.GetAccountAuthorizationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_authorization_endpoint.GetAccountAuthorizationResponse.displayName = 'proto.account_authorization_endpoint.GetAccountAuthorizationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_authorization_endpoint.GetAccountAuthorizationsResponse.oneofGroups_);
};
goog.inherits(proto.account_authorization_endpoint.GetAccountAuthorizationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_authorization_endpoint.GetAccountAuthorizationsResponse.displayName = 'proto.account_authorization_endpoint.GetAccountAuthorizationsResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account_authorization_endpoint.AddAccountAuthorizationRequest.repeatedFields_ = [4,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_authorization_endpoint.AddAccountAuthorizationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_authorization_endpoint.AddAccountAuthorizationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_authorization_endpoint.AddAccountAuthorizationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_authorization_endpoint.AddAccountAuthorizationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    account: jspb.Message.getFieldWithDefault(msg, 1, ""),
    authorizedclient: jspb.Message.getFieldWithDefault(msg, 2, ""),
    permissionsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    authcode: (f = msg.getAuthcode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_authorization_endpoint.AddAccountAuthorizationRequest}
 */
proto.account_authorization_endpoint.AddAccountAuthorizationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_authorization_endpoint.AddAccountAuthorizationRequest;
  return proto.account_authorization_endpoint.AddAccountAuthorizationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_authorization_endpoint.AddAccountAuthorizationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_authorization_endpoint.AddAccountAuthorizationRequest}
 */
proto.account_authorization_endpoint.AddAccountAuthorizationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthorizedclient(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPermissions(values[i]);
      }
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuthcode(value);
      break;
    case 6:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_authorization_endpoint.AddAccountAuthorizationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_authorization_endpoint.AddAccountAuthorizationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_authorization_endpoint.AddAccountAuthorizationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_authorization_endpoint.AddAccountAuthorizationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuthorizedclient();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      4,
      f
    );
  }
  f = message.getAuthcode();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional string account = 1;
 * @return {string}
 */
proto.account_authorization_endpoint.AddAccountAuthorizationRequest.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_authorization_endpoint.AddAccountAuthorizationRequest} returns this
 */
proto.account_authorization_endpoint.AddAccountAuthorizationRequest.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string authorizedClient = 2;
 * @return {string}
 */
proto.account_authorization_endpoint.AddAccountAuthorizationRequest.prototype.getAuthorizedclient = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_authorization_endpoint.AddAccountAuthorizationRequest} returns this
 */
proto.account_authorization_endpoint.AddAccountAuthorizationRequest.prototype.setAuthorizedclient = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated int32 Permissions = 4;
 * @return {!Array<number>}
 */
proto.account_authorization_endpoint.AddAccountAuthorizationRequest.prototype.getPermissionsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.account_authorization_endpoint.AddAccountAuthorizationRequest} returns this
 */
proto.account_authorization_endpoint.AddAccountAuthorizationRequest.prototype.setPermissionsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.account_authorization_endpoint.AddAccountAuthorizationRequest} returns this
 */
proto.account_authorization_endpoint.AddAccountAuthorizationRequest.prototype.addPermissions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account_authorization_endpoint.AddAccountAuthorizationRequest} returns this
 */
proto.account_authorization_endpoint.AddAccountAuthorizationRequest.prototype.clearPermissionsList = function() {
  return this.setPermissionsList([]);
};


/**
 * optional google.protobuf.StringValue authCode = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_authorization_endpoint.AddAccountAuthorizationRequest.prototype.getAuthcode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_authorization_endpoint.AddAccountAuthorizationRequest} returns this
*/
proto.account_authorization_endpoint.AddAccountAuthorizationRequest.prototype.setAuthcode = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_authorization_endpoint.AddAccountAuthorizationRequest} returns this
 */
proto.account_authorization_endpoint.AddAccountAuthorizationRequest.prototype.clearAuthcode = function() {
  return this.setAuthcode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_authorization_endpoint.AddAccountAuthorizationRequest.prototype.hasAuthcode = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated common.ExtraField extraFields = 6;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.account_authorization_endpoint.AddAccountAuthorizationRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 6));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.account_authorization_endpoint.AddAccountAuthorizationRequest} returns this
*/
proto.account_authorization_endpoint.AddAccountAuthorizationRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.account_authorization_endpoint.AddAccountAuthorizationRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account_authorization_endpoint.AddAccountAuthorizationRequest} returns this
 */
proto.account_authorization_endpoint.AddAccountAuthorizationRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};


/**
 * optional google.protobuf.Int32Value organization = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.account_authorization_endpoint.AddAccountAuthorizationRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.account_authorization_endpoint.AddAccountAuthorizationRequest} returns this
*/
proto.account_authorization_endpoint.AddAccountAuthorizationRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_authorization_endpoint.AddAccountAuthorizationRequest} returns this
 */
proto.account_authorization_endpoint.AddAccountAuthorizationRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_authorization_endpoint.AddAccountAuthorizationRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_authorization_endpoint.UpdateAccountAuthorizationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_authorization_endpoint.UpdateAccountAuthorizationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_authorization_endpoint.UpdateAccountAuthorizationRequest}
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_authorization_endpoint.UpdateAccountAuthorizationRequest;
  return proto.account_authorization_endpoint.UpdateAccountAuthorizationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_authorization_endpoint.UpdateAccountAuthorizationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_authorization_endpoint.UpdateAccountAuthorizationRequest}
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_authorization_endpoint.UpdateAccountAuthorizationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_authorization_endpoint.UpdateAccountAuthorizationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_authorization_endpoint.UpdateAccountAuthorizationRequest} returns this
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated common.ExtraField extraFields = 4;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 4));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.account_authorization_endpoint.UpdateAccountAuthorizationRequest} returns this
*/
proto.account_authorization_endpoint.UpdateAccountAuthorizationRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account_authorization_endpoint.UpdateAccountAuthorizationRequest} returns this
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_authorization_endpoint.DeleteAccountAuthorizationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_authorization_endpoint.DeleteAccountAuthorizationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    authcode: (f = msg.getAuthcode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_authorization_endpoint.DeleteAccountAuthorizationRequest}
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_authorization_endpoint.DeleteAccountAuthorizationRequest;
  return proto.account_authorization_endpoint.DeleteAccountAuthorizationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_authorization_endpoint.DeleteAccountAuthorizationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_authorization_endpoint.DeleteAccountAuthorizationRequest}
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuthcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_authorization_endpoint.DeleteAccountAuthorizationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_authorization_endpoint.DeleteAccountAuthorizationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuthcode();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_authorization_endpoint.DeleteAccountAuthorizationRequest} returns this
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue authCode = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationRequest.prototype.getAuthcode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_authorization_endpoint.DeleteAccountAuthorizationRequest} returns this
*/
proto.account_authorization_endpoint.DeleteAccountAuthorizationRequest.prototype.setAuthcode = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_authorization_endpoint.DeleteAccountAuthorizationRequest} returns this
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationRequest.prototype.clearAuthcode = function() {
  return this.setAuthcode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationRequest.prototype.hasAuthcode = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_authorization_endpoint.GetAccountAuthorizationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_authorization_endpoint.GetAccountAuthorizationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_authorization_endpoint.GetAccountAuthorizationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationRequest}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_authorization_endpoint.GetAccountAuthorizationRequest;
  return proto.account_authorization_endpoint.GetAccountAuthorizationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_authorization_endpoint.GetAccountAuthorizationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationRequest}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_authorization_endpoint.GetAccountAuthorizationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_authorization_endpoint.GetAccountAuthorizationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_authorization_endpoint.GetAccountAuthorizationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationRequest} returns this
 */
proto.account_authorization_endpoint.GetAccountAuthorizationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_authorization_endpoint.GetAccountAuthorizationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_authorization_endpoint.GetAccountAuthorizationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    account: (f = msg.getAccount()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    authorizedclient: (f = msg.getAuthorizedclient()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    order: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    nextto: (f = msg.getNextto()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    numofresults: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationsRequest}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_authorization_endpoint.GetAccountAuthorizationsRequest;
  return proto.account_authorization_endpoint.GetAccountAuthorizationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_authorization_endpoint.GetAccountAuthorizationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationsRequest}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuthorizedclient(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrder(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNextto(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumofresults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_authorization_endpoint.GetAccountAuthorizationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_authorization_endpoint.GetAccountAuthorizationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAuthorizedclient();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getNextto();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNumofresults();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
};


/**
 * optional google.protobuf.Int32Value organization = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationsRequest} returns this
*/
proto.account_authorization_endpoint.GetAccountAuthorizationsRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationsRequest} returns this
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue account = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsRequest.prototype.getAccount = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationsRequest} returns this
*/
proto.account_authorization_endpoint.GetAccountAuthorizationsRequest.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationsRequest} returns this
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsRequest.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsRequest.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue authorizedClient = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsRequest.prototype.getAuthorizedclient = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationsRequest} returns this
*/
proto.account_authorization_endpoint.GetAccountAuthorizationsRequest.prototype.setAuthorizedclient = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationsRequest} returns this
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsRequest.prototype.clearAuthorizedclient = function() {
  return this.setAuthorizedclient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsRequest.prototype.hasAuthorizedclient = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue search = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationsRequest} returns this
*/
proto.account_authorization_endpoint.GetAccountAuthorizationsRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationsRequest} returns this
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool order = 10;
 * @return {boolean}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsRequest.prototype.getOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationsRequest} returns this
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional google.protobuf.StringValue nextTo = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsRequest.prototype.getNextto = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationsRequest} returns this
*/
proto.account_authorization_endpoint.GetAccountAuthorizationsRequest.prototype.setNextto = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationsRequest} returns this
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsRequest.prototype.clearNextto = function() {
  return this.setNextto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsRequest.prototype.hasNextto = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional int32 numOfResults = 12;
 * @return {number}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsRequest.prototype.getNumofresults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationsRequest} returns this
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsRequest.prototype.setNumofresults = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_authorization_endpoint.AddAccountAuthorizationReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_authorization_endpoint.AddAccountAuthorizationReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_authorization_endpoint.AddAccountAuthorizationReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_authorization_endpoint.AddAccountAuthorizationReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_authorization_endpoint.AddAccountAuthorizationReplay}
 */
proto.account_authorization_endpoint.AddAccountAuthorizationReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_authorization_endpoint.AddAccountAuthorizationReplay;
  return proto.account_authorization_endpoint.AddAccountAuthorizationReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_authorization_endpoint.AddAccountAuthorizationReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_authorization_endpoint.AddAccountAuthorizationReplay}
 */
proto.account_authorization_endpoint.AddAccountAuthorizationReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 4:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_authorization_endpoint.AddAccountAuthorizationReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_authorization_endpoint.AddAccountAuthorizationReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_authorization_endpoint.AddAccountAuthorizationReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_authorization_endpoint.AddAccountAuthorizationReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.account_authorization_endpoint.AddAccountAuthorizationReplay.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_authorization_endpoint.AddAccountAuthorizationReplay} returns this
 */
proto.account_authorization_endpoint.AddAccountAuthorizationReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_authorization_endpoint.AddAccountAuthorizationReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_authorization_endpoint.AddAccountAuthorizationReplay} returns this
*/
proto.account_authorization_endpoint.AddAccountAuthorizationReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_authorization_endpoint.AddAccountAuthorizationReplay} returns this
 */
proto.account_authorization_endpoint.AddAccountAuthorizationReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_authorization_endpoint.AddAccountAuthorizationReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional common.MessageResponse message = 4;
 * @return {?proto.common.MessageResponse}
 */
proto.account_authorization_endpoint.AddAccountAuthorizationReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 4));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.account_authorization_endpoint.AddAccountAuthorizationReplay} returns this
*/
proto.account_authorization_endpoint.AddAccountAuthorizationReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_authorization_endpoint.AddAccountAuthorizationReplay} returns this
 */
proto.account_authorization_endpoint.AddAccountAuthorizationReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_authorization_endpoint.AddAccountAuthorizationReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay}
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay;
  return proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay}
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay} returns this
*/
proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay} returns this
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay} returns this
*/
proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay} returns this
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    deleteddate: (f = msg.getDeleteddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay}
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay;
  return proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay}
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeleteddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeleteddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp deletedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay.prototype.getDeleteddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay} returns this
*/
proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay.prototype.setDeleteddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay} returns this
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay.prototype.clearDeleteddate = function() {
  return this.setDeleteddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay.prototype.hasDeleteddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay} returns this
*/
proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay} returns this
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_authorization_endpoint.ClientResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_authorization_endpoint.ClientResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_authorization_endpoint.ClientResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_authorization_endpoint.ClientResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    firstname: (f = msg.getFirstname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    middlename: (f = msg.getMiddlename()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    lastname: (f = msg.getLastname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_authorization_endpoint.ClientResponse}
 */
proto.account_authorization_endpoint.ClientResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_authorization_endpoint.ClientResponse;
  return proto.account_authorization_endpoint.ClientResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_authorization_endpoint.ClientResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_authorization_endpoint.ClientResponse}
 */
proto.account_authorization_endpoint.ClientResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFirstname(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMiddlename(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLastname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_authorization_endpoint.ClientResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_authorization_endpoint.ClientResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_authorization_endpoint.ClientResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_authorization_endpoint.ClientResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFirstname();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMiddlename();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLastname();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue id = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_authorization_endpoint.ClientResponse.prototype.getId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_authorization_endpoint.ClientResponse} returns this
*/
proto.account_authorization_endpoint.ClientResponse.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_authorization_endpoint.ClientResponse} returns this
 */
proto.account_authorization_endpoint.ClientResponse.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_authorization_endpoint.ClientResponse.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue firstName = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_authorization_endpoint.ClientResponse.prototype.getFirstname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_authorization_endpoint.ClientResponse} returns this
*/
proto.account_authorization_endpoint.ClientResponse.prototype.setFirstname = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_authorization_endpoint.ClientResponse} returns this
 */
proto.account_authorization_endpoint.ClientResponse.prototype.clearFirstname = function() {
  return this.setFirstname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_authorization_endpoint.ClientResponse.prototype.hasFirstname = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue middleName = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_authorization_endpoint.ClientResponse.prototype.getMiddlename = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_authorization_endpoint.ClientResponse} returns this
*/
proto.account_authorization_endpoint.ClientResponse.prototype.setMiddlename = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_authorization_endpoint.ClientResponse} returns this
 */
proto.account_authorization_endpoint.ClientResponse.prototype.clearMiddlename = function() {
  return this.setMiddlename(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_authorization_endpoint.ClientResponse.prototype.hasMiddlename = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue lastName = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_authorization_endpoint.ClientResponse.prototype.getLastname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_authorization_endpoint.ClientResponse} returns this
*/
proto.account_authorization_endpoint.ClientResponse.prototype.setLastname = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_authorization_endpoint.ClientResponse} returns this
 */
proto.account_authorization_endpoint.ClientResponse.prototype.clearLastname = function() {
  return this.setLastname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_authorization_endpoint.ClientResponse.prototype.hasLastname = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account_authorization_endpoint.GetAccountAuthorizationReplay.repeatedFields_ = [4,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_authorization_endpoint.GetAccountAuthorizationReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_authorization_endpoint.GetAccountAuthorizationReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_authorization_endpoint.GetAccountAuthorizationReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    account: (f = msg.getAccount()) && Protos_common_pb.AccountResponse.toObject(includeInstance, f),
    authorizedclient: (f = msg.getAuthorizedclient()) && proto.account_authorization_endpoint.ClientResponse.toObject(includeInstance, f),
    permissionsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationReplay}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_authorization_endpoint.GetAccountAuthorizationReplay;
  return proto.account_authorization_endpoint.GetAccountAuthorizationReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_authorization_endpoint.GetAccountAuthorizationReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationReplay}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new Protos_common_pb.AccountResponse;
      reader.readMessage(value,Protos_common_pb.AccountResponse.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    case 3:
      var value = new proto.account_authorization_endpoint.ClientResponse;
      reader.readMessage(value,proto.account_authorization_endpoint.ClientResponse.deserializeBinaryFromReader);
      msg.setAuthorizedclient(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPermissions(values[i]);
      }
      break;
    case 7:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    case 9:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_authorization_endpoint.GetAccountAuthorizationReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_authorization_endpoint.GetAccountAuthorizationReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_authorization_endpoint.GetAccountAuthorizationReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.AccountResponse.serializeBinaryToWriter
    );
  }
  f = message.getAuthorizedclient();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.account_authorization_endpoint.ClientResponse.serializeBinaryToWriter
    );
  }
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      4,
      f
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional common.AccountResponse account = 2;
 * @return {?proto.common.AccountResponse}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationReplay.prototype.getAccount = function() {
  return /** @type{?proto.common.AccountResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.AccountResponse, 2));
};


/**
 * @param {?proto.common.AccountResponse|undefined} value
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationReplay} returns this
*/
proto.account_authorization_endpoint.GetAccountAuthorizationReplay.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationReplay} returns this
 */
proto.account_authorization_endpoint.GetAccountAuthorizationReplay.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationReplay.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ClientResponse authorizedClient = 3;
 * @return {?proto.account_authorization_endpoint.ClientResponse}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationReplay.prototype.getAuthorizedclient = function() {
  return /** @type{?proto.account_authorization_endpoint.ClientResponse} */ (
    jspb.Message.getWrapperField(this, proto.account_authorization_endpoint.ClientResponse, 3));
};


/**
 * @param {?proto.account_authorization_endpoint.ClientResponse|undefined} value
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationReplay} returns this
*/
proto.account_authorization_endpoint.GetAccountAuthorizationReplay.prototype.setAuthorizedclient = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationReplay} returns this
 */
proto.account_authorization_endpoint.GetAccountAuthorizationReplay.prototype.clearAuthorizedclient = function() {
  return this.setAuthorizedclient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationReplay.prototype.hasAuthorizedclient = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated int32 permissions = 4;
 * @return {!Array<number>}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationReplay.prototype.getPermissionsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationReplay} returns this
 */
proto.account_authorization_endpoint.GetAccountAuthorizationReplay.prototype.setPermissionsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationReplay} returns this
 */
proto.account_authorization_endpoint.GetAccountAuthorizationReplay.prototype.addPermissions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationReplay} returns this
 */
proto.account_authorization_endpoint.GetAccountAuthorizationReplay.prototype.clearPermissionsList = function() {
  return this.setPermissionsList([]);
};


/**
 * repeated common.ExtraField extraFields = 7;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationReplay.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 7));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationReplay} returns this
*/
proto.account_authorization_endpoint.GetAccountAuthorizationReplay.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationReplay.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationReplay} returns this
 */
proto.account_authorization_endpoint.GetAccountAuthorizationReplay.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};


/**
 * optional common.ProfileResponse createdBy = 9;
 * @return {?proto.common.ProfileResponse}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationReplay.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 9));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationReplay} returns this
*/
proto.account_authorization_endpoint.GetAccountAuthorizationReplay.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationReplay} returns this
 */
proto.account_authorization_endpoint.GetAccountAuthorizationReplay.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationReplay.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationReplay} returns this
*/
proto.account_authorization_endpoint.GetAccountAuthorizationReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationReplay} returns this
 */
proto.account_authorization_endpoint.GetAccountAuthorizationReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string id = 12;
 * @return {string}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationReplay.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationReplay} returns this
 */
proto.account_authorization_endpoint.GetAccountAuthorizationReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account_authorization_endpoint.AccountAuthorization.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_authorization_endpoint.AccountAuthorization.prototype.toObject = function(opt_includeInstance) {
  return proto.account_authorization_endpoint.AccountAuthorization.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_authorization_endpoint.AccountAuthorization} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_authorization_endpoint.AccountAuthorization.toObject = function(includeInstance, msg) {
  var f, obj = {
    account: (f = msg.getAccount()) && Protos_common_pb.AccountResponse.toObject(includeInstance, f),
    authorizedclient: (f = msg.getAuthorizedclient()) && proto.account_authorization_endpoint.ClientResponse.toObject(includeInstance, f),
    permissionsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_authorization_endpoint.AccountAuthorization}
 */
proto.account_authorization_endpoint.AccountAuthorization.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_authorization_endpoint.AccountAuthorization;
  return proto.account_authorization_endpoint.AccountAuthorization.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_authorization_endpoint.AccountAuthorization} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_authorization_endpoint.AccountAuthorization}
 */
proto.account_authorization_endpoint.AccountAuthorization.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new Protos_common_pb.AccountResponse;
      reader.readMessage(value,Protos_common_pb.AccountResponse.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    case 3:
      var value = new proto.account_authorization_endpoint.ClientResponse;
      reader.readMessage(value,proto.account_authorization_endpoint.ClientResponse.deserializeBinaryFromReader);
      msg.setAuthorizedclient(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPermissions(values[i]);
      }
      break;
    case 9:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_authorization_endpoint.AccountAuthorization.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_authorization_endpoint.AccountAuthorization.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_authorization_endpoint.AccountAuthorization} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_authorization_endpoint.AccountAuthorization.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.AccountResponse.serializeBinaryToWriter
    );
  }
  f = message.getAuthorizedclient();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.account_authorization_endpoint.ClientResponse.serializeBinaryToWriter
    );
  }
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      4,
      f
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional common.AccountResponse account = 2;
 * @return {?proto.common.AccountResponse}
 */
proto.account_authorization_endpoint.AccountAuthorization.prototype.getAccount = function() {
  return /** @type{?proto.common.AccountResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.AccountResponse, 2));
};


/**
 * @param {?proto.common.AccountResponse|undefined} value
 * @return {!proto.account_authorization_endpoint.AccountAuthorization} returns this
*/
proto.account_authorization_endpoint.AccountAuthorization.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_authorization_endpoint.AccountAuthorization} returns this
 */
proto.account_authorization_endpoint.AccountAuthorization.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_authorization_endpoint.AccountAuthorization.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ClientResponse authorizedClient = 3;
 * @return {?proto.account_authorization_endpoint.ClientResponse}
 */
proto.account_authorization_endpoint.AccountAuthorization.prototype.getAuthorizedclient = function() {
  return /** @type{?proto.account_authorization_endpoint.ClientResponse} */ (
    jspb.Message.getWrapperField(this, proto.account_authorization_endpoint.ClientResponse, 3));
};


/**
 * @param {?proto.account_authorization_endpoint.ClientResponse|undefined} value
 * @return {!proto.account_authorization_endpoint.AccountAuthorization} returns this
*/
proto.account_authorization_endpoint.AccountAuthorization.prototype.setAuthorizedclient = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_authorization_endpoint.AccountAuthorization} returns this
 */
proto.account_authorization_endpoint.AccountAuthorization.prototype.clearAuthorizedclient = function() {
  return this.setAuthorizedclient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_authorization_endpoint.AccountAuthorization.prototype.hasAuthorizedclient = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated int32 permissions = 4;
 * @return {!Array<number>}
 */
proto.account_authorization_endpoint.AccountAuthorization.prototype.getPermissionsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.account_authorization_endpoint.AccountAuthorization} returns this
 */
proto.account_authorization_endpoint.AccountAuthorization.prototype.setPermissionsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.account_authorization_endpoint.AccountAuthorization} returns this
 */
proto.account_authorization_endpoint.AccountAuthorization.prototype.addPermissions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account_authorization_endpoint.AccountAuthorization} returns this
 */
proto.account_authorization_endpoint.AccountAuthorization.prototype.clearPermissionsList = function() {
  return this.setPermissionsList([]);
};


/**
 * optional common.ProfileResponse createdBy = 9;
 * @return {?proto.common.ProfileResponse}
 */
proto.account_authorization_endpoint.AccountAuthorization.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 9));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.account_authorization_endpoint.AccountAuthorization} returns this
*/
proto.account_authorization_endpoint.AccountAuthorization.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_authorization_endpoint.AccountAuthorization} returns this
 */
proto.account_authorization_endpoint.AccountAuthorization.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_authorization_endpoint.AccountAuthorization.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_authorization_endpoint.AccountAuthorization.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_authorization_endpoint.AccountAuthorization} returns this
*/
proto.account_authorization_endpoint.AccountAuthorization.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_authorization_endpoint.AccountAuthorization} returns this
 */
proto.account_authorization_endpoint.AccountAuthorization.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_authorization_endpoint.AccountAuthorization.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string id = 12;
 * @return {string}
 */
proto.account_authorization_endpoint.AccountAuthorization.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_authorization_endpoint.AccountAuthorization} returns this
 */
proto.account_authorization_endpoint.AccountAuthorization.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_authorization_endpoint.GetAccountAuthorizationsReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_authorization_endpoint.GetAccountAuthorizationsReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountauthorizationsList: jspb.Message.toObjectList(msg.getAccountauthorizationsList(),
    proto.account_authorization_endpoint.AccountAuthorization.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationsReplay}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_authorization_endpoint.GetAccountAuthorizationsReplay;
  return proto.account_authorization_endpoint.GetAccountAuthorizationsReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_authorization_endpoint.GetAccountAuthorizationsReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationsReplay}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_authorization_endpoint.AccountAuthorization;
      reader.readMessage(value,proto.account_authorization_endpoint.AccountAuthorization.deserializeBinaryFromReader);
      msg.addAccountauthorizations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_authorization_endpoint.GetAccountAuthorizationsReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_authorization_endpoint.GetAccountAuthorizationsReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountauthorizationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.account_authorization_endpoint.AccountAuthorization.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AccountAuthorization accountAuthorizations = 1;
 * @return {!Array<!proto.account_authorization_endpoint.AccountAuthorization>}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsReplay.prototype.getAccountauthorizationsList = function() {
  return /** @type{!Array<!proto.account_authorization_endpoint.AccountAuthorization>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.account_authorization_endpoint.AccountAuthorization, 1));
};


/**
 * @param {!Array<!proto.account_authorization_endpoint.AccountAuthorization>} value
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationsReplay} returns this
*/
proto.account_authorization_endpoint.GetAccountAuthorizationsReplay.prototype.setAccountauthorizationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.account_authorization_endpoint.AccountAuthorization=} opt_value
 * @param {number=} opt_index
 * @return {!proto.account_authorization_endpoint.AccountAuthorization}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsReplay.prototype.addAccountauthorizations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.account_authorization_endpoint.AccountAuthorization, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationsReplay} returns this
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsReplay.prototype.clearAccountauthorizationsList = function() {
  return this.setAccountauthorizationsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_authorization_endpoint.AddAccountAuthorizationResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_authorization_endpoint.AddAccountAuthorizationResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_authorization_endpoint.AddAccountAuthorizationResponse.ResultCase}
 */
proto.account_authorization_endpoint.AddAccountAuthorizationResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_authorization_endpoint.AddAccountAuthorizationResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_authorization_endpoint.AddAccountAuthorizationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_authorization_endpoint.AddAccountAuthorizationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_authorization_endpoint.AddAccountAuthorizationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_authorization_endpoint.AddAccountAuthorizationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_authorization_endpoint.AddAccountAuthorizationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_authorization_endpoint.AddAccountAuthorizationReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_authorization_endpoint.AddAccountAuthorizationResponse}
 */
proto.account_authorization_endpoint.AddAccountAuthorizationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_authorization_endpoint.AddAccountAuthorizationResponse;
  return proto.account_authorization_endpoint.AddAccountAuthorizationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_authorization_endpoint.AddAccountAuthorizationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_authorization_endpoint.AddAccountAuthorizationResponse}
 */
proto.account_authorization_endpoint.AddAccountAuthorizationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_authorization_endpoint.AddAccountAuthorizationReplay;
      reader.readMessage(value,proto.account_authorization_endpoint.AddAccountAuthorizationReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_authorization_endpoint.AddAccountAuthorizationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_authorization_endpoint.AddAccountAuthorizationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_authorization_endpoint.AddAccountAuthorizationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_authorization_endpoint.AddAccountAuthorizationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_authorization_endpoint.AddAccountAuthorizationReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional AddAccountAuthorizationReplay success = 1;
 * @return {?proto.account_authorization_endpoint.AddAccountAuthorizationReplay}
 */
proto.account_authorization_endpoint.AddAccountAuthorizationResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_authorization_endpoint.AddAccountAuthorizationReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_authorization_endpoint.AddAccountAuthorizationReplay, 1));
};


/**
 * @param {?proto.account_authorization_endpoint.AddAccountAuthorizationReplay|undefined} value
 * @return {!proto.account_authorization_endpoint.AddAccountAuthorizationResponse} returns this
*/
proto.account_authorization_endpoint.AddAccountAuthorizationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_authorization_endpoint.AddAccountAuthorizationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_authorization_endpoint.AddAccountAuthorizationResponse} returns this
 */
proto.account_authorization_endpoint.AddAccountAuthorizationResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_authorization_endpoint.AddAccountAuthorizationResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse.ResultCase}
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse}
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse;
  return proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse}
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay;
      reader.readMessage(value,proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateAccountAuthorizationReplay success = 1;
 * @return {?proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay}
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay, 1));
};


/**
 * @param {?proto.account_authorization_endpoint.UpdateAccountAuthorizationReplay|undefined} value
 * @return {!proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse} returns this
*/
proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse} returns this
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_authorization_endpoint.UpdateAccountAuthorizationResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse.ResultCase}
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse}
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse;
  return proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse}
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay;
      reader.readMessage(value,proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeleteAccountAuthorizationReplay success = 1;
 * @return {?proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay}
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay, 1));
};


/**
 * @param {?proto.account_authorization_endpoint.DeleteAccountAuthorizationReplay|undefined} value
 * @return {!proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse} returns this
*/
proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse} returns this
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_authorization_endpoint.DeleteAccountAuthorizationResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_authorization_endpoint.GetAccountAuthorizationResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_authorization_endpoint.GetAccountAuthorizationResponse.ResultCase}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_authorization_endpoint.GetAccountAuthorizationResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_authorization_endpoint.GetAccountAuthorizationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_authorization_endpoint.GetAccountAuthorizationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_authorization_endpoint.GetAccountAuthorizationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_authorization_endpoint.GetAccountAuthorizationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_authorization_endpoint.GetAccountAuthorizationReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationResponse}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_authorization_endpoint.GetAccountAuthorizationResponse;
  return proto.account_authorization_endpoint.GetAccountAuthorizationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_authorization_endpoint.GetAccountAuthorizationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationResponse}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_authorization_endpoint.GetAccountAuthorizationReplay;
      reader.readMessage(value,proto.account_authorization_endpoint.GetAccountAuthorizationReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_authorization_endpoint.GetAccountAuthorizationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_authorization_endpoint.GetAccountAuthorizationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_authorization_endpoint.GetAccountAuthorizationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_authorization_endpoint.GetAccountAuthorizationReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetAccountAuthorizationReplay success = 1;
 * @return {?proto.account_authorization_endpoint.GetAccountAuthorizationReplay}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_authorization_endpoint.GetAccountAuthorizationReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_authorization_endpoint.GetAccountAuthorizationReplay, 1));
};


/**
 * @param {?proto.account_authorization_endpoint.GetAccountAuthorizationReplay|undefined} value
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationResponse} returns this
*/
proto.account_authorization_endpoint.GetAccountAuthorizationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_authorization_endpoint.GetAccountAuthorizationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationResponse} returns this
 */
proto.account_authorization_endpoint.GetAccountAuthorizationResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_authorization_endpoint.GetAccountAuthorizationsResponse.ResultCase}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_authorization_endpoint.GetAccountAuthorizationsResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_authorization_endpoint.GetAccountAuthorizationsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_authorization_endpoint.GetAccountAuthorizationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_authorization_endpoint.GetAccountAuthorizationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_authorization_endpoint.GetAccountAuthorizationsReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationsResponse}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_authorization_endpoint.GetAccountAuthorizationsResponse;
  return proto.account_authorization_endpoint.GetAccountAuthorizationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_authorization_endpoint.GetAccountAuthorizationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationsResponse}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_authorization_endpoint.GetAccountAuthorizationsReplay;
      reader.readMessage(value,proto.account_authorization_endpoint.GetAccountAuthorizationsReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_authorization_endpoint.GetAccountAuthorizationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_authorization_endpoint.GetAccountAuthorizationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_authorization_endpoint.GetAccountAuthorizationsReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetAccountAuthorizationsReplay success = 1;
 * @return {?proto.account_authorization_endpoint.GetAccountAuthorizationsReplay}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_authorization_endpoint.GetAccountAuthorizationsReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_authorization_endpoint.GetAccountAuthorizationsReplay, 1));
};


/**
 * @param {?proto.account_authorization_endpoint.GetAccountAuthorizationsReplay|undefined} value
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationsResponse} returns this
*/
proto.account_authorization_endpoint.GetAccountAuthorizationsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_authorization_endpoint.GetAccountAuthorizationsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_authorization_endpoint.GetAccountAuthorizationsResponse} returns this
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_authorization_endpoint.GetAccountAuthorizationsResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.account_authorization_endpoint);
