import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { UserEndpointClient } from '../../../../repository/UserManagement/Customers/UserServiceClientPb';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIResponse, refreshSession, getSession, ACCOUNTANT_API_URL, APIRequest } from '../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../app/Helpers';
import { TableState } from '../../../common/Table/TableSate';
import { number } from 'yup';
import { GetCurrenciesRequest, GetCurrenciesResponse } from '../../../../repository/Accountant/currency_pb';
import { CurrencyEndpointClient } from '../../../../repository/Accountant/CurrencyServiceClientPb';
import { TreasuryEndpointClient } from '../../../../repository/Accountant/TreasuryServiceClientPb';
import { TreasuryTypeEndpointClient } from '../../../../repository/Accountant/Treasury_typeServiceClientPb';
import { CloseTreasuryRequest, CloseTreasuryResponse, GetTreasuriesRequest, UpdateTreasuryLimitationResponse, GetTreasuriesResponse, UpdateTreasuryLimitationRequest, UpdateTreasuryStatusRequest, UpdateTreasuryStatusResponse } from '../../../../repository/Accountant/treasury_pb';
import { GetTreasuryTypesRequest, GetTreasuryTypesResponse } from '../../../../repository/Accountant/treasury_type_pb';
import { GetLimitationsRequest, GetLimitationsResponse } from '../../../../repository/Accountant/limitation_pb';
import { LimitationEndpointClient } from '../../../../repository/Accountant/LimitationServiceClientPb';
import { TreasuryStatus } from '../../../../app/Enums';


export interface TreasuriesPageState {
    set: TableState
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    searchText: string | undefined,
    filters: any[],
    currencies: TableState
    treasuryTypes: TableState
    limitations: TableState,
    codeFilterOption: string | undefined,
    natrueFilterOption: { key: number, text: string },
    statusFilterOption: { key: number, text: string },

}

const initialState: TreasuriesPageState = {
    set: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    isChangeStateLoading: false,
    message: undefined,
    searchText: undefined,
    filters: [],
    currencies: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    treasuryTypes: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    limitations: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    codeFilterOption: undefined,
    natrueFilterOption: { key: 0, text: "" },
    statusFilterOption: { key: 0, text: "" },

}

const client = new TreasuryEndpointClient(ACCOUNTANT_API_URL, null, null);
const clientCurrency = new CurrencyEndpointClient(ACCOUNTANT_API_URL, null, null);
const clientTreasuryType = new TreasuryTypeEndpointClient(ACCOUNTANT_API_URL, null, null);
const clienLimition = new LimitationEndpointClient(ACCOUNTANT_API_URL, null, null);

export const getItems = createAsyncThunk<GetTreasuriesResponse.AsObject, APIRequest<GetTreasuriesRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/treasuries/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await client.getTreasuries(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getCurrencies = createAsyncThunk<GetCurrenciesResponse.AsObject, APIRequest<GetCurrenciesRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/treasuries/currencies/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clientCurrency.getCurrencies(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getTreasuryTypes = createAsyncThunk<GetTreasuryTypesResponse.AsObject, APIRequest<GetTreasuryTypesRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/treasuries/treasuryTypes/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clientTreasuryType.getTreasuryTypes(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getLimitations = createAsyncThunk<GetLimitationsResponse.AsObject, APIRequest<GetLimitationsRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/treasuries/limitations/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clienLimition.getLimitations(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const updateStatus = createAsyncThunk<APIResponse<UpdateTreasuryStatusResponse.AsObject>, APIRequest<UpdateTreasuryStatusRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/treasuries/updateStatus',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.updateStatus(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId(), status: req.body.getStatus() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)


export const close = createAsyncThunk<APIResponse<CloseTreasuryResponse.AsObject>, APIRequest<CloseTreasuryRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/treasuries/close',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.close(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)


export const updateLimit = createAsyncThunk<APIResponse<UpdateTreasuryLimitationResponse.AsObject>, APIRequest<UpdateTreasuryLimitationRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/treasuries/updateLimitation',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.updateLimitation(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId(), limitationId: req.body.getLimitation() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)


const getFilters = (state: TreasuriesPageState): any[] => {
    const selected = [];
    if (state.treasuryTypes.selected.length > 0) {
        selected.push({ key: 1, name: state.treasuryTypes.selected.at(0).name });
    }
    if (state.currencies.selected.length > 0) {
        selected.push({ key: 2, name: state.currencies.selected.at(0).name });
    }

    if (state.codeFilterOption && state.codeFilterOption.length > 0) {
        selected.push({ key: 3, name: state.codeFilterOption });
    }
    if (state.statusFilterOption.key != 0) {
        selected.push({ key: 4, name: state.statusFilterOption.text });
    }
    if (state.natrueFilterOption.key != 0) {
        selected.push({ key: 5, name: state.natrueFilterOption.text });
    }

    return selected;
}

export const treasuriesPageSlice = createSlice({
    name: 'pages/accountant/treasuries',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.set = initialState.set;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
            state.searchText = initialState.searchText;
            state.treasuryTypes = initialState.treasuryTypes;
            state.codeFilterOption = initialState.codeFilterOption;
            state.currencies = initialState.currencies;
            state.limitations = initialState.limitations;
            state.natrueFilterOption = initialState.natrueFilterOption;
            state.statusFilterOption = initialState.statusFilterOption;
            state.filters = initialState.filters;

        },
        resetSet: (state) => {
            state.set.items = [];
            state.set.hasMore = true;
        },
        resetLimitations: (state) => {
            state.limitations.items = [];
            state.limitations.hasMore = true;
        },
        resetCurrencies: (state) => {
            state.currencies.items = [];
            state.currencies.hasMore = true;
        },
        resetTreasuryTypes: (state) => {
            state.treasuryTypes.items = [];
            state.treasuryTypes.hasMore = true;
        },

        setSort: (state, action: PayloadAction<boolean>) => {
            state.set.isDescending = action.payload;
        },
        setNumberOfResults: (state, action: PayloadAction<number>) => {
            state.set.numberOfResults = action.payload;
        },
        setSelectedItems: (state, action: PayloadAction<any[]>) => {
            state.set.selected = action.payload;
        },
        setSelectedCurrencyItems: (state, action: PayloadAction<any[]>) => {
            state.currencies.selected = action.payload;
            state.filters = getFilters(state);

        },
        setSelectedLimitationItems: (state, action: PayloadAction<any[]>) => {
            state.limitations.selected = action.payload;
            
        },
        setSelectedTreasuryTypeItems: (state, action: PayloadAction<any[]>) => {
            state.treasuryTypes.selected = action.payload;
            state.filters = getFilters(state);

        },
     
        setSearchText: (state, action: PayloadAction<string | undefined>) => {
            state.searchText = action.payload;
        },
        setIsFilteredSet: (state, action: PayloadAction<boolean>) => {
            state.set.isFilteredSet = action.payload;
        },
        setIsFilteredCurrenciesSet: (state, action: PayloadAction<boolean>) => {
            state.currencies.isFilteredSet = action.payload;
        },
        setIsFilteredLimitationsSet: (state, action: PayloadAction<boolean>) => {
            state.limitations.isFilteredSet = action.payload;
        },
        setIsFilteredTreasuryTypesSet: (state, action: PayloadAction<boolean>) => {
            state.treasuryTypes.isFilteredSet = action.payload;
        },
    
        setStatusFilterOption: (state, action: PayloadAction<{ key: number, text: string }>) => {
            state.statusFilterOption = action.payload;
            state.filters = getFilters(state);
        },
        setCodeFilterOption: (state, action: PayloadAction<string | undefined>) => {
            state.codeFilterOption = action.payload;
            state.filters = getFilters(state);
        },
        setNatureFilterOption: (state, action: PayloadAction<{ key: number, text: string }>) => {
            state.natrueFilterOption = action.payload;
            state.filters = getFilters(state);
        },
        addItem: (state, action: PayloadAction<any>) => {
            if (!state.set.isFilteredSet) {
                if (!state.set.hasMore && !state.set.isDescending) {
                    state.set.items.push(action.payload);
                } else if (state.set.isDescending) {
                    state.set.items.unshift(action.payload);
                }
            }
        },
        updateItem: (state, action: PayloadAction<any>) => {
            let selected = state.set.selected;
            if (action?.payload) {
                if (selected.length > 0 && selected.at(0).id == action?.payload?.id) {
                    selected[0] = action?.payload
                }
                var u = state.set.items.findIndex(e => e.id == action?.payload?.id)
                if (u >= 0) {
                    state.set.items[u] = action?.payload
                }
            }

        }

    },
    extraReducers: (builder) => {
        builder.addCase(getTreasuryTypes.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.treasurytypesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])

                if (r.length < state.treasuryTypes.numberOfResults) {
                    state.treasuryTypes.hasMore = false;
                }
                state.treasuryTypes.items = state.treasuryTypes.items.concat(r);
          
            } else {
                state.treasuryTypes.hasMore = false;
            }
            state.treasuryTypes.isFetching = false

        })
        builder.addCase(getTreasuryTypes.rejected, (state, action) => {
            state.treasuryTypes.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getTreasuryTypes.pending, (state, action) => {
            state.treasuryTypes.isFetching = true;
            //state.message = undefined;
        })

        builder.addCase(getCurrencies.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.currenciesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        code: val.code?.value ?? "",
                        icon: val.iconsource?.value ?? "",
                        form: val.form?.formid,
                        symbol: val.symbol?.value ?? "",
                        decimalPlaces: val.decimalplaces?.value,

                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.currencies.numberOfResults) {
                    state.currencies.hasMore = false;
                }
                state.currencies.items = state.currencies.items.concat(r);

          
                //console.log(state.items)

            } else {
                state.currencies.hasMore = false;
            }
            state.currencies.isFetching = false

        })
        builder.addCase(getCurrencies.rejected, (state, action) => {
            state.currencies.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getCurrencies.pending, (state, action) => {
            state.currencies.isFetching = true;
            //state.message = undefined;
        })




        builder.addCase(getLimitations.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.limitationsList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        isActive: val.isactive?.value ?? undefined,
                        type: val.type?.value ?? undefined,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.limitations.numberOfResults) {
                    state.limitations.hasMore = false;
                }
                state.limitations.items = state.limitations.items.concat(r);
            } else {
                state.limitations.hasMore = false;
            }
            state.limitations.isFetching = false

        })
        builder.addCase(getLimitations.rejected, (state, action) => {
            state.limitations.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getLimitations.pending, (state, action) => {
            state.limitations.isFetching = true;
            //state.message = undefined;
        })

        builder.addCase(getItems.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.treasuriesList.map(val => {
                  
                    return {
                        id: val.id ,
                        internalId: val.internalid,
                        name: val.name?.value ?? "",
                        code: val.code?.value ?? "",
                        nature: val.nature?.id?.value ?? undefined,
                        treasuryTypeId: val.treasurytype?.id?.value ?? "",
                        treasuryTypeName: val.treasurytype?.name?.value ?? "",
                        status: val.status?.value ?? undefined,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                let l = [];
                if (state.set.items.length != 0) {
                    l = state.set.items.slice(0, state.set.items.length - 10)
                }
                l = l.concat(r);
                if (r.length < state.set.numberOfResults) {
                    state.set.hasMore = false;
                } else {
                    l = l.concat(...Array(10).fill(null));
                }

                state.set.items = l
                //console.log(state.items)

            } else {
                state.set.hasMore = false;
            }
            state.set.isFetching = false

        })
        builder.addCase(getItems.rejected, (state, action) => {
            state.set.isFetching = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getItems.pending, (state, action) => {
            state.set.isFetching = true;
            state.message = undefined;
        })


        builder.addCase(updateStatus.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response?.success?.message);
                let selected = state.set.selected;
                if (payload?.metadata) {
                    if (selected.length > 0 && selected.at(0).internalId == payload?.metadata.id) {
                        selected.at(0).status = payload?.metadata.status
                    }
                    var u = state.set.items.findIndex(e => e.internalId == payload?.metadata.id)
                    if (u >= 0) {
                        state.set.items.at(u).status = payload?.metadata.status
                    }

                }
            }
        })
        builder.addCase(updateStatus.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateStatus.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })
        builder.addCase(close.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response?.success?.message);
                let selected = state.set.selected;
                if (payload?.metadata) {
                    if (selected.length > 0 && selected.at(0).internalId == payload?.metadata.id) {
                        selected.at(0).status = TreasuryStatus.CLOSED
                    }
                    var u = state.set.items.findIndex(e => e.internalId == payload?.metadata.id)
                    if (u >= 0) {
                        state.set.items.at(u).status = TreasuryStatus.CLOSED
                    }

                }
            }
        })
        builder.addCase(close.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(close.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

      
     

        builder.addCase(updateLimit.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response?.success?.message);
                let selected = state.set.selected;
                if (payload?.metadata) {
                    if (selected.length > 0 && selected.at(0).internalId == payload?.metadata.id) {
                        let r = selected.at(0);
                        r.limitationId = payload?.metadata.limitationId
                        //  r.ownerFirstname = val.owner?.firstname?.value ?? "";
                        // r.ownerLastname = val.owner?.lastname?.value ?? "";
                        //  r.ownerMiddlename = val.owner?.middlename?.value ?? "";
                        //  r.ownerId = val.owner?.id?.value ?? "";
                    }
                    var u = state.set.items.findIndex(e => e.internalId == payload?.metadata.id)
                    if (u >= 0) {
                        let r = selected.at(u);
                        r.limitationId = payload?.metadata.limitationId

                    }

                }
            }
        })
        builder.addCase(updateLimit.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateLimit.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })
    }
})

export const { reset, dismissMessage, setSort, setNumberOfResults, resetSet, setSelectedItems, setSearchText, setIsFilteredSet, addItem, updateItem, resetTreasuryTypes, resetCurrencies, resetLimitations, setIsFilteredTreasuryTypesSet, setCodeFilterOption, setNatureFilterOption,setSelectedTreasuryTypeItems , setIsFilteredCurrenciesSet, setIsFilteredLimitationsSet,  setSelectedCurrencyItems, setSelectedLimitationItems, setStatusFilterOption } = treasuriesPageSlice.actions

export default treasuriesPageSlice.reducer


