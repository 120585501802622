/**
 * @fileoverview gRPC-Web generated client stub for fee_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as fee_pb from './fee_pb';


export class FeeEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAdd = new grpcWeb.MethodDescriptor(
    '/fee_endpoint.FeeEndpoint/Add',
    grpcWeb.MethodType.UNARY,
    fee_pb.AddFeeRequest,
    fee_pb.AddFeeResponse,
    (request: fee_pb.AddFeeRequest) => {
      return request.serializeBinary();
    },
    fee_pb.AddFeeResponse.deserializeBinary
  );

  add(
    request: fee_pb.AddFeeRequest,
    metadata: grpcWeb.Metadata | null): Promise<fee_pb.AddFeeResponse>;

  add(
    request: fee_pb.AddFeeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: fee_pb.AddFeeResponse) => void): grpcWeb.ClientReadableStream<fee_pb.AddFeeResponse>;

  add(
    request: fee_pb.AddFeeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: fee_pb.AddFeeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/fee_endpoint.FeeEndpoint/Add',
        request,
        metadata || {},
        this.methodDescriptorAdd,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/fee_endpoint.FeeEndpoint/Add',
    request,
    metadata || {},
    this.methodDescriptorAdd);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/fee_endpoint.FeeEndpoint/Update',
    grpcWeb.MethodType.UNARY,
    fee_pb.UpdateFeeRequest,
    fee_pb.UpdateFeeResponse,
    (request: fee_pb.UpdateFeeRequest) => {
      return request.serializeBinary();
    },
    fee_pb.UpdateFeeResponse.deserializeBinary
  );

  update(
    request: fee_pb.UpdateFeeRequest,
    metadata: grpcWeb.Metadata | null): Promise<fee_pb.UpdateFeeResponse>;

  update(
    request: fee_pb.UpdateFeeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: fee_pb.UpdateFeeResponse) => void): grpcWeb.ClientReadableStream<fee_pb.UpdateFeeResponse>;

  update(
    request: fee_pb.UpdateFeeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: fee_pb.UpdateFeeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/fee_endpoint.FeeEndpoint/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/fee_endpoint.FeeEndpoint/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorUpdateActivity = new grpcWeb.MethodDescriptor(
    '/fee_endpoint.FeeEndpoint/UpdateActivity',
    grpcWeb.MethodType.UNARY,
    fee_pb.UpdateFeeActivityRequest,
    fee_pb.UpdateFeeActivityResponse,
    (request: fee_pb.UpdateFeeActivityRequest) => {
      return request.serializeBinary();
    },
    fee_pb.UpdateFeeActivityResponse.deserializeBinary
  );

  updateActivity(
    request: fee_pb.UpdateFeeActivityRequest,
    metadata: grpcWeb.Metadata | null): Promise<fee_pb.UpdateFeeActivityResponse>;

  updateActivity(
    request: fee_pb.UpdateFeeActivityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: fee_pb.UpdateFeeActivityResponse) => void): grpcWeb.ClientReadableStream<fee_pb.UpdateFeeActivityResponse>;

  updateActivity(
    request: fee_pb.UpdateFeeActivityRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: fee_pb.UpdateFeeActivityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/fee_endpoint.FeeEndpoint/UpdateActivity',
        request,
        metadata || {},
        this.methodDescriptorUpdateActivity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/fee_endpoint.FeeEndpoint/UpdateActivity',
    request,
    metadata || {},
    this.methodDescriptorUpdateActivity);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/fee_endpoint.FeeEndpoint/Delete',
    grpcWeb.MethodType.UNARY,
    fee_pb.DeleteFeeRequest,
    fee_pb.DeleteFeeResponse,
    (request: fee_pb.DeleteFeeRequest) => {
      return request.serializeBinary();
    },
    fee_pb.DeleteFeeResponse.deserializeBinary
  );

  delete(
    request: fee_pb.DeleteFeeRequest,
    metadata: grpcWeb.Metadata | null): Promise<fee_pb.DeleteFeeResponse>;

  delete(
    request: fee_pb.DeleteFeeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: fee_pb.DeleteFeeResponse) => void): grpcWeb.ClientReadableStream<fee_pb.DeleteFeeResponse>;

  delete(
    request: fee_pb.DeleteFeeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: fee_pb.DeleteFeeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/fee_endpoint.FeeEndpoint/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/fee_endpoint.FeeEndpoint/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorGetFee = new grpcWeb.MethodDescriptor(
    '/fee_endpoint.FeeEndpoint/GetFee',
    grpcWeb.MethodType.UNARY,
    fee_pb.GetFeeRequest,
    fee_pb.GetFeeResponse,
    (request: fee_pb.GetFeeRequest) => {
      return request.serializeBinary();
    },
    fee_pb.GetFeeResponse.deserializeBinary
  );

  getFee(
    request: fee_pb.GetFeeRequest,
    metadata: grpcWeb.Metadata | null): Promise<fee_pb.GetFeeResponse>;

  getFee(
    request: fee_pb.GetFeeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: fee_pb.GetFeeResponse) => void): grpcWeb.ClientReadableStream<fee_pb.GetFeeResponse>;

  getFee(
    request: fee_pb.GetFeeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: fee_pb.GetFeeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/fee_endpoint.FeeEndpoint/GetFee',
        request,
        metadata || {},
        this.methodDescriptorGetFee,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/fee_endpoint.FeeEndpoint/GetFee',
    request,
    metadata || {},
    this.methodDescriptorGetFee);
  }

  methodDescriptorGetFees = new grpcWeb.MethodDescriptor(
    '/fee_endpoint.FeeEndpoint/GetFees',
    grpcWeb.MethodType.UNARY,
    fee_pb.GetFeesRequest,
    fee_pb.GetFeesResponse,
    (request: fee_pb.GetFeesRequest) => {
      return request.serializeBinary();
    },
    fee_pb.GetFeesResponse.deserializeBinary
  );

  getFees(
    request: fee_pb.GetFeesRequest,
    metadata: grpcWeb.Metadata | null): Promise<fee_pb.GetFeesResponse>;

  getFees(
    request: fee_pb.GetFeesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: fee_pb.GetFeesResponse) => void): grpcWeb.ClientReadableStream<fee_pb.GetFeesResponse>;

  getFees(
    request: fee_pb.GetFeesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: fee_pb.GetFeesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/fee_endpoint.FeeEndpoint/GetFees',
        request,
        metadata || {},
        this.methodDescriptorGetFees,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/fee_endpoint.FeeEndpoint/GetFees',
    request,
    metadata || {},
    this.methodDescriptorGetFees);
  }

  methodDescriptorAddCollectionAccount = new grpcWeb.MethodDescriptor(
    '/fee_endpoint.FeeEndpoint/AddCollectionAccount',
    grpcWeb.MethodType.UNARY,
    fee_pb.AddFeeCollectionAccountRequest,
    fee_pb.AddFeeCollectionAccountResponse,
    (request: fee_pb.AddFeeCollectionAccountRequest) => {
      return request.serializeBinary();
    },
    fee_pb.AddFeeCollectionAccountResponse.deserializeBinary
  );

  addCollectionAccount(
    request: fee_pb.AddFeeCollectionAccountRequest,
    metadata: grpcWeb.Metadata | null): Promise<fee_pb.AddFeeCollectionAccountResponse>;

  addCollectionAccount(
    request: fee_pb.AddFeeCollectionAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: fee_pb.AddFeeCollectionAccountResponse) => void): grpcWeb.ClientReadableStream<fee_pb.AddFeeCollectionAccountResponse>;

  addCollectionAccount(
    request: fee_pb.AddFeeCollectionAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: fee_pb.AddFeeCollectionAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/fee_endpoint.FeeEndpoint/AddCollectionAccount',
        request,
        metadata || {},
        this.methodDescriptorAddCollectionAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/fee_endpoint.FeeEndpoint/AddCollectionAccount',
    request,
    metadata || {},
    this.methodDescriptorAddCollectionAccount);
  }

  methodDescriptorUpdateCollectionAccountActivity = new grpcWeb.MethodDescriptor(
    '/fee_endpoint.FeeEndpoint/UpdateCollectionAccountActivity',
    grpcWeb.MethodType.UNARY,
    fee_pb.UpdateFeeCollectionAccountActivityRequest,
    fee_pb.UpdateFeeCollectionAccountActivityResponse,
    (request: fee_pb.UpdateFeeCollectionAccountActivityRequest) => {
      return request.serializeBinary();
    },
    fee_pb.UpdateFeeCollectionAccountActivityResponse.deserializeBinary
  );

  updateCollectionAccountActivity(
    request: fee_pb.UpdateFeeCollectionAccountActivityRequest,
    metadata: grpcWeb.Metadata | null): Promise<fee_pb.UpdateFeeCollectionAccountActivityResponse>;

  updateCollectionAccountActivity(
    request: fee_pb.UpdateFeeCollectionAccountActivityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: fee_pb.UpdateFeeCollectionAccountActivityResponse) => void): grpcWeb.ClientReadableStream<fee_pb.UpdateFeeCollectionAccountActivityResponse>;

  updateCollectionAccountActivity(
    request: fee_pb.UpdateFeeCollectionAccountActivityRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: fee_pb.UpdateFeeCollectionAccountActivityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/fee_endpoint.FeeEndpoint/UpdateCollectionAccountActivity',
        request,
        metadata || {},
        this.methodDescriptorUpdateCollectionAccountActivity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/fee_endpoint.FeeEndpoint/UpdateCollectionAccountActivity',
    request,
    metadata || {},
    this.methodDescriptorUpdateCollectionAccountActivity);
  }

  methodDescriptorRemoveCollectionAccount = new grpcWeb.MethodDescriptor(
    '/fee_endpoint.FeeEndpoint/RemoveCollectionAccount',
    grpcWeb.MethodType.UNARY,
    fee_pb.RemoveFeeCollectionAccountRequest,
    fee_pb.RemoveFeeCollectionAccountResponse,
    (request: fee_pb.RemoveFeeCollectionAccountRequest) => {
      return request.serializeBinary();
    },
    fee_pb.RemoveFeeCollectionAccountResponse.deserializeBinary
  );

  removeCollectionAccount(
    request: fee_pb.RemoveFeeCollectionAccountRequest,
    metadata: grpcWeb.Metadata | null): Promise<fee_pb.RemoveFeeCollectionAccountResponse>;

  removeCollectionAccount(
    request: fee_pb.RemoveFeeCollectionAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: fee_pb.RemoveFeeCollectionAccountResponse) => void): grpcWeb.ClientReadableStream<fee_pb.RemoveFeeCollectionAccountResponse>;

  removeCollectionAccount(
    request: fee_pb.RemoveFeeCollectionAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: fee_pb.RemoveFeeCollectionAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/fee_endpoint.FeeEndpoint/RemoveCollectionAccount',
        request,
        metadata || {},
        this.methodDescriptorRemoveCollectionAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/fee_endpoint.FeeEndpoint/RemoveCollectionAccount',
    request,
    metadata || {},
    this.methodDescriptorRemoveCollectionAccount);
  }

}

