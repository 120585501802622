import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError } from 'grpc-web';
import { RoleEndpointClient } from '../../../../repository/UserManagement/Managers/RoleServiceClientPb';
import { AddRoleRequest, AddRoleResponse, UpdateRoleRequest, UpdateRoleResponse } from '../../../../repository/UserManagement/Managers/role_pb';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, refreshSession, getSession, ACCOUNTANT_API_URL } from '../../../../app/Api';
import { AccountTypeEndpointClient } from '../../../../repository/Accountant/Account_typeServiceClientPb';
import { formatDate, timestampToDate } from '../../../../app/Helpers';
import { DynamicField } from '../../../common/DynamicFields/DynamicFields';
import { TreasuryOperationEndpointClient } from '../../../../repository/Accountant/Treasury_operationServiceClientPb';
import { GetTransactionRequest, GetTransactionResponse, UpdateTransactionRequest, UpdateTransactionResponse } from '../../../../repository/Accountant/account_operation_pb';
import { AccountOperationEndpointClient } from '../../../../repository/Accountant/Account_operationServiceClientPb';

export interface TransactionsFormState {
    isLoading: boolean
    message?: ApiMessage
    stage1: any,
}

const initialState: TransactionsFormState = {
    isLoading: false,
    message: undefined,
    stage1: undefined,
}

const client = new AccountOperationEndpointClient(ACCOUNTANT_API_URL, null, null);


export const update = createAsyncThunk<UpdateTransactionResponse.AsObject, APIRequest<UpdateTransactionRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/accountTransactions/update',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.update(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)



export const get = createAsyncThunk<GetTransactionResponse.AsObject, APIRequest<GetTransactionRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/accountTransactions/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getTransaction(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)


export const accountTransactionsFormSlice = createSlice({
    name: 'forms/accountant/accountTransactions',
    initialState,
    reducers: {

        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.isLoading = false;
            state.message = undefined;
            state.stage1 = initialState.stage1;
        },
        setStage1: (state, action: PayloadAction<any>) => {
            let stage1 = {
                extraFields: action.payload?.extraFields,
                internalId: action.payload?.internalid,

            }
            state.stage1 = stage1;
        },
    },
    extraReducers: (builder) => {
      
        builder.addCase(update.fulfilled, (state, { payload }) => {
            if (payload)
                state.message = toApiMessage(payload.success?.message);
            state.isLoading = false;
        })
        builder.addCase(update.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(update.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })

        builder.addCase(get.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {

                let r = {
                    internalId: payload.success?.internalid,
                    extraFields: [] as DynamicField[]
                }
                if (payload?.success?.extrafieldsList) {
                    let count = 0
                    payload?.success?.extrafieldsList?.forEach(element => {
                        r.extraFields.push({ key: element.key, label: element.label, type: element.type, value: element.value })
                        count++
                    });
                }
           
                /*if (payload?.success?.collectionaccountsList) {
                    let count = 0
                    payload?.success?.collectionaccountsList?.forEach(element => {
                        r.extraFields.push({ key: element.account?.id, label: element.label, type: element.type, value: element.value })
                        count++
                    });
                }*/

                state.stage1 = r
            }
        })
        builder.addCase(get.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(get.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })
    }
})

export const { reset, dismissMessage, setStage1 } = accountTransactionsFormSlice.actions

export default accountTransactionsFormSlice.reducer


