import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ProgressIndicator, Pivot, PivotItem, Separator, IPersonaProps, NormalPeoplePicker, IPickerItemProps, ValidationState, PeoplePickerItem } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../../app/Hooks"
import { getPage } from "../../../../../app/Pages"
import { setPage } from "../../../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../../../common/Table/Table"
import { ApiMessage, getHeaders } from "../../../../../app/Api"
import { useId, useBoolean } from '@fluentui/react-hooks';

import { Message } from "../../../../common/Message/Message"
import { ShareBox } from "../../../../common/ShareBox/ShareBox"
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { dismissMessage, get, reset, resetItem, updateItem, updateLimit, updateStatus, resetLimitations, setSelectedLimitationItems, getLimitations, setIsFilteredLimitationsSet, close, setSelectedTreasuryTypeItems, getTreasuryTypes, resetTreasuryTypes, setIsFilteredTreasuryTypesSet, updateType, } from "./TreasuryDetailsPageSlice"
import { Popup } from "../../../../common/Popup/Popup"
import { FormType } from "../../../../forms/FormProps"
import { DynamicField } from "../../../../common/DynamicFields/DynamicFields"
import { TextView } from "../../../../common/DataView/TextView"
import { ProfileView } from "../../../../common/DataView/ProfileView"
import { TreasuryStatus, LimitationType, Natures } from "../../../../../app/Enums"
import { TreasuriesForm } from "../../../../forms/Accountant/Treasuries/TreasuriesForm"
import { TableState } from "../../../../common/Table/TableSate"
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { GetLimitationsRequest } from "../../../../../repository/Accountant/limitation_pb"
import { LinkView } from "../../../../common/DataView/LinkView"
import { currencyFormatter, printComponent, normalizeKey } from "../../../../../app/Helpers"
import { CloseTreasuryRequest, GetTreasuryRequest, UpdateTreasuryLimitationRequest, UpdateTreasuryStatusRequest, UpdateTreasuryTypeRequest } from "../../../../../repository/Accountant/treasury_pb"
import { TreasuryDepositsPage } from "./TreasuryDepositsPage/TreasuryDepositsPage"
import { TreasuryStatementPage } from "./TreasuryStatementPage/TreasuryStatementPage"
import { GetTreasuryTypesRequest } from "../../../../../repository/Accountant/treasury_type_pb"
import { useReactToPrint } from "react-to-print"
import { List } from "../../../../common/List/List"
import { TreasuryTypesPage } from "../../TreasuryTypes/TreasuryTypesPage"
import { LimitationsPage } from "../../Limitations/LimitationsPage"

const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};


let req: GetTreasuryRequest;
let limitationReq: GetLimitationsRequest;
let treasuryTypesReq: GetTreasuryTypesRequest;

let org: number = -1;

let getPromise: any;
let getBalancePromise: any;
let actionPromise: any;
let getFilterPromise: any;


const key = "treasuriesManagement/treasuries/details"
export const TreasuryDetailsPage: React.FunctionComponent = () => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    let { id } = useParams();
    const { hash } = useLocation();
    const [selectedKey, setSelectedKey] = useState("0");

    const state: {
        isChangeStateLoading: boolean,
        message: ApiMessage | undefined,
        appTheme: string, isDarkModeEnabled: boolean,
        isLoading: boolean,
        item: any,
        limitations: TableState,
        treasuryTypes: TableState
    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.treasuryDetailsPage.isChangeStateLoading,
            message: state.treasuryDetailsPage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            isLoading: state.treasuryDetailsPage.isLoading,
            item: state.treasuryDetailsPage.item,
            limitations: state.treasuryDetailsPage.limitations,
            treasuryTypes: state.treasuryDetailsPage.treasuryTypes,


        }
    })

    const calloutTargetId = useId('callout-target');
    const labelId = useId('callout-label');
    const closeTreasuryActionConfirmationcalloutTargetId = useId('close-treasury-action-callout-target');
    const activityActionConfirmationcalloutTargetId = useId('activity-action-callout-target');
    const updateLimitationActionConfirmationcalloutTargetId = useId('update-limitation-action-callout-target');
    const transferOwnershipActionConfirmationcalloutTargetId = useId('transfer-ownership-action-callout-target');
    const ActionConfirmationLabelId = useId('action-callout-label');
    const shareCalloutLabelId = useId('share-callout-label');
    const shareTargetId = useId('share-callout-target');
    const updateTypeActionConfirmationcalloutTargetId = useId('update-type-action-callout-target');


    const updateLimtCalloutLabelId = useId('limit-callout-label');
    const transfareOwnerShipCalloutLabelId = useId('transfareOwnerShip-callout-label');
    const updateTypeCalloutLabelId = useId('type-callout-label');


    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const [isActionConfirmationCalloutVisible, { toggle: toggleisActionConfirmationCalloutVisible }] = useBoolean(false);
    const [isShareCalloutVisible, { toggle: toggleisShareCalloutVisible }] = useBoolean(false);
    const [isupdateLimitationConfirmationCalloutVisible, { toggle: toggleisUpdateLimitationConfirmationCalloutVisible }] = useBoolean(false);
    const [isTransferOwnershipConfirmationCalloutVisible, { toggle: toggleisTransferOwnershipConfirmationCalloutVisible }] = useBoolean(false);
    const [isUpdateTypeConfirmationCalloutVisible, { toggle: toggleisUpdateTypeConfirmationCalloutVisible }] = useBoolean(false);
    const updateConfirmationcalloutTargetId = useId('update-action-callout-target');

    const ref = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => {
            return printComponent(ref.current?.cloneNode(true))
        }

    });

    const [currentAction, setCurrenctAction] = useState(0)

    const getActionId = (action: number): string => {
        if (action == 1) {
            return closeTreasuryActionConfirmationcalloutTargetId;
        } else if (action == 2) {
            return activityActionConfirmationcalloutTargetId;
        } else if (action == 4) {
            return updateLimitationActionConfirmationcalloutTargetId;
        } else if (action == 3) {
            return transferOwnershipActionConfirmationcalloutTargetId;
        } else if (action == 11) {
            return updateTypeActionConfirmationcalloutTargetId;
        } else {
            return ""
        }
    }


    useEffect(() => {
        req = new GetTreasuryRequest();
        limitationReq = new GetLimitationsRequest();
        treasuryTypesReq = new GetTreasuryTypesRequest();

        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                limitationReq.setOrganization(wrapper)
                treasuryTypesReq.setOrganization(wrapper)

            }
        }
        const page = getPage(key)
        dispatch(setTitle(t(normalizeKey(page?.title))));
        dispatch(setPage(page));

        const boolv = new BoolValue();
        boolv.setValue(true);

        treasuryTypesReq.setNumofresults(state.treasuryTypes.numberOfResults)
        treasuryTypesReq.setOrder(state.treasuryTypes.isDescending)
        treasuryTypesReq.setIsavailable(boolv)

        limitationReq.setNumofresults(state.limitations.numberOfResults)
        limitationReq.setOrder(state.limitations.isDescending)
        const type = new Int32Value();
        type.setValue(LimitationType.TREASURY_LIMITATION);
        limitationReq.setType(type)
        limitationReq.setIsavailable(boolv)

        if (id) {
            req.setId(id)
            getPromise = dispatch(get({ body: req, headers: getHeaders() }))
            getPromise.unwrap().then((e: any) => {
                if (hash == "#deposits") {
                    setSelectedKey("1");
                } else if (hash == "#statement") {
                    setSelectedKey("2");
                } else {
                    setSelectedKey("0");
                }
            });
        } else {
            navigate(-1)
        }


        return () => { //clean up
            getPromise?.abort();
            actionPromise?.abort();
            getFilterPromise?.abort();
            getBalancePromise?.abort();
            dispatch(reset());
        }
    }, [])



    const _selectionItems: ICommandBarItemProps[] = [
        {
            key: 'back',
            text: t('back'),
            title: t("back"),
            iconProps: { iconName: 'back' },
            disabled: state.isLoading,
            onClick: () => {
                navigate(-1)
            }
        },
        {
            key: 'refresh',
            text: t('refresh'),
            title: t('refresh'),
            iconProps: { iconName: 'refresh' },
            disabled: state.isLoading,
            onClick: () => {
                dispatch(resetItem())
                getPromise = dispatch(get({ body: req, headers: getHeaders() }))

            }
        },
        {
            key: 'edit',
            text: t("edit"),
            iconProps: { id: updateConfirmationcalloutTargetId, iconName: 'EditSolid12' },
            disabled: state.item?.status == TreasuryStatus.CLOSED,
            title: t("edit"),
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && (currentAction == 4 || currentAction == 11) ? undefined : e.iconProps} text={state.isChangeStateLoading && (currentAction == 4 || currentAction == 11) ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && (currentAction == 4 || currentAction == 11) ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            subMenuProps: {
                items: [
                    {
                        key: 'editInfo',
                        text: t('editInfo'),
                        title: t("editInfo"),
                        iconProps: { iconName: 'EditContact', styles: { root: { fontWeight: "bold" } } },
                        disabled: state.item?.status == TreasuryStatus.CLOSED,
                        onClick: () => {
                            if (!state.isChangeStateLoading) {
                                setCurrenctAction(101)
                            }

                        }
                    },
                    {
                        key: 'updateLimitation',
                        text: t("updateLimitation"),
                        iconProps: { iconName: "MaximumValue" },
                        disabled: state.item?.status == TreasuryStatus.CLOSED,
                        title: t("updateLimitation"),

                        onClick: () => {
                            if (!state.isChangeStateLoading) {
                                setCurrenctAction(4)
                                toggleisUpdateLimitationConfirmationCalloutVisible()

                            }
                        },
                    },


                    {
                        key: 'updateType',
                        text: t("updateType"),
                        iconProps: { iconName: "ChangeEntitlements" },
                        disabled: state.item?.status == TreasuryStatus.CLOSED,
                        title: t("updateType"),
                        onClick: () => {
                            if (!state.isChangeStateLoading) {
                                setCurrenctAction(11)
                                toggleisUpdateTypeConfirmationCalloutVisible()

                            }
                        },
                    },
                ]
            },
        },



        {
            key: 'status',
            text: (state.item?.status == TreasuryStatus.ACTIVE) ? t("deactivate") : t("activate"),
            iconProps: { id: activityActionConfirmationcalloutTargetId, iconName: (state.item?.status == TreasuryStatus.ACTIVE) ? "Cancel" : "Accept", styles: { root: { fontWeight: (state.item?.status == TreasuryStatus.ACTIVE) ? undefined : "bold" } } },
            disabled: state.item?.status == TreasuryStatus.CLOSED,
            title: (state.item?.status == TreasuryStatus.ACTIVE) ? t("deactivate") : t("activate"),
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 2 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 2 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 2 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(2)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },
        {
            key: 'close',
            text: t("closeTreasury"),
            iconProps: { id: closeTreasuryActionConfirmationcalloutTargetId, iconName: "Blocked", styles: { root: { fontWeight: "bold" } } },
            disabled: state.item?.status == TreasuryStatus.CLOSED,
            title: t("closeTreasury"),
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 1 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 1 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 1 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(1)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },

        {
            key: 'logs',
            text: t("logs"),
            iconProps: { iconName: "PageList", styles: { root: { fontWeight: "bold" } } },
            title: t("logs"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/logs?resourceId=" + state.item?.internalId + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },
        {
            key: 'share',
            text: t("share"),
            title: t("share"),
            iconProps: { id: shareTargetId, iconName: 'Share', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                toggleisShareCalloutVisible()
            },

        },
        {
            key: 'print',
            text: t("print"),
            iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
            title: t("print"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    handlePrint();
                }
            }
        },

    ];


    const treasuryTypesSuggestionsProps: ISuggestionsProps<ITag> = {
        suggestionsHeaderText: t('treasuryTypes'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.treasuryTypes.hasMore && !state.treasuryTypes.isFetching,
        onRenderSuggestion: (props: ITag, suggestionItemProps: ISuggestionItemProps<ITag>) => {
            return <TagItemSuggestion>
                {props?.name ?? props?.key}
            </TagItemSuggestion>
        },
        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.treasuryTypes.items.findIndex(e => e.id == item.key)
            if (u >= 0) {
                dispatch(setSelectedTreasuryTypeItems([state.treasuryTypes.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.treasuryTypes.items.length > 0 && !state.treasuryTypes.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.treasuryTypes.items.at(state.treasuryTypes.items.length - 1).id);
                treasuryTypesReq.setNextto(wrapper)
                getFilterPromise = dispatch(getTreasuryTypes({ body: treasuryTypesReq, headers: getHeaders() }))
            }
        },
        suggestions: state.treasuryTypes.items.length > 0 ? state.treasuryTypes.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : [],
        isLoading: state.treasuryTypes.items.length == 0 ? state.treasuryTypes.isFetching : false,
        isSearching: state.treasuryTypes.items.length > 0 ? state.treasuryTypes.isFetching : false,


    };
    const onTreasuryTypesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetTreasuryTypes())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        treasuryTypesReq.setSearch(wrapper)
        treasuryTypesReq.setNextto(undefined)
        dispatch(setIsFilteredTreasuryTypesSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getTreasuryTypes({ body: treasuryTypesReq, headers: getHeaders() }))
        return [];

    }


    const limitationsSuggestionsProps: ISuggestionsProps<ITag> = {
        suggestionsHeaderText: t('limitations'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.limitations.hasMore && !state.limitations.isFetching,
        onRenderSuggestion: (props: ITag, suggestionItemProps: ISuggestionItemProps<ITag>) => {
            return <TagItemSuggestion>
                {props?.name ?? props?.key}
            </TagItemSuggestion>
        },
        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.limitations.items.findIndex(e => e.id == item.key)
            if (u >= 0) {
                dispatch(setSelectedLimitationItems([state.limitations.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.limitations.items.length > 0 && !state.limitations.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.limitations.items.at(state.limitations.items.length - 1).id);
                limitationReq.setNextto(wrapper)
                getFilterPromise = dispatch(getLimitations({ body: limitationReq, headers: getHeaders() }))
            }
        },
        suggestions: state.limitations.items.length > 0 ? state.limitations.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : [],
        isLoading: state.limitations.items.length == 0 ? state.limitations.isFetching : false,
        isSearching: state.limitations.items.length > 0 ? state.limitations.isFetching : false,


    };
    const onLimitationsFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetLimitations())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        limitationReq.setSearch(wrapper)
        limitationReq.setNextto(undefined)
        dispatch(setIsFilteredLimitationsSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getLimitations({ body: limitationReq, headers: getHeaders() }))
        return [];

    }

    return (
        <Stack >
            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }

            <Popup isOpen={(currentAction == 101)} title={t("editTreasury")} onDismiss={() => { setCurrenctAction(0) }} >
                <TreasuriesForm childrenGap={10} maxWidth={280} type={FormType.EDIT} renderObject={state.item} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(updateItem(e))
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>
            <Popup isOpen={(currentAction == 1000)} title={t("treasuryTypes")} onDismiss={() => { setCurrenctAction(0) }} >

                <TreasuryTypesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedTreasuryTypeItems([e]))
                    setCurrenctAction(11)
                    toggleisUpdateTypeConfirmationCalloutVisible();
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Popup isOpen={(currentAction == 1001)} title={t("limitations")} onDismiss={() => { setCurrenctAction(0) }} >

                <LimitationsPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedLimitationItems([e]))
                    setCurrenctAction(4)
                    toggleisUpdateLimitationConfirmationCalloutVisible();
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            {isUpdateTypeConfirmationCalloutVisible ? (
                <Callout
                    ariaLabelledBy={updateTypeCalloutLabelId}
                    target={`#${updateConfirmationcalloutTargetId}`}
                    onDismiss={() => { dispatch(setSelectedTreasuryTypeItems([])); toggleisUpdateTypeConfirmationCalloutVisible() }}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                        <Stack tokens={{ padding: '20px 24px ' }} >
                            <Text>{t("treasurytypeUpdatehint")}</Text>
                            <Label required>{t("treasuryType")}</Label>
                            <List
                                inputProps={{ placeholder: t("treasuryType"), required: true }}
                                suggestionsHeaderText={t('treasuryTypes')}
                                isLoading={state.treasuryTypes.items.length == 0 ? state.treasuryTypes.isFetching : false}
                                isSearching={state.treasuryTypes.items.length > 0 ? state.treasuryTypes.isFetching : false}
                                moreSuggestionsAvailable={state.treasuryTypes.hasMore && !state.treasuryTypes.isFetching}
                                suggestions={state.treasuryTypes.items.length > 0 ? state.treasuryTypes.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : []}
                                onGetMoreResults={() => {
                                    if (state.treasuryTypes.items.length > 0 && !state.treasuryTypes.isFetching) {
                                        const wrapper = new StringValue();
                                        wrapper.setValue(state.treasuryTypes.items.at(state.treasuryTypes.items.length - 1).id);
                                        treasuryTypesReq.setNextto(wrapper)
                                        getFilterPromise = dispatch(getTreasuryTypes({ body: treasuryTypesReq, headers: getHeaders() }))
                                    }
                                }}
                                onSuggestionClick={(ev?, item?: any, index?: number) => {
                                    var u = state.treasuryTypes.items.findIndex(e => e.id == item.key)
                                    if (u >= 0) {
                                        dispatch(setSelectedTreasuryTypeItems([state.treasuryTypes.items.at(u)]))
                                    }

                                }}
                                isPeoplePicker={false}
                                selectedItems={state.treasuryTypes.selected.length > 0 ? state.treasuryTypes.selected.map(e => { return { name: e.name, key: e.id } as ITag }) : []}
                                onChange={(e) => {
                                    dispatch(setSelectedTreasuryTypeItems([]))

                                }}
                                onEmptyInputFocus={() => {
                                    dispatch(resetTreasuryTypes())
                                    treasuryTypesReq.setSearch(undefined)
                                    treasuryTypesReq.setNextto(undefined)
                                    dispatch(setIsFilteredTreasuryTypesSet(false))
                                    getFilterPromise?.abort()
                                    getFilterPromise = dispatch(getTreasuryTypes({ body: treasuryTypesReq, headers: getHeaders() }))
                                    return []
                                }}
                                onFilterChanged={onTreasuryTypesFilterChanged}
                                endButtons={[
                                    {
                                        title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                        onClick: () => {
                                            setCurrenctAction(1000)
                                            toggleisUpdateTypeConfirmationCalloutVisible();
                                        }
                                    }]}
                            />


                            {/* This FocusZone allows the user to go between buttons with the arrow keys.
              It's not related to or required for FocusTrapCallout's built-in focus trapping. */}
                            <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                                <Stack reversed tokens={{ childrenGap: 8, padding: "20px 0px 0px 0px" }} horizontal>
                                    <DefaultButton autoFocus onClick={() => { dispatch(setSelectedTreasuryTypeItems([])); toggleisUpdateTypeConfirmationCalloutVisible() }}>{t("cancel")}</DefaultButton>

                                    <PrimaryButton onClick={() => {
                                        toggleisUpdateTypeConfirmationCalloutVisible()
                                        if (state.item && state.treasuryTypes?.selected?.at(0)?.id) {
                                            if (currentAction == 11) {
                                                let req = new UpdateTreasuryTypeRequest();
                                                req.setId(state.item?.internalId)
                                                req.setTreasurytype(state.treasuryTypes?.selected?.at(0)?.id)
                                                actionPromise?.abort();
                                                actionPromise = dispatch(updateType({ body: req, headers: getHeaders() }))
                                            }
                                        }

                                    }} text={t("update")}  >

                                    </PrimaryButton>
                                </Stack>
                            </FocusZone>
                        </Stack>
                

                </Callout>
            ) : null}

            {isupdateLimitationConfirmationCalloutVisible ? (
                <Callout
                    ariaLabelledBy={updateLimtCalloutLabelId}
                    target={`#${updateConfirmationcalloutTargetId}`}
                    onDismiss={() => { dispatch(setSelectedLimitationItems([])); toggleisUpdateLimitationConfirmationCalloutVisible() }}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <Stack tokens={{ padding: '20px 24px ' }}>
                        <Text>{t("treasuryLimitationUpdatehint")}</Text>
                        <Label required>{t("limitation")}</Label>
                        <List
                            inputProps={{ placeholder: t("limitation"), required: true }}
                            disabled={state.isLoading}
                            suggestionsHeaderText={t('limitations')}
                            isLoading={state.limitations.items.length == 0 ? state.limitations.isFetching : false}
                            isSearching={state.limitations.items.length > 0 ? state.limitations.isFetching : false}
                            moreSuggestionsAvailable={state.limitations.hasMore && !state.limitations.isFetching}
                            suggestions={state.limitations.items.length > 0 ? state.limitations.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : []}
                            onGetMoreResults={() => {
                                if (state.limitations.items.length > 0 && !state.limitations.isFetching) {
                                    const wrapper = new StringValue();
                                    wrapper.setValue(state.limitations.items.at(state.limitations.items.length - 1).id);
                                    limitationReq.setNextto(wrapper)
                                    getFilterPromise = dispatch(getLimitations({ body: limitationReq, headers: getHeaders() }))
                                }
                            }}
                            onSuggestionClick={(ev?, item?: any, index?: number) => {
                                var u = state.limitations.items.findIndex(e => e.id == item.key)
                                if (u >= 0) {
                                    dispatch(setSelectedLimitationItems([state.limitations.items.at(u)]))
                                }
                            }}
                            isPeoplePicker={false}
                            selectedItems={state.limitations.selected.length > 0 ? state.limitations.selected.map(e => { return { name: e.name, key: e.id } as ITag }) : []}
                            onChange={(e) => {
                                dispatch(setSelectedLimitationItems([]))

                            }}
                            onEmptyInputFocus={() => {
                                dispatch(resetLimitations())
                                limitationReq.setSearch(undefined)
                                limitationReq.setNextto(undefined)
                                dispatch(setIsFilteredLimitationsSet(false))
                                getFilterPromise?.abort()
                                getFilterPromise = dispatch(getLimitations({ body: limitationReq, headers: getHeaders() }))
                                return []
                            }}
                            onFilterChanged={onLimitationsFilterChanged}
                            endButtons={[
                                {
                                    title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                    onClick: () => {
                                        setCurrenctAction(1001)

                                    }
                                }]}
                        />

                        {/* This FocusZone allows the user to go between buttons with the arrow keys.
              It's not related to or required for FocusTrapCallout's built-in focus trapping. */}
                        <FocusZone   handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                            <Stack reversed tokens={{ childrenGap: 8, padding: "20px 0px 0px 0px" }} horizontal>
                                <DefaultButton onClick={() => { dispatch(setSelectedLimitationItems([])); toggleisUpdateLimitationConfirmationCalloutVisible() }}>{t("cancel")}</DefaultButton>

                                <PrimaryButton autoFocus={true} onClick={() => {
                                    toggleisUpdateLimitationConfirmationCalloutVisible()
                                    if (state.item && state.limitations?.selected?.at(0)?.id) {
                                        if (currentAction == 4) {
                                            let req = new UpdateTreasuryLimitationRequest();
                                            req.setId(state.item?.internalId)
                                            req.setLimitation(state.limitations?.selected?.at(0)?.id)
                                            actionPromise?.abort();
                                            actionPromise = dispatch(updateLimit({ body: req, headers: getHeaders() }))
                                        }
                                    }

                                }} text={t("update")}  >

                                </PrimaryButton>
                            </Stack>
                        </FocusZone>
                    </Stack>


                </Callout>
            ) : null}


            {isShareCalloutVisible ? (
                <Callout
                    ariaLabelledBy={shareCalloutLabelId}
                    target={`#${shareTargetId}`}
                    onDismiss={toggleisShareCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <ShareBox onDismiss={toggleisShareCalloutVisible} title={t("shareTreasury")} value={`https://${window.location.host}/treasuries/${state.item?.internalId}` + ((org == -1) ? "" : "?org=" + org)}></ShareBox>
                </Callout>
            ) : null}


            {isActionConfirmationCalloutVisible ? (
                <Callout
                    ariaLabelledBy={ActionConfirmationLabelId}
                    target={`#${getActionId(currentAction)}`}
                    onDismiss={toggleisActionConfirmationCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <Stack tokens={{ padding: '20px 24px ' }}>
                        <Text block variant="xLarge" styles={{ root: { marginBottom: 12, fontWeight: FontWeights.semilight } }}>
                            {(currentAction == 1 ? t("closeTreasury") : currentAction == 2 ? (state.item?.status == 1) ? t("deactivate") : t("activate") : "")}
                        </Text>
                        <Text block variant="small">
                            {(currentAction == 1 ? t("closeTreasuryDesc") : currentAction == 2 ? (state.item?.status == 1) ? t("deactivateTreasuryDesc") : t("activateTreasuryDesc") : "")}
                        </Text>
                        {/* This FocusZone allows the user to go between buttons with the arrow keys.
              It's not related to or required for FocusTrapCallout's built-in focus trapping. */}
                        <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                            <Stack reversed tokens={{ childrenGap: 8, padding: "20px 0px 0px 0px" }} horizontal>
                                <DefaultButton  autoFocus onClick={toggleisActionConfirmationCalloutVisible}>{t("cancel")}</DefaultButton>

                                <PrimaryButton onClick={() => {
                                    toggleisActionConfirmationCalloutVisible()
                                    if (state.item) {
                                        if (currentAction == 1) {
                                            let req = new CloseTreasuryRequest();
                                            req.setId(state.item?.internalId)
                                            actionPromise?.abort();
                                            actionPromise = dispatch(close({ body: req, headers: getHeaders() }))

                                        } else if (currentAction == 2) {
                                            let req = new UpdateTreasuryStatusRequest();
                                            req.setId(state.item?.internalId)
                                            req.setStatus(state.item?.status == 1 ? 2 : 1)
                                            actionPromise?.abort();
                                            actionPromise = dispatch(updateStatus({ body: req, headers: getHeaders() }))


                                        }
                                    }
                                }} text={(currentAction == 1 ? t("close") : currentAction == 2 ? (state.item?.status == 1) ? t("deactivate") : t("activate") : "")}  >

                                </PrimaryButton>
                            </Stack>
                        </FocusZone>
                    </Stack>
                </Callout>
            ) : null}

            {state.isLoading ? <ProgressIndicator /> :

                !state.item ? null :
                    <Pivot selectedKey={String(selectedKey)} onLinkClick={(i) => {
                        if (i?.props.itemKey == "2")
                            // navigate("#rules")
                            window.history.replaceState(null, "", "#statement")
                        if (i?.props.itemKey == "1")
                            // navigate("#rules")
                            window.history.replaceState(null, "", "#deposits")

                        if (i?.props.itemKey == "0")
                            window.history.replaceState(null, "", "#info")
                        //navigate("#info")

                        setSelectedKey(i?.props?.itemKey ?? "0")
                    }} >
                        <PivotItem headerText={t("info")} itemKey="0"   >
                            <div ref={ref}>
                                <CommandBar
                                    items={_selectionItems}
                                />
                                <Stack tokens={{ childrenGap: 10, padding: "0px 33% 10px 3%" }}>

                                    <Stack horizontal tokens={{ childrenGap: 10 }}>

                                        <TextView label={t("id")} value={state.item?.internalId} />


                                        <TextView label={t("createdDate")} value={state.item?.createdDate} />

                                        {state.item?.addedBy ? <ProfileView label={t("addedBy")} value={state.item?.addedBy == "" ? state.item?.addedById : state.item?.addedBy}
                                            url={"/management/users/" + state.item?.addedById + ((org == -1) ? "" : "?org=" + org)}
                                            text={state.item?.addedBy == "" ? state.item?.addedById : state.item?.addedBy}
                                            size={PersonaSize.size40} />

                                            : undefined}

                                    </Stack>
                                    <TextView label={t("treasuryNumber")} value={state.item?.id} />

                                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                                        <TextView label={t("treasuryName")} value={state.item?.name} />

                                        <TextView label={t("treasuryCode")} value={state.item?.code} />

                                        <TextView label={t("status")} iconName={state.item?.status != undefined ? state.item?.status == TreasuryStatus.ACTIVE ? "Accept" :
                                            state.item?.status == TreasuryStatus.INACTIVE ? "Cancel" : state.item?.status == TreasuryStatus.CLOSED ? "Blocked" : undefined : undefined}
                                            value={state.item?.status != undefined ? state.item?.status == TreasuryStatus.ACTIVE ? t("active") :
                                                state.item?.status == TreasuryStatus.INACTIVE ? t("inactive") : state.item?.status == TreasuryStatus.CLOSED ? t("closed") : "" : ""}
                                        />
                                    </Stack>

                                    <TextView label={t("nature")}
                                        value={state.item?.nature != undefined ? state.item?.nature == Natures.PHYSICAL ? t("physical") :
                                            state.item?.nature == Natures.VIRTUAL ? t("virtual") : "" : ""}
                                    />

                                    <LinkView label={t("treasuryType")} value={state.item?.treasuryTypeName == "" ? state.item?.treasuryTypeId : state.item?.treasuryTypeName}
                                        url={"/treasury-types/" + state.item?.treasuryTypeId + ((org == -1) ? "" : "?org=" + org)} />



                                    <LinkView label={t("limitation")} value={state.item?.limitationName == "" ? state.item?.limitationId : state.item?.limitationName}
                                        url={"/limitations/" + state.item?.limitationId + ((org == -1) ? "" : "?org=" + org)} />


                                    <Separator alignContent="start"><b>{t("otherInfo")}</b></Separator>

                                    {state.item?.extraFields.map((e: DynamicField, index: number) => {
                                        return <Stack key={e.key}>
                                            <TextView label={e.label ?? ""} value={e?.value} />

                                        </Stack>
                                    })}
                                </Stack>
                            </div>
                        </PivotItem>
                        <PivotItem headerText={t("deposits")} itemKey="1"  >
                            <TreasuryDepositsPage treasury={state.item} />
                        </PivotItem>
                        <PivotItem headerText={t("statement")} itemKey="2"  >
                            <TreasuryStatementPage treasury={state.item} />
                        </PivotItem>
                    </Pivot>


            }


        </Stack>



    );
}




