// source: currency.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('./common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.currency_endpoint.AddCurrencyReplay', null, global);
goog.exportSymbol('proto.currency_endpoint.AddCurrencyRequest', null, global);
goog.exportSymbol('proto.currency_endpoint.AddCurrencyResponse', null, global);
goog.exportSymbol('proto.currency_endpoint.AddCurrencyResponse.ResultCase', null, global);
goog.exportSymbol('proto.currency_endpoint.Currency', null, global);
goog.exportSymbol('proto.currency_endpoint.CurrencyForm', null, global);
goog.exportSymbol('proto.currency_endpoint.CurrencyFormResponse', null, global);
goog.exportSymbol('proto.currency_endpoint.DeleteCurrencyReplay', null, global);
goog.exportSymbol('proto.currency_endpoint.DeleteCurrencyRequest', null, global);
goog.exportSymbol('proto.currency_endpoint.DeleteCurrencyResponse', null, global);
goog.exportSymbol('proto.currency_endpoint.DeleteCurrencyResponse.ResultCase', null, global);
goog.exportSymbol('proto.currency_endpoint.GetCurrenciesReplay', null, global);
goog.exportSymbol('proto.currency_endpoint.GetCurrenciesRequest', null, global);
goog.exportSymbol('proto.currency_endpoint.GetCurrenciesResponse', null, global);
goog.exportSymbol('proto.currency_endpoint.GetCurrenciesResponse.ResultCase', null, global);
goog.exportSymbol('proto.currency_endpoint.GetCurrencyReplay', null, global);
goog.exportSymbol('proto.currency_endpoint.GetCurrencyRequest', null, global);
goog.exportSymbol('proto.currency_endpoint.GetCurrencyResponse', null, global);
goog.exportSymbol('proto.currency_endpoint.GetCurrencyResponse.ResultCase', null, global);
goog.exportSymbol('proto.currency_endpoint.UpdateCurrencyReplay', null, global);
goog.exportSymbol('proto.currency_endpoint.UpdateCurrencyRequest', null, global);
goog.exportSymbol('proto.currency_endpoint.UpdateCurrencyResponse', null, global);
goog.exportSymbol('proto.currency_endpoint.UpdateCurrencyResponse.ResultCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_endpoint.CurrencyForm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_endpoint.CurrencyForm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_endpoint.CurrencyForm.displayName = 'proto.currency_endpoint.CurrencyForm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_endpoint.CurrencyFormResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_endpoint.CurrencyFormResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_endpoint.CurrencyFormResponse.displayName = 'proto.currency_endpoint.CurrencyFormResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_endpoint.AddCurrencyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.currency_endpoint.AddCurrencyRequest.repeatedFields_, null);
};
goog.inherits(proto.currency_endpoint.AddCurrencyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_endpoint.AddCurrencyRequest.displayName = 'proto.currency_endpoint.AddCurrencyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_endpoint.UpdateCurrencyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.currency_endpoint.UpdateCurrencyRequest.repeatedFields_, null);
};
goog.inherits(proto.currency_endpoint.UpdateCurrencyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_endpoint.UpdateCurrencyRequest.displayName = 'proto.currency_endpoint.UpdateCurrencyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_endpoint.DeleteCurrencyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_endpoint.DeleteCurrencyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_endpoint.DeleteCurrencyRequest.displayName = 'proto.currency_endpoint.DeleteCurrencyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_endpoint.GetCurrencyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_endpoint.GetCurrencyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_endpoint.GetCurrencyRequest.displayName = 'proto.currency_endpoint.GetCurrencyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_endpoint.GetCurrenciesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_endpoint.GetCurrenciesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_endpoint.GetCurrenciesRequest.displayName = 'proto.currency_endpoint.GetCurrenciesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_endpoint.AddCurrencyReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_endpoint.AddCurrencyReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_endpoint.AddCurrencyReplay.displayName = 'proto.currency_endpoint.AddCurrencyReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_endpoint.UpdateCurrencyReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_endpoint.UpdateCurrencyReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_endpoint.UpdateCurrencyReplay.displayName = 'proto.currency_endpoint.UpdateCurrencyReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_endpoint.DeleteCurrencyReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_endpoint.DeleteCurrencyReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_endpoint.DeleteCurrencyReplay.displayName = 'proto.currency_endpoint.DeleteCurrencyReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_endpoint.GetCurrencyReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.currency_endpoint.GetCurrencyReplay.repeatedFields_, null);
};
goog.inherits(proto.currency_endpoint.GetCurrencyReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_endpoint.GetCurrencyReplay.displayName = 'proto.currency_endpoint.GetCurrencyReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_endpoint.Currency = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_endpoint.Currency, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_endpoint.Currency.displayName = 'proto.currency_endpoint.Currency';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_endpoint.GetCurrenciesReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.currency_endpoint.GetCurrenciesReplay.repeatedFields_, null);
};
goog.inherits(proto.currency_endpoint.GetCurrenciesReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_endpoint.GetCurrenciesReplay.displayName = 'proto.currency_endpoint.GetCurrenciesReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_endpoint.AddCurrencyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.currency_endpoint.AddCurrencyResponse.oneofGroups_);
};
goog.inherits(proto.currency_endpoint.AddCurrencyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_endpoint.AddCurrencyResponse.displayName = 'proto.currency_endpoint.AddCurrencyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_endpoint.UpdateCurrencyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.currency_endpoint.UpdateCurrencyResponse.oneofGroups_);
};
goog.inherits(proto.currency_endpoint.UpdateCurrencyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_endpoint.UpdateCurrencyResponse.displayName = 'proto.currency_endpoint.UpdateCurrencyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_endpoint.DeleteCurrencyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.currency_endpoint.DeleteCurrencyResponse.oneofGroups_);
};
goog.inherits(proto.currency_endpoint.DeleteCurrencyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_endpoint.DeleteCurrencyResponse.displayName = 'proto.currency_endpoint.DeleteCurrencyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_endpoint.GetCurrencyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.currency_endpoint.GetCurrencyResponse.oneofGroups_);
};
goog.inherits(proto.currency_endpoint.GetCurrencyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_endpoint.GetCurrencyResponse.displayName = 'proto.currency_endpoint.GetCurrencyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_endpoint.GetCurrenciesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.currency_endpoint.GetCurrenciesResponse.oneofGroups_);
};
goog.inherits(proto.currency_endpoint.GetCurrenciesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_endpoint.GetCurrenciesResponse.displayName = 'proto.currency_endpoint.GetCurrenciesResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_endpoint.CurrencyForm.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_endpoint.CurrencyForm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_endpoint.CurrencyForm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.CurrencyForm.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_endpoint.CurrencyForm}
 */
proto.currency_endpoint.CurrencyForm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_endpoint.CurrencyForm;
  return proto.currency_endpoint.CurrencyForm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_endpoint.CurrencyForm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_endpoint.CurrencyForm}
 */
proto.currency_endpoint.CurrencyForm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_endpoint.CurrencyForm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_endpoint.CurrencyForm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_endpoint.CurrencyForm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.CurrencyForm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.currency_endpoint.CurrencyForm.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.currency_endpoint.CurrencyForm} returns this
 */
proto.currency_endpoint.CurrencyForm.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_endpoint.CurrencyFormResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_endpoint.CurrencyFormResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_endpoint.CurrencyFormResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.CurrencyFormResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    formid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_endpoint.CurrencyFormResponse}
 */
proto.currency_endpoint.CurrencyFormResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_endpoint.CurrencyFormResponse;
  return proto.currency_endpoint.CurrencyFormResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_endpoint.CurrencyFormResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_endpoint.CurrencyFormResponse}
 */
proto.currency_endpoint.CurrencyFormResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFormid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_endpoint.CurrencyFormResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_endpoint.CurrencyFormResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_endpoint.CurrencyFormResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.CurrencyFormResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFormid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 formId = 1;
 * @return {number}
 */
proto.currency_endpoint.CurrencyFormResponse.prototype.getFormid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.currency_endpoint.CurrencyFormResponse} returns this
 */
proto.currency_endpoint.CurrencyFormResponse.prototype.setFormid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.currency_endpoint.AddCurrencyRequest.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_endpoint.AddCurrencyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_endpoint.AddCurrencyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_endpoint.AddCurrencyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.AddCurrencyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    decimalplaces: jspb.Message.getFieldWithDefault(msg, 2, 0),
    symbol: jspb.Message.getFieldWithDefault(msg, 3, ""),
    code: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isavailable: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    form: (f = msg.getForm()) && proto.currency_endpoint.CurrencyForm.toObject(includeInstance, f),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    iconsource: jspb.Message.getFieldWithDefault(msg, 8, ""),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_endpoint.AddCurrencyRequest}
 */
proto.currency_endpoint.AddCurrencyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_endpoint.AddCurrencyRequest;
  return proto.currency_endpoint.AddCurrencyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_endpoint.AddCurrencyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_endpoint.AddCurrencyRequest}
 */
proto.currency_endpoint.AddCurrencyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDecimalplaces(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsavailable(value);
      break;
    case 6:
      var value = new proto.currency_endpoint.CurrencyForm;
      reader.readMessage(value,proto.currency_endpoint.CurrencyForm.deserializeBinaryFromReader);
      msg.setForm(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setIconsource(value);
      break;
    case 9:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_endpoint.AddCurrencyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_endpoint.AddCurrencyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_endpoint.AddCurrencyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.AddCurrencyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDecimalplaces();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsavailable();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getForm();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.currency_endpoint.CurrencyForm.serializeBinaryToWriter
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getIconsource();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.currency_endpoint.AddCurrencyRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_endpoint.AddCurrencyRequest} returns this
 */
proto.currency_endpoint.AddCurrencyRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 decimalPlaces = 2;
 * @return {number}
 */
proto.currency_endpoint.AddCurrencyRequest.prototype.getDecimalplaces = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.currency_endpoint.AddCurrencyRequest} returns this
 */
proto.currency_endpoint.AddCurrencyRequest.prototype.setDecimalplaces = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string symbol = 3;
 * @return {string}
 */
proto.currency_endpoint.AddCurrencyRequest.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_endpoint.AddCurrencyRequest} returns this
 */
proto.currency_endpoint.AddCurrencyRequest.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string code = 4;
 * @return {string}
 */
proto.currency_endpoint.AddCurrencyRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_endpoint.AddCurrencyRequest} returns this
 */
proto.currency_endpoint.AddCurrencyRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool isAvailable = 5;
 * @return {boolean}
 */
proto.currency_endpoint.AddCurrencyRequest.prototype.getIsavailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.currency_endpoint.AddCurrencyRequest} returns this
 */
proto.currency_endpoint.AddCurrencyRequest.prototype.setIsavailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional CurrencyForm form = 6;
 * @return {?proto.currency_endpoint.CurrencyForm}
 */
proto.currency_endpoint.AddCurrencyRequest.prototype.getForm = function() {
  return /** @type{?proto.currency_endpoint.CurrencyForm} */ (
    jspb.Message.getWrapperField(this, proto.currency_endpoint.CurrencyForm, 6));
};


/**
 * @param {?proto.currency_endpoint.CurrencyForm|undefined} value
 * @return {!proto.currency_endpoint.AddCurrencyRequest} returns this
*/
proto.currency_endpoint.AddCurrencyRequest.prototype.setForm = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.AddCurrencyRequest} returns this
 */
proto.currency_endpoint.AddCurrencyRequest.prototype.clearForm = function() {
  return this.setForm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.AddCurrencyRequest.prototype.hasForm = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Int32Value organization = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.currency_endpoint.AddCurrencyRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.currency_endpoint.AddCurrencyRequest} returns this
*/
proto.currency_endpoint.AddCurrencyRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.AddCurrencyRequest} returns this
 */
proto.currency_endpoint.AddCurrencyRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.AddCurrencyRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string iconSource = 8;
 * @return {string}
 */
proto.currency_endpoint.AddCurrencyRequest.prototype.getIconsource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_endpoint.AddCurrencyRequest} returns this
 */
proto.currency_endpoint.AddCurrencyRequest.prototype.setIconsource = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated common.ExtraField extraFields = 9;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.currency_endpoint.AddCurrencyRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 9));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.currency_endpoint.AddCurrencyRequest} returns this
*/
proto.currency_endpoint.AddCurrencyRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.currency_endpoint.AddCurrencyRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.currency_endpoint.AddCurrencyRequest} returns this
 */
proto.currency_endpoint.AddCurrencyRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.currency_endpoint.UpdateCurrencyRequest.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_endpoint.UpdateCurrencyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_endpoint.UpdateCurrencyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_endpoint.UpdateCurrencyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.UpdateCurrencyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 3, ""),
    code: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isavailable: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    form: (f = msg.getForm()) && proto.currency_endpoint.CurrencyForm.toObject(includeInstance, f),
    iconsource: jspb.Message.getFieldWithDefault(msg, 7, ""),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_endpoint.UpdateCurrencyRequest}
 */
proto.currency_endpoint.UpdateCurrencyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_endpoint.UpdateCurrencyRequest;
  return proto.currency_endpoint.UpdateCurrencyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_endpoint.UpdateCurrencyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_endpoint.UpdateCurrencyRequest}
 */
proto.currency_endpoint.UpdateCurrencyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsavailable(value);
      break;
    case 6:
      var value = new proto.currency_endpoint.CurrencyForm;
      reader.readMessage(value,proto.currency_endpoint.CurrencyForm.deserializeBinaryFromReader);
      msg.setForm(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setIconsource(value);
      break;
    case 8:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_endpoint.UpdateCurrencyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_endpoint.UpdateCurrencyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_endpoint.UpdateCurrencyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.UpdateCurrencyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsavailable();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getForm();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.currency_endpoint.CurrencyForm.serializeBinaryToWriter
    );
  }
  f = message.getIconsource();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.currency_endpoint.UpdateCurrencyRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_endpoint.UpdateCurrencyRequest} returns this
 */
proto.currency_endpoint.UpdateCurrencyRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.currency_endpoint.UpdateCurrencyRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_endpoint.UpdateCurrencyRequest} returns this
 */
proto.currency_endpoint.UpdateCurrencyRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string symbol = 3;
 * @return {string}
 */
proto.currency_endpoint.UpdateCurrencyRequest.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_endpoint.UpdateCurrencyRequest} returns this
 */
proto.currency_endpoint.UpdateCurrencyRequest.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string code = 4;
 * @return {string}
 */
proto.currency_endpoint.UpdateCurrencyRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_endpoint.UpdateCurrencyRequest} returns this
 */
proto.currency_endpoint.UpdateCurrencyRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool isAvailable = 5;
 * @return {boolean}
 */
proto.currency_endpoint.UpdateCurrencyRequest.prototype.getIsavailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.currency_endpoint.UpdateCurrencyRequest} returns this
 */
proto.currency_endpoint.UpdateCurrencyRequest.prototype.setIsavailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional CurrencyForm form = 6;
 * @return {?proto.currency_endpoint.CurrencyForm}
 */
proto.currency_endpoint.UpdateCurrencyRequest.prototype.getForm = function() {
  return /** @type{?proto.currency_endpoint.CurrencyForm} */ (
    jspb.Message.getWrapperField(this, proto.currency_endpoint.CurrencyForm, 6));
};


/**
 * @param {?proto.currency_endpoint.CurrencyForm|undefined} value
 * @return {!proto.currency_endpoint.UpdateCurrencyRequest} returns this
*/
proto.currency_endpoint.UpdateCurrencyRequest.prototype.setForm = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.UpdateCurrencyRequest} returns this
 */
proto.currency_endpoint.UpdateCurrencyRequest.prototype.clearForm = function() {
  return this.setForm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.UpdateCurrencyRequest.prototype.hasForm = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string iconSource = 7;
 * @return {string}
 */
proto.currency_endpoint.UpdateCurrencyRequest.prototype.getIconsource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_endpoint.UpdateCurrencyRequest} returns this
 */
proto.currency_endpoint.UpdateCurrencyRequest.prototype.setIconsource = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated common.ExtraField extraFields = 8;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.currency_endpoint.UpdateCurrencyRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 8));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.currency_endpoint.UpdateCurrencyRequest} returns this
*/
proto.currency_endpoint.UpdateCurrencyRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.currency_endpoint.UpdateCurrencyRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.currency_endpoint.UpdateCurrencyRequest} returns this
 */
proto.currency_endpoint.UpdateCurrencyRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_endpoint.DeleteCurrencyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_endpoint.DeleteCurrencyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_endpoint.DeleteCurrencyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.DeleteCurrencyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_endpoint.DeleteCurrencyRequest}
 */
proto.currency_endpoint.DeleteCurrencyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_endpoint.DeleteCurrencyRequest;
  return proto.currency_endpoint.DeleteCurrencyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_endpoint.DeleteCurrencyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_endpoint.DeleteCurrencyRequest}
 */
proto.currency_endpoint.DeleteCurrencyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_endpoint.DeleteCurrencyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_endpoint.DeleteCurrencyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_endpoint.DeleteCurrencyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.DeleteCurrencyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.currency_endpoint.DeleteCurrencyRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_endpoint.DeleteCurrencyRequest} returns this
 */
proto.currency_endpoint.DeleteCurrencyRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_endpoint.GetCurrencyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_endpoint.GetCurrencyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_endpoint.GetCurrencyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.GetCurrencyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_endpoint.GetCurrencyRequest}
 */
proto.currency_endpoint.GetCurrencyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_endpoint.GetCurrencyRequest;
  return proto.currency_endpoint.GetCurrencyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_endpoint.GetCurrencyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_endpoint.GetCurrencyRequest}
 */
proto.currency_endpoint.GetCurrencyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_endpoint.GetCurrencyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_endpoint.GetCurrencyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_endpoint.GetCurrencyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.GetCurrencyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.currency_endpoint.GetCurrencyRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_endpoint.GetCurrencyRequest} returns this
 */
proto.currency_endpoint.GetCurrencyRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_endpoint.GetCurrenciesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_endpoint.GetCurrenciesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_endpoint.GetCurrenciesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.GetCurrenciesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    isavailable: (f = msg.getIsavailable()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    form: (f = msg.getForm()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    order: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    nextto: (f = msg.getNextto()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    numofresults: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_endpoint.GetCurrenciesRequest}
 */
proto.currency_endpoint.GetCurrenciesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_endpoint.GetCurrenciesRequest;
  return proto.currency_endpoint.GetCurrenciesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_endpoint.GetCurrenciesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_endpoint.GetCurrenciesRequest}
 */
proto.currency_endpoint.GetCurrenciesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsavailable(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setForm(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrder(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNextto(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumofresults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_endpoint.GetCurrenciesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_endpoint.GetCurrenciesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_endpoint.GetCurrenciesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.GetCurrenciesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getIsavailable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getForm();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getNextto();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNumofresults();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional google.protobuf.Int32Value organization = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.currency_endpoint.GetCurrenciesRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.currency_endpoint.GetCurrenciesRequest} returns this
*/
proto.currency_endpoint.GetCurrenciesRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.GetCurrenciesRequest} returns this
 */
proto.currency_endpoint.GetCurrenciesRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.GetCurrenciesRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.BoolValue isAvailable = 2;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.currency_endpoint.GetCurrenciesRequest.prototype.getIsavailable = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 2));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.currency_endpoint.GetCurrenciesRequest} returns this
*/
proto.currency_endpoint.GetCurrenciesRequest.prototype.setIsavailable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.GetCurrenciesRequest} returns this
 */
proto.currency_endpoint.GetCurrenciesRequest.prototype.clearIsavailable = function() {
  return this.setIsavailable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.GetCurrenciesRequest.prototype.hasIsavailable = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Int32Value form = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.currency_endpoint.GetCurrenciesRequest.prototype.getForm = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.currency_endpoint.GetCurrenciesRequest} returns this
*/
proto.currency_endpoint.GetCurrenciesRequest.prototype.setForm = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.GetCurrenciesRequest} returns this
 */
proto.currency_endpoint.GetCurrenciesRequest.prototype.clearForm = function() {
  return this.setForm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.GetCurrenciesRequest.prototype.hasForm = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue search = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_endpoint.GetCurrenciesRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_endpoint.GetCurrenciesRequest} returns this
*/
proto.currency_endpoint.GetCurrenciesRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.GetCurrenciesRequest} returns this
 */
proto.currency_endpoint.GetCurrenciesRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.GetCurrenciesRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool order = 5;
 * @return {boolean}
 */
proto.currency_endpoint.GetCurrenciesRequest.prototype.getOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.currency_endpoint.GetCurrenciesRequest} returns this
 */
proto.currency_endpoint.GetCurrenciesRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional google.protobuf.StringValue nextTo = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_endpoint.GetCurrenciesRequest.prototype.getNextto = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_endpoint.GetCurrenciesRequest} returns this
*/
proto.currency_endpoint.GetCurrenciesRequest.prototype.setNextto = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.GetCurrenciesRequest} returns this
 */
proto.currency_endpoint.GetCurrenciesRequest.prototype.clearNextto = function() {
  return this.setNextto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.GetCurrenciesRequest.prototype.hasNextto = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 numOfResults = 7;
 * @return {number}
 */
proto.currency_endpoint.GetCurrenciesRequest.prototype.getNumofresults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.currency_endpoint.GetCurrenciesRequest} returns this
 */
proto.currency_endpoint.GetCurrenciesRequest.prototype.setNumofresults = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_endpoint.AddCurrencyReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_endpoint.AddCurrencyReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_endpoint.AddCurrencyReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.AddCurrencyReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_endpoint.AddCurrencyReplay}
 */
proto.currency_endpoint.AddCurrencyReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_endpoint.AddCurrencyReplay;
  return proto.currency_endpoint.AddCurrencyReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_endpoint.AddCurrencyReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_endpoint.AddCurrencyReplay}
 */
proto.currency_endpoint.AddCurrencyReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 3:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_endpoint.AddCurrencyReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_endpoint.AddCurrencyReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_endpoint.AddCurrencyReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.AddCurrencyReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.currency_endpoint.AddCurrencyReplay.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_endpoint.AddCurrencyReplay} returns this
 */
proto.currency_endpoint.AddCurrencyReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.currency_endpoint.AddCurrencyReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.currency_endpoint.AddCurrencyReplay} returns this
*/
proto.currency_endpoint.AddCurrencyReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.AddCurrencyReplay} returns this
 */
proto.currency_endpoint.AddCurrencyReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.AddCurrencyReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.MessageResponse message = 3;
 * @return {?proto.common.MessageResponse}
 */
proto.currency_endpoint.AddCurrencyReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 3));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.currency_endpoint.AddCurrencyReplay} returns this
*/
proto.currency_endpoint.AddCurrencyReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.AddCurrencyReplay} returns this
 */
proto.currency_endpoint.AddCurrencyReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.AddCurrencyReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_endpoint.UpdateCurrencyReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_endpoint.UpdateCurrencyReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_endpoint.UpdateCurrencyReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.UpdateCurrencyReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_endpoint.UpdateCurrencyReplay}
 */
proto.currency_endpoint.UpdateCurrencyReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_endpoint.UpdateCurrencyReplay;
  return proto.currency_endpoint.UpdateCurrencyReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_endpoint.UpdateCurrencyReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_endpoint.UpdateCurrencyReplay}
 */
proto.currency_endpoint.UpdateCurrencyReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_endpoint.UpdateCurrencyReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_endpoint.UpdateCurrencyReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_endpoint.UpdateCurrencyReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.UpdateCurrencyReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.currency_endpoint.UpdateCurrencyReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.currency_endpoint.UpdateCurrencyReplay} returns this
*/
proto.currency_endpoint.UpdateCurrencyReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.UpdateCurrencyReplay} returns this
 */
proto.currency_endpoint.UpdateCurrencyReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.UpdateCurrencyReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.currency_endpoint.UpdateCurrencyReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.currency_endpoint.UpdateCurrencyReplay} returns this
*/
proto.currency_endpoint.UpdateCurrencyReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.UpdateCurrencyReplay} returns this
 */
proto.currency_endpoint.UpdateCurrencyReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.UpdateCurrencyReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_endpoint.DeleteCurrencyReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_endpoint.DeleteCurrencyReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_endpoint.DeleteCurrencyReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.DeleteCurrencyReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    deleteddate: (f = msg.getDeleteddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_endpoint.DeleteCurrencyReplay}
 */
proto.currency_endpoint.DeleteCurrencyReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_endpoint.DeleteCurrencyReplay;
  return proto.currency_endpoint.DeleteCurrencyReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_endpoint.DeleteCurrencyReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_endpoint.DeleteCurrencyReplay}
 */
proto.currency_endpoint.DeleteCurrencyReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeleteddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_endpoint.DeleteCurrencyReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_endpoint.DeleteCurrencyReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_endpoint.DeleteCurrencyReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.DeleteCurrencyReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeleteddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp deletedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.currency_endpoint.DeleteCurrencyReplay.prototype.getDeleteddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.currency_endpoint.DeleteCurrencyReplay} returns this
*/
proto.currency_endpoint.DeleteCurrencyReplay.prototype.setDeleteddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.DeleteCurrencyReplay} returns this
 */
proto.currency_endpoint.DeleteCurrencyReplay.prototype.clearDeleteddate = function() {
  return this.setDeleteddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.DeleteCurrencyReplay.prototype.hasDeleteddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.currency_endpoint.DeleteCurrencyReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.currency_endpoint.DeleteCurrencyReplay} returns this
*/
proto.currency_endpoint.DeleteCurrencyReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.DeleteCurrencyReplay} returns this
 */
proto.currency_endpoint.DeleteCurrencyReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.DeleteCurrencyReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.currency_endpoint.GetCurrencyReplay.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_endpoint.GetCurrencyReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_endpoint.GetCurrencyReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_endpoint.GetCurrencyReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.GetCurrencyReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    decimalplaces: (f = msg.getDecimalplaces()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    symbol: (f = msg.getSymbol()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    code: (f = msg.getCode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isavailable: (f = msg.getIsavailable()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    form: (f = msg.getForm()) && proto.currency_endpoint.CurrencyFormResponse.toObject(includeInstance, f),
    iconsource: (f = msg.getIconsource()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_endpoint.GetCurrencyReplay}
 */
proto.currency_endpoint.GetCurrencyReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_endpoint.GetCurrencyReplay;
  return proto.currency_endpoint.GetCurrencyReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_endpoint.GetCurrencyReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_endpoint.GetCurrencyReplay}
 */
proto.currency_endpoint.GetCurrencyReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setDecimalplaces(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSymbol(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCode(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsavailable(value);
      break;
    case 7:
      var value = new proto.currency_endpoint.CurrencyFormResponse;
      reader.readMessage(value,proto.currency_endpoint.CurrencyFormResponse.deserializeBinaryFromReader);
      msg.setForm(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setIconsource(value);
      break;
    case 9:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    case 10:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_endpoint.GetCurrencyReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_endpoint.GetCurrencyReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_endpoint.GetCurrencyReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.GetCurrencyReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDecimalplaces();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getSymbol();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCode();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsavailable();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getForm();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.currency_endpoint.CurrencyFormResponse.serializeBinaryToWriter
    );
  }
  f = message.getIconsource();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.currency_endpoint.GetCurrencyReplay.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_endpoint.GetCurrencyReplay} returns this
 */
proto.currency_endpoint.GetCurrencyReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_endpoint.GetCurrencyReplay.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_endpoint.GetCurrencyReplay} returns this
*/
proto.currency_endpoint.GetCurrencyReplay.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.GetCurrencyReplay} returns this
 */
proto.currency_endpoint.GetCurrencyReplay.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.GetCurrencyReplay.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Int32Value decimalPlaces = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.currency_endpoint.GetCurrencyReplay.prototype.getDecimalplaces = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.currency_endpoint.GetCurrencyReplay} returns this
*/
proto.currency_endpoint.GetCurrencyReplay.prototype.setDecimalplaces = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.GetCurrencyReplay} returns this
 */
proto.currency_endpoint.GetCurrencyReplay.prototype.clearDecimalplaces = function() {
  return this.setDecimalplaces(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.GetCurrencyReplay.prototype.hasDecimalplaces = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue symbol = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_endpoint.GetCurrencyReplay.prototype.getSymbol = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_endpoint.GetCurrencyReplay} returns this
*/
proto.currency_endpoint.GetCurrencyReplay.prototype.setSymbol = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.GetCurrencyReplay} returns this
 */
proto.currency_endpoint.GetCurrencyReplay.prototype.clearSymbol = function() {
  return this.setSymbol(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.GetCurrencyReplay.prototype.hasSymbol = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue code = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_endpoint.GetCurrencyReplay.prototype.getCode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_endpoint.GetCurrencyReplay} returns this
*/
proto.currency_endpoint.GetCurrencyReplay.prototype.setCode = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.GetCurrencyReplay} returns this
 */
proto.currency_endpoint.GetCurrencyReplay.prototype.clearCode = function() {
  return this.setCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.GetCurrencyReplay.prototype.hasCode = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.BoolValue isAvailable = 6;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.currency_endpoint.GetCurrencyReplay.prototype.getIsavailable = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 6));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.currency_endpoint.GetCurrencyReplay} returns this
*/
proto.currency_endpoint.GetCurrencyReplay.prototype.setIsavailable = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.GetCurrencyReplay} returns this
 */
proto.currency_endpoint.GetCurrencyReplay.prototype.clearIsavailable = function() {
  return this.setIsavailable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.GetCurrencyReplay.prototype.hasIsavailable = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional CurrencyFormResponse form = 7;
 * @return {?proto.currency_endpoint.CurrencyFormResponse}
 */
proto.currency_endpoint.GetCurrencyReplay.prototype.getForm = function() {
  return /** @type{?proto.currency_endpoint.CurrencyFormResponse} */ (
    jspb.Message.getWrapperField(this, proto.currency_endpoint.CurrencyFormResponse, 7));
};


/**
 * @param {?proto.currency_endpoint.CurrencyFormResponse|undefined} value
 * @return {!proto.currency_endpoint.GetCurrencyReplay} returns this
*/
proto.currency_endpoint.GetCurrencyReplay.prototype.setForm = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.GetCurrencyReplay} returns this
 */
proto.currency_endpoint.GetCurrencyReplay.prototype.clearForm = function() {
  return this.setForm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.GetCurrencyReplay.prototype.hasForm = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue iconSource = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_endpoint.GetCurrencyReplay.prototype.getIconsource = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_endpoint.GetCurrencyReplay} returns this
*/
proto.currency_endpoint.GetCurrencyReplay.prototype.setIconsource = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.GetCurrencyReplay} returns this
 */
proto.currency_endpoint.GetCurrencyReplay.prototype.clearIconsource = function() {
  return this.setIconsource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.GetCurrencyReplay.prototype.hasIconsource = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated common.ExtraField extraFields = 9;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.currency_endpoint.GetCurrencyReplay.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 9));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.currency_endpoint.GetCurrencyReplay} returns this
*/
proto.currency_endpoint.GetCurrencyReplay.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.currency_endpoint.GetCurrencyReplay.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.currency_endpoint.GetCurrencyReplay} returns this
 */
proto.currency_endpoint.GetCurrencyReplay.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};


/**
 * optional common.ProfileResponse createdBy = 10;
 * @return {?proto.common.ProfileResponse}
 */
proto.currency_endpoint.GetCurrencyReplay.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 10));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.currency_endpoint.GetCurrencyReplay} returns this
*/
proto.currency_endpoint.GetCurrencyReplay.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.GetCurrencyReplay} returns this
 */
proto.currency_endpoint.GetCurrencyReplay.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.GetCurrencyReplay.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.currency_endpoint.GetCurrencyReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.currency_endpoint.GetCurrencyReplay} returns this
*/
proto.currency_endpoint.GetCurrencyReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.GetCurrencyReplay} returns this
 */
proto.currency_endpoint.GetCurrencyReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.GetCurrencyReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_endpoint.Currency.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_endpoint.Currency.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_endpoint.Currency} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.Currency.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    symbol: (f = msg.getSymbol()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    code: (f = msg.getCode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isavailable: (f = msg.getIsavailable()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    form: (f = msg.getForm()) && proto.currency_endpoint.CurrencyFormResponse.toObject(includeInstance, f),
    decimalplaces: (f = msg.getDecimalplaces()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    iconsource: (f = msg.getIconsource()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_endpoint.Currency}
 */
proto.currency_endpoint.Currency.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_endpoint.Currency;
  return proto.currency_endpoint.Currency.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_endpoint.Currency} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_endpoint.Currency}
 */
proto.currency_endpoint.Currency.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSymbol(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCode(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsavailable(value);
      break;
    case 6:
      var value = new proto.currency_endpoint.CurrencyFormResponse;
      reader.readMessage(value,proto.currency_endpoint.CurrencyFormResponse.deserializeBinaryFromReader);
      msg.setForm(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setDecimalplaces(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setIconsource(value);
      break;
    case 9:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_endpoint.Currency.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_endpoint.Currency.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_endpoint.Currency} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.Currency.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSymbol();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCode();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsavailable();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getForm();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.currency_endpoint.CurrencyFormResponse.serializeBinaryToWriter
    );
  }
  f = message.getDecimalplaces();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getIconsource();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.currency_endpoint.Currency.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_endpoint.Currency} returns this
 */
proto.currency_endpoint.Currency.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_endpoint.Currency.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_endpoint.Currency} returns this
*/
proto.currency_endpoint.Currency.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.Currency} returns this
 */
proto.currency_endpoint.Currency.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.Currency.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue symbol = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_endpoint.Currency.prototype.getSymbol = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_endpoint.Currency} returns this
*/
proto.currency_endpoint.Currency.prototype.setSymbol = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.Currency} returns this
 */
proto.currency_endpoint.Currency.prototype.clearSymbol = function() {
  return this.setSymbol(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.Currency.prototype.hasSymbol = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue code = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_endpoint.Currency.prototype.getCode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_endpoint.Currency} returns this
*/
proto.currency_endpoint.Currency.prototype.setCode = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.Currency} returns this
 */
proto.currency_endpoint.Currency.prototype.clearCode = function() {
  return this.setCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.Currency.prototype.hasCode = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.BoolValue isAvailable = 5;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.currency_endpoint.Currency.prototype.getIsavailable = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 5));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.currency_endpoint.Currency} returns this
*/
proto.currency_endpoint.Currency.prototype.setIsavailable = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.Currency} returns this
 */
proto.currency_endpoint.Currency.prototype.clearIsavailable = function() {
  return this.setIsavailable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.Currency.prototype.hasIsavailable = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional CurrencyFormResponse form = 6;
 * @return {?proto.currency_endpoint.CurrencyFormResponse}
 */
proto.currency_endpoint.Currency.prototype.getForm = function() {
  return /** @type{?proto.currency_endpoint.CurrencyFormResponse} */ (
    jspb.Message.getWrapperField(this, proto.currency_endpoint.CurrencyFormResponse, 6));
};


/**
 * @param {?proto.currency_endpoint.CurrencyFormResponse|undefined} value
 * @return {!proto.currency_endpoint.Currency} returns this
*/
proto.currency_endpoint.Currency.prototype.setForm = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.Currency} returns this
 */
proto.currency_endpoint.Currency.prototype.clearForm = function() {
  return this.setForm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.Currency.prototype.hasForm = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Int32Value decimalPlaces = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.currency_endpoint.Currency.prototype.getDecimalplaces = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.currency_endpoint.Currency} returns this
*/
proto.currency_endpoint.Currency.prototype.setDecimalplaces = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.Currency} returns this
 */
proto.currency_endpoint.Currency.prototype.clearDecimalplaces = function() {
  return this.setDecimalplaces(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.Currency.prototype.hasDecimalplaces = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue iconSource = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_endpoint.Currency.prototype.getIconsource = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_endpoint.Currency} returns this
*/
proto.currency_endpoint.Currency.prototype.setIconsource = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.Currency} returns this
 */
proto.currency_endpoint.Currency.prototype.clearIconsource = function() {
  return this.setIconsource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.Currency.prototype.hasIconsource = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional common.ProfileResponse createdBy = 9;
 * @return {?proto.common.ProfileResponse}
 */
proto.currency_endpoint.Currency.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 9));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.currency_endpoint.Currency} returns this
*/
proto.currency_endpoint.Currency.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.Currency} returns this
 */
proto.currency_endpoint.Currency.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.Currency.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.currency_endpoint.Currency.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.currency_endpoint.Currency} returns this
*/
proto.currency_endpoint.Currency.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.Currency} returns this
 */
proto.currency_endpoint.Currency.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.Currency.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 10) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.currency_endpoint.GetCurrenciesReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_endpoint.GetCurrenciesReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_endpoint.GetCurrenciesReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_endpoint.GetCurrenciesReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.GetCurrenciesReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    currenciesList: jspb.Message.toObjectList(msg.getCurrenciesList(),
    proto.currency_endpoint.Currency.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_endpoint.GetCurrenciesReplay}
 */
proto.currency_endpoint.GetCurrenciesReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_endpoint.GetCurrenciesReplay;
  return proto.currency_endpoint.GetCurrenciesReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_endpoint.GetCurrenciesReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_endpoint.GetCurrenciesReplay}
 */
proto.currency_endpoint.GetCurrenciesReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.currency_endpoint.Currency;
      reader.readMessage(value,proto.currency_endpoint.Currency.deserializeBinaryFromReader);
      msg.addCurrencies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_endpoint.GetCurrenciesReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_endpoint.GetCurrenciesReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_endpoint.GetCurrenciesReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.GetCurrenciesReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrenciesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.currency_endpoint.Currency.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Currency currencies = 1;
 * @return {!Array<!proto.currency_endpoint.Currency>}
 */
proto.currency_endpoint.GetCurrenciesReplay.prototype.getCurrenciesList = function() {
  return /** @type{!Array<!proto.currency_endpoint.Currency>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.currency_endpoint.Currency, 1));
};


/**
 * @param {!Array<!proto.currency_endpoint.Currency>} value
 * @return {!proto.currency_endpoint.GetCurrenciesReplay} returns this
*/
proto.currency_endpoint.GetCurrenciesReplay.prototype.setCurrenciesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.currency_endpoint.Currency=} opt_value
 * @param {number=} opt_index
 * @return {!proto.currency_endpoint.Currency}
 */
proto.currency_endpoint.GetCurrenciesReplay.prototype.addCurrencies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.currency_endpoint.Currency, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.currency_endpoint.GetCurrenciesReplay} returns this
 */
proto.currency_endpoint.GetCurrenciesReplay.prototype.clearCurrenciesList = function() {
  return this.setCurrenciesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.currency_endpoint.AddCurrencyResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.currency_endpoint.AddCurrencyResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.currency_endpoint.AddCurrencyResponse.ResultCase}
 */
proto.currency_endpoint.AddCurrencyResponse.prototype.getResultCase = function() {
  return /** @type {proto.currency_endpoint.AddCurrencyResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.currency_endpoint.AddCurrencyResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_endpoint.AddCurrencyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_endpoint.AddCurrencyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_endpoint.AddCurrencyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.AddCurrencyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.currency_endpoint.AddCurrencyReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_endpoint.AddCurrencyResponse}
 */
proto.currency_endpoint.AddCurrencyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_endpoint.AddCurrencyResponse;
  return proto.currency_endpoint.AddCurrencyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_endpoint.AddCurrencyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_endpoint.AddCurrencyResponse}
 */
proto.currency_endpoint.AddCurrencyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.currency_endpoint.AddCurrencyReplay;
      reader.readMessage(value,proto.currency_endpoint.AddCurrencyReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_endpoint.AddCurrencyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_endpoint.AddCurrencyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_endpoint.AddCurrencyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.AddCurrencyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.currency_endpoint.AddCurrencyReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional AddCurrencyReplay success = 1;
 * @return {?proto.currency_endpoint.AddCurrencyReplay}
 */
proto.currency_endpoint.AddCurrencyResponse.prototype.getSuccess = function() {
  return /** @type{?proto.currency_endpoint.AddCurrencyReplay} */ (
    jspb.Message.getWrapperField(this, proto.currency_endpoint.AddCurrencyReplay, 1));
};


/**
 * @param {?proto.currency_endpoint.AddCurrencyReplay|undefined} value
 * @return {!proto.currency_endpoint.AddCurrencyResponse} returns this
*/
proto.currency_endpoint.AddCurrencyResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.currency_endpoint.AddCurrencyResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.AddCurrencyResponse} returns this
 */
proto.currency_endpoint.AddCurrencyResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.AddCurrencyResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.currency_endpoint.UpdateCurrencyResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.currency_endpoint.UpdateCurrencyResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.currency_endpoint.UpdateCurrencyResponse.ResultCase}
 */
proto.currency_endpoint.UpdateCurrencyResponse.prototype.getResultCase = function() {
  return /** @type {proto.currency_endpoint.UpdateCurrencyResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.currency_endpoint.UpdateCurrencyResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_endpoint.UpdateCurrencyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_endpoint.UpdateCurrencyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_endpoint.UpdateCurrencyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.UpdateCurrencyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.currency_endpoint.UpdateCurrencyReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_endpoint.UpdateCurrencyResponse}
 */
proto.currency_endpoint.UpdateCurrencyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_endpoint.UpdateCurrencyResponse;
  return proto.currency_endpoint.UpdateCurrencyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_endpoint.UpdateCurrencyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_endpoint.UpdateCurrencyResponse}
 */
proto.currency_endpoint.UpdateCurrencyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.currency_endpoint.UpdateCurrencyReplay;
      reader.readMessage(value,proto.currency_endpoint.UpdateCurrencyReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_endpoint.UpdateCurrencyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_endpoint.UpdateCurrencyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_endpoint.UpdateCurrencyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.UpdateCurrencyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.currency_endpoint.UpdateCurrencyReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateCurrencyReplay success = 1;
 * @return {?proto.currency_endpoint.UpdateCurrencyReplay}
 */
proto.currency_endpoint.UpdateCurrencyResponse.prototype.getSuccess = function() {
  return /** @type{?proto.currency_endpoint.UpdateCurrencyReplay} */ (
    jspb.Message.getWrapperField(this, proto.currency_endpoint.UpdateCurrencyReplay, 1));
};


/**
 * @param {?proto.currency_endpoint.UpdateCurrencyReplay|undefined} value
 * @return {!proto.currency_endpoint.UpdateCurrencyResponse} returns this
*/
proto.currency_endpoint.UpdateCurrencyResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.currency_endpoint.UpdateCurrencyResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.UpdateCurrencyResponse} returns this
 */
proto.currency_endpoint.UpdateCurrencyResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.UpdateCurrencyResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.currency_endpoint.DeleteCurrencyResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.currency_endpoint.DeleteCurrencyResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.currency_endpoint.DeleteCurrencyResponse.ResultCase}
 */
proto.currency_endpoint.DeleteCurrencyResponse.prototype.getResultCase = function() {
  return /** @type {proto.currency_endpoint.DeleteCurrencyResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.currency_endpoint.DeleteCurrencyResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_endpoint.DeleteCurrencyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_endpoint.DeleteCurrencyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_endpoint.DeleteCurrencyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.DeleteCurrencyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.currency_endpoint.DeleteCurrencyReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_endpoint.DeleteCurrencyResponse}
 */
proto.currency_endpoint.DeleteCurrencyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_endpoint.DeleteCurrencyResponse;
  return proto.currency_endpoint.DeleteCurrencyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_endpoint.DeleteCurrencyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_endpoint.DeleteCurrencyResponse}
 */
proto.currency_endpoint.DeleteCurrencyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.currency_endpoint.DeleteCurrencyReplay;
      reader.readMessage(value,proto.currency_endpoint.DeleteCurrencyReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_endpoint.DeleteCurrencyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_endpoint.DeleteCurrencyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_endpoint.DeleteCurrencyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.DeleteCurrencyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.currency_endpoint.DeleteCurrencyReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeleteCurrencyReplay success = 1;
 * @return {?proto.currency_endpoint.DeleteCurrencyReplay}
 */
proto.currency_endpoint.DeleteCurrencyResponse.prototype.getSuccess = function() {
  return /** @type{?proto.currency_endpoint.DeleteCurrencyReplay} */ (
    jspb.Message.getWrapperField(this, proto.currency_endpoint.DeleteCurrencyReplay, 1));
};


/**
 * @param {?proto.currency_endpoint.DeleteCurrencyReplay|undefined} value
 * @return {!proto.currency_endpoint.DeleteCurrencyResponse} returns this
*/
proto.currency_endpoint.DeleteCurrencyResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.currency_endpoint.DeleteCurrencyResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.DeleteCurrencyResponse} returns this
 */
proto.currency_endpoint.DeleteCurrencyResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.DeleteCurrencyResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.currency_endpoint.GetCurrencyResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.currency_endpoint.GetCurrencyResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.currency_endpoint.GetCurrencyResponse.ResultCase}
 */
proto.currency_endpoint.GetCurrencyResponse.prototype.getResultCase = function() {
  return /** @type {proto.currency_endpoint.GetCurrencyResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.currency_endpoint.GetCurrencyResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_endpoint.GetCurrencyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_endpoint.GetCurrencyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_endpoint.GetCurrencyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.GetCurrencyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.currency_endpoint.GetCurrencyReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_endpoint.GetCurrencyResponse}
 */
proto.currency_endpoint.GetCurrencyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_endpoint.GetCurrencyResponse;
  return proto.currency_endpoint.GetCurrencyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_endpoint.GetCurrencyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_endpoint.GetCurrencyResponse}
 */
proto.currency_endpoint.GetCurrencyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.currency_endpoint.GetCurrencyReplay;
      reader.readMessage(value,proto.currency_endpoint.GetCurrencyReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_endpoint.GetCurrencyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_endpoint.GetCurrencyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_endpoint.GetCurrencyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.GetCurrencyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.currency_endpoint.GetCurrencyReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetCurrencyReplay success = 1;
 * @return {?proto.currency_endpoint.GetCurrencyReplay}
 */
proto.currency_endpoint.GetCurrencyResponse.prototype.getSuccess = function() {
  return /** @type{?proto.currency_endpoint.GetCurrencyReplay} */ (
    jspb.Message.getWrapperField(this, proto.currency_endpoint.GetCurrencyReplay, 1));
};


/**
 * @param {?proto.currency_endpoint.GetCurrencyReplay|undefined} value
 * @return {!proto.currency_endpoint.GetCurrencyResponse} returns this
*/
proto.currency_endpoint.GetCurrencyResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.currency_endpoint.GetCurrencyResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.GetCurrencyResponse} returns this
 */
proto.currency_endpoint.GetCurrencyResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.GetCurrencyResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.currency_endpoint.GetCurrenciesResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.currency_endpoint.GetCurrenciesResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.currency_endpoint.GetCurrenciesResponse.ResultCase}
 */
proto.currency_endpoint.GetCurrenciesResponse.prototype.getResultCase = function() {
  return /** @type {proto.currency_endpoint.GetCurrenciesResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.currency_endpoint.GetCurrenciesResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_endpoint.GetCurrenciesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_endpoint.GetCurrenciesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_endpoint.GetCurrenciesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.GetCurrenciesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.currency_endpoint.GetCurrenciesReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_endpoint.GetCurrenciesResponse}
 */
proto.currency_endpoint.GetCurrenciesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_endpoint.GetCurrenciesResponse;
  return proto.currency_endpoint.GetCurrenciesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_endpoint.GetCurrenciesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_endpoint.GetCurrenciesResponse}
 */
proto.currency_endpoint.GetCurrenciesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.currency_endpoint.GetCurrenciesReplay;
      reader.readMessage(value,proto.currency_endpoint.GetCurrenciesReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_endpoint.GetCurrenciesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_endpoint.GetCurrenciesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_endpoint.GetCurrenciesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_endpoint.GetCurrenciesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.currency_endpoint.GetCurrenciesReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetCurrenciesReplay success = 1;
 * @return {?proto.currency_endpoint.GetCurrenciesReplay}
 */
proto.currency_endpoint.GetCurrenciesResponse.prototype.getSuccess = function() {
  return /** @type{?proto.currency_endpoint.GetCurrenciesReplay} */ (
    jspb.Message.getWrapperField(this, proto.currency_endpoint.GetCurrenciesReplay, 1));
};


/**
 * @param {?proto.currency_endpoint.GetCurrenciesReplay|undefined} value
 * @return {!proto.currency_endpoint.GetCurrenciesResponse} returns this
*/
proto.currency_endpoint.GetCurrenciesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.currency_endpoint.GetCurrenciesResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_endpoint.GetCurrenciesResponse} returns this
 */
proto.currency_endpoint.GetCurrenciesResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_endpoint.GetCurrenciesResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.currency_endpoint);
