import { CommandBar, Text, Stack, PrimaryButton, DefaultButton, Link, Modal, IconButton, IIconProps, getTheme, mergeStyleSets, FontWeights, IButtonStyles, Label, TextField, Layer, mergeStyles, AnimationClassNames } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../app/Hooks";
import { Settings } from "../../common/Settings/Settings";
import { isVisible } from "../../common/Settings/SettingsSlice";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Popup } from "../../common/Popup/Popup";
import { LoginForm } from "../../forms/Login/LoginForm";
import { useEffect } from "react";
import { getPage } from "../../../app/Pages";
import { setTitle } from "../../common/NavHeader/NavHeaderSlice";
import { formatDate, getCurrentLanguage, normalizeKey } from "../../../app/Helpers";
const theme = getTheme();


const key = "login"

export const Login: React.FunctionComponent = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch()
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
  //https://forms.office.com/r/jnbn7hbC5h?origin=lprLink

  const signupURL = "https://forms.office.com/r/jnbn7hbC5h?lang=" + (getCurrentLanguage() == "ar" ? "ar-LY" : "en-US")
  const [isSignUpOpen, { setTrue: showSignUpModal, setFalse: hideSignUpModal }] = useBoolean(false);

  useEffect(() => {

    const page = getPage(key)
    dispatch(setTitle(t(normalizeKey(page?.title))));

  }, [])
  return (
    <Stack verticalFill  >

      <Settings />
      <Popup isOpen={isModalOpen} title={t("signin")} onDismiss={hideModal} >
        <LoginForm childrenGap={10} maxWidth={240} onCancel={hideModal} />
      </Popup>

      <Popup isOpen={isSignUpOpen} title={t("signup")} onDismiss={hideSignUpModal} >
        <iframe width={800} height={800} src={signupURL}></iframe>

      </Popup>


      <Stack verticalFill horizontal >
        <Stack verticalFill styles={{ root: { width: "60%", backgroundImage: "url(\"/img/background.jpg\")" } }}>

        </Stack>
        <Stack verticalFill grow >
          <CommandBar

            items={[]}
            farItems={[{
              key: 'settings',
              text: t("settings"),
              ariaLabel: t("settings"),
              iconOnly: true,
              iconProps: { iconName: 'Settings' },
              onClick: (ev, i) => {
                ev?.currentTarget.blur();
                dispatch(isVisible(true))
              }
            }
            ]}
            ariaLabel={t("commandBarAria")}
          />
          <Stack verticalFill tokens={{ childrenGap: 15, padding: 20 }}>
            <Text variant={'mega'} nowrap block>{t("sarafa")}</Text>
            <Text variant={'xxLarge'} nowrap block>{t("joinToDay")}</Text>

            <Stack tokens={{ childrenGap: 15 }}  >
              <PrimaryButton onClick={showSignUpModal} text={t("signup")} styles={{ root: { borderRadius: 12, width: "20%", minWidth: 150 } }}></PrimaryButton>
              <Stack tokens={{ childrenGap: 15, padding: "40px 0 0 0" }}>
                <Text styles={{ root: { fontWeight: FontWeights.bold } }} variant={'large'} block>{t("alreadyHaveAccount")}</Text>
                <DefaultButton onClick={showModal} styles={{ root: { borderRadius: 12, width: "20%", minWidth: 150 } }} text={t("signin")}></DefaultButton>
              </Stack>


            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 20, padding: 10 }} >
        <Link href={"https://sarafa.ly"} target="_blank">{t("about")}</Link>
        <Link href={"https://sarafa.ly/contact"} target="_blank">{t("helpCenter")}</Link>
        <Link href={"https://sarafa.ly/terms"} target="_blank">{t("tOfs")}</Link>
        <Link href={"https://sarafa.ly/privacy-policy"} target="_blank">{t("pp")}</Link>
        <Stack.Item>

          <Text variant="small">{t("copyright", { year: formatDate((new Date()), "YYYY") })}</Text>
          <Link href={"https://devpoint.ly"} target="_blank">{t("devpoint")}</Link>
        </Stack.Item>
      </Stack>
    </Stack >
  );
}

