/**
 * @fileoverview gRPC-Web generated client stub for treasury_type_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as treasury_type_pb from './treasury_type_pb';


export class TreasuryTypeEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAdd = new grpcWeb.MethodDescriptor(
    '/treasury_type_endpoint.TreasuryTypeEndpoint/Add',
    grpcWeb.MethodType.UNARY,
    treasury_type_pb.AddTreasuryTypeRequest,
    treasury_type_pb.AddTreasuryTypeResponse,
    (request: treasury_type_pb.AddTreasuryTypeRequest) => {
      return request.serializeBinary();
    },
    treasury_type_pb.AddTreasuryTypeResponse.deserializeBinary
  );

  add(
    request: treasury_type_pb.AddTreasuryTypeRequest,
    metadata: grpcWeb.Metadata | null): Promise<treasury_type_pb.AddTreasuryTypeResponse>;

  add(
    request: treasury_type_pb.AddTreasuryTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: treasury_type_pb.AddTreasuryTypeResponse) => void): grpcWeb.ClientReadableStream<treasury_type_pb.AddTreasuryTypeResponse>;

  add(
    request: treasury_type_pb.AddTreasuryTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: treasury_type_pb.AddTreasuryTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/treasury_type_endpoint.TreasuryTypeEndpoint/Add',
        request,
        metadata || {},
        this.methodDescriptorAdd,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/treasury_type_endpoint.TreasuryTypeEndpoint/Add',
    request,
    metadata || {},
    this.methodDescriptorAdd);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/treasury_type_endpoint.TreasuryTypeEndpoint/Update',
    grpcWeb.MethodType.UNARY,
    treasury_type_pb.UpdateTreasuryTypeRequest,
    treasury_type_pb.UpdateTreasuryTypeResponse,
    (request: treasury_type_pb.UpdateTreasuryTypeRequest) => {
      return request.serializeBinary();
    },
    treasury_type_pb.UpdateTreasuryTypeResponse.deserializeBinary
  );

  update(
    request: treasury_type_pb.UpdateTreasuryTypeRequest,
    metadata: grpcWeb.Metadata | null): Promise<treasury_type_pb.UpdateTreasuryTypeResponse>;

  update(
    request: treasury_type_pb.UpdateTreasuryTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: treasury_type_pb.UpdateTreasuryTypeResponse) => void): grpcWeb.ClientReadableStream<treasury_type_pb.UpdateTreasuryTypeResponse>;

  update(
    request: treasury_type_pb.UpdateTreasuryTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: treasury_type_pb.UpdateTreasuryTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/treasury_type_endpoint.TreasuryTypeEndpoint/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/treasury_type_endpoint.TreasuryTypeEndpoint/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/treasury_type_endpoint.TreasuryTypeEndpoint/Delete',
    grpcWeb.MethodType.UNARY,
    treasury_type_pb.DeleteTreasuryTypeRequest,
    treasury_type_pb.DeleteTreasuryTypeResponse,
    (request: treasury_type_pb.DeleteTreasuryTypeRequest) => {
      return request.serializeBinary();
    },
    treasury_type_pb.DeleteTreasuryTypeResponse.deserializeBinary
  );

  delete(
    request: treasury_type_pb.DeleteTreasuryTypeRequest,
    metadata: grpcWeb.Metadata | null): Promise<treasury_type_pb.DeleteTreasuryTypeResponse>;

  delete(
    request: treasury_type_pb.DeleteTreasuryTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: treasury_type_pb.DeleteTreasuryTypeResponse) => void): grpcWeb.ClientReadableStream<treasury_type_pb.DeleteTreasuryTypeResponse>;

  delete(
    request: treasury_type_pb.DeleteTreasuryTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: treasury_type_pb.DeleteTreasuryTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/treasury_type_endpoint.TreasuryTypeEndpoint/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/treasury_type_endpoint.TreasuryTypeEndpoint/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorGetTreasuryType = new grpcWeb.MethodDescriptor(
    '/treasury_type_endpoint.TreasuryTypeEndpoint/GetTreasuryType',
    grpcWeb.MethodType.UNARY,
    treasury_type_pb.GetTreasuryTypeRequest,
    treasury_type_pb.GetTreasuryTypeResponse,
    (request: treasury_type_pb.GetTreasuryTypeRequest) => {
      return request.serializeBinary();
    },
    treasury_type_pb.GetTreasuryTypeResponse.deserializeBinary
  );

  getTreasuryType(
    request: treasury_type_pb.GetTreasuryTypeRequest,
    metadata: grpcWeb.Metadata | null): Promise<treasury_type_pb.GetTreasuryTypeResponse>;

  getTreasuryType(
    request: treasury_type_pb.GetTreasuryTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: treasury_type_pb.GetTreasuryTypeResponse) => void): grpcWeb.ClientReadableStream<treasury_type_pb.GetTreasuryTypeResponse>;

  getTreasuryType(
    request: treasury_type_pb.GetTreasuryTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: treasury_type_pb.GetTreasuryTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/treasury_type_endpoint.TreasuryTypeEndpoint/GetTreasuryType',
        request,
        metadata || {},
        this.methodDescriptorGetTreasuryType,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/treasury_type_endpoint.TreasuryTypeEndpoint/GetTreasuryType',
    request,
    metadata || {},
    this.methodDescriptorGetTreasuryType);
  }

  methodDescriptorGetTreasuryTypes = new grpcWeb.MethodDescriptor(
    '/treasury_type_endpoint.TreasuryTypeEndpoint/GetTreasuryTypes',
    grpcWeb.MethodType.UNARY,
    treasury_type_pb.GetTreasuryTypesRequest,
    treasury_type_pb.GetTreasuryTypesResponse,
    (request: treasury_type_pb.GetTreasuryTypesRequest) => {
      return request.serializeBinary();
    },
    treasury_type_pb.GetTreasuryTypesResponse.deserializeBinary
  );

  getTreasuryTypes(
    request: treasury_type_pb.GetTreasuryTypesRequest,
    metadata: grpcWeb.Metadata | null): Promise<treasury_type_pb.GetTreasuryTypesResponse>;

  getTreasuryTypes(
    request: treasury_type_pb.GetTreasuryTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: treasury_type_pb.GetTreasuryTypesResponse) => void): grpcWeb.ClientReadableStream<treasury_type_pb.GetTreasuryTypesResponse>;

  getTreasuryTypes(
    request: treasury_type_pb.GetTreasuryTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: treasury_type_pb.GetTreasuryTypesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/treasury_type_endpoint.TreasuryTypeEndpoint/GetTreasuryTypes',
        request,
        metadata || {},
        this.methodDescriptorGetTreasuryTypes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/treasury_type_endpoint.TreasuryTypeEndpoint/GetTreasuryTypes',
    request,
    metadata || {},
    this.methodDescriptorGetTreasuryTypes);
  }

}

