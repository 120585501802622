import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { UserEndpointClient } from '../../../../repository/UserManagement/Customers/UserServiceClientPb';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIResponse, refreshSession, getSession, ACCOUNTANT_API_URL, APIRequest, TRADING_API_URL } from '../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../app/Helpers';

import { TableState } from '../../../common/Table/TableSate';
import { number } from 'yup';
import { GetCurrenciesRequest, GetCurrenciesResponse } from '../../../../repository/Accountant/currency_pb';
import { CurrencyEndpointClient } from '../../../../repository/Accountant/CurrencyServiceClientPb';
import { AccountStatus } from '../../../../app/Enums';
import { CurrencyPairEndpointClient } from '../../../../repository/Trading/Currency_pairServiceClientPb';
import { GetCurrencyPairsResponse, GetCurrencyPairsRequest, UpdateCurrencyPairActivityRequest, UpdateCurrencyPairActivityResponse, DeleteCurrencyPairRequest, DeleteCurrencyPairResponse } from '../../../../repository/Trading/currency_pair_pb';
import { ReportEndpointClient } from '../../../../repository/Trading/ReportServiceClientPb';
import { GetCurrencyExchangeRatesReportRequest, GetCurrencyExchangeRatesReportResponse } from '../../../../repository/Trading/report_pb';


export interface AdvancedChartPageState {
    set: TableState
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    searchText: string | undefined,
    filters: any[],
    report: TableState

}

const initialState: AdvancedChartPageState = {
    set: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    report: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    isChangeStateLoading: false,
    message: undefined,
    searchText: undefined,
    filters: [],

}

const client = new CurrencyPairEndpointClient(TRADING_API_URL, null, null);
const clientReport = new ReportEndpointClient(TRADING_API_URL, null, null);

export const getItems = createAsyncThunk<GetCurrencyPairsResponse.AsObject, APIRequest<GetCurrencyPairsRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/trading/chart/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await client.getCurrencyPairs(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

export const getReport = createAsyncThunk<APIResponse<GetCurrencyExchangeRatesReportResponse.AsObject>, APIRequest<GetCurrencyExchangeRatesReportRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/trading/chart/report/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await clientReport.getCurrencyExchangeRatesReport(req.body, req.headers ?? {});
                return { response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

const getFilters = (state: AdvancedChartPageState): any[] => {
    const selected = [];


    return [];
}

export const advancedChartPageSlice = createSlice({
    name: 'pages/trading/chart',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.set = initialState.set;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
            state.searchText = initialState.searchText;
            state.filters = initialState.filters;
            state.report = initialState.report;

        },
        resetSet: (state) => {
            state.set.items = [];
            state.set.hasMore = true;
        },


        setSort: (state, action: PayloadAction<boolean>) => {
            state.set.isDescending = action.payload;
        },

        setNumberOfResults: (state, action: PayloadAction<number>) => {
            state.set.numberOfResults = action.payload;
        },

        setSelectedItems: (state, action: PayloadAction<any[]>) => {
            state.set.selected = action.payload;
        },


        setSearchText: (state, action: PayloadAction<string | undefined>) => {
            state.searchText = action.payload;
        },

        setIsFilteredSet: (state, action: PayloadAction<boolean>) => {
            state.set.isFilteredSet = action.payload;
        },


    },
    extraReducers: (builder) => {

        builder.addCase(getReport.fulfilled, (state, { payload }) => {

            state.report.isFetching = false

        })
        builder.addCase(getReport.rejected, (state, action) => {
            state.report.isFetching = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getReport.pending, (state, action) => {
            state.report.isFetching = true;
            state.message = undefined;
        })

        builder.addCase(getItems.fulfilled, (state, { payload }) => {
            if (payload) {
                let c = 1
                var r = (payload?.success?.currencypairsList.map(val => {
                    let rc = {
                        id: val.id,
                        name: val.name?.value,
                        buyPrice: val.buyprice?.value,
                        iconSource: val.iconsource?.value ?? "",
                        categoryId: val.category?.id?.value ?? "",
                        categoryNameAr: val.category?.namear?.value ?? "",
                        categoryNameEn: val.category?.nameen?.value ?? "",
                        buyPriceEquation: val.pricetrigger?.buypriceequation?.value ?? "",
                        sellPriceEquation: val.pricetrigger?.sellpriceequation?.value ?? "",
                        tradingPriceEquation: val.pricetrigger?.tradingpriceequation?.value ?? "",
                        isPriceTriggerActive: val.pricetrigger?.isactive?.value ,
                        mainCurrencyPair: val.pricetrigger?.main?.id?.value ?? "",
                        mainCurrencyPairName: val.pricetrigger?.main?.name?.value ?? "",
                        mainCurrencyPairBaseCurrencyId: val.pricetrigger?.main?.base?.id?.value ?? "",
                        mainCurrencyPairBaseCurrencyName: val.pricetrigger?.main?.base?.name?.value ?? "",
                        mainCurrencyPairBaseCurrencyCode: val.pricetrigger?.main?.base?.code?.value ?? "",
                        mainCurrencyPairBaseCurrencyDecimalPlaces: val.pricetrigger?.main?.base?.decimalplaces?.value ?? undefined,
                        mainCurrencyPairBaseCurrencyForm: val.pricetrigger?.main?.base?.form?.value ?? undefined,
                        mainCurrencyPairBaseCurrencySymbol: val.pricetrigger?.main?.base?.symbol?.value ?? "",
                        mainCurrencyPairQuoteCurrencyId: val.pricetrigger?.main?.quote?.id?.value ?? "",
                        mainCurrencyPairQuoteCurrencyName: val.pricetrigger?.main?.quote?.name?.value ?? "",
                        mainCurrencyPairQuoteCurrencyCode: val.pricetrigger?.main?.quote?.code?.value ?? "",
                        mainCurrencyPairQuoteCurrencyDecimalPlaces: val.pricetrigger?.main?.quote?.decimalplaces?.value ?? undefined,
                        mainCurrencyPairQuoteCurrencyForm: val.pricetrigger?.main?.quote?.form?.value ?? undefined,
                        mainCurrencyPairQuoteCurrencySymbol: val.pricetrigger?.main?.quote?.symbol?.value ?? "",

                        sellPrice: val.sellprice?.value,
                        tradingPrice: val.tradingprice?.value,
                        isActive: val.isactive?.value,
                        syncPrices: val.syncprices?.value,
                        baseCurrencyId: val.base?.id?.value ?? "",
                        baseCurrencyName: val.base?.name?.value ?? "",
                        baseCurrencyCode: val.base?.code?.value ?? "",
                        baseCurrencyDecimalPlaces: val.base?.decimalplaces?.value ?? undefined,
                        baseCurrencyForm: val.base?.form?.value ?? undefined,
                        baseCurrencySymbol: val.base?.symbol?.value ?? "",
                        quoteCurrencyId: val.quote?.id?.value ?? "",
                        quoteCurrencyName: val.quote?.name?.value ?? "",
                        quoteCurrencyCode: val.quote?.code?.value ?? "",
                        quoteCurrencyDecimalPlaces: val.quote?.decimalplaces?.value ?? undefined,
                        quoteCurrencyForm: val.quote?.form?.value ?? undefined,
                        quoteCurrencySymbol: val.quote?.symbol?.value ?? "",
                        priceUpdate: formatDate(timestampToDate(val.priceupdate?.seconds, val.priceupdate?.nanos)),
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                        number: c
                    }
                    if (rc.name == undefined) {
                        rc.name = (rc?.baseCurrencyName == "" ? rc?.baseCurrencyId : rc?.baseCurrencyName + " (" + rc?.baseCurrencyCode + ")") + " / " + (rc?.quoteCurrencyName == "" ? rc?.quoteCurrencyId : rc?.quoteCurrencyName + " (" + rc?.quoteCurrencyCode + ")")
                    }
                    c++;
                    //         rc.name = (rc?.baseCurrencyCode == "" ? rc?.baseCurrencyId : rc?.baseCurrencyCode) + "/" + (rc?.quoteCurrencyCode == "" ? rc?.quoteCurrencyId : rc?.quoteCurrencyCode);

                    return rc;
                }
                ) as any[])

                state.set.items = r;
                //console.log(state.items)

            } else {
                state.set.hasMore = false;
            }
            state.set.isFetching = false

        })
        builder.addCase(getItems.rejected, (state, action) => {
            state.set.isFetching = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getItems.pending, (state, action) => {
            state.set.isFetching = true;
            state.message = undefined;
        })

    }
})

export const { reset, dismissMessage, setSort, setNumberOfResults, resetSet, setSelectedItems, setSearchText, setIsFilteredSet, } = advancedChartPageSlice.actions

export default advancedChartPageSlice.reducer


