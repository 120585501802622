import { DefaultButton, DialogFooter, Icon, IconButton, IPersonaProps, IPickerItemProps, IStackTokens, ISuggestionItemProps, ISuggestionModel, ISuggestionsProps, ITag, Label, MessageBar, MessageBarType, Modal, NormalPeoplePicker, PeoplePickerItem, Persona, PersonaSize, PrimaryButton, Spinner, SpinnerSize, Stack, TagItemSuggestion, TagPicker, Text, TextField, Toggle, TooltipHost, ValidationState } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { FormEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../../app/Hooks";
import { add, dismissMessage, getAccounts, getCurrencies, reset, resetAccountsSet, resetCurrencies, setIsFilteredAccountSet, setIsFilteredCurrenciesSet, setSelectedAccountItems, setSelectedCurrencyItems, setStage1 } from "./FeeCollectionAccountsFormSlice";
import * as Yup from 'yup';
import { DynamicInputField, DynamicNumberInputField, InputField } from "../../../controls/Controls";
import { inputs } from "../../../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../../../app/Api";
import { Message } from "../../../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../../../FormProps";
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../../../common/SessionExpiredDialog/SessionExpiredDialog";
import { formatDate, timestampToDate } from "../../../../../app/Helpers";
import { AuthenticateReply } from "../../../../../repository/UserManagement/authentication_pb";
import { DynamicField } from "../../../../common/DynamicFields/DynamicFields";
import { ExtraField } from "../../../../../repository/Accountant/common_pb";
import { AddFeeCollectionAccountRequest, AddFeeCollectionAccountResponse } from "../../../../../repository/Accountant/fee_pb";
import { TableState } from "../../../../common/Table/TableSate";
import { GetAccountsRequest } from "../../../../../repository/Accountant/account_pb";
import { AccountStatus } from "../../../../../app/Enums";
import { GetCurrenciesRequest } from "../../../../../repository/Accountant/currency_pb";
import { List } from "../../../../common/List/List";
import { Popup } from "../../../../common/Popup/Popup";
import { AccountsPage } from "../../../../pages/Accountant/Accounts/AccountsPage";


let req: AddFeeCollectionAccountRequest;
let getAccountsReq: GetAccountsRequest;

let promise: any;
let getFilterPromise: any;

let org: number = -1;
let current: AuthenticateReply.AsObject;
let currenciesReq: GetCurrenciesRequest;

export const CollectionAccountsForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();
    const [currentAction, setCurrenctAction] = useState(0)

    const state: { isLoading: boolean, message: ApiMessage | undefined, currencies: TableState, stage1: any, accounts: TableState } = useAppSelector((state) => {

        return {
            isLoading: state.feeCollectionAccountsForm.isLoading, message: state.feeCollectionAccountsForm.message, stage1: state.feeCollectionAccountsForm.stage1
            , accounts: state.feeCollectionAccountsForm.accounts,
            currencies: state.feeCollectionAccountsForm.currencies,

        }
    })

    useEffect(() => {
        var l = searchParams.get('org');
        req = new AddFeeCollectionAccountRequest();
        getAccountsReq = new GetAccountsRequest();
        currenciesReq = new GetCurrenciesRequest();
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                getAccountsReq.setOrganization(wrapper)
                currenciesReq.setOrganization(wrapper)
            }
        }
        const boolv = new BoolValue();
        boolv.setValue(true);
        const stat = new Int32Value();
        stat.setValue(AccountStatus.ACTIVE);
        getAccountsReq.setNumofresults(state.accounts.numberOfResults)
        getAccountsReq.setOrder(state.accounts.isDescending)
        getAccountsReq.setStatus(stat)
        currenciesReq.setNumofresults(state.currencies.numberOfResults)
        currenciesReq.setOrder(state.currencies.isDescending)
        currenciesReq.setIsavailable(boolv)
        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        }
        return () => { //clean up
            promise?.abort();
            getFilterPromise?.abort();
            dispatch(reset());
        }
    }, [])


    const currenciesSuggestionsProps: ISuggestionsProps<ITag> = {
        suggestionsHeaderText: t('currencies'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.currencies.hasMore && !state.currencies.isFetching,
        onRenderSuggestion: (props: ITag, suggestionItemProps: ISuggestionItemProps<ITag>) => {
            return <TagItemSuggestion>
                {props?.name ?? props?.key}
            </TagItemSuggestion>
        },
        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.currencies.items.findIndex(e => e.id == item.key)
            if (u >= 0) {
                dispatch(setSelectedCurrencyItems([state.currencies.items.at(u)]))
                dispatch(setSelectedAccountItems([]))

            }

        },

        onGetMoreResults: () => {
            if (state.currencies.items.length > 0 && !state.currencies.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.currencies.items.at(state.currencies.items.length - 1).id);
                currenciesReq.setNextto(wrapper)
                getFilterPromise = dispatch(getCurrencies({ body: currenciesReq, headers: getHeaders() }))
            }
        },
        suggestions: state.currencies.items.length > 0 ? state.currencies.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : [],
        isLoading: state.currencies.items.length == 0 ? state.currencies.isFetching : false,
        isSearching: state.currencies.items.length > 0 ? state.currencies.isFetching : false,


    };
    const onCurrenciesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetCurrencies())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        currenciesReq.setSearch(wrapper)
        currenciesReq.setNextto(undefined)
        dispatch(setIsFilteredCurrenciesSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getCurrencies({ body: currenciesReq, headers: getHeaders() }))
        return [];

    }


    const accountsSuggestionsProps: ISuggestionsProps<IPersonaProps> = {
        suggestionsHeaderText: t('accounts'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.accounts.hasMore && !state.accounts.isFetching,
        onRenderSuggestion: (props: IPersonaProps) => {


            return <Persona {...props} size={PersonaSize.size32} showSecondaryText={true} styles={{ root: { margin: 8 } }} />;
        },

        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.accounts.items.findIndex(e => e.internalId == item.key)
            if (u >= 0) {
                dispatch(setSelectedAccountItems([state.accounts.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.accounts.items.length > 0 && !state.accounts.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.accounts.items.at(state.accounts.items.length - 1).internalId);
                getAccountsReq.setNextto(wrapper)
                getFilterPromise = dispatch(getAccounts({ body: getAccountsReq, headers: getHeaders() }))
            }
        },
        suggestions: state.accounts.items.length > 0 ? state.accounts.items.map(e => { return { item: { key: e.internalId, text: e.name, secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } } as ISuggestionModel<IPersonaProps> }) : [],
        isLoading: state.accounts.items.length == 0 ? state.accounts.isFetching : false,
        isSearching: state.accounts.items.length > 0 ? state.accounts.isFetching : false,


    };


    const onAccountsFilterChanged = (filterText: string, selectedItems?: IPersonaProps[]): IPersonaProps[] => {

        dispatch(resetAccountsSet())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        getAccountsReq.setSearch(wrapper)
        getAccountsReq.setNextto(undefined)
        dispatch(setIsFilteredAccountSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getAccounts({ body: getAccountsReq, headers: getHeaders() }))
        return [];

    }



    return (
        <>
            <Popup isOpen={(currentAction == 100)} title={t("accounts")} onDismiss={() => { setCurrenctAction(0) }} >

                <AccountsPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedAccountItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Formik
                enableReinitialize={true}
                initialValues={{

                    account: undefined,
                    isActive: (props.renderObject) ? props.renderObject.isActive : true,

                }}

                validationSchema={Yup.object({




                })}

                onSubmit={(values, actions) => {
                    if (props.type == FormType.ADD) {
                        let account = state.accounts.selected.at(0);

                        req.setAccount(account.internalId);
                        req.setIsactive(values.isActive)
                        req.setId(props.renderObject?.id)

                        promise = dispatch(add({ body: req, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: AddFeeCollectionAccountResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            id: account?.internalId,
                                            name: account?.name,
                                            createdDate: formatDate(timestampToDate(res.success?.addeddate?.seconds, res.success?.addeddate?.nanos)),
                                            addedBy: (current.profile?.firstname?.value ?? "") + " " + (current.profile?.lastname?.value ?? ""),
                                            addedById: current.profile?.id?.value,
                                            isActive: values.isActive,
                                            currencyId: account?.currencyId,
                                            currencyName: account?.currencyName,
                                            currencyCode: account?.currencyCode,
                                            currencyDecimalPlaces: account?.currencyDecimalPlaces,
                                            currencyForm: account?.currencyForm,
                                            currencySymbol: account?.currencySymbol,

                                        });
                                    }
                                }
                                actions.setSubmitting(false)
                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    } else {

                    }
                }}

            >
                {formkikProps => (
                    <Form >
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? (state.message.data != 401) ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            /> :
                                <SessionExpiredDialog />
                                : null
                            }


                            {props.type == FormType.ADD ? <Stack>
                                <Label required disabled={state.isLoading}>{t("account")}</Label>
                                <List
                                    isPeoplePicker={true}
                                    suggestionsHeaderText={t('accounts')}
                                    isLoading={state.accounts.items.length == 0 ? state.accounts.isFetching : false}
                                    isSearching={state.accounts.items.length > 0 ? state.accounts.isFetching : false}
                                    moreSuggestionsAvailable={state.accounts.hasMore && !state.accounts.isFetching}
                                    suggestions={state.accounts.items.length > 0 ? state.accounts.items.map(e => { return { item: { key: e.internalId, text: e.name + " (#" + e.id +")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } } as ISuggestionModel<IPersonaProps> }) : []}
                                    onGetMoreResults={() => {
                                        if (state.accounts.items.length > 0 && !state.accounts.isFetching) {
                                            const wrapper = new StringValue();
                                            wrapper.setValue(state.accounts.items.at(state.accounts.items.length - 1).internalId);
                                            getAccountsReq.setNextto(wrapper)
                                            getFilterPromise = dispatch(getAccounts({ body: getAccountsReq, headers: getHeaders() }))
                                        }
                                    }}
                                    onSuggestionClick={(ev?, item?: any, index?: number) => {
                                        var u = state.accounts.items.findIndex(e => e.internalId == item.key)
                                        if (u >= 0) {
                                            dispatch(setSelectedAccountItems([state.accounts.items.at(u)]))
                                        }

                                    }}
                                    inputProps={{ placeholder: t("account"), required: true }}

                                    disabled={state.isLoading}
                                    selectedItems={state.accounts.selected.length > 0 ? state.accounts.selected.map(e => { return { key: e.internalId, text: e.name + " (#" + e.id +")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } as IPersonaProps }) : []}
                                    onChange={(e) => {
                                        dispatch(setSelectedAccountItems([]))
                                    }}
                                    onEmptyInputFocus={() => {
                                        dispatch(resetAccountsSet())
                                        getAccountsReq.setSearch(undefined)
                                        getAccountsReq.setNextto(undefined)
                                        dispatch(setIsFilteredAccountSet(false))
                                        getFilterPromise?.abort()
                                        getFilterPromise = dispatch(getAccounts({ body: getAccountsReq, headers: getHeaders() }))

                                    }}
                                    onFilterChanged={onAccountsFilterChanged}
                                    endButtons={[
                                        {
                                            title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                            onClick: () => {
                                                setCurrenctAction(100);

                                            }
                                        }]}
                                />

                            </Stack> : undefined}
                            {props.type == FormType.ADD ? <Toggle

                                onText={t("active")} offText={t("inactive")}
                                label={t("status")}
                                disabled={state.isLoading}

                                checked={formkikProps.values.isActive}
                                onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                                    formkikProps.setFieldValue('isActive', checked);
                                }
                                }
                            /> : undefined}

                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("add") : t("edit")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={props.type == FormType.ADD ? t("discard") : t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>

                    </Form>
                )}
            </Formik>
        </>
    );

}

