import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError } from 'grpc-web';
import { RoleEndpointClient } from '../../../../repository/UserManagement/Managers/RoleServiceClientPb';
import { AddRoleRequest, AddRoleResponse, UpdateRoleRequest, UpdateRoleResponse } from '../../../../repository/UserManagement/Managers/role_pb';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, refreshSession, getSession, ACCOUNTANT_API_URL, TRADING_API_URL } from '../../../../app/Api';
import { AddCategoryRequest, AddCategoryResponse, UpdateCategoryRequest, UpdateCategoryResponse, GetCategoryRequest, GetCategoryResponse, GetCategoriesResponse, GetCategoriesRequest } from '../../../../repository/Trading/category_pb';
import { formatDate, timestampToDate } from '../../../../app/Helpers';
import { DynamicField } from '../../../common/DynamicFields/DynamicFields';
import { CategoryEndpointClient } from '../../../../repository/Trading/CategoryServiceClientPb';
import { TableState } from '../../../common/Table/TableSate';
export interface CategoriesFormState {
    isLoading: boolean
    message?: ApiMessage
    stage1: any,
    categories: TableState,

}

const initialState: CategoriesFormState = {
    isLoading: false,
    message: undefined,
    stage1: undefined,
    categories: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },

}

const client = new CategoryEndpointClient(TRADING_API_URL, null, null);
export const add = createAsyncThunk<AddCategoryResponse.AsObject, APIRequest<AddCategoryRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/trading/categories/add',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.add(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)


export const update = createAsyncThunk<UpdateCategoryResponse.AsObject, APIRequest<UpdateCategoryRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/trading/category/update',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.update(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)



export const get = createAsyncThunk<GetCategoryResponse.AsObject, APIRequest<GetCategoryRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/trading/category/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getCategory(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)

export const getCategories = createAsyncThunk<GetCategoriesResponse.AsObject, APIRequest<GetCategoriesRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/trading/category/getCategories',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await client.getCategories(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const categoriesFormSlice = createSlice({
    name: 'forms/trading/categories',
    initialState,
    reducers: {

        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.isLoading = false;
            state.message = undefined;
            state.stage1 = initialState.stage1;
            state.categories = initialState.categories;

        },
        setStage1: (state, action: PayloadAction<any>) => {
            let stage1 = {
                id: action.payload?.id,
                nameAr: action.payload?.nameAr,
                nameEn: action.payload?.nameEn,
                rank: action.payload?.rank,
                isAvailable: action.payload?.isAvailable,
                createdDate: action.payload?.createdDate,
                addedBy: action.payload?.addedBy,
                addedById: action.payload?.addedById,
                parentCategoryId: action.payload.parentCategoryId,
                parentCategoryNameAr: action.payload.parentCategoryNameAr,
                parentCategoryNameEn: action.payload.parentCategoryNameEn,
                iconSource: action.payload.iconSource,

            }
            state.stage1 = stage1;
        },
        
        resetCategories: (state) => {
            state.categories.items = [];
            state.categories.hasMore = true;
        },
        setSelectedCategoryItems: (state, action: PayloadAction<any[]>) => {
            state.categories.selected = action.payload;
        },

     
        setIsFilteredCategoriesSet: (state, action: PayloadAction<boolean>) => {
            state.categories.isFilteredSet = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(add.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            if (payload)
                state.message = toApiMessage(payload.success?.message);

        })
        builder.addCase(add.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(add.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })
        builder.addCase(update.fulfilled, (state, { payload }) => {
            if (payload)
                state.message = toApiMessage(payload.success?.message);
            state.isLoading = false;
        })
        builder.addCase(update.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(update.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })

        builder.addCase(get.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {

                let r = {
                    id: payload?.success?.id,
                    nameAr: payload?.success?.namear?.value ?? "",
                    nameEn: payload?.success?.nameen?.value ?? "",
                    rank: payload?.success?.rank?.value,
                    isAvailable: payload?.success?.isavailable?.value ?? undefined,
                    createdDate: formatDate(timestampToDate(payload?.success?.createddate?.seconds, payload?.success?.createddate?.nanos)),
                    addedBy: (payload?.success?.createdby?.firstname?.value ?? "") + " " + (payload?.success?.createdby?.lastname?.value ?? ""),
                    addedById: payload?.success?.createdby?.id?.value,
                    parentCategoryId: payload?.success?.parent?.id?.value,
                    parentCategoryNameAr: payload?.success?.parent?.namear?.value ?? "",
                    parentCategoryNameEn: payload?.success?.parent?.nameen?.value ?? "",
                    iconSource: payload?.success?.iconsource?.value,
                }



                state.stage1 = r
            }
        })
        builder.addCase(get.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(get.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })

        
        builder.addCase(getCategories.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.categoriesList.map(val => {
                    return {
                        id: val.id,
                        nameAr: val.namear?.value ?? "",
                        nameEn: val.nameen?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        rank: val.rank?.value,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                        parentCategoryId: val?.parent?.id?.value ,
                        parentCategoryNameAr: val?.parent?.namear?.value ?? "",
                        parentCategoryNameEn: val?.parent?.nameen?.value ?? "",
                        iconSource: val?.iconsource?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.categories.numberOfResults) {
                    state.categories.hasMore = false;
                }
                state.categories.items = state.categories.items.concat(r);
                //console.log(state.items)

            } else {
                state.categories.hasMore = false;
            }
            state.categories.isFetching = false

        })
        builder.addCase(getCategories.rejected, (state, action) => {
            state.categories.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getCategories.pending, (state, action) => {
            state.categories.isFetching = true;
            //state.message = undefined;
        })

    }
})

export const { reset, dismissMessage, setStage1, resetCategories, setIsFilteredCategoriesSet, setSelectedCategoryItems } = categoriesFormSlice.actions

export default categoriesFormSlice.reducer


