// source: account.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('./common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.account_endpoint.Account', null, global);
goog.exportSymbol('proto.account_endpoint.AccountSettingResponse', null, global);
goog.exportSymbol('proto.account_endpoint.CloseAccountReplay', null, global);
goog.exportSymbol('proto.account_endpoint.CloseAccountRequest', null, global);
goog.exportSymbol('proto.account_endpoint.CloseAccountResponse', null, global);
goog.exportSymbol('proto.account_endpoint.CloseAccountResponse.ResultCase', null, global);
goog.exportSymbol('proto.account_endpoint.GetAccountReplay', null, global);
goog.exportSymbol('proto.account_endpoint.GetAccountRequest', null, global);
goog.exportSymbol('proto.account_endpoint.GetAccountResponse', null, global);
goog.exportSymbol('proto.account_endpoint.GetAccountResponse.ResultCase', null, global);
goog.exportSymbol('proto.account_endpoint.GetAccountsReplay', null, global);
goog.exportSymbol('proto.account_endpoint.GetAccountsRequest', null, global);
goog.exportSymbol('proto.account_endpoint.GetAccountsResponse', null, global);
goog.exportSymbol('proto.account_endpoint.GetAccountsResponse.ResultCase', null, global);
goog.exportSymbol('proto.account_endpoint.IdentityResponse', null, global);
goog.exportSymbol('proto.account_endpoint.LinkReplay', null, global);
goog.exportSymbol('proto.account_endpoint.LinkRequest', null, global);
goog.exportSymbol('proto.account_endpoint.LinkResponse', null, global);
goog.exportSymbol('proto.account_endpoint.LinkResponse.ResultCase', null, global);
goog.exportSymbol('proto.account_endpoint.OpenAccountReplay', null, global);
goog.exportSymbol('proto.account_endpoint.OpenAccountRequest', null, global);
goog.exportSymbol('proto.account_endpoint.OpenAccountResponse', null, global);
goog.exportSymbol('proto.account_endpoint.OpenAccountResponse.ResultCase', null, global);
goog.exportSymbol('proto.account_endpoint.OwnerResponse', null, global);
goog.exportSymbol('proto.account_endpoint.RevokeLinkRequest', null, global);
goog.exportSymbol('proto.account_endpoint.TransferAccountOwnershipReplay', null, global);
goog.exportSymbol('proto.account_endpoint.TransferAccountOwnershipRequest', null, global);
goog.exportSymbol('proto.account_endpoint.TransferAccountOwnershipResponse', null, global);
goog.exportSymbol('proto.account_endpoint.TransferAccountOwnershipResponse.ResultCase', null, global);
goog.exportSymbol('proto.account_endpoint.UpdateAccountLimitationReplay', null, global);
goog.exportSymbol('proto.account_endpoint.UpdateAccountLimitationRequest', null, global);
goog.exportSymbol('proto.account_endpoint.UpdateAccountLimitationResponse', null, global);
goog.exportSymbol('proto.account_endpoint.UpdateAccountLimitationResponse.ResultCase', null, global);
goog.exportSymbol('proto.account_endpoint.UpdateAccountReplay', null, global);
goog.exportSymbol('proto.account_endpoint.UpdateAccountRequest', null, global);
goog.exportSymbol('proto.account_endpoint.UpdateAccountResponse', null, global);
goog.exportSymbol('proto.account_endpoint.UpdateAccountResponse.ResultCase', null, global);
goog.exportSymbol('proto.account_endpoint.UpdateAccountStatusReplay', null, global);
goog.exportSymbol('proto.account_endpoint.UpdateAccountStatusRequest', null, global);
goog.exportSymbol('proto.account_endpoint.UpdateAccountStatusResponse', null, global);
goog.exportSymbol('proto.account_endpoint.UpdateAccountStatusResponse.ResultCase', null, global);
goog.exportSymbol('proto.account_endpoint.UpdateAccountTypeReplay', null, global);
goog.exportSymbol('proto.account_endpoint.UpdateAccountTypeRequest', null, global);
goog.exportSymbol('proto.account_endpoint.UpdateAccountTypeResponse', null, global);
goog.exportSymbol('proto.account_endpoint.UpdateAccountTypeResponse.ResultCase', null, global);
goog.exportSymbol('proto.account_endpoint.UpdateAuthorizationRequirementReplay', null, global);
goog.exportSymbol('proto.account_endpoint.UpdateAuthorizationRequirementRequest', null, global);
goog.exportSymbol('proto.account_endpoint.UpdateAuthorizationRequirementResponse', null, global);
goog.exportSymbol('proto.account_endpoint.UpdateAuthorizationRequirementResponse.ResultCase', null, global);
goog.exportSymbol('proto.account_endpoint.UpdateTransferControlReplay', null, global);
goog.exportSymbol('proto.account_endpoint.UpdateTransferControlRequest', null, global);
goog.exportSymbol('proto.account_endpoint.UpdateTransferControlResponse', null, global);
goog.exportSymbol('proto.account_endpoint.UpdateTransferControlResponse.ResultCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.OpenAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account_endpoint.OpenAccountRequest.repeatedFields_, null);
};
goog.inherits(proto.account_endpoint.OpenAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.OpenAccountRequest.displayName = 'proto.account_endpoint.OpenAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.UpdateAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account_endpoint.UpdateAccountRequest.repeatedFields_, null);
};
goog.inherits(proto.account_endpoint.UpdateAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.UpdateAccountRequest.displayName = 'proto.account_endpoint.UpdateAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.UpdateAccountStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_endpoint.UpdateAccountStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.UpdateAccountStatusRequest.displayName = 'proto.account_endpoint.UpdateAccountStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.UpdateTransferControlRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_endpoint.UpdateTransferControlRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.UpdateTransferControlRequest.displayName = 'proto.account_endpoint.UpdateTransferControlRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.CloseAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_endpoint.CloseAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.CloseAccountRequest.displayName = 'proto.account_endpoint.CloseAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.UpdateAccountLimitationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_endpoint.UpdateAccountLimitationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.UpdateAccountLimitationRequest.displayName = 'proto.account_endpoint.UpdateAccountLimitationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.UpdateAuthorizationRequirementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_endpoint.UpdateAuthorizationRequirementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.UpdateAuthorizationRequirementRequest.displayName = 'proto.account_endpoint.UpdateAuthorizationRequirementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.UpdateAccountTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_endpoint.UpdateAccountTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.UpdateAccountTypeRequest.displayName = 'proto.account_endpoint.UpdateAccountTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.TransferAccountOwnershipRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_endpoint.TransferAccountOwnershipRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.TransferAccountOwnershipRequest.displayName = 'proto.account_endpoint.TransferAccountOwnershipRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.GetAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_endpoint.GetAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.GetAccountRequest.displayName = 'proto.account_endpoint.GetAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.GetAccountsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_endpoint.GetAccountsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.GetAccountsRequest.displayName = 'proto.account_endpoint.GetAccountsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.LinkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account_endpoint.LinkRequest.repeatedFields_, null);
};
goog.inherits(proto.account_endpoint.LinkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.LinkRequest.displayName = 'proto.account_endpoint.LinkRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.RevokeLinkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_endpoint.RevokeLinkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.RevokeLinkRequest.displayName = 'proto.account_endpoint.RevokeLinkRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.OpenAccountReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_endpoint.OpenAccountReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.OpenAccountReplay.displayName = 'proto.account_endpoint.OpenAccountReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.UpdateAccountReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_endpoint.UpdateAccountReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.UpdateAccountReplay.displayName = 'proto.account_endpoint.UpdateAccountReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.UpdateAccountStatusReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_endpoint.UpdateAccountStatusReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.UpdateAccountStatusReplay.displayName = 'proto.account_endpoint.UpdateAccountStatusReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.LinkReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_endpoint.LinkReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.LinkReplay.displayName = 'proto.account_endpoint.LinkReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.UpdateTransferControlReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_endpoint.UpdateTransferControlReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.UpdateTransferControlReplay.displayName = 'proto.account_endpoint.UpdateTransferControlReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.CloseAccountReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_endpoint.CloseAccountReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.CloseAccountReplay.displayName = 'proto.account_endpoint.CloseAccountReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.UpdateAuthorizationRequirementReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_endpoint.UpdateAuthorizationRequirementReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.UpdateAuthorizationRequirementReplay.displayName = 'proto.account_endpoint.UpdateAuthorizationRequirementReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.UpdateAccountLimitationReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_endpoint.UpdateAccountLimitationReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.UpdateAccountLimitationReplay.displayName = 'proto.account_endpoint.UpdateAccountLimitationReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.UpdateAccountTypeReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_endpoint.UpdateAccountTypeReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.UpdateAccountTypeReplay.displayName = 'proto.account_endpoint.UpdateAccountTypeReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.TransferAccountOwnershipReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_endpoint.TransferAccountOwnershipReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.TransferAccountOwnershipReplay.displayName = 'proto.account_endpoint.TransferAccountOwnershipReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.IdentityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_endpoint.IdentityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.IdentityResponse.displayName = 'proto.account_endpoint.IdentityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.AccountSettingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_endpoint.AccountSettingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.AccountSettingResponse.displayName = 'proto.account_endpoint.AccountSettingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.OwnerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_endpoint.OwnerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.OwnerResponse.displayName = 'proto.account_endpoint.OwnerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.GetAccountReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account_endpoint.GetAccountReplay.repeatedFields_, null);
};
goog.inherits(proto.account_endpoint.GetAccountReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.GetAccountReplay.displayName = 'proto.account_endpoint.GetAccountReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.Account = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_endpoint.Account, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.Account.displayName = 'proto.account_endpoint.Account';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.GetAccountsReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account_endpoint.GetAccountsReplay.repeatedFields_, null);
};
goog.inherits(proto.account_endpoint.GetAccountsReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.GetAccountsReplay.displayName = 'proto.account_endpoint.GetAccountsReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.OpenAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_endpoint.OpenAccountResponse.oneofGroups_);
};
goog.inherits(proto.account_endpoint.OpenAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.OpenAccountResponse.displayName = 'proto.account_endpoint.OpenAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.UpdateAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_endpoint.UpdateAccountResponse.oneofGroups_);
};
goog.inherits(proto.account_endpoint.UpdateAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.UpdateAccountResponse.displayName = 'proto.account_endpoint.UpdateAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.UpdateAccountStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_endpoint.UpdateAccountStatusResponse.oneofGroups_);
};
goog.inherits(proto.account_endpoint.UpdateAccountStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.UpdateAccountStatusResponse.displayName = 'proto.account_endpoint.UpdateAccountStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.CloseAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_endpoint.CloseAccountResponse.oneofGroups_);
};
goog.inherits(proto.account_endpoint.CloseAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.CloseAccountResponse.displayName = 'proto.account_endpoint.CloseAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.UpdateAccountLimitationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_endpoint.UpdateAccountLimitationResponse.oneofGroups_);
};
goog.inherits(proto.account_endpoint.UpdateAccountLimitationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.UpdateAccountLimitationResponse.displayName = 'proto.account_endpoint.UpdateAccountLimitationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.UpdateAccountTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_endpoint.UpdateAccountTypeResponse.oneofGroups_);
};
goog.inherits(proto.account_endpoint.UpdateAccountTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.UpdateAccountTypeResponse.displayName = 'proto.account_endpoint.UpdateAccountTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.GetAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_endpoint.GetAccountResponse.oneofGroups_);
};
goog.inherits(proto.account_endpoint.GetAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.GetAccountResponse.displayName = 'proto.account_endpoint.GetAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.GetAccountsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_endpoint.GetAccountsResponse.oneofGroups_);
};
goog.inherits(proto.account_endpoint.GetAccountsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.GetAccountsResponse.displayName = 'proto.account_endpoint.GetAccountsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.TransferAccountOwnershipResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_endpoint.TransferAccountOwnershipResponse.oneofGroups_);
};
goog.inherits(proto.account_endpoint.TransferAccountOwnershipResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.TransferAccountOwnershipResponse.displayName = 'proto.account_endpoint.TransferAccountOwnershipResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.UpdateAuthorizationRequirementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_endpoint.UpdateAuthorizationRequirementResponse.oneofGroups_);
};
goog.inherits(proto.account_endpoint.UpdateAuthorizationRequirementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.UpdateAuthorizationRequirementResponse.displayName = 'proto.account_endpoint.UpdateAuthorizationRequirementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.LinkResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_endpoint.LinkResponse.oneofGroups_);
};
goog.inherits(proto.account_endpoint.LinkResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.LinkResponse.displayName = 'proto.account_endpoint.LinkResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_endpoint.UpdateTransferControlResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.account_endpoint.UpdateTransferControlResponse.oneofGroups_);
};
goog.inherits(proto.account_endpoint.UpdateTransferControlResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_endpoint.UpdateTransferControlResponse.displayName = 'proto.account_endpoint.UpdateTransferControlResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account_endpoint.OpenAccountRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.OpenAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.OpenAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.OpenAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.OpenAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accounttype: jspb.Message.getFieldWithDefault(msg, 3, ""),
    owner: jspb.Message.getFieldWithDefault(msg, 4, ""),
    code: jspb.Message.getFieldWithDefault(msg, 5, ""),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    limitation: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.OpenAccountRequest}
 */
proto.account_endpoint.OpenAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.OpenAccountRequest;
  return proto.account_endpoint.OpenAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.OpenAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.OpenAccountRequest}
 */
proto.account_endpoint.OpenAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccounttype(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwner(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 6:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLimitation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.OpenAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.OpenAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.OpenAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.OpenAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccounttype();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOwner();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getLimitation();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.account_endpoint.OpenAccountRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_endpoint.OpenAccountRequest} returns this
 */
proto.account_endpoint.OpenAccountRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string currency = 2;
 * @return {string}
 */
proto.account_endpoint.OpenAccountRequest.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_endpoint.OpenAccountRequest} returns this
 */
proto.account_endpoint.OpenAccountRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string accountType = 3;
 * @return {string}
 */
proto.account_endpoint.OpenAccountRequest.prototype.getAccounttype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_endpoint.OpenAccountRequest} returns this
 */
proto.account_endpoint.OpenAccountRequest.prototype.setAccounttype = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string owner = 4;
 * @return {string}
 */
proto.account_endpoint.OpenAccountRequest.prototype.getOwner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_endpoint.OpenAccountRequest} returns this
 */
proto.account_endpoint.OpenAccountRequest.prototype.setOwner = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string code = 5;
 * @return {string}
 */
proto.account_endpoint.OpenAccountRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_endpoint.OpenAccountRequest} returns this
 */
proto.account_endpoint.OpenAccountRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated common.ExtraField extraFields = 6;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.account_endpoint.OpenAccountRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 6));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.account_endpoint.OpenAccountRequest} returns this
*/
proto.account_endpoint.OpenAccountRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.account_endpoint.OpenAccountRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account_endpoint.OpenAccountRequest} returns this
 */
proto.account_endpoint.OpenAccountRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};


/**
 * optional google.protobuf.Int32Value organization = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.account_endpoint.OpenAccountRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.account_endpoint.OpenAccountRequest} returns this
*/
proto.account_endpoint.OpenAccountRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.OpenAccountRequest} returns this
 */
proto.account_endpoint.OpenAccountRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.OpenAccountRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string limitation = 8;
 * @return {string}
 */
proto.account_endpoint.OpenAccountRequest.prototype.getLimitation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_endpoint.OpenAccountRequest} returns this
 */
proto.account_endpoint.OpenAccountRequest.prototype.setLimitation = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account_endpoint.UpdateAccountRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.UpdateAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.UpdateAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.UpdateAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    code: jspb.Message.getFieldWithDefault(msg, 3, ""),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.UpdateAccountRequest}
 */
proto.account_endpoint.UpdateAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.UpdateAccountRequest;
  return proto.account_endpoint.UpdateAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.UpdateAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.UpdateAccountRequest}
 */
proto.account_endpoint.UpdateAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 4:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.UpdateAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.UpdateAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.UpdateAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.account_endpoint.UpdateAccountRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_endpoint.UpdateAccountRequest} returns this
 */
proto.account_endpoint.UpdateAccountRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.account_endpoint.UpdateAccountRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_endpoint.UpdateAccountRequest} returns this
 */
proto.account_endpoint.UpdateAccountRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.account_endpoint.UpdateAccountRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_endpoint.UpdateAccountRequest} returns this
 */
proto.account_endpoint.UpdateAccountRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated common.ExtraField extraFields = 4;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.account_endpoint.UpdateAccountRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 4));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.account_endpoint.UpdateAccountRequest} returns this
*/
proto.account_endpoint.UpdateAccountRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.account_endpoint.UpdateAccountRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account_endpoint.UpdateAccountRequest} returns this
 */
proto.account_endpoint.UpdateAccountRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.UpdateAccountStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.UpdateAccountStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.UpdateAccountStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateAccountStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.UpdateAccountStatusRequest}
 */
proto.account_endpoint.UpdateAccountStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.UpdateAccountStatusRequest;
  return proto.account_endpoint.UpdateAccountStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.UpdateAccountStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.UpdateAccountStatusRequest}
 */
proto.account_endpoint.UpdateAccountStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.UpdateAccountStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.UpdateAccountStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.UpdateAccountStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateAccountStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.account_endpoint.UpdateAccountStatusRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_endpoint.UpdateAccountStatusRequest} returns this
 */
proto.account_endpoint.UpdateAccountStatusRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 status = 2;
 * @return {number}
 */
proto.account_endpoint.UpdateAccountStatusRequest.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.account_endpoint.UpdateAccountStatusRequest} returns this
 */
proto.account_endpoint.UpdateAccountStatusRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.UpdateTransferControlRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.UpdateTransferControlRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.UpdateTransferControlRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateTransferControlRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tansfercontrol: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.UpdateTransferControlRequest}
 */
proto.account_endpoint.UpdateTransferControlRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.UpdateTransferControlRequest;
  return proto.account_endpoint.UpdateTransferControlRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.UpdateTransferControlRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.UpdateTransferControlRequest}
 */
proto.account_endpoint.UpdateTransferControlRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTansfercontrol(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.UpdateTransferControlRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.UpdateTransferControlRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.UpdateTransferControlRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateTransferControlRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTansfercontrol();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.account_endpoint.UpdateTransferControlRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_endpoint.UpdateTransferControlRequest} returns this
 */
proto.account_endpoint.UpdateTransferControlRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 transferControl = 2;
 * @return {number}
 */
proto.account_endpoint.UpdateTransferControlRequest.prototype.getTansfercontrol = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.account_endpoint.UpdateTransferControlRequest} returns this
 */
proto.account_endpoint.UpdateTransferControlRequest.prototype.setTansfercontrol = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.CloseAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.CloseAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.CloseAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.CloseAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.CloseAccountRequest}
 */
proto.account_endpoint.CloseAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.CloseAccountRequest;
  return proto.account_endpoint.CloseAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.CloseAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.CloseAccountRequest}
 */
proto.account_endpoint.CloseAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.CloseAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.CloseAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.CloseAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.CloseAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.account_endpoint.CloseAccountRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_endpoint.CloseAccountRequest} returns this
 */
proto.account_endpoint.CloseAccountRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.UpdateAccountLimitationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.UpdateAccountLimitationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.UpdateAccountLimitationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateAccountLimitationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    limitation: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.UpdateAccountLimitationRequest}
 */
proto.account_endpoint.UpdateAccountLimitationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.UpdateAccountLimitationRequest;
  return proto.account_endpoint.UpdateAccountLimitationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.UpdateAccountLimitationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.UpdateAccountLimitationRequest}
 */
proto.account_endpoint.UpdateAccountLimitationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLimitation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.UpdateAccountLimitationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.UpdateAccountLimitationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.UpdateAccountLimitationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateAccountLimitationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLimitation();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.account_endpoint.UpdateAccountLimitationRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_endpoint.UpdateAccountLimitationRequest} returns this
 */
proto.account_endpoint.UpdateAccountLimitationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string limitation = 2;
 * @return {string}
 */
proto.account_endpoint.UpdateAccountLimitationRequest.prototype.getLimitation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_endpoint.UpdateAccountLimitationRequest} returns this
 */
proto.account_endpoint.UpdateAccountLimitationRequest.prototype.setLimitation = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.UpdateAuthorizationRequirementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.UpdateAuthorizationRequirementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.UpdateAuthorizationRequirementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateAuthorizationRequirementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isauthorizationrequired: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    authcode: (f = msg.getAuthcode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.UpdateAuthorizationRequirementRequest}
 */
proto.account_endpoint.UpdateAuthorizationRequirementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.UpdateAuthorizationRequirementRequest;
  return proto.account_endpoint.UpdateAuthorizationRequirementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.UpdateAuthorizationRequirementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.UpdateAuthorizationRequirementRequest}
 */
proto.account_endpoint.UpdateAuthorizationRequirementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsauthorizationrequired(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuthcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.UpdateAuthorizationRequirementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.UpdateAuthorizationRequirementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.UpdateAuthorizationRequirementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateAuthorizationRequirementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsauthorizationrequired();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getAuthcode();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.account_endpoint.UpdateAuthorizationRequirementRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_endpoint.UpdateAuthorizationRequirementRequest} returns this
 */
proto.account_endpoint.UpdateAuthorizationRequirementRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool isAuthorizationRequired = 2;
 * @return {boolean}
 */
proto.account_endpoint.UpdateAuthorizationRequirementRequest.prototype.getIsauthorizationrequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account_endpoint.UpdateAuthorizationRequirementRequest} returns this
 */
proto.account_endpoint.UpdateAuthorizationRequirementRequest.prototype.setIsauthorizationrequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional google.protobuf.StringValue authCode = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_endpoint.UpdateAuthorizationRequirementRequest.prototype.getAuthcode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_endpoint.UpdateAuthorizationRequirementRequest} returns this
*/
proto.account_endpoint.UpdateAuthorizationRequirementRequest.prototype.setAuthcode = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.UpdateAuthorizationRequirementRequest} returns this
 */
proto.account_endpoint.UpdateAuthorizationRequirementRequest.prototype.clearAuthcode = function() {
  return this.setAuthcode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.UpdateAuthorizationRequirementRequest.prototype.hasAuthcode = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.UpdateAccountTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.UpdateAccountTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.UpdateAccountTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateAccountTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accounttype: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.UpdateAccountTypeRequest}
 */
proto.account_endpoint.UpdateAccountTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.UpdateAccountTypeRequest;
  return proto.account_endpoint.UpdateAccountTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.UpdateAccountTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.UpdateAccountTypeRequest}
 */
proto.account_endpoint.UpdateAccountTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccounttype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.UpdateAccountTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.UpdateAccountTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.UpdateAccountTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateAccountTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccounttype();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.account_endpoint.UpdateAccountTypeRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_endpoint.UpdateAccountTypeRequest} returns this
 */
proto.account_endpoint.UpdateAccountTypeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string accountType = 2;
 * @return {string}
 */
proto.account_endpoint.UpdateAccountTypeRequest.prototype.getAccounttype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_endpoint.UpdateAccountTypeRequest} returns this
 */
proto.account_endpoint.UpdateAccountTypeRequest.prototype.setAccounttype = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.TransferAccountOwnershipRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.TransferAccountOwnershipRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.TransferAccountOwnershipRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.TransferAccountOwnershipRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    owner: jspb.Message.getFieldWithDefault(msg, 2, ""),
    authcode: (f = msg.getAuthcode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.TransferAccountOwnershipRequest}
 */
proto.account_endpoint.TransferAccountOwnershipRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.TransferAccountOwnershipRequest;
  return proto.account_endpoint.TransferAccountOwnershipRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.TransferAccountOwnershipRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.TransferAccountOwnershipRequest}
 */
proto.account_endpoint.TransferAccountOwnershipRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwner(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuthcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.TransferAccountOwnershipRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.TransferAccountOwnershipRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.TransferAccountOwnershipRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.TransferAccountOwnershipRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwner();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAuthcode();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.account_endpoint.TransferAccountOwnershipRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_endpoint.TransferAccountOwnershipRequest} returns this
 */
proto.account_endpoint.TransferAccountOwnershipRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string owner = 2;
 * @return {string}
 */
proto.account_endpoint.TransferAccountOwnershipRequest.prototype.getOwner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_endpoint.TransferAccountOwnershipRequest} returns this
 */
proto.account_endpoint.TransferAccountOwnershipRequest.prototype.setOwner = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.StringValue authCode = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_endpoint.TransferAccountOwnershipRequest.prototype.getAuthcode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_endpoint.TransferAccountOwnershipRequest} returns this
*/
proto.account_endpoint.TransferAccountOwnershipRequest.prototype.setAuthcode = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.TransferAccountOwnershipRequest} returns this
 */
proto.account_endpoint.TransferAccountOwnershipRequest.prototype.clearAuthcode = function() {
  return this.setAuthcode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.TransferAccountOwnershipRequest.prototype.hasAuthcode = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.GetAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.GetAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.GetAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.GetAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.GetAccountRequest}
 */
proto.account_endpoint.GetAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.GetAccountRequest;
  return proto.account_endpoint.GetAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.GetAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.GetAccountRequest}
 */
proto.account_endpoint.GetAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.GetAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.GetAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.GetAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.GetAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.account_endpoint.GetAccountRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_endpoint.GetAccountRequest} returns this
 */
proto.account_endpoint.GetAccountRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.GetAccountsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.GetAccountsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.GetAccountsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.GetAccountsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    accounttype: (f = msg.getAccounttype()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    currency: (f = msg.getCurrency()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    owner: (f = msg.getOwner()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    limitation: (f = msg.getLimitation()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    code: (f = msg.getCode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isdebtor: (f = msg.getIsdebtor()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    authorizedclient: (f = msg.getAuthorizedclient()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    order: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    nextto: (f = msg.getNextto()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    numofresults: jspb.Message.getFieldWithDefault(msg, 12, 0),
    isauthorizationrequired: (f = msg.getIsauthorizationrequired()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    linkingstate: (f = msg.getLinkingstate()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.GetAccountsRequest}
 */
proto.account_endpoint.GetAccountsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.GetAccountsRequest;
  return proto.account_endpoint.GetAccountsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.GetAccountsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.GetAccountsRequest}
 */
proto.account_endpoint.GetAccountsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccounttype(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLimitation(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCode(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsdebtor(value);
      break;
    case 15:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuthorizedclient(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrder(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNextto(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumofresults(value);
      break;
    case 13:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsauthorizationrequired(value);
      break;
    case 14:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setLinkingstate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.GetAccountsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.GetAccountsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.GetAccountsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.GetAccountsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getAccounttype();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLimitation();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getCode();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsdebtor();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getAuthorizedclient();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getNextto();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNumofresults();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getIsauthorizationrequired();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getLinkingstate();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value organization = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.account_endpoint.GetAccountsRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.account_endpoint.GetAccountsRequest} returns this
*/
proto.account_endpoint.GetAccountsRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.GetAccountsRequest} returns this
 */
proto.account_endpoint.GetAccountsRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.GetAccountsRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue accountType = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_endpoint.GetAccountsRequest.prototype.getAccounttype = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_endpoint.GetAccountsRequest} returns this
*/
proto.account_endpoint.GetAccountsRequest.prototype.setAccounttype = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.GetAccountsRequest} returns this
 */
proto.account_endpoint.GetAccountsRequest.prototype.clearAccounttype = function() {
  return this.setAccounttype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.GetAccountsRequest.prototype.hasAccounttype = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue currency = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_endpoint.GetAccountsRequest.prototype.getCurrency = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_endpoint.GetAccountsRequest} returns this
*/
proto.account_endpoint.GetAccountsRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.GetAccountsRequest} returns this
 */
proto.account_endpoint.GetAccountsRequest.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.GetAccountsRequest.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue owner = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_endpoint.GetAccountsRequest.prototype.getOwner = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_endpoint.GetAccountsRequest} returns this
*/
proto.account_endpoint.GetAccountsRequest.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.GetAccountsRequest} returns this
 */
proto.account_endpoint.GetAccountsRequest.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.GetAccountsRequest.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue limitation = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_endpoint.GetAccountsRequest.prototype.getLimitation = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_endpoint.GetAccountsRequest} returns this
*/
proto.account_endpoint.GetAccountsRequest.prototype.setLimitation = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.GetAccountsRequest} returns this
 */
proto.account_endpoint.GetAccountsRequest.prototype.clearLimitation = function() {
  return this.setLimitation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.GetAccountsRequest.prototype.hasLimitation = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Int32Value status = 6;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.account_endpoint.GetAccountsRequest.prototype.getStatus = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 6));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.account_endpoint.GetAccountsRequest} returns this
*/
proto.account_endpoint.GetAccountsRequest.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.GetAccountsRequest} returns this
 */
proto.account_endpoint.GetAccountsRequest.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.GetAccountsRequest.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue code = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_endpoint.GetAccountsRequest.prototype.getCode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_endpoint.GetAccountsRequest} returns this
*/
proto.account_endpoint.GetAccountsRequest.prototype.setCode = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.GetAccountsRequest} returns this
 */
proto.account_endpoint.GetAccountsRequest.prototype.clearCode = function() {
  return this.setCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.GetAccountsRequest.prototype.hasCode = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.BoolValue isDebtor = 8;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.account_endpoint.GetAccountsRequest.prototype.getIsdebtor = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 8));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.account_endpoint.GetAccountsRequest} returns this
*/
proto.account_endpoint.GetAccountsRequest.prototype.setIsdebtor = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.GetAccountsRequest} returns this
 */
proto.account_endpoint.GetAccountsRequest.prototype.clearIsdebtor = function() {
  return this.setIsdebtor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.GetAccountsRequest.prototype.hasIsdebtor = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue authorizedClient = 15;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_endpoint.GetAccountsRequest.prototype.getAuthorizedclient = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 15));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_endpoint.GetAccountsRequest} returns this
*/
proto.account_endpoint.GetAccountsRequest.prototype.setAuthorizedclient = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.GetAccountsRequest} returns this
 */
proto.account_endpoint.GetAccountsRequest.prototype.clearAuthorizedclient = function() {
  return this.setAuthorizedclient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.GetAccountsRequest.prototype.hasAuthorizedclient = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.StringValue search = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_endpoint.GetAccountsRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_endpoint.GetAccountsRequest} returns this
*/
proto.account_endpoint.GetAccountsRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.GetAccountsRequest} returns this
 */
proto.account_endpoint.GetAccountsRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.GetAccountsRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool order = 10;
 * @return {boolean}
 */
proto.account_endpoint.GetAccountsRequest.prototype.getOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account_endpoint.GetAccountsRequest} returns this
 */
proto.account_endpoint.GetAccountsRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional google.protobuf.StringValue nextTo = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_endpoint.GetAccountsRequest.prototype.getNextto = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_endpoint.GetAccountsRequest} returns this
*/
proto.account_endpoint.GetAccountsRequest.prototype.setNextto = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.GetAccountsRequest} returns this
 */
proto.account_endpoint.GetAccountsRequest.prototype.clearNextto = function() {
  return this.setNextto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.GetAccountsRequest.prototype.hasNextto = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional int32 numOfResults = 12;
 * @return {number}
 */
proto.account_endpoint.GetAccountsRequest.prototype.getNumofresults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.account_endpoint.GetAccountsRequest} returns this
 */
proto.account_endpoint.GetAccountsRequest.prototype.setNumofresults = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional google.protobuf.BoolValue isAuthorizationRequired = 13;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.account_endpoint.GetAccountsRequest.prototype.getIsauthorizationrequired = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 13));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.account_endpoint.GetAccountsRequest} returns this
*/
proto.account_endpoint.GetAccountsRequest.prototype.setIsauthorizationrequired = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.GetAccountsRequest} returns this
 */
proto.account_endpoint.GetAccountsRequest.prototype.clearIsauthorizationrequired = function() {
  return this.setIsauthorizationrequired(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.GetAccountsRequest.prototype.hasIsauthorizationrequired = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.Int32Value linkingState = 14;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.account_endpoint.GetAccountsRequest.prototype.getLinkingstate = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 14));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.account_endpoint.GetAccountsRequest} returns this
*/
proto.account_endpoint.GetAccountsRequest.prototype.setLinkingstate = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.GetAccountsRequest} returns this
 */
proto.account_endpoint.GetAccountsRequest.prototype.clearLinkingstate = function() {
  return this.setLinkingstate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.GetAccountsRequest.prototype.hasLinkingstate = function() {
  return jspb.Message.getField(this, 14) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account_endpoint.LinkRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.LinkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.LinkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.LinkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.LinkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    islinkingenable: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    linkmetadataList: jspb.Message.toObjectList(msg.getLinkmetadataList(),
    Protos_common_pb.ExtraField.toObject, includeInstance),
    id: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.LinkRequest}
 */
proto.account_endpoint.LinkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.LinkRequest;
  return proto.account_endpoint.LinkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.LinkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.LinkRequest}
 */
proto.account_endpoint.LinkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIslinkingenable(value);
      break;
    case 2:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addLinkmetadata(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.LinkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.LinkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.LinkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.LinkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIslinkingenable();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getLinkmetadataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool isLinkingEnable = 1;
 * @return {boolean}
 */
proto.account_endpoint.LinkRequest.prototype.getIslinkingenable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account_endpoint.LinkRequest} returns this
 */
proto.account_endpoint.LinkRequest.prototype.setIslinkingenable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated common.ExtraField LinkMetadata = 2;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.account_endpoint.LinkRequest.prototype.getLinkmetadataList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 2));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.account_endpoint.LinkRequest} returns this
*/
proto.account_endpoint.LinkRequest.prototype.setLinkmetadataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.account_endpoint.LinkRequest.prototype.addLinkmetadata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account_endpoint.LinkRequest} returns this
 */
proto.account_endpoint.LinkRequest.prototype.clearLinkmetadataList = function() {
  return this.setLinkmetadataList([]);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.account_endpoint.LinkRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_endpoint.LinkRequest} returns this
 */
proto.account_endpoint.LinkRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.RevokeLinkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.RevokeLinkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.RevokeLinkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.RevokeLinkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.RevokeLinkRequest}
 */
proto.account_endpoint.RevokeLinkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.RevokeLinkRequest;
  return proto.account_endpoint.RevokeLinkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.RevokeLinkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.RevokeLinkRequest}
 */
proto.account_endpoint.RevokeLinkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.RevokeLinkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.RevokeLinkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.RevokeLinkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.RevokeLinkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.account_endpoint.RevokeLinkRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_endpoint.RevokeLinkRequest} returns this
 */
proto.account_endpoint.RevokeLinkRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.OpenAccountReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.OpenAccountReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.OpenAccountReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.OpenAccountReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    internalid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.OpenAccountReplay}
 */
proto.account_endpoint.OpenAccountReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.OpenAccountReplay;
  return proto.account_endpoint.OpenAccountReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.OpenAccountReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.OpenAccountReplay}
 */
proto.account_endpoint.OpenAccountReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalid(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 4:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.OpenAccountReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.OpenAccountReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.OpenAccountReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.OpenAccountReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getInternalid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.account_endpoint.OpenAccountReplay.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.account_endpoint.OpenAccountReplay} returns this
 */
proto.account_endpoint.OpenAccountReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string internalId = 2;
 * @return {string}
 */
proto.account_endpoint.OpenAccountReplay.prototype.getInternalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_endpoint.OpenAccountReplay} returns this
 */
proto.account_endpoint.OpenAccountReplay.prototype.setInternalid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_endpoint.OpenAccountReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_endpoint.OpenAccountReplay} returns this
*/
proto.account_endpoint.OpenAccountReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.OpenAccountReplay} returns this
 */
proto.account_endpoint.OpenAccountReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.OpenAccountReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional common.MessageResponse message = 4;
 * @return {?proto.common.MessageResponse}
 */
proto.account_endpoint.OpenAccountReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 4));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.account_endpoint.OpenAccountReplay} returns this
*/
proto.account_endpoint.OpenAccountReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.OpenAccountReplay} returns this
 */
proto.account_endpoint.OpenAccountReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.OpenAccountReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.UpdateAccountReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.UpdateAccountReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.UpdateAccountReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateAccountReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.UpdateAccountReplay}
 */
proto.account_endpoint.UpdateAccountReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.UpdateAccountReplay;
  return proto.account_endpoint.UpdateAccountReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.UpdateAccountReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.UpdateAccountReplay}
 */
proto.account_endpoint.UpdateAccountReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.UpdateAccountReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.UpdateAccountReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.UpdateAccountReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateAccountReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_endpoint.UpdateAccountReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_endpoint.UpdateAccountReplay} returns this
*/
proto.account_endpoint.UpdateAccountReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.UpdateAccountReplay} returns this
 */
proto.account_endpoint.UpdateAccountReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.UpdateAccountReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.account_endpoint.UpdateAccountReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.account_endpoint.UpdateAccountReplay} returns this
*/
proto.account_endpoint.UpdateAccountReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.UpdateAccountReplay} returns this
 */
proto.account_endpoint.UpdateAccountReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.UpdateAccountReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.UpdateAccountStatusReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.UpdateAccountStatusReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.UpdateAccountStatusReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateAccountStatusReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.UpdateAccountStatusReplay}
 */
proto.account_endpoint.UpdateAccountStatusReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.UpdateAccountStatusReplay;
  return proto.account_endpoint.UpdateAccountStatusReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.UpdateAccountStatusReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.UpdateAccountStatusReplay}
 */
proto.account_endpoint.UpdateAccountStatusReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.UpdateAccountStatusReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.UpdateAccountStatusReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.UpdateAccountStatusReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateAccountStatusReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_endpoint.UpdateAccountStatusReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_endpoint.UpdateAccountStatusReplay} returns this
*/
proto.account_endpoint.UpdateAccountStatusReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.UpdateAccountStatusReplay} returns this
 */
proto.account_endpoint.UpdateAccountStatusReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.UpdateAccountStatusReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.account_endpoint.UpdateAccountStatusReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.account_endpoint.UpdateAccountStatusReplay} returns this
*/
proto.account_endpoint.UpdateAccountStatusReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.UpdateAccountStatusReplay} returns this
 */
proto.account_endpoint.UpdateAccountStatusReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.UpdateAccountStatusReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.LinkReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.LinkReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.LinkReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.LinkReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.LinkReplay}
 */
proto.account_endpoint.LinkReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.LinkReplay;
  return proto.account_endpoint.LinkReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.LinkReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.LinkReplay}
 */
proto.account_endpoint.LinkReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.LinkReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.LinkReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.LinkReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.LinkReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_endpoint.LinkReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_endpoint.LinkReplay} returns this
*/
proto.account_endpoint.LinkReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.LinkReplay} returns this
 */
proto.account_endpoint.LinkReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.LinkReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.account_endpoint.LinkReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.account_endpoint.LinkReplay} returns this
*/
proto.account_endpoint.LinkReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.LinkReplay} returns this
 */
proto.account_endpoint.LinkReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.LinkReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.UpdateTransferControlReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.UpdateTransferControlReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.UpdateTransferControlReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateTransferControlReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.UpdateTransferControlReplay}
 */
proto.account_endpoint.UpdateTransferControlReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.UpdateTransferControlReplay;
  return proto.account_endpoint.UpdateTransferControlReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.UpdateTransferControlReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.UpdateTransferControlReplay}
 */
proto.account_endpoint.UpdateTransferControlReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.UpdateTransferControlReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.UpdateTransferControlReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.UpdateTransferControlReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateTransferControlReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_endpoint.UpdateTransferControlReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_endpoint.UpdateTransferControlReplay} returns this
*/
proto.account_endpoint.UpdateTransferControlReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.UpdateTransferControlReplay} returns this
 */
proto.account_endpoint.UpdateTransferControlReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.UpdateTransferControlReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.account_endpoint.UpdateTransferControlReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.account_endpoint.UpdateTransferControlReplay} returns this
*/
proto.account_endpoint.UpdateTransferControlReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.UpdateTransferControlReplay} returns this
 */
proto.account_endpoint.UpdateTransferControlReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.UpdateTransferControlReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.CloseAccountReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.CloseAccountReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.CloseAccountReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.CloseAccountReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.CloseAccountReplay}
 */
proto.account_endpoint.CloseAccountReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.CloseAccountReplay;
  return proto.account_endpoint.CloseAccountReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.CloseAccountReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.CloseAccountReplay}
 */
proto.account_endpoint.CloseAccountReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.CloseAccountReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.CloseAccountReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.CloseAccountReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.CloseAccountReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_endpoint.CloseAccountReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_endpoint.CloseAccountReplay} returns this
*/
proto.account_endpoint.CloseAccountReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.CloseAccountReplay} returns this
 */
proto.account_endpoint.CloseAccountReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.CloseAccountReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.account_endpoint.CloseAccountReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.account_endpoint.CloseAccountReplay} returns this
*/
proto.account_endpoint.CloseAccountReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.CloseAccountReplay} returns this
 */
proto.account_endpoint.CloseAccountReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.CloseAccountReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.UpdateAuthorizationRequirementReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.UpdateAuthorizationRequirementReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.UpdateAuthorizationRequirementReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateAuthorizationRequirementReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.UpdateAuthorizationRequirementReplay}
 */
proto.account_endpoint.UpdateAuthorizationRequirementReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.UpdateAuthorizationRequirementReplay;
  return proto.account_endpoint.UpdateAuthorizationRequirementReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.UpdateAuthorizationRequirementReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.UpdateAuthorizationRequirementReplay}
 */
proto.account_endpoint.UpdateAuthorizationRequirementReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.UpdateAuthorizationRequirementReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.UpdateAuthorizationRequirementReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.UpdateAuthorizationRequirementReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateAuthorizationRequirementReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_endpoint.UpdateAuthorizationRequirementReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_endpoint.UpdateAuthorizationRequirementReplay} returns this
*/
proto.account_endpoint.UpdateAuthorizationRequirementReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.UpdateAuthorizationRequirementReplay} returns this
 */
proto.account_endpoint.UpdateAuthorizationRequirementReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.UpdateAuthorizationRequirementReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.account_endpoint.UpdateAuthorizationRequirementReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.account_endpoint.UpdateAuthorizationRequirementReplay} returns this
*/
proto.account_endpoint.UpdateAuthorizationRequirementReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.UpdateAuthorizationRequirementReplay} returns this
 */
proto.account_endpoint.UpdateAuthorizationRequirementReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.UpdateAuthorizationRequirementReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.UpdateAccountLimitationReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.UpdateAccountLimitationReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.UpdateAccountLimitationReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateAccountLimitationReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.UpdateAccountLimitationReplay}
 */
proto.account_endpoint.UpdateAccountLimitationReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.UpdateAccountLimitationReplay;
  return proto.account_endpoint.UpdateAccountLimitationReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.UpdateAccountLimitationReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.UpdateAccountLimitationReplay}
 */
proto.account_endpoint.UpdateAccountLimitationReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.UpdateAccountLimitationReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.UpdateAccountLimitationReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.UpdateAccountLimitationReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateAccountLimitationReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_endpoint.UpdateAccountLimitationReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_endpoint.UpdateAccountLimitationReplay} returns this
*/
proto.account_endpoint.UpdateAccountLimitationReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.UpdateAccountLimitationReplay} returns this
 */
proto.account_endpoint.UpdateAccountLimitationReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.UpdateAccountLimitationReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.account_endpoint.UpdateAccountLimitationReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.account_endpoint.UpdateAccountLimitationReplay} returns this
*/
proto.account_endpoint.UpdateAccountLimitationReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.UpdateAccountLimitationReplay} returns this
 */
proto.account_endpoint.UpdateAccountLimitationReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.UpdateAccountLimitationReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.UpdateAccountTypeReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.UpdateAccountTypeReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.UpdateAccountTypeReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateAccountTypeReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.UpdateAccountTypeReplay}
 */
proto.account_endpoint.UpdateAccountTypeReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.UpdateAccountTypeReplay;
  return proto.account_endpoint.UpdateAccountTypeReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.UpdateAccountTypeReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.UpdateAccountTypeReplay}
 */
proto.account_endpoint.UpdateAccountTypeReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.UpdateAccountTypeReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.UpdateAccountTypeReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.UpdateAccountTypeReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateAccountTypeReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_endpoint.UpdateAccountTypeReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_endpoint.UpdateAccountTypeReplay} returns this
*/
proto.account_endpoint.UpdateAccountTypeReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.UpdateAccountTypeReplay} returns this
 */
proto.account_endpoint.UpdateAccountTypeReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.UpdateAccountTypeReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.account_endpoint.UpdateAccountTypeReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.account_endpoint.UpdateAccountTypeReplay} returns this
*/
proto.account_endpoint.UpdateAccountTypeReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.UpdateAccountTypeReplay} returns this
 */
proto.account_endpoint.UpdateAccountTypeReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.UpdateAccountTypeReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.TransferAccountOwnershipReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.TransferAccountOwnershipReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.TransferAccountOwnershipReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.TransferAccountOwnershipReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.TransferAccountOwnershipReplay}
 */
proto.account_endpoint.TransferAccountOwnershipReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.TransferAccountOwnershipReplay;
  return proto.account_endpoint.TransferAccountOwnershipReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.TransferAccountOwnershipReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.TransferAccountOwnershipReplay}
 */
proto.account_endpoint.TransferAccountOwnershipReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.TransferAccountOwnershipReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.TransferAccountOwnershipReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.TransferAccountOwnershipReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.TransferAccountOwnershipReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_endpoint.TransferAccountOwnershipReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_endpoint.TransferAccountOwnershipReplay} returns this
*/
proto.account_endpoint.TransferAccountOwnershipReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.TransferAccountOwnershipReplay} returns this
 */
proto.account_endpoint.TransferAccountOwnershipReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.TransferAccountOwnershipReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.account_endpoint.TransferAccountOwnershipReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.account_endpoint.TransferAccountOwnershipReplay} returns this
*/
proto.account_endpoint.TransferAccountOwnershipReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.TransferAccountOwnershipReplay} returns this
 */
proto.account_endpoint.TransferAccountOwnershipReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.TransferAccountOwnershipReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.IdentityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.IdentityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.IdentityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.IdentityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    username: (f = msg.getUsername()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.IdentityResponse}
 */
proto.account_endpoint.IdentityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.IdentityResponse;
  return proto.account_endpoint.IdentityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.IdentityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.IdentityResponse}
 */
proto.account_endpoint.IdentityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setUsername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.IdentityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.IdentityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.IdentityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.IdentityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.account_endpoint.IdentityResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.account_endpoint.IdentityResponse} returns this
 */
proto.account_endpoint.IdentityResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue username = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_endpoint.IdentityResponse.prototype.getUsername = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_endpoint.IdentityResponse} returns this
*/
proto.account_endpoint.IdentityResponse.prototype.setUsername = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.IdentityResponse} returns this
 */
proto.account_endpoint.IdentityResponse.prototype.clearUsername = function() {
  return this.setUsername(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.IdentityResponse.prototype.hasUsername = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.AccountSettingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.AccountSettingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.AccountSettingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.AccountSettingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isauthorizationrequired: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    islinkingenable: (f = msg.getIslinkingenable()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    islinkingmetadataset: (f = msg.getIslinkingmetadataset()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    tansfercontrol: (f = msg.getTansfercontrol()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.AccountSettingResponse}
 */
proto.account_endpoint.AccountSettingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.AccountSettingResponse;
  return proto.account_endpoint.AccountSettingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.AccountSettingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.AccountSettingResponse}
 */
proto.account_endpoint.AccountSettingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsauthorizationrequired(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIslinkingenable(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIslinkingmetadataset(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setTansfercontrol(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.AccountSettingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.AccountSettingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.AccountSettingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.AccountSettingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsauthorizationrequired();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIslinkingenable();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIslinkingmetadataset();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getTansfercontrol();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool isAuthorizationRequired = 1;
 * @return {boolean}
 */
proto.account_endpoint.AccountSettingResponse.prototype.getIsauthorizationrequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account_endpoint.AccountSettingResponse} returns this
 */
proto.account_endpoint.AccountSettingResponse.prototype.setIsauthorizationrequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional google.protobuf.BoolValue isLinkingEnable = 2;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.account_endpoint.AccountSettingResponse.prototype.getIslinkingenable = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 2));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.account_endpoint.AccountSettingResponse} returns this
*/
proto.account_endpoint.AccountSettingResponse.prototype.setIslinkingenable = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.AccountSettingResponse} returns this
 */
proto.account_endpoint.AccountSettingResponse.prototype.clearIslinkingenable = function() {
  return this.setIslinkingenable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.AccountSettingResponse.prototype.hasIslinkingenable = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.BoolValue IsLinkingMetadataSet = 3;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.account_endpoint.AccountSettingResponse.prototype.getIslinkingmetadataset = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 3));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.account_endpoint.AccountSettingResponse} returns this
*/
proto.account_endpoint.AccountSettingResponse.prototype.setIslinkingmetadataset = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.AccountSettingResponse} returns this
 */
proto.account_endpoint.AccountSettingResponse.prototype.clearIslinkingmetadataset = function() {
  return this.setIslinkingmetadataset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.AccountSettingResponse.prototype.hasIslinkingmetadataset = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Int32Value transferControl = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.account_endpoint.AccountSettingResponse.prototype.getTansfercontrol = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.account_endpoint.AccountSettingResponse} returns this
*/
proto.account_endpoint.AccountSettingResponse.prototype.setTansfercontrol = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.AccountSettingResponse} returns this
 */
proto.account_endpoint.AccountSettingResponse.prototype.clearTansfercontrol = function() {
  return this.setTansfercontrol(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.AccountSettingResponse.prototype.hasTansfercontrol = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.OwnerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.OwnerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.OwnerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.OwnerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    firstname: (f = msg.getFirstname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    middlename: (f = msg.getMiddlename()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    lastname: (f = msg.getLastname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.OwnerResponse}
 */
proto.account_endpoint.OwnerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.OwnerResponse;
  return proto.account_endpoint.OwnerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.OwnerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.OwnerResponse}
 */
proto.account_endpoint.OwnerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFirstname(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMiddlename(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLastname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.OwnerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.OwnerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.OwnerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.OwnerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFirstname();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMiddlename();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLastname();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue id = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_endpoint.OwnerResponse.prototype.getId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_endpoint.OwnerResponse} returns this
*/
proto.account_endpoint.OwnerResponse.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.OwnerResponse} returns this
 */
proto.account_endpoint.OwnerResponse.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.OwnerResponse.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue firstName = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_endpoint.OwnerResponse.prototype.getFirstname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_endpoint.OwnerResponse} returns this
*/
proto.account_endpoint.OwnerResponse.prototype.setFirstname = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.OwnerResponse} returns this
 */
proto.account_endpoint.OwnerResponse.prototype.clearFirstname = function() {
  return this.setFirstname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.OwnerResponse.prototype.hasFirstname = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue middleName = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_endpoint.OwnerResponse.prototype.getMiddlename = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_endpoint.OwnerResponse} returns this
*/
proto.account_endpoint.OwnerResponse.prototype.setMiddlename = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.OwnerResponse} returns this
 */
proto.account_endpoint.OwnerResponse.prototype.clearMiddlename = function() {
  return this.setMiddlename(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.OwnerResponse.prototype.hasMiddlename = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue lastName = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_endpoint.OwnerResponse.prototype.getLastname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_endpoint.OwnerResponse} returns this
*/
proto.account_endpoint.OwnerResponse.prototype.setLastname = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.OwnerResponse} returns this
 */
proto.account_endpoint.OwnerResponse.prototype.clearLastname = function() {
  return this.setLastname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.OwnerResponse.prototype.hasLastname = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account_endpoint.GetAccountReplay.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.GetAccountReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.GetAccountReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.GetAccountReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.GetAccountReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    currency: (f = msg.getCurrency()) && Protos_common_pb.CurrencyResponse.toObject(includeInstance, f),
    accounttype: (f = msg.getAccounttype()) && Protos_common_pb.AccountTypeResponse.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    limitation: (f = msg.getLimitation()) && Protos_common_pb.LimitationResponse.toObject(includeInstance, f),
    code: (f = msg.getCode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance),
    owner: (f = msg.getOwner()) && proto.account_endpoint.OwnerResponse.toObject(includeInstance, f),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 11, 0),
    internalid: jspb.Message.getFieldWithDefault(msg, 12, ""),
    identity: (f = msg.getIdentity()) && proto.account_endpoint.IdentityResponse.toObject(includeInstance, f),
    accountsetting: (f = msg.getAccountsetting()) && proto.account_endpoint.AccountSettingResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.GetAccountReplay}
 */
proto.account_endpoint.GetAccountReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.GetAccountReplay;
  return proto.account_endpoint.GetAccountReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.GetAccountReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.GetAccountReplay}
 */
proto.account_endpoint.GetAccountReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 2:
      var value = new Protos_common_pb.CurrencyResponse;
      reader.readMessage(value,Protos_common_pb.CurrencyResponse.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 3:
      var value = new Protos_common_pb.AccountTypeResponse;
      reader.readMessage(value,Protos_common_pb.AccountTypeResponse.deserializeBinaryFromReader);
      msg.setAccounttype(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 5:
      var value = new Protos_common_pb.LimitationResponse;
      reader.readMessage(value,Protos_common_pb.LimitationResponse.deserializeBinaryFromReader);
      msg.setLimitation(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCode(value);
      break;
    case 7:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    case 8:
      var value = new proto.account_endpoint.OwnerResponse;
      reader.readMessage(value,proto.account_endpoint.OwnerResponse.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 9:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalid(value);
      break;
    case 13:
      var value = new proto.account_endpoint.IdentityResponse;
      reader.readMessage(value,proto.account_endpoint.IdentityResponse.deserializeBinaryFromReader);
      msg.setIdentity(value);
      break;
    case 14:
      var value = new proto.account_endpoint.AccountSettingResponse;
      reader.readMessage(value,proto.account_endpoint.AccountSettingResponse.deserializeBinaryFromReader);
      msg.setAccountsetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.GetAccountReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.GetAccountReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.GetAccountReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.GetAccountReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.CurrencyResponse.serializeBinaryToWriter
    );
  }
  f = message.getAccounttype();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.AccountTypeResponse.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getLimitation();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Protos_common_pb.LimitationResponse.serializeBinaryToWriter
    );
  }
  f = message.getCode();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.account_endpoint.OwnerResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getInternalid();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getIdentity();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.account_endpoint.IdentityResponse.serializeBinaryToWriter
    );
  }
  f = message.getAccountsetting();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.account_endpoint.AccountSettingResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue name = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_endpoint.GetAccountReplay.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_endpoint.GetAccountReplay} returns this
*/
proto.account_endpoint.GetAccountReplay.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.GetAccountReplay} returns this
 */
proto.account_endpoint.GetAccountReplay.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.GetAccountReplay.prototype.hasName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.CurrencyResponse currency = 2;
 * @return {?proto.common.CurrencyResponse}
 */
proto.account_endpoint.GetAccountReplay.prototype.getCurrency = function() {
  return /** @type{?proto.common.CurrencyResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.CurrencyResponse, 2));
};


/**
 * @param {?proto.common.CurrencyResponse|undefined} value
 * @return {!proto.account_endpoint.GetAccountReplay} returns this
*/
proto.account_endpoint.GetAccountReplay.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.GetAccountReplay} returns this
 */
proto.account_endpoint.GetAccountReplay.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.GetAccountReplay.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.AccountTypeResponse accountType = 3;
 * @return {?proto.common.AccountTypeResponse}
 */
proto.account_endpoint.GetAccountReplay.prototype.getAccounttype = function() {
  return /** @type{?proto.common.AccountTypeResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.AccountTypeResponse, 3));
};


/**
 * @param {?proto.common.AccountTypeResponse|undefined} value
 * @return {!proto.account_endpoint.GetAccountReplay} returns this
*/
proto.account_endpoint.GetAccountReplay.prototype.setAccounttype = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.GetAccountReplay} returns this
 */
proto.account_endpoint.GetAccountReplay.prototype.clearAccounttype = function() {
  return this.setAccounttype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.GetAccountReplay.prototype.hasAccounttype = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Int32Value status = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.account_endpoint.GetAccountReplay.prototype.getStatus = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.account_endpoint.GetAccountReplay} returns this
*/
proto.account_endpoint.GetAccountReplay.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.GetAccountReplay} returns this
 */
proto.account_endpoint.GetAccountReplay.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.GetAccountReplay.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional common.LimitationResponse limitation = 5;
 * @return {?proto.common.LimitationResponse}
 */
proto.account_endpoint.GetAccountReplay.prototype.getLimitation = function() {
  return /** @type{?proto.common.LimitationResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.LimitationResponse, 5));
};


/**
 * @param {?proto.common.LimitationResponse|undefined} value
 * @return {!proto.account_endpoint.GetAccountReplay} returns this
*/
proto.account_endpoint.GetAccountReplay.prototype.setLimitation = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.GetAccountReplay} returns this
 */
proto.account_endpoint.GetAccountReplay.prototype.clearLimitation = function() {
  return this.setLimitation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.GetAccountReplay.prototype.hasLimitation = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue code = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_endpoint.GetAccountReplay.prototype.getCode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_endpoint.GetAccountReplay} returns this
*/
proto.account_endpoint.GetAccountReplay.prototype.setCode = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.GetAccountReplay} returns this
 */
proto.account_endpoint.GetAccountReplay.prototype.clearCode = function() {
  return this.setCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.GetAccountReplay.prototype.hasCode = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated common.ExtraField extraFields = 7;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.account_endpoint.GetAccountReplay.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 7));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.account_endpoint.GetAccountReplay} returns this
*/
proto.account_endpoint.GetAccountReplay.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.account_endpoint.GetAccountReplay.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account_endpoint.GetAccountReplay} returns this
 */
proto.account_endpoint.GetAccountReplay.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};


/**
 * optional OwnerResponse owner = 8;
 * @return {?proto.account_endpoint.OwnerResponse}
 */
proto.account_endpoint.GetAccountReplay.prototype.getOwner = function() {
  return /** @type{?proto.account_endpoint.OwnerResponse} */ (
    jspb.Message.getWrapperField(this, proto.account_endpoint.OwnerResponse, 8));
};


/**
 * @param {?proto.account_endpoint.OwnerResponse|undefined} value
 * @return {!proto.account_endpoint.GetAccountReplay} returns this
*/
proto.account_endpoint.GetAccountReplay.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.GetAccountReplay} returns this
 */
proto.account_endpoint.GetAccountReplay.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.GetAccountReplay.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional common.ProfileResponse createdBy = 9;
 * @return {?proto.common.ProfileResponse}
 */
proto.account_endpoint.GetAccountReplay.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 9));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.account_endpoint.GetAccountReplay} returns this
*/
proto.account_endpoint.GetAccountReplay.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.GetAccountReplay} returns this
 */
proto.account_endpoint.GetAccountReplay.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.GetAccountReplay.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_endpoint.GetAccountReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_endpoint.GetAccountReplay} returns this
*/
proto.account_endpoint.GetAccountReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.GetAccountReplay} returns this
 */
proto.account_endpoint.GetAccountReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.GetAccountReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional int64 id = 11;
 * @return {number}
 */
proto.account_endpoint.GetAccountReplay.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.account_endpoint.GetAccountReplay} returns this
 */
proto.account_endpoint.GetAccountReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string internalId = 12;
 * @return {string}
 */
proto.account_endpoint.GetAccountReplay.prototype.getInternalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_endpoint.GetAccountReplay} returns this
 */
proto.account_endpoint.GetAccountReplay.prototype.setInternalid = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional IdentityResponse identity = 13;
 * @return {?proto.account_endpoint.IdentityResponse}
 */
proto.account_endpoint.GetAccountReplay.prototype.getIdentity = function() {
  return /** @type{?proto.account_endpoint.IdentityResponse} */ (
    jspb.Message.getWrapperField(this, proto.account_endpoint.IdentityResponse, 13));
};


/**
 * @param {?proto.account_endpoint.IdentityResponse|undefined} value
 * @return {!proto.account_endpoint.GetAccountReplay} returns this
*/
proto.account_endpoint.GetAccountReplay.prototype.setIdentity = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.GetAccountReplay} returns this
 */
proto.account_endpoint.GetAccountReplay.prototype.clearIdentity = function() {
  return this.setIdentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.GetAccountReplay.prototype.hasIdentity = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional AccountSettingResponse accountSetting = 14;
 * @return {?proto.account_endpoint.AccountSettingResponse}
 */
proto.account_endpoint.GetAccountReplay.prototype.getAccountsetting = function() {
  return /** @type{?proto.account_endpoint.AccountSettingResponse} */ (
    jspb.Message.getWrapperField(this, proto.account_endpoint.AccountSettingResponse, 14));
};


/**
 * @param {?proto.account_endpoint.AccountSettingResponse|undefined} value
 * @return {!proto.account_endpoint.GetAccountReplay} returns this
*/
proto.account_endpoint.GetAccountReplay.prototype.setAccountsetting = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.GetAccountReplay} returns this
 */
proto.account_endpoint.GetAccountReplay.prototype.clearAccountsetting = function() {
  return this.setAccountsetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.GetAccountReplay.prototype.hasAccountsetting = function() {
  return jspb.Message.getField(this, 14) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.Account.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.Account.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.Account} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.Account.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    currency: (f = msg.getCurrency()) && Protos_common_pb.CurrencyResponse.toObject(includeInstance, f),
    accounttype: (f = msg.getAccounttype()) && Protos_common_pb.AccountTypeResponse.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    code: (f = msg.getCode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    mirrorbalance: (f = msg.getMirrorbalance()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    owner: (f = msg.getOwner()) && proto.account_endpoint.OwnerResponse.toObject(includeInstance, f),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 10, 0),
    internalid: jspb.Message.getFieldWithDefault(msg, 11, ""),
    accountsetting: (f = msg.getAccountsetting()) && proto.account_endpoint.AccountSettingResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.Account}
 */
proto.account_endpoint.Account.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.Account;
  return proto.account_endpoint.Account.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.Account} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.Account}
 */
proto.account_endpoint.Account.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 2:
      var value = new Protos_common_pb.CurrencyResponse;
      reader.readMessage(value,Protos_common_pb.CurrencyResponse.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 3:
      var value = new Protos_common_pb.AccountTypeResponse;
      reader.readMessage(value,Protos_common_pb.AccountTypeResponse.deserializeBinaryFromReader);
      msg.setAccounttype(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCode(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMirrorbalance(value);
      break;
    case 7:
      var value = new proto.account_endpoint.OwnerResponse;
      reader.readMessage(value,proto.account_endpoint.OwnerResponse.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 8:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalid(value);
      break;
    case 12:
      var value = new proto.account_endpoint.AccountSettingResponse;
      reader.readMessage(value,proto.account_endpoint.AccountSettingResponse.deserializeBinaryFromReader);
      msg.setAccountsetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.Account.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.Account.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.Account} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.Account.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.CurrencyResponse.serializeBinaryToWriter
    );
  }
  f = message.getAccounttype();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.AccountTypeResponse.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getCode();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMirrorbalance();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.account_endpoint.OwnerResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getInternalid();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAccountsetting();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.account_endpoint.AccountSettingResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue name = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_endpoint.Account.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_endpoint.Account} returns this
*/
proto.account_endpoint.Account.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.Account} returns this
 */
proto.account_endpoint.Account.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.Account.prototype.hasName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.CurrencyResponse currency = 2;
 * @return {?proto.common.CurrencyResponse}
 */
proto.account_endpoint.Account.prototype.getCurrency = function() {
  return /** @type{?proto.common.CurrencyResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.CurrencyResponse, 2));
};


/**
 * @param {?proto.common.CurrencyResponse|undefined} value
 * @return {!proto.account_endpoint.Account} returns this
*/
proto.account_endpoint.Account.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.Account} returns this
 */
proto.account_endpoint.Account.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.Account.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.AccountTypeResponse accountType = 3;
 * @return {?proto.common.AccountTypeResponse}
 */
proto.account_endpoint.Account.prototype.getAccounttype = function() {
  return /** @type{?proto.common.AccountTypeResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.AccountTypeResponse, 3));
};


/**
 * @param {?proto.common.AccountTypeResponse|undefined} value
 * @return {!proto.account_endpoint.Account} returns this
*/
proto.account_endpoint.Account.prototype.setAccounttype = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.Account} returns this
 */
proto.account_endpoint.Account.prototype.clearAccounttype = function() {
  return this.setAccounttype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.Account.prototype.hasAccounttype = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Int32Value status = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.account_endpoint.Account.prototype.getStatus = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.account_endpoint.Account} returns this
*/
proto.account_endpoint.Account.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.Account} returns this
 */
proto.account_endpoint.Account.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.Account.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue code = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_endpoint.Account.prototype.getCode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_endpoint.Account} returns this
*/
proto.account_endpoint.Account.prototype.setCode = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.Account} returns this
 */
proto.account_endpoint.Account.prototype.clearCode = function() {
  return this.setCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.Account.prototype.hasCode = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue mirrorBalance = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_endpoint.Account.prototype.getMirrorbalance = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_endpoint.Account} returns this
*/
proto.account_endpoint.Account.prototype.setMirrorbalance = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.Account} returns this
 */
proto.account_endpoint.Account.prototype.clearMirrorbalance = function() {
  return this.setMirrorbalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.Account.prototype.hasMirrorbalance = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional OwnerResponse owner = 7;
 * @return {?proto.account_endpoint.OwnerResponse}
 */
proto.account_endpoint.Account.prototype.getOwner = function() {
  return /** @type{?proto.account_endpoint.OwnerResponse} */ (
    jspb.Message.getWrapperField(this, proto.account_endpoint.OwnerResponse, 7));
};


/**
 * @param {?proto.account_endpoint.OwnerResponse|undefined} value
 * @return {!proto.account_endpoint.Account} returns this
*/
proto.account_endpoint.Account.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.Account} returns this
 */
proto.account_endpoint.Account.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.Account.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional common.ProfileResponse createdBy = 8;
 * @return {?proto.common.ProfileResponse}
 */
proto.account_endpoint.Account.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 8));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.account_endpoint.Account} returns this
*/
proto.account_endpoint.Account.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.Account} returns this
 */
proto.account_endpoint.Account.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.Account.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_endpoint.Account.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_endpoint.Account} returns this
*/
proto.account_endpoint.Account.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.Account} returns this
 */
proto.account_endpoint.Account.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.Account.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional int64 id = 10;
 * @return {number}
 */
proto.account_endpoint.Account.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.account_endpoint.Account} returns this
 */
proto.account_endpoint.Account.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string internalId = 11;
 * @return {string}
 */
proto.account_endpoint.Account.prototype.getInternalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.account_endpoint.Account} returns this
 */
proto.account_endpoint.Account.prototype.setInternalid = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional AccountSettingResponse accountSetting = 12;
 * @return {?proto.account_endpoint.AccountSettingResponse}
 */
proto.account_endpoint.Account.prototype.getAccountsetting = function() {
  return /** @type{?proto.account_endpoint.AccountSettingResponse} */ (
    jspb.Message.getWrapperField(this, proto.account_endpoint.AccountSettingResponse, 12));
};


/**
 * @param {?proto.account_endpoint.AccountSettingResponse|undefined} value
 * @return {!proto.account_endpoint.Account} returns this
*/
proto.account_endpoint.Account.prototype.setAccountsetting = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.Account} returns this
 */
proto.account_endpoint.Account.prototype.clearAccountsetting = function() {
  return this.setAccountsetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.Account.prototype.hasAccountsetting = function() {
  return jspb.Message.getField(this, 12) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account_endpoint.GetAccountsReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.GetAccountsReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.GetAccountsReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.GetAccountsReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.GetAccountsReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountsList: jspb.Message.toObjectList(msg.getAccountsList(),
    proto.account_endpoint.Account.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.GetAccountsReplay}
 */
proto.account_endpoint.GetAccountsReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.GetAccountsReplay;
  return proto.account_endpoint.GetAccountsReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.GetAccountsReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.GetAccountsReplay}
 */
proto.account_endpoint.GetAccountsReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_endpoint.Account;
      reader.readMessage(value,proto.account_endpoint.Account.deserializeBinaryFromReader);
      msg.addAccounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.GetAccountsReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.GetAccountsReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.GetAccountsReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.GetAccountsReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.account_endpoint.Account.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Account accounts = 1;
 * @return {!Array<!proto.account_endpoint.Account>}
 */
proto.account_endpoint.GetAccountsReplay.prototype.getAccountsList = function() {
  return /** @type{!Array<!proto.account_endpoint.Account>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.account_endpoint.Account, 1));
};


/**
 * @param {!Array<!proto.account_endpoint.Account>} value
 * @return {!proto.account_endpoint.GetAccountsReplay} returns this
*/
proto.account_endpoint.GetAccountsReplay.prototype.setAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.account_endpoint.Account=} opt_value
 * @param {number=} opt_index
 * @return {!proto.account_endpoint.Account}
 */
proto.account_endpoint.GetAccountsReplay.prototype.addAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.account_endpoint.Account, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account_endpoint.GetAccountsReplay} returns this
 */
proto.account_endpoint.GetAccountsReplay.prototype.clearAccountsList = function() {
  return this.setAccountsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_endpoint.OpenAccountResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_endpoint.OpenAccountResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_endpoint.OpenAccountResponse.ResultCase}
 */
proto.account_endpoint.OpenAccountResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_endpoint.OpenAccountResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_endpoint.OpenAccountResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.OpenAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.OpenAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.OpenAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.OpenAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_endpoint.OpenAccountReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.OpenAccountResponse}
 */
proto.account_endpoint.OpenAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.OpenAccountResponse;
  return proto.account_endpoint.OpenAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.OpenAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.OpenAccountResponse}
 */
proto.account_endpoint.OpenAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_endpoint.OpenAccountReplay;
      reader.readMessage(value,proto.account_endpoint.OpenAccountReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.OpenAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.OpenAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.OpenAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.OpenAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_endpoint.OpenAccountReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional OpenAccountReplay success = 1;
 * @return {?proto.account_endpoint.OpenAccountReplay}
 */
proto.account_endpoint.OpenAccountResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_endpoint.OpenAccountReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_endpoint.OpenAccountReplay, 1));
};


/**
 * @param {?proto.account_endpoint.OpenAccountReplay|undefined} value
 * @return {!proto.account_endpoint.OpenAccountResponse} returns this
*/
proto.account_endpoint.OpenAccountResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_endpoint.OpenAccountResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.OpenAccountResponse} returns this
 */
proto.account_endpoint.OpenAccountResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.OpenAccountResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_endpoint.UpdateAccountResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_endpoint.UpdateAccountResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_endpoint.UpdateAccountResponse.ResultCase}
 */
proto.account_endpoint.UpdateAccountResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_endpoint.UpdateAccountResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_endpoint.UpdateAccountResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.UpdateAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.UpdateAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.UpdateAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_endpoint.UpdateAccountReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.UpdateAccountResponse}
 */
proto.account_endpoint.UpdateAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.UpdateAccountResponse;
  return proto.account_endpoint.UpdateAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.UpdateAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.UpdateAccountResponse}
 */
proto.account_endpoint.UpdateAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_endpoint.UpdateAccountReplay;
      reader.readMessage(value,proto.account_endpoint.UpdateAccountReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.UpdateAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.UpdateAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.UpdateAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_endpoint.UpdateAccountReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateAccountReplay success = 1;
 * @return {?proto.account_endpoint.UpdateAccountReplay}
 */
proto.account_endpoint.UpdateAccountResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_endpoint.UpdateAccountReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_endpoint.UpdateAccountReplay, 1));
};


/**
 * @param {?proto.account_endpoint.UpdateAccountReplay|undefined} value
 * @return {!proto.account_endpoint.UpdateAccountResponse} returns this
*/
proto.account_endpoint.UpdateAccountResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_endpoint.UpdateAccountResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.UpdateAccountResponse} returns this
 */
proto.account_endpoint.UpdateAccountResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.UpdateAccountResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_endpoint.UpdateAccountStatusResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_endpoint.UpdateAccountStatusResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_endpoint.UpdateAccountStatusResponse.ResultCase}
 */
proto.account_endpoint.UpdateAccountStatusResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_endpoint.UpdateAccountStatusResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_endpoint.UpdateAccountStatusResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.UpdateAccountStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.UpdateAccountStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.UpdateAccountStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateAccountStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_endpoint.UpdateAccountStatusReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.UpdateAccountStatusResponse}
 */
proto.account_endpoint.UpdateAccountStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.UpdateAccountStatusResponse;
  return proto.account_endpoint.UpdateAccountStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.UpdateAccountStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.UpdateAccountStatusResponse}
 */
proto.account_endpoint.UpdateAccountStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_endpoint.UpdateAccountStatusReplay;
      reader.readMessage(value,proto.account_endpoint.UpdateAccountStatusReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.UpdateAccountStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.UpdateAccountStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.UpdateAccountStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateAccountStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_endpoint.UpdateAccountStatusReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateAccountStatusReplay success = 1;
 * @return {?proto.account_endpoint.UpdateAccountStatusReplay}
 */
proto.account_endpoint.UpdateAccountStatusResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_endpoint.UpdateAccountStatusReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_endpoint.UpdateAccountStatusReplay, 1));
};


/**
 * @param {?proto.account_endpoint.UpdateAccountStatusReplay|undefined} value
 * @return {!proto.account_endpoint.UpdateAccountStatusResponse} returns this
*/
proto.account_endpoint.UpdateAccountStatusResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_endpoint.UpdateAccountStatusResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.UpdateAccountStatusResponse} returns this
 */
proto.account_endpoint.UpdateAccountStatusResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.UpdateAccountStatusResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_endpoint.CloseAccountResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_endpoint.CloseAccountResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_endpoint.CloseAccountResponse.ResultCase}
 */
proto.account_endpoint.CloseAccountResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_endpoint.CloseAccountResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_endpoint.CloseAccountResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.CloseAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.CloseAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.CloseAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.CloseAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_endpoint.CloseAccountReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.CloseAccountResponse}
 */
proto.account_endpoint.CloseAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.CloseAccountResponse;
  return proto.account_endpoint.CloseAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.CloseAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.CloseAccountResponse}
 */
proto.account_endpoint.CloseAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_endpoint.CloseAccountReplay;
      reader.readMessage(value,proto.account_endpoint.CloseAccountReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.CloseAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.CloseAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.CloseAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.CloseAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_endpoint.CloseAccountReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional CloseAccountReplay success = 1;
 * @return {?proto.account_endpoint.CloseAccountReplay}
 */
proto.account_endpoint.CloseAccountResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_endpoint.CloseAccountReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_endpoint.CloseAccountReplay, 1));
};


/**
 * @param {?proto.account_endpoint.CloseAccountReplay|undefined} value
 * @return {!proto.account_endpoint.CloseAccountResponse} returns this
*/
proto.account_endpoint.CloseAccountResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_endpoint.CloseAccountResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.CloseAccountResponse} returns this
 */
proto.account_endpoint.CloseAccountResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.CloseAccountResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_endpoint.UpdateAccountLimitationResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_endpoint.UpdateAccountLimitationResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_endpoint.UpdateAccountLimitationResponse.ResultCase}
 */
proto.account_endpoint.UpdateAccountLimitationResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_endpoint.UpdateAccountLimitationResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_endpoint.UpdateAccountLimitationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.UpdateAccountLimitationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.UpdateAccountLimitationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.UpdateAccountLimitationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateAccountLimitationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_endpoint.UpdateAccountLimitationReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.UpdateAccountLimitationResponse}
 */
proto.account_endpoint.UpdateAccountLimitationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.UpdateAccountLimitationResponse;
  return proto.account_endpoint.UpdateAccountLimitationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.UpdateAccountLimitationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.UpdateAccountLimitationResponse}
 */
proto.account_endpoint.UpdateAccountLimitationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_endpoint.UpdateAccountLimitationReplay;
      reader.readMessage(value,proto.account_endpoint.UpdateAccountLimitationReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.UpdateAccountLimitationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.UpdateAccountLimitationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.UpdateAccountLimitationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateAccountLimitationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_endpoint.UpdateAccountLimitationReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateAccountLimitationReplay success = 1;
 * @return {?proto.account_endpoint.UpdateAccountLimitationReplay}
 */
proto.account_endpoint.UpdateAccountLimitationResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_endpoint.UpdateAccountLimitationReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_endpoint.UpdateAccountLimitationReplay, 1));
};


/**
 * @param {?proto.account_endpoint.UpdateAccountLimitationReplay|undefined} value
 * @return {!proto.account_endpoint.UpdateAccountLimitationResponse} returns this
*/
proto.account_endpoint.UpdateAccountLimitationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_endpoint.UpdateAccountLimitationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.UpdateAccountLimitationResponse} returns this
 */
proto.account_endpoint.UpdateAccountLimitationResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.UpdateAccountLimitationResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_endpoint.UpdateAccountTypeResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_endpoint.UpdateAccountTypeResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_endpoint.UpdateAccountTypeResponse.ResultCase}
 */
proto.account_endpoint.UpdateAccountTypeResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_endpoint.UpdateAccountTypeResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_endpoint.UpdateAccountTypeResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.UpdateAccountTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.UpdateAccountTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.UpdateAccountTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateAccountTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_endpoint.UpdateAccountTypeReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.UpdateAccountTypeResponse}
 */
proto.account_endpoint.UpdateAccountTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.UpdateAccountTypeResponse;
  return proto.account_endpoint.UpdateAccountTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.UpdateAccountTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.UpdateAccountTypeResponse}
 */
proto.account_endpoint.UpdateAccountTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_endpoint.UpdateAccountTypeReplay;
      reader.readMessage(value,proto.account_endpoint.UpdateAccountTypeReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.UpdateAccountTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.UpdateAccountTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.UpdateAccountTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateAccountTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_endpoint.UpdateAccountTypeReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateAccountTypeReplay success = 1;
 * @return {?proto.account_endpoint.UpdateAccountTypeReplay}
 */
proto.account_endpoint.UpdateAccountTypeResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_endpoint.UpdateAccountTypeReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_endpoint.UpdateAccountTypeReplay, 1));
};


/**
 * @param {?proto.account_endpoint.UpdateAccountTypeReplay|undefined} value
 * @return {!proto.account_endpoint.UpdateAccountTypeResponse} returns this
*/
proto.account_endpoint.UpdateAccountTypeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_endpoint.UpdateAccountTypeResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.UpdateAccountTypeResponse} returns this
 */
proto.account_endpoint.UpdateAccountTypeResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.UpdateAccountTypeResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_endpoint.GetAccountResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_endpoint.GetAccountResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_endpoint.GetAccountResponse.ResultCase}
 */
proto.account_endpoint.GetAccountResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_endpoint.GetAccountResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_endpoint.GetAccountResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.GetAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.GetAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.GetAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.GetAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_endpoint.GetAccountReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.GetAccountResponse}
 */
proto.account_endpoint.GetAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.GetAccountResponse;
  return proto.account_endpoint.GetAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.GetAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.GetAccountResponse}
 */
proto.account_endpoint.GetAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_endpoint.GetAccountReplay;
      reader.readMessage(value,proto.account_endpoint.GetAccountReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.GetAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.GetAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.GetAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.GetAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_endpoint.GetAccountReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetAccountReplay success = 1;
 * @return {?proto.account_endpoint.GetAccountReplay}
 */
proto.account_endpoint.GetAccountResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_endpoint.GetAccountReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_endpoint.GetAccountReplay, 1));
};


/**
 * @param {?proto.account_endpoint.GetAccountReplay|undefined} value
 * @return {!proto.account_endpoint.GetAccountResponse} returns this
*/
proto.account_endpoint.GetAccountResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_endpoint.GetAccountResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.GetAccountResponse} returns this
 */
proto.account_endpoint.GetAccountResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.GetAccountResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_endpoint.GetAccountsResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_endpoint.GetAccountsResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_endpoint.GetAccountsResponse.ResultCase}
 */
proto.account_endpoint.GetAccountsResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_endpoint.GetAccountsResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_endpoint.GetAccountsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.GetAccountsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.GetAccountsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.GetAccountsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.GetAccountsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_endpoint.GetAccountsReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.GetAccountsResponse}
 */
proto.account_endpoint.GetAccountsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.GetAccountsResponse;
  return proto.account_endpoint.GetAccountsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.GetAccountsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.GetAccountsResponse}
 */
proto.account_endpoint.GetAccountsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_endpoint.GetAccountsReplay;
      reader.readMessage(value,proto.account_endpoint.GetAccountsReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.GetAccountsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.GetAccountsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.GetAccountsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.GetAccountsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_endpoint.GetAccountsReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetAccountsReplay success = 1;
 * @return {?proto.account_endpoint.GetAccountsReplay}
 */
proto.account_endpoint.GetAccountsResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_endpoint.GetAccountsReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_endpoint.GetAccountsReplay, 1));
};


/**
 * @param {?proto.account_endpoint.GetAccountsReplay|undefined} value
 * @return {!proto.account_endpoint.GetAccountsResponse} returns this
*/
proto.account_endpoint.GetAccountsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_endpoint.GetAccountsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.GetAccountsResponse} returns this
 */
proto.account_endpoint.GetAccountsResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.GetAccountsResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_endpoint.TransferAccountOwnershipResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_endpoint.TransferAccountOwnershipResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_endpoint.TransferAccountOwnershipResponse.ResultCase}
 */
proto.account_endpoint.TransferAccountOwnershipResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_endpoint.TransferAccountOwnershipResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_endpoint.TransferAccountOwnershipResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.TransferAccountOwnershipResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.TransferAccountOwnershipResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.TransferAccountOwnershipResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.TransferAccountOwnershipResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_endpoint.TransferAccountOwnershipReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.TransferAccountOwnershipResponse}
 */
proto.account_endpoint.TransferAccountOwnershipResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.TransferAccountOwnershipResponse;
  return proto.account_endpoint.TransferAccountOwnershipResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.TransferAccountOwnershipResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.TransferAccountOwnershipResponse}
 */
proto.account_endpoint.TransferAccountOwnershipResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_endpoint.TransferAccountOwnershipReplay;
      reader.readMessage(value,proto.account_endpoint.TransferAccountOwnershipReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.TransferAccountOwnershipResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.TransferAccountOwnershipResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.TransferAccountOwnershipResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.TransferAccountOwnershipResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_endpoint.TransferAccountOwnershipReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional TransferAccountOwnershipReplay success = 1;
 * @return {?proto.account_endpoint.TransferAccountOwnershipReplay}
 */
proto.account_endpoint.TransferAccountOwnershipResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_endpoint.TransferAccountOwnershipReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_endpoint.TransferAccountOwnershipReplay, 1));
};


/**
 * @param {?proto.account_endpoint.TransferAccountOwnershipReplay|undefined} value
 * @return {!proto.account_endpoint.TransferAccountOwnershipResponse} returns this
*/
proto.account_endpoint.TransferAccountOwnershipResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_endpoint.TransferAccountOwnershipResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.TransferAccountOwnershipResponse} returns this
 */
proto.account_endpoint.TransferAccountOwnershipResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.TransferAccountOwnershipResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_endpoint.UpdateAuthorizationRequirementResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_endpoint.UpdateAuthorizationRequirementResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_endpoint.UpdateAuthorizationRequirementResponse.ResultCase}
 */
proto.account_endpoint.UpdateAuthorizationRequirementResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_endpoint.UpdateAuthorizationRequirementResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_endpoint.UpdateAuthorizationRequirementResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.UpdateAuthorizationRequirementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.UpdateAuthorizationRequirementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.UpdateAuthorizationRequirementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateAuthorizationRequirementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_endpoint.UpdateAuthorizationRequirementReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.UpdateAuthorizationRequirementResponse}
 */
proto.account_endpoint.UpdateAuthorizationRequirementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.UpdateAuthorizationRequirementResponse;
  return proto.account_endpoint.UpdateAuthorizationRequirementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.UpdateAuthorizationRequirementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.UpdateAuthorizationRequirementResponse}
 */
proto.account_endpoint.UpdateAuthorizationRequirementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_endpoint.UpdateAuthorizationRequirementReplay;
      reader.readMessage(value,proto.account_endpoint.UpdateAuthorizationRequirementReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.UpdateAuthorizationRequirementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.UpdateAuthorizationRequirementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.UpdateAuthorizationRequirementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateAuthorizationRequirementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_endpoint.UpdateAuthorizationRequirementReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateAuthorizationRequirementReplay success = 1;
 * @return {?proto.account_endpoint.UpdateAuthorizationRequirementReplay}
 */
proto.account_endpoint.UpdateAuthorizationRequirementResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_endpoint.UpdateAuthorizationRequirementReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_endpoint.UpdateAuthorizationRequirementReplay, 1));
};


/**
 * @param {?proto.account_endpoint.UpdateAuthorizationRequirementReplay|undefined} value
 * @return {!proto.account_endpoint.UpdateAuthorizationRequirementResponse} returns this
*/
proto.account_endpoint.UpdateAuthorizationRequirementResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_endpoint.UpdateAuthorizationRequirementResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.UpdateAuthorizationRequirementResponse} returns this
 */
proto.account_endpoint.UpdateAuthorizationRequirementResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.UpdateAuthorizationRequirementResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_endpoint.LinkResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_endpoint.LinkResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_endpoint.LinkResponse.ResultCase}
 */
proto.account_endpoint.LinkResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_endpoint.LinkResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_endpoint.LinkResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.LinkResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.LinkResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.LinkResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.LinkResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_endpoint.LinkReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.LinkResponse}
 */
proto.account_endpoint.LinkResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.LinkResponse;
  return proto.account_endpoint.LinkResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.LinkResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.LinkResponse}
 */
proto.account_endpoint.LinkResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_endpoint.LinkReplay;
      reader.readMessage(value,proto.account_endpoint.LinkReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.LinkResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.LinkResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.LinkResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.LinkResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_endpoint.LinkReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional LinkReplay success = 1;
 * @return {?proto.account_endpoint.LinkReplay}
 */
proto.account_endpoint.LinkResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_endpoint.LinkReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_endpoint.LinkReplay, 1));
};


/**
 * @param {?proto.account_endpoint.LinkReplay|undefined} value
 * @return {!proto.account_endpoint.LinkResponse} returns this
*/
proto.account_endpoint.LinkResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_endpoint.LinkResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.LinkResponse} returns this
 */
proto.account_endpoint.LinkResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.LinkResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.account_endpoint.UpdateTransferControlResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.account_endpoint.UpdateTransferControlResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.account_endpoint.UpdateTransferControlResponse.ResultCase}
 */
proto.account_endpoint.UpdateTransferControlResponse.prototype.getResultCase = function() {
  return /** @type {proto.account_endpoint.UpdateTransferControlResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.account_endpoint.UpdateTransferControlResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_endpoint.UpdateTransferControlResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_endpoint.UpdateTransferControlResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_endpoint.UpdateTransferControlResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateTransferControlResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.account_endpoint.UpdateTransferControlReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_endpoint.UpdateTransferControlResponse}
 */
proto.account_endpoint.UpdateTransferControlResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_endpoint.UpdateTransferControlResponse;
  return proto.account_endpoint.UpdateTransferControlResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_endpoint.UpdateTransferControlResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_endpoint.UpdateTransferControlResponse}
 */
proto.account_endpoint.UpdateTransferControlResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.account_endpoint.UpdateTransferControlReplay;
      reader.readMessage(value,proto.account_endpoint.UpdateTransferControlReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_endpoint.UpdateTransferControlResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_endpoint.UpdateTransferControlResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_endpoint.UpdateTransferControlResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_endpoint.UpdateTransferControlResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.account_endpoint.UpdateTransferControlReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateTransferControlReplay success = 1;
 * @return {?proto.account_endpoint.UpdateTransferControlReplay}
 */
proto.account_endpoint.UpdateTransferControlResponse.prototype.getSuccess = function() {
  return /** @type{?proto.account_endpoint.UpdateTransferControlReplay} */ (
    jspb.Message.getWrapperField(this, proto.account_endpoint.UpdateTransferControlReplay, 1));
};


/**
 * @param {?proto.account_endpoint.UpdateTransferControlReplay|undefined} value
 * @return {!proto.account_endpoint.UpdateTransferControlResponse} returns this
*/
proto.account_endpoint.UpdateTransferControlResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.account_endpoint.UpdateTransferControlResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_endpoint.UpdateTransferControlResponse} returns this
 */
proto.account_endpoint.UpdateTransferControlResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_endpoint.UpdateTransferControlResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.account_endpoint);
