/**
 * @fileoverview gRPC-Web generated client stub for account_type_rule_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as account_type_rule_pb from './account_type_rule_pb';


export class AccountTypeRuleEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAdd = new grpcWeb.MethodDescriptor(
    '/account_type_rule_endpoint.AccountTypeRuleEndpoint/Add',
    grpcWeb.MethodType.UNARY,
    account_type_rule_pb.AddAccountTypeRuleRequest,
    account_type_rule_pb.AddAccountTypeRuleResponse,
    (request: account_type_rule_pb.AddAccountTypeRuleRequest) => {
      return request.serializeBinary();
    },
    account_type_rule_pb.AddAccountTypeRuleResponse.deserializeBinary
  );

  add(
    request: account_type_rule_pb.AddAccountTypeRuleRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_type_rule_pb.AddAccountTypeRuleResponse>;

  add(
    request: account_type_rule_pb.AddAccountTypeRuleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_type_rule_pb.AddAccountTypeRuleResponse) => void): grpcWeb.ClientReadableStream<account_type_rule_pb.AddAccountTypeRuleResponse>;

  add(
    request: account_type_rule_pb.AddAccountTypeRuleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_type_rule_pb.AddAccountTypeRuleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_type_rule_endpoint.AccountTypeRuleEndpoint/Add',
        request,
        metadata || {},
        this.methodDescriptorAdd,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_type_rule_endpoint.AccountTypeRuleEndpoint/Add',
    request,
    metadata || {},
    this.methodDescriptorAdd);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/account_type_rule_endpoint.AccountTypeRuleEndpoint/Update',
    grpcWeb.MethodType.UNARY,
    account_type_rule_pb.UpdateAccountTypeRuleRequest,
    account_type_rule_pb.UpdateAccountTypeRuleResponse,
    (request: account_type_rule_pb.UpdateAccountTypeRuleRequest) => {
      return request.serializeBinary();
    },
    account_type_rule_pb.UpdateAccountTypeRuleResponse.deserializeBinary
  );

  update(
    request: account_type_rule_pb.UpdateAccountTypeRuleRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_type_rule_pb.UpdateAccountTypeRuleResponse>;

  update(
    request: account_type_rule_pb.UpdateAccountTypeRuleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_type_rule_pb.UpdateAccountTypeRuleResponse) => void): grpcWeb.ClientReadableStream<account_type_rule_pb.UpdateAccountTypeRuleResponse>;

  update(
    request: account_type_rule_pb.UpdateAccountTypeRuleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_type_rule_pb.UpdateAccountTypeRuleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_type_rule_endpoint.AccountTypeRuleEndpoint/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_type_rule_endpoint.AccountTypeRuleEndpoint/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorUpdateActivity = new grpcWeb.MethodDescriptor(
    '/account_type_rule_endpoint.AccountTypeRuleEndpoint/UpdateActivity',
    grpcWeb.MethodType.UNARY,
    account_type_rule_pb.UpdateAccountTypeRuleActivityRequest,
    account_type_rule_pb.UpdateAccountTypeRuleActivityResponse,
    (request: account_type_rule_pb.UpdateAccountTypeRuleActivityRequest) => {
      return request.serializeBinary();
    },
    account_type_rule_pb.UpdateAccountTypeRuleActivityResponse.deserializeBinary
  );

  updateActivity(
    request: account_type_rule_pb.UpdateAccountTypeRuleActivityRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_type_rule_pb.UpdateAccountTypeRuleActivityResponse>;

  updateActivity(
    request: account_type_rule_pb.UpdateAccountTypeRuleActivityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_type_rule_pb.UpdateAccountTypeRuleActivityResponse) => void): grpcWeb.ClientReadableStream<account_type_rule_pb.UpdateAccountTypeRuleActivityResponse>;

  updateActivity(
    request: account_type_rule_pb.UpdateAccountTypeRuleActivityRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_type_rule_pb.UpdateAccountTypeRuleActivityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_type_rule_endpoint.AccountTypeRuleEndpoint/UpdateActivity',
        request,
        metadata || {},
        this.methodDescriptorUpdateActivity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_type_rule_endpoint.AccountTypeRuleEndpoint/UpdateActivity',
    request,
    metadata || {},
    this.methodDescriptorUpdateActivity);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/account_type_rule_endpoint.AccountTypeRuleEndpoint/Delete',
    grpcWeb.MethodType.UNARY,
    account_type_rule_pb.DeleteAccountTypeRuleRequest,
    account_type_rule_pb.DeleteAccountTypeRuleResponse,
    (request: account_type_rule_pb.DeleteAccountTypeRuleRequest) => {
      return request.serializeBinary();
    },
    account_type_rule_pb.DeleteAccountTypeRuleResponse.deserializeBinary
  );

  delete(
    request: account_type_rule_pb.DeleteAccountTypeRuleRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_type_rule_pb.DeleteAccountTypeRuleResponse>;

  delete(
    request: account_type_rule_pb.DeleteAccountTypeRuleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_type_rule_pb.DeleteAccountTypeRuleResponse) => void): grpcWeb.ClientReadableStream<account_type_rule_pb.DeleteAccountTypeRuleResponse>;

  delete(
    request: account_type_rule_pb.DeleteAccountTypeRuleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_type_rule_pb.DeleteAccountTypeRuleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_type_rule_endpoint.AccountTypeRuleEndpoint/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_type_rule_endpoint.AccountTypeRuleEndpoint/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorGetAccountTypeRule = new grpcWeb.MethodDescriptor(
    '/account_type_rule_endpoint.AccountTypeRuleEndpoint/GetAccountTypeRule',
    grpcWeb.MethodType.UNARY,
    account_type_rule_pb.GetAccountTypeRuleRequest,
    account_type_rule_pb.GetAccountTypeRuleResponse,
    (request: account_type_rule_pb.GetAccountTypeRuleRequest) => {
      return request.serializeBinary();
    },
    account_type_rule_pb.GetAccountTypeRuleResponse.deserializeBinary
  );

  getAccountTypeRule(
    request: account_type_rule_pb.GetAccountTypeRuleRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_type_rule_pb.GetAccountTypeRuleResponse>;

  getAccountTypeRule(
    request: account_type_rule_pb.GetAccountTypeRuleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_type_rule_pb.GetAccountTypeRuleResponse) => void): grpcWeb.ClientReadableStream<account_type_rule_pb.GetAccountTypeRuleResponse>;

  getAccountTypeRule(
    request: account_type_rule_pb.GetAccountTypeRuleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_type_rule_pb.GetAccountTypeRuleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_type_rule_endpoint.AccountTypeRuleEndpoint/GetAccountTypeRule',
        request,
        metadata || {},
        this.methodDescriptorGetAccountTypeRule,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_type_rule_endpoint.AccountTypeRuleEndpoint/GetAccountTypeRule',
    request,
    metadata || {},
    this.methodDescriptorGetAccountTypeRule);
  }

  methodDescriptorGetAccountTypeRules = new grpcWeb.MethodDescriptor(
    '/account_type_rule_endpoint.AccountTypeRuleEndpoint/GetAccountTypeRules',
    grpcWeb.MethodType.UNARY,
    account_type_rule_pb.GetAccountTypeRulesRequest,
    account_type_rule_pb.GetAccountTypeRulesResponse,
    (request: account_type_rule_pb.GetAccountTypeRulesRequest) => {
      return request.serializeBinary();
    },
    account_type_rule_pb.GetAccountTypeRulesResponse.deserializeBinary
  );

  getAccountTypeRules(
    request: account_type_rule_pb.GetAccountTypeRulesRequest,
    metadata: grpcWeb.Metadata | null): Promise<account_type_rule_pb.GetAccountTypeRulesResponse>;

  getAccountTypeRules(
    request: account_type_rule_pb.GetAccountTypeRulesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_type_rule_pb.GetAccountTypeRulesResponse) => void): grpcWeb.ClientReadableStream<account_type_rule_pb.GetAccountTypeRulesResponse>;

  getAccountTypeRules(
    request: account_type_rule_pb.GetAccountTypeRulesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_type_rule_pb.GetAccountTypeRulesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account_type_rule_endpoint.AccountTypeRuleEndpoint/GetAccountTypeRules',
        request,
        metadata || {},
        this.methodDescriptorGetAccountTypeRules,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account_type_rule_endpoint.AccountTypeRuleEndpoint/GetAccountTypeRules',
    request,
    metadata || {},
    this.methodDescriptorGetAccountTypeRules);
  }

}

