import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { UserEndpointClient } from '../../../../../../repository/UserManagement/Managers/UserServiceClientPb';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, refreshSession, getSession, ACCOUNTANT_API_URL } from '../../../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../../../app/Helpers';
import { TableState } from '../../../../../common/Table/TableSate';
import { number } from 'yup';
import { AccountTypeRuleEndpointClient } from '../../../../../../repository/Accountant/Account_type_ruleServiceClientPb';
import { GetAccountTypesRequest, GetAccountTypesResponse } from '../../../../../../repository/Accountant/account_type_pb';
import { AccountTypeEndpointClient } from '../../../../../../repository/Accountant/Account_typeServiceClientPb';
import { TreasuryTypeRuleEndpointClient } from '../../../../../../repository/Accountant/Treasury_type_ruleServiceClientPb';
import { DeleteTreasuryTypeRuleRequest, GetTreasuryTypeRulesRequest, DeleteTreasuryTypeRuleResponse, GetTreasuryTypeRulesResponse, UpdateTreasuryTypeRuleActivityRequest, UpdateTreasuryTypeRuleActivityResponse } from '../../../../../../repository/Accountant/treasury_type_rule_pb';


export interface TreasuryTypeRulesPageState {
    set: TableState
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    searchText: string | undefined,
    filters: any[],
    activityFilterOption: { key: number, text: string },
    operationFilterOption: { key: number, text: string },
    directReservationFilterOption: { key: number, text: string },

    accountTypes: TableState
}

const initialState: TreasuryTypeRulesPageState = {
    set: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    accountTypes: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    isChangeStateLoading: false,
    message: undefined,
    searchText: undefined,
    filters: [],
    activityFilterOption: { key: 0, text: "" },
    operationFilterOption: { key: 0, text: "" },
    directReservationFilterOption: { key: 0, text: "" },


}

const client = new TreasuryTypeRuleEndpointClient(ACCOUNTANT_API_URL, null, null);
const accountTypesClient = new AccountTypeEndpointClient(ACCOUNTANT_API_URL, null, null);


export const getItems = createAsyncThunk<GetTreasuryTypeRulesResponse.AsObject, APIRequest<GetTreasuryTypeRulesRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/treasuryTypeRules/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await client.getTreasuryTypeRules(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

export const updateActivity = createAsyncThunk<APIResponse<UpdateTreasuryTypeRuleActivityResponse.AsObject>, APIRequest<UpdateTreasuryTypeRuleActivityRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/treasuryTypeRules/updateActivity',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.updateActivity(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId(), state: req.body.getIsactive() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)


export const deleteItem = createAsyncThunk<APIResponse<DeleteTreasuryTypeRuleResponse.AsObject>, APIRequest<DeleteTreasuryTypeRuleRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/treasuryTypeRules/delete',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.delete(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)



export const getAccountTypes = createAsyncThunk<GetAccountTypesResponse.AsObject, APIRequest<GetAccountTypesRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/treasuryTypeRules/accountTypes/getAccountTypes',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await accountTypesClient.getAccountTypes(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

const getFilters = (state: TreasuryTypeRulesPageState): any[] => {
    const selected = [];

    if (state.accountTypes.selected.length > 0) {
        selected.push({ key: 1, name: state.accountTypes.selected.at(0).name });
    }
    if (state.activityFilterOption.key != 0) {
        selected.push({ key: 2, name: state.activityFilterOption.text });
    }
    if (state.operationFilterOption.key != 0) {
        selected.push({ key: 3, name: state.operationFilterOption.text });
    }
    if (state.directReservationFilterOption.key != 0) {
        selected.push({ key: 4, name: state.directReservationFilterOption.text });
    }
    
    return selected;
}

export const treasuryTypeRulesPageSlice = createSlice({
    name: 'pages/accountant/treasuryTypeRules',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.set = initialState.set;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
            state.searchText = initialState.searchText;
            state.activityFilterOption = initialState.activityFilterOption;
            state.filters = initialState.filters;
            state.accountTypes = initialState.accountTypes;
            state.operationFilterOption = initialState.operationFilterOption;
            state.directReservationFilterOption = initialState.directReservationFilterOption;

        },

        resetSet: (state) => {
            state.set.items = [];
            state.set.hasMore = true;
        },

        resetAccountTypes: (state) => {
            state.accountTypes.items = [];
            state.accountTypes.hasMore = true;
        },
        setSort: (state, action: PayloadAction<boolean>) => {
            state.set.isDescending = action.payload;
        },
        setMessage: (state, action: PayloadAction<any>) => {
            state.message = action.payload;
        },
        setNumberOfResults: (state, action: PayloadAction<number>) => {
            state.set.numberOfResults = action.payload;
        },
        setSelectedItems: (state, action: PayloadAction<any[]>) => {
            state.set.selected = action.payload;
        },

        setSelectedAccountType: (state, action: PayloadAction<any[]>) => {
            state.accountTypes.selected = action.payload;
            state.filters = getFilters(state);
        },

        setSearchText: (state, action: PayloadAction<string | undefined>) => {
            state.searchText = action.payload;
        },
        setIsFilteredSet: (state, action: PayloadAction<boolean>) => {
            state.set.isFilteredSet = action.payload;
        },

        setIsFilteredAccountTypesSet: (state, action: PayloadAction<boolean>) => {
            state.accountTypes.isFilteredSet = action.payload;
        },
        setActivityFilterOption: (state, action: PayloadAction<{ key: number, text: string }>) => {
            state.activityFilterOption = action.payload;
            state.filters = getFilters(state);
        },
        setDirectReservationFilterOption: (state, action: PayloadAction<{ key: number, text: string }>) => {
            state.directReservationFilterOption = action.payload;
            state.filters = getFilters(state);
        },
        setOperationFilterOption: (state, action: PayloadAction<{ key: number, text: string }>) => {
            state.operationFilterOption = action.payload;
            state.filters = getFilters(state);
        },
        addItem: (state, action: PayloadAction<any>) => {
            if (!state.set.isFilteredSet) {
                if (!state.set.hasMore && !state.set.isDescending) {
                    state.set.items.push(action.payload);
                } else if (state.set.isDescending) {
                    state.set.items.unshift(action.payload);
                }
            }
        },
        updateItem: (state, action: PayloadAction<any>) => {
            let selected = state.set.selected;
            if (action?.payload) {
                if (selected.length > 0 && selected.at(0).id == action?.payload?.id) {
                    selected[0] = action?.payload
                }
                var u = state.set.items.findIndex(e => e.id == action?.payload?.id)
                if (u >= 0) {
                    state.set.items[u] = action?.payload
                }
            }

        }
    },

    extraReducers: (builder) => {
        builder.addCase(getItems.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.treasurytyperulesList.map(val => {
                    return {
                        id: val.id,
                        treasuryTypeId: val.treasurytype?.id?.value ?? "",
                        treasuryTypeName: val.treasurytype?.name?.value ?? "",
                        accountTypeId: val.accounttype?.id?.value ?? "",
                        accountTypeName: val.accounttype?.name?.value ?? "",
                        operation: val.operation?.value ?? undefined,
                        feeId: val.fee?.id?.value ?? "",
                        feeName: val.fee?.name?.value ?? "",
                        isActive: val.isactive?.value ?? undefined,
                        directReservation: val.directreservation?.value ?? undefined,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                let l = [];
                if (state.set.items.length != 0) {
                    l = state.set.items.slice(0, state.set.items.length - 10)
                }
                l = l.concat(r);
                if (r.length < state.set.numberOfResults) {
                    state.set.hasMore = false;
                } else {
                    l = l.concat(...Array(10).fill(null));
                }
                state.set.items = l
            } else {
                state.set.hasMore = false;
            }
            state.set.isFetching = false

        })
        builder.addCase(getItems.rejected, (state, action) => {
            state.set.isFetching = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getItems.pending, (state, action) => {
            state.set.isFetching = true;
            state.message = undefined;
        })



        builder.addCase(deleteItem.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                let selected = state.set.selected;
                if (payload?.metadata) {
                    if (selected.length > 0 && selected.at(0).id == payload?.metadata.id) {
                        state.set.selected = []
                    }
                    var u = state.set.items.findIndex(e => e.id == payload?.metadata.id)
                    if (u >= 0) {
                        state.set.items.splice(u, 1);
                    }
                }
            }
        })
        builder.addCase(deleteItem.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(deleteItem.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

        builder.addCase(updateActivity.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                let selected = state.set.selected;
                if (payload?.metadata) {
                    if (selected.length > 0 && selected.at(0).id == payload?.metadata.id) {
                        selected.at(0).isActive = payload?.metadata.state
                    }
                    var u = state.set.items.findIndex(e => e.id == payload?.metadata.id)
                    if (u >= 0) {
                        state.set.items.at(u).isActive = payload?.metadata.state
                    }

                }
            }
        })
        builder.addCase(updateActivity.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateActivity.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })


        builder.addCase(getAccountTypes.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.accounttypesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.accountTypes.numberOfResults) {
                    state.accountTypes.hasMore = false;
                }
                state.accountTypes.items = state.accountTypes.items.concat(r);
                //console.log(state.items)

            } else {
                state.accountTypes.hasMore = false;
            }
            state.accountTypes.isFetching = false

        })
        builder.addCase(getAccountTypes.rejected, (state, action) => {
            state.accountTypes.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getAccountTypes.pending, (state, action) => {
            state.accountTypes.isFetching = true;
            //state.message = undefined;
        })

    }
})

export const { reset, dismissMessage, setSort, setDirectReservationFilterOption, setNumberOfResults, resetSet, setMessage, setSelectedItems, setSearchText, setActivityFilterOption, setIsFilteredSet, addItem, updateItem, resetAccountTypes, setIsFilteredAccountTypesSet, setOperationFilterOption, setSelectedAccountType } = treasuryTypeRulesPageSlice.actions

export default treasuryTypeRulesPageSlice.reducer


