import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError } from 'grpc-web';
import { RoleEndpointClient } from '../../../../../repository/UserManagement/Managers/RoleServiceClientPb';
import { AddRoleRequest, AddRoleResponse, UpdateRoleRequest, UpdateRoleResponse } from '../../../../../repository/UserManagement/Managers/role_pb';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, refreshSession, getSession, ACCOUNTANT_API_URL } from '../../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../../app/Helpers';
import { DynamicField } from '../../../../common/DynamicFields/DynamicFields';
import { FeeEndpointClient } from '../../../../../repository/Accountant/FeeServiceClientPb';
import { AddFeeCollectionAccountRequest, AddFeeCollectionAccountResponse, AddFeeRequest, AddFeeResponse, GetFeeRequest, GetFeeResponse, UpdateFeeRequest, UpdateFeeResponse } from '../../../../../repository/Accountant/fee_pb';
import { GetAccountsRequest, GetAccountsResponse } from '../../../../../repository/Accountant/account_pb';
import { AccountEndpointClient } from '../../../../../repository/Accountant/AccountServiceClientPb';
import { TableState } from '../../../../common/Table/TableSate';
import { GetCurrenciesRequest, GetCurrenciesResponse } from '../../../../../repository/Accountant/currency_pb';
import { CurrencyEndpointClient } from '../../../../../repository/Accountant/CurrencyServiceClientPb';
import { AppendTreasuryCurrencyRequest, AppendTreasuryCurrencyResponse } from '../../../../../repository/Accountant/treasury_pb';
import { TreasuryEndpointClient } from '../../../../../repository/Accountant/TreasuryServiceClientPb';
export interface TreasuryDipositsFormState {
    isLoading: boolean
    message?: ApiMessage
    stage1: any,
    currencies: TableState
}

const initialState: TreasuryDipositsFormState = {
    isLoading: false,
    message: undefined,
    stage1: undefined,
    currencies: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },

}

const client = new TreasuryEndpointClient(ACCOUNTANT_API_URL, null, null);
const clientCurrency = new CurrencyEndpointClient(ACCOUNTANT_API_URL, null, null);



export const add = createAsyncThunk<AppendTreasuryCurrencyResponse.AsObject, APIRequest<AppendTreasuryCurrencyRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/treasuryDeposits/add',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.appendCurrency(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)


export const getCurrencies = createAsyncThunk<GetCurrenciesResponse.AsObject, APIRequest<GetCurrenciesRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/treasuryDeposits/currencies/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await clientCurrency.getCurrencies(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const treasuryDepositsFormSlice = createSlice({
    name: 'forms/accountant/treasuries/treasuryDeposits',
    initialState,
    reducers: {

        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.isLoading = false;
            state.message = undefined;
            state.stage1 = initialState.stage1;
            state.currencies = initialState.currencies;
        },
     
      
        resetCurrencies: (state) => {
            state.currencies.items = [];
            state.currencies.hasMore = true;
        },
        setSelectedCurrencyItems: (state, action: PayloadAction<any[]>) => {
            state.currencies.selected = action.payload;

        },

        setIsFilteredCurrenciesSet: (state, action: PayloadAction<boolean>) => {
            state.currencies.isFilteredSet = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(add.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            if (payload)
                state.message = toApiMessage(payload.success?.message);

        })
        builder.addCase(add.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(add.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })
        
       

        builder.addCase(getCurrencies.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.currenciesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        code: val.code?.value ?? "",
                        icon: val.iconsource?.value ?? "",
                        form: val.form?.formid,
                        symbol: val.symbol?.value ?? "",
                        decimalPlaces: val.decimalplaces?.value ?? "",
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.currencies.numberOfResults) {
                    state.currencies.hasMore = false;
                }
                state.currencies.items = state.currencies.items.concat(r);
               

                //console.log(state.items)

            } else {
                state.currencies.hasMore = false;
            }
            state.currencies.isFetching = false

        })
        builder.addCase(getCurrencies.rejected, (state, action) => {
            state.currencies.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getCurrencies.pending, (state, action) => {
            state.currencies.isFetching = true;
            //state.message = undefined;
        })

    }
})

export const { reset, dismissMessage, resetCurrencies, setSelectedCurrencyItems, setIsFilteredCurrenciesSet } = treasuryDepositsFormSlice.actions

export default treasuryDepositsFormSlice.reducer


