/**
 * @fileoverview gRPC-Web generated client stub for limitation_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as limitation_pb from './limitation_pb';


export class LimitationEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAdd = new grpcWeb.MethodDescriptor(
    '/limitation_endpoint.LimitationEndpoint/Add',
    grpcWeb.MethodType.UNARY,
    limitation_pb.AddLimitationRequest,
    limitation_pb.AddLimitationResponse,
    (request: limitation_pb.AddLimitationRequest) => {
      return request.serializeBinary();
    },
    limitation_pb.AddLimitationResponse.deserializeBinary
  );

  add(
    request: limitation_pb.AddLimitationRequest,
    metadata: grpcWeb.Metadata | null): Promise<limitation_pb.AddLimitationResponse>;

  add(
    request: limitation_pb.AddLimitationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: limitation_pb.AddLimitationResponse) => void): grpcWeb.ClientReadableStream<limitation_pb.AddLimitationResponse>;

  add(
    request: limitation_pb.AddLimitationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: limitation_pb.AddLimitationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/limitation_endpoint.LimitationEndpoint/Add',
        request,
        metadata || {},
        this.methodDescriptorAdd,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/limitation_endpoint.LimitationEndpoint/Add',
    request,
    metadata || {},
    this.methodDescriptorAdd);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/limitation_endpoint.LimitationEndpoint/Update',
    grpcWeb.MethodType.UNARY,
    limitation_pb.UpdateLimitationRequest,
    limitation_pb.UpdateLimitationResponse,
    (request: limitation_pb.UpdateLimitationRequest) => {
      return request.serializeBinary();
    },
    limitation_pb.UpdateLimitationResponse.deserializeBinary
  );

  update(
    request: limitation_pb.UpdateLimitationRequest,
    metadata: grpcWeb.Metadata | null): Promise<limitation_pb.UpdateLimitationResponse>;

  update(
    request: limitation_pb.UpdateLimitationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: limitation_pb.UpdateLimitationResponse) => void): grpcWeb.ClientReadableStream<limitation_pb.UpdateLimitationResponse>;

  update(
    request: limitation_pb.UpdateLimitationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: limitation_pb.UpdateLimitationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/limitation_endpoint.LimitationEndpoint/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/limitation_endpoint.LimitationEndpoint/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorUpdateActivity = new grpcWeb.MethodDescriptor(
    '/limitation_endpoint.LimitationEndpoint/UpdateActivity',
    grpcWeb.MethodType.UNARY,
    limitation_pb.UpdateLimitationActivityRequest,
    limitation_pb.UpdateLimitationActivityResponse,
    (request: limitation_pb.UpdateLimitationActivityRequest) => {
      return request.serializeBinary();
    },
    limitation_pb.UpdateLimitationActivityResponse.deserializeBinary
  );

  updateActivity(
    request: limitation_pb.UpdateLimitationActivityRequest,
    metadata: grpcWeb.Metadata | null): Promise<limitation_pb.UpdateLimitationActivityResponse>;

  updateActivity(
    request: limitation_pb.UpdateLimitationActivityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: limitation_pb.UpdateLimitationActivityResponse) => void): grpcWeb.ClientReadableStream<limitation_pb.UpdateLimitationActivityResponse>;

  updateActivity(
    request: limitation_pb.UpdateLimitationActivityRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: limitation_pb.UpdateLimitationActivityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/limitation_endpoint.LimitationEndpoint/UpdateActivity',
        request,
        metadata || {},
        this.methodDescriptorUpdateActivity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/limitation_endpoint.LimitationEndpoint/UpdateActivity',
    request,
    metadata || {},
    this.methodDescriptorUpdateActivity);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/limitation_endpoint.LimitationEndpoint/Delete',
    grpcWeb.MethodType.UNARY,
    limitation_pb.DeleteLimitationRequest,
    limitation_pb.DeleteLimitationResponse,
    (request: limitation_pb.DeleteLimitationRequest) => {
      return request.serializeBinary();
    },
    limitation_pb.DeleteLimitationResponse.deserializeBinary
  );

  delete(
    request: limitation_pb.DeleteLimitationRequest,
    metadata: grpcWeb.Metadata | null): Promise<limitation_pb.DeleteLimitationResponse>;

  delete(
    request: limitation_pb.DeleteLimitationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: limitation_pb.DeleteLimitationResponse) => void): grpcWeb.ClientReadableStream<limitation_pb.DeleteLimitationResponse>;

  delete(
    request: limitation_pb.DeleteLimitationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: limitation_pb.DeleteLimitationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/limitation_endpoint.LimitationEndpoint/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/limitation_endpoint.LimitationEndpoint/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorGetLimitation = new grpcWeb.MethodDescriptor(
    '/limitation_endpoint.LimitationEndpoint/GetLimitation',
    grpcWeb.MethodType.UNARY,
    limitation_pb.GetLimitationRequest,
    limitation_pb.GetLimitationResponse,
    (request: limitation_pb.GetLimitationRequest) => {
      return request.serializeBinary();
    },
    limitation_pb.GetLimitationResponse.deserializeBinary
  );

  getLimitation(
    request: limitation_pb.GetLimitationRequest,
    metadata: grpcWeb.Metadata | null): Promise<limitation_pb.GetLimitationResponse>;

  getLimitation(
    request: limitation_pb.GetLimitationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: limitation_pb.GetLimitationResponse) => void): grpcWeb.ClientReadableStream<limitation_pb.GetLimitationResponse>;

  getLimitation(
    request: limitation_pb.GetLimitationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: limitation_pb.GetLimitationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/limitation_endpoint.LimitationEndpoint/GetLimitation',
        request,
        metadata || {},
        this.methodDescriptorGetLimitation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/limitation_endpoint.LimitationEndpoint/GetLimitation',
    request,
    metadata || {},
    this.methodDescriptorGetLimitation);
  }

  methodDescriptorGetLimitations = new grpcWeb.MethodDescriptor(
    '/limitation_endpoint.LimitationEndpoint/GetLimitations',
    grpcWeb.MethodType.UNARY,
    limitation_pb.GetLimitationsRequest,
    limitation_pb.GetLimitationsResponse,
    (request: limitation_pb.GetLimitationsRequest) => {
      return request.serializeBinary();
    },
    limitation_pb.GetLimitationsResponse.deserializeBinary
  );

  getLimitations(
    request: limitation_pb.GetLimitationsRequest,
    metadata: grpcWeb.Metadata | null): Promise<limitation_pb.GetLimitationsResponse>;

  getLimitations(
    request: limitation_pb.GetLimitationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: limitation_pb.GetLimitationsResponse) => void): grpcWeb.ClientReadableStream<limitation_pb.GetLimitationsResponse>;

  getLimitations(
    request: limitation_pb.GetLimitationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: limitation_pb.GetLimitationsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/limitation_endpoint.LimitationEndpoint/GetLimitations',
        request,
        metadata || {},
        this.methodDescriptorGetLimitations,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/limitation_endpoint.LimitationEndpoint/GetLimitations',
    request,
    metadata || {},
    this.methodDescriptorGetLimitations);
  }

}

