// source: currency_pair_price_log.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('./common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.currency_pair_endpoint.CurrencyPairPriceLog', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogRequest', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse.ResultCase', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.GetCurrencyPairPriceLogRequest', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse.ResultCase', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse.ResultCase', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogRequest', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse.ResultCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogRequest.displayName = 'proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogRequest.displayName = 'proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_pair_endpoint.GetCurrencyPairPriceLogRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.GetCurrencyPairPriceLogRequest.displayName = 'proto.currency_pair_endpoint.GetCurrencyPairPriceLogRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.displayName = 'proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay.displayName = 'proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay.displayName = 'proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.displayName = 'proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_pair_endpoint.CurrencyPairPriceLog, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.CurrencyPairPriceLog.displayName = 'proto.currency_pair_endpoint.CurrencyPairPriceLog';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay.repeatedFields_, null);
};
goog.inherits(proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay.displayName = 'proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse.oneofGroups_);
};
goog.inherits(proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse.displayName = 'proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse.oneofGroups_);
};
goog.inherits(proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse.displayName = 'proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse.oneofGroups_);
};
goog.inherits(proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse.displayName = 'proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse.oneofGroups_);
};
goog.inherits(proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse.displayName = 'proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogRequest}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogRequest;
  return proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogRequest}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogRequest} returns this
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogRequest.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogRequest} returns this
*/
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogRequest.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogRequest} returns this
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogRequest.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogRequest.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogRequest}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogRequest;
  return proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogRequest}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogRequest} returns this
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.GetCurrencyPairPriceLogRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogRequest}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.GetCurrencyPairPriceLogRequest;
  return proto.currency_pair_endpoint.GetCurrencyPairPriceLogRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogRequest}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.GetCurrencyPairPriceLogRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogRequest} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    pair: (f = msg.getPair()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    order: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    nextto: (f = msg.getNextto()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    numofresults: jspb.Message.getFieldWithDefault(msg, 8, 0),
    from: (f = msg.getFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    to: (f = msg.getTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest;
  return proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPair(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrder(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNextto(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumofresults(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPair();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getNextto();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNumofresults();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value organization = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue pair = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.prototype.getPair = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.prototype.setPair = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.prototype.clearPair = function() {
  return this.setPair(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.prototype.hasPair = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue search = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool order = 6;
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.prototype.getOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional google.protobuf.StringValue nextTo = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.prototype.getNextto = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.prototype.setNextto = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.prototype.clearNextto = function() {
  return this.setNextto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.prototype.hasNextto = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int32 numOfResults = 8;
 * @return {number}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.prototype.getNumofresults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.prototype.setNumofresults = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp from = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.prototype.getFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp to = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.prototype.getTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsRequest.prototype.hasTo = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f),
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay;
  return proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.MessageResponse message = 1;
 * @return {?proto.common.MessageResponse}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 1));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay} returns this
*/
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay} returns this
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp updatedDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay} returns this
*/
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay} returns this
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f),
    deteleteddate: (f = msg.getDeteleteddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay;
  return proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeteleteddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getDeteleteddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.MessageResponse message = 1;
 * @return {?proto.common.MessageResponse}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 1));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay} returns this
*/
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay} returns this
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp deteletedDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay.prototype.getDeteleteddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay} returns this
*/
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay.prototype.setDeteleteddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay} returns this
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay.prototype.clearDeteleteddate = function() {
  return this.setDeteleteddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay.prototype.hasDeteleteddate = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    currencypair: (f = msg.getCurrencypair()) && Protos_common_pb.CurrencyPairResponse.toObject(includeInstance, f),
    sellprice: (f = msg.getSellprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    buyprice: (f = msg.getBuyprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    tradingprice: (f = msg.getTradingprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    volumebase: (f = msg.getVolumebase()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    volumequote: (f = msg.getVolumequote()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    trigger: (f = msg.getTrigger()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay;
  return proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new Protos_common_pb.CurrencyPairResponse;
      reader.readMessage(value,Protos_common_pb.CurrencyPairResponse.deserializeBinaryFromReader);
      msg.setCurrencypair(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSellprice(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBuyprice(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTradingprice(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setVolumebase(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setVolumequote(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setTrigger(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 12:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCurrencypair();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.CurrencyPairResponse.serializeBinaryToWriter
    );
  }
  f = message.getSellprice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getBuyprice();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTradingprice();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getVolumebase();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getVolumequote();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTrigger();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.CurrencyPairResponse currencyPair = 2;
 * @return {?proto.common.CurrencyPairResponse}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.getCurrencypair = function() {
  return /** @type{?proto.common.CurrencyPairResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.CurrencyPairResponse, 2));
};


/**
 * @param {?proto.common.CurrencyPairResponse|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.setCurrencypair = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.clearCurrencypair = function() {
  return this.setCurrencypair(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.hasCurrencypair = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue sellPrice = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.getSellprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.setSellprice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.clearSellprice = function() {
  return this.setSellprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.hasSellprice = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue buyPrice = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.getBuyprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.setBuyprice = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.clearBuyprice = function() {
  return this.setBuyprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.hasBuyprice = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue tradingPrice = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.getTradingprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.setTradingprice = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.clearTradingprice = function() {
  return this.setTradingprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.hasTradingprice = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue volumeBase = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.getVolumebase = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.setVolumebase = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.clearVolumebase = function() {
  return this.setVolumebase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.hasVolumebase = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue volumeQuote = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.getVolumequote = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.setVolumequote = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.clearVolumequote = function() {
  return this.setVolumequote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.hasVolumequote = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Int32Value trigger = 10;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.getTrigger = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 10));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.setTrigger = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.clearTrigger = function() {
  return this.setTrigger(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.hasTrigger = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional common.ProfileResponse createdBy = 12;
 * @return {?proto.common.ProfileResponse}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 12));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.CurrencyPairPriceLog.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.CurrencyPairPriceLog} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    currencypair: (f = msg.getCurrencypair()) && Protos_common_pb.CurrencyPairResponse.toObject(includeInstance, f),
    sellprice: (f = msg.getSellprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    buyprice: (f = msg.getBuyprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    tradingprice: (f = msg.getTradingprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    volumebase: (f = msg.getVolumebase()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    volumequote: (f = msg.getVolumequote()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    trigger: (f = msg.getTrigger()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.CurrencyPairPriceLog}
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.CurrencyPairPriceLog;
  return proto.currency_pair_endpoint.CurrencyPairPriceLog.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.CurrencyPairPriceLog} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.CurrencyPairPriceLog}
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new Protos_common_pb.CurrencyPairResponse;
      reader.readMessage(value,Protos_common_pb.CurrencyPairResponse.deserializeBinaryFromReader);
      msg.setCurrencypair(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSellprice(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBuyprice(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTradingprice(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setVolumebase(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setVolumequote(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setTrigger(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 12:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.CurrencyPairPriceLog.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.CurrencyPairPriceLog} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCurrencypair();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.CurrencyPairResponse.serializeBinaryToWriter
    );
  }
  f = message.getSellprice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getBuyprice();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTradingprice();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getVolumebase();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getVolumequote();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTrigger();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.CurrencyPairPriceLog} returns this
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.CurrencyPairResponse currencyPair = 2;
 * @return {?proto.common.CurrencyPairResponse}
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.getCurrencypair = function() {
  return /** @type{?proto.common.CurrencyPairResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.CurrencyPairResponse, 2));
};


/**
 * @param {?proto.common.CurrencyPairResponse|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPairPriceLog} returns this
*/
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.setCurrencypair = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPairPriceLog} returns this
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.clearCurrencypair = function() {
  return this.setCurrencypair(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.hasCurrencypair = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue sellPrice = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.getSellprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPairPriceLog} returns this
*/
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.setSellprice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPairPriceLog} returns this
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.clearSellprice = function() {
  return this.setSellprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.hasSellprice = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue buyPrice = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.getBuyprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPairPriceLog} returns this
*/
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.setBuyprice = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPairPriceLog} returns this
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.clearBuyprice = function() {
  return this.setBuyprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.hasBuyprice = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue tradingPrice = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.getTradingprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPairPriceLog} returns this
*/
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.setTradingprice = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPairPriceLog} returns this
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.clearTradingprice = function() {
  return this.setTradingprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.hasTradingprice = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue volumeBase = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.getVolumebase = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPairPriceLog} returns this
*/
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.setVolumebase = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPairPriceLog} returns this
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.clearVolumebase = function() {
  return this.setVolumebase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.hasVolumebase = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue volumeQuote = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.getVolumequote = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPairPriceLog} returns this
*/
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.setVolumequote = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPairPriceLog} returns this
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.clearVolumequote = function() {
  return this.setVolumequote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.hasVolumequote = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Int32Value trigger = 10;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.getTrigger = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 10));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPairPriceLog} returns this
*/
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.setTrigger = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPairPriceLog} returns this
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.clearTrigger = function() {
  return this.setTrigger(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.hasTrigger = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPairPriceLog} returns this
*/
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPairPriceLog} returns this
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional common.ProfileResponse createdBy = 12;
 * @return {?proto.common.ProfileResponse}
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 12));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPairPriceLog} returns this
*/
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPairPriceLog} returns this
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPairPriceLog.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 12) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    currencypairpricelogsList: jspb.Message.toObjectList(msg.getCurrencypairpricelogsList(),
    proto.currency_pair_endpoint.CurrencyPairPriceLog.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay;
  return proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.currency_pair_endpoint.CurrencyPairPriceLog;
      reader.readMessage(value,proto.currency_pair_endpoint.CurrencyPairPriceLog.deserializeBinaryFromReader);
      msg.addCurrencypairpricelogs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrencypairpricelogsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.currency_pair_endpoint.CurrencyPairPriceLog.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CurrencyPairPriceLog currencyPairPriceLogs = 1;
 * @return {!Array<!proto.currency_pair_endpoint.CurrencyPairPriceLog>}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay.prototype.getCurrencypairpricelogsList = function() {
  return /** @type{!Array<!proto.currency_pair_endpoint.CurrencyPairPriceLog>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.currency_pair_endpoint.CurrencyPairPriceLog, 1));
};


/**
 * @param {!Array<!proto.currency_pair_endpoint.CurrencyPairPriceLog>} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay.prototype.setCurrencypairpricelogsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.currency_pair_endpoint.CurrencyPairPriceLog=} opt_value
 * @param {number=} opt_index
 * @return {!proto.currency_pair_endpoint.CurrencyPairPriceLog}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay.prototype.addCurrencypairpricelogs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.currency_pair_endpoint.CurrencyPairPriceLog, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay.prototype.clearCurrencypairpricelogsList = function() {
  return this.setCurrencypairpricelogsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse.ResultCase}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse.prototype.getResultCase = function() {
  return /** @type {proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse;
  return proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay;
      reader.readMessage(value,proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateCurrencyPairPriceLogReplay success = 1;
 * @return {?proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse.prototype.getSuccess = function() {
  return /** @type{?proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay} */ (
    jspb.Message.getWrapperField(this, proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay, 1));
};


/**
 * @param {?proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogReplay|undefined} value
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse} returns this
*/
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse} returns this
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.UpdateCurrencyPairPriceLogResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse.ResultCase}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse.prototype.getResultCase = function() {
  return /** @type {proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse;
  return proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay;
      reader.readMessage(value,proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeleteCurrencyPairPriceLogReplay success = 1;
 * @return {?proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse.prototype.getSuccess = function() {
  return /** @type{?proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay} */ (
    jspb.Message.getWrapperField(this, proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay, 1));
};


/**
 * @param {?proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogReplay|undefined} value
 * @return {!proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse} returns this
*/
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse} returns this
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.DeleteCurrencyPairPriceLogResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse.ResultCase}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse.prototype.getResultCase = function() {
  return /** @type {proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse;
  return proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay;
      reader.readMessage(value,proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetCurrencyPairPriceLogsReplay success = 1;
 * @return {?proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse.prototype.getSuccess = function() {
  return /** @type{?proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay} */ (
    jspb.Message.getWrapperField(this, proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay, 1));
};


/**
 * @param {?proto.currency_pair_endpoint.GetCurrencyPairPriceLogsReplay|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogsResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse.ResultCase}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse.prototype.getResultCase = function() {
  return /** @type {proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse;
  return proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay;
      reader.readMessage(value,proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetCurrencyPairPriceLogReplay success = 1;
 * @return {?proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse.prototype.getSuccess = function() {
  return /** @type{?proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay} */ (
    jspb.Message.getWrapperField(this, proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay, 1));
};


/**
 * @param {?proto.currency_pair_endpoint.GetCurrencyPairPriceLogReplay|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairPriceLogResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.currency_pair_endpoint);
