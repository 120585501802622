/**
 * @fileoverview gRPC-Web generated client stub for currency_pair_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as currency_pair_price_log_pb from './currency_pair_price_log_pb';


export class CurrencyPairPriceLogEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/currency_pair_endpoint.CurrencyPairPriceLogEndpoint/Update',
    grpcWeb.MethodType.UNARY,
    currency_pair_price_log_pb.UpdateCurrencyPairPriceLogRequest,
    currency_pair_price_log_pb.UpdateCurrencyPairPriceLogResponse,
    (request: currency_pair_price_log_pb.UpdateCurrencyPairPriceLogRequest) => {
      return request.serializeBinary();
    },
    currency_pair_price_log_pb.UpdateCurrencyPairPriceLogResponse.deserializeBinary
  );

  update(
    request: currency_pair_price_log_pb.UpdateCurrencyPairPriceLogRequest,
    metadata: grpcWeb.Metadata | null): Promise<currency_pair_price_log_pb.UpdateCurrencyPairPriceLogResponse>;

  update(
    request: currency_pair_price_log_pb.UpdateCurrencyPairPriceLogRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: currency_pair_price_log_pb.UpdateCurrencyPairPriceLogResponse) => void): grpcWeb.ClientReadableStream<currency_pair_price_log_pb.UpdateCurrencyPairPriceLogResponse>;

  update(
    request: currency_pair_price_log_pb.UpdateCurrencyPairPriceLogRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: currency_pair_price_log_pb.UpdateCurrencyPairPriceLogResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/currency_pair_endpoint.CurrencyPairPriceLogEndpoint/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/currency_pair_endpoint.CurrencyPairPriceLogEndpoint/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/currency_pair_endpoint.CurrencyPairPriceLogEndpoint/Delete',
    grpcWeb.MethodType.UNARY,
    currency_pair_price_log_pb.DeleteCurrencyPairPriceLogRequest,
    currency_pair_price_log_pb.DeleteCurrencyPairPriceLogResponse,
    (request: currency_pair_price_log_pb.DeleteCurrencyPairPriceLogRequest) => {
      return request.serializeBinary();
    },
    currency_pair_price_log_pb.DeleteCurrencyPairPriceLogResponse.deserializeBinary
  );

  delete(
    request: currency_pair_price_log_pb.DeleteCurrencyPairPriceLogRequest,
    metadata: grpcWeb.Metadata | null): Promise<currency_pair_price_log_pb.DeleteCurrencyPairPriceLogResponse>;

  delete(
    request: currency_pair_price_log_pb.DeleteCurrencyPairPriceLogRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: currency_pair_price_log_pb.DeleteCurrencyPairPriceLogResponse) => void): grpcWeb.ClientReadableStream<currency_pair_price_log_pb.DeleteCurrencyPairPriceLogResponse>;

  delete(
    request: currency_pair_price_log_pb.DeleteCurrencyPairPriceLogRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: currency_pair_price_log_pb.DeleteCurrencyPairPriceLogResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/currency_pair_endpoint.CurrencyPairPriceLogEndpoint/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/currency_pair_endpoint.CurrencyPairPriceLogEndpoint/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorGetCurrencyPairPriceLog = new grpcWeb.MethodDescriptor(
    '/currency_pair_endpoint.CurrencyPairPriceLogEndpoint/GetCurrencyPairPriceLog',
    grpcWeb.MethodType.UNARY,
    currency_pair_price_log_pb.GetCurrencyPairPriceLogRequest,
    currency_pair_price_log_pb.GetCurrencyPairPriceLogResponse,
    (request: currency_pair_price_log_pb.GetCurrencyPairPriceLogRequest) => {
      return request.serializeBinary();
    },
    currency_pair_price_log_pb.GetCurrencyPairPriceLogResponse.deserializeBinary
  );

  getCurrencyPairPriceLog(
    request: currency_pair_price_log_pb.GetCurrencyPairPriceLogRequest,
    metadata: grpcWeb.Metadata | null): Promise<currency_pair_price_log_pb.GetCurrencyPairPriceLogResponse>;

  getCurrencyPairPriceLog(
    request: currency_pair_price_log_pb.GetCurrencyPairPriceLogRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: currency_pair_price_log_pb.GetCurrencyPairPriceLogResponse) => void): grpcWeb.ClientReadableStream<currency_pair_price_log_pb.GetCurrencyPairPriceLogResponse>;

  getCurrencyPairPriceLog(
    request: currency_pair_price_log_pb.GetCurrencyPairPriceLogRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: currency_pair_price_log_pb.GetCurrencyPairPriceLogResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/currency_pair_endpoint.CurrencyPairPriceLogEndpoint/GetCurrencyPairPriceLog',
        request,
        metadata || {},
        this.methodDescriptorGetCurrencyPairPriceLog,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/currency_pair_endpoint.CurrencyPairPriceLogEndpoint/GetCurrencyPairPriceLog',
    request,
    metadata || {},
    this.methodDescriptorGetCurrencyPairPriceLog);
  }

  methodDescriptorGetCurrencyPairPriceLogs = new grpcWeb.MethodDescriptor(
    '/currency_pair_endpoint.CurrencyPairPriceLogEndpoint/GetCurrencyPairPriceLogs',
    grpcWeb.MethodType.UNARY,
    currency_pair_price_log_pb.GetCurrencyPairPriceLogsRequest,
    currency_pair_price_log_pb.GetCurrencyPairPriceLogsResponse,
    (request: currency_pair_price_log_pb.GetCurrencyPairPriceLogsRequest) => {
      return request.serializeBinary();
    },
    currency_pair_price_log_pb.GetCurrencyPairPriceLogsResponse.deserializeBinary
  );

  getCurrencyPairPriceLogs(
    request: currency_pair_price_log_pb.GetCurrencyPairPriceLogsRequest,
    metadata: grpcWeb.Metadata | null): Promise<currency_pair_price_log_pb.GetCurrencyPairPriceLogsResponse>;

  getCurrencyPairPriceLogs(
    request: currency_pair_price_log_pb.GetCurrencyPairPriceLogsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: currency_pair_price_log_pb.GetCurrencyPairPriceLogsResponse) => void): grpcWeb.ClientReadableStream<currency_pair_price_log_pb.GetCurrencyPairPriceLogsResponse>;

  getCurrencyPairPriceLogs(
    request: currency_pair_price_log_pb.GetCurrencyPairPriceLogsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: currency_pair_price_log_pb.GetCurrencyPairPriceLogsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/currency_pair_endpoint.CurrencyPairPriceLogEndpoint/GetCurrencyPairPriceLogs',
        request,
        metadata || {},
        this.methodDescriptorGetCurrencyPairPriceLogs,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/currency_pair_endpoint.CurrencyPairPriceLogEndpoint/GetCurrencyPairPriceLogs',
    request,
    metadata || {},
    this.methodDescriptorGetCurrencyPairPriceLogs);
  }

}

