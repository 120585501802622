import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { UserEndpointClient } from '../../../../../../repository/UserManagement/Managers/UserServiceClientPb';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, refreshSession, getSession } from '../../../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../../../app/Helpers';

import { TableState } from '../../../../../common/Table/TableSate';
import { number } from 'yup';
import { PrivilegeDataEndpointClient } from '../../../../../../repository/UserManagement/PrivilegedataServiceClientPb';
import { GetPrivilegeDataRequest , GetPrivilegeDataResponse} from '../../../../../../repository/UserManagement/privilegedata_pb';


export interface PrivilegeDataPageState {
    privilegeData: TableState
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    searchText: string | undefined,
    filters: any[],
}

const initialState: PrivilegeDataPageState = {
    privilegeData: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    isChangeStateLoading: false,
    message: undefined,
    searchText: undefined,
    filters: []

}

const client = new PrivilegeDataEndpointClient(API_URL, null, null);
export const getPrivilegeData = createAsyncThunk<GetPrivilegeDataResponse.AsObject, APIRequest<GetPrivilegeDataRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/management/privilegeData/getPrivilegeData',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {

                var r = await client.getPrivilegeData(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }


        }
        return await callReq();
    }

)

const getFilters = (state: PrivilegeDataPageState): any[] => {

    return [];

}
export const managersPrivilegeDataPageSlice = createSlice({
    name: 'pages/management/privilegeDetails/privilegeData',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.privilegeData = initialState.privilegeData;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
            state.searchText = initialState.searchText;
        },
        resetPrivilegeData: (state) => {
            state.privilegeData.items = [];
            state.privilegeData.hasMore = true;
        },

        setSort: (state, action: PayloadAction<boolean>) => {
            state.privilegeData.isDescending = action.payload;
        },
        setNumberOfResults: (state, action: PayloadAction<number>) => {
            state.privilegeData.numberOfResults = action.payload;
        },
        setSelectedPrivilegeData: (state, action: PayloadAction<any[]>) => {
            state.privilegeData.selected = action.payload;
        },
        setSearchText: (state, action: PayloadAction<string | undefined>) => {
            state.searchText = action.payload;
        },
        setIsFilteredSet: (state, action: PayloadAction<boolean>) => {
            state.privilegeData.isFilteredSet = action.payload;
        },
        setMessage: (state, action: PayloadAction<ApiMessage>) => {
            state.message = action.payload;
        },
      
    },
    extraReducers: (builder) => {
        builder.addCase(getPrivilegeData.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.privilegedataList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        dataType: val.datatype?.value,
                        propertyName: val.propertyname?.value ?? "",
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.addedby?.firstname?.value ?? "") + " " + (val.addedby?.lastname?.value ?? ""),
                        addedById: val.addedby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                let l = [];
                if (state.privilegeData.items.length != 0) {
                    l = state.privilegeData.items.slice(0, state.privilegeData.items.length - 10)
                }
                l = l.concat(r);
                if (r.length < state.privilegeData.numberOfResults) {
                    state.privilegeData.hasMore = false;
                } else {
                    l = l.concat(...Array(10).fill(null));
                }

                state.privilegeData.items = l
                //console.log(state.items)

            } else {
                state.privilegeData.hasMore = false;
            }
            state.privilegeData.isFetching = false

        })
        builder.addCase(getPrivilegeData.rejected, (state, action) => {
            state.privilegeData.isFetching = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getPrivilegeData.pending, (state, action) => {
            state.privilegeData.isFetching = true;
            state.message = undefined;
        })

    }
})

export const { reset, dismissMessage, setSort, setNumberOfResults, setMessage, setSelectedPrivilegeData, resetPrivilegeData, setSearchText, setIsFilteredSet } = managersPrivilegeDataPageSlice.actions

export default managersPrivilegeDataPageSlice.reducer


