import { ComboBox, DefaultButton, DialogFooter, Icon, IconButton, IPersonaProps, IPickerItemProps, IStackTokens, ISuggestionItemProps, ISuggestionModel, ISuggestionsProps, ITag, Label, MessageBar, MessageBarType, Modal, NormalPeoplePicker, PeoplePickerItem, Persona, PersonaSize, PrimaryButton, Spinner, SpinnerSize, Stack, TagItemSuggestion, TagPicker, Text, TextField, Toggle, TooltipHost, ValidationState } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { FormEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks";
import { add, dismissMessage, get, getAccountTypes, getClinetUsers, getCurrencies, getLimitations, reset, resetCurrencies, resetLimitations, resetAccountTypes, setIsFilteredAccountTypesSet, resetUsers, setIsFilteredCurrenciesSet, setIsFilteredOwnersSet, setSelectedAccountTypeItems, setSelectedCurrencyItems, setIsFilteredLimitationsSet, setSelectedLimitationItems, setStage1, setSelectedUserItems, update, linkAccount } from "./AccountsFromSlice";
import * as Yup from 'yup';
import { DynamicInputField, InputField } from "../../controls/Controls";
import { inputs } from "../../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../../app/Api";
import { Message } from "../../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../../FormProps";
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog";
import { formatDate, timestampToDate } from "../../../../app/Helpers";
import { AuthenticateReply } from "../../../../repository/UserManagement/authentication_pb";
import { DynamicField } from "../../../common/DynamicFields/DynamicFields";
import { ExtraField } from "../../../../repository/Accountant/common_pb";
import { TableState } from "../../../common/Table/TableSate";
import { AccountStatus, LimitationType, Operations } from "../../../../app/Enums";
import { GetAccountTypesRequest } from "../../../../repository/Accountant/account_type_pb"
import { GetCurrenciesRequest } from "../../../../repository/Accountant/currency_pb"
import { GetUsersRequest } from "../../../../repository/UserManagement/Customers/user_pb"
import { GetLimitationsRequest } from "../../../../repository/Accountant/limitation_pb"
import { GetAccountRequest, GetAccountResponse, OpenAccountRequest, UpdateAccountResponse, OpenAccountResponse, UpdateAccountRequest, LinkRequest, LinkResponse } from "../../../../repository/Accountant/account_pb";
import { List } from "../../../common/List/List";
import { Popup } from "../../../common/Popup/Popup";
import { UsersPage } from "../../../pages/Customers/Users/UsersPage";
import { LimitationsPage } from "../../../pages/Accountant/Limitations/LimitationsPage";
import { AccountTypesPage } from "../../../pages/Accountant/AccountTypes/AccountTypesPage";
import { CurrenciesPage } from "../../../pages/Accountant/Currencies/CurrenciesPage";

let req: LinkRequest;


let promise: any;
let getFilterPromise: any;
let org: number = -1;
let current: AuthenticateReply.AsObject;

export const LinkAccountForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();
    const [currentAction, setCurrenctAction] = useState(0)


    const state: { isLoading: boolean, message: ApiMessage | undefined, stage1: any, owners: TableState, limitations: TableState, currencies: TableState, accountTypes: TableState } = useAppSelector((state) => {

        return {
            isLoading: state.accountsForm.isLoading, message: state.accountsForm.message, stage1: state.accountsForm.stage1,
            accountTypes: state.accountsForm.accountTypes,
            currencies: state.accountsForm.currencies,
            limitations: state.accountsForm.limitations,
            owners: state.accountsForm.owners,

        }
    })

    useEffect(() => {
        var l = searchParams.get('org');
        req = new LinkRequest();


        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);


            }
        }

        if (props.type == FormType.EDIT) {
            if (props.fetchData) {
                let r = new GetAccountRequest()
                r.setId(props?.renderObject?.internalId);
                promise?.abort()
                promise = dispatch(get({ body: r, headers: getHeaders() }))
            } else {
                dispatch(setStage1(props?.renderObject))
            }
        }
 


        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        }
        return () => { //clean up
            promise?.abort();
            dispatch(reset());
        }
    }, [])



    return (
        <>

            <Formik
                enableReinitialize={true}
                initialValues={{
                    metadata: "",
                    isLinkEnabled: props.type == FormType.ADD ? true : (props.renderObject) ? props.renderObject?.isLinkingEnable : false,

                }}

                validationSchema={props.type == FormType.ADD ? Yup.object({
                    metadata: inputs.linkMetadata,

                }) : undefined}

                onSubmit={(values, actions) => {
                    if (props.type == FormType.ADD) {


                        var a = values.metadata.split("_")
                        if (a.length < 3 || a.length > 4) {
                            actions.setFieldValue("metadata", "")
                            return;
                        }
                        req.setId(props.renderObject?.internalId);
                        req.setIslinkingenable(values.isLinkEnabled);
                        const extraField: ExtraField[] = [];
                        const count: Number = 1;
                        const r = new ExtraField();
                        r.setKey("org");
                        r.setLabel("org")
                        r.setType(1)
                        r.setValue(a[0] + "")
                        extraField.push(r)

                        const r2 = new ExtraField();
                        r2.setKey("account");
                        r2.setLabel("account")
                        r2.setType(1)
                        r2.setValue(a[1] + "")
                        extraField.push(r2)

                        if (a.length == 4) {
                            const r3 = new ExtraField();
                            r3.setKey("intermediate");
                            r3.setLabel("intermediate")
                            r3.setType(1)
                            r3.setValue(a[2] + "")
                            extraField.push(r3)

                            const r4 = new ExtraField();
                            r4.setKey("token");
                            r4.setLabel("token")
                            r4.setType(1)
                            r4.setValue(a[3] + "")
                            extraField.push(r4)
                        }else{
                            const r4 = new ExtraField();
                            r4.setKey("token");
                            r4.setLabel("token")
                            r4.setType(1)
                            r4.setValue(a[2] + "")
                            extraField.push(r4)
                        }

                      

                        req.setLinkmetadataList(extraField)
                        promise = dispatch(linkAccount({ body: req, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: LinkResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            ...props.renderObject,
                                            isLinkingEnable: values.isLinkEnabled,
                                            isLinkingMetadataSet: req.getLinkmetadataList().length > 0
                                        });
                                    }
                                }
                                actions.setSubmitting(false)
                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })

                    } else {

                        req.setId(props.renderObject?.internalId);
                        req.setIslinkingenable(values.isLinkEnabled);

                        promise = dispatch(linkAccount({ body: req, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: LinkResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            ...props.renderObject,
                                            isLinkingEnable: values.isLinkEnabled,
                                            isLinkingMetadataSet: true
                                        });
                                    }
                                }
                                actions.setSubmitting(false)
                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    }
                }}

            >
                {formkikProps => (
                    <Form >
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? (state.message.data != 401) ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            /> :
                                <SessionExpiredDialog />
                                : null
                            }
                            <Toggle

                                onText={t("disable")} offText={t("enable")}
                                label={t("status")}
                                disabled={state.isLoading}

                                checked={formkikProps.values.isLinkEnabled}
                                onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                                    formkikProps.setFieldValue('isLinkEnabled', checked);
                                }
                                }
                            />
                            {props.type == FormType.ADD ? <Field name="metadata" label={t("linkingMetadata")} placeholder={t("linkingMetadata")}
                                type="password"
                                canRevealPassword
                                revealPasswordAriaLabel={t("show")}
                                disabled={state.isLoading}
                                required
                                 maxLength={500}
                                 autoComplete="new-password"
                                component={InputField}
                                />
                                : undefined}




                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("save") : t("save")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>


                            <DefaultButton disabled={state.isLoading} text={props.type == FormType.ADD ? t("discard") : t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>



                    </Form>
                )}
            </Formik>
        </>
    );

}

