import { ContextualMenu, Dialog, DialogFooter, DialogType, PrimaryButton } from "@fluentui/react";
import React from "react";
import { useBoolean } from '@fluentui/react-hooks';
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { clearSession } from "../../../app/Api";


const modalPropsStyles = { main: { maxWidth: 450 } };

export const SessionExpiredDialog: React.FunctionComponent = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(false);
    const dialogContentProps = {
        type: DialogType.normal,
        title: t("sessionExpairedTitle"),
        subText: t("sessionExpairedBody"),
    };

    const modalProps = React.useMemo(
        () => ({
            isBlocking: true,
            styles: modalPropsStyles,

        }),
        [],
    );
    return (
        <>
            <Dialog
                hidden={hideDialog}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >
                <DialogFooter>
                    <PrimaryButton autoFocus onClick={() => {
                        clearSession();

                        navigate({
                            pathname: "/login",
                            search: "return=" + encodeURI(location.pathname+""+location.search)
                        });
                        toggleHideDialog()
                    }} text={t("signin")} />
                </DialogFooter>
            </Dialog>
        </>
    );
};


