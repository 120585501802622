/**
 * @fileoverview gRPC-Web generated client stub for balance_reservation_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as balance_reservation_pb from './balance_reservation_pb';


export class BalanceReservationEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorReserveAmount = new grpcWeb.MethodDescriptor(
    '/balance_reservation_endpoint.BalanceReservationEndpoint/ReserveAmount',
    grpcWeb.MethodType.UNARY,
    balance_reservation_pb.ReserveAmountRequest,
    balance_reservation_pb.ReserveAmountResponse,
    (request: balance_reservation_pb.ReserveAmountRequest) => {
      return request.serializeBinary();
    },
    balance_reservation_pb.ReserveAmountResponse.deserializeBinary
  );

  reserveAmount(
    request: balance_reservation_pb.ReserveAmountRequest,
    metadata: grpcWeb.Metadata | null): Promise<balance_reservation_pb.ReserveAmountResponse>;

  reserveAmount(
    request: balance_reservation_pb.ReserveAmountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: balance_reservation_pb.ReserveAmountResponse) => void): grpcWeb.ClientReadableStream<balance_reservation_pb.ReserveAmountResponse>;

  reserveAmount(
    request: balance_reservation_pb.ReserveAmountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: balance_reservation_pb.ReserveAmountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/balance_reservation_endpoint.BalanceReservationEndpoint/ReserveAmount',
        request,
        metadata || {},
        this.methodDescriptorReserveAmount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/balance_reservation_endpoint.BalanceReservationEndpoint/ReserveAmount',
    request,
    metadata || {},
    this.methodDescriptorReserveAmount);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/balance_reservation_endpoint.BalanceReservationEndpoint/Update',
    grpcWeb.MethodType.UNARY,
    balance_reservation_pb.UpdateBalanceReservationRequest,
    balance_reservation_pb.UpdateBalanceReservationResponse,
    (request: balance_reservation_pb.UpdateBalanceReservationRequest) => {
      return request.serializeBinary();
    },
    balance_reservation_pb.UpdateBalanceReservationResponse.deserializeBinary
  );

  update(
    request: balance_reservation_pb.UpdateBalanceReservationRequest,
    metadata: grpcWeb.Metadata | null): Promise<balance_reservation_pb.UpdateBalanceReservationResponse>;

  update(
    request: balance_reservation_pb.UpdateBalanceReservationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: balance_reservation_pb.UpdateBalanceReservationResponse) => void): grpcWeb.ClientReadableStream<balance_reservation_pb.UpdateBalanceReservationResponse>;

  update(
    request: balance_reservation_pb.UpdateBalanceReservationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: balance_reservation_pb.UpdateBalanceReservationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/balance_reservation_endpoint.BalanceReservationEndpoint/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/balance_reservation_endpoint.BalanceReservationEndpoint/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorFreeAmount = new grpcWeb.MethodDescriptor(
    '/balance_reservation_endpoint.BalanceReservationEndpoint/FreeAmount',
    grpcWeb.MethodType.UNARY,
    balance_reservation_pb.FreeAmountRequest,
    balance_reservation_pb.FreeAmountResponse,
    (request: balance_reservation_pb.FreeAmountRequest) => {
      return request.serializeBinary();
    },
    balance_reservation_pb.FreeAmountResponse.deserializeBinary
  );

  freeAmount(
    request: balance_reservation_pb.FreeAmountRequest,
    metadata: grpcWeb.Metadata | null): Promise<balance_reservation_pb.FreeAmountResponse>;

  freeAmount(
    request: balance_reservation_pb.FreeAmountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: balance_reservation_pb.FreeAmountResponse) => void): grpcWeb.ClientReadableStream<balance_reservation_pb.FreeAmountResponse>;

  freeAmount(
    request: balance_reservation_pb.FreeAmountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: balance_reservation_pb.FreeAmountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/balance_reservation_endpoint.BalanceReservationEndpoint/FreeAmount',
        request,
        metadata || {},
        this.methodDescriptorFreeAmount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/balance_reservation_endpoint.BalanceReservationEndpoint/FreeAmount',
    request,
    metadata || {},
    this.methodDescriptorFreeAmount);
  }

  methodDescriptorTransferAmount = new grpcWeb.MethodDescriptor(
    '/balance_reservation_endpoint.BalanceReservationEndpoint/TransferAmount',
    grpcWeb.MethodType.UNARY,
    balance_reservation_pb.TransferAmountRequest,
    balance_reservation_pb.TransferAmountResponse,
    (request: balance_reservation_pb.TransferAmountRequest) => {
      return request.serializeBinary();
    },
    balance_reservation_pb.TransferAmountResponse.deserializeBinary
  );

  transferAmount(
    request: balance_reservation_pb.TransferAmountRequest,
    metadata: grpcWeb.Metadata | null): Promise<balance_reservation_pb.TransferAmountResponse>;

  transferAmount(
    request: balance_reservation_pb.TransferAmountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: balance_reservation_pb.TransferAmountResponse) => void): grpcWeb.ClientReadableStream<balance_reservation_pb.TransferAmountResponse>;

  transferAmount(
    request: balance_reservation_pb.TransferAmountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: balance_reservation_pb.TransferAmountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/balance_reservation_endpoint.BalanceReservationEndpoint/TransferAmount',
        request,
        metadata || {},
        this.methodDescriptorTransferAmount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/balance_reservation_endpoint.BalanceReservationEndpoint/TransferAmount',
    request,
    metadata || {},
    this.methodDescriptorTransferAmount);
  }

  methodDescriptorExternalTransferAmount = new grpcWeb.MethodDescriptor(
    '/balance_reservation_endpoint.BalanceReservationEndpoint/ExternalTransferAmount',
    grpcWeb.MethodType.UNARY,
    balance_reservation_pb.ExternalTransferAmountRequest,
    balance_reservation_pb.ExternalTransferAmountResponse,
    (request: balance_reservation_pb.ExternalTransferAmountRequest) => {
      return request.serializeBinary();
    },
    balance_reservation_pb.ExternalTransferAmountResponse.deserializeBinary
  );

  externalTransferAmount(
    request: balance_reservation_pb.ExternalTransferAmountRequest,
    metadata: grpcWeb.Metadata | null): Promise<balance_reservation_pb.ExternalTransferAmountResponse>;

  externalTransferAmount(
    request: balance_reservation_pb.ExternalTransferAmountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: balance_reservation_pb.ExternalTransferAmountResponse) => void): grpcWeb.ClientReadableStream<balance_reservation_pb.ExternalTransferAmountResponse>;

  externalTransferAmount(
    request: balance_reservation_pb.ExternalTransferAmountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: balance_reservation_pb.ExternalTransferAmountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/balance_reservation_endpoint.BalanceReservationEndpoint/ExternalTransferAmount',
        request,
        metadata || {},
        this.methodDescriptorExternalTransferAmount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/balance_reservation_endpoint.BalanceReservationEndpoint/ExternalTransferAmount',
    request,
    metadata || {},
    this.methodDescriptorExternalTransferAmount);
  }

  methodDescriptorWithdrawAmount = new grpcWeb.MethodDescriptor(
    '/balance_reservation_endpoint.BalanceReservationEndpoint/WithdrawAmount',
    grpcWeb.MethodType.UNARY,
    balance_reservation_pb.WithdrawAmountRequest,
    balance_reservation_pb.WithdrawAmountResponse,
    (request: balance_reservation_pb.WithdrawAmountRequest) => {
      return request.serializeBinary();
    },
    balance_reservation_pb.WithdrawAmountResponse.deserializeBinary
  );

  withdrawAmount(
    request: balance_reservation_pb.WithdrawAmountRequest,
    metadata: grpcWeb.Metadata | null): Promise<balance_reservation_pb.WithdrawAmountResponse>;

  withdrawAmount(
    request: balance_reservation_pb.WithdrawAmountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: balance_reservation_pb.WithdrawAmountResponse) => void): grpcWeb.ClientReadableStream<balance_reservation_pb.WithdrawAmountResponse>;

  withdrawAmount(
    request: balance_reservation_pb.WithdrawAmountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: balance_reservation_pb.WithdrawAmountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/balance_reservation_endpoint.BalanceReservationEndpoint/WithdrawAmount',
        request,
        metadata || {},
        this.methodDescriptorWithdrawAmount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/balance_reservation_endpoint.BalanceReservationEndpoint/WithdrawAmount',
    request,
    metadata || {},
    this.methodDescriptorWithdrawAmount);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/balance_reservation_endpoint.BalanceReservationEndpoint/Delete',
    grpcWeb.MethodType.UNARY,
    balance_reservation_pb.DeleteReservationRequest,
    balance_reservation_pb.DeleteReservationResponse,
    (request: balance_reservation_pb.DeleteReservationRequest) => {
      return request.serializeBinary();
    },
    balance_reservation_pb.DeleteReservationResponse.deserializeBinary
  );

  delete(
    request: balance_reservation_pb.DeleteReservationRequest,
    metadata: grpcWeb.Metadata | null): Promise<balance_reservation_pb.DeleteReservationResponse>;

  delete(
    request: balance_reservation_pb.DeleteReservationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: balance_reservation_pb.DeleteReservationResponse) => void): grpcWeb.ClientReadableStream<balance_reservation_pb.DeleteReservationResponse>;

  delete(
    request: balance_reservation_pb.DeleteReservationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: balance_reservation_pb.DeleteReservationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/balance_reservation_endpoint.BalanceReservationEndpoint/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/balance_reservation_endpoint.BalanceReservationEndpoint/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorGetBalanceReservation = new grpcWeb.MethodDescriptor(
    '/balance_reservation_endpoint.BalanceReservationEndpoint/GetBalanceReservation',
    grpcWeb.MethodType.UNARY,
    balance_reservation_pb.GetBalanceReservationRequest,
    balance_reservation_pb.GetBalanceReservationResponse,
    (request: balance_reservation_pb.GetBalanceReservationRequest) => {
      return request.serializeBinary();
    },
    balance_reservation_pb.GetBalanceReservationResponse.deserializeBinary
  );

  getBalanceReservation(
    request: balance_reservation_pb.GetBalanceReservationRequest,
    metadata: grpcWeb.Metadata | null): Promise<balance_reservation_pb.GetBalanceReservationResponse>;

  getBalanceReservation(
    request: balance_reservation_pb.GetBalanceReservationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: balance_reservation_pb.GetBalanceReservationResponse) => void): grpcWeb.ClientReadableStream<balance_reservation_pb.GetBalanceReservationResponse>;

  getBalanceReservation(
    request: balance_reservation_pb.GetBalanceReservationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: balance_reservation_pb.GetBalanceReservationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/balance_reservation_endpoint.BalanceReservationEndpoint/GetBalanceReservation',
        request,
        metadata || {},
        this.methodDescriptorGetBalanceReservation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/balance_reservation_endpoint.BalanceReservationEndpoint/GetBalanceReservation',
    request,
    metadata || {},
    this.methodDescriptorGetBalanceReservation);
  }

  methodDescriptorGetBalanceReservations = new grpcWeb.MethodDescriptor(
    '/balance_reservation_endpoint.BalanceReservationEndpoint/GetBalanceReservations',
    grpcWeb.MethodType.UNARY,
    balance_reservation_pb.GetBalanceReservationsRequest,
    balance_reservation_pb.GetBalanceReservationsResponse,
    (request: balance_reservation_pb.GetBalanceReservationsRequest) => {
      return request.serializeBinary();
    },
    balance_reservation_pb.GetBalanceReservationsResponse.deserializeBinary
  );

  getBalanceReservations(
    request: balance_reservation_pb.GetBalanceReservationsRequest,
    metadata: grpcWeb.Metadata | null): Promise<balance_reservation_pb.GetBalanceReservationsResponse>;

  getBalanceReservations(
    request: balance_reservation_pb.GetBalanceReservationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: balance_reservation_pb.GetBalanceReservationsResponse) => void): grpcWeb.ClientReadableStream<balance_reservation_pb.GetBalanceReservationsResponse>;

  getBalanceReservations(
    request: balance_reservation_pb.GetBalanceReservationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: balance_reservation_pb.GetBalanceReservationsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/balance_reservation_endpoint.BalanceReservationEndpoint/GetBalanceReservations',
        request,
        metadata || {},
        this.methodDescriptorGetBalanceReservations,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/balance_reservation_endpoint.BalanceReservationEndpoint/GetBalanceReservations',
    request,
    metadata || {},
    this.methodDescriptorGetBalanceReservations);
  }

}

