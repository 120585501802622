import { ComboBox, DefaultButton, DialogFooter, Icon, IconButton, IStackTokens, Label, MessageBar, MessageBarType, Modal, PrimaryButton, Separator, SpinButton, Spinner, SpinnerSize, Stack, Text, TextField, Toggle, TooltipHost } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { FormEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks";
import { add, dismissMessage, get, reset, setStage1, update } from "./LimitationsFromSlice";
import * as Yup from 'yup';
import { DynamicInputField, DynamicNumberInputField, InputField, NumberInputField } from "../../controls/Controls";
import { inputs } from "../../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../../app/Api";
import { Message } from "../../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../../FormProps";
import { Int32Value, Int64Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog";
import { formatDate, getOperations, normalizeKey, timestampToDate } from "../../../../app/Helpers";
import { AuthenticateReply } from "../../../../repository/UserManagement/authentication_pb";
import { DynamicField } from "../../../common/DynamicFields/DynamicFields";
import { ExtraField } from "../../../../repository/Accountant/common_pb";
import { AddLimitationRequest, AddLimitationResponse, GetLimitationRequest, UpdateLimitationRequest, GetLimitationResponse, TransactionLimitation, UpdateLimitationResponse, TimePeriodLimitation } from '../../../../repository/Accountant/limitation_pb';


let req: AddLimitationRequest;
let updateReq: UpdateLimitationRequest;

let promise: any;

let org: number = -1;
let current: AuthenticateReply.AsObject;

export const LimitationsForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();


    const state: { isLoading: boolean, message: ApiMessage | undefined, stage1: any } = useAppSelector((state) => {

        return { isLoading: state.limitationsForm.isLoading, message: state.limitationsForm.message, stage1: state.limitationsForm.stage1 }
    })

    const getKey = (op: number, type: number, isCreditor: boolean) => {
        //{ key: 4, text: t("deposit") }
        /*
        {formkikProps.values.type.key == 1 ? [{ key: 1, text: t("treasury") }, { key: 2, text: t("account") }]
         : o?.operation.key == 5 ? [{ key: 1, text: t("sender") }, { key: 2, text: t("reciver") }] : [{ key: 1, text: t("treasury") }, { key: 2, text: t("account") }]}
        */
        if (type == 1) {// treasury{
            if (op == 3) {
                if (isCreditor) {
                    return 2
                } else {
                    return 1
                }
            } else if (op == 4) {
                if (isCreditor) {
                    return 1
                } else {
                    return 2
                }
            } else if (op == 0) {
                if (isCreditor) {
                    return 1
                } else {
                    return 2
                }
            }
        } else {
            if (op == 3) {
                if (isCreditor) {
                    return 2
                } else {
                    return 1
                }
            } else if (op == 4) {
                if (isCreditor) {
                    return 1
                } else {
                    return 2
                }
            } else if (op == 5) {
                if (isCreditor) {
                    return 1
                } else {
                    return 2
                }
            } else if (op == 0) {
                if (isCreditor) {
                    return 1
                } else {
                    return 2
                }
            }
        }

    }

    useEffect(() => {
        var l = searchParams.get('org');
        req = new AddLimitationRequest();
        updateReq = new UpdateLimitationRequest()
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                req.setOrganization(wrapper)
            }
        }

        if (props.type == FormType.EDIT) {
            if (props.fetchData) {
                let r = new GetLimitationRequest()
                r.setId(props?.renderObject?.id);
                promise?.abort()
                promise = dispatch(get({ body: r, headers: getHeaders() }))
            } else {
                dispatch(setStage1(props?.renderObject))
            }
        }


        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        }
        return () => { //clean up
            promise?.abort();
            dispatch(reset());
            org = -1
        }
    }, [])



    return (
        <>

            <Formik
                enableReinitialize={true}

                initialValues={{

                    name: (props.renderObject) ? props.renderObject?.name : '',
                    isAvailable: (props.renderObject) ? props.renderObject.isAvailable : true,
                    isActive: (props.renderObject) ? props.renderObject.isActive : true,
                    type: (props.renderObject?.type) ? { key: props.renderObject?.type, text: "" } : { key: 2, text: t("account") },
                    balanceMaxLimit: (state.stage1?.balanceMaxLimit) ? state.stage1?.balanceMaxLimit : 0,
                    balanceMinLimit: (state.stage1?.balanceMinLimit) ? state.stage1?.balanceMinLimit : 0,
                    extraFields: (state.stage1?.extraFields) ? state.stage1?.extraFields as DynamicField[] : [] as DynamicField[],
                    transactionLimitations: (state.stage1?.transactionLimitations) ? state.stage1?.transactionLimitations as {
                        operation: { key: number, text: string },
                        // period: { key: number, text: string },
                        timePeriodLimitations: {
                            period: { key: number, text: string },
                            maxNumberOfTransactions: Number,
                            maxTransactionsTotalAmount: Number
                        }[],
                        isCreditor: { key: number, text: string },
                        maxAmountValuePerTransaction: Number,
                        minAmountValuePerTransaction: Number
                    }[] : [] as {
                        operation: { key: number, text: string },
                        //period: { key: number, text: string },
                        timePeriodLimitations: {
                            period: { key: number, text: string },
                            maxNumberOfTransactions: Number,
                            maxTransactionsTotalAmount: Number
                        }[],

                        isCreditor: { key: number, text: string },
                        maxAmountValuePerTransaction: Number,
                        minAmountValuePerTransaction: Number,
                    }[],


                }}

                validationSchema={Yup.object({

                    name: inputs.limitationName,
                    balanceMaxLimit: inputs.balanceMaxLimit,
                    balanceMinLimit: inputs.balanceMinLimit,
                    extraFields: inputs.extraFields,
                    transactionLimitations: inputs.transactionLimitations,


                })}

                onSubmit={(values, actions) => {
                    if (props.type == FormType.ADD) {
                        req.setName(values.name.trim());
                        req.setIsavailable(values.isAvailable)
                        req.setIsactive(values.isActive)
                        let type = Number(values.type.key);
                        req.setType(type)

                        const transactionLimitations: TransactionLimitation[] = [];
                        values.transactionLimitations.forEach((e) => {
                            const r = new TransactionLimitation();
                            r.setMaxamountvaluepertransaction(e.maxAmountValuePerTransaction + "");
                            r.setMinamountvaluepertransaction(e.minAmountValuePerTransaction + "")


                            if (type == 2) { //account
                                if (e.operation.key == 3) { // withdraw
                                    r.setIscreditor(true);

                                } else if (e.operation.key == 4) { // deposit
                                    r.setIscreditor(false);
                                } else {
                                    r.setIscreditor(e.isCreditor.key == 1)

                                    r.setOperation(Number(e.operation.key))
                                }

                                r.setOperation((Number(e.operation.key)))

                            } else {
                                if (e.operation.key == 3) { // withdraw
                                    r.setIscreditor(false);

                                } else if (e.operation.key == 4) { // deposit
                                    r.setIscreditor(true);
                                }

                                r.setOperation(Number(e.operation.key))
                            }

                            /* let from = new Date(0);
                             let to = new Date(0);
                             to.setFullYear(to.getFullYear() + Number(e.year))
                             to.setMonth(to.getMonth() + Number(e.month))
                             to.setDate(to.getDate() + Number(e.day))
                             to.setHours(to.getHours() + Number(e.hour))
                             to.setMinutes(to.getMinutes() + Number(e.minute))
 
                             var seconds = Math.abs(to.getTime() - from.getTime()) / 1000;
                             */


                            const timePeriodLimitation: TimePeriodLimitation[] = [];

                            e.timePeriodLimitations.forEach((e) => {

                                let tp = new TimePeriodLimitation();
                                tp.setMaxnumberoftransactions(Number(e.maxNumberOfTransactions))
                                tp.setMaxtransactionstotalamount(e.maxTransactionsTotalAmount + "")
                                tp.setSeconds(Number(e.period?.key))
                                timePeriodLimitation.push(tp)
                            });

                            r.setTimeperiodlimitationsList(timePeriodLimitation)
                            transactionLimitations.push(r)

                        })
                        req.setTransactionlimitationsList(transactionLimitations)
                        req.setBalancemaxlimit(values.balanceMaxLimit + "")
                        req.setBalanceminlimit(values.balanceMinLimit + "")
                        const extraField: ExtraField[] = [];
                        const count: Number = 1;
                        values.extraFields.forEach((e: DynamicField) => {
                            const r = new ExtraField();
                            r.setKey(e.key);
                            r.setLabel(e.label?.trim() ?? "")
                            r.setType(1)
                            r.setValue(e.value.trim())
                            extraField.push(r)
                        })
                        req.setExtrafieldsList(extraField)
                        promise = dispatch(add({ body: req, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: AddLimitationResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            id: res.success?.id,
                                            name: values.name.trim(),
                                            isAvailable: values.isAvailable,
                                            isActive: values.isActive,
                                            type: values.type.key,
                                            balanceMaxLimit: values.balanceMaxLimit,
                                            balanceMinLimit: values.balanceMinLimit,
                                            transactionLimitations: values.transactionLimitations,
                                            createdDate: formatDate(timestampToDate(res.success?.createddate?.seconds, res.success?.createddate?.nanos)),
                                            addedBy: (current.profile?.firstname?.value ?? "") + " " + (current.profile?.lastname?.value ?? ""),
                                            addedById: current.profile?.id?.value,
                                        });
                                    }
                                }
                                actions.setSubmitting(false)

                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    } else {
                        updateReq.setName(values.name.trim());
                        updateReq.setIsavailable(values.isAvailable)

                        const transactionLimitations: TransactionLimitation[] = [];
                        values.transactionLimitations.forEach((e) => {
                            const r = new TransactionLimitation();
                            r.setMaxamountvaluepertransaction(e.maxAmountValuePerTransaction + "");
                            r.setMinamountvaluepertransaction(e.minAmountValuePerTransaction + "");

                            if (props.renderObject?.type == 2) { //account
                                if (e.operation.key == 3) { // withdraw
                                    r.setIscreditor(true);

                                } else if (e.operation.key == 4) { // deposit
                                    r.setIscreditor(false);
                                } else {
                                    r.setIscreditor(e.isCreditor.key == 1)

                                    r.setOperation(Number(e.operation.key))
                                }

                                r.setOperation((Number(e.operation.key)))

                            } else {
                                if (e.operation.key == 3) { // withdraw
                                    r.setIscreditor(false);

                                } else if (e.operation.key == 4) { // deposit
                                    r.setIscreditor(true);
                                }

                                r.setOperation(Number(e.operation.key))
                            }

                            /* let from = new Date(0);
                             let to = new Date(0);
                             to.setFullYear(to.getFullYear() + Number(e.year))
                             to.setMonth(to.getMonth() + Number(e.month))
                             to.setDate(to.getDate() + Number(e.day))
                             to.setHours(to.getHours() + Number(e.hour))
                             to.setMinutes(to.getMinutes() + Number(e.minute))
 
                             var seconds = Math.abs(to.getTime() - from.getTime()) / 1000;
                             */


                            const timePeriodLimitation: TimePeriodLimitation[] = [];

                            e.timePeriodLimitations.forEach((e) => {

                                let tp = new TimePeriodLimitation();
                                tp.setMaxnumberoftransactions(Number(e.maxNumberOfTransactions))
                                tp.setMaxtransactionstotalamount(e.maxTransactionsTotalAmount + "")
                                tp.setSeconds(Number(e.period?.key))
                                timePeriodLimitation.push(tp)
                            });

                            r.setTimeperiodlimitationsList(timePeriodLimitation)
                            transactionLimitations.push(r)
                        })
                        updateReq.setTransactionlimitationsList(transactionLimitations)
                        updateReq.setBalancemaxlimit(values.balanceMaxLimit + "")
                        updateReq.setBalanceminlimit(values.balanceMinLimit + "")
                        const extraField: ExtraField[] = [];
                        const count: Number = 1;
                        values.extraFields.forEach((e: DynamicField) => {
                            const r = new ExtraField();
                            r.setKey(e.key);
                            r.setLabel(e.label?.trim() ?? "")
                            r.setType(1)
                            r.setValue(e.value.trim())
                            extraField.push(r)
                        })
                        updateReq.setExtrafieldsList(extraField)
                        updateReq.setId(props.renderObject?.id)
                        promise = dispatch(update({ body: updateReq, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: UpdateLimitationResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            ...props.renderObject,
                                            name: values.name.trim(),
                                            isAvailable: values.isAvailable,
                                            balanceMaxLimit: values.balanceMaxLimit,
                                            balanceMinLimit: values.balanceMinLimit,
                                            transactionLimitations: values.transactionLimitations,
                                            extraFields: values.extraFields
                                        });
                                    }
                                }
                                actions.setSubmitting(false)
                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    }
                }}

            >
                {formkikProps => (
                    <Form >
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? (state.message.data != 401) ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            /> :
                                <SessionExpiredDialog />
                                : null
                            }
                            <Field name="name" label={t("limitationName")} placeholder={t("limitationNameHint")} component={InputField} disabled={state.isLoading} autoFocus maxLength={50} required />



                            {props.type == FormType.ADD ? <ComboBox
                                label={t("type")}
                                required
                                allowFreeform={true}
                                autoComplete={'on'}
                                disabled={state.isLoading}
                                options={[{ key: 2, text: t("account") }, { key: 1, text: t("treasury") },]}
                                selectedKey={formkikProps.values.type.key}
                                onChange={(e, o) => {

                                    formkikProps.setFieldValue("type", { key: o?.key, text: o?.text })
                                }} />
                                : undefined}
                            <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: props.childrenGap }} >
                                <Stack.Item grow>
                                    <Label required disabled={state.isLoading}>  {t("balanceMinLimit")} </Label>
                                    <Field name="balanceMinLimit" placeholder={"0"} component={NumberInputField} disabled={state.isLoading} max={999999999999999} min={-999999999999999} required />
                                </Stack.Item>
                                <Stack.Item grow>
                                    <Label required disabled={state.isLoading}>  {t("balanceMaxLimit")} </Label>
                                    <Field name="balanceMaxLimit" placeholder={"50000"} component={NumberInputField} disabled={state.isLoading} max={999999999999999} min={-999999999999999} required />
                                </Stack.Item>
                            </Stack>
                            {props.type == FormType.ADD ? <Toggle

                                onText={t("active")} offText={t("inactive")}
                                label={t("status")}
                                disabled={state.isLoading}

                                checked={formkikProps.values.isActive}
                                onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                                    formkikProps.setFieldValue('isActive', checked);
                                }
                                }
                            /> : undefined}
                            <Toggle

                                onText={t("available")} offText={t("unavailable")}
                                label={t("availability")}
                                disabled={state.isLoading}

                                checked={formkikProps.values.isAvailable}
                                onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                                    formkikProps.setFieldValue('isAvailable', checked);
                                }
                                }
                            />
                            <Label disabled={state.isLoading}>{
                                <div>
                                    {t("transactionLimitations") + ' '}
                                    <TooltipHost content={t("transactionLimitationsDescription")}>
                                        <Icon iconName="Info" aria-label={t("transactionLimitations")} />
                                    </TooltipHost>
                                </div>
                            }</Label>

                            <FieldArray
                                name="transactionLimitations"
                                validateOnChange={false}
                            >
                                {arrayHelpers => {
                                    let r = [] as React.ReactNode[];
                                    if (formkikProps.values.transactionLimitations && formkikProps.values.transactionLimitations.length > 0) {
                                        r = formkikProps.values.transactionLimitations.map((o, index) => {
                                            return (
                                                <Stack styles={{ root: { borderRadius: 8, border: "1px solid rgb(153,153,153)" } }} key={index}>
                                                    <Stack.Item align="end">

                                                        <IconButton disabled={state.isLoading}
                                                            iconProps={{ iconName: "remove" }} onClick={() => {
                                                                arrayHelpers.remove(index)
                                                            }} />
                                                    </Stack.Item>
                                                    <Stack tokens={{ childrenGap: props.childrenGap, padding: "0px 20px 20px 20px" }}>

                                                        {/* <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: props.childrenGap }}>
                                                        <DynamicNumberInputField
                                                            name={`transactionLimitations[${index}].minute`}
                                                            label={t("minutes")}
                                                            disabled={state.isLoading}
                                                            min={0}
                                                            max={59}
                                                            step={1}
                                                        />
                                                        <DynamicNumberInputField
                                                            name={`transactionLimitations[${index}].hour`}
                                                            label={t("hours")}
                                                            disabled={state.isLoading}
                                                            min={0}
                                                            max={23}
                                                            step={1}

                                                        />
                                                        <DynamicNumberInputField
                                                            name={`transactionLimitations[${index}].day`}
                                                            label={t("days")}
                                                            disabled={state.isLoading}
                                                            min={0}
                                                            max={30}
                                                            step={1}

                                                        />
                                                        <DynamicNumberInputField
                                                            name={`transactionLimitations[${index}].month`}
                                                            label={t("months")}
                                                            disabled={state.isLoading}
                                                            min={0}
                                                            max={11}
                                                            step={1}

                                                        />
                                                        <DynamicNumberInputField
                                                            name={`transactionLimitations[${index}].year`}
                                                            label={t("years")}
                                                            disabled={state.isLoading}
                                                            min={0}
                                                            max={100}
                                                            step={1}
                                                        />
                                                </Stack> */}
                                                        <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: props.childrenGap }}>
                                                            <Stack.Item grow>
                                                                <ComboBox
                                                                    required
                                                                    label={t("operation")}
                                                                    allowFreeform={true}
                                                                    autoComplete={'on'}
                                                                    disabled={state.isLoading}
                                                                    options={formkikProps.values.type.key == 1 ? getOperations(true, 3, false).map(o => {
                                                                        return { key: o.key, text: t(normalizeKey(o.text)) }
                                                                    }) : getOperations(true, 4, false).map(o => {
                                                                        return { key: o.key, text: t(normalizeKey(o.text)) }
                                                                    })}
                                                                    selectedKey={o?.operation?.key}

                                                                    onChange={(e, o) => {

                                                                        formkikProps.setFieldValue(`transactionLimitations[${index}].operation`, { key: o?.key, text: o?.text })
                                                                    }}
                                                                // Force re-creating the component when the toggles change (for demo purposes)
                                                                />
                                                            </Stack.Item>
                                                            {formkikProps.values.transactionLimitations[index].operation.key == 5 && formkikProps.values.type.key == 2 ?
                                                                <Stack.Item grow>
                                                                    <ComboBox
                                                                        required
                                                                        label={t("party")}
                                                                        allowFreeform={true}
                                                                        autoComplete={'on'}
                                                                        disabled={state.isLoading}
                                                                        options={[{ key: 1, text: t("credit") }, { key: 2, text: t("debit") }]}
                                                                        selectedKey={o?.isCreditor.key}
                                                                        onChange={(e, o) => {
                                                                            formkikProps.setFieldValue(`transactionLimitations[${index}].isCreditor`, { key: o?.key, text: o?.text })
                                                                        }} />
                                                                </Stack.Item> : undefined}
                                                        </Stack>
                                                        <Label disabled={state.isLoading}>{
                                                            <div>
                                                                {t("minAmountValuePerTransaction") + ' '}
                                                                <TooltipHost content={t("minAmountValuePerTransactionDescription")}>
                                                                    <Icon iconName="Info" aria-label={t("minAmountValuePerTransaction")} />
                                                                </TooltipHost>
                                                            </div>
                                                        }</Label>
                                                        <Field name={`transactionLimitations[${index}].minAmountValuePerTransaction`}
                                                            placeholder={"0"} component={NumberInputField}
                                                            disabled={state.isLoading} max={999999999999999} min={0} required />
                                                        <Label disabled={state.isLoading}>{
                                                            <div>
                                                                {t("maxAmountValuePerTransaction") + ' '}
                                                                <TooltipHost content={t("maxAmountValuePerTransactionDescription")}>
                                                                    <Icon iconName="Info" aria-label={t("maxAmountValuePerTransaction")} />
                                                                </TooltipHost>
                                                            </div>
                                                        }</Label>

                                                        <Field name={`transactionLimitations[${index}].maxAmountValuePerTransaction`}
                                                            placeholder={"500"} component={NumberInputField}
                                                            disabled={state.isLoading} max={999999999999999} min={0} required />
                                                        <Label required disabled={state.isLoading}>{
                                                            <>
                                                                {t("period") + ' '}
                                                                <TooltipHost content={t("periodDescription")}>
                                                                    <Icon iconName="Info" aria-label={t("period")} />
                                                                </TooltipHost>
                                                            </>
                                                        }</Label>


                                                        <FieldArray
                                                            name={`transactionLimitations[${index}].timePeriodLimitations`}
                                                            validateOnChange={false}

                                                        >
                                                            {arrayHelpers2 => {
                                                                let r = [] as React.ReactNode[];
                                                                if (formkikProps.values.transactionLimitations[index].timePeriodLimitations && formkikProps.values.transactionLimitations[index].timePeriodLimitations.length > 0) {
                                                                    r = formkikProps.values.transactionLimitations[index].timePeriodLimitations.map((v, c) => {

                                                                        return (<Stack styles={{ root: { borderRadius: 8, border: "1px solid rgb(153,153,153)" } }} key={c}>
                                                                            <Stack.Item align="end">
                                                                                <IconButton disabled={state.isLoading}
                                                                                    iconProps={{ iconName: "remove" }} onClick={() => {
                                                                                        arrayHelpers2.remove(c)
                                                                                    }} />
                                                                            </Stack.Item>
                                                                            <Stack tokens={{ childrenGap: props.childrenGap, padding: "0px 20px 20px 20px" }}>
                                                                                <ComboBox
                                                                                    required
                                                                                    allowFreeform={true}
                                                                                    label={t("period")}
                                                                                    autoComplete={'on'}
                                                                                    disabled={state.isLoading}
                                                                                    options={[{ key: 60, text: t("minutely") }, { key: 3600, text: t("hourly") },
                                                                                    { key: 86400, text: t("daily") }, { key: 2592000, text: t("monthly") }, { key: 31104000, text: t("yearly") }
                                                                                    ]}
                                                                                    selectedKey={v?.period?.key}


                                                                                    onChange={(e, o) => {

                                                                                        formkikProps.setFieldValue(`transactionLimitations[${index}].timePeriodLimitations[${c}].period`, { key: o?.key, text: o?.text })
                                                                                    }} />
                                                                                <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: props.childrenGap }}>
                                                                                    <Stack.Item grow>
                                                                                        <Label disabled={state.isLoading}>{
                                                                                            <div>
                                                                                                {t("maxNumberOfTransactions") + ' '}
                                                                                                <TooltipHost content={t("maxNumberOfTransactionsDescription")}>
                                                                                                    <Icon iconName="Info" aria-label={t("maxNumberOfTransactions")} />
                                                                                                </TooltipHost>
                                                                                            </div>
                                                                                        }</Label>
                                                                                        <DynamicNumberInputField name={`transactionLimitations[${index}].timePeriodLimitations[${c}].maxNumberOfTransactions`} defaultValue={"10"}
                                                                                            min={0}
                                                                                            max={2000000000}
                                                                                            step={1}
                                                                                            disabled={state.isLoading}
                                                                                        />
                                                                                    </Stack.Item>
                                                                                    <Stack.Item grow>
                                                                                        <Label disabled={state.isLoading}>{
                                                                                            <div>
                                                                                                {t("maxTransactionsTotalAmount") + ' '}
                                                                                                <TooltipHost content={t("maxTransactionsTotalAmountDescription")}>
                                                                                                    <Icon iconName="Info" aria-label={t("maxTransactionsTotalAmount")} />
                                                                                                </TooltipHost>
                                                                                            </div>
                                                                                        }</Label>
                                                                                        <DynamicNumberInputField name={`transactionLimitations[${index}].timePeriodLimitations[${c}].maxTransactionsTotalAmount`} defaultValue={"10000"} disabled={state.isLoading}
                                                                                            min={0}
                                                                                            max={999999999999999}
                                                                                            step={1}
                                                                                        />
                                                                                    </Stack.Item>


                                                                                </Stack>
                                                                            </Stack>
                                                                        </Stack>)
                                                                    }
                                                                    )
                                                                }
                                                                if (formkikProps.values.transactionLimitations[index].timePeriodLimitations.length <= 4) {
                                                                    r.push(< IconButton  disabled={state.isLoading}
                                                                        key={"addButton"} iconProps={{ iconName: "add" }} onClick={() => {

                                                                            arrayHelpers2.push({ maxNumberOfTransactions: 0, maxTransactionsTotalAmount: 0, period: { key: 60, text: "" } })
                                                                        }} />)
                                                                }
                                                                return r;
                                                            }
                                                            }

                                                        </FieldArray>


                                                    </Stack>
                                                </Stack>
                                            )
                                        }
                                        )
                                    }
                                    if (formkikProps.values.transactionLimitations.length <= 5) {
                                        r.push(< IconButton disabled={state.isLoading}
                                            key={"addButton"} iconProps={{ iconName: "add" }} onClick={() => {
                                                arrayHelpers.push({ operation: { key: 0, text: "" }, isCreditor: { key: 1, text: "" }, minAmountValuePerTransaction: 0, maxAmountValuePerTransaction: 0, timePeriodLimitations: [] })
                                            }} />)
                                    }
                                    return r;
                                }
                                }

                            </FieldArray>



                            <Label disabled={state.isLoading}>{
                                <div>
                                    {t("extraFields") + ' '}
                                    <TooltipHost content={t("extraInformationDescription")}>
                                        <Icon iconName="Info" aria-label={t("extraFields")} />
                                    </TooltipHost>
                                </div>
                            }</Label>
                            <FieldArray
                                name="extraFields"
                                validateOnChange={false}

                            >
                                {arrayHelpers => {
                                    let r = [] as React.ReactNode[];
                                    if (formkikProps.values.extraFields && formkikProps.values.extraFields.length > 0) {
                                        r = formkikProps.values.extraFields.map((o, index) => {
                                            if (o.type == 1) {
                                                return (<Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: props.childrenGap }} key={index}>
                                                    {o.label != undefined ? <Stack.Item grow> <DynamicInputField name={`extraFields[${index}].label`} placeholder={t("extraFieldLabel")} disabled={state.isLoading}
                                                        maxLength={50} autoFocus={(o as any)?.autoFocus} /> </Stack.Item> : null}
                                                    <Stack.Item grow>  <DynamicInputField name={`extraFields[${index}].value`} placeholder={t("extraFieldValue")} disabled={state.isLoading}
                                                        maxLength={50}
                                                    /></Stack.Item>

                                                    <IconButton disabled={state.isLoading}
                                                        iconProps={{ iconName: "remove" }} onClick={() => {
                                                            arrayHelpers.remove(index)
                                                        }} />
                                                </Stack>)
                                            }
                                        }
                                        )
                                    }
                                    if (formkikProps.values.extraFields.length <= 4) {
                                        r.push(< IconButton disabled={state.isLoading}
                                            key={"addButton"} iconProps={{ iconName: "add" }} onClick={() => {
                                                arrayHelpers.push({ key: (formkikProps.values.extraFields.length + 1) + "", label: "", type: 1, value: "", autoFocus: true })
                                            }} />)
                                    }
                                    return r;
                                }
                                }

                            </FieldArray>

                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("add") : t("edit")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={props.type == FormType.ADD ? t("discard") : t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>

                    </Form>
                )}
            </Formik>
        </>
    );

}

