/**
 * @fileoverview gRPC-Web generated client stub for category_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as category_pb from './category_pb';


export class CategoryEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAdd = new grpcWeb.MethodDescriptor(
    '/category_endpoint.CategoryEndpoint/Add',
    grpcWeb.MethodType.UNARY,
    category_pb.AddCategoryRequest,
    category_pb.AddCategoryResponse,
    (request: category_pb.AddCategoryRequest) => {
      return request.serializeBinary();
    },
    category_pb.AddCategoryResponse.deserializeBinary
  );

  add(
    request: category_pb.AddCategoryRequest,
    metadata: grpcWeb.Metadata | null): Promise<category_pb.AddCategoryResponse>;

  add(
    request: category_pb.AddCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: category_pb.AddCategoryResponse) => void): grpcWeb.ClientReadableStream<category_pb.AddCategoryResponse>;

  add(
    request: category_pb.AddCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: category_pb.AddCategoryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/category_endpoint.CategoryEndpoint/Add',
        request,
        metadata || {},
        this.methodDescriptorAdd,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/category_endpoint.CategoryEndpoint/Add',
    request,
    metadata || {},
    this.methodDescriptorAdd);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/category_endpoint.CategoryEndpoint/Update',
    grpcWeb.MethodType.UNARY,
    category_pb.UpdateCategoryRequest,
    category_pb.UpdateCategoryResponse,
    (request: category_pb.UpdateCategoryRequest) => {
      return request.serializeBinary();
    },
    category_pb.UpdateCategoryResponse.deserializeBinary
  );

  update(
    request: category_pb.UpdateCategoryRequest,
    metadata: grpcWeb.Metadata | null): Promise<category_pb.UpdateCategoryResponse>;

  update(
    request: category_pb.UpdateCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: category_pb.UpdateCategoryResponse) => void): grpcWeb.ClientReadableStream<category_pb.UpdateCategoryResponse>;

  update(
    request: category_pb.UpdateCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: category_pb.UpdateCategoryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/category_endpoint.CategoryEndpoint/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/category_endpoint.CategoryEndpoint/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/category_endpoint.CategoryEndpoint/Delete',
    grpcWeb.MethodType.UNARY,
    category_pb.DeleteCategoryRequest,
    category_pb.DeleteCategoryResponse,
    (request: category_pb.DeleteCategoryRequest) => {
      return request.serializeBinary();
    },
    category_pb.DeleteCategoryResponse.deserializeBinary
  );

  delete(
    request: category_pb.DeleteCategoryRequest,
    metadata: grpcWeb.Metadata | null): Promise<category_pb.DeleteCategoryResponse>;

  delete(
    request: category_pb.DeleteCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: category_pb.DeleteCategoryResponse) => void): grpcWeb.ClientReadableStream<category_pb.DeleteCategoryResponse>;

  delete(
    request: category_pb.DeleteCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: category_pb.DeleteCategoryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/category_endpoint.CategoryEndpoint/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/category_endpoint.CategoryEndpoint/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorGetCategory = new grpcWeb.MethodDescriptor(
    '/category_endpoint.CategoryEndpoint/GetCategory',
    grpcWeb.MethodType.UNARY,
    category_pb.GetCategoryRequest,
    category_pb.GetCategoryResponse,
    (request: category_pb.GetCategoryRequest) => {
      return request.serializeBinary();
    },
    category_pb.GetCategoryResponse.deserializeBinary
  );

  getCategory(
    request: category_pb.GetCategoryRequest,
    metadata: grpcWeb.Metadata | null): Promise<category_pb.GetCategoryResponse>;

  getCategory(
    request: category_pb.GetCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: category_pb.GetCategoryResponse) => void): grpcWeb.ClientReadableStream<category_pb.GetCategoryResponse>;

  getCategory(
    request: category_pb.GetCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: category_pb.GetCategoryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/category_endpoint.CategoryEndpoint/GetCategory',
        request,
        metadata || {},
        this.methodDescriptorGetCategory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/category_endpoint.CategoryEndpoint/GetCategory',
    request,
    metadata || {},
    this.methodDescriptorGetCategory);
  }

  methodDescriptorGetCategories = new grpcWeb.MethodDescriptor(
    '/category_endpoint.CategoryEndpoint/GetCategories',
    grpcWeb.MethodType.UNARY,
    category_pb.GetCategoriesRequest,
    category_pb.GetCategoriesResponse,
    (request: category_pb.GetCategoriesRequest) => {
      return request.serializeBinary();
    },
    category_pb.GetCategoriesResponse.deserializeBinary
  );

  getCategories(
    request: category_pb.GetCategoriesRequest,
    metadata: grpcWeb.Metadata | null): Promise<category_pb.GetCategoriesResponse>;

  getCategories(
    request: category_pb.GetCategoriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: category_pb.GetCategoriesResponse) => void): grpcWeb.ClientReadableStream<category_pb.GetCategoriesResponse>;

  getCategories(
    request: category_pb.GetCategoriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: category_pb.GetCategoriesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/category_endpoint.CategoryEndpoint/GetCategories',
        request,
        metadata || {},
        this.methodDescriptorGetCategories,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/category_endpoint.CategoryEndpoint/GetCategories',
    request,
    metadata || {},
    this.methodDescriptorGetCategories);
  }

}

