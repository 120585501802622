import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError } from 'grpc-web';
import { RoleEndpointClient } from '../../../../../repository/UserManagement/Managers/RoleServiceClientPb';
import { AddRoleRequest, AddRoleResponse, UpdateRoleRequest, UpdateRoleResponse } from '../../../../../repository/UserManagement/Managers/role_pb';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, refreshSession, getSession, ACCOUNTANT_API_URL, APIResponse, APIRequest } from '../../../../../app/Api';
import { formatDate, timestampToDate } from '../../../../../app/Helpers';
import { AddFeeCollectionAccountRequest, AddFeeCollectionAccountResponse, AddFeeRequest, AddFeeResponse, GetFeeRequest, GetFeeResponse, UpdateFeeRequest, UpdateFeeResponse } from '../../../../../repository/Accountant/fee_pb';
import { GetAccountsRequest, GetAccountsResponse } from '../../../../../repository/Accountant/account_pb';
import { AccountEndpointClient } from '../../../../../repository/Accountant/AccountServiceClientPb';
import { TableState } from '../../../../common/Table/TableSate';
import { GetBalanceReservationRequest, GetBalanceReservationResponse, TransferAmountResponse, TransferAmountRequest } from '../../../../../repository/Accountant/balance_reservation_pb';
import { BalanceReservationEndpointClient } from '../../../../../repository/Accountant/Balance_reservationServiceClientPb';
import { GetTransactionSummaryRequest, GetTransactionSummaryResponse } from '../../../../../repository/Accountant/account_operation_pb';
import { AccountOperationEndpointClient } from '../../../../../repository/Accountant/Account_operationServiceClientPb';

export interface TransferAmountFormState {
    isLoading: boolean
    message?: ApiMessage
    stage1: any,
    from: TableState,
    to: TableState,
    confirmation: any[]

}

const initialState: TransferAmountFormState = {
    isLoading: false,
    message: undefined,
    stage1: undefined,
    from: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    to: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    confirmation: []

}

const client = new BalanceReservationEndpointClient(ACCOUNTANT_API_URL, null, null);
const accountClient = new AccountEndpointClient(ACCOUNTANT_API_URL, null, null);
const transactionClient = new AccountOperationEndpointClient(ACCOUNTANT_API_URL, null, null);


export const getFromAccounts = createAsyncThunk<GetAccountsResponse.AsObject, APIRequest<GetAccountsRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/reservedBalances/transferAmount/fromAccounts/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await accountClient.getAccounts(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getTransactionSummary = createAsyncThunk<APIResponse<GetTransactionSummaryResponse.AsObject>, APIRequest<GetTransactionSummaryRequest>, {

    rejectValue: APIResponse<ApiMessage>
}>(
    'forms/accountant/reservedBalances/transferAmount/getTransactionSummary',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await transactionClient.getTransactionSummary(req.body, req.headers ?? {});
                return {
                    metadata: {
                        transactions: req.body.getTransactionsList()?.map(v => {
                            return { amount: v.getAmount() }
                        })
                    }, response: r.toObject()
                };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue({
                        response: message, metadata: {
                            transactions: req.body.getTransactionsList()?.map(v => {
                                return { amount: v.getAmount() }
                            })
                        }
                    })
                } catch (err) {

                    return thunkApi.rejectWithValue({
                        response: { body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage, metadata: {
                            transactions: {
                                amount: req.body.getTransactionsList()?.map(v => {
                                    return { amount: v.getAmount() }
                                })
                            }
                        }
                    })
                }
            }
        }
        return await callReq();
    }
)


export const get = createAsyncThunk<GetBalanceReservationResponse.AsObject, APIRequest<GetBalanceReservationRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/reservedBalances/transferAmount/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getBalanceReservation(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)


export const getToAccounts = createAsyncThunk<GetAccountsResponse.AsObject, APIRequest<GetAccountsRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/reservedBalances/transferAmount/toAccounts/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await accountClient.getAccounts(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const add = createAsyncThunk<TransferAmountResponse.AsObject, APIRequest<TransferAmountRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/reservedBalances/transferAmount/add',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.transferAmount(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)


export const transferAmountFormSlice = createSlice({
    name: 'forms/accountant/reservedBalances/transferAmount',
    initialState,
    reducers: {

        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.isLoading = false;
            state.message = undefined;
            state.stage1 = initialState.stage1;
            state.from = initialState.from;
            state.to = initialState.to;
            state.confirmation = initialState.confirmation;

        },
        resetFromAccountsSet: (state) => {
            state.from.items = [];
            state.from.hasMore = true;
        },

        resetToAccountsSet: (state) => {
            state.to.items = [];
            state.to.hasMore = true;
        },

        setSelectedFromAccountItems: (state, action: PayloadAction<any[]>) => {
            state.from.selected = action.payload;
        },

        setIsFilteredFromAccountSet: (state, action: PayloadAction<boolean>) => {
            state.from.isFilteredSet = action.payload;
        },

        setSelectedToAccountItems: (state, action: PayloadAction<any[]>) => {
            state.to.selected = action.payload;
        },

        setIsFilteredToAccountSet: (state, action: PayloadAction<boolean>) => {
            state.to.isFilteredSet = action.payload;
        },

        setStage1: (state, action: PayloadAction<any>) => {
            let stage1 = {
                amount: action.payload?.amount,
            }

            state.stage1 = stage1;
        },

        setMessage: (state, action: PayloadAction<ApiMessage>) => {
            state.message = action.payload;
        },
        resetMessage: (state) => {
            state.message = undefined;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(add.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            // if (payload)
            //state.message = toApiMessage(payload.success?.);

        })
        builder.addCase(add.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(add.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })


        builder.addCase(getFromAccounts.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.accountsList.map(val => {

                    return {
                        id: val.id,
                        internalId: val.internalid,
                        name: val.name?.value ?? "",
                        code: val.code?.value ?? "",
                        currencyId: val.currency?.id?.value ?? "",
                        currencyName: val.currency?.name?.value ?? "",
                        currencyCode: val.currency?.code?.value ?? "",
                        currencyDecimalPlaces: val.currency?.decimalplaces?.value ?? undefined,
                        currencyForm: val.currency?.form?.value ?? undefined,
                        currencySymbol: val.currency?.symbol?.value ?? "",
                        accountTypeId: val.accounttype?.id?.value ?? "",
                        accountTypeName: val.accounttype?.name?.value ?? "",
                        mirrorBalance: val.mirrorbalance?.value ?? undefined,
                        status: val.status?.value ?? undefined,
                        ownerFirstname: val.owner?.firstname?.value ?? "",
                        ownerLastname: val.owner?.lastname?.value ?? "",
                        ownerMiddlename: val.owner?.middlename?.value ?? "",
                        ownerName: (val.owner?.firstname?.value ?? "") + " " + (val.owner?.lastname?.value ?? ""),
                        ownerId: val.owner?.id?.value ?? "",
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                                                isAuthorizationRequired: val?.accountsetting?.isauthorizationrequired,
                        isLinkingEnable: val?.accountsetting?.islinkingenable?.value,
                        isLinkingMetadataSet: val?.accountsetting?.islinkingmetadataset?.value,
                        transferControl: val?.accountsetting?.tansfercontrol?.value ,


                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.from.numberOfResults) {
                    state.from.hasMore = false;
                }
                state.from.items = state.from.items.concat(r);
            } else {
                state.from.hasMore = false;

            }
            state.from.isFetching = false

        })
        builder.addCase(getFromAccounts.rejected, (state, action) => {
            state.from.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getFromAccounts.pending, (state, action) => {
            state.from.isFetching = true;
            // state.message = undefined;
        })

        builder.addCase(getToAccounts.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.accountsList.map(val => {

                    return {
                        id: val.id,
                        internalId: val.internalid,
                        name: val.name?.value ?? "",
                        code: val.code?.value ?? "",
                        currencyId: val.currency?.id?.value ?? "",
                        currencyName: val.currency?.name?.value ?? "",
                        currencyCode: val.currency?.code?.value ?? "",
                        currencyDecimalPlaces: val.currency?.decimalplaces?.value ?? undefined,
                        currencyForm: val.currency?.form?.value ?? undefined,
                        currencySymbol: val.currency?.symbol?.value ?? "",
                        accountTypeId: val.accounttype?.id?.value ?? "",
                        accountTypeName: val.accounttype?.name?.value ?? "",
                        mirrorBalance: val.mirrorbalance?.value ?? undefined,
                        status: val.status?.value ?? undefined,
                        ownerFirstname: val.owner?.firstname?.value ?? "",
                        ownerLastname: val.owner?.lastname?.value ?? "",
                        ownerMiddlename: val.owner?.middlename?.value ?? "",
                        ownerName: (val.owner?.firstname?.value ?? "") + " " + (val.owner?.lastname?.value ?? ""),
                        ownerId: val.owner?.id?.value ?? "",
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                                                isAuthorizationRequired: val?.accountsetting?.isauthorizationrequired,
                        isLinkingEnable: val?.accountsetting?.islinkingenable?.value,
                        isLinkingMetadataSet: val?.accountsetting?.islinkingmetadataset?.value,
                        transferControl: val?.accountsetting?.tansfercontrol?.value ,


                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.to.numberOfResults) {
                    state.to.hasMore = false;
                }
                state.to.items = state.to.items.concat(r);
            } else {
                state.to.hasMore = false;

            }
            state.to.isFetching = false

        })
        builder.addCase(getToAccounts.rejected, (state, action) => {
            state.to.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getToAccounts.pending, (state, action) => {
            state.to.isFetching = true;
            // state.message = undefined;
        })

        builder.addCase(get.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {

                let r = {
                    id: payload.success?.id,
                    accountId: payload.success?.account?.id?.value ?? "",
                    accountName: payload.success?.account?.name?.value ?? "",
                    currencyId: payload.success?.account?.currency?.id?.value ?? "",
                    currencyName: payload.success?.account?.currency?.name?.value ?? "",
                    currencyCode: payload.success?.account?.currency?.code?.value ?? "",
                    currencyDecimalPlaces: payload.success?.account?.currency?.decimalplaces?.value ?? undefined,
                    currencyForm: payload.success?.account?.currency?.form?.value ?? undefined,
                    currencySymbol: payload.success?.account?.currency?.symbol?.value ?? "",
                    amount: payload.success?.amount?.value ?? undefined,
                    reason: payload.success?.reason?.value ?? undefined,
                    statusCreatedBy: (payload.success?.status?.createdby?.firstname?.value ?? "") + " " + (payload.success?.status?.createdby?.lastname?.value ?? ""),
                    statusCreatedDate: formatDate(timestampToDate(payload.success?.createddate?.seconds, payload.success?.createddate?.nanos)),
                    status: payload.success?.status?.status?.value ?? undefined,
                    statusTransactionId: payload.success?.status?.createdtransaction?.id ?? undefined,
                    statusTransactionInternalId: payload.success?.status?.createdtransaction?.internalid ?? "",
                    referenceTransactionId: payload.success?.referencetransaction?.id ?? undefined,
                    referenceTransactionInternalId: payload.success?.referencetransaction?.internalid ?? "",
                    createdDate: formatDate(timestampToDate(payload.success?.createddate?.seconds, payload.success?.createddate?.nanos)),
                    addedBy: (payload.success?.createdby?.firstname?.value ?? "") + " " + (payload.success?.createdby?.lastname?.value ?? ""),
                    addedById: payload.success?.createdby?.id?.value,
                }



                state.stage1 = r
            }
        })
        builder.addCase(get.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(get.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })

        builder.addCase(getTransactionSummary.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {
                var r = (payload.response?.success?.transactionsummaryList.map(val => {

                    return {
                        amount: val?.amount?.value,
                        creditorfeeValue: val?.creditorfee?.fee?.value,
                        creditorfeeId: val?.creditorfee?.id?.value,
                        creditorfeeName: val?.creditorfee?.name?.value,
                        debtorfeeValue: val?.debtorfee?.fee?.value,
                        debtorfeeId: val?.debtorfee?.id?.value,
                        debtorfeeName: val?.debtorfee?.name?.value,
                        currencyId: val?.currency?.id?.value ?? "",
                        currencyName: val?.currency?.name?.value ?? "",
                        currencyCode: val?.currency?.code?.value ?? "",
                        currencyDecimalPlaces: val?.currency?.decimalplaces?.value ?? undefined,
                        currencyForm: val?.currency?.form?.value ?? undefined,
                        currencySymbol: val?.currency?.symbol?.value ?? "",
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));

                state.confirmation = r;

            }

        })
        builder.addCase(getTransactionSummary.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload?.response.data == 403) {
                var r = (action.payload?.metadata?.transactions?.map((val: any) => {
                    return {
                        amount: val?.amount,
                    }
                }
                ) as any[])
                state.confirmation = r;
                return;
            }
            if (action.payload) {
                state.message = action.payload.response;
            }
        })
        builder.addCase(getTransactionSummary.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })
    }
})

export const { reset, dismissMessage, resetMessage, setStage1, resetFromAccountsSet, resetToAccountsSet, setIsFilteredFromAccountSet, setIsFilteredToAccountSet, setMessage, setSelectedFromAccountItems, setSelectedToAccountItems } = transferAmountFormSlice.actions

export default transferAmountFormSlice.reducer


