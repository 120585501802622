import { ComboBox, DefaultButton, DialogFooter, Icon, IconButton, IPersonaProps, IPickerItemProps, IStackTokens, ISuggestionItemProps, ISuggestionModel, ISuggestionsProps, ITag, Label, MessageBar, MessageBarType, Modal, NormalPeoplePicker, PeoplePickerItem, Persona, PersonaSize, PrimaryButton, Spinner, SpinnerSize, Stack, TagItemSuggestion, TagPicker, Text, TextField, Toggle, TooltipHost, ValidationState } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { FormEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks";
import { add, dismissMessage, get, getBaseCurrencies, getQuoteCurrencies, reset, getCategories, getMainPairsItems, resetCategories, resetMainPair, setIsFilteredCategoriesSet, updatePrices, resetBaseCurrencies, resetQuoteCurrencies, setIsFilteredBaseCurrenciesSet, setIsFilteredMainPairsSet, setIsFilteredQuoteCurrenciesSet, setSelectedBaseCurrencyItems, setSelectedCategoryItems, setSelectedQuoteCurrencyItems, setSelecteMainPairItems, setStage1, update } from "./CurrencyPairsFormSlice";
import * as Yup from 'yup';
import { DynamicInputField, InputField } from "../../controls/Controls";
import { inputs } from "../../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../../app/Api";
import { Message } from "../../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../../FormProps";
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog";
import { formatDate, timestampToDate } from "../../../../app/Helpers";
import { AuthenticateReply } from "../../../../repository/UserManagement/authentication_pb";
import { DynamicField } from "../../../common/DynamicFields/DynamicFields";
import { ExtraField } from "../../../../repository/Accountant/common_pb";
import { TableState } from "../../../common/Table/TableSate";
import { AccountStatus, LimitationType, Operations } from "../../../../app/Enums";
import { GetCurrenciesRequest } from "../../../../repository/Accountant/currency_pb"
import { AddCurrencyPairRequest, GetCurrencyPairRequest, UpdateCurrencyPairResponse, AddCurrencyPairResponse, UpdateCurrencyPairRequest, GetCurrencyPairsRequest } from "../../../../repository/Trading/currency_pair_pb";
import { List } from "../../../common/List/List";
import { Popup } from "../../../common/Popup/Popup";
import { CurrenciesPage } from "../../../pages/Accountant/Currencies/CurrenciesPage";
import { GetCategoriesRequest } from "../../../../repository/Trading/category_pb";
import { CurrencyPairsPage } from "../../../pages/Trading/CurrencyPairs/CurrencyPairsPage";
import { CategoriesPage } from "../../../pages/Trading/Categories/CategoriesPage";

let req: AddCurrencyPairRequest;
let updateReq: UpdateCurrencyPairRequest;
let baseCurrenciesReq: GetCurrenciesRequest;
let quoteCurrenciesReq: GetCurrenciesRequest;
let categoriesReq: GetCategoriesRequest;
let mainPairsReq: GetCurrencyPairsRequest;

let promise: any;
let getFilterPromise: any;
let org: number = -1;
let current: AuthenticateReply.AsObject;

export const CurrencyPairsForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();
    const [currentAction, setCurrenctAction] = useState(0)

    const state: {
        isLoading: boolean, message: ApiMessage | undefined, stage1: any, baseCurrencies: TableState
        quoteCurrencies: TableState,
        categories: TableState,
        mainPairs: TableState

    } = useAppSelector((state) => {

        return {
            isLoading: state.currencyPairsForm.isLoading, message: state.currencyPairsForm.message, stage1: state.currencyPairsForm.stage1,
            baseCurrencies: state.currencyPairsForm.baseCurrencies,
            quoteCurrencies: state.currencyPairsForm.quoteCurrencies,
            categories: state.currencyPairsForm.categories,
            mainPairs: state.currencyPairsForm.mainPairs


        }
    })

    useEffect(() => {
        var l = searchParams.get('org');
        req = new AddCurrencyPairRequest();
        updateReq = new UpdateCurrencyPairRequest()
        baseCurrenciesReq = new GetCurrenciesRequest();
        quoteCurrenciesReq = new GetCurrenciesRequest();
        categoriesReq = new GetCategoriesRequest();
        mainPairsReq = new GetCurrencyPairsRequest();

        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                req.setOrganization(wrapper)
                baseCurrenciesReq.setOrganization(wrapper)
                quoteCurrenciesReq.setOrganization(wrapper)
                categoriesReq.setOrganization(wrapper)
                mainPairsReq.setOrganization(wrapper)

            }
        }

        if (props.type == FormType.EDIT) {
            if (props.fetchData) {
                let r = new GetCurrencyPairRequest()
                r.setId(props?.renderObject?.id);
                promise?.abort()
                promise = dispatch(get({ body: r, headers: getHeaders() }))
            } else {
                dispatch(setStage1(props?.renderObject))
            }
        }
        const boolv = new BoolValue();
        boolv.setValue(true);
        baseCurrenciesReq.setNumofresults(state.baseCurrencies.numberOfResults)
        baseCurrenciesReq.setOrder(state.baseCurrencies.isDescending)
        baseCurrenciesReq.setIsavailable(boolv)

        quoteCurrenciesReq.setNumofresults(state.quoteCurrencies.numberOfResults)
        quoteCurrenciesReq.setOrder(state.quoteCurrencies.isDescending)
        quoteCurrenciesReq.setIsavailable(boolv)

        categoriesReq.setNumofresults(state.categories.numberOfResults)
        categoriesReq.setOrder(state.categories.isDescending)
        //categoriesReq.setIsavailable(boolv)
        categoriesReq.setOnlysub(boolv)
        
        mainPairsReq.setNumofresults(state.mainPairs.numberOfResults)
        mainPairsReq.setOrder(state.mainPairs.isDescending)


        if (props.renderObject?.baseCurrencyId) {
            dispatch(setSelectedBaseCurrencyItems(([{ id: props.renderObject.baseCurrencyId, name: props.renderObject.baseCurrencyName, decimalPlaces: props.renderObject.baseCurrencyDecimalPlaces }])))

        }

        if (props.renderObject?.quoteCurrencyId)
            dispatch(setSelectedQuoteCurrencyItems(([{ id: props.renderObject.quoteCurrencyId, name: props.renderObject.quoteCurrencyName, decimalPlaces: props.renderObject.quoteCurrencyDecimalPlaces }])))


        if (props.renderObject?.categoryId)
            dispatch(setSelectedCategoryItems(([{ id: props.renderObject.categoryId, nameAr: props.renderObject.categoryNameAr, nameEn: props.renderObject.categoryNameEn }])))


        if (props.renderObject?.mainCurrencyPair)
            dispatch(setSelecteMainPairItems(([{
                id: props.renderObject.mainCurrencyPair,
                name: props.renderObject.mainCurrencyPairName,
                baseCurrencyId: props.renderObject?.mainCurrencyPairBaseCurrencyId,
                baseCurrencyName: props.renderObject?.mainCurrencyPairBaseCurrencyName,
                baseCurrencyCode: props.renderObject?.mainCurrencyPairBaseCurrencyCode,
                baseCurrencyDecimalPlaces: props.renderObject?.mainCurrencyPairBaseCurrencyDecimalPlaces,
                baseCurrencyForm: props.renderObject?.mainCurrencyPairBaseCurrencyForm,
                baseCurrencySymbol: props.renderObject?.mainCurrencyPairBaseCurrencySymbol,
                quoteCurrencyId: props.renderObject?.mainCurrencyPairQuoteCurrencyId,
                quoteCurrencyName: props.renderObject?.mainCurrencyPairQuoteCurrencyName,
                quoteCurrencyCode: props.renderObject?.mainCurrencyPairQuoteCurrencyCode,
                quoteCurrencyDecimalPlaces: props.renderObject?.mainCurrencyPairQuoteCurrencyDecimalPlaces,
                quoteCurrencyForm: props.renderObject?.mainCurrencyPairQuoteCurrencyForm,
                quoteCurrencySymbol: props.renderObject?.mainCurrencyPairQuoteCurrencySymbol,





            }])))

        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        }
        return () => { //clean up
            promise?.abort();
            dispatch(reset());
        }
    }, [])


    const baseCurrenciesSuggestionsProps: ISuggestionsProps<ITag> = {
        suggestionsHeaderText: t('currencies'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.baseCurrencies.hasMore && !state.baseCurrencies.isFetching,
        onRenderSuggestion: (props: ITag, suggestionItemProps: ISuggestionItemProps<ITag>) => {
            return <TagItemSuggestion>
                {props?.name ?? props?.key}
            </TagItemSuggestion>
        },
        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.baseCurrencies.items.findIndex(e => e.id == item.key)
            if (u >= 0) {
                dispatch(setSelectedBaseCurrencyItems([state.baseCurrencies.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.baseCurrencies.items.length > 0 && !state.baseCurrencies.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.baseCurrencies.items.at(state.baseCurrencies.items.length - 1).id);
                baseCurrenciesReq.setNextto(wrapper)
                getFilterPromise = dispatch(getBaseCurrencies({ body: baseCurrenciesReq, headers: getHeaders() }))
            }
        },
        suggestions: state.baseCurrencies.items.length > 0 ? state.baseCurrencies.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : [],
        isLoading: state.baseCurrencies.items.length == 0 ? state.baseCurrencies.isFetching : false,
        isSearching: state.baseCurrencies.items.length > 0 ? state.baseCurrencies.isFetching : false,


    };
    const onBaseCurrenciesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetBaseCurrencies())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        baseCurrenciesReq.setSearch(wrapper)
        baseCurrenciesReq.setNextto(undefined)
        dispatch(setIsFilteredBaseCurrenciesSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getBaseCurrencies({ body: baseCurrenciesReq, headers: getHeaders() }))
        return [];

    }

    const quoteCurrenciesSuggestionsProps: ISuggestionsProps<ITag> = {
        suggestionsHeaderText: t('currencies'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.quoteCurrencies.hasMore && !state.quoteCurrencies.isFetching,
        onRenderSuggestion: (props: ITag, suggestionItemProps: ISuggestionItemProps<ITag>) => {
            return <TagItemSuggestion>
                {props?.name ?? props?.key}
            </TagItemSuggestion>
        },
        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.quoteCurrencies.items.findIndex(e => e.id == item.key)
            if (u >= 0) {
                dispatch(setSelectedQuoteCurrencyItems([state.quoteCurrencies.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.quoteCurrencies.items.length > 0 && !state.quoteCurrencies.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.quoteCurrencies.items.at(state.quoteCurrencies.items.length - 1).id);
                quoteCurrenciesReq.setNextto(wrapper)
                getFilterPromise = dispatch(getQuoteCurrencies({ body: quoteCurrenciesReq, headers: getHeaders() }))
            }
        },
        suggestions: state.quoteCurrencies.items.length > 0 ? state.quoteCurrencies.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : [],
        isLoading: state.quoteCurrencies.items.length == 0 ? state.quoteCurrencies.isFetching : false,
        isSearching: state.quoteCurrencies.items.length > 0 ? state.quoteCurrencies.isFetching : false,


    };
    const onQuoteCurrenciesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetQuoteCurrencies())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        quoteCurrenciesReq.setSearch(wrapper)
        quoteCurrenciesReq.setNextto(undefined)
        dispatch(setIsFilteredQuoteCurrenciesSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getQuoteCurrencies({ body: quoteCurrenciesReq, headers: getHeaders() }))
        return [];

    }
    const onCategoriesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetCategories())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        categoriesReq.setSearch(wrapper)
        categoriesReq.setNextto(undefined)
        dispatch(setIsFilteredCategoriesSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getCategories({ body: categoriesReq, headers: getHeaders() }))
        return [];

    }

    const onPairsFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetMainPair())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        mainPairsReq.setSearch(wrapper)
        mainPairsReq.setNextto(undefined)
        dispatch(setIsFilteredMainPairsSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getMainPairsItems({ body: mainPairsReq, headers: getHeaders() }))
        return [];


    }

    return (
        <>
            <Popup isOpen={(currentAction == 100)} title={t("currencies")} onDismiss={() => { setCurrenctAction(0) }} >

                <CurrenciesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedBaseCurrencyItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            <Popup isOpen={(currentAction == 101)} title={t("currencies")} onDismiss={() => { setCurrenctAction(0) }} >

                <CurrenciesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedQuoteCurrencyItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            <Popup isOpen={(currentAction == 102)} title={t("categories")} onDismiss={() => { setCurrenctAction(0) }} >

                <CategoriesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedCategoryItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>


            <Popup isOpen={(currentAction == 103)} title={t("currencyPairs")} onDismiss={() => { setCurrenctAction(0) }} >

                <CurrencyPairsPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelecteMainPairItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    base: undefined,
                    quote: undefined,
                    name: props.renderObject?.name ?? "",
                    sellPrice: (props.renderObject?.sellPrice && props.renderObject?.sellPrice) ? Number(props.renderObject?.sellPrice).toFixed(state.quoteCurrencies.selected.at(0)?.decimalPlaces ?? 9) : "",
                    buyPrice: (props.renderObject?.buyPrice) ? Number(props.renderObject?.buyPrice).toFixed(state.quoteCurrencies.selected.at(0)?.decimalPlaces ?? 9) : "",
                    categories: undefined,
                    icon: (props.renderObject) ? props.renderObject?.iconSource : '',

                    isActive: (props.renderObject) ? props.renderObject.isActive : true,
                    syncPrices: (props.renderObject) ? props.renderObject.syncPrices : false,

                }}

                validationSchema={Yup.object({
                    name: inputs.namePair,
                    buyPrice: inputs.buyPrice,
                    sellPrice: inputs.sellPrice,
                    icon: inputs.currencyIcon,

                })}

                onSubmit={(values, actions) => {
                    if (props.type == FormType.ADD) {

                        let quote = state.quoteCurrencies.selected.at(0);
                        let base = state.baseCurrencies.selected.at(0);
                        let category = state.categories.selected.at(0);

                        if (base.id == quote.id) {
                            dispatch(setSelectedQuoteCurrencyItems([]))
                            return;
                        }


                        req.setBasecurrency(base?.id);
                        req.setQuotecurrency(quote?.id);
                        req.setName(values.name?.trim());
                        req.setCategory(category?.id);
                        req.setIconsource(values.icon)

                        req.setBuyprice(values.buyPrice?.trim()?.replace(/,/g, ''))
                        req.setSellprice(values.sellPrice?.trim()?.replace(/,/g, ''))
                        req.setIsactive(values.isActive);

                        req.setSyncprices(values.syncPrices)
                        promise = dispatch(add({ body: req, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: AddCurrencyPairResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            id: res.success?.id,
                                            name: values.name?.trim(),
                                            buyPrice: values.buyPrice?.replace(/,/g, ''),
                                            sellPrice: values.sellPrice?.replace(/,/g, ''),
                                            tradingPrice: values.sellPrice?.replace(/,/g, ''),
                                            iconSource: values.icon,
                                            categoryId: category?.id,
                                            categoryNameAr: category?.nameAr,
                                            categoryNameEn: category?.nameEn,
                                            isActive: values.isActive,
                                            syncPrices: values.syncPrices,
                                            baseCurrencyId: base?.id ?? "",
                                            baseCurrencyName: base?.name ?? "",
                                            baseCurrencyCode: base?.code ?? "",
                                            baseCurrencyDecimalPlaces: base?.decimalPlaces ?? undefined,
                                            baseCurrencyForm: base?.form ?? undefined,
                                            baseCurrencySymbol: base?.symbol ?? undefined,
                                            quoteCurrencyId: quote?.id ?? "",
                                            quoteCurrencyName: quote?.name ?? "",
                                            quoteCurrencyCode: quote?.code ?? "",
                                            quoteCurrencyDecimalPlaces: quote?.decimalPlaces ?? undefined,
                                            quoteCurrencyForm: quote?.form ?? undefined,
                                            quoteCurrencySymbol: quote?.symbol ?? "",
                                            priceUpdate: formatDate(timestampToDate(res.success?.createddate?.seconds, res.success?.createddate?.nanos)),
                                            createdDate: formatDate(timestampToDate(res.success?.createddate?.seconds, res.success?.createddate?.nanos)),
                                            addedBy: (current.profile?.firstname?.value ?? "") + " " + (current.profile?.lastname?.value ?? ""),
                                            addedById: current.profile?.id?.value,
                                        });
                                    }
                                }
                                actions.setSubmitting(false)


                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    } else {
                        updateReq.setId(props.renderObject?.id);
                        updateReq.setName(values.name?.trim())
                        updateReq.setSyncprices(values.syncPrices)
                        let category = state.categories.selected.at(0);

                        updateReq.setCategory(category?.id);
                        updateReq.setIconsource(values.icon)


                        promise = dispatch(update({ body: updateReq, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: UpdateCurrencyPairResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            ...props.renderObject,
                                            name: values.name?.trim(),
                                            syncPrices: values.syncPrices,
                                            iconSource: values.icon,
                                            categoryId: category?.id,
                                            categoryNameAr: category?.nameAr,
                                            categoryNameEn: category?.nameEn,


                                        });
                                    }
                                }
                                actions.setSubmitting(false)
                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    }
                }}

            >
                {formkikProps => (
                    <Form >
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? (state.message.data != 401) ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            /> :
                                <SessionExpiredDialog />
                                : null
                            }

                            {props.type == FormType.ADD ? <Stack>
                                <Label required
                                    disabled={state.isLoading}>{t("baseCurrency")}</Label>
                                <List
                                    inputProps={{ placeholder: t("baseCurrency"), required: true }}
                                    disabled={state.isLoading}
                                    suggestionsHeaderText={t('currencies')}
                                    isLoading={state.baseCurrencies.items.length == 0 ? state.baseCurrencies.isFetching : false}
                                    isSearching={state.baseCurrencies.items.length > 0 ? state.baseCurrencies.isFetching : false}
                                    moreSuggestionsAvailable={state.baseCurrencies.hasMore && !state.baseCurrencies.isFetching}
                                    suggestions={state.baseCurrencies.items.length > 0 ? state.baseCurrencies.items.map(e => { return { item: { key: e.id, text: e.name, imageInitials: e?.symbol, secondaryText: e?.code, imageUrl: e?.icon } } as ISuggestionModel<IPersonaProps> }) : []}
                                    onGetMoreResults={() => {
                                        if (state.baseCurrencies.items.length > 0 && !state.baseCurrencies.isFetching) {
                                            const wrapper = new StringValue();
                                            wrapper.setValue(state.baseCurrencies.items.at(state.baseCurrencies.items.length - 1).id);
                                            baseCurrenciesReq.setNextto(wrapper)
                                            getFilterPromise = dispatch(getBaseCurrencies({ body: baseCurrenciesReq, headers: getHeaders() }))
                                        }
                                    }}
                                    onSuggestionClick={(ev?, item?: any, index?: number) => {

                                        var u = state.baseCurrencies.items.findIndex(e => e.id == item.key)
                                        if (u >= 0) {
                                            dispatch(setSelectedBaseCurrencyItems([state.baseCurrencies.items.at(u)]))
                                            let quote = state.quoteCurrencies.selected.at(0);
                                            let base = state.baseCurrencies.items.at(u);
                                            if (quote != undefined && base != undefined && formkikProps.values.name?.length == 0) {
                                                formkikProps.setFieldValue("name", (base?.name == "" ? base?.id : base?.name + " (" + base?.code + ")") + " / " + (quote?.name == "" ? quote?.id : quote?.name + " (" + quote?.code + ")"))
                                            }
                                        }

                                    }}
                                    isPeoplePicker={true}
                                    selectedItems={state.baseCurrencies.selected.length > 0 ? state.baseCurrencies.selected.map(e => { return { key: e.id, text: e.name, imageInitials: e?.symbol, secondaryText: e?.code, imageUrl: e?.icon } as IPersonaProps }) : []}
                                    onChange={(e) => {
                                        dispatch(setSelectedBaseCurrencyItems([]))

                                    }}
                                    onEmptyInputFocus={() => {
                                        dispatch(resetBaseCurrencies())
                                        baseCurrenciesReq.setSearch(undefined)
                                        baseCurrenciesReq.setNextto(undefined)
                                        dispatch(setIsFilteredBaseCurrenciesSet(false))
                                        getFilterPromise?.abort()
                                        getFilterPromise = dispatch(getBaseCurrencies({ body: baseCurrenciesReq, headers: getHeaders() }))
                                        return []

                                    }}
                                    onFilterChanged={onBaseCurrenciesFilterChanged}
                                    endButtons={[
                                        {
                                            title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                            onClick: () => {
                                                setCurrenctAction(100)

                                            }
                                        }]}
                                />
                            </Stack> : undefined}


                            {props.type == FormType.ADD ? <Stack>
                                <Label required
                                    disabled={state.isLoading}>{t("quoteCurrency")}</Label>
                                <List
                                    inputProps={{ placeholder: t("quoteCurrency"), required: true }}
                                    disabled={state.isLoading}
                                    suggestionsHeaderText={t('currencies')}
                                    isLoading={state.quoteCurrencies.items.length == 0 ? state.quoteCurrencies.isFetching : false}
                                    isSearching={state.quoteCurrencies.items.length > 0 ? state.quoteCurrencies.isFetching : false}
                                    moreSuggestionsAvailable={state.quoteCurrencies.hasMore && !state.quoteCurrencies.isFetching}
                                    suggestions={state.quoteCurrencies.items.length > 0 ? state.quoteCurrencies.items.map(e => { return { item: { key: e.id, text: e.name, imageInitials: e?.symbol, secondaryText: e?.code, imageUrl: e?.icon } } as ISuggestionModel<IPersonaProps> }) : []}

                                    onGetMoreResults={() => {
                                        if (state.quoteCurrencies.items.length > 0 && !state.quoteCurrencies.isFetching) {
                                            const wrapper = new StringValue();
                                            wrapper.setValue(state.quoteCurrencies.items.at(state.quoteCurrencies.items.length - 1).id);
                                            quoteCurrenciesReq.setNextto(wrapper)
                                            getFilterPromise = dispatch(getQuoteCurrencies({ body: quoteCurrenciesReq, headers: getHeaders() }))
                                        }
                                    }}
                                    onSuggestionClick={(ev?, item?: any, index?: number) => {

                                        var u = state.quoteCurrencies.items.findIndex(e => e.id == item.key)
                                        if (u >= 0) {
                                            dispatch(setSelectedQuoteCurrencyItems([state.quoteCurrencies.items.at(u)]))
                                            let quote = state.quoteCurrencies.items.at(u);
                                            let base = state.baseCurrencies.selected.at(0);
                                            if (quote != undefined && base != undefined && formkikProps.values.name?.length == 0) {
                                                formkikProps.setFieldValue("name", (base?.name == "" ? base?.id : base?.name + " (" + base?.code + ")") + " / " + (quote?.name == "" ? quote?.id : quote?.name + " (" + quote?.code + ")"))
                                            }
                                        }

                                    }}
                                    isPeoplePicker={true}
                                    selectedItems={state.quoteCurrencies.selected.length > 0 ? state.quoteCurrencies.selected.map(e => { return { key: e.id, text: e.name, imageInitials: e?.symbol, secondaryText: e?.code, imageUrl: e?.icon } as IPersonaProps }) : []}
                                    onChange={(e) => {
                                        dispatch(setSelectedQuoteCurrencyItems([]))

                                    }}
                                    onEmptyInputFocus={() => {
                                        dispatch(resetQuoteCurrencies())
                                        quoteCurrenciesReq.setSearch(undefined)
                                        quoteCurrenciesReq.setNextto(undefined)
                                        dispatch(setIsFilteredQuoteCurrenciesSet(false))
                                        getFilterPromise?.abort()
                                        getFilterPromise = dispatch(getQuoteCurrencies({ body: quoteCurrenciesReq, headers: getHeaders() }))
                                        return []

                                    }}
                                    onFilterChanged={onQuoteCurrenciesFilterChanged}
                                    endButtons={[
                                        {
                                            title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                            onClick: () => {
                                                setCurrenctAction(101)

                                            }
                                        }]}
                                />
                            </Stack> : undefined}

                            <Field name="name" label={t("pairName")} placeholder={t("pairNameHint")} component={InputField} disabled={state.isLoading} maxLength={200} required />


                            <Stack>
                                <Label required
                                    disabled={state.isLoading}>{t("category")}</Label>
                                <List
                                    inputProps={{ placeholder: t("category"), required: true }}
                                    disabled={state.isLoading}
                                    suggestionsHeaderText={t('categories')}
                                    isLoading={state.categories.items.length == 0 ? state.categories.isFetching : false}
                                    isSearching={state.categories.items.length > 0 ? state.categories.isFetching : false}
                                    moreSuggestionsAvailable={state.categories.hasMore && !state.categories.isFetching}
                                    suggestions={state.categories.items.length > 0 ? state.categories.items.map(e => { return { item: { key: e.id, text: e.nameAr + " | " + e.nameEn, imageInitials: (e.parentCategoryNameAr == "" && e.parentCategoryNameEn == "" ? undefined : e.parentCategoryNameAr + " | " + e.parentCategoryNameEn), secondaryText: e.parentCategoryId == undefined ? undefined : e.parentCategoryNameAr == "" && e.parentCategoryNameEn == "" ? undefined : e.parentCategoryNameAr + " | " + e.parentCategoryNameEn, imageUrl: e?.iconSource } } as ISuggestionModel<IPersonaProps> }) : []}
                                    onGetMoreResults={() => {
                                        if (state.categories.items.length > 0 && !state.categories.isFetching) {
                                            const wrapper = new StringValue();
                                            wrapper.setValue(state.categories.items.at(state.categories.items.length - 1).id);
                                            categoriesReq.setNextto(wrapper)
                                            getFilterPromise = dispatch(getCategories({ body: categoriesReq, headers: getHeaders() }))
                                        }
                                    }}
                                    onSuggestionClick={(ev?, item?: any, index?: number) => {

                                        var u = state.categories.items.findIndex(e => e.id == item.key)
                                        if (u >= 0) {
                                            dispatch(setSelectedCategoryItems([state.categories.items.at(u)]))

                                        }

                                    }}
                                    isPeoplePicker={true}
                                    selectedItems={state.categories.selected.length > 0 ? state.categories.selected.map(e => { return { key: e.id, text: e.nameAr + " | " + e.nameEn, imageInitials: (e.parentCategoryNameAr == "" && e.parentCategoryNameEn == "" ? undefined : e.parentCategoryNameAr + " | " + e.parentCategoryNameEn), secondaryText: e.parentCategoryId == undefined ? undefined : e.parentCategoryNameAr == "" && e.parentCategoryNameEn == "" ? undefined : e.parentCategoryNameAr + " | " + e.parentCategoryNameEn, imageUrl: e?.iconSource } as IPersonaProps }) : []}
                                    onChange={(e) => {
                                        dispatch(setSelectedCategoryItems([]))

                                    }}
                                    onEmptyInputFocus={() => {
                                        dispatch(resetCategories())
                                        categoriesReq.setSearch(undefined)
                                        categoriesReq.setNextto(undefined)
                                        dispatch(setIsFilteredCategoriesSet(false))
                                        getFilterPromise?.abort()
                                        getFilterPromise = dispatch(getCategories({ body: categoriesReq, headers: getHeaders() }))
                                        return []

                                    }}
                                    onFilterChanged={onCategoriesFilterChanged}
                                    endButtons={[
                                        {
                                            title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                            onClick: () => {
                                                setCurrenctAction(102)

                                            }
                                        }]}
                                />
                            </Stack>

                            <Field name="icon" label={t("currencyIcon")} placeholder={"https://icons.com/usd.png"} component={InputField} disabled={state.isLoading} maxLength={2048} />

                            {props.type == FormType.ADD ? <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: props.childrenGap }}>
                                <Stack.Item grow>
                                    <Field name="buyPrice" label={t("buyPrice")} placeholder={t("buyPrice")}
                                        component={InputField} disabled={state.isLoading} maxLength={15}
                                        onBlur={(event: any) => {
                                            if (isNaN(Number(formkikProps.values.buyPrice?.replace(/,/g, '')))) {
                                                formkikProps.handleBlur(event);
                                                return;
                                            }
                                            let quote = state.quoteCurrencies.selected.at(0);
                                            if (quote)
                                                formkikProps.setFieldValue("buyPrice", Number(formkikProps.values.buyPrice?.replace(/,/g, '')).toFixed(quote?.decimalPlaces ?? 9))
                                            else
                                                formkikProps.setFieldValue("buyPrice", Number(formkikProps.values.buyPrice?.replace(/,/g, '')).toFixed(9))
                                            formkikProps.handleBlur(event);
                                        }}
                                        required />
                                </Stack.Item>
                                <Stack.Item grow>
                                    <Field name="sellPrice" label={t("sellPrice")} placeholder={t("sellPrice")}
                                        component={InputField} disabled={state.isLoading} maxLength={15}
                                        onBlur={(event: any) => {
                                            if (isNaN(Number(formkikProps.values.sellPrice?.replace(/,/g, '')))) {
                                                formkikProps.handleBlur(event);
                                                return;
                                            }
                                            let quote = state.quoteCurrencies.selected.at(0);
                                            if (quote)
                                                formkikProps.setFieldValue("sellPrice", Number(formkikProps.values.sellPrice?.replace(/,/g, '')).toFixed(quote?.decimalPlaces ?? 9))
                                            else
                                                formkikProps.setFieldValue("sellPrice", Number(formkikProps.values.sellPrice?.replace(/,/g, '')).toFixed(9))
                                            formkikProps.handleBlur(event);
                                        }}
                                        required />
                                </Stack.Item>

                            </Stack> : undefined}
                            {props.type == FormType.ADD ? <Toggle

                                onText={t("active")} offText={t("inactive")}
                                label={t("status")}
                                disabled={state.isLoading}

                                checked={formkikProps.values.isActive}
                                onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                                    formkikProps.setFieldValue('isActive', checked);
                                }
                                }
                            /> : undefined}

                            <Toggle

                                onText={t("active")} offText={t("inactive")}
                                label={<div>
                                    {t("syncPrices") + ' '}
                                    <TooltipHost content={t("syncPricesHint")}>
                                        <Icon iconName="Info" aria-label={t("syncPrices")} />
                                    </TooltipHost>
                                </div>}
                                disabled={state.isLoading}
                                checked={formkikProps.values.syncPrices}
                                onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                                    formkikProps.setFieldValue('syncPrices', checked);
                                }
                                }
                            />

                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("add") : t("edit")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={props.type == FormType.ADD ? t("discard") : t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>



                    </Form>
                )}
            </Formik>
        </>
    );

}

