import { ComboBox, DefaultButton, DialogFooter, Icon, IconButton, IPersonaProps, IPickerItemProps, IStackTokens, ISuggestionItemProps, ISuggestionModel, ISuggestionsProps, ITag, Label, MessageBar, MessageBarType, Modal, NormalPeoplePicker, PeoplePickerItem, Persona, PersonaSize, PrimaryButton, Spinner, SpinnerSize, Stack, TagItemSuggestion, TagPicker, Text, TextField, Toggle, TooltipHost, ValidationState } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { FormEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks";
import { dismissMessage, getAccountTypes, getCurrencies, getLimitations, reset, getAccounts, resetAccountsSet, setIsFilteredAccountsSet, setSelectedAccountsItems, updateExternalTransferEntityConfig, resetLimitations, resetAccountTypes, setIsFilteredAccountTypesSet, setIsFilteredCurrenciesSet, setSelectedAccountTypeItems, setSelectedCurrencyItems, setIsFilteredLimitationsSet, setSelectedLimitationItems, resetItem, updateOpenAccountDefaultConfiguration, setSelectedCurrencyPairsItems, resetCurrencyPairsSet, setIsFilteredCurrencyPairSet, getCurrencyPairs, updateCurrencyPairConfiguration } from "./OrganizationClientPortalSettingFormSlice";
import * as Yup from 'yup';
import { DynamicInputField, InputField, NumberInputField } from "../../controls/Controls";
import { inputs } from "../../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../../app/Api";
import { Message } from "../../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../../FormProps";
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog";
import { formatDate, timestampToDate } from "../../../../app/Helpers";
import { AuthenticateReply } from "../../../../repository/UserManagement/authentication_pb";
import { DynamicField } from "../../../common/DynamicFields/DynamicFields";
import { ExtraField } from "../../../../repository/Accountant/common_pb";
import { TableState } from "../../../common/Table/TableSate";
import { AccountStatus, LimitationType, Operations } from "../../../../app/Enums";
import { GetAccountTypesRequest } from "../../../../repository/Accountant/account_type_pb"
import { GetCurrenciesRequest } from "../../../../repository/Accountant/currency_pb"
import { GetUsersRequest } from "../../../../repository/UserManagement/Customers/user_pb"
import { GetLimitationsRequest } from "../../../../repository/Accountant/limitation_pb"
import { UpdateExternalTransfersEntitiesConfigurationRequest, UpdateExternalTransfersEntitiesConfigurationResponse } from "../../../../repository/UserManagement/organizationclientportalsetting_pb";
import { List } from "../../../common/List/List";
import { Popup } from "../../../common/Popup/Popup";

import { GetAccountsRequest } from "../../../../repository/Accountant/account_pb";
import { setSelectedExternalTransferEntityConfig } from "../../../pages/Organizations/OrganizationClientPortalSettingPage/OrganizationClientPortalSettingPageSlice";
import { AccountsPage } from "../../../pages/Accountant/Accounts/AccountsPage";

let req: UpdateExternalTransfersEntitiesConfigurationRequest;
let acconuntsReq: GetAccountsRequest;

let promise: any;
let getFilterPromise: any;
let org: number = -1;
let current: AuthenticateReply.AsObject;

export const OrganizationClientPortalSettingExternalTransfersForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();
    const [currentAction, setCurrenctAction] = useState(0)


    const state: { isLoading: boolean, message: ApiMessage | undefined, accounts: TableState, } = useAppSelector((state) => {

        return {
            isLoading: state.organizationClientPortalSettingForm.isLoading,
            message: state.organizationClientPortalSettingForm.message,

            accounts: state.organizationClientPortalSettingForm.accounts,


        }
    })

    useEffect(() => {
        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        }

        var l = searchParams.get('org');
        req = new UpdateExternalTransfersEntitiesConfigurationRequest();
        acconuntsReq = new GetAccountsRequest();

        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
                req.setId(Number(current.organization?.id))

            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                req.setId(org)
                acconuntsReq.setOrganization(wrapper)

            }
        } else {
            req.setId(Number(current.organization?.id))
        }


        const boolv = new BoolValue();
        boolv.setValue(true);
        const stat = new Int32Value();
        stat.setValue(AccountStatus.ACTIVE);
        const stat2 = new Int32Value();
        stat2.setValue(1);


        acconuntsReq.setNumofresults(state.accounts.numberOfResults)
        acconuntsReq.setOrder(state.accounts.isDescending)
        acconuntsReq.setLinkingstate(stat2)

        if (props.renderObject?.gwAccountInternalId)
            dispatch(setSelectedAccountsItems(([{ internalId: props.renderObject?.gwAccountInternalId, id: props.renderObject?.gwAccountId }])))


        return () => { //clean up
            promise?.abort();
            dispatch(reset());
        }
    }, [])

    const onAccountsFilterChanged = (filterText: string, selectedItems?: IPersonaProps[]): IPersonaProps[] => {

        dispatch(resetAccountsSet())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        acconuntsReq.setSearch(wrapper)
        acconuntsReq.setNextto(undefined)

        dispatch(setIsFilteredAccountsSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getAccounts({ body: acconuntsReq, headers: getHeaders() }))
        return [];


    }

    return (
        <>

            <Popup isOpen={(currentAction == 101)} title={t("accounts")} onDismiss={() => { setCurrenctAction(0) }} >

                <AccountsPage isSelection={true} onItemSelected={(e) => {

                    dispatch(setSelectedAccountsItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    account: undefined,

                    name: props?.renderObject?.name ?? "",

                }}

                validationSchema={Yup.object({
                    name: inputs.accountName,

                })}

                onSubmit={(values, actions) => {

                    let accounts = state.accounts.selected.at(0);
                    if (props.type == FormType.ADD) {
                        if (accounts == undefined) {

                            return;
                        }
                        req.setGwaccount(accounts?.internalId);
                    } else {
                        req.setGwaccount(accounts?.internalId);
                    }
                    req.setName((values.name));


                    promise = dispatch(updateExternalTransferEntityConfig({ body: req, headers: getHeaders() }))
                    promise.unwrap()
                        .then((res: UpdateExternalTransfersEntitiesConfigurationResponse.AsObject) => {
                            if (res) {
                                if (props?.onSuccess) {
                                    props?.onSuccess({
                                        name: values.name,
                                        gwAccountInternalId: accounts?.internalId,
                                        gwAccountId: accounts?.id,
                                    });

                                }
                            }
                            actions.setSubmitting(false)


                        })
                        .catch((error: ApiMessage) => {
                            actions.setSubmitting(false)
                        })

                }}

            >
                {formkikProps => (
                    <Form >
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? (state.message.data != 401) ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            /> :
                                <SessionExpiredDialog />
                                : null
                            }


                            {props.type == FormType.ADD ? <Stack grow>
                                <Label required disabled={state.isLoading}>{t("account")}</Label>
                                <List
                                    isPeoplePicker={true}
                                    suggestionsHeaderText={t('accounts')}
                                    isLoading={state.accounts.items.length == 0 ? state.accounts.isFetching : false}
                                    isSearching={state.accounts.items.length > 0 ? state.accounts.isFetching : false}
                                    moreSuggestionsAvailable={state.accounts.hasMore && !state.accounts.isFetching}
                                    suggestions={state.accounts.items.length > 0 ? state.accounts.items.map(e => { return { item: { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } } as ISuggestionModel<IPersonaProps> }) : []}
                                    onGetMoreResults={() => {
                                        if (state.accounts.items.length > 0 && !state.accounts.isFetching) {
                                            const wrapper = new StringValue();
                                            wrapper.setValue(state.accounts.items.at(state.accounts.items.length - 1).internalId);
                                            acconuntsReq.setNextto(wrapper)
                                            getFilterPromise = dispatch(getAccounts({ body: acconuntsReq, headers: getHeaders() }))
                                        }
                                    }}
                                    onSuggestionClick={(ev?, item?: any, index?: number) => {
                                        var u = state.accounts.items.findIndex(e => e.internalId == item.key)
                                        if (u >= 0) {
                                            dispatch(setSelectedAccountsItems([state.accounts.items.at(u)]))
                                        }
                                        if (formkikProps.values.name == "") {
                                            formkikProps.setFieldValue("name", state.accounts.items.at(u).name)
                                        }
                                    }}
                                    inputProps={{ placeholder: t("account"), required: true }}

                                    disabled={state.isLoading}
                                    selectedItems={state.accounts.selected.length > 0 ? state.accounts.selected.map(e => { return { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } as IPersonaProps }) : []}
                                    onChange={(e) => {
                                        dispatch(setSelectedAccountsItems([]))
                                    }}
                                    onEmptyInputFocus={() => {
                                        dispatch(resetAccountsSet())
                                        acconuntsReq.setSearch(undefined)
                                        acconuntsReq.setNextto(undefined)

                                        dispatch(setIsFilteredAccountsSet(false))
                                        getFilterPromise?.abort()
                                        getFilterPromise = dispatch(getAccounts({ body: acconuntsReq, headers: getHeaders() }))
                                        return []
                                    }}
                                    onFilterChanged={onAccountsFilterChanged}
                                    endButtons={[
                                        {
                                            title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                            onClick: () => {
                                                setCurrenctAction(101)

                                            }
                                        }]}
                                />

                            </Stack> : undefined}



                            <Field name="name" label={t("accountName")} placeholder={t("accountName")} component={InputField} disabled={state.isLoading} autoFocus required maxLength={50} />




                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("add") : t("edit")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={props.type == FormType.ADD ? t("discard") : t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>



                    </Form>
                )}
            </Formik>
        </>
    );

}

