import { ComboBox, DefaultButton, DialogFooter, IComboBoxOption, Icon, IconButton, IStackTokens, ISuggestionItemProps, ISuggestionModel, ISuggestionsProps, ITag, Label, MessageBar, MessageBarType, Modal, PrimaryButton, Spinner, SpinnerSize, Stack, TagItemSuggestion, TagPicker, Text, TextField, Toggle, TooltipHost } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { FormEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../../app/Hooks";
import { add, dismissMessage, get, reset, setStage1, update, getCreditorAccountTypes, resetDebtorFees, resetCreditorFees, setIsFilteredDebtorFeesSet, setSelectedCreditorFee, setSelectedDebtorFee, getDebtorFees, setIsFilteredCreditorFeesSet, getCreditorFees, resetCreditorAccountTypes, setSelectedCreditorAccountType, setIsFilteredCreditorAccountTypesSet } from "./AccounetTypeRulesFormSlice";
import * as Yup from 'yup';
import { DynamicInputField, InputField } from "../../../controls/Controls";
import { inputs } from "../../../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../../../app/Api";
import { Message } from "../../../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../../../FormProps";
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../../../common/SessionExpiredDialog/SessionExpiredDialog";
import { formatDate, getOperations, normalizeKey, timestampToDate } from "../../../../../app/Helpers";
import { AuthenticateReply } from "../../../../../repository/UserManagement/authentication_pb";
import { DynamicField } from "../../../../common/DynamicFields/DynamicFields";
import { ExtraField } from "../../../../../repository/Accountant/common_pb";
import { AddAccountTypeRuleRequest, GetAccountTypeRuleRequest, UpdateAccountTypeRuleRequest, AddAccountTypeRuleResponse, UpdateAccountTypeRuleResponse } from "../../../../../repository/Accountant/account_type_rule_pb";
import { TableState } from "../../../../common/Table/TableSate";
import { GetAccountTypesRequest } from "../../../../../repository/Accountant/account_type_pb";
import { GetFeesRequest } from "../../../../../repository/Accountant/fee_pb";
import { Operations } from "../../../../../app/Enums";
import { List } from "../../../../common/List/List";
import { Popup } from "../../../../common/Popup/Popup";
import { AccountTypesPage } from "../../../../pages/Accountant/AccountTypes/AccountTypesPage";
import { FeesPage } from "../../../../pages/Accountant/Fees/FeesPage";


let req: AddAccountTypeRuleRequest;
let updateReq: UpdateAccountTypeRuleRequest;
let creditTypesReq: GetAccountTypesRequest;
let debtorFeesReq: GetFeesRequest;
let creditorFeesReq: GetFeesRequest;

let promise: any;
let getFilterPromise: any;
let org: number = -1;
let current: AuthenticateReply.AsObject;

export const AccountTypeRulesForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();
    const [currentAction, setCurrenctAction] = useState(0)

    const state: { isLoading: boolean, message: ApiMessage | undefined, stage1: any, creditorFees: TableState, debtorFees: TableState, creditorAccountTypes: TableState } = useAppSelector((state) => {

        return {
            isLoading: state.accountTypeRulesForm.isLoading, message: state.accountTypeRulesForm.message, stage1: state.accountTypeRulesForm.stage1,
            creditorAccountTypes: state.accountTypeRulesForm.creditorAccountTypes,
            debtorFees: state.accountTypeRulesForm.debtorFees,
            creditorFees: state.accountTypeRulesForm.creditorFees,

        }
    })

    useEffect(() => {
        var l = searchParams.get('org');
        req = new AddAccountTypeRuleRequest();
        updateReq = new UpdateAccountTypeRuleRequest()
        creditTypesReq = new GetAccountTypesRequest()
        debtorFeesReq = new GetFeesRequest()
        creditorFeesReq = new GetFeesRequest()

        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                req.setOrganization(wrapper)
                creditorFeesReq.setOrganization(wrapper)
                debtorFeesReq.setOrganization(wrapper)
                creditTypesReq.setOrganization(wrapper)

            }
        }

        if (props.type == FormType.EDIT) {
            if (props.fetchData) {
                let r = new GetAccountTypeRuleRequest()
                r.setId(props?.renderObject?.id);
                promise?.abort()
                promise = dispatch(get({ body: r, headers: getHeaders() }))
            } else {
                dispatch(setStage1(props?.renderObject))
            }
        }
        creditTypesReq.setNumofresults(state.creditorAccountTypes.numberOfResults)
        creditTypesReq.setOrder(state.creditorAccountTypes.isDescending)
        creditorFeesReq.setNumofresults(state.creditorFees.numberOfResults)
        creditorFeesReq.setOrder(state.creditorFees.isDescending)
        debtorFeesReq.setNumofresults(state.debtorFees.numberOfResults)
        debtorFeesReq.setOrder(state.debtorFees.isDescending)
        const boolv = new BoolValue();
        boolv.setValue(true);
        debtorFeesReq.setIsavailable(boolv)
        creditTypesReq.setIsavailable(boolv)
        creditorFeesReq.setIsavailable(boolv)

        if (props.renderObject?.debtorFeeId)
            dispatch(setSelectedDebtorFee(([{ id: props.renderObject.debtorFeeId, name: props.renderObject.debtorFeeName }])))

        if (props.renderObject?.creditorFeeId)
            dispatch(setSelectedCreditorFee(([{ id: props.renderObject.creditorFeeId, name: props.renderObject.creditorFeeName }])))

        if (props.renderObject?.creditorAccountTypeName)
            dispatch(setSelectedCreditorAccountType(([{ id: props.renderObject.creditorAccountTypeId, name: props.renderObject.creditorAccountTypeName }])))


        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        }
        return () => { //clean up
            promise?.abort();
            dispatch(reset());
        }
    }, [])



    const onDebtorFeesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetDebtorFees())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        debtorFeesReq.setSearch(wrapper)
        debtorFeesReq.setNextto(undefined)
        dispatch(setIsFilteredDebtorFeesSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getDebtorFees({ body: debtorFeesReq, headers: getHeaders() }))
        return [];
    }


    const onCreditorFeesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetCreditorFees())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        creditorFeesReq.setSearch(wrapper)
        creditorFeesReq.setNextto(undefined)
        dispatch(setIsFilteredCreditorFeesSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getCreditorFees({ body: creditorFeesReq, headers: getHeaders() }))
        return [];
    }

    const onCreditorTypesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetCreditorAccountTypes())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        creditTypesReq.setSearch(wrapper)
        creditTypesReq.setNextto(undefined)
        dispatch(setIsFilteredCreditorAccountTypesSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getCreditorAccountTypes({ body: creditTypesReq, headers: getHeaders() }))
        return [];

    }



    return (
        <>
            <Popup isOpen={(currentAction == 100)} title={t("creditorAccountType")} onDismiss={() => { setCurrenctAction(0) }} >

                <AccountTypesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedCreditorAccountType([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Popup isOpen={(currentAction == 101)} title={t("fees")} onDismiss={() => { setCurrenctAction(0) }} >

                <FeesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedCreditorFee([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            <Popup isOpen={(currentAction == 102)} title={t("fees")} onDismiss={() => { setCurrenctAction(0) }} >

                <FeesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedDebtorFee([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    operation: (props.renderObject?.operation) ? { key: props.renderObject?.operation, text: "" } : { key: 1, text: t("queryBalance") },
                    debtorFee: undefined,
                    creditorFee: undefined,
                    creditorAccountType: undefined,
                    isActive: (props.renderObject) ? props.renderObject.isActive == undefined ? true : props.renderObject.isActive : true,
                    directReservation: false,
                    extraFields: (state.stage1?.extraFields) ? state.stage1?.extraFields as DynamicField[] : [] as DynamicField[],

                }}

                validationSchema={Yup.object({

                    extraFields: inputs.extraFields,

                })}

                onSubmit={(values, actions) => {
                    if (props.type == FormType.ADD) {

                        let creditorAccountType = state.creditorAccountTypes.selected.at(0);
                        if (!creditorAccountType && (values.operation.key == Operations.TRANSFER || values.operation.key == Operations.EXTERNAL_TRANSFER || values.operation.key == Operations.SETTLEMENT || values.operation.key == Operations.TRADING_ORDER_BUY || values.operation.key == Operations.TRADING_ORDER_SELL)) {
                            actions.setFieldError("creditorAccountType", "")
                            return;
                        }
                        let creditorFee = state.creditorFees.selected.at(0);

                        let debtorFee = state.debtorFees.selected.at(0);

                        req.setDebtoraccounttype(props.renderObject?.debtorAccountType?.id);
                        if (creditorAccountType)
                            req.setCreditoraccounttype(creditorAccountType.id);

                        req.setIsactive(values.isActive)
                        req.setDirectreservation(values.directReservation)
                        req.setOperation(values.operation.key)
                        req.setCreditorfee(creditorFee?.id)
                        req.setDebtorfee(debtorFee?.id)
                        const extraField: ExtraField[] = [];
                        const count: Number = 1;
                        values.extraFields.forEach((e: DynamicField) => {
                            const r = new ExtraField();
                            r.setKey(e.key);
                            r.setLabel(e.label?.trim() ?? "")
                            r.setType(1)
                            r.setValue(e.value.trim())
                            extraField.push(r)
                        })
                        req.setExtrafieldsList(extraField)
                        promise = dispatch(add({ body: req, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: AddAccountTypeRuleResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            id: res.success?.id,
                                            debtorAccountTypeId: props.renderObject?.debtorAccountType?.id,
                                            debtorAccountTypeName: props.renderObject?.debtorAccountType?.name,
                                            creditorAccountTypeId: creditorAccountType?.id,
                                            creditorAccountTypeName: creditorAccountType?.name,
                                            operation: values.operation.key,
                                            creditorFeeId: creditorFee?.id,
                                            creditorFeeName: creditorFee?.name,
                                            debtorFeeId: debtorFee?.id,
                                            debtorFeeName: debtorFee?.name,
                                            isActive: values.isActive,
                                            createdDate: formatDate(timestampToDate(res.success?.createddate?.seconds, res.success?.createddate?.nanos)),
                                            addedBy: (current.profile?.firstname?.value ?? "") + " " + (current.profile?.lastname?.value ?? ""),
                                            addedById: current.profile?.id?.value,
                                            directReservation: values.directReservation
                                        });
                                    }
                                }
                                actions.setSubmitting(false)

                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    } else {
                        let creditorAccountType = state.creditorAccountTypes.selected.at(0);
                        if (!creditorAccountType && (values.operation.key == Operations.TRANSFER || values.operation.key == Operations.EXTERNAL_TRANSFER || values.operation.key == Operations.SETTLEMENT || values.operation.key == Operations.TRADING_ORDER_BUY || values.operation.key == Operations.TRADING_ORDER_SELL)) {
                            actions.setFieldError("creditorAccountType", "")
                            return;
                        }
                        let creditorFee = state.creditorFees?.selected?.at(0);

                        let debtorFee = state.debtorFees?.selected?.at(0);
                        if (creditorAccountType)
                            updateReq.setCreditoraccounttype(creditorAccountType.id);
                        updateReq.setId(props.renderObject?.id)
                        updateReq.setCreditorfee(creditorFee?.id)
                        updateReq.setDebtorfee(debtorFee?.id)
                        const extraField: ExtraField[] = [];
                        const count: Number = 1;
                        values.extraFields.forEach((e: DynamicField) => {
                            const r = new ExtraField();
                            r.setKey(e.key);
                            r.setLabel(e.label?.trim() ?? "")
                            r.setType(1)
                            r.setValue(e.value.trim())
                            extraField.push(r)
                        })
                        updateReq.setExtrafieldsList(extraField)
                        updateReq.setId(props.renderObject?.id)
                        promise = dispatch(update({ body: updateReq, headers: getHeaders() }))
                        promise.unwrap().then((res: UpdateAccountTypeRuleResponse.AsObject) => {
                            if (res) {
                                if (props?.onSuccess) {
                                    props?.onSuccess({
                                        ...props.renderObject,
                                        creditorAccountTypeId: creditorAccountType?.id,
                                        creditorAccountTypeName: creditorAccountType?.name,
                                        creditorFeeId: creditorFee?.id,
                                        creditorFeeName: creditorFee?.name,
                                        debtorFeeId: debtorFee?.id,
                                        debtorFeeName: debtorFee?.name,
                                        extraFields: values.extraFields
                                    });
                                }
                            }
                            actions.setSubmitting(false)
                        })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    }
                }}

            >
                {formkikProps => (
                    <Form >
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? (state.message.data != 401) ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            /> :
                                <SessionExpiredDialog />
                                : null
                            }

                            {props.type == FormType.ADD ? <ComboBox
                                label={t("operation")}
                                allowFreeform={true}
                                autoComplete={'on'}
                                disabled={state.isLoading}
                                options={getOperations(false, 0, false).map(o => {
                                    return { key: o.key, text: t(normalizeKey(o.text)) }
                                }).concat(getOperations(undefined, 2, false).map(o => {
                                    return { key: o.key, text: t(normalizeKey(o.text)) }
                                })) as IComboBoxOption[]}

                                selectedKey={formkikProps.values.operation.key}
                                onChange={(e, o) => {
                                    formkikProps.setFieldValue("operation", { key: o?.key, text: o?.text })
                                }}
                            // Force re-creating the component when the toggles change (for demo purposes)
                            /> : undefined}
                            {formkikProps.values.operation.key == Operations.TRANSFER || formkikProps.values.operation.key == Operations.EXTERNAL_TRANSFER || formkikProps.values.operation.key == Operations.SETTLEMENT || formkikProps.values.operation.key == Operations.TRADING_ORDER_BUY || formkikProps.values.operation.key == Operations.TRADING_ORDER_SELL ?
                                <Stack>
                                    <Label required disabled={state.isLoading}>{t("creditorAccountType")}</Label>
                                    <List
                                        inputProps={{ placeholder: t("creditorAccountType"), required: true }}
                                        disabled={state.isLoading}
                                        suggestionsHeaderText={t('creditorAccountType')}
                                        isLoading={state.creditorAccountTypes.items.length == 0 ? state.creditorAccountTypes.isFetching : false}
                                        isSearching={state.creditorAccountTypes.items.length > 0 ? state.creditorAccountTypes.isFetching : false}
                                        moreSuggestionsAvailable={state.creditorAccountTypes.hasMore && !state.creditorAccountTypes.isFetching}
                                        suggestions={state.creditorAccountTypes.items.length > 0 ? state.creditorAccountTypes.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : []}
                                        onGetMoreResults={() => {
                                            if (state.creditorAccountTypes.items.length > 0 && !state.creditorAccountTypes.isFetching) {
                                                const wrapper = new StringValue();
                                                wrapper.setValue(state.creditorAccountTypes.items.at(state.creditorAccountTypes.items.length - 1).id);
                                                creditTypesReq.setNextto(wrapper)
                                                getFilterPromise = dispatch(getCreditorAccountTypes({ body: creditTypesReq, headers: getHeaders() }))
                                            }
                                        }}
                                        onSuggestionClick={(ev?, item?: any, index?: number) => {
                                            var u = state.creditorAccountTypes.items.findIndex(e => e.id == item.key)
                                            if (u >= 0) {
                                                dispatch(setSelectedCreditorAccountType([state.creditorAccountTypes.items.at(u)]))
                                            }
                                        }}
                                        isPeoplePicker={false}
                                        selectedItems={state.creditorAccountTypes.selected.length > 0 ? state.creditorAccountTypes.selected.map(e => { return { name: e.name, key: e.id } as ITag }) : []}
                                        onChange={(e) => {
                                            dispatch(setSelectedCreditorAccountType([]))

                                        }}
                                        onEmptyInputFocus={() => {
                                            dispatch(resetCreditorAccountTypes())
                                            creditTypesReq.setSearch(undefined)
                                            creditTypesReq.setNextto(undefined)
                                            dispatch(setIsFilteredCreditorAccountTypesSet(false))
                                            getFilterPromise?.abort()
                                            getFilterPromise = dispatch(getCreditorAccountTypes({ body: creditTypesReq, headers: getHeaders() }))
                                            return []
                                        }}
                                        onFilterChanged={onCreditorTypesFilterChanged}
                                        endButtons={[
                                            {
                                                title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                                onClick: () => {
                                                    setCurrenctAction(100)

                                                }
                                            }]}
                                    />
                                </Stack> : undefined}
                            {formkikProps.values.operation.key == Operations.TRANSFER || formkikProps.values.operation.key == Operations.EXTERNAL_TRANSFER || formkikProps.values.operation.key == Operations.SETTLEMENT || formkikProps.values.operation.key == Operations.TRADING_ORDER_BUY || formkikProps.values.operation.key == Operations.TRADING_ORDER_SELL ? <Stack>

                                <Label disabled={state.isLoading}>{
                                    <div>
                                        {formkikProps.values.operation.key == Operations.TRANSFER || formkikProps.values.operation.key == Operations.EXTERNAL_TRANSFER || formkikProps.values.operation.key == Operations.SETTLEMENT || formkikProps.values.operation.key == Operations.TRADING_ORDER_BUY || formkikProps.values.operation.key == Operations.TRADING_ORDER_SELL ? t("creditorFees") + ' ' : t("fees") + ' '}
                                        <TooltipHost content={t("feesDescription")}>
                                            <Icon iconName="Info" aria-label={formkikProps.values.operation.key == Operations.TRANSFER || formkikProps.values.operation.key == Operations.EXTERNAL_TRANSFER || formkikProps.values.operation.key == Operations.SETTLEMENT || formkikProps.values.operation.key == Operations.TRADING_ORDER_BUY || formkikProps.values.operation.key == Operations.TRADING_ORDER_SELL ? t("creditorFees") : t("fees")} />
                                        </TooltipHost>
                                    </div>
                                }</Label>
                                <List
                                    inputProps={{ placeholder: formkikProps.values.operation.key == Operations.TRANSFER || formkikProps.values.operation.key == Operations.EXTERNAL_TRANSFER || formkikProps.values.operation.key == Operations.SETTLEMENT || formkikProps.values.operation.key == Operations.TRADING_ORDER_BUY || formkikProps.values.operation.key == Operations.TRADING_ORDER_SELL ? t("creditorFees") : t("fees") }}
                                    disabled={state.isLoading}
                                    suggestionsHeaderText={t('fees')}
                                    isLoading={state.creditorFees.items.length == 0 ? state.creditorFees.isFetching : false}
                                    isSearching={state.creditorFees.items.length > 0 ? state.creditorFees.isFetching : false}
                                    moreSuggestionsAvailable={state.creditorFees.hasMore && !state.creditorFees.isFetching}
                                    suggestions={state.creditorFees.items.length > 0 ? state.creditorFees.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : []}
                                    onGetMoreResults={() => {
                                        if (state.creditorFees.items.length > 0 && !state.creditorFees.isFetching) {
                                            const wrapper = new StringValue();
                                            wrapper.setValue(state.creditorFees.items.at(state.creditorFees.items.length - 1).id);
                                            creditorFeesReq.setNextto(wrapper)
                                            getFilterPromise = dispatch(getCreditorFees({ body: creditorFeesReq, headers: getHeaders() }))
                                        }
                                    }}
                                    onSuggestionClick={(ev?, item?: any, index?: number) => {
                                        var u = state.creditorFees.items.findIndex(e => e.id == item.key)
                                        if (u >= 0) {
                                            dispatch(setSelectedCreditorFee([state.creditorFees.items.at(u)]))
                                        }
                                    }}
                                    isPeoplePicker={false}
                                    selectedItems={state.creditorFees.selected.length > 0 ? state.creditorFees.selected.map(e => { return { name: e.name, key: e.id } as ITag }) : []}
                                    onChange={(e) => {
                                        dispatch(setSelectedCreditorFee([]))

                                    }}
                                    onEmptyInputFocus={() => {
                                        dispatch(resetCreditorFees())
                                        creditorFeesReq.setSearch(undefined)
                                        creditorFeesReq.setNextto(undefined)
                                        dispatch(setIsFilteredCreditorFeesSet(false))
                                        getFilterPromise?.abort()
                                        getFilterPromise = dispatch(getCreditorFees({ body: creditorFeesReq, headers: getHeaders() }))
                                        return []
                                    }}
                                    onFilterChanged={onCreditorFeesFilterChanged}
                                    endButtons={[
                                        {
                                            title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                            onClick: () => {
                                                setCurrenctAction(101)

                                            }
                                        }]}
                                />      </Stack> : undefined}


                            <Label disabled={state.isLoading}>{
                                <div>
                                    {formkikProps.values.operation.key == Operations.TRANSFER || formkikProps.values.operation.key == Operations.EXTERNAL_TRANSFER || formkikProps.values.operation.key == Operations.SETTLEMENT || formkikProps.values.operation.key == Operations.TRADING_ORDER_BUY || formkikProps.values.operation.key == Operations.TRADING_ORDER_SELL ? t("debtorFees") + ' ' : t("fees") + ' '}
                                    <TooltipHost content={t("feesDescription")}>
                                        <Icon iconName="Info" aria-label={formkikProps.values.operation.key == Operations.TRANSFER || formkikProps.values.operation.key == Operations.EXTERNAL_TRANSFER || formkikProps.values.operation.key == Operations.SETTLEMENT || formkikProps.values.operation.key == Operations.TRADING_ORDER_BUY || formkikProps.values.operation.key == Operations.TRADING_ORDER_SELL ? t("debtorFees") : t("fees")} />
                                    </TooltipHost>
                                </div>
                            }</Label>

                            <List
                                inputProps={{ placeholder: formkikProps.values.operation.key == Operations.TRANSFER || formkikProps.values.operation.key == Operations.EXTERNAL_TRANSFER || formkikProps.values.operation.key == Operations.SETTLEMENT || formkikProps.values.operation.key == Operations.TRADING_ORDER_BUY || formkikProps.values.operation.key == Operations.TRADING_ORDER_SELL ? t("debtorFees") : t("fees") }}
                                disabled={state.isLoading}
                                suggestionsHeaderText={t('fees')}
                                isLoading={state.debtorFees.items.length == 0 ? state.debtorFees.isFetching : false}
                                isSearching={state.debtorFees.items.length > 0 ? state.debtorFees.isFetching : false}
                                moreSuggestionsAvailable={state.debtorFees.hasMore && !state.debtorFees.isFetching}
                                suggestions={state.debtorFees.items.length > 0 ? state.debtorFees.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : []}
                                onGetMoreResults={() => {
                                    if (state.debtorFees.items.length > 0 && !state.debtorFees.isFetching) {
                                        const wrapper = new StringValue();
                                        wrapper.setValue(state.debtorFees.items.at(state.debtorFees.items.length - 1).id);
                                        debtorFeesReq.setNextto(wrapper)

                                        getFilterPromise = dispatch(getDebtorFees({ body: debtorFeesReq, headers: getHeaders() }))
                                    }
                                }}
                                onSuggestionClick={(ev?, item?: any, index?: number) => {
                                    var u = state.debtorFees.items.findIndex(e => e.id == item.key)
                                    if (u >= 0) {
                                        dispatch(setSelectedDebtorFee([state.debtorFees.items.at(u)]))
                                    }
                                }}
                                isPeoplePicker={false}
                                selectedItems={state.debtorFees.selected.length > 0 ? state.debtorFees.selected.map(e => { return { name: e.name, key: e.id } as ITag }) : []}
                                onChange={(e) => {
                                    dispatch(setSelectedDebtorFee([]))

                                }}
                                onEmptyInputFocus={() => {
                                    dispatch(resetDebtorFees())
                                    debtorFeesReq.setSearch(undefined)
                                    debtorFeesReq.setNextto(undefined)
                                    dispatch(setIsFilteredDebtorFeesSet(false))
                                    getFilterPromise?.abort()
                                    getFilterPromise = dispatch(getDebtorFees({ body: debtorFeesReq, headers: getHeaders() }))
                                    return []
                                }}
                                onFilterChanged={onDebtorFeesFilterChanged}
                                endButtons={[
                                    {
                                        title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                        onClick: () => {
                                            setCurrenctAction(102)

                                        }
                                    }]}
                            />

                            {props.type == FormType.ADD && formkikProps.values.operation.key != Operations.QUERY_BALANCE 
                                && formkikProps.values.operation.key != Operations.ISSUE_STATEMENT
                                && formkikProps.values.operation.key != Operations.WITHDRAW ? <Stack>
                                <Label disabled={state.isLoading}>{
                                    <div>
                                        {t("directReservation") + ' '}
                                        <TooltipHost content={t("directReservationDesc")}>
                                            <Icon iconName="Info" aria-label={t("openAccountByDefault")} />
                                        </TooltipHost>
                                    </div>
                                }</Label>
                                <Toggle

                                    onText={t("enabled")} offText={t("disabled")}
                                    disabled={state.isLoading}
                                    checked={formkikProps.values.directReservation}
                                    onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                                        formkikProps.setFieldValue('directReservation', checked);
                                    }
                                    }
                                /> </Stack> : undefined}

                            {props.type == FormType.ADD ? <Toggle

                                onText={t("active")} offText={t("inactive")}
                                label={t("status")}
                                disabled={state.isLoading}
                                checked={formkikProps.values.isActive}
                                onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                                    formkikProps.setFieldValue('isActive', checked);
                                }
                                }
                            /> : undefined}




                            <Label disabled={state.isLoading}>{
                                <div>
                                    {t("extraFields") + ' '}
                                    <TooltipHost content={t("extraInformationDescription")}>
                                        <Icon iconName="Info" aria-label={t("extraFields")} />
                                    </TooltipHost>
                                </div>
                            }</Label>
                            <FieldArray
                                name="extraFields"
                                validateOnChange={false}

                            >
                                {arrayHelpers => {
                                    let r = [] as React.ReactNode[];
                                    if (formkikProps.values.extraFields && formkikProps.values.extraFields.length > 0) {
                                        r = formkikProps.values.extraFields.map((o, index) => {
                                            if (o.type == 1) {
                                                return (<Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: props.childrenGap }} key={index}>
                                                    {o.label != undefined ? <Stack.Item grow> <DynamicInputField name={`extraFields[${index}].label`} placeholder={t("extraFieldLabel")} disabled={state.isLoading}
                                                        maxLength={50} autoFocus={(o as any)?.autoFocus} /> </Stack.Item> : null}
                                                    <Stack.Item grow>  <DynamicInputField name={`extraFields[${index}].value`} placeholder={t("extraFieldValue")} disabled={state.isLoading}
                                                        maxLength={50}
                                                    /></Stack.Item>

                                                    <IconButton disabled={state.isLoading}
                                                        iconProps={{ iconName: "remove" }} onClick={() => {
                                                            arrayHelpers.remove(index)
                                                        }} />
                                                </Stack>)
                                            }
                                        }
                                        )
                                    }
                                    if (formkikProps.values.extraFields.length <= 4) {
                                        r.push(< IconButton disabled={state.isLoading}
                                            key={"addButton"} iconProps={{ iconName: "add" }} onClick={() => {
                                                arrayHelpers.push({ key: (formkikProps.values.extraFields.length + 1) + "", label: "", type: 1, value: "", autoFocus: true })
                                            }} />)
                                    }
                                    return r;
                                }
                                }

                            </FieldArray>
                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("add") : t("edit")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={props.type == FormType.ADD ? t("discard") : t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>

                    </Form>
                )}
            </Formik>
        </>
    );

}

