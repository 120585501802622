import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, refreshSession, getSession, ACCOUNTANT_API_URL, APIRequest, APIResponse, TRADING_API_URL } from '../../../../app/Api';
import { AccountTypeEndpointClient } from '../../../../repository/Accountant/Account_typeServiceClientPb';
import { GetAccountTypesResponse, GetAccountTypesRequest } from '../../../../repository/Accountant/account_type_pb';
import { formatDate, timestampToDate } from '../../../../app/Helpers';
import { DynamicField } from '../../../common/DynamicFields/DynamicFields';
import { AccountTypeRuleEndpointClient } from '../../../../repository/Accountant/Account_type_ruleServiceClientPb';
import { FeeEndpointClient } from '../../../../repository/Accountant/FeeServiceClientPb';
import { GetFeesRequest, GetFeesResponse } from '../../../../repository/Accountant/fee_pb';
import { TableState } from '../../../common/Table/TableSate';
import { CurrencyEndpointClient } from '../../../../repository/Accountant/CurrencyServiceClientPb';
import { GetCurrenciesRequest, GetCurrenciesResponse } from '../../../../repository/Accountant/currency_pb';
import { AddCurrencyPairRequest, AddCurrencyPairResponse, UpdateCurrencyPairPriceRequest, UpdateCurrencyPairPriceResponse, UpdateCurrencyPairResponse, GetCurrencyPairResponse, GetCurrencyPairRequest, UpdateCurrencyPairRequest, GetCurrencyPairsResponse, GetCurrencyPairsRequest, SetPriceTriggerResponse, SetPriceTriggerRequest } from '../../../../repository/Trading/currency_pair_pb';
import { CurrencyPairEndpointClient } from '../../../../repository/Trading/Currency_pairServiceClientPb';
import { CategoryEndpointClient } from '../../../../repository/Trading/CategoryServiceClientPb';
import { GetCategoriesRequest, GetCategoriesResponse } from '../../../../repository/Trading/category_pb';


export interface CurrencyPairsFormState {
    isLoading: boolean
    message?: ApiMessage
    stage1: any,
    baseCurrencies: TableState
    quoteCurrencies: TableState
    categories: TableState,
    mainPairs: TableState,

}

const initialState: CurrencyPairsFormState = {
    isLoading: false,
    message: undefined,
    stage1: undefined,
    baseCurrencies: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    quoteCurrencies: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    categories: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    mainPairs: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },

}

const client = new CurrencyPairEndpointClient(TRADING_API_URL, null, null);
const clientCurrency = new CurrencyEndpointClient(ACCOUNTANT_API_URL, null, null);
const clientCategory = new CategoryEndpointClient(TRADING_API_URL, null, null);

export const add = createAsyncThunk<AddCurrencyPairResponse.AsObject, APIRequest<AddCurrencyPairRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/trading/currencyPair/add',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.add(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)


export const update = createAsyncThunk<UpdateCurrencyPairResponse.AsObject, APIRequest<UpdateCurrencyPairRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/trading/currencyPair/update',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.update(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)



export const setPriceTriggerSettings = createAsyncThunk<SetPriceTriggerResponse.AsObject, APIRequest<SetPriceTriggerRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/trading/currencyPair/setPriceTriggerSettings',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.setPriceTrigger(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)

export const updatePrices = createAsyncThunk<UpdateCurrencyPairPriceResponse.AsObject, APIRequest<UpdateCurrencyPairPriceRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/trading/currencyPair/updatePrices',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.updatePrice(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)



export const get = createAsyncThunk<GetCurrencyPairResponse.AsObject, APIRequest<GetCurrencyPairRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/trading/currencyPair/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getCurrencyPair(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)


export const getBaseCurrencies = createAsyncThunk<GetCurrenciesResponse.AsObject, APIRequest<GetCurrenciesRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/trading/currencyPairs/baseCurrencies/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clientCurrency.getCurrencies(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getQuoteCurrencies = createAsyncThunk<GetCurrenciesResponse.AsObject, APIRequest<GetCurrenciesRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/trading/currencyPairs/quoteCurrencies/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clientCurrency.getCurrencies(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getMainPairsItems = createAsyncThunk<APIResponse<GetCurrencyPairsResponse.AsObject>, APIRequest<GetCurrencyPairsRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/trading/currencyPairs/fetchMainPairs',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await client.getCurrencyPairs(req.body, req.headers ?? {});
                return { response: r.toObject(), metadata: req.body?.getSearch()?.getValue() } as APIResponse<GetCurrencyPairsResponse.AsObject>;
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getCategories = createAsyncThunk<GetCategoriesResponse.AsObject, APIRequest<GetCategoriesRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/trading/currencyPairs/getCategories',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clientCategory.getCategories(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const currencyPairsFormSlice = createSlice({
    name: 'forms/trading/currencyPairs',
    initialState,
    reducers: {

        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.isLoading = false;
            state.message = undefined;
            state.stage1 = initialState.stage1;
            state.baseCurrencies = initialState.baseCurrencies;
            state.quoteCurrencies = initialState.quoteCurrencies;
            state.categories = initialState.categories;
            state.mainPairs = initialState.mainPairs;

        },
        setStage1: (state, action: PayloadAction<any>) => {
            let stage1 = {
                id: action.payload.id,
                name: action.payload.name,
                buyPrice: action.payload.buyPrice,
                sellPrice: action.payload.sellPrice,
                tradingPrice: action.payload.tradingPrice,
                isActive: action.payload.isActive,
                baseCurrencyId: action.payload.baseCurrencyId,
                baseCurrencyName: action.payload.baseCurrencyName,
                baseCurrencyCode: action.payload.baseCurrencyCode,
                baseCurrencyDecimalPlaces: action.payload.baseCurrencyDecimalPlaces,
                baseCurrencyForm: action.payload.baseCurrencyForm,
                baseCurrencySymbol: action.payload.baseCurrencySymbol,
                quoteCurrencyId: action.payload.quoteCurrencyId,
                quoteCurrencyName: action.payload.quoteCurrencyName,
                quoteCurrencyCode: action.payload.quoteCurrencyCode,
                quoteCurrencyDecimalPlaces: action.payload.quoteCurrencyDecimalPlaces,
                quoteCurrencyForm: action.payload.quoteCurrencyForm,
                quoteCurrencySymbol: action.payload.quoteCurrencySymbol,
                priceUpdate: action.payload.priceUpdate,
                createdDate: action.payload.createdDate,
                addedBy: action.payload.addedBy,
                addedById: action.payload.addedById,

                iconSource: action.payload.iconSource,
                categoryId: action.payload.categoryId,
                categoryNameAr: action.payload.categoryNameAr,
                categoryNameEn: action.payload.categoryNameEn,
                buyPriceEquation: action.payload.buyPriceEquation,
                sellPriceEquation: action.payload.sellPriceEquation,
                tradingPriceEquation: action.payload.tradingPriceEquation,
                isPriceTriggerActive: action.payload.isPriceTriggerActive,
                mainCurrencyPair: action.payload.mainCurrencyPair,
                mainCurrencyPairName: action.payload.mainCurrencyPairName,
                mainCurrencyPairBaseCurrencyId: action.payload.mainCurrencyPairBaseCurrencyId,
                mainCurrencyPairBaseCurrencyName: action.payload.mainCurrencyPairBaseCurrencyName,
                mainCurrencyPairBaseCurrencyCode: action.payload.mainCurrencyPairBaseCurrencyCode,
                mainCurrencyPairBaseCurrencyDecimalPlaces: action.payload.mainCurrencyPairBaseCurrencyDecimalPlaces,
                mainCurrencyPairBaseCurrencyForm: action.payload.mainCurrencyPairBaseCurrencyForm,
                mainCurrencyPairBaseCurrencySymbol: action.payload.mainCurrencyPairBaseCurrencySymbol,
                mainCurrencyPairQuoteCurrencyId: action.payload.mainCurrencyPairQuoteCurrencyId,
                mainCurrencyPairQuoteCurrencyName: action.payload.mainCurrencyPairQuoteCurrencyName,
                mainCurrencyPairQuoteCurrencyCode: action.payload.mainCurrencyPairQuoteCurrencyCode,
                mainCurrencyPairQuoteCurrencyDecimalPlaces: action.payload.mainCurrencyPairQuoteCurrencyDecimalPlaces,
                mainCurrencyPairQuoteCurrencyForm: action.payload.mainCurrencyPairQuoteCurrencyForm,
                mainCurrencyPairQuoteCurrencySymbol: action.payload.mainCurrencyPairQuoteCurrencySymbol,

            }
            state.stage1 = stage1;
        },
        resetBaseCurrencies: (state) => {
            state.baseCurrencies.items = [];
            state.baseCurrencies.hasMore = true;
        },
        resetQuoteCurrencies: (state) => {
            state.quoteCurrencies.items = [];
            state.quoteCurrencies.hasMore = true;
        },

        resetMainPair: (state) => {
            state.mainPairs.items = [];
            state.mainPairs.hasMore = true;
        },

        resetCategories: (state) => {
            state.categories.items = [];
            state.categories.hasMore = true;
        },
        setSelectedBaseCurrencyItems: (state, action: PayloadAction<any[]>) => {
            state.baseCurrencies.selected = action.payload;
        },
        setSelectedQuoteCurrencyItems: (state, action: PayloadAction<any[]>) => {
            state.quoteCurrencies.selected = action.payload;
        },

        setSelectedCategoryItems: (state, action: PayloadAction<any[]>) => {
            state.categories.selected = action.payload;
        },

        setSelecteMainPairItems: (state, action: PayloadAction<any[]>) => {
            state.mainPairs.selected = action.payload;

        },
        setIsFilteredBaseCurrenciesSet: (state, action: PayloadAction<boolean>) => {
            state.baseCurrencies.isFilteredSet = action.payload;
        },

        setIsFilteredQuoteCurrenciesSet: (state, action: PayloadAction<boolean>) => {
            state.quoteCurrencies.isFilteredSet = action.payload;
        },
        setIsFilteredCategoriesSet: (state, action: PayloadAction<boolean>) => {
            state.categories.isFilteredSet = action.payload;
        },

        setIsFilteredMainPairsSet: (state, action: PayloadAction<boolean>) => {
            state.mainPairs.isFilteredSet = action.payload;
        },

    },
    extraReducers: (builder) => {
        builder.addCase(add.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            if (payload)
                state.message = toApiMessage(payload.success?.message);

        })
        builder.addCase(add.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(add.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })
        builder.addCase(updatePrices.fulfilled, (state, { payload }) => {
            if (payload)
                state.message = toApiMessage(payload.success?.message);
            state.isLoading = false;
        })
        builder.addCase(updatePrices.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updatePrices.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })


        builder.addCase(update.fulfilled, (state, { payload }) => {
            if (payload)
                state.message = toApiMessage(payload.success?.message);
            state.isLoading = false;
        })
        builder.addCase(update.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(update.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })

        builder.addCase(setPriceTriggerSettings.fulfilled, (state, { payload }) => {
            if (payload)
                state.message = toApiMessage(payload.success?.message);
            state.isLoading = false;
        })
        builder.addCase(setPriceTriggerSettings.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(setPriceTriggerSettings.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })



        builder.addCase(get.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {

                let r = {
                    id: payload.success?.id,
                    name: payload.success?.name?.value ?? "",
                    buyPrice: payload.success?.buyprice?.value,
                    sellPrice: payload.success?.sellprice?.value,
                    tradingPrice: payload.success?.tradingprice?.value,
                    isActive: payload.success?.isactive?.value,
                    baseCurrencyId: payload.success?.base?.id?.value ?? "",
                    baseCurrencyName: payload.success?.base?.name?.value ?? "",
                    baseCurrencyCode: payload.success?.base?.code?.value ?? "",
                    baseCurrencyDecimalPlaces: payload.success?.base?.decimalplaces?.value ?? undefined,
                    baseCurrencyForm: payload.success?.base?.form?.value ?? undefined,
                    baseCurrencySymbol: payload.success?.base?.symbol?.value ?? "",
                    quoteCurrencyId: payload.success?.quote?.id?.value ?? "",
                    quoteCurrencyName: payload.success?.quote?.name?.value ?? "",
                    quoteCurrencyCode: payload.success?.quote?.code?.value ?? "",
                    quoteCurrencyDecimalPlaces: payload.success?.quote?.decimalplaces?.value ?? undefined,
                    quoteCurrencyForm: payload.success?.quote?.form?.value ?? undefined,
                    quoteCurrencySymbol: payload.success?.quote?.symbol?.value ?? "",
                    priceUpdate: formatDate(timestampToDate(payload.success?.priceupdate?.seconds, payload.success?.priceupdate?.nanos)),
                    createdDate: formatDate(timestampToDate(payload.success?.createddate?.seconds, payload.success?.createddate?.nanos)),
                    addedBy: (payload.success?.createdby?.firstname?.value ?? "") + " " + (payload.success?.createdby?.lastname?.value ?? ""),
                    addedById: payload.success?.createdby?.id?.value,


                    iconSource: payload.success?.iconsource?.value ?? "",
                    categoryId: payload.success?.category?.id?.value ?? "",
                    categoryNameAr: payload.success?.category?.namear?.value ?? "",
                    categoryNameEn: payload.success?.category?.nameen?.value ?? "",
                    buyPriceEquation: payload.success?.pricetrigger?.buypriceequation?.value ?? "",
                    sellPriceEquation: payload.success?.pricetrigger?.sellpriceequation?.value ?? "",
                    tradingPriceEquation: payload.success?.pricetrigger?.tradingpriceequation?.value ?? "",
                    isPriceTriggerActive: payload.success?.pricetrigger?.isactive?.value,
                    mainCurrencyPair: payload.success?.pricetrigger?.main?.id?.value ?? "",
                    mainCurrencyPairName: payload.success?.pricetrigger?.main?.name?.value ?? "",
                    mainCurrencyPairBaseCurrencyId: payload.success?.pricetrigger?.main?.base?.id?.value ?? "",
                    mainCurrencyPairBaseCurrencyName: payload.success?.pricetrigger?.main?.base?.name?.value ?? "",
                    mainCurrencyPairBaseCurrencyCode: payload.success?.pricetrigger?.main?.base?.code?.value ?? "",
                    mainCurrencyPairBaseCurrencyDecimalPlaces: payload.success?.pricetrigger?.main?.base?.decimalplaces?.value ?? undefined,
                    mainCurrencyPairBaseCurrencyForm: payload.success?.pricetrigger?.main?.base?.form?.value ?? undefined,
                    mainCurrencyPairBaseCurrencySymbol: payload.success?.pricetrigger?.main?.base?.symbol?.value ?? "",
                    mainCurrencyPairQuoteCurrencyId: payload.success?.pricetrigger?.main?.quote?.id?.value ?? "",
                    mainCurrencyPairQuoteCurrencyName: payload.success?.pricetrigger?.main?.quote?.name?.value ?? "",
                    mainCurrencyPairQuoteCurrencyCode: payload.success?.pricetrigger?.main?.quote?.code?.value ?? "",
                    mainCurrencyPairQuoteCurrencyDecimalPlaces: payload.success?.pricetrigger?.main?.quote?.decimalplaces?.value ?? undefined,
                    mainCurrencyPairQuoteCurrencyForm: payload.success?.pricetrigger?.main?.quote?.form?.value ?? undefined,
                    mainCurrencyPairQuoteCurrencySymbol: payload.success?.pricetrigger?.main?.quote?.symbol?.value ?? "",

                }



                state.stage1 = r
            }
        })
        builder.addCase(get.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(get.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })

        builder.addCase(getBaseCurrencies.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.currenciesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        code: val.code?.value ?? "",
                        icon: val.iconsource?.value ?? "",
                        form: val.form?.formid,
                        symbol: val.symbol?.value ?? "",
                        decimalPlaces: val.decimalplaces?.value,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.baseCurrencies.numberOfResults) {
                    state.baseCurrencies.hasMore = false;
                }
                state.baseCurrencies.items = state.baseCurrencies.items.concat(r);
                //console.log(state.items)

            } else {
                state.baseCurrencies.hasMore = false;
            }
            state.baseCurrencies.isFetching = false

        })
        builder.addCase(getBaseCurrencies.rejected, (state, action) => {
            state.baseCurrencies.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getBaseCurrencies.pending, (state, action) => {
            state.baseCurrencies.isFetching = true;
            //state.message = undefined;
        })

        builder.addCase(getQuoteCurrencies.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.currenciesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        code: val.code?.value ?? "",
                        icon: val.iconsource?.value ?? "",
                        form: val.form?.formid,
                        symbol: val.symbol?.value ?? "",
                        decimalPlaces: val.decimalplaces?.value,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.quoteCurrencies.numberOfResults) {
                    state.quoteCurrencies.hasMore = false;
                }
                state.quoteCurrencies.items = state.quoteCurrencies.items.concat(r);
                //console.log(state.items)

            } else {
                state.quoteCurrencies.hasMore = false;
            }
            state.quoteCurrencies.isFetching = false

        })
        builder.addCase(getQuoteCurrencies.rejected, (state, action) => {
            state.quoteCurrencies.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getQuoteCurrencies.pending, (state, action) => {
            state.quoteCurrencies.isFetching = true;
            //state.message = undefined;
        })


        builder.addCase(getCategories.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.categoriesList.map(val => {
                    return {
                        id: val.id,
                        nameAr: val.namear?.value ?? "",
                        nameEn: val.nameen?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        rank: val.rank?.value,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                        parentCategoryId: val?.parent?.id?.value,
                        parentCategoryNameAr: val?.parent?.namear?.value ?? "",
                        parentCategoryNameEn: val?.parent?.nameen?.value ?? "",
                        iconSource: val?.iconsource?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.categories.numberOfResults) {
                    state.categories.hasMore = false;
                }
                state.categories.items = state.categories.items.concat(r);
                //console.log(state.items)

            } else {
                state.categories.hasMore = false;
            }
            state.categories.isFetching = false

        })
        builder.addCase(getCategories.rejected, (state, action) => {
            state.categories.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getCategories.pending, (state, action) => {
            state.categories.isFetching = true;
            //state.message = undefined;
        })


        builder.addCase(getMainPairsItems.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.response?.success?.currencypairsList.map(val => {
                    let rc = {
                        id: val.id,
                        name: val.name?.value,
                        buyPrice: val.buyprice?.value,
                        sellPrice: val.sellprice?.value,
                        tradingPrice: val.tradingprice?.value,

                        isActive: val.isactive?.value,
                        syncPrices: val.syncprices?.value,
                        iconSource: val.iconsource?.value ?? "",
                        categoryId: val.category?.id?.value ?? "",
                        categoryNameAr: val.category?.namear?.value ?? "",
                        categoryNameEn: val.category?.nameen?.value ?? "",
                        buyPriceEquation: val.pricetrigger?.buypriceequation?.value ?? "",
                        sellPriceEquation: val.pricetrigger?.sellpriceequation?.value ?? "",
                        tradingPriceEquation: val.pricetrigger?.tradingpriceequation?.value ?? "",
                        isPriceTriggerActive: val.pricetrigger?.isactive?.value,
                        mainCurrencyPair: val.pricetrigger?.main?.id?.value ?? "",
                        mainCurrencyPairName: val.pricetrigger?.main?.name?.value ?? "",
                        mainCurrencyPairBaseCurrencyId: val.pricetrigger?.main?.base?.id?.value ?? "",
                        mainCurrencyPairBaseCurrencyName: val.pricetrigger?.main?.base?.name?.value ?? "",
                        mainCurrencyPairBaseCurrencyCode: val.pricetrigger?.main?.base?.code?.value ?? "",
                        mainCurrencyPairBaseCurrencyDecimalPlaces: val.pricetrigger?.main?.base?.decimalplaces?.value ?? undefined,
                        mainCurrencyPairBaseCurrencyForm: val.pricetrigger?.main?.base?.form?.value ?? undefined,
                        mainCurrencyPairBaseCurrencySymbol: val.pricetrigger?.main?.base?.symbol?.value ?? "",
                        mainCurrencyPairQuoteCurrencyId: val.pricetrigger?.main?.quote?.id?.value ?? "",
                        mainCurrencyPairQuoteCurrencyName: val.pricetrigger?.main?.quote?.name?.value ?? "",
                        mainCurrencyPairQuoteCurrencyCode: val.pricetrigger?.main?.quote?.code?.value ?? "",
                        mainCurrencyPairQuoteCurrencyDecimalPlaces: val.pricetrigger?.main?.quote?.decimalplaces?.value ?? undefined,
                        mainCurrencyPairQuoteCurrencyForm: val.pricetrigger?.main?.quote?.form?.value ?? undefined,
                        mainCurrencyPairQuoteCurrencySymbol: val.pricetrigger?.main?.quote?.symbol?.value ?? "",

                        baseCurrencyId: val.base?.id?.value ?? "",
                        baseCurrencyName: val.base?.name?.value ?? "",
                        baseCurrencyCode: val.base?.code?.value ?? "",
                        baseCurrencyDecimalPlaces: val.base?.decimalplaces?.value ?? undefined,
                        baseCurrencyForm: val.base?.form?.value ?? undefined,
                        baseCurrencySymbol: val.base?.symbol?.value ?? "",
                        quoteCurrencyId: val.quote?.id?.value ?? "",
                        quoteCurrencyName: val.quote?.name?.value ?? "",
                        quoteCurrencyCode: val.quote?.code?.value ?? "",
                        quoteCurrencyDecimalPlaces: val.quote?.decimalplaces?.value ?? undefined,
                        quoteCurrencyForm: val.quote?.form?.value ?? undefined,
                        quoteCurrencySymbol: val.quote?.symbol?.value ?? "",
                        priceUpdate: formatDate(timestampToDate(val.priceupdate?.seconds, val.priceupdate?.nanos)),
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                    if (rc.name == undefined) {
                        rc.name = (rc?.baseCurrencyName == "" ? rc?.baseCurrencyId : rc?.baseCurrencyName + " (" + rc?.baseCurrencyCode + ")") + " / " + (rc?.quoteCurrencyName == "" ? rc?.quoteCurrencyId : rc?.quoteCurrencyName + " (" + rc?.quoteCurrencyCode + ")")
                    }
                    //         rc.name = (rc?.baseCurrencyCode == "" ? rc?.baseCurrencyId : rc?.baseCurrencyCode) + "/" + (rc?.quoteCurrencyCode == "" ? rc?.quoteCurrencyId : rc?.quoteCurrencyCode);

                    return rc;
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.mainPairs.numberOfResults) {
                    state.mainPairs.hasMore = false;
                }
                if (r.length == 0) {
                    if (payload?.metadata != undefined && payload?.metadata != "") {
                        if (payload?.metadata?.length == 24) {
                            r = [{
                                id: payload?.metadata,
                                name: payload?.metadata,
                            }]
                        }
                    }
                }
                state.mainPairs.items = state.mainPairs.items.concat(r);
                //console.log(state.items)

            } else {
                state.mainPairs.hasMore = false;
            }
            state.mainPairs.isFetching = false

        })
        builder.addCase(getMainPairsItems.rejected, (state, action) => {
            state.mainPairs.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getMainPairsItems.pending, (state, action) => {
            state.mainPairs.isFetching = true;
            //state.message = undefined;
        })



    }
})

export const { reset, dismissMessage, setStage1, resetCategories, resetMainPair, setIsFilteredCategoriesSet, setIsFilteredMainPairsSet, setSelecteMainPairItems, setSelectedCategoryItems, resetBaseCurrencies, resetQuoteCurrencies, setIsFilteredBaseCurrenciesSet, setIsFilteredQuoteCurrenciesSet, setSelectedBaseCurrencyItems, setSelectedQuoteCurrencyItems } = currencyPairsFormSlice.actions

export default currencyPairsFormSlice.reducer


