/**
 * @fileoverview gRPC-Web generated client stub for treasury_operation_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as treasury_operation_pb from './treasury_operation_pb';


export class TreasuryOperationEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetBalance = new grpcWeb.MethodDescriptor(
    '/treasury_operation_endpoint.TreasuryOperationEndpoint/GetBalance',
    grpcWeb.MethodType.UNARY,
    treasury_operation_pb.GetBalanceRequest,
    treasury_operation_pb.GetBalanceResponse,
    (request: treasury_operation_pb.GetBalanceRequest) => {
      return request.serializeBinary();
    },
    treasury_operation_pb.GetBalanceResponse.deserializeBinary
  );

  getBalance(
    request: treasury_operation_pb.GetBalanceRequest,
    metadata: grpcWeb.Metadata | null): Promise<treasury_operation_pb.GetBalanceResponse>;

  getBalance(
    request: treasury_operation_pb.GetBalanceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: treasury_operation_pb.GetBalanceResponse) => void): grpcWeb.ClientReadableStream<treasury_operation_pb.GetBalanceResponse>;

  getBalance(
    request: treasury_operation_pb.GetBalanceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: treasury_operation_pb.GetBalanceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/treasury_operation_endpoint.TreasuryOperationEndpoint/GetBalance',
        request,
        metadata || {},
        this.methodDescriptorGetBalance,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/treasury_operation_endpoint.TreasuryOperationEndpoint/GetBalance',
    request,
    metadata || {},
    this.methodDescriptorGetBalance);
  }

  methodDescriptorGetStatement = new grpcWeb.MethodDescriptor(
    '/treasury_operation_endpoint.TreasuryOperationEndpoint/GetStatement',
    grpcWeb.MethodType.UNARY,
    treasury_operation_pb.GetStatementRequest,
    treasury_operation_pb.GetStatementResponse,
    (request: treasury_operation_pb.GetStatementRequest) => {
      return request.serializeBinary();
    },
    treasury_operation_pb.GetStatementResponse.deserializeBinary
  );

  getStatement(
    request: treasury_operation_pb.GetStatementRequest,
    metadata: grpcWeb.Metadata | null): Promise<treasury_operation_pb.GetStatementResponse>;

  getStatement(
    request: treasury_operation_pb.GetStatementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: treasury_operation_pb.GetStatementResponse) => void): grpcWeb.ClientReadableStream<treasury_operation_pb.GetStatementResponse>;

  getStatement(
    request: treasury_operation_pb.GetStatementRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: treasury_operation_pb.GetStatementResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/treasury_operation_endpoint.TreasuryOperationEndpoint/GetStatement',
        request,
        metadata || {},
        this.methodDescriptorGetStatement,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/treasury_operation_endpoint.TreasuryOperationEndpoint/GetStatement',
    request,
    metadata || {},
    this.methodDescriptorGetStatement);
  }

  methodDescriptorGetTransaction = new grpcWeb.MethodDescriptor(
    '/treasury_operation_endpoint.TreasuryOperationEndpoint/GetTransaction',
    grpcWeb.MethodType.UNARY,
    treasury_operation_pb.GetTransactionRequest,
    treasury_operation_pb.GetTransactionResponse,
    (request: treasury_operation_pb.GetTransactionRequest) => {
      return request.serializeBinary();
    },
    treasury_operation_pb.GetTransactionResponse.deserializeBinary
  );

  getTransaction(
    request: treasury_operation_pb.GetTransactionRequest,
    metadata: grpcWeb.Metadata | null): Promise<treasury_operation_pb.GetTransactionResponse>;

  getTransaction(
    request: treasury_operation_pb.GetTransactionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: treasury_operation_pb.GetTransactionResponse) => void): grpcWeb.ClientReadableStream<treasury_operation_pb.GetTransactionResponse>;

  getTransaction(
    request: treasury_operation_pb.GetTransactionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: treasury_operation_pb.GetTransactionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/treasury_operation_endpoint.TreasuryOperationEndpoint/GetTransaction',
        request,
        metadata || {},
        this.methodDescriptorGetTransaction,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/treasury_operation_endpoint.TreasuryOperationEndpoint/GetTransaction',
    request,
    metadata || {},
    this.methodDescriptorGetTransaction);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/treasury_operation_endpoint.TreasuryOperationEndpoint/Update',
    grpcWeb.MethodType.UNARY,
    treasury_operation_pb.UpdateTransactionRequest,
    treasury_operation_pb.UpdateTransactionResponse,
    (request: treasury_operation_pb.UpdateTransactionRequest) => {
      return request.serializeBinary();
    },
    treasury_operation_pb.UpdateTransactionResponse.deserializeBinary
  );

  update(
    request: treasury_operation_pb.UpdateTransactionRequest,
    metadata: grpcWeb.Metadata | null): Promise<treasury_operation_pb.UpdateTransactionResponse>;

  update(
    request: treasury_operation_pb.UpdateTransactionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: treasury_operation_pb.UpdateTransactionResponse) => void): grpcWeb.ClientReadableStream<treasury_operation_pb.UpdateTransactionResponse>;

  update(
    request: treasury_operation_pb.UpdateTransactionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: treasury_operation_pb.UpdateTransactionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/treasury_operation_endpoint.TreasuryOperationEndpoint/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/treasury_operation_endpoint.TreasuryOperationEndpoint/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorExportStatement = new grpcWeb.MethodDescriptor(
    '/treasury_operation_endpoint.TreasuryOperationEndpoint/ExportStatement',
    grpcWeb.MethodType.UNARY,
    treasury_operation_pb.ExportStatementRequest,
    treasury_operation_pb.ExportStatementResponse,
    (request: treasury_operation_pb.ExportStatementRequest) => {
      return request.serializeBinary();
    },
    treasury_operation_pb.ExportStatementResponse.deserializeBinary
  );

  exportStatement(
    request: treasury_operation_pb.ExportStatementRequest,
    metadata: grpcWeb.Metadata | null): Promise<treasury_operation_pb.ExportStatementResponse>;

  exportStatement(
    request: treasury_operation_pb.ExportStatementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: treasury_operation_pb.ExportStatementResponse) => void): grpcWeb.ClientReadableStream<treasury_operation_pb.ExportStatementResponse>;

  exportStatement(
    request: treasury_operation_pb.ExportStatementRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: treasury_operation_pb.ExportStatementResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/treasury_operation_endpoint.TreasuryOperationEndpoint/ExportStatement',
        request,
        metadata || {},
        this.methodDescriptorExportStatement,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/treasury_operation_endpoint.TreasuryOperationEndpoint/ExportStatement',
    request,
    metadata || {},
    this.methodDescriptorExportStatement);
  }

  methodDescriptorExportTransaction = new grpcWeb.MethodDescriptor(
    '/treasury_operation_endpoint.TreasuryOperationEndpoint/ExportTransaction',
    grpcWeb.MethodType.UNARY,
    treasury_operation_pb.ExportTransactionRequest,
    treasury_operation_pb.ExportTransactionResponse,
    (request: treasury_operation_pb.ExportTransactionRequest) => {
      return request.serializeBinary();
    },
    treasury_operation_pb.ExportTransactionResponse.deserializeBinary
  );

  exportTransaction(
    request: treasury_operation_pb.ExportTransactionRequest,
    metadata: grpcWeb.Metadata | null): Promise<treasury_operation_pb.ExportTransactionResponse>;

  exportTransaction(
    request: treasury_operation_pb.ExportTransactionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: treasury_operation_pb.ExportTransactionResponse) => void): grpcWeb.ClientReadableStream<treasury_operation_pb.ExportTransactionResponse>;

  exportTransaction(
    request: treasury_operation_pb.ExportTransactionRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: treasury_operation_pb.ExportTransactionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/treasury_operation_endpoint.TreasuryOperationEndpoint/ExportTransaction',
        request,
        metadata || {},
        this.methodDescriptorExportTransaction,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/treasury_operation_endpoint.TreasuryOperationEndpoint/ExportTransaction',
    request,
    metadata || {},
    this.methodDescriptorExportTransaction);
  }

}

