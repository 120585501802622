import * as Yup from 'yup';
import i18next from './i18n';

export const inputs = {

    password: Yup.string()

        .max(40, i18next.t("passwordValidation"))
        .min(6, i18next.t("passwordValidation"))
        .required(i18next.t("required")),

    roleName: Yup.string()
        .max(50, i18next.t("roleNameValidation"))
        .min(1, i18next.t("roleNameValidation"))
        .required(i18next.t("required")),
    userTypeName: Yup.string()
        .max(50, i18next.t("userTypeNameValidation"))
        .min(1, i18next.t("userTypeNameValidation"))
        .required(i18next.t("required")),
    groupName: Yup.string()
        .max(50, i18next.t("groupNameValidation"))
        .min(1, i18next.t("groupNameValidation"))
        .required(i18next.t("required")),
    groupDescription: Yup.string()
        .max(140, i18next.t("groupDescriptionValidation")),
    organizationName: Yup.string()
        .min(1, i18next.t("organizationNameValidation"))
        .max(150, i18next.t("organizationNameValidation"))
        .required(i18next.t("required")),
    organizationDomain: Yup.string().email(i18next.t("organizationDomainEmailValidation"))
        .min(1, i18next.t("organizationDomainValidation"))
        .max(250, i18next.t("organizationDomainValidation"))
        .required(i18next.t("required")),
    organizationAddress: Yup.string()
        .max(500, i18next.t("organizationAddressValidation")),


    firstname: Yup.string()
        .max(50, i18next.t("firstnameValidation"))
        .min(1, i18next.t("firstnameValidation"))
        .required(i18next.t("required")),
    lastname: Yup.string()
        .max(50, i18next.t("lastnameValidation"))
        .min(1, i18next.t("lastnameValidation"))
        .required(i18next.t("required")),

    username: Yup.string().email(i18next.t("usernameEmailValidation"))
        .max(320, i18next.t("usernameValidation"))
        .min(3, i18next.t("usernameValidation"))
        .required(i18next.t("required")),

    code: Yup.number()
        .max(999999, i18next.t("codeValidation"))
        .min(0, i18next.t("codeValidation"))
        .required(i18next.t("required"))
        .typeError(i18next.t("numberOnly")),


    phoneNumbers: Yup.array().of(
        Yup.object().shape({
            value: Yup.string().matches(/^(\+|00|0)[1-9][0-9 \-\(\)\.]{7,32}$/, i18next.t("phoneNumberValidation")).required(i18next.t("required")), // these constraints take precedence
        })
    ),

    emailAddresses: Yup.array().of(
        Yup.object().shape({
            value: Yup.string().email(i18next.t("emailValidation")).required('Required'), // these constraints take precedence
        })
    ),

    newPassword: Yup.string()
        .max(40, i18next.t("passwordValidation"))
        .min(6, i18next.t("passwordValidation"))
        .required(i18next.t("required")),
    rePassword: Yup.string()
        .oneOf([Yup.ref('newPassword')], i18next.t("rePasswordValidation"))
        .required(i18next.t("required")),


    primaryPhoneNumber: Yup.string().matches(/^(\+|00|0)[1-9][0-9 \-\(\)\.]{7,32}$/, i18next.t("phoneNumberValidation")),

    primaryEmail: Yup.string().email(i18next.t("emailValidation")),

    extraFields: Yup.array().of(
        Yup.object().shape({
            value: Yup.string()
                .max(50, i18next.t("extraFieldsValidation"))
                .min(1, i18next.t("extraFieldsValidation"))
                .required(i18next.t("required")),
            label: Yup.string()
                .max(50, i18next.t("extraFieldsValidation"))
                .min(1, i18next.t("extraFieldsValidation"))
                .required(i18next.t("required")),
        })
    ),

    enums: Yup.array().of(
        Yup.object().shape({
            value: Yup.string()
                .max(500, i18next.t("enumsValidation"))
                .min(1, i18next.t("enumsValidation"))
                .required(i18next.t("required")), // these constraints take precedence
        })
    ),
    blocked: Yup.array().of(
        Yup.object().shape({
            value: Yup.string()
                .max(500, i18next.t("blockedValidation"))
                .min(1, i18next.t("blockedValidation"))
                .required(i18next.t("required")), // these constraints take precedence
        })
    ),

    range: Yup.array().of(
        Yup.object().shape({
            label: Yup.string()
                .max(200, i18next.t("rangeValidation"))
                .min(1, i18next.t("rangeValidation"))
                .required(i18next.t("required")),
            value: Yup.string()
                .max(200, i18next.t("rangeValidation"))
                .min(1, i18next.t("rangeValidation"))
                .required(i18next.t("required")),
        })
    ),

    regex: Yup.string()
        .max(1000, i18next.t("regexValidation"))
        .min(1, i18next.t("regexValidation")),

    accountTypeName: Yup.string()
        .max(50, i18next.t("accountTypeNameValidation"))
        .min(1, i18next.t("accountTypeNameValidation"))
        .required(i18next.t("required")),

    treasuryTypeName: Yup.string()
        .max(50, i18next.t("treasuryTypeNameValidation"))
        .min(1, i18next.t("treasuryTypeNameValidation"))
        .required(i18next.t("required")),

    currencyName: Yup.string()
        .max(50, i18next.t("currencyNameValidation"))
        .min(1, i18next.t("currencyNameValidation"))
        .required(i18next.t("required")),

    currencyCode: Yup.string()
        .max(4, i18next.t("currencyCodeValidation"))
        .min(1, i18next.t("currencyCodeValidation"))
        .required(i18next.t("required")),

    currencySymbol: Yup.string()
        .max(4, i18next.t("currencySymbolValidation"))
        .min(1, i18next.t("currencySymbolValidation"))
        .required(i18next.t("required")),

    currencyIcon: Yup.string()
        .max(2048, i18next.t("currencyIconValidation"))
        .min(3, i18next.t("currencyIconValidation")),

    currencyDecimalPlaces: Yup.number()
        .max(14, i18next.t("currencyDecimalPlacesValidation"))
        .min(0, i18next.t("currencyDecimalPlacesValidation"))
        .typeError(i18next.t("numberOnly"))
        .required(i18next.t("required")),

    feeName: Yup.string()
        .max(50, i18next.t("feeNameValidation"))
        .min(1, i18next.t("feeNameValidation"))
        .required(i18next.t("required")),

    feeEntries: Yup.array().of(
        Yup.object().shape({
            activationThreshold: Yup.number()
                .max(999999999999999, i18next.t("activationThresholdValidation"))
                .min(0, i18next.t("activationThresholdValidation"))
                .required(i18next.t("required"))
                .typeError(i18next.t("numberOnly")),
            fixedValue: Yup.number()
                .max(999999999999999, i18next.t("fixedValueValidation"))
                .min(0, i18next.t("fixedValueValidation"))
                .required(i18next.t("required"))
                .typeError(i18next.t("numberOnly")),
            percentageValue: Yup.number()
                .max(100, i18next.t("percentageValueValidation"))
                .min(0, i18next.t("percentageValueValidation"))
                .required(i18next.t("required"))
                .typeError(i18next.t("numberOnly")),
        })
    ),

    limitationName: Yup.string()
        .max(50, i18next.t("limitationNameValidation"))
        .min(1, i18next.t("limitationNameValidation"))
        .required(i18next.t("required")),

    balanceMaxLimit: Yup.number()
        .max(999999999999999, i18next.t("balanceMaxLimitValidation"))
        .min(-999999999999999, i18next.t("balanceMaxLimitValidation"))
        .required(i18next.t("required"))
        .typeError(i18next.t("numberOnly")),

    balanceMinLimit: Yup.number()
        .max(999999999999999, i18next.t("balanceMinLimitValidation"))
        .min(-999999999999999, i18next.t("balanceMinLimitValidation"))
        .required(i18next.t("required"))
        .typeError(i18next.t("numberOnly")),

    transactionLimitations: Yup.array().of(
        Yup.object().shape({
            minAmountValuePerTransaction: Yup.number()
                .max(999999999999999, i18next.t("minAmountValuePerTransactionValidation"))
                .min(0, i18next.t("minAmountValuePerTransactionValidation"))
                .required(i18next.t("required"))
                .typeError(i18next.t("numberOnly")),
            maxAmountValuePerTransaction: Yup.number()
                .max(999999999999999, i18next.t("maxAmountValuePerTransactionValidation"))
                .min(0, i18next.t("maxAmountValuePerTransactionValidation"))
                .required(i18next.t("required"))
                .typeError(i18next.t("numberOnly")),

            timePeriodLimitations: Yup.array().of(
                Yup.object().shape({
                    maxNumberOfTransactions: Yup.number()
                        .max(999999999999999, i18next.t("maxNumberOfTransactionsValidation"))
                        .min(0, i18next.t("maxNumberOfTransactionsValidation"))
                        .required(i18next.t("required"))
                        .typeError(i18next.t("numberOnly")),
                    maxTransactionsTotalAmount: Yup.number()
                        .max(999999999999999, i18next.t("maxTransactionsTotalAmountValidation"))
                        .min(0, i18next.t("maxTransactionsTotalAmountValidation"))
                        .required(i18next.t("required"))
                        .typeError(i18next.t("numberOnly")),

                })
            )

        })
    ),

    /*
      
    */
    accountName: Yup.string()
        .max(50, i18next.t("accountNameValidation"))
        .min(1, i18next.t("accountNameValidation"))
        .required(i18next.t("required")),

    accountCode: Yup.string()
        .max(25, i18next.t("accountCodeValidation")),

    treasuryName: Yup.string()
        .max(50, i18next.t("treasuryNameValidation"))
        .min(1, i18next.t("treasuryNameValidation"))
        .required(i18next.t("required")),

    treasuryCode: Yup.string()
        .max(20, i18next.t("treasuryCodeValidation")),

    amount: Yup.number().transform((o, v) => Number(v.replace(/,/g, '')))
        .max(999999999999999, i18next.t("amountValidation"))
        .moreThan(0, i18next.t("amountValidation"))
        .required(i18next.t("required"))
        .typeError(i18next.t("numberOnly"))
    ,
    total: Yup.number().transform((o, v) => Number(v.replace(/,/g, '')))
        .max(999999999999999, i18next.t("totalValidation"))
        .moreThan(0, i18next.t("totalValidation"))
        .required(i18next.t("required"))
        .typeError(i18next.t("numberOnly"))
    ,

    price: Yup.number().transform((o, v) => Number(v.replace(/,/g, '')))
        .max(999999999999999, i18next.t("priceValidation"))
        .moreThan(0, i18next.t("priceValidation"))
        .required(i18next.t("required"))
        .typeError(i18next.t("numberOnly")),

    sellPrice: Yup.number().transform((o, v) => Number(v.replace(/,/g, '')))
        .max(999999999999999, i18next.t("sellPriceValidation"))
        .moreThan(0, i18next.t("sellPriceValidation"))
        .required(i18next.t("required"))
        .typeError(i18next.t("numberOnly")),

    buyPrice: Yup.number().transform((o, v) => Number(v.replace(/,/g, '')))
        .max(999999999999999, i18next.t("buyPriceValidation"))
        .moreThan(0, i18next.t("buyPriceValidation"))
        .required(i18next.t("required"))
        .typeError(i18next.t("numberOnly")),

    tradingPrice: Yup.number().transform((o, v) => Number(v.replace(/,/g, '')))
        .max(999999999999999, i18next.t("tradingPriceValidation"))
        .moreThan(0, i18next.t("tradingPriceValidation"))
        .required(i18next.t("required"))
        .typeError(i18next.t("numberOnly")),
    note: Yup.string()
        .max(140, i18next.t("noteValidation")),

    url: Yup.string()
        .url(i18next.t("urlValidation")),

    authCode: Yup.string()
        .max(8, i18next.t("authCodeValidation"))
        .min(8, i18next.t("authCodeValidation"))
        .required(i18next.t("required")),

    namePair: Yup.string()
        .max(200, i18next.t("namePairValidation"))
        .min(0, i18next.t("namePairValidation"))
        .required(i18next.t("required")),


    TwoFactorPhoneNumber: Yup.string().matches(/^(\+|00|0)[1-9][0-9 \-\(\)\.]{7,32}$/, i18next.t("phoneNumberValidation")).required(i18next.t("required")),

    TwoFactorEmail: Yup.string().email(i18next.t("emailValidation")).required(i18next.t("required")),

    message: Yup.string()
        .max(140, i18next.t("messageValidation")),

    accountNumber: Yup.number().integer(i18next.t("numberOnly"))
        .max(9999999999999999999, i18next.t("accountNumberValidation"))
        .moreThan(0, i18next.t("accountNumberValidation"))
        .required(i18next.t("required"))
        .typeError(i18next.t("numberOnly")),

    notRequiredAccountNumber: Yup.number().integer(i18next.t("numberOnly"))
        .max(9999999999999999999, i18next.t("accountNumberValidation"))
        .moreThan(0, i18next.t("accountNumberValidation"))
        .typeError(i18next.t("numberOnly")),

    linkMetadata: Yup.string()
        .max(500, i18next.t("invalidInput"))
        .min(1, i18next.t("invalidInput"))
        .required(i18next.t("required")),


    categoryName: Yup.string()
        .max(20, i18next.t("categoryNameValidation"))
        .min(1, i18next.t("categoryNameValidation"))
        .required(i18next.t("required")),

    equation: Yup.string()
        .max(200, i18next.t("equationValidation"))
        .min(2, i18next.t("equationValidation"))



}



