import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, refreshSession, getSession, ACCOUNTANT_API_URL, APIRequest } from '../../../../../app/Api';
import { AccountTypeEndpointClient } from '../../../../../repository/Accountant/Account_typeServiceClientPb';
import { GetAccountTypesResponse, GetAccountTypesRequest } from '../../../../../repository/Accountant/account_type_pb';
import { formatDate, timestampToDate } from '../../../../../app/Helpers';
import { DynamicField } from '../../../../common/DynamicFields/DynamicFields';
import { AccountTypeRuleEndpointClient } from '../../../../../repository/Accountant/Account_type_ruleServiceClientPb';
import { AddAccountTypeRuleRequest, AddAccountTypeRuleResponse, GetAccountTypeRuleRequest, UpdateAccountTypeRuleRequest, UpdateAccountTypeRuleResponse, GetAccountTypeRuleResponse } from '../../../../../repository/Accountant/account_type_rule_pb';
import { FeeEndpointClient } from '../../../../../repository/Accountant/FeeServiceClientPb';
import { GetFeesRequest, GetFeesResponse } from '../../../../../repository/Accountant/fee_pb';
import { TableState } from '../../../../common/Table/TableSate';


export interface AccountTypeRulesFormState {
    isLoading: boolean
    message?: ApiMessage
    stage1: any,
    creditorAccountTypes: TableState,
    creditorFees: TableState,
    debtorFees: TableState,


}

const initialState: AccountTypeRulesFormState = {
    isLoading: false,
    message: undefined,
    stage1: undefined,
    creditorAccountTypes: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    creditorFees: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    debtorFees: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },

}

const client = new AccountTypeRuleEndpointClient(ACCOUNTANT_API_URL, null, null);
const accountTypesClient = new AccountTypeEndpointClient(ACCOUNTANT_API_URL, null, null);
const feesClient = new FeeEndpointClient(ACCOUNTANT_API_URL, null, null);


export const add = createAsyncThunk<AddAccountTypeRuleResponse.AsObject, APIRequest<AddAccountTypeRuleRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/accountTypeRules/add',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.add(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)


export const update = createAsyncThunk<UpdateAccountTypeRuleResponse.AsObject, APIRequest<UpdateAccountTypeRuleRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/accountTypeRules/update',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.update(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)



export const get = createAsyncThunk<GetAccountTypeRuleResponse.AsObject, APIRequest<GetAccountTypeRuleRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/accountTypeRules/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getAccountTypeRule(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)


export const getCreditorAccountTypes = createAsyncThunk<GetAccountTypesResponse.AsObject, APIRequest<GetAccountTypesRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/accountTypeRules/accountTypes/getCreditorAccountTypes',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await accountTypesClient.getAccountTypes(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getDebtorFees = createAsyncThunk<GetFeesResponse.AsObject, APIRequest<GetFeesRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/accountTypeRules/fees/getDebtorFees',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await feesClient.getFees(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getCreditorFees = createAsyncThunk<GetFeesResponse.AsObject, APIRequest<GetFeesRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/accountant/accountTypeRules/fees/getCreditorFees',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await feesClient.getFees(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

export const accountTypeRulesFormSlice = createSlice({
    name: 'forms/accountant/accountTypeRules',
    initialState,
    reducers: {

        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.isLoading = false;
            state.message = undefined;
            state.stage1 = initialState.stage1;
            state.creditorAccountTypes = initialState.creditorAccountTypes;
            state.debtorFees = initialState.debtorFees;
            state.creditorFees = initialState.creditorFees;
        },
        setStage1: (state, action: PayloadAction<any>) => {
            let stage1 = {
                id: action.payload?.id,
                name: action.payload?.name,
                isActive: action.payload?.isActive,
                createdDate: action.payload?.createdDate,
                addedBy: action.payload?.addedBy,
                addedById: action.payload?.addedById,
                extraFields: action.payload?.extraFields,
                creditorAccountTypeId: action.payload?.creditorAccountTypeId,
                creditorAccountTypeName: action.payload?.creditorAccountTypeName,
                debtorAccountTypeId: action.payload?.debtorAccountTypeId,
                debtorAccountTypeName: action.payload?.debtorAccountTypeName,
                operation: action.payload?.operation,
                creditorFeeId: action.payload?.creditorFeeId,
                creditorFeeName: action.payload?.creditorFeeName,
                debtorFeeId: action.payload?.debtorFeeId,
                debtorFeeName: action.payload?.debtorFeeName,

            }
            state.stage1 = stage1;
        },
        resetCreditorAccountTypes: (state) => {
            state.creditorAccountTypes.items = [];
            state.creditorAccountTypes.hasMore = true;
        },

        resetDebtorFees: (state) => {
            state.debtorFees = initialState.debtorFees
        },
        resetCreditorFees: (state) => {
            state.creditorFees = initialState.creditorFees
        },
        setSelectedDebtorFee: (state, action: PayloadAction<any[]>) => {
            state.debtorFees.selected = action.payload;
        },
        setSelectedCreditorFee: (state, action: PayloadAction<any[]>) => {
            state.creditorFees.selected = action.payload;
        },
        setSelectedCreditorAccountType: (state, action: PayloadAction<any[]>) => {
            state.creditorAccountTypes.selected = action.payload;
        },
        setIsFilteredCreditorAccountTypesSet: (state, action: PayloadAction<boolean>) => {
            state.creditorAccountTypes.isFilteredSet = action.payload;
        },
        setIsFilteredDebtorFeesSet: (state, action: PayloadAction<boolean>) => {
            state.debtorFees.isFilteredSet = action.payload;
        },
        setIsFilteredCreditorFeesSet: (state, action: PayloadAction<boolean>) => {
            state.creditorFees.isFilteredSet = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(add.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            if (payload)
                state.message = toApiMessage(payload.success?.message);

        })
        builder.addCase(add.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(add.pending, (state, action) => {
            
            state.isLoading = true;
            state.message = undefined;
        })
        builder.addCase(update.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            if (payload)
                state.message = toApiMessage(payload.success?.message);
    
        })
        builder.addCase(update.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(update.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })

        builder.addCase(get.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {

                let r = {
                    id: payload?.success?.id,
                    creditorAccountTypeId: payload?.success?.creditoraccounttype?.id?.value ?? "",
                    creditorAccountTypeName: payload?.success?.creditoraccounttype?.name?.value ?? "",
                    debtorAccountTypeId: payload?.success?.debtoraccounttype?.id?.value ?? "",
                    debtorAccountTypeName: payload?.success?.debtoraccounttype?.name?.value ?? "",
                    operation: payload?.success?.operation?.value ?? undefined,
                    creditorFeeId: payload?.success?.creditorfee?.id?.value ?? "",
                    creditorFeeName: payload?.success?.creditorfee?.name?.value ?? "",
                    debtorFeeId: payload?.success?.debtorfee?.id?.value ?? "",
                    debtorFeeName: payload?.success?.debtorfee?.name?.value ?? "",
                    isActive: payload?.success?.isactive?.value ?? undefined,
                    createdDate: formatDate(timestampToDate(payload?.success?.createddate?.seconds, payload?.success?.createddate?.nanos)),
                    addedBy: (payload?.success?.createdby?.firstname?.value ?? "") + " " + (payload?.success?.createdby?.lastname?.value ?? ""),
                    addedById: payload?.success?.createdby?.id?.value,
                    extraFields: [] as DynamicField[]
                }

                if (payload?.success?.extrafieldsList) {
                    let count = 0
                    payload?.success?.extrafieldsList?.forEach(element => {
                        r.extraFields.push({ key: element.key, label: element.label, type: element.type, value: element.value })
                        count++
                    });
                }

                state.stage1 = r
            }
        })
        builder.addCase(get.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(get.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })

        builder.addCase(getDebtorFees.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.feesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        isActive: val.isactive?.value ?? undefined,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])

                if (r.length < state.debtorFees.numberOfResults) {
                    state.debtorFees.hasMore = false;
                }
                state.debtorFees.items = state.debtorFees.items.concat(r);
            } else {
                state.debtorFees.hasMore = false;
            }
            state.debtorFees.isFetching = false

        })
        builder.addCase(getDebtorFees.rejected, (state, action) => {
            state.debtorFees.isFetching = false;

            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getDebtorFees.pending, (state, action) => {
            state.debtorFees.isFetching = true;
            //state.message = undefined;
        })
        builder.addCase(getCreditorFees.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.feesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        isActive: val.isactive?.value ?? undefined,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])

                if (r.length < state.creditorFees.numberOfResults) {
                    state.creditorFees.hasMore = false;
                }
                state.creditorFees.items = state.creditorFees.items.concat(r);

            } else {
                state.creditorFees.hasMore = false;
            }
            state.creditorFees.isFetching = false

        })
        builder.addCase(getCreditorFees.rejected, (state, action) => {
            state.creditorFees.isFetching = false;

            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getCreditorFees.pending, (state, action) => {
            state.creditorFees.isFetching = true;
            //state.message = undefined;
        })

        builder.addCase(getCreditorAccountTypes.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.accounttypesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])

                if (r.length < state.creditorAccountTypes.numberOfResults) {
                    state.creditorAccountTypes.hasMore = false;
                }
                state.creditorAccountTypes.items = state.creditorAccountTypes.items.concat(r);



            } else {
                state.creditorAccountTypes.hasMore = false;
            }
            state.creditorAccountTypes.isFetching = false

        })
        builder.addCase(getCreditorAccountTypes.rejected, (state, action) => {
            state.creditorAccountTypes.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getCreditorAccountTypes.pending, (state, action) => {
            state.creditorAccountTypes.isFetching = true;
            //state.message = undefined;
        })
    }
})

export const { reset, dismissMessage, setStage1, resetCreditorAccountTypes, resetDebtorFees, resetCreditorFees, setIsFilteredCreditorAccountTypesSet, setIsFilteredDebtorFeesSet, setIsFilteredCreditorFeesSet, setSelectedCreditorAccountType, setSelectedDebtorFee, setSelectedCreditorFee } = accountTypeRulesFormSlice.actions

export default accountTypeRulesFormSlice.reducer


