import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIResponse, refreshSession, getSession, ACCOUNTANT_API_URL, APIRequest } from '../../../../../app/Api';
import { ReservationStatus } from '../../../../../app/Enums';
import { formatDate, timestampToDate } from '../../../../../app/Helpers';
import { AccountEndpointClient } from '../../../../../repository/Accountant/AccountServiceClientPb';
import { GetAccountRequest, GetAccountResponse } from '../../../../../repository/Accountant/account_pb';
import { BalanceReservationEndpointClient } from '../../../../../repository/Accountant/Balance_reservationServiceClientPb';
import { DeleteReservationRequest, DeleteReservationResponse,UpdateBalanceReservationResponse, UpdateBalanceReservationRequest, FreeAmountRequest, FreeAmountResponse, GetBalanceReservationRequest, GetBalanceReservationResponse } from '../../../../../repository/Accountant/balance_reservation_pb';
import { DynamicField } from '../../../../common/DynamicFields/DynamicFields';


export interface LimitationDetailsPageState {
    item: any
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    isLoading: boolean
    account: any,

}

const initialState: LimitationDetailsPageState = {
    item: undefined,
    isChangeStateLoading: false,
    message: undefined,
    isLoading: false,
    account: undefined

}

const client = new BalanceReservationEndpointClient(ACCOUNTANT_API_URL, null, null);
const accountClient = new AccountEndpointClient(ACCOUNTANT_API_URL, null, null);


export const get = createAsyncThunk<GetBalanceReservationResponse.AsObject, APIRequest<GetBalanceReservationRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/reservedBalanceDetails/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getBalanceReservation(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)

 
export const getAccount = createAsyncThunk<GetAccountResponse.AsObject, APIRequest<GetAccountRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/reservedBalanceDetails/account/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await accountClient.getAccount(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)

export const freeBalance = createAsyncThunk<APIResponse<FreeAmountResponse.AsObject>, APIRequest<FreeAmountRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/reservedBalanceDetails/freeBalance',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.freeAmount(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)


export const deleteItem = createAsyncThunk<APIResponse<DeleteReservationResponse.AsObject>, APIRequest<DeleteReservationRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/reservedBalanceDetails/delete',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.delete(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)





export const reservedBalanceDetailsPageSlice = createSlice({
    name: 'pages/accountant/reservedBalanceDetails',
    initialState,
    reducers: {
        setMessage: (state, action: PayloadAction<any>) => {
            state.message = action.payload;;
        },
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
            state.account = initialState.account;

        },

        resetItem: (state) => {
            state.item = undefined;

        },

        updateItem: (state, action: PayloadAction<any>) => {
            state.item = action.payload;

        }
    },
    extraReducers: (builder) => {
        builder.addCase(deleteItem.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
            }
        })
        builder.addCase(deleteItem.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(deleteItem.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

        builder.addCase(freeBalance.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                state.item.status = ReservationStatus.FREED
            }
        })
        builder.addCase(freeBalance.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(freeBalance.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })



        
        builder.addCase(get.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {

                let r = {
                    id: payload.success?.id,
                    accountId: payload.success?.account?.id?.value ?? "",
                    accountName: payload.success?.account?.name?.value ?? "",
                    currencyId: payload.success?.account?.currency?.id?.value ?? "",
                    currencyName: payload.success?.account?.currency?.name?.value ?? "",
                    currencyCode: payload.success?.account?.currency?.code?.value ?? "",
                    currencyDecimalPlaces: payload.success?.account?.currency?.decimalplaces?.value ?? undefined,
                    currencyForm: payload.success?.account?.currency?.form?.value ?? undefined,
                    currencySymbol: payload.success?.account?.currency?.symbol?.value ?? "",
                    amount: payload.success?.amount?.value ?? undefined,
                    reason: payload.success?.reason?.value ?? undefined,
                    statusCreatedBy: (payload.success?.status?.createdby?.firstname?.value ?? "") + " " + (payload.success?.status?.createdby?.lastname?.value ?? ""),
                    statusCreatedDate: formatDate(timestampToDate(payload.success?.createddate?.seconds, payload.success?.createddate?.nanos)),
                    status: payload.success?.status?.status?.value ?? undefined,
                    statusTransactionId: payload.success?.status?.createdtransaction?.id?.value ?? undefined,
                    statusTransactionInternalId: payload.success?.status?.createdtransaction?.internalid?.value ?? "",
                    referenceTransactionId: payload.success?.referencetransaction?.id?.value ?? undefined,
                    referenceTransactionInternalId: payload.success?.referencetransaction?.internalid?.value ?? "",
                    createdDate: formatDate(timestampToDate(payload.success?.createddate?.seconds, payload.success?.createddate?.nanos)),
                    addedBy: (payload.success?.createdby?.firstname?.value ?? "") + " " + (payload.success?.createdby?.lastname?.value ?? ""),
                    addedById: payload.success?.createdby?.id?.value,
                    note: payload.success?.note?.value ?? "",
                    extraFields: [] as DynamicField[],
                    triggeredbyTransactionId: payload.success?.triggeredby?.id?.value ?? undefined,
                    triggeredbyTransactionInternalId: payload.success?.triggeredby?.internalid?.value ?? "",

                }


                if (payload?.success?.extrafieldsList) {
                    let count = 0
                    payload?.success?.extrafieldsList?.forEach(element => {
                        r.extraFields.push({ key: element.key, label: element.label, type: element.type, value: element.value })
                        count++
                    });
                }

                state.item = r
            }
        })
        builder.addCase(get.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(get.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })

        builder.addCase(getAccount.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false
            if (payload) {
                let r = {
                    id: payload.success?.id,
                    internalId: payload.success?.internalid,
                    name: payload.success?.name?.value ?? "",
                    code: payload.success?.code?.value ?? "",
                    currencyId: payload.success?.currency?.id?.value ?? "",
                    currencyName: payload.success?.currency?.name?.value ?? "",
                    currencyCode: payload.success?.currency?.code?.value ?? "",
                    currencyDecimalPlaces: payload.success?.currency?.decimalplaces?.value ?? undefined,
                    currencyForm: payload.success?.currency?.form?.value ?? undefined,
                    currencySymbol: payload.success?.currency?.symbol?.value ?? "",
                    accountTypeId: payload.success?.accounttype?.id?.value ?? "",
                    accountTypeName: payload.success?.accounttype?.name?.value ?? "",
                    status: payload.success?.status?.value ?? undefined,
                    ownerFirstname: payload.success?.owner?.firstname?.value ?? "",
                    ownerLastname: payload.success?.owner?.lastname?.value ?? "",
                    ownerMiddlename: payload.success?.owner?.middlename?.value ?? "",
                    ownerName: (payload.success?.owner?.firstname?.value ?? "") + " " + (payload.success?.owner?.lastname?.value ?? ""),
                    ownerId: payload.success?.owner?.id?.value ?? "",
                    createdDate: formatDate(timestampToDate(payload.success?.createddate?.seconds, payload.success?.createddate?.nanos)),
                    addedBy: (payload.success?.createdby?.firstname?.value ?? "") + " " + (payload.success?.createdby?.lastname?.value ?? ""),
                    addedById: payload.success?.createdby?.id?.value,
                    limitationId: payload.success?.limitation?.id?.value ?? "",
                    limitationName: payload.success?.limitation?.name?.value ?? "",
                    freeBalance: undefined,
                    reservedBalance: undefined,
                    totalBalance: undefined,
                    extraFields: [] as DynamicField[],
                    isAuthorizationRequired: payload.success?.accountsetting?.isauthorizationrequired,
                    isLinkingEnable: payload.success?.accountsetting?.islinkingenable?.value,
                    isLinkingMetadataSet: payload.success?.accountsetting?.islinkingmetadataset?.value,
                    transferControl: payload.success?.accountsetting?.tansfercontrol?.value ,



                }

                if (payload?.success?.extrafieldsList) {
                    let count = 0
                    payload?.success?.extrafieldsList?.forEach(element => {
                        r.extraFields.push({ key: element.key, label: element.label, type: element.type, value: element.value })
                        count++
                    });
                }


                state.account = r
            }
        })
        builder.addCase(getAccount.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getAccount.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

    }
})

export const { reset, dismissMessage, resetItem, updateItem, setMessage } = reservedBalanceDetailsPageSlice.actions

export default reservedBalanceDetailsPageSlice.reducer