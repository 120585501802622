/**
 * @fileoverview gRPC-Web generated client stub for treasury_type_rule_endpoint
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as treasury_type_rule_pb from './treasury_type_rule_pb';


export class TreasuryTypeRuleEndpointClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAdd = new grpcWeb.MethodDescriptor(
    '/treasury_type_rule_endpoint.TreasuryTypeRuleEndpoint/Add',
    grpcWeb.MethodType.UNARY,
    treasury_type_rule_pb.AddTreasuryTypeRuleRequest,
    treasury_type_rule_pb.AddTreasuryTypeRuleResponse,
    (request: treasury_type_rule_pb.AddTreasuryTypeRuleRequest) => {
      return request.serializeBinary();
    },
    treasury_type_rule_pb.AddTreasuryTypeRuleResponse.deserializeBinary
  );

  add(
    request: treasury_type_rule_pb.AddTreasuryTypeRuleRequest,
    metadata: grpcWeb.Metadata | null): Promise<treasury_type_rule_pb.AddTreasuryTypeRuleResponse>;

  add(
    request: treasury_type_rule_pb.AddTreasuryTypeRuleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: treasury_type_rule_pb.AddTreasuryTypeRuleResponse) => void): grpcWeb.ClientReadableStream<treasury_type_rule_pb.AddTreasuryTypeRuleResponse>;

  add(
    request: treasury_type_rule_pb.AddTreasuryTypeRuleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: treasury_type_rule_pb.AddTreasuryTypeRuleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/treasury_type_rule_endpoint.TreasuryTypeRuleEndpoint/Add',
        request,
        metadata || {},
        this.methodDescriptorAdd,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/treasury_type_rule_endpoint.TreasuryTypeRuleEndpoint/Add',
    request,
    metadata || {},
    this.methodDescriptorAdd);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/treasury_type_rule_endpoint.TreasuryTypeRuleEndpoint/Update',
    grpcWeb.MethodType.UNARY,
    treasury_type_rule_pb.UpdateTreasuryTypeRuleRequest,
    treasury_type_rule_pb.UpdateTreasuryTypeRuleResponse,
    (request: treasury_type_rule_pb.UpdateTreasuryTypeRuleRequest) => {
      return request.serializeBinary();
    },
    treasury_type_rule_pb.UpdateTreasuryTypeRuleResponse.deserializeBinary
  );

  update(
    request: treasury_type_rule_pb.UpdateTreasuryTypeRuleRequest,
    metadata: grpcWeb.Metadata | null): Promise<treasury_type_rule_pb.UpdateTreasuryTypeRuleResponse>;

  update(
    request: treasury_type_rule_pb.UpdateTreasuryTypeRuleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: treasury_type_rule_pb.UpdateTreasuryTypeRuleResponse) => void): grpcWeb.ClientReadableStream<treasury_type_rule_pb.UpdateTreasuryTypeRuleResponse>;

  update(
    request: treasury_type_rule_pb.UpdateTreasuryTypeRuleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: treasury_type_rule_pb.UpdateTreasuryTypeRuleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/treasury_type_rule_endpoint.TreasuryTypeRuleEndpoint/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/treasury_type_rule_endpoint.TreasuryTypeRuleEndpoint/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorUpdateActivity = new grpcWeb.MethodDescriptor(
    '/treasury_type_rule_endpoint.TreasuryTypeRuleEndpoint/UpdateActivity',
    grpcWeb.MethodType.UNARY,
    treasury_type_rule_pb.UpdateTreasuryTypeRuleActivityRequest,
    treasury_type_rule_pb.UpdateTreasuryTypeRuleActivityResponse,
    (request: treasury_type_rule_pb.UpdateTreasuryTypeRuleActivityRequest) => {
      return request.serializeBinary();
    },
    treasury_type_rule_pb.UpdateTreasuryTypeRuleActivityResponse.deserializeBinary
  );

  updateActivity(
    request: treasury_type_rule_pb.UpdateTreasuryTypeRuleActivityRequest,
    metadata: grpcWeb.Metadata | null): Promise<treasury_type_rule_pb.UpdateTreasuryTypeRuleActivityResponse>;

  updateActivity(
    request: treasury_type_rule_pb.UpdateTreasuryTypeRuleActivityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: treasury_type_rule_pb.UpdateTreasuryTypeRuleActivityResponse) => void): grpcWeb.ClientReadableStream<treasury_type_rule_pb.UpdateTreasuryTypeRuleActivityResponse>;

  updateActivity(
    request: treasury_type_rule_pb.UpdateTreasuryTypeRuleActivityRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: treasury_type_rule_pb.UpdateTreasuryTypeRuleActivityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/treasury_type_rule_endpoint.TreasuryTypeRuleEndpoint/UpdateActivity',
        request,
        metadata || {},
        this.methodDescriptorUpdateActivity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/treasury_type_rule_endpoint.TreasuryTypeRuleEndpoint/UpdateActivity',
    request,
    metadata || {},
    this.methodDescriptorUpdateActivity);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/treasury_type_rule_endpoint.TreasuryTypeRuleEndpoint/Delete',
    grpcWeb.MethodType.UNARY,
    treasury_type_rule_pb.DeleteTreasuryTypeRuleRequest,
    treasury_type_rule_pb.DeleteTreasuryTypeRuleResponse,
    (request: treasury_type_rule_pb.DeleteTreasuryTypeRuleRequest) => {
      return request.serializeBinary();
    },
    treasury_type_rule_pb.DeleteTreasuryTypeRuleResponse.deserializeBinary
  );

  delete(
    request: treasury_type_rule_pb.DeleteTreasuryTypeRuleRequest,
    metadata: grpcWeb.Metadata | null): Promise<treasury_type_rule_pb.DeleteTreasuryTypeRuleResponse>;

  delete(
    request: treasury_type_rule_pb.DeleteTreasuryTypeRuleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: treasury_type_rule_pb.DeleteTreasuryTypeRuleResponse) => void): grpcWeb.ClientReadableStream<treasury_type_rule_pb.DeleteTreasuryTypeRuleResponse>;

  delete(
    request: treasury_type_rule_pb.DeleteTreasuryTypeRuleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: treasury_type_rule_pb.DeleteTreasuryTypeRuleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/treasury_type_rule_endpoint.TreasuryTypeRuleEndpoint/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/treasury_type_rule_endpoint.TreasuryTypeRuleEndpoint/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorGetTreasuryTypeRule = new grpcWeb.MethodDescriptor(
    '/treasury_type_rule_endpoint.TreasuryTypeRuleEndpoint/GetTreasuryTypeRule',
    grpcWeb.MethodType.UNARY,
    treasury_type_rule_pb.GetTreasuryTypeRuleRequest,
    treasury_type_rule_pb.GetTreasuryTypeRuleResponse,
    (request: treasury_type_rule_pb.GetTreasuryTypeRuleRequest) => {
      return request.serializeBinary();
    },
    treasury_type_rule_pb.GetTreasuryTypeRuleResponse.deserializeBinary
  );

  getTreasuryTypeRule(
    request: treasury_type_rule_pb.GetTreasuryTypeRuleRequest,
    metadata: grpcWeb.Metadata | null): Promise<treasury_type_rule_pb.GetTreasuryTypeRuleResponse>;

  getTreasuryTypeRule(
    request: treasury_type_rule_pb.GetTreasuryTypeRuleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: treasury_type_rule_pb.GetTreasuryTypeRuleResponse) => void): grpcWeb.ClientReadableStream<treasury_type_rule_pb.GetTreasuryTypeRuleResponse>;

  getTreasuryTypeRule(
    request: treasury_type_rule_pb.GetTreasuryTypeRuleRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: treasury_type_rule_pb.GetTreasuryTypeRuleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/treasury_type_rule_endpoint.TreasuryTypeRuleEndpoint/GetTreasuryTypeRule',
        request,
        metadata || {},
        this.methodDescriptorGetTreasuryTypeRule,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/treasury_type_rule_endpoint.TreasuryTypeRuleEndpoint/GetTreasuryTypeRule',
    request,
    metadata || {},
    this.methodDescriptorGetTreasuryTypeRule);
  }

  methodDescriptorGetTreasuryTypeRules = new grpcWeb.MethodDescriptor(
    '/treasury_type_rule_endpoint.TreasuryTypeRuleEndpoint/GetTreasuryTypeRules',
    grpcWeb.MethodType.UNARY,
    treasury_type_rule_pb.GetTreasuryTypeRulesRequest,
    treasury_type_rule_pb.GetTreasuryTypeRulesResponse,
    (request: treasury_type_rule_pb.GetTreasuryTypeRulesRequest) => {
      return request.serializeBinary();
    },
    treasury_type_rule_pb.GetTreasuryTypeRulesResponse.deserializeBinary
  );

  getTreasuryTypeRules(
    request: treasury_type_rule_pb.GetTreasuryTypeRulesRequest,
    metadata: grpcWeb.Metadata | null): Promise<treasury_type_rule_pb.GetTreasuryTypeRulesResponse>;

  getTreasuryTypeRules(
    request: treasury_type_rule_pb.GetTreasuryTypeRulesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: treasury_type_rule_pb.GetTreasuryTypeRulesResponse) => void): grpcWeb.ClientReadableStream<treasury_type_rule_pb.GetTreasuryTypeRulesResponse>;

  getTreasuryTypeRules(
    request: treasury_type_rule_pb.GetTreasuryTypeRulesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: treasury_type_rule_pb.GetTreasuryTypeRulesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/treasury_type_rule_endpoint.TreasuryTypeRuleEndpoint/GetTreasuryTypeRules',
        request,
        metadata || {},
        this.methodDescriptorGetTreasuryTypeRules,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/treasury_type_rule_endpoint.TreasuryTypeRuleEndpoint/GetTreasuryTypeRules',
    request,
    metadata || {},
    this.methodDescriptorGetTreasuryTypeRules);
  }

}

